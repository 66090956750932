import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {AngularFireAuth} from '@angular/fire/auth'
import {AngularFireDatabase} from '@angular/fire/database'
import { ResizedEvent } from 'angular-resize-event';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Router } from '@angular/router';
import {AngularFireFunctions} from '@angular/fire/functions'
import { auth } from 'firebase';
import { AngularFireStorage } from '@angular/fire/storage';
import moment from 'moment-timezone';
import { environment } from 'src/environments/environment.prod';

@Component({  
  selector: 'app-header',    
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  logging:boolean;     
  width: number; 
  email = "";
  pass = false;
  height: number;
  errorLogin = []
  @Output() mobile = new EventEmitter<boolean>();
  @Output() selectedTimeFrame = new EventEmitter<any[]>();
  @Output() totalEmitter = new EventEmitter<any>();
  teacher = false
  lastTogg: string;
  cartItems = []
  cartPortInit = false
  UID = ''
  isCartOpen = false
  total = 0
  currentTimeRanges = []

  stripe: Stripe;

  constructor(private fns:AngularFireFunctions,private afm: AngularFireStorage,public auth: AuthService,private afAuth: AngularFireAuth, private db: AngularFireDatabase,private router:Router) { }
  
  async ngOnInit(){
    var holl = ''
    var hold = false
    setTimeout(()=>{
      this.totalEmitter.emit(this.total)
    },0)
    this.auth.user$.subscribe(usr=>{
      if(usr){
        this.UID = usr.uid
        var dbRef = this.db.database
        dbRef.ref('/users/' +  `${this.UID}` + '/cart').once('value',val=>{
          
          for(const keys in val.val()){
            console.log(val.val()[keys])
            var sessionTotalHours = 0
            val.val()[keys]['dates'].split('~').forEach(dateSpread =>{
              sessionTotalHours += moment(dateSpread.split('|-|')[1]).diff(moment(dateSpread.split('|-|')[0]), 'hours',true)
            })
            console.log(sessionTotalHours,val.val()[keys]['rate'],sessionTotalHours*val.val()[keys]['rate'])
            var hld = val.val()[keys]
            hld['total'] = sessionTotalHours*val.val()[keys]['rate']
            this.total += sessionTotalHours*val.val()[keys]['rate']
            this.totalEmitter.emit(this.total)
            console.log(hld)
            this.cartItems.unshift(hld)
            console.log(val.val()[keys],val.val()[keys]['dates'].split('~'))
            this.currentTimeRanges = val.val()[keys]['dates'].split('~')
            setTimeout(()=>{
              this.setStudentProfilePic(val.val()[keys]['SProf'],val.val()[keys]['dates'])
              this.getTeacherProPic(val.val()[keys]['TUID'],val.val()[keys]['dates'])
            },0)
            console.log(this.currentTimeRanges)
          }
        })

        dbRef.ref('/users/' +  `${this.UID}` + '/cartPort').on('value',val=>{
          console.log(val.val())
          if(this.cartPortInit){
            console.log(val.val())
            for(const keys in val.val()){
              console.log(val.val()[keys])
              var sessionTotalHours = 0
              val.val()[keys]['dates'].split('~').forEach(dateSpread =>{
                sessionTotalHours += moment(dateSpread.split('|-|')[1]).diff(moment(dateSpread.split('|-|')[0]), 'hours',true)
              })
              console.log(sessionTotalHours,val.val()[keys]['rate'],sessionTotalHours*val.val()[keys]['rate'])
              var hld = val.val()[keys]
              console.log(hld)
              hld['total'] = sessionTotalHours*val.val()[keys]['rate']
              this.total += sessionTotalHours*val.val()[keys]['rate']
              this.totalEmitter.emit(this.total)
              console.log(hld)
              this.cartItems.unshift(hld)
              console.log(val.val()[keys],val.val()[keys]['dates'].split('~'))
              this.currentTimeRanges = val.val()[keys]['dates'].split('~')
              setTimeout(()=>{
                this.setStudentProfilePic(val.val()[keys]['SProf'],val.val()[keys]['dates'])
                this.getTeacherProPic(val.val()[keys]['TUID'],val.val()[keys]['dates'])
              },0)
              console.log(this.currentTimeRanges)
            }
          }else{
            this.cartPortInit = true
          }
        })

        this.getProPic()
        document.getElementById('signIn').setAttribute('class','empty')
        document.getElementById('signUp').setAttribute('class','empty')
        document.getElementById('profPic').setAttribute('class','ellipse-17-1')
        document.getElementById('profPicArrow').setAttribute('class','vector-2-1')
        dbRef.ref('providers/userInfo/' + usr.uid).once('value').then(function(snap){
          if(snap.exists()){
            hold = true 
          }
        }).then(()=>{
          this.teacher = hold
        })
      }
    })
  }

  async getTeacherProPic(TUID,key){
    console.log(TUID,key)
    var teacherUID = TUID
    // Create a reference from a Google Cloud Storage URI
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/teacherPics/' + teacherUID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    console.log('teacherPic3'+ key,storage)
    storage.getDownloadURL().then(function(url) {
      document.getElementById('teacherPic3'+ key).setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      var key = ''
      var randomNum = Math.random()
      console.log(randomNum)
      if(Number(randomNum) === 1){
        randomNum =.98
      }
      var randomNumber = Math.floor(randomNum * 7) 
      if(randomNumber === 0){
        randomNumber = 1
      }
       
      if(randomNumber === 1){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png'
      }else if(randomNumber === 2){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-48@2x.png'
      }else if(randomNumber === 3){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-49@2x.png'
      }else if(randomNumber === 4){
        key = '../../../assets/Giraf.png'
      }else if(randomNumber === 5){
        key = '../../../assets/Cat.png'
      }else if(randomNumber === 6){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-50@2x.png'
      }
      document.getElementById('teacherPic3'+ key).setAttribute('src',`${key}`);;
      // document.getElementById(`${teacherHold.UID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }

  async getProPic(){
    var teacherUID = this.UID
    // Create a reference from a Google Cloud Storage URI
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/teacherPics/' + teacherUID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById('profPic').setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      document.getElementById('profPic').setAttribute('src','../../../assets/Giraf.svg');
      // document.getElementById(`${teacherHold.UID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }   

  setStudentProfilePic(proImg,key){
    console.log(proImg+ key)
    if(String(proImg) === '1'){
      document.getElementById('sProPic' + key).setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png')
    }else if(String(proImg) === '2'){
      document.getElementById('sProPic'+ key).setAttribute('src','../../../assets/Frog.svg')
    }else if(String(proImg) === '3'){
      document.getElementById('sProPic'+ key).setAttribute('src','../../../assets/Hippo.svg')
    }else if(String(proImg) === '4'){
      document.getElementById('sProPic'+ key).setAttribute('src','../../../assets/Giraf.svg')
    }else if(String(proImg) === '5'){
      document.getElementById('sProPic'+ key).setAttribute('src','../../../assets/Cat.svg')
    }else if(String(proImg) === '6'){
      document.getElementById('sProPic'+ key).setAttribute('src','../../../assets/zebra.svg')
    }
  }


  async pay(){
    console.log(1)
    if(auth().currentUser){
      console.log(2)
      this.stripe = await loadStripe(environment.stripeP);
      const stripeCheckout = await this.fns.httpsCallable('stripeCheckout')
      await stripeCheckout({
        dateTime:'',
        appointmentTimes1: '',
        appointmentTimes2: '',
        teacherName: 'name',
        services: 'Tutoring',
        teacherEmail: '',
        parentEmail: '',
        teacherUID: '',
        parentUID: '',
        product_name: 'Appointment(s)',
        quantity:1, 
        unit_amount: this.total,
        timeZone: '',
        timeZone2:'',
        requestType:`${this.UID}`
      }).toPromise().then((sessionId:string)=> this.stripe.redirectToCheckout({sessionId})
      .then(function(va){} )).catch((e)=> console.log('err',e)).finally(()=> {
        this.logging = false
      })
    }
  }
  // async pay(){
  //   if(auth().currentUser){
  //     this.stripe = await loadStripe(environment.stripeP);
  //     const stripeCheckout = await this.fns.httpsCallable('stripeCheckout')
  //     await stripeCheckout({
  //       dateTime:'',
  //       appointmentTimes1: `${dates}`,
  //       appointmentTimes2: '',
  //       teacherName: 'name',
  //       services: 'Tutoring',
  //       teacherEmail: this.teacherEmail,
  //       parentEmail: this.parentEmail,
  //       teacherUID: this.teacherUID,
  //       parentUID: this.parentUID,
  //       product_name: 'appointment',
  //       quantity:1, 
  //       unit_amount: this.total,
  //       timeZone: (new Date().getTimezoneOffset()/60),
  //       timeZone2:ttzHold,
  //       requestType:`${this.UID}`
  //     }).toPromise().then((sessionId:string)=> this.stripe.redirectToCheckout({sessionId})
  //     .then(function(va){} )).catch((e)=> console.log('err',e)).finally(()=> {
  //       this.logging = false
  //     }).finally(()=>{
  //       this.paySent = false
  //     })
  //   }
  // }

  signOut(){
    this.errorLogin = []
    this.auth.signOut()
    document.getElementById('signIn').setAttribute('class','group-58')
    document.getElementById('signUp').setAttribute('class','sign-up-1 worksans-semi-bold-chathams-blue-14px')
    document.getElementById('profPic').setAttribute('class','ellipse-17')
    document.getElementById('profPicArrow').setAttribute('class','vector-2')
    this.lastTogg = ''  
    document.getElementById('dropship2').setAttribute('class','overlap-group221-1')
  }


  onResized(event: ResizedEvent) {
    this.width = event.newWidth;
    this.height = event.newHeight;
    if(this.width < 500){
      this.mobile.emit(false)
    }
    if(this.width > 500){
      this.mobile.emit(true)
    }
  }
  openEditor(item){
    console.log(item)
    document.getElementById('cartEditor').setAttribute('style','')
    this.selectedTimeFrame.emit(item)
  }
  
  toggleCart(){
    if(this.isCartOpen){
      document.getElementById('cartDropDown').setAttribute('style','display:none') 
      document.getElementById('cartDropDownTotal').setAttribute('style','display:none')
    }else{
      document.getElementById('cartDropDown').setAttribute('style','position: absolute;width: 466px;left: 604px;top: 120px;border-bottom-right-radius: 10px;border-bottom-left-radius: 10px;height: 255px;overflow:scroll;background-color: white;z-index: 3;border: 1px solid #F3F3F3;')
      document.getElementById('cartDropDownTotal').setAttribute('style','width: 464px;height: 44px;border-top-left-radius: 10px;border-top-right-radius: 10px;background: lightgray;position: absolute;left: 605px;top: 77px;z-index: 3;')
    }
    this.isCartOpen = !this.isCartOpen
  }

  redirectTeacherForm(){
    this.lastTogg = ''  
    document.getElementById('dropship2').setAttribute('class','overlap-group221-1')
    this.router.navigateByUrl('/TeacherProfile')
  }

  newProfile(){
    document.getElementById('profPic').setAttribute('class','ellipse-17-1')
    document.getElementById('signIn').setAttribute('class','empty')
    document.getElementById('signUp').setAttribute('class','empty')
    document.getElementById('profPicArrow').setAttribute('class','vector-2-1')
  }
  removeTime($event){

  }

  closeMe2(){
    document.getElementById('cartEditor').setAttribute('style','display:none')
  }

  formatHourMin(range){
    var formatedTime = moment(range).format("h:mm a");

    return formatedTime
  }
  addBackSelectedTimes(v){
    console.log(v)
  }

  async emailSignIn(){
    if(this.pass && this.email === ""){
      this.errorLogin = ["please enter an email"]
    }else if (!this.pass && this.email !== ""){
      this.errorLogin = ["please enter a password"]
    }else if(this.pass && this.email === ""){
      this.errorLogin = ["please enter an email and password"]
    }else{
      this.errorLogin = [await this.auth.emailSignIn()]
    }
    var holl = ''
    var hold = false
    this.auth.user$.subscribe(usr=>{
      if(usr){
        document.getElementById('dropship2').setAttribute('class','overlap-group221-1')
        this.UID = usr.uid
        document.getElementById('profPic').setAttribute('class','ellipse-17-1')
        document.getElementById('signIn').setAttribute('class','empty')
        document.getElementById('signUp').setAttribute('class','empty')
        document.getElementById('profPicArrow').setAttribute('class','vector-2-1')
        this.lastTogg = ''  
        this.getProPic()
      }
      this.db.database.ref('providers/userInfo/' + usr.uid).once('value').then(function(snap){
        if(snap.exists()){
          hold = true 
        }
      }).then(()=>{
        this.teacher = hold
      })
    })
  }
  
  async storeEmail(email){
    this.auth.storeEmail(await email.viewModel)
    this.email = email.viewModel
  }  

  closeMe(){
    document.getElementById('cartDropDown').setAttribute('style','display:none')
    document.getElementById('cartDropDownTotal').setAttribute('style','display:none')
  }
 
  storePass(pass){
    this.auth.storePassword(pass.viewModel)
    if(pass.viewModel !== ""){
      this.pass = true
    }else{
      this.pass = false
    }
  }

  authToggle(TYPE){
    if(TYPE === 'in'){
      if(this.lastTogg !== 'in'){
        this.lastTogg = TYPE
        document.getElementById('dropship2').setAttribute('class','overlap-group221-1-1-2')
      }else{
        this.lastTogg = ''  
        document.getElementById('dropship2').setAttribute('class','overlap-group221-1')
      }  
    }
    if(TYPE === 'up'){
      if(this.lastTogg !== 'up'){       
        this.lastTogg = TYPE
        document.getElementById('dropship2').setAttribute('class','overlap-group221-1-1')
      }else{
        this.lastTogg = ''
        document.getElementById('dropship2').setAttribute('class','overlap-group221-1')
      }
    }
  }

  formatDate(range){
    var formatedTime = moment(range).format("dddd, MMMM Do");

    return formatedTime
  }

  async resetPass(){
    if(this.email !== ""){
      this.errorLogin = [await this.auth.resetPass()]
    }else{
      //you need to enter an email
      this.errorLogin = ["we need an email to resest"]
    }
  }

  async signUp(){
    this.logging = true
    await this.auth.emailSignUp().then(val=>{
      this.lastTogg = ''  
      document.getElementById('dropship2').setAttribute('class','overlap-group221-1')
    }).finally(
      () => this.logging = false
    )
  }
}
