import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
   
import { TeacherProfileComponent } from '../app/components/pages/teacher-profile/teacher-profile.component';
import { TeacherPageComponent } from '../app/components/teacher-page/teacher-page.component';
import { MeetTeachersComponent } from '../app/components/meet-teachers/meet-teachers.component';
import { UpdateTeacherInfoComponent } from '../app/components/update-teacher-info/update-teacher-info.component';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import { AppointmentsComponent } from './components/appointments/appointments.component';
import { AboutComponent } from '../app/components/pages/about/about.component'
import { SuccessComponent } from './components/success/success.component';
import { AcComponent } from './components/ac/ac.component';  
import { TeacherSuccessComponent } from './components/teacher-success/teacher-success.component';
import { ParentZoneComponent } from './components/parent-zone/parent-zone.component';
import { MarketingComponent } from './components/marketing/marketing.component'
import { QuestionHubComponent } from './components/question-hub/question-hub.component';
import { SearchComponent } from './components/search/search.component';
import { BookingComponent } from './booking/booking.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { PostComponent } from './components/post/post.component';
import { CampsComponent } from './components/camps/camps.component';
   
import { TeacherContentComponent } from './components/teacher-content/teacher-content.component';
import { VerifiedChatComponent } from './components/verified-chat/verified-chat.component';
import { TeacherLandingComponent } from './components/teacher-landing/teacher-landing.component';
import { UserAgreementComponent } from './components/pages/user-agreement/user-agreement.component';
import { ClassroomComponent } from './components/classroom/classroom.component';
import { CommunityResourcesComponent } from './components/community-resources/community-resources.component';
import { AmbassadorLandingComponent } from './components/pages/ambassador-landing/ambassador-landing.component';
import { StandardSignupComponent } from './components/standard-signup/standard-signup.component';
import { VideoPageComponent } from './components/video-page/video-page.component';
import { EventLandingPageComponent } from './components/event-landing-page/event-landing-page.component';
import { GroupClassComponent } from './components/group-class/group-class.component';
import { StudentProfileComponent } from './components/student-profile/student-profile.component';
import { OrgLandingPageComponent } from './components/org-landing-page/org-landing-page.component';


     
     
const routes: Routes = [  
  {path: '', component:BookingComponent},
  {path: 'TeacherProfile', component:TeacherProfileComponent},
  {path: 'TeacherProfile/:NAME', component:TeacherProfileComponent},
  {path: 'SignUp/:UID', component:StandardSignupComponent},
  {path: 'OrgSignUp', component:OrgLandingPageComponent},
  {path: 'TeacherPage/:UID', component:TeacherPageComponent},
  {path: 'VerifiedBlog/:BUID', component:MeetTeachersComponent},
  {path: 'update-teacher/:UID', component:UpdateTeacherInfoComponent},
  {path:'ParentZone/:UID', component:ParentZoneComponent},
  {path: 'profile/:UID', component:UserProfileComponent},
  {path: 'successPay', component:AppointmentsComponent},
  {path: 'success', component:SuccessComponent},    
  {path: '155445dcsxcws56xsw2778&*73', component:AcComponent},
  {path: 'welcome', component:TeacherSuccessComponent},  
  {path: 'Promo', component:MarketingComponent},      
  {path: 'QuestionZone', component:QuestionHubComponent},
  {path: 'FindATeacher', component:SearchComponent},   
  {path: 'FindATeacher/:UID', component:SearchComponent},   
  {path: 'privacypolicy', component:PrivacyComponent},
  {path: 'VerifiedPost/:AUID', component:PostComponent},
  {path: 'VerifiedCampaigns/:UID', component:CampsComponent},   
  {path: 'VerifiedVideo/:BUID', component:VerifiedChatComponent},
  {path: 'VerifiedGroupVideo/:BUID', component:GroupClassComponent},
  {path: 'ContentCreator/:UID', component:TeacherContentComponent},
  {path: 'TeacherWelcome', component:TeacherLandingComponent},
  {path: 'TeacherAgreement', component:UserAgreementComponent},
  {path: 'VerifiedClassroom/:BUID', component:VideoPageComponent},
  {path: 'CommunityResources', component:CommunityResourcesComponent},
  {path: 'AmbassadorZone', component:AmbassadorLandingComponent},
  {path: 'EventPage', component:EventLandingPageComponent},
  {path: 'StudentZone/:UID', component:StudentProfileComponent},
  {path: 'FAQ', component:AboutComponent}    
      
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
