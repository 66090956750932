import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import {Router } from '@angular/router';

@Component({
  selector: 'app-ambassador-landing',
  templateUrl: './ambassador-landing.component.html',
  styleUrls: ['./ambassador-landing.component.scss']
})
export class AmbassadorLandingComponent implements OnInit {
  nameNL = ''
  emailNL = ''
  constructor(private rerouter: Router,private fns:AngularFireFunctions) { }

  ngOnInit(): void {

  }

  signUp(){
    if(this.emailNL !== '' && this.nameNL !== ''){
      document.getElementById('signUpButt').setAttribute('style','display: none;')
      this.emailParker()
    }else{
      //erre
    }
  }

  async emailParker(){
    var message = 'We got a new sign up bro bro, check them out: name - ' + this.nameNL + ' email - ' + this.emailNL
    var addr = 'parker@verifiedteachers.com'
    var sub = 'New Ambassador'
    const newEmail = this.fns.httpsCallable('sendMail')
    await newEmail({
      address: addr,
      subject: sub,
      msg:`${message}`
    }).toPromise().then(()=>{
      document.getElementById('signSucc').setAttribute('style','text-align: left;padding-top: 20px;display: block;')
    }).catch(()=>{
      document.getElementById('signUpButt').setAttribute('style','text-align: left;padding-top: 20px;display: block;')
    })
  }
}
     