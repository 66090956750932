<div class="overlap-group1082">
    <h1 class="text-9 worksans-semi-bold-abbey-24px">Your Schedule</h1>
    <div class="overlap-group3-2" (click) = openCalendar()><div class="sign-in worksans-semi-bold-chathams-blue-14px">Black Out Dates</div></div>
    <div class="timeSelect">
        <div style="display:inline-block;vertical-align: top;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;margin-left: 10px;
        margin-right: 10px;width: 95px;">Monday's</div> 
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleStartTime2(1)' style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedStartMoTime | async}}</p>
            </button>
        </span>
        <span id="startSpreadMo" class="hide">
            <button *ngFor = "let range of currentStartMoTime"(click)="updateStartTime(range,'1')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px; 
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleEndTime("1")'style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedEndMoTime | async}}</p>
            </button>
        </span>
        <span id="endSpreadMo" class="hide">
            <button *ngFor = "let range of currentEndMoTime"(click)="updateEndTime(range,'1')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px;
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>

        <!-- <span style="padding-right:50px;display:inline-block;overflow: scroll;vertical-align: top;padding-bottom: 7px;">
            <button style="display:block;width: 250px;">
                End Time
            </button>
        </span> -->
        <span style="display:inline-block;overflow: scroll;vertical-align: top;"(click)='addTime3("1")'>
            <button style="display:block;border: none;background-color: transparent;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                (+) Add Time
            </button>
        </span>
        <div style="display:block;margin-top: -8px;"></div>
        <div style="display:inline-block;width: 674px;
        padding: 20px;
        background-color: #C3C3C3;
        border-radius: 10px;overflow: scroll;margin-left:10px;margin-top: -3px;margin-bottom: 20px;height: 106px;">
            <span *ngIf = 'mondaySelectedTimes.length === 0' style="color: rgba(30, 86, 99, 1);
            font-family: var(--font-family-inter);
            font-weight: 500;">
                No Time Selected
            </span>
            <button  *ngFor = "let mondayTime of mondaySelectedTimes" style="border-radius: 6px;margin-right: 4px;background-color: white;border: 1px solid lightgrey;vertical-align: top;">
                <img (click)="removeTime('1',mondayTime)"  style = 'height: 18px; width: 18px; margin-left: 145px;'src ='https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png'>
                <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 42px;
                margin-top: -23px;
                padding-bottom: 0px;
                margin-bottom: 0px;">{{formatAvailableTime(mondayTime)}}</p>
            </button>
        </div>
        <!-- <span style="display: none;" id = 'startRange'>
            <button *ngFor = "let range of currentStartTime"(click)="updateStartTime(range)"style="display:block;width: 281px;   border-radius: 10px;
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{formatTime(range)}}
            </button>
        </span>
        <span id = 'endRange' style="display: none;">
            <button *ngFor = "let range of currentEndTime"(click)="updateEndTime(range)"style="display:block;width: 281px;   border-radius: 10px;
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{formatTime(range)}}
            </button>
        </span> -->
    </div>

    <div class="timeSelect">
        <div style="display:inline-block;vertical-align: top;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;margin-left: 10px;
        margin-right: 10px;width: 95px;">Tuesday's</div> 
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleStartTime2("2")' style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedStartTuTime | async}}</p>
            </button>
        </span>
        <span id="startSpreadTu" class="hide">
            <button *ngFor = "let range of currentStartTuTime"(click)="updateStartTime(range,'2')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px; 
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleEndTime("2")'style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedEndTuTime | async}}</p>
            </button>
        </span>
        <span id="endSpreadTu" class="hide">
            <button *ngFor = "let range of currentEndTuTime"(click)="updateEndTime(range,'2')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px;
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>

        <!-- <span style="padding-right:50px;display:inline-block;overflow: scroll;vertical-align: top;padding-bottom: 7px;">
            <button style="display:block;width: 250px;">
                End Time
            </button>
        </span> -->
        <span style="display:inline-block;overflow: scroll;vertical-align: top;"(click)='addTime3("2")'>
            <button style="display:block;border: none;background-color: transparent;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                (+) Add Time
            </button>
        </span>
        <div style="display:block;margin-top: -8px;"></div>

        <div style="display:inline-block;
        width: 674px;
        padding: 20px;
        background-color: #C3C3C3;
        border-radius: 10px;overflow: scroll;margin-left:10px;margin-top: -3px;margin-bottom: 20px;height: 106px;">
            <span *ngIf = 'tuesdaySelectedTimes.length === 0' style="color: rgba(30, 86, 99, 1);
            font-family: var(--font-family-inter);
            font-weight: 500;">
                No Time Selected
            </span>
            <button  *ngFor = "let tuesdayTime of tuesdaySelectedTimes" style="border-radius: 6px;margin-right: 4px;background-color: white;border: 1px solid lightgrey;vertical-align: top;">
                <img (click)="removeTime('2',tuesdayTime)"  style = 'height: 18px; width: 18px; margin-left: 145px;'src ='https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png'>
                <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 42px;
                margin-top: -23px;
                padding-bottom: 0px;
                margin-bottom: 0px;">{{formatAvailableTime(tuesdayTime)}}</p>
            </button>
        </div>
        
    </div>

    <div class="timeSelect">
        <div style="display:inline-block;vertical-align: top;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;margin-left: 10px;
        margin-right: 10px;width: 95px;">Wednesday's</div> 
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleStartTime2("3")' style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedStartWeTime | async}}</p>
            </button>
        </span>
        <span id="startSpreadWe" class="hide">
            <button *ngFor = "let range of currentStartWeTime"(click)="updateStartTime(range,'3')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px; 
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleEndTime("3")'style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedEndWeTime | async}}</p>
            </button>
        </span>
        <span id="endSpreadWe" class="hide">
            <button *ngFor = "let range of currentEndWeTime"(click)="updateEndTime(range,'3')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px;
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>

        <!-- <span style="padding-right:50px;display:inline-block;overflow: scroll;vertical-align: top;padding-bottom: 7px;">
            <button style="display:block;width: 250px;">
                End Time
            </button>
        </span> -->
        <span style="display:inline-block;overflow: scroll;vertical-align: top;"(click)='addTime3("3")'>
            <button style="display:block;border: none;background-color: transparent;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                (+) Add Time
            </button>
        </span>
        <div style="display:block;margin-top: -8px;"></div>

        <div style="display:inline-block;
        width: 674px;
        padding: 20px;
        background-color: #C3C3C3;
        border-radius: 10px;overflow: scroll;margin-left:10px;margin-top: -3px;margin-bottom: 20px;height: 106px;">
            <span *ngIf = 'wednesdaySelectedTimes.length === 0' style="color: rgba(30, 86, 99, 1);
            font-family: var(--font-family-inter);
            font-weight: 500;">
                No Time Selected
            </span>
            <button  *ngFor = "let wednesdayTime of wednesdaySelectedTimes" style="border-radius: 6px;margin-right: 4px;background-color: white;border: 1px solid lightgrey;vertical-align: top;">
                <img (click)="removeTime('3',wednesdayTime)"  style = 'height: 18px; width: 18px; margin-left: 145px;'src ='https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png'>
                <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 42px;
                margin-top: -23px;
                padding-bottom: 0px;
                margin-bottom: 0px;">{{formatAvailableTime(wednesdayTime)}}</p>
            </button>
        </div>
    </div>

    <div class="timeSelect">
        <div style="display:inline-block;vertical-align: top;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;margin-left: 10px;
        margin-right: 10px;width: 95px;">Thursday's</div> 
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleStartTime2("4")' style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedStartThTime | async}}</p>
            </button>
        </span>
        <span id="startSpreadTh" class="hide">
            <button *ngFor = "let range of currentStartThTime"(click)="updateStartTime(range,'4')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px; 
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleEndTime("4")'style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedEndThTime | async}}</p>
            </button>
        </span>
        <span id="endSpreadTh" class="hide">
            <button *ngFor = "let range of currentEndThTime"(click)="updateEndTime(range,'4')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px;
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>

        <!-- <span style="padding-right:50px;display:inline-block;overflow: scroll;vertical-align: top;padding-bottom: 7px;">
            <button style="display:block;width: 250px;">
                End Time
            </button>
        </span> -->
        <span style="display:inline-block;overflow: scroll;vertical-align: top;"(click)='addTime3("4")'>
            <button style="display:block;border: none;background-color: transparent;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                (+) Add Time
            </button>
        </span>
        <div style="display:block;margin-top: -8px;"></div>

        <div style="display:inline-block;
        width: 674px;
        padding: 20px;
        background-color: #C3C3C3;
        border-radius: 10px;overflow: scroll;margin-left:10px;margin-top: -3px;margin-bottom: 20px;height: 106px;">
            <span *ngIf = 'thursdaySelectedTimes.length === 0' style="color: rgba(30, 86, 99, 1);
            font-family: var(--font-family-inter);
            font-weight: 500;">
                No Time Selected
            </span>
            <button  *ngFor = "let thursdayTime of thursdaySelectedTimes" style="border-radius: 6px;margin-right: 4px;background-color: white;border: 1px solid lightgrey;vertical-align: top;">
                <img (click)="removeTime('4',thursdayTime)"  style = 'height: 18px; width: 18px; margin-left: 145px;'src ='https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png'>
                <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 42px;
                margin-top: -23px;
                padding-bottom: 0px;
                margin-bottom: 0px;">{{formatAvailableTime(thursdayTime)}}</p>
            </button>
        </div>
    </div>

    <div class="timeSelect">
        <div style="display:inline-block;vertical-align: top;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;margin-left: 10px;
        margin-right: 10px;width: 95px;">Friday's</div> 
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleStartTime2("5")' style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedStartFrTime | async}}</p>
            </button>
        </span>
        <span id="startSpreadFr" class="hide">
            <button *ngFor = "let range of currentStartFrTime"(click)="updateStartTime(range,'5')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px; 
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleEndTime("5")'style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedEndFrTime | async}}</p>
            </button>
        </span>
        <span id="endSpreadFr" class="hide">
            <button *ngFor = "let range of currentEndFrTime"(click)="updateEndTime(range,'5')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px;
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>

        <!-- <span style="padding-right:50px;display:inline-block;overflow: scroll;vertical-align: top;padding-bottom: 7px;">
            <button style="display:block;width: 250px;">
                End Time
            </button>
        </span> -->
        <span style="display:inline-block;overflow: scroll;vertical-align: top;"(click)='addTime3("5")'>
            <button style="display:block;border: none;background-color: transparent;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                (+) Add Time
            </button>
        </span>
        <div style="display:block;margin-top: -8px;"></div>

        <div style="display:inline-block;
        width: 674px;
        padding: 20px;
        background-color: #C3C3C3;
        border-radius: 10px;overflow: scroll;margin-left:10px;margin-top: -3px;margin-bottom: 20px;height: 106px;">
            <span *ngIf = 'fridaySelectedTimes.length === 0' style="color: rgba(30, 86, 99, 1);
            font-family: var(--font-family-inter);
            font-weight: 500;">
                No Time Selected
            </span>
            <button  *ngFor = "let fridayTime of fridaySelectedTimes" style="border-radius: 6px;margin-right: 4px;background-color: white;border: 1px solid lightgrey;vertical-align: top;">
                <img (click)="removeTime('5',fridayTime)"  style = 'height: 18px; width: 18px; margin-left: 145px;'src ='https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png'>
                <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 42px;
                margin-top: -23px;
                padding-bottom: 0px;
                margin-bottom: 0px;">{{formatAvailableTime(fridayTime)}}</p>
            </button>
        </div>
    </div>
    <div class="timeSelect">
        <div style="display:inline-block;vertical-align: top;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;margin-left: 10px;
        margin-right: 10px;width: 95px;">Saturday's</div> 
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleStartTime2("6")' style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedStartSaTime | async}}</p>
            </button>
        </span>
        <span id="startSpreadSa" class="hide">
            <button *ngFor = "let range of currentStartSaTime"(click)="updateStartTime(range,'6')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px; 
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleEndTime("6")'style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedEndSaTime | async}}</p>
            </button>
        </span>
        <span id="endSpreadSa" class="hide">
            <button *ngFor = "let range of currentEndSaTime"(click)="updateEndTime(range,'6')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px;
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>

        <!-- <span style="padding-right:50px;display:inline-block;overflow: scroll;vertical-align: top;padding-bottom: 7px;">
            <button style="display:block;width: 250px;">
                End Time
            </button>
        </span> -->
        <span style="display:inline-block;overflow: scroll;vertical-align: top;"(click)='addTime3("6")'>
            <button style="display:block;border: none;background-color: transparent;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                (+) Add Time
            </button>
        </span>
        <div style="display:block;margin-top: -8px;"></div>

        <div style="display:inline-block;
        width: 674px;
        padding: 20px;
        background-color: #C3C3C3;
        border-radius: 10px;overflow: scroll;margin-left:10px;margin-top: -3px;margin-bottom: 20px;height: 106px;">
            <span *ngIf = 'saturdaySelectedTimes.length === 0' style="color: rgba(30, 86, 99, 1);
            font-family: var(--font-family-inter);
            font-weight: 500;">
                No Time Selected
            </span>
            <button  *ngFor = "let saturdayTime of saturdaySelectedTimes" style="border-radius: 6px;margin-right: 4px;background-color: white;border: 1px solid lightgrey;vertical-align: top;">
                <img (click)="removeTime('6',saturdayTime)"  style = 'height: 18px; width: 18px; margin-left: 145px;'src ='https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png'>
                <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 42px;
                margin-top: -23px;
                padding-bottom: 0px;
                margin-bottom: 0px;">{{formatAvailableTime(saturdayTime)}}</p>
            </button>
        </div>
    </div>
    <div class="timeSelect">
        <div style="display:inline-block;vertical-align: top;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;margin-left: 10px;
        margin-right: 10px;width: 95px;">Sunday's</div> 
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleStartTime2("0")' style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedStartSuTime | async}}</p>
            </button>
        </span>
        <span id="startSpreadSu" class="hide">
            <button *ngFor = "let range of currentStartSuTime"(click)="updateStartTime(range,'0')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px; 
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>
        <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-left: 10px;">
            <button (click) = 'toggleEndTime("0")'style="display:block;width: 200px;height:40px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;">
                <p style="text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;overflow:scroll">{{selectedEndSuTime | async}}</p>
            </button>
        </span>
        <span id="endSpreadSu" class="hide">
            <button *ngFor = "let range of currentEndSuTime"(click)="updateEndTime(range,'0')"style="display:block;width: 173px;       margin-left: 13px;
            margin-top: 7px;border-radius: 10px;
            border: none;
            background-color: #B1E7F3;
            color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                {{range}}
            </button>
        </span>

        <!-- <span style="padding-right:50px;display:inline-block;overflow: scroll;vertical-align: top;padding-bottom: 7px;">
            <button style="display:block;width: 250px;">
                End Time
            </button>
        </span> -->
        <span style="display:inline-block;overflow: scroll;vertical-align: top;"(click)='addTime3("0")'>
            <button style="display:block;border: none;background-color: transparent;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;">
                (+) Add Time
            </button>
        </span>
        <div style="display:block;margin-top: -8px;"></div>

        <div style="display:inline-block;
        width: 674px;
        padding: 20px;
        background-color: #C3C3C3;
        border-radius: 10px;overflow: scroll;margin-left:10px;margin-top: -3px;margin-bottom: 20px;height: 106px;">
            <span *ngIf = 'sundaySelectedTimes.length === 0' style="color: rgba(30, 86, 99, 1);
            font-family: var(--font-family-inter);
            font-weight: 500;">
                No Time Selected
            </span>
            <button  *ngFor = "let sundayTime of sundaySelectedTimes" style="border-radius: 6px;margin-right: 4px;background-color: white;border: 1px solid lightgrey;vertical-align: top;">
                <img (click)="removeTime('0',sundayTime)"  style = 'height: 18px; width: 18px; margin-left: 145px;'src ='https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png'>
                <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 42px;
                margin-top: -23px;
                padding-bottom: 0px;
                margin-bottom: 0px;">{{formatAvailableTime(sundayTime)}}</p>
            </button>
        </div>
    </div>
</div>
