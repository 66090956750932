
<div class="container-center-horizontal">
    <input  required type="file" class="custom-file-input" (change) = "storeFile($event)" accept = ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"id="inputGroupFile0123" style="display: none;">
    <div class="frame-483 screen">
      <div class="overlap-group1"id = 'two'>
          
        <span id="inviteParent2" style="display: none;">
            <span style="display: inline-block;vertical-align: top;">
                <p style="    color: rgba(30, 86, 99, 1);
                font-family: var(--font-family-inter);
                font-weight: 500; 
                font-size: 33px;
                margin-left: 30px;
                margin-top: 98px;
                 ">Invite A Friend For Free!</p>
                <p style="color: rgba(30, 86, 99, 1);
                font-family: var(--font-family-inter);
                font-weight: 500;
                font-size: 17px;
                margin-left: 30px;
                margin-top: 20px;
                width: 300px;
                line-height: 36px;
                 ">Invite a friend at no extra cost for your first 3 Verified Sessions. We believe the only thing better than learning, is learning with a friend! Just enter an email, press 'confirm', and let us handle the rest. </p>
                <span style="padding-right: 22px;display:inline-block;overflow: scroll;vertical-align: top; height: 69px;padding-top: 10px;padding-left: 10px;">
                    <input ngModel name="fn22" #fn22 ="ngModel" (change) = 'emailToShareWith = fn22.value' style="display:block;width: 300px;height:45px;margin-left: 18px;box-shadow: 4px 3px 16px #e5e5e5;border: none; background-color: white;border-radius: 10px;text-align: left;padding-left: 6px;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;" placeholder="Email">
                </span>
                <button (click) = 'closeParentInvite()'style = "position: absolute;top: 503px;left:0px;height: 51px;width: 100%;border-bottom-right-radius: 10px;border-bottom-left-radius: 10px;color: white;font-family: var(--font-family-inter);font-weight: 500;background-color: rgba(30, 86, 99, 1); border: none;">Confirm Email</button>
            </span>
            <span style="display: inline-block;">
                <img
    
                (click) = 'closeParentInvite()'
                data-id="1535:6765"
                style="    width: 20px;
                position: absolute;
                left: 815px;
                top: 12px;"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png"
              />
                <img style="mix-blend-mode: normal;object-fit: cover; width: 385px;margin-top:57px;margin-left: 31px;" src="../../../assets/Group 527.svg">
            </span>
            
        </span>
        <div class="group-457">
          <img
            class="x"
            (click) = 'closeMe()'
            data-id="1535:6765"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png"
          />
        </div>
        <h1 class="title worksans-semi-bold-abbey-32px">Update You Appointment</h1>
        <div id = 'chosenTimes' class="chosenTimes">
            <span id = 'noChosenTimesCart'>
                <p style="    color: rgba(30, 86, 99, 1);
                font-family: var(--font-family-inter);
                font-weight: 500;
                padding-left: 10px; display: none;">Start By Choosing A Time...</p>
            </span>
            <span *ngFor = 'let range of listOfSelectedTimes' id = 'range[0]' style="display: inline-block;width:174px;height:58px;border-radius:10px;background-color:#B1E7F3;margin-top: 19px;margin-left: 15px;">
                <img (click) = 'addBackSelectedTimes(range)' style='height: 18px;
                width: 18px;
                margin-left: 145px;' src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png">
                <p style="    margin-left: 10px;
                margin-top: -47px;
                color: rgba(30, 86, 99, 1);
                font-family: var(--font-family-inter);
                font-weight: 500;overflow: scroll;width: 132px;">{{formatDate(range.split('|-|')[0])}}</p>
                <p style="    margin-top: -18px;
                margin-left: 10px;
                color: rgba(30, 86, 99, 1);
                font-family: var(--font-family-inter);
                font-weight: 500;overflow: scroll;">{{formatHourMin(range.split('|-|')[0])}} - {{formatHourMin(range.split('|-|')[1])}}</p>
            </span>
        </div>
        <div id = 'studentProfile2Title' class="studentSelectTitle">
            <p class="text-4">Who Is This Session For?</p>
        </div>
        <div id = 'studentProfile2' class="studentSelect">
            
            <span id ='{{range[0]}}CartSelect' *ngFor = "let range of students" style="display:inline-block;padding:7px;padding-right: 20px;padding-left: 20px;border-radius: 25px;" (click) = 'selectStudent(range)'>
                <img id ='{{range[0]}}Cart' src="../../../assets/Giraf.png" style="border-radius: 35px;display: block;align-self: flex-end;height: 70px;mix-blend-mode: normal;object-fit: cover;width: 70px;">
                <p style="text-align: center;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 34px;">{{range[2]}}</p> 
            </span>

        </div>
        <div id = 'preSessionInfo2' class="preSessionInfo">
            <!-- <textarea style="width: 422px;height: 150px;"></textarea>-->
            <p class="text-3">What Is This Session For?</p>
            <textarea class="overlap-group3"  placeholder="What is Lorem Ipsum?
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." ngModel name="fn333" #fn333 ="ngModel" (change) = 'messageToTeacher = fn333.value' ></textarea>
            <span style="position: absolute;
            top: 106px;
            height: 42px;
            margin-top: 28px;
            width: 410px;
            margin-left: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            border: none;
            background-color:  #C6C6C6;
            text-align: right;">
            <span style="    width: 313px;
            height: 36px;
            border-radius: 10px;
            position: absolute;
            left: 17px;overflow:scroll;text-align: left;">
                <span *ngFor='let file of uploadedFiles' 
                style="        height: 26px;
                margin-top: -5px;
                border: 1px solid #F3F3F3;
                display: inline-block;
                border-radius: 10px;
                margin-right: 6px;
                ">
                <p style="color: rgba(30, 86, 99, 1);
                font-family: var(--font-family-inter);
                font-weight: 500;
                font-size: 13px;
                top: -4px;
                padding-right: 7px;
                padding-left: 7px;
                margin-top: -9px;">
                    {{file.name}}
                </p>
                </span>  
            </span>
            <img style = 'width: 20px;margin-right: 10px;'src = '../../../assets/camera.svg'>
            <img  (click) ='uploadFileToggle()'  src = '../../../assets/document.svg' style="width: 20px;margin-right: 10px;z-index:1;cursor: pointer;">
        </span>
        </div>
        <div id = 'inviteParent' class="inviteParent">
            <span style="display:block;overflow: scroll;margin-top: 10px;">
                <button style="display:block;border: none;background-color: transparent;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;padding-left: 20px;margin-top: 10px;" (click) = 'addParentEmail()'>
                   (+) Add Parent To Invite
                </button>
            </span>
        </div>

        <button (click) ='addToCart()'style = "position: absolute;top: 438px;height: 51px;width: 100%;border-bottom-right-radius: 10px;border-bottom-left-radius: 10px;color: white;font-family: var(--font-family-inter);font-weight: 500;background-color: rgba(30, 86, 99, 1); border: none;">Confirm Changes</button>
      </div>
    </div>
  </div>

