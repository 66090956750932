import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-classroom-snapshot',
  templateUrl: './classroom-snapshot.component.html',
  styleUrls: ['./classroom-snapshot.component.scss']
})
export class ClassroomSnapshotComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
