<div class="container-center-horizontal">
    <div class="lobby screen" data-id="1276:4978">
      <div class="overlap-group1" data-id="an|rJqcc71k">
        <h1 class="text-1-1 worksans-semi-bold-abbey-32px" data-id="1276:5447">Waiting room for your online classroom</h1>
        <div class="flex-row" data-id="an|SqzVo5W4">
          <div class="flex-col" data-id="an|pfGamEHU">
            <div class="group-457" data-id="1276:5448">
              <div id ='avatarDisp'class="group-458 turtle" data-id="1280:5487">
                <!-- <div id = 'local' class = 'group-458-2'>
                    <div id = 'flipFeed'>
                        <webcam [height]="302" [width]="456" [trigger]="triggerObservable" (imageCapture)="upload($event)" *ngIf="showWebcam"
                        [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                        [videoOptions]="videoOptions"
                        [imageQuality]="1"
                        (cameraSwitched)="cameraWasSwitched($event)"
                        (initError)="handleInitError($event)"
                        ></webcam>  
                    </div>
                </div> -->
                <!-- <div class="frame-75" data-id="1276:5408">
                  <div class="group-51" data-id="1276:5410">
                    <img
                      class="mic"
                      data-id="I1276:5410;93:2"
                      src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/mic@2x.png"
                    />
                  </div>
                  <div class="group-50" data-id="1276:5409" style="z-index: 1;cursor:pointer"(click) = 'toggleLiveVideo()'>
                    <img
                      *ngIf = 'videoFeedOn'
                      class="video-on"
                      data-id="I1276:5409;80:26"
                      src="../../../assets/video.svg"
                    />
                    <img
                    *ngIf = '!videoFeedOn'
                    class="video-off"
                    data-id="I1276:5409;80:26"
                    src="../../../assets/video-off.svg"
                  />
                  </div>
                </div> -->
              </div>
            </div>
            <div class="group-462" data-id="1291:5249">
              <img
                style="cursor: pointer;z-index: 1;"
                (click) = 'firstRow()'
                class="chevron-left"
                data-id="1280:5560"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/chevron-left@2x.png"
              />
              <div class="group-461" data-id="1280:5510">
                <img
                  id = 'avatarSlot1'
                  style="z-index:1;cursor:pointer"
                  (click)='updateAvatar(1)'
                  class="rectangle-48"
                  data-id="1280:5506"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-48@2x.png"
                />
                <img
                  id = 'avatarSlot2'
                  style="z-index:1;cursor:pointer"
                  (click)='updateAvatar(2)'
                  class="rectangle"
                  data-id="1280:5508"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-49@2x.png"
                />
                  <img
                  id = 'avatarSlot4'
                  (click)='updateAvatar(4)'
                  style="display: none;"
                  class="rectangle-48"
                  data-id="1280:5506"
                  src="../../../assets/Giraf.svg"
                />
                <img
                  id = 'avatarSlot5'
                  (click)='updateAvatar(5)'
                  style="display: none;"
                  class="rectangle"
                  data-id="1280:5508"
                  src="../../../assets/Cat.svg"
                />
                <img 
                id = 'avatarSlot6'
                (click)='updateAvatar(6)'
                style="display: none;"
                class="rectangle"
                data-id="1280:5508"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png"
                />
                <img
                  style="z-index:1;cursor:pointer"
                  (click)='updateAvatar(3)'
                  id = 'avatarSlot3'
                  class="rectangle"
                  data-id="1280:5509"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-50@2x.png"
                />
              </div>
              <img
                style="cursor: pointer;z-index: 1;"
                (click) = 'secondRow()'
                class="chevron-left-1"
                data-id="1280:5562"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/chevron-left-1@2x.png"
              />
            </div>
          </div>
          <div class="flex-col-1" data-id="an|zE54PxhE">
            <div class="group-460" data-id="1280:5507">
              <div class="frame-351" data-id="1280:5469">
                <div class="name inter-semi-bold-chathams-blue-16px" data-id="1280:5470">Camera</div>
                <div class="group-314" data-id="1280:5471">
                  <div class="frame-353" data-id="1280:5473">
                    <p class="text body" data-id="1280:5474">Frontal Camera HD</p>
                    <img
                      class="frame-205"
                      data-id="1280:5475"
                      src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/frame-205@2x.png"
                    />
                  </div>
                </div>
              </div>
              <div class="frame-352" data-id="1280:5478">
                <div class="mic-1 inter-semi-bold-chathams-blue-16px" data-id="1280:5479">Mic</div>
                <div class="group-314" data-id="1280:5480">
                  <div class="frame-353" data-id="1280:5482">
                    <p class="text body" data-id="1280:5483">Inter Micro HD</p>
                    <img
                      class="frame-205"
                      data-id="1280:5484"
                      src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/frame-205@2x.png"
                    />
                  </div>
                </div>
              </div>
              <!-- <div class="flex-row-1" data-id="an|MN8E8GC2">
                <div class="group-458-1" data-id="1280:5491">
                  <div class="test-micro worksans-semi-bold-chathams-blue-14px" data-id="1280:5492">Test Micro</div>
                  <img
                    class="line-4"
                    data-id="1280:5493"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/line-4@2x.png"
                  />
                </div>
                <div class="group-459" data-id="1280:5505">
                  <img
                    class="music"
                    data-id="1280:5501"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/music@2x.png"
                  />
                  <div class="overlap-group" data-id="an|JHkr42u9">
                    <div class="rectangle-161" data-id="1280:5495"></div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="frame-442" data-id="1280:5521">
              <p class="text-4 inter-semi-bold-chathams-blue-16px" data-id="1280:5511">
                Choose The Color Of Your Classroom’s Task Bar
              </p>
              <div class="frame-462" data-id="1280:5520">
                <div id = 'circle1' style = 'z-index: 1;cursor:pointer;border: 4px solid var(--chathams-blue);' (click) = 'logBarColor(1)' class="ellipse-89" data-id="1280:5512"></div>
                <div id = 'circle2' style = 'z-index: 1;cursor:pointer;' (click) = 'logBarColor(2)' class="ellipse-90" data-id="1280:5513"></div>
                <div id = 'circle3' style = 'z-index: 1;cursor:pointer;' (click) = 'logBarColor(3)' class="ellipse-95" data-id="1280:5518"></div>
                <div id = 'circle5' style = 'z-index: 1;cursor:pointer;' (click) = 'logBarColor(5)' class="ellipse-91" data-id="1280:5514"></div>
                <div id = 'circle6' style = 'z-index: 1;cursor:pointer;' (click) = 'logBarColor(6)' class="ellipse-94" data-id="1280:5517"></div>
                <div id = 'circle7' style = 'z-index: 1;cursor:pointer;' (click) = 'logBarColor(7)' class="ellipse-93" data-id="1280:5516"></div>
                <div id = 'circle8' style = 'z-index: 1;cursor:pointer;' (click) = 'logBarColor(8)' class="ellipse-96" data-id="1290:5218"></div>
              </div>
            </div>
            <div class="frame-443" data-id="1280:5522">
              <div class="text-5 inter-semi-bold-chathams-blue-16px" data-id="1280:5523">Choose The Pencil Color</div>
              <div id = 'pens' class="frame-462-1" style = 'z-index: 1;cursor: url({{currentPecil}}), auto ;'>
                <img id = 'Pen1a' style='margin-right: 15px;' src="../../../assets/vector-32@2x.svg" (click) = 'togglePen("1","../../../assets/vector-32@2x.svg")'/>
                <img id = 'Pen2a'style='margin-right: 15px;'src="../../../assets/Vector (1).svg" (click) = 'togglePen("2","../../../assets/Vector (1).svg")'/>  
                <img id = 'Pen3a'style='margin-right: 15px;'src="../../../assets/Vector (2).svg" (click) = 'togglePen("3","../../../assets/Vector (2).svg")'/>
                <img id = 'Pen4a'style='margin-right: 15px;'src="../../../assets/Vector (3).svg" (click) = 'togglePen("4","../../../assets/Vector (3).svg")'/>
                <img id = 'Pen5a'style='margin-right: 15px;'src="../../../assets/Vector (4).svg" (click) = 'togglePen("5",".../../../assets/Vector (4).svg")'/>
                <img id = 'Pen6a'style='margin-right: 15px;'src="../../../assets/Vector (5).svg" (click) = 'togglePen("6","../../../assets/Vector (5).svg")'/>   
                <img id = 'Pen7a'style='margin-right: 15px;'src="../../../assets/Vector (6).svg" (click) = 'togglePen("7","../../../assets/Vector (6).svg")'/>
                <img id = 'Pen8a'style='margin-right: 15px;'src="../../../assets/Vector (7).svg" (click) = 'togglePen("8","../../../assets/Vector (7).svg")'/>
                <img id = 'Pen9a'style='margin-right: 15px;'src="../../../assets/Vector (8).svg" (click) = 'togglePen("9","../../../assets/Vector (8).svg")'/>
                <img id = 'Pen10a' src="../../../assets/Vector (9).svg" (click) = 'togglePen("10","../../../assets/Vector (9).svg")'/>           
              </div>
              <!-- <img
                class="frame-462-1"
                data-id="1280:5524"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/frame-462@2x.png"
              /> -->
            </div>
          </div>  
        </div>
        <div class="frame-355" id = 'B2' data-id="1291:5247" style="display: none;" (click) = 'EnterClassRoom()'>
          <div class="place worksans-semi-bold-concrete-14px" data-id="1291:5248">Enter</div>
        </div>
        <div class="frame-355"id = 'B1'  data-id="1291:5247" style="z-index: 1;cursor:pointer">
          <div class="place worksans-semi-bold-concrete-14px" data-id="1291:5248">Loading Classroom...</div>
        </div>
      </div>
    </div>
  </div>
