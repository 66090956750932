import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import {ActivatedRoute} from '@angular/router'
import {AngularFireDatabase} from '@angular/fire/database'
import {AngularFireStorage} from '@angular/fire/storage'
import { ignoreElements } from 'rxjs/operators';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import moment from 'moment-timezone';
import Calendar from 'tui-calendar'; /* ES6 */

@Component({ 
  selector: 'app-update-teacher-info',    
  templateUrl: './update-teacher-info.component.html',
  styleUrls: ['./update-teacher-info.component.scss']
})   
export class UpdateTeacherInfoComponent implements OnInit {
  UID:string;   
  currentTitles = ""; 
  longIntro = "empty"
  shortIntro = "empty"
  grades = ""
  gradesDisplay = []   
  days = ""
  chosenService = [];  
  @Output() currentDesc = new EventEmitter<any>();
  @Output() teacherInfo = new EventEmitter<any[]>();

  mondayUnselectedTimes = []
  tuesdayUnselectedTimes = []
  wednesdayUnselectedTimes = []
  thursdayUnselectedTimes = []
  fridayUnselectedTimes = []
  saturdayUnselectedTimes = []
  sundayUnselectedTimes = []

  sundaySelectedTimes = []
  mondaySelectedTimes = []
  tuesdaySelectedTimes = []
  wednesdaySelectedTimes = []
  thursdaySelectedTimes = []
  fridaySelectedTimes = []
  saturdaySelectedTimes = []

  currentStartRange = []

  sundayOpenS = false
  mondayOpenS = false
  tuesdayOpenS = false
  wednesdayOpenS = false
  thursdayOpenS = false
  fridayOpenS = false
  saturdayOpenS = false

  sundayOpenE = false
  mondayOpenE = false
  tuesdayOpenE = false
  wednesdayOpenE = false
  thursdayOpenE = false
  fridayOpenE = false
  saturdayOpenE = false
  
  
  listOfSelectedTimes = []
  calendar:any = {}
  
  path:string = "";
  updatedServiceDesc = ""     
  newRate =-1;
  newFuture =-1;
  succMessages = [] 
  policyMessages = []
  perSuber = ''
  error = []
  customTopics = ''
  customTopicsList = []
  policisMsg = []

  gradeSet = 'General'
  topicSet= 'General'
  
  @Output() ct1 = new EventEmitter<any>();
  
  @Output() policy1 = new EventEmitter<any>();
  @Output() policy2 = new EventEmitter<any>();
  @Output() policy3 = new EventEmitter<any>();
  @Output() policy4 = new EventEmitter<any>();
  @Output() policy5 = new EventEmitter<any>();
  @Output() policy6 = new EventEmitter<any>();
  @Output() policy7 = new EventEmitter<any>();

  pol1
  pol2
  pol3
  pol4
  pol5
  pol6
  pol7

  @Output() currentRate = new EventEmitter<any>();
  @Output() currentFuture = new EventEmitter<any>();
  @Output() currentShort = new EventEmitter<any>();
  @Output() currentLong = new EventEmitter<any>();

  @Output() doneLoading = new EventEmitter<any>();
  calendarHolder2:Calendar;

  constructor(private router:ActivatedRoute, private db: AngularFireDatabase,private afm: AngularFireStorage) { }

  async ngOnInit(){
    this.doneLoading.emit(false)
    this.UID = this.router.snapshot.params.UID
    console.log(this.UID)
    var serviceHolder = ""
    var gradeHolder = ""
    var dayHolder = "" 
    var rateHolder = ""  
    var futureHolder = ""
    var longHolder = ""
    var shortHolder = ""    

    this.getProPic()

    // this.calendarHolder2 = new Calendar('#calendar3', {
    //   defaultView: 'month',
    //   // taskView: true,    // Can be also ['milestone', 'task']
    //   // scheduleView: true,  // Can be also ['allday', 'time']
    //   taskView: [],
    //   scheduleView: ['time'],
    //   useCreationPopup: false,
    //   useDetailPopup: false,
    //   // bgColor: 'red',
    //   // borderColor: 'calColor',
    //   // color: 'calColor',
    //   theme:{
    //     'common.holiday.color': 'rgba(30, 86, 99, 1)',
    //     'month.holidayExceptThisMonth.color': 'rgba(30, 86, 99, 1)',
    //     // 'week.currentTimeLinePast.border': 'none',
    //     // 'week.currentTimeLineBullet.backgroundColor': 'transparent',
    //     // 'week.currentTimeLineToday.border': 'none',
    //     // 'week.currentTimeLineFuture.border': 'none',
    //   },
      
    //   timezone: {
    //     zones: [
    //       {  
    //         timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
    //       }  
    //     ],
    //     offsetCalculator: function(timezoneName, timestamp){
    //       // matches 'getTimezoneOffset()' of Date API
    //       // e.g. +09:00 => -540, -04:00 => 240
  
    //       // console.log(moment.tz.zone(timezoneName).utcOffset(timestamp))
    //       return moment.tz.zone(timezoneName).utcOffset(timestamp);
    //     },
    //   },   
    //   template: {
    //       time: function(schedule) {
    //         // this conrols what goes in the schdule 
    //           return ' <i class="fa fa-refresh personal-info" style = " color: var(--chathams-blue);font-family: var(--font-family-inter);font-size: var(--font-size-m); font-style: normal;font-weight: 600;position: absolute;top: 43%;">Booked</i>';
    //       },
    //       timegridCurrentTime: function(timezone: any) {
    //         return null;
    //       },
    //       // weekDayname: function(model){
    //       //   var days = ['Sunday\'s','Monday\'s','Tuesday\'s','Wednesday\'s','Thursday\'s','Friday\'s','Saturday\'s'];
    //       //   return '<span class=\"tui-full-calendar-dayname-name\"style =\"font-size:15px;padding-left: 10px;\">' + days[model.day] + '</span>';
    //       // },
    //   },
    //   month: {
    //       daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    //       startDayOfWeek: 0,
    //       // narrowWeekend: true
    //   },
    //   week: {
    //       daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    //       startDayOfWeek: 0,
    //       narrowWeekend: false,
    //       hourStart:5,
    //   }
    // });

    await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/rate/val').once('value').then(function(snap){
      console.log(snap.val())
      rateHolder  = snap.val()
    })
    console.log(rateHolder)
    
    this.currentRate.emit(rateHolder)
    this.newRate = Number(rateHolder)

    await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/future/val').once('value').then(function(snap){
      console.log(snap.val())
      futureHolder  = snap.val()
    })
    this.currentFuture.emit(futureHolder)
    
    var hldArr = []
    await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/avail').once('value').then(snap => {
      if(snap.exists()){
        var hl  = {0:'',1:'',2:'',3:'',4:'',5:'',6:''}
        snap.forEach(date=>{
          hl[date.key] = date.val()
        })
       this.teacherInfo.emit([this.UID,hl])

      }else{
        this.teacherInfo.emit([this.UID,{0:'',1:'',2:'',3:'',4:'',5:'',6:''}])
      }
    })

    await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/longIntro/val').once('value').then(function(snap){
      console.log(snap.val())
      longHolder  = snap.val()
    })
    this.currentLong.emit(longHolder)
    
    await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/shortIntro/val').once('value').then(function(snap){
      console.log(snap.val())
      shortHolder  = snap.val()
    })
    this.currentShort.emit(shortHolder)

    await this.db.database.ref('providers/userInfo/' + this.UID + '/services/currentTitles/val').once('value').then(function(snap){
        console.log(snap.val())
        serviceHolder = snap.val()
    })
    

    this.currentTitles = serviceHolder
    await this.db.database.ref('providers/userInfo/' + this.UID + '/services/grades/val').once('value').then(function(snap){
      console.log(snap.val())
      gradeHolder = snap.val()
    })

    this.grades = gradeHolder
    var hold
    this.grades.split(",").forEach(val=>{
      document.getElementById(val).style.background = '#b0e6f3'
    })

    this.currentTitles.split(",").forEach(val=>{
      document.getElementById(val).style.background = '#b0e6f3'
    })

    this.gradesDisplay = [this.grades]
    await this.db.database.ref('providers/userInfo/' + this.UID + '/services/activeDays/val').once('value').then(function(snap){
        console.log(snap.val())
        dayHolder = snap.val()
    })

    this.customTopics = dayHolder
    this.customTopicsList = dayHolder.split(',')
    this.ct1.emit(this.customTopicsList)

    this.days = dayHolder
    var gracePeriod 
    var general 
    var noShow
    var i36_24
    var i23_12  
    var i11_2
    var i2
    
    await this.db.database.ref('providers/userInfo/' + this.UID + '/policy').once('value').then(function(snap){
      snap.forEach(function(snapp){
        if(snapp.key === 'grace'){
          gracePeriod = snapp.child('val').val()
        }else if(snapp.key === 'generalFee'){
          general  = snapp.child('val').val()
        }else if(snapp.key === 'noShow'){
          noShow = snapp.child('val').val()
        }else if(snapp.key === '36_24'){
          i36_24 = snapp.child('val').val()
        }else if(snapp.key === '24_12'){
          i23_12 = snapp.child('val').val()
        }else if(snapp.key === '12_2'){
          i11_2 = snapp.child('val').val()
        }else if(snapp.key === '2_0'){
          i2 = snapp.child('val').val()
        }
      })
    })

    this.policy1.emit(Number(gracePeriod))
    this.pol1 = Number(gracePeriod)
    this.policy2.emit(Number(general))
    this.pol2 = Number(general)
    this.policy7.emit(Number(noShow))
    this.pol7 = Number(noShow)
    this.policy3.emit(Number(i36_24))
    this.pol3 = Number(i36_24)
    this.policy4.emit(Number(i23_12))
    this.pol4 = Number(i23_12)
    this.policy5.emit(Number(i11_2))
    this.pol5 = Number(i11_2)
    this.policy6.emit(Number(i2))       
    this.pol6 = Number(i2) 
    this.doneLoading.emit(true)
      
  } 
  addTime(){
    // console.log(spread)
    // var dateKey = spread[1].split('T')[0]
    // console.log(dateKey)
    // var timeFramesToFix = []
    // var found = false
    // var indexCounter = 0
    // var index = 0

    // this.calendar[dateKey].forEach(timeFrame=>{
    //   console.log(spread)
    //   console.log(timeFrame)
    //   if(moment(spread[1]).isSameOrAfter(timeFrame.start) && moment(spread[1]).isSameOrBefore(timeFrame.end)){
    //     timeFramesToFix.push([indexCounter,timeFrame])
    //   }else if(moment(spread[2]).isSameOrAfter(timeFrame.start) && moment(spread[2]).isSameOrBefore(timeFrame.end)){
    //     timeFramesToFix.push([indexCounter,timeFrame])
    //   }
    //   if(moment(timeFrame.start).isAfter(spread[2]) && !found){
    //     found = true
    //     if(indexCounter > 0){
    //       indexCounter -=1
    //     }
    //     index = indexCounter
    //   }
    //   indexCounter += 1
    // })
    // if(!found){
    //   found = true
    //   index = indexCounter
    // }
    // if(timeFramesToFix.length === 0){
    //   var hlder = {'start':spread[1],'end':spread[2]}
    //   if(index = 0){
    //     index = 0
    //   }else{
    //     index -= 1
    //   }
    //   this.weekOfAvailabilities[dateKey].splice(index,0,hlder)
    // }else{
    //   //turn all these values to one that is the first ones star time and the last ones end time
    //   if(index = 0){
    //     index = 0
    //   }else{
    //     index -= 1
    //   }
    //   console.log(timeFramesToFix)
    //   var offset = 0
    //   timeFramesToFix.forEach(val=>{
    //     console.log(Number(val[0]))
    //     this.weekOfAvailabilities[dateKey].splice(Number(val[0])-offset,1)
    //     offset += 1
        
    //   })
    //   console.log(this.weekOfAvailabilities[dateKey])
    //   var tfHld = [...timeFramesToFix]
    //   var hlder2 = [index,{'start':spread[1],'end':spread[2]} ]
    //   tfHld.splice(index,0,hlder2)
    //   console.log(tfHld[0])
    //   console.log(tfHld[tfHld.length-1])
    //   hlder = {'start':tfHld[0][1].start,'end':tfHld[tfHld.length-1][1].end}
      
    //   var firstIndex = timeFramesToFix[0][0]

    //   this.weekOfAvailabilities[dateKey].splice(firstIndex,0,hlder)
    //   console.log(this.weekOfAvailabilities[dateKey])
    // }
    
    // var startDate = moment(this.lastStartDate.format())
    // var endDate = moment(this.lastEndDate.format())

    // var openings = []

    // if(startDate.date() === endDate.date() && startDate.month() === endDate.month() && startDate.year() === endDate.year()){
    //   var key = startDate.toISOString(true).split('T')[0]
    //   var hold = {}
    //   hold[key] = this.weekOfAvailabilities[key]
    //   openings.push(hold)
    //   // openings.push(hold)          
    // }else{
    //   if(startDate.isBefore(endDate)){
    //     while(startDate.isSameOrBefore(endDate)){
    //       var dateTracker = startDate.toISOString(true).split('T')[0]
    //       if(dateTracker in this.weekOfAvailabilities){
    //         var hold = {}
    //         hold[dateTracker] = this.weekOfAvailabilities[dateTracker]
    //         openings.push(hold)
            
    //       }
    //       startDate.add(24,'hours')
    //     }
    //   }else{
    //     console.log('error avoided: infinite loop')
    //   }
    // }
    // this.selectedTimeFrame.emit(openings)

  }

  // removeTime(spread){
  //   console.log(spread,this.weekOfAvailabilities)
  //   var startTime = spread.split('|-|')[0]
  //   var endTime = spread.split('|-|')[1]
  //   var dateKey = startTime.split('T')[0]
  //   var weekOfAvailabilitiesHld = []
  //   var indexHld
  //   var counter = 0
  //   console.log(this.weekOfAvailabilities[dateKey])
  //   this.weekOfAvailabilities[dateKey].forEach(frames=>{
  //     var hldStart = frames['start']
  //     var hldEnd = frames['end']
  //     if(moment(startTime).isSameOrAfter(hldStart) && moment(startTime).isSameOrBefore(hldEnd)){
  //       console.log(startTime,endTime,hldStart,hldEnd,frames)
  //       weekOfAvailabilitiesHld = [startTime,endTime,hldStart,hldEnd,frames,counter]
  //     }
  //     counter += 1
  //   })
  //   console.log(weekOfAvailabilitiesHld)
  //   console.log(this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]])

  //   // var newStart1 = ''
  //   // var newStart2 = ''
  //   // var newEnd1 = ''
  //   // var newEnd2 = ''

  //   if(moment(weekOfAvailabilitiesHld[0]).diff(weekOfAvailabilitiesHld[2],'minutes') === 0){
  //     console.log(1)
  //     if(moment(weekOfAvailabilitiesHld[1]).isBefore(moment(weekOfAvailabilitiesHld[3]))){
  //       var newStart = moment(weekOfAvailabilitiesHld[1]).format()
  //       var newEnd = moment(weekOfAvailabilitiesHld[3]).format()
  //       console.log(this.weekOfAvailabilities[dateKey])
  //       this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['start'] = newStart
  //       this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['end'] = newEnd
  //       console.log(this.weekOfAvailabilities[dateKey])
  //     }else{
  //       this.weekOfAvailabilities[dateKey].splice(weekOfAvailabilitiesHld[5],1)
  //     }
  //   }else if(moment(weekOfAvailabilitiesHld[1]).diff(weekOfAvailabilitiesHld[3],'minutes') === 0){
  //     console.log(2)
  //     if(moment(weekOfAvailabilitiesHld[2]).isBefore(moment(weekOfAvailabilitiesHld[0]))){
  //       var newStart = moment(weekOfAvailabilitiesHld[2]).format()
  //       var newEnd = moment(weekOfAvailabilitiesHld[0]).format()
  //       console.log(this.weekOfAvailabilities[dateKey])
  //       this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['start'] = newStart
  //       this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['end'] = newEnd
  //       console.log(this.weekOfAvailabilities[dateKey])
  //     }else{
  //       this.weekOfAvailabilities[dateKey].splice(weekOfAvailabilitiesHld[5],1)
  //     }
  //   }else{
  //     // creates 2 pieces 
  //     console.log(3)
  //     var startTime1 = moment(weekOfAvailabilitiesHld[2]).format()
  //     var endTime1 = moment(weekOfAvailabilitiesHld[0]).format()
  //     var startTime2 = moment(weekOfAvailabilitiesHld[1]).format()
  //     var endTime2 = moment(weekOfAvailabilitiesHld[3]).format()


  //     this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['start'] = startTime1
  //     this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['end'] = endTime1
  //     var hlder = {'start':startTime2,'end':endTime2}
  //     console.log(this.weekOfAvailabilities[dateKey])
  //     this.weekOfAvailabilities[dateKey].splice(weekOfAvailabilitiesHld[5]+1,0,hlder)
  //     console.log(this.weekOfAvailabilities[dateKey])

  //   }
    
  //   var startDate = moment(this.lastStartDate.format())
  //   var endDate = moment(this.lastEndDate.format())

  //   var openings = []
  //   console.log(startDate,endDate)
  //   console.log(startDate.isBefore(endDate),startDate.format(),endDate.format())
  //   if(startDate.date() === endDate.date() && startDate.month() === endDate.month() && startDate.year() === endDate.year()){
  //     var key = startDate.toISOString(true).split('T')[0]
  //     var hold = {}
  //     hold[key] = this.weekOfAvailabilities[key]
  //     openings.push(hold)
  //     // openings.push(hold)          
  //   }else{
  //     console.log(startDate.isBefore(endDate),startDate.format(),endDate.format())
  //     if(startDate.isBefore(endDate)){
  //       while(startDate.isSameOrBefore(endDate)){
  //         var dateTracker = startDate.toISOString(true).split('T')[0]
  //         if(dateTracker in this.weekOfAvailabilities){
  //           var hold = {}
  //           hold[dateTracker] = this.weekOfAvailabilities[dateTracker]
  //           openings.push(hold)
            
  //         }
  //         startDate.add(24,'hours')
  //       }
  //     }else{
  //       console.log('error avoided: infinite loop')
  //     }
  //   }
  //   console.log(openings)
  //   this.selectedTimeFrame.emit(openings)
  // }
  initTeacherSchedule(calendar: {},) {
    //calendar is an array with key representaing day of week
    //{0:'...',3:'...',5:'...'}
    if('0' in calendar){
      var time = moment(+ new Date()).hour(0).minute(0).second(0)
      this.sundaySelectedTimes = String(calendar['0']).split('|')
      String(calendar['0']).split('|').forEach((range:String)=>{
        var endMoment = moment(+ new Date()).hour(Number(range.split('-')[0].split(':')[0])).minute(Number(range.split('-')[0].split(':')[1])).second(0)
        this.sundayUnselectedTimes.push(time.toISOString(true) + '-' + endMoment.toISOString(true))
        console.log(this.sundayUnselectedTimes)
        time = moment(+ new Date()).hour(Number(range.split('-')[1].split(':')[0])).minute(Number(range.split('-')[1].split(':')[1])).second(0)
        // var endMoment = moment(+ new Date()).hour(range.split('-')[0].split(':')[0]).minute(range.split('-')[0].split(':')[1]).second(0)
        // while(time.isSameOrBefore(range.split('-')[0])){

        // }
      })
      this.sundayUnselectedTimes = String(calendar['0']).split('|')
      
    }else{

      this.sundayUnselectedTimes = ['00:00:00-00:00:00']
    }

    if('1' in calendar){
      this.mondayUnselectedTimes = String(calendar['1']).split('|')
    }else{
      // console.log('12am-12am')
      // var startMoment = moment(+ new Date()).hour(0).minute(0).second(0)
      // var endMoment = moment(+ new Date()).hour(0).minute(0).second(0)
      // endMoment.add(24,'hours')
      // console.log(startMoment.format('hh:mm A'))
      this.mondayUnselectedTimes =['00:00:00-00:00:00']
    }

    if('2' in calendar){
      this.tuesdayUnselectedTimes=String(calendar['2']).split('|')
    }else{
      this.tuesdayUnselectedTimes = ['00:00:00-00:00:00']
    }

    if('3' in calendar){
      this.wednesdayUnselectedTimes=String(calendar['3']).split('|')
    }else{
      this.wednesdayUnselectedTimes=['00:00:00-00:00:00']
    }

    if('4' in calendar){
      this.thursdayUnselectedTimes=String(calendar['4']).split('|')
    }else{
      this.thursdayUnselectedTimes=['00:00:00-00:00:00']
    }

    if('5' in calendar){
      this.fridayUnselectedTimes=String(calendar['5']).split('|')
    }else{
      this.fridayUnselectedTimes=['00:00:00-00:00:00']
    }

    if('6' in calendar){
      this.saturdayUnselectedTimes=String(calendar['6']).split('|')
    }else{
      this.saturdayUnselectedTimes=['00:00:00-00:00:00']
    }
  }

  async removeCustSubs(val){
    this.customTopicsList = await this.customTopicsList.filter(vals => vals !== val)
    var holdStr = ''
    this.customTopicsList.forEach(tal=>{
      if(holdStr === ''){
        holdStr = tal
      }else{
        holdStr += ',' + tal
      }
    })
    this.customTopics = holdStr
    this.ct1.emit(this.customTopicsList)
    this.updateCustSub(this.customTopics)
  }

  async changeCustSubs(){ 

    if(!this.customTopics.includes(`${this.perSuber}`)){
      console.log('boom', this.perSuber,this.customTopics)
      if(this.customTopics === ''){
        this.customTopics = `${this.perSuber}`
        this.updateCustSub(this.customTopics)
      }else{
        this.customTopics += ',' + `${this.perSuber}`
        this.updateCustSub(this.customTopics)
      }      
      this.customTopicsList.unshift(`${this.perSuber}`)
      console.log('boom', this.perSuber,this.customTopics)
      this.ct1.emit(this.customTopicsList)
    }else{
      //double up
    }
  }

  formatUnselectedTime(mondayTime){
    var startTimeHour = mondayTime.split('-')[0].split(':')[0]
    var startTimeMin = mondayTime.split('-')[0].split(':')[1]
    var endTimeHour = mondayTime.split('-')[0].split(':')[0]
    var endTimeMin = mondayTime.split('-')[0].split(':')[1]
    var startMoment = moment(+ new Date()).hour(startTimeHour).minute(startTimeMin).second(0)
    var endMoment = moment(+ new Date()).hour(endTimeHour).minute(endTimeMin).second(0)
    return startMoment.format('hh:mm A') + '-' + endMoment.format('hh:mm A')
  }

  clickUpload(){
    document.getElementById('imageUploader').click()
  }

  async updateCustSub(newList){
    await this.db.database.ref('providers/userInfo/' + this.UID + '/services/activeDays').set({
      val:newList
    }).catch(e=>{console.log(e)})
  }

 
  changeRate(rate,type){
    if(rate >= 0){
      if(type === 1){
        this.pol1 = rate
      }else if(type === 2){
        this.pol2 = rate
      }else if(type === 3){
        this.pol3 = rate
      }else if(type === 4){
        this.pol4 = rate
      }else if(type === 5){
        this.pol5 = rate
      }else if(type === 6){
        this.pol6 = rate
      }else if(type === 7){
        this.pol7 = rate
      }
      this.policyMessages = []
    }else{
      this.policyMessages = ["rate can't be negative"]
    } 
  }

  changeFuture(ftr){
    if(ftr >= 0){
      this.newFuture = Number(ftr)
      this.policyMessages = []
    }else{
      this.policyMessages = ["days ahead can't be negative"]
    }
  }

  async updatePolicy(){
    var holdRate = this.newRate
    var holdFuture = this.newFuture
    console.log(holdRate)
    console.log(holdFuture)
    var succmessages = ""
    if(this.newFuture === -1 && this.newRate === -1){
      // nothing to update
    }else{
      if(this.newRate !== -1){
        await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/rate').set({
          val:holdRate
        })
        succmessages += "rate updated!"
      }
      if(this.newFuture !== -1){
        await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/future').set({
          val:holdFuture
        })
        succmessages += " future boooking span updated!"
      }
    }
    this.policyMessages = [succmessages]
  }

  async storeShortIntro(short:string){
    this.shortIntro = short
  }
  


  async getLong():Promise<string>{
    var hold;
    await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/longIntro/val').once('value').then(function(snap){
      hold = snap.val()
    })
    return hold 
  }

  async storeLongIntro(long:string){
    this.longIntro = long
    console.log(long)
    
  }

  async updateShortIntro(){
    var holdShort = this.shortIntro
    var ret = ""
    if(holdShort !== "empty"){
      await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/shortIntro').set({
        val:holdShort
      })
      ret += "short intro updated!"
    }
    this.succMessages = [ret]

  }

  async updateLongIntro(){
    var holdLong = this.longIntro
    var ret = ""
    if(holdLong !== "empty"){
      await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/longIntro').set({
        val:holdLong
      })
      ret += " long intro updated!"
    }
    this.succMessages = [ret]
  }

  // async updatePersonalInfo(){
  //   var holdShort = this.shortIntro
  //   var holdLong = this.longIntro
  //   var ret = ""
  //   console.log("ehllo")
  //   console.log("ehllo")
  //   if(holdShort !== "empty"){
  //     console.log("ehllo")
  //     await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/shortIntro').set({
  //       val:holdShort
  //     })
  //     ret += "short intro updated!"
  //   }
  //   if(holdLong !== "empty"){
  //     await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/longIntro').set({
  //       val:holdLong
  //     })
  //     ret += " long intro updated!"
  //   }
  //   this.succMessages = [ret]

  // }

  // var gracePeriod 
  // var general 
  // var noShow
  // var i36_24
  // var i23_12  
  // var i11_2
  // var i2
  
  async updatePolicies(){
    this.policisMsg = []
    await this.db.database.ref('providers/userInfo/' + this.UID + "/policy").set({
      '12_2':{val: `${this.pol5}`},
      '24_12':{val: `${this.pol4}`},
      '2_0':{val: `${this.pol6}`},
      '36_24':{val: `${this.pol3}`},
      'generalFee':{val: `${this.pol2}`},
      'grace':{val: `${this.pol1}`},
      'noShow':{val: `${this.pol7}`},
    }).then(()=>{
      this.db.database.ref('providers/userInfo/' + this.UID + '/profile/rate').set({
        val:this.newRate
      })
      this.policisMsg = ['Updated']
    })

  }

  async getProPic(){
    var teacherUID = this.UID
    console.log(this.UID)
    // Create a reference from a Google Cloud Storage URI
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/teacherPics/' + teacherUID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById('proPic').setAttribute('src',url);
    }).catch(e=>{
      console.log(e)    
    })      
  }

  upload($event){
    document.getElementById('proPic').setAttribute('src',URL.createObjectURL($event.target.files[0]));
    this.afm.upload("/teacherPics/" + this.UID, $event.target.files[0]).then(()=>{
      this.succMessages = ["profile picture updated"]
    })
  }

  addBackSelectedTimes(range){

  }

  toggleEndTime(){   

  }

  toggleStartTime(){

  }


  toggleStartTime2(day){
    console.log(day)
    if(Number(day) === 0){
      if(!this.sundayOpenS){
        document.getElementById('startSpread').setAttribute('class','sundayStart')
        // display possible start times for this day 
        var timeBetween = []
        if('0' in this.calendar){
          console.log(this.calendar)
          this.calendar[0].split('|').forEach(range=>{
            var startTime = moment(+ new Date()).hour(range.split('-')[0].split(':')[0]).minute(range.split('-')[0].split(':')[1]).second(0)
            var endTime = moment(+ new Date()).hour(range.split('-')[1].split(':')[0]).minute(range.split('-')[1].split(':')[1]).second(0)
            while(startTime.isSameOrBefore(endTime)){
              timeBetween.push(startTime.format())
              startTime.add(15,'minutes')
            } 
          })
        }else{
         // opening is all hours 
          var startTime = moment(+ new Date()).hour(0).minute(0).second(0)
          var endTime = moment(+ new Date()).hour(0).minute(0).second(0)
          endTime.add(24,'hours')
          while(startTime.isBefore(endTime)){
            timeBetween.push(startTime.format())
            startTime.add(15,'minutes')
          }
        }
        console.log(timeBetween)
        this.currentStartRange = timeBetween
      }else{
        document.getElementById('startSpread').setAttribute('class','hide')
      }
      this.sundayOpenS = !this.sundayOpenS

    }else if(Number(day) === 1){
      if(!this.mondayOpenS){
        document.getElementById('startSpread').setAttribute('class','mondayStart')
      }else{
        document.getElementById('startSpread').setAttribute('class','hide')
      }
      this.mondayOpenS = !this.mondayOpenS
    }else if(Number(day) === 2){
      if(!this.tuesdayOpenS){
        document.getElementById('startSpread').setAttribute('class','tuesdayStart')
      }else{
        document.getElementById('startSpread').setAttribute('class','hide')
      }
      this.tuesdayOpenS = !this.tuesdayOpenS
    }else if(Number(day) === 3){
      if(!this.wednesdayOpenS){
        document.getElementById('startSpread').setAttribute('class','wednesdayStart')
      }else{
        document.getElementById('startSpread').setAttribute('class','hide')
      }
      this.wednesdayOpenS = !this.wednesdayOpenS
    }else if(Number(day) === 4){
      if(!this.thursdayOpenS){
        document.getElementById('startSpread').setAttribute('class','thursdayStart')
      }else{
        document.getElementById('startSpread').setAttribute('class','hide')
      }
      this.thursdayOpenS = !this.thursdayOpenS
    }else if(Number(day) === 5){
      if(!this.fridayOpenS){
        document.getElementById('startSpread').setAttribute('class','fridayStart')
      }else{
        document.getElementById('startSpread').setAttribute('class','hide')
      }
      this.fridayOpenS = !this.fridayOpenS
    }else if(Number(day) === 6){
      if(!this.saturdayOpenS){
        document.getElementById('startSpread').setAttribute('class','saturdayStart')
      }else{
        document.getElementById('startSpread').setAttribute('class','hide')
      }
      this.saturdayOpenS = !this.saturdayOpenS
    }
  }
  updateTimeFrame(range){


  }

  formatTime(range){   
    console.log(range)
    if(range === 'T00:00:00 break'){
      var formatedTime = '-';
    }else{
      var formatedTime = moment(range).format("dddd, MMMM Do YYYY, h:mm a");
    }
    console.log(formatedTime)
    return formatedTime
  }

  async updateServiceDescription(){
    var hold = String(this.chosenService[0])
    var desc = this.updatedServiceDesc
    var succ = false
    await this.db.database.ref('providers/userInfo/' + this.UID + '/services').once('value').then(function(snap){
      snap.forEach(function(childSnapshot){
        if(childSnapshot.child('title').child('val').val() === hold){
          childSnapshot.child('desc').ref.set({
            val:desc
          })
          succ = true
        } 
      })
    })
    if(succ){
      this.currentDesc.emit(desc)
    }
  }
  
  storeDesc(desc){
    this.updatedServiceDesc = desc
  }

  async chosenTitle(title){
    this.chosenService = [title]
    var currentDesc;
    await this.db.database.ref('providers/userInfo/' + this.UID + '/services').once('value').then(function(snap){
      snap.forEach(function(snapp){
        if(snapp.child('title').child('val').val() === title){
          currentDesc = snapp.child('desc').child('val').val()
        }
      })
    })
    this.currentDesc.emit(currentDesc)
    
  }

  getNameOfGrade(grade){
    if(grade === "1_2"){
      return "(1st-2nd)"
    }else if(grade === "3_5"){
      return "(3rd-5th)"
    }else if(grade === "6_8"){
      return "(6th-8th)"
    }else{
      return "(9th-12th)"
    }
  }

  formatGrades(grades){
    var hold = grades.split(",")
    var ret = ""
    hold.forEach(grade =>{
      ret += this.getNameOfGrade(grade) + " "
    })
    return ret
  }



  present(title:string){
    var ret = false
    this.currentTitles.split(",").forEach(val =>{
      if(val === title){
        ret = true
      }
    })
    return ret
  }
  
  updateDayString(day){
    this.days = day
  }

  async checkGrade2(grade:string){
    var unique = true
    this.grades.split(",").forEach(grd=>{
      if(grade === grd){
        console.log("uig")
        unique = false
      }
    })
 
    if(unique){
      document.getElementById(grade).style.background = '#b0e6f3'
      this.addGrade(grade)
      //update personal profile with new grades list
      if(this.grades.split(",")[0] === ""){
        this.grades +=  grade
      }else{
        this.grades += "," + grade
      }
      await this.db.database.ref('providers/userInfo/' + this.UID + '/services/grades').set({
        val:this.grades
      })
    }else{
      //remove
      document.getElementById(grade).style.background = '#292F45'
      this.removeGrade(grade)
      
      
      //update personal profile with new grades list
      var locate = this.grades.split(",")
      if(locate.length === 1){
        this.grades = ""
      }else if(locate[0] === grade){
        this.grades = this.grades.replace(grade + ",","")
      }else if(locate[locate.length - 1] === grade){
        this.grades = this.grades.replace("," + grade ,"")
      }else{
        this.grades = this.grades.replace("," + grade + ",",",")
      }
      await this.db.database.ref('providers/userInfo/' + this.UID + '/services/grades').set({
        val:this.grades
      })
       
    }  
  }

  openCalendar(){
    document.getElementById('calendar3').setAttribute('style','height: 648px;overflow: hidden;width:694px;margin-top:58px;border-radius:10px')
  }

  //type 1:Grade 2:Subject
  async updateSearch(val,type){
    console.log(1)
    if(type === 1){
      console.log(2)
      var unique = true
      this.grades.split(",").forEach(grade=>{
        if(val === grade){
          unique = false
        }
      })
      if(unique){
        console.log(3)
        document.getElementById(val).style.background = '#b0e6f3'
        this.addGrade2(val)
        //update personal profile with new grades list
        if(this.grades.split(",")[0] === ""){
          this.grades +=  val
        }else{
          this.grades += "," + val
        }
        await this.db.database.ref('providers/userInfo/' + this.UID + '/services/grades').set({
          val:this.grades
        })
      }else{
        //remove
        console.log(4)
        document.getElementById(val).style.background = '#F3F3F3'
        this.removeGrade2(val)
        
        
        //update personal profile with new grades list
        var locate = this.grades.split(",")
        if(locate.length === 1){
          this.grades = ""
        }else if(locate[0] === val){
          this.grades = this.grades.replace(val + ",","")
        }else if(locate[locate.length - 1] === val){
          this.grades = this.grades.replace("," + val ,"")
        }else{
          this.grades = this.grades.replace("," + val + ",",",")
        }
        await this.db.database.ref('providers/userInfo/' + this.UID + '/services/grades').set({
          val:this.grades
        })
         
      }
    }else{
      console.log(5)
      var unique = true
      this.currentTitles.split(",").forEach(subject=>{
        if(val === subject){
          unique = false
        }
      })
      if(unique){
        console.log(6)
        console.log(val)
        document.getElementById(val).style.background = '#b0e6f3'
        this.addServices2(val)
        //update personal profile with new grades list
        if(this.currentTitles.split(",")[0] === ""){
          this.currentTitles +=  val
        }else{
          this.currentTitles += "," + val
        }
        await this.db.database.ref('providers/userInfo/' + this.UID + '/services/currentTitles').set({
          val:this.currentTitles
        })
      }else{
        //remove
        console.log(6)
        document.getElementById(val).style.background = '#F3F3F3'
        this.removeServices2(val)
        
        
        //update personal profile with new grades list
        var locate = this.currentTitles.split(",")
        if(locate.length === 1){
          this.currentTitles = ""
        }else if(locate[0] === val){
          this.currentTitles = this.currentTitles.replace(val + ",","")
        }else if(locate[locate.length - 1] === val){
          this.currentTitles = this.currentTitles.replace("," + val ,"")
        }else{
          this.currentTitles = this.currentTitles.replace("," + val + ",",",")
        }
        await this.db.database.ref('providers/userInfo/' + this.UID + '/services/currentTitles').set({
          val:this.currentTitles
        })
         
      }
    }
  }

  async removeServices2(subject:string){
    var dbRef = this.db
    if(!this.currentTitles.includes(',')){
      await this.grades.split(",").forEach(grade=>{
        dbRef.database.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}` +"/" + `${this.UID}`).remove()
      })
      this.updateSearch('General',2)
    }else{
      this.grades.split(",").forEach(grade=>{
        dbRef.database.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}` +"/" + `${this.UID}`).remove()
      })
    }
  }

  async addServices2(subject:string){
    var dbRef = this.db.database
    if(this.grades === ''){
      dbRef.ref('teacherSearch/General' + `${subject}`).update({[this.UID]:this.UID})
    }else{
      this.grades.split(",").forEach(grade=>{
        dbRef.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}`).update({[this.UID]:this.UID})
      })
    }
  }

  async removeGrade2(grade:string){
    var dbRef = this.db
    if(!this.grades.includes(',')){
      console.log(11)
      await this.currentTitles.split(",").forEach(subject=>{
        dbRef.database.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}` +"/" + `${this.UID}`).remove()
      })
      this.updateSearch('General Education',1)
    }else{
      console.log(22)
      this.currentTitles.split(",").forEach(subject=>{
        dbRef.database.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}` +"/" + `${this.UID}`).remove()
      })
    }
  }

  async addGrade2(grade:string){
    var dbRef = this.db.database
    if(this.currentTitles === ''){
      dbRef.ref('teacherSearch/' + `${grade}` + '/General').update({[this.UID]:this.UID})
    }else{
      this.currentTitles.split(",").forEach(subject=>{
        dbRef.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}`).update({[this.UID]:this.UID})
      })
    }
  }

  async checkGrade(grade:string){
    var unique = true
    this.grades.split(",").forEach(grd=>{
      if(grade === grd){
        console.log("uig")
        unique = false
      }
    })
 
    if(unique){
      document.getElementById(grade).style.background = '#b0e6f3'
      if(this.currentTitles !== "" && this.days !== ""){
        this.addGrade(grade)
      }
      //update personal profile with new grades list
      if(this.grades.split(",")[0] === ""){
        this.grades +=  grade
      }else{
        this.grades += "," + grade
      }
      await this.db.database.ref('providers/userInfo/' + this.UID + '/services/grades').set({
        val:this.grades
      })
    }else{
      //remove
      document.getElementById(grade).style.background = 'grey'
      this.removeGrade(grade)
      
      
      //update personal profile with new grades list
      var locate = this.grades.split(",")
      if(locate.length === 1){
        this.grades = ""
      }else if(locate[0] === grade){
        this.grades = this.grades.replace(grade + ",","")
      }else if(locate[locate.length - 1] === grade){
        this.grades = this.grades.replace("," + grade ,"")
      }else{
        this.grades = this.grades.replace("," + grade + ",",",")
      }
      await this.db.database.ref('providers/userInfo/' + this.UID + '/services/grades').set({
        val:this.grades
      })
       
    }
  }


  async addGrade(grade:string){
    this.currentTitles.split(",").forEach(title =>{
      this.days.split(",").forEach(day=>{
        this.db.database.ref('teacherSearch/' + title + '/' + grade+ '/'+ day).push(
          {
            uid: this.UID
          }
        )
      })
    })
  }

  async removeGrade(grade:string){
    var dbRef = this.db
    var UIDhold = this.UID
    this.currentTitles.split(",").forEach(title =>{
      this.days.split(",").forEach(day=>{
        console.log(day,title)
        this.db.database.ref('teacherSearch/' + title+ '/' + grade+ '/'+ day).once('value').then(function(sna){
          sna.forEach(function(s){
            console.log(s.child('uid').val())
            if(s.child('uid').val() === UIDhold){
              dbRef.database.ref('teacherSearch/' + title+ '/' + grade+ '/'+ day +"/" + s.key).remove()
            }
          })
        })
      })
    })
  }

  async checkService(service:string){
    var unique = true
    this.currentTitles.split(",").forEach(title=>{
      if(service === title){
        unique = false
      }
    })
    if(this.grades === "" || this.days === ""){
      this.error = ["first you have to pic a time and grade level"]
    }else if(!unique || this.currentTitles.split(",").length < 5){
      this.error = []  
      if(unique){
        if(this.grades !== "" && this.days !== ""){
          this.addService(service)
        }
        //update personal profile with new grades list
        if(this.currentTitles.split(",")[0] === ""){
          this.currentTitles += service 
        }else{
          this.currentTitles += "," + service 
        }
        await this.db.database.ref('providers/userInfo/' + this.UID + '/services/currentTitles').set({
          val:this.currentTitles
        })
      }else{
        //remove
        if(this.grades !== "" && this.days !== ""){
          this.removeService(service)
        }
        //update personal profile with new grades list
        if(this.currentTitles.split(",").length > 1){
          var locate = this.currentTitles.split(",")
          if(locate[0] === service){
            this.currentTitles = this.currentTitles.replace(service + ",","")
          }else if(locate[locate.length - 1] === service){
            this.currentTitles = this.currentTitles.replace("," + service ,"")
          }else{
            this.currentTitles = this.currentTitles.replace("," + service + ",",",")
          }
        }else{
          this.currentTitles = this.currentTitles.replace(service,"")
        }
  
        await this.db.database.ref('providers/userInfo/' + this.UID + '/services/currentTitles').set({
          val:this.currentTitles
        })
      }
    }else{
      this.error = ["We have a 5 service max, click an existing title to make space"]
    }

  }

  async addService(title){
    console.log(title)
    var holdUID = this.UID
    var added = false;
    var gradesHold = this.grades
    var daysHold = this.days
    var dbHold = this.db
    console.log(this.currentTitles, this.grades, this.days)
    await this.db.database.ref('providers/userInfo/' + this.UID + '/services').once('value').then(function(snap){
      snap.forEach(function(childSnapshot) {
        childSnapshot.forEach(function(snaper){
          if(snaper.key ==='title'){
            snaper.forEach(function(snappa){
              if((snappa.val() === 'empty' || snappa.val() === '') && !added){
                childSnapshot.ref.set({
                  title: {
                    val:title
                  },
                  desc: {
                    val:""
                  } 
                })
                gradesHold.split(",").forEach(grade =>{
                  daysHold.split(",").forEach(day=>{
                    console.log(day,grade)
                    dbHold.database.ref('teacherSearch/' + title+ '/' + grade+ '/'+ day).push(
                      {
                        uid: holdUID
                      }
                    )
                  })
                })
                added = true;
              }
            })
          }
        })
      });
    })
  }

  async removeService(title){
    if(this.chosenService[0] === title){
      this.chosenService = []
    }
    var added = false;
    var gradesHold = this.grades
    var daysHold = this.days
    var dbHold = this.db
    var holdUID = this.UID
    console.log(this.currentTitles, this.grades, this.days)
    await this.db.database.ref('providers/userInfo/' + this.UID + '/services').once('value').then(function(snap){
      snap.forEach(function(childSnapshot) {
        childSnapshot.forEach(function(snaper){
          if(snaper.key ==='title'){
            snaper.forEach(function(snappa){
              if(snappa.val() === title){
                childSnapshot.ref.set({
                  title: {
                    val:"empty"
                  },
                  desc: {
                    val:"empty"
                  } 
                })
                console.log("hello")
                gradesHold.split(",").forEach(grade =>{
                  daysHold.split(",").forEach(day=>{
                    console.log(day,grade)
                    dbHold.database.ref('teacherSearch/' + title+ '/' + grade+ '/'+ day).once('value').then(function(sna){
                      sna.forEach(function(s){
                        console.log(s.child('uid').val())
                        if(s.child('uid').val() === holdUID){
                          dbHold.database.ref('teacherSearch/' + title+ '/' + grade+ '/'+ day +"/" + s.key).remove()
                        }
                      })
                    })
                  })
                })
              }
            })
          }
        })
      });
    })
  }

  catChange(title:string){
    var add = true;
    if(this.currentTitles.split(",").length < 9){
      this.currentTitles.split(",").forEach(curr=>{
        if(title === curr){
          add = false
        }
      })
      if(add){
        this.addService(title)
      }else{
        this.removeService(title)
      }
    }else{
      // you have to chose a service to remove
    }
    
  }





  log(msg){
    console.log(msg);
  }
}
