import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { storageFile } from 'src/app/models/storageFile';
import { AuthService } from 'src/app/services/auth.service';
// import { getDocument, GlobalWorkerOptions, PDFDocumentProxy, PDFRenderParams, version, ViewportParameters } from 'pdfjs';
import { getDocument,GlobalWorkerOptions} from 'pdfjs-dist';


@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})   
export class FileUploaderComponent implements OnInit {
  teacher = false
  path:string = ""
  UID = ''
  loadedFiles:storageFile[] = []
  pageNumber = 0  
  topic = false
  subtopic = false 
  topics = ['Practice Problems', 'Board Templates', 'Fun Pics', 'General']
  subtopics = ['Math', 'English', 'Social Studies', 'World Language', 'Creative Arts', 'Fun', 'General']

  // pdfjs = await import('pdfjs-dist/build/pdf');
  // pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');

    
  fileName = ''
  fileDesc = ''
  fileTopic = 'General'
  fileSubtopic = 'General'

  currentlySelected = 0

  @Output() close = new EventEmitter<any[]>();


  constructor(private db: AngularFireDatabase,private afm: AngularFireStorage,public auth: AuthService) { }

  async ngOnInit(){   
    await this.auth.user$.subscribe(usr=>{   
      // GlobalWorkerOptions.workerSrc = '"//mozilla.github.io/pdf.js/build/pdf.worker.js"'
      this.UID = usr.uid
      var teacherHold = false
      this.db.database.ref('providers/userInfo/' +  `${usr.uid}`).once('value').then(function(snap){
        if(snap.exists()){ 
          teacherHold = true
        } 
      }).then(()=>{
        this.teacher = teacherHold
      })
    })
    document.getElementById('drop_zone8').addEventListener('drop',event=>{
      this.dropHandler(event)
    })
    document.getElementById('drop_zone8').addEventListener('dragover',event=>{
      document.getElementById('drop_zone8').setAttribute('class','group-458 border-8px-blue')
      this.dragOverHandler(event)
    })
    document.getElementById('drop_zone8').addEventListener('dragleave',()=>{
      document.getElementById('drop_zone8').setAttribute('class','group-458 border-1px-blue')
    })
    document.getElementById('drop_zone8').addEventListener('click',()=>{
      document.getElementById('inputGroupFile0001').click()
    })
 
  }

  updateName(name){
    this.loadedFiles[this.currentlySelected].name = name
  }
  updateDesc(desc){
    this.loadedFiles[this.currentlySelected].desc = desc
  }

  closeMe(){
    this.close.emit([])
  }

  updateTopic(services){
    this.loadedFiles[this.currentlySelected].topic = services
    this.fileTopic = services
  }

  updateSubtopic(services){
    this.loadedFiles[this.currentlySelected].subtopic = services
    this.fileSubtopic = services
  }



  restFileDisplay(){
    document.getElementById('box1').setAttribute('style','display:none')
    document.getElementById('image1').setAttribute('style','display:none')
    document.getElementById('image1').setAttribute('src','')
    document.getElementById('x1').setAttribute('style','display:none')

    document.getElementById('box2').setAttribute('style','display:none')
    document.getElementById('image2').setAttribute('style','display:none')
    document.getElementById('image2').setAttribute('src','')
    document.getElementById('x2').setAttribute('style','display:none')

    document.getElementById('box3').setAttribute('style','display:none')
    document.getElementById('image3').setAttribute('style','display:none')
    document.getElementById('image3').setAttribute('src','')
    document.getElementById('x3').setAttribute('style','display:none')

    document.getElementById('box4').setAttribute('style','display:none')
    document.getElementById('image4').setAttribute('style','display:none')
    document.getElementById('image4').setAttribute('src','')
    document.getElementById('x4').setAttribute('style','display:none')


  }

  clearHighlightBox(){
    document.getElementById('box1').setAttribute('style','display:none')
    document.getElementById('box2').setAttribute('style','display:none')
    document.getElementById('box3').setAttribute('style','display:none')
    document.getElementById('box4').setAttribute('style','display:none')
  }

  highlight(slot){
    document.getElementById('dropShip').setAttribute('style','display:none')
    this.topic = false
    this.subtopic = false
    this.fileDesc = ''
    this.fileName = ''
    this.fileTopic = ''
    this.fileSubtopic = ''
    this.clearHighlightBox()
    this.currentlySelected = (this.pageNumber*4 + slot) - 1
    if(this.loadedFiles[this.currentlySelected].desc === ''){
      this.fileDesc = 'empty'
    }else{
      this.fileDesc = this.loadedFiles[this.currentlySelected].desc
    }

    this.fileName = this.loadedFiles[this.currentlySelected].name
    this.fileTopic = this.loadedFiles[this.currentlySelected].topic
    this.fileSubtopic = this.loadedFiles[this.currentlySelected].subtopic

    document.getElementById('box' + `${slot}`).setAttribute('style','')
  }

  deleteFile(num){
    var deleteIndex = (this.pageNumber * 4) + num
    this.loadedFiles.splice(deleteIndex-1,1)
    if(this.loadedFiles.length === 0){
      this.fileName = ''
    }
    this.displayNewFiles()
  }

  displayNewFiles(){
    var start = this.pageNumber * 4
    var end = this.loadedFiles.length
    var currCount = 0
    this.restFileDisplay()
    while(start < end){
      if(currCount === 0){   
        document.getElementById('box1').setAttribute('style','')
        document.getElementById('image1').setAttribute('style','')
        if(this.loadedFiles[start].file.type.includes('image/')){
          document.getElementById('image1').setAttribute('src',URL.createObjectURL(this.loadedFiles[start].file))
        }else{
          document.getElementById('image1').setAttribute('src','../../../assets/document.svg')
        }
        document.getElementById('x1').setAttribute('style','')
        var fileName = this.loadedFiles[start].name
        var fileDesc = this.loadedFiles[start].desc
        var fileTopic = this.loadedFiles[start].topic
        var fileSubtopic = this.loadedFiles[start].subtopic
        this.fileName = fileName
        this.fileDesc = fileDesc
        this.fileSubtopic = fileSubtopic
        this.fileTopic = fileTopic

      }else if(currCount === 1){
        document.getElementById('image2').setAttribute('style','')
        if(this.loadedFiles[start].file.type.includes('image/')){
          document.getElementById('image2').setAttribute('src',URL.createObjectURL(this.loadedFiles[start].file))
        }else{
          document.getElementById('image2').setAttribute('src','../../../assets/document.svg')
        }
        document.getElementById('x2').setAttribute('style','')
      }else if(currCount === 2){
        document.getElementById('image3').setAttribute('style','')
        if(this.loadedFiles[start].file.type.includes('image/')){
          document.getElementById('image3').setAttribute('src',URL.createObjectURL(this.loadedFiles[start].file))
        }else{
          document.getElementById('image3').setAttribute('src','../../../assets/document.svg')
        }
        document.getElementById('x3').setAttribute('style','')
      }else if(currCount === 3){  
        document.getElementById('image4').setAttribute('style','')
        if(this.loadedFiles[start].file.type.includes('image/')){
          document.getElementById('image4').setAttribute('src',URL.createObjectURL(this.loadedFiles[start].file))
        }else{
          document.getElementById('image4').setAttribute('src','../../../assets/document.svg')
        }
        document.getElementById('x4').setAttribute('style','')
      }
      currCount += 1  
      start += 1
    }
  }

  prevPage(){
    if(this.pageNumber > 0){
      this.pageNumber -= 1
      this.currentlySelected = (this.pageNumber*4) 
      this.clearHighlightBox()
      this.displayNewFiles()
    }
  } 

  nextPage(){
    var end = Math.ceil(this.loadedFiles.length / 4)   
    if(this.pageNumber < end-1){
      this.pageNumber += 1
      this.currentlySelected = (this.pageNumber*4) 
      this.clearHighlightBox()
      this.displayNewFiles()
    }
  }

  logTopic(){  
    if(this.topic === true){
      this.topic = false
      document.getElementById('dropShip').setAttribute('style','display:none')
    }else{
      this.subtopic = false
      this.topic = true
      document.getElementById('dropShip').setAttribute('style','')
    }
  }

  logSubtopic(){
    if(this.subtopic === true){
      this.subtopic = false
      document.getElementById('dropShip').setAttribute('style','display:none')
    }else{
      this.subtopic = true
      this.topic = false
      document.getElementById('dropShip').setAttribute('style','')
    }
  }

  dragOverHandler(ev) {      
    // console.log('File(s) in drop zone');
  
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  dropHandler(ev) {
    // Prevent default behavior (Prevent file from being opened)
    document.getElementById('drop_zone8').setAttribute('class','group-458 border-1px-blue')
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      var TS = Number(+ new Date())
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          TS += 1 
          var file:File = ev.dataTransfer.items[i].getAsFile();
          var newFile = new storageFile('',TS,file.name,'','General','General',file)
          this.loadedFiles.unshift(newFile)
          // this.upload(file)
        }
      }
      this.displayNewFiles()
    } else {
      // Use DataTransfer interface to access the file(s)
      var TS = Number(+ new Date())
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        TS += 1
        var file:File = ev.dataTransfer.items[i].getAsFile();
        var newFile = new storageFile('',TS,file.name,'','General','General',file)
        this.loadedFiles.unshift(newFile)
      }
      this.displayNewFiles()
    }
  }

  storeFile(event){
    var files = event.target.files
    var fileName = files[0].name
    // var extension = files[0].type
    var TS = + new Date()
    var newFile = new storageFile('',TS,fileName,'','General','General',event.target.files[0])
    this.loadedFiles.unshift(newFile)
    this.displayNewFiles()
  }

  up(){
    var TS = Number(+ new Date())
    this.loadedFiles.forEach(file=>{
      TS += 1
      this.upload(file.file,file.name,file.desc,file.topic,file.subtopic,file.file.type,TS)
    })
    this.loadedFiles = []
    this.clearHighlightBox()
    this.restFileDisplay()
    this.closeMe()
  }

  // convertPDF(file){
  //   getDocument(file).promise.then(doc=>{
  //     console.log(doc)
  //   })

  // }

  upload($event,name,desc,topic,subtopic,type,TSHold){
    setTimeout(() => {
      this.path = $event
      var TS = TSHold
      console.log("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path)

      var key = this.UID + '-' + TS
      if(this.teacher){
        console.log(this.path)
        this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path).then(e=>{
          // if($event.type.includes('pdf')){
          //   e.ref.getDownloadURL().then(URL=>{
          //     this.convertPDF(URL)
          //   })
          // }
          this.db.database.ref('providers/userInfo/' + this.UID + '/storage').update({[key]:{'type':'PERSONAL','value':TS,'name':name,'desc':desc,'topic':topic,'subtopic':subtopic}}).then(()=>{
            this.db.database.ref('providers/userInfo/' + this.UID + '/').update({'newStorage':key +'/'+name + '/' + type})
          })
             
        })
      }else{
        this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path).then(()=>{
          this.db.database.ref('users/' + this.UID + '/storage').update({[key]:{'type':'PERSONAL','value':TS,'name':name,'desc':desc,'topic':topic,'subtopic':subtopic}}).then(()=>{
            this.db.database.ref('users/' + this.UID + '/').update({'newStorage':key +'/'+name + '/' + type})
          })
        })
      }
    }, 0);    
  }


}
