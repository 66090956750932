export class comment{
    name:string;
    date:string;
    comment:string;
    stars: number;
    
    constructor(name,date,comment,stars){
        this.name = name
        this.date = date
        this.comment = comment
        this.stars = stars
    }
}