<div class="container-center-horizontal" style="overflow-x: hidden;">
    <div class="sign-up screen">
      <div style = 'display: none;' id ='studentProfile'>
        <app-create-student-account id = 'createNewStudent'(newStudentEmitter) = 'addNewStudent($event)'></app-create-student-account>
      </div>
      <div class="overlap-group" >
        <div class="overlap-group1" id ='parentProfile'>

          <img
            class="rectangle-96"
            src="../../../assets/Group 322.png"
          />
          <div class="frame-75" style = 'z-index: 1;cursor: pointer;'  (click) = 'signUp()'><div class="sign-up-2">Final Step</div></div>
          <div class="form">
            <div class="flex-row">
              <div class="flex-col-2">
                <div class="group-158">
                  <div class="place">Name</div>
                  <div class="overlap-group6">
                    <!-- <input type="email" class="name inter-normal-gunsmoke-14px-2" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Name"autocomplete="off"> -->
                    <input maxLength = "25" minLength = "2" type="text" class = "text-7 inter-normal-steel-teal-14px"ngModel name="fn1" #fn1 ="ngModel" (change) = "name = fn1.value" placeholder="Name" value= 'name | async'>
                  </div>
                </div>
                <div class="group-158">
                  <div class="place">Password</div>
                  <div class="overlap-group6">
                    <!-- <input type="email" class="name inter-normal-gunsmoke-14px-2" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Name"autocomplete="off"> -->
                    <input maxLength = "55" minLength = "2" type="password" class = "text-7 inter-normal-steel-teal-14px"ngModel name="fn2" #fn2 ="ngModel" (change) = "storePass(fn2.value)" placeholder="Password" value= 'name | async'>
                  </div>
                </div>
                <div class="group-158">
                  <div class="place">State, Zip</div>
                  <div class="overlap-group6">
                    <!-- <input type="email" class="name inter-normal-gunsmoke-14px-2" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Name"autocomplete="off"> -->
                    <input maxLength = "12" minLength = "2" type="text" class = "text-7 inter-normal-steel-teal-14px"ngModel name="fn3" #fn3 ="ngModel" (change) = "stateZip = fn3.value" placeholder="State, Zip" value= 'name | async'>
                  </div>
                </div>
              </div>
              <div class="flex-col-1">
                <div class="group-158">
                  <div class="place">Email</div>
                  <div class="overlap-group6">
                    <!-- <input type="email" class="name inter-normal-gunsmoke-14px-2" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Name"autocomplete="off"> -->
                    <input maxLength = "55" minLength = "2" type="email" class = "text-7 inter-normal-steel-teal-14px"ngModel name="fn4" #fn4 ="ngModel" (change) = "storeEmail(fn4.value)" placeholder="Email" value= 'name | async'>
                  </div>
                </div>
                <div class="group-158">
                  <div class="place">City</div>
                  <div class="overlap-group6">
                    <input maxLength = "55" minLength = "2" type="text" class = "text-7 inter-normal-steel-teal-14px"ngModel name="fn5" #fn5 ="ngModel" (change) = "city = fn5.value" placeholder="City" value= 'name | async'>
                  </div>
                </div>
                <div class="x709286 component component-wrapper ready">
                  <div class="group-158">
                    <div class="place">What Grade Is Your Child Enrolled In?</div>
                    <div class="overlap-group6">
                      <input maxLength = "55" minLength = "2" type="text" class = "text-7 inter-normal-steel-teal-14px"ngModel name="fn6" #fn6 ="ngModel" (change) = "grades = fn6.value" placeholder="Grade" value= 'name | async'>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="frame-352">
              <div class="group-158">
                <div class="place">Which Subjects Are You Looking For Tutoring In?</div>
                <div class="overlap-group6-1">
                  <input maxLength = "255" minLength = "2" type="text" class = "text-7-1 inter-normal-steel-teal-14px"ngModel name="fn7" #fn7 ="ngModel" (change) = "subjects = fn7.value" placeholder="Which Subjects Are You Looking For Tutoring In?" value= 'name | async'>
                </div>
              </div>
            </div>
          </div>
          <img
            class="x34322-2"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/34322-------------------2@2x.png"
          />
        </div>
      </div>
    </div>
  </div>
