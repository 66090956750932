
<div class="container-center-horizontal">
    <input  required type="file" class="custom-file-input" (change) = "storeFile($event)" accept = ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"id="inputCalPopUp" style="display: none;">
    <div class="frame-483 screen">
      <div class="overlap-group1"id = 'two'>
        <div class="group-457">
          <img
            class="x"
            (click) = 'closeMe()'
            data-id="1535:6765"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png"
          />
        </div>
        <h1 class="title worksans-semi-bold-abbey-32px">Session Information</h1>
        <div id = 'timeSelection' class="timeSelect">
            <div style="display:inline-block;vertical-align: top;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;margin-left: 10px;
            margin-right: 10px;">{{formatDate(currentDate | async)}}</div> 
            <div style="display:inline-block;width: 523px;overflow: scroll;margin-left:10px">
                <button *ngFor = "let range of currentTimeRanges"(click)="updateTimeFrame(range)" style="border-radius: 6px;margin-right: 4px;background-color: white;border: 1px solid lightgrey;">
                    <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 32px;margin-top: -11px;">{{formatHourMin(range[0].split('|-|')[0])}} - {{formatHourMin(range[0].split('|-|')[1])}}</p>
                </button>
            </div>
            <div style="display:block;margin-top: -20px;"></div>

            <span style="display: none;" id = 'startRange'>
                <button *ngFor = "let range of currentStartTime"(click)="updateStartTime(range)"style="display:block;width: 281px;   border-radius: 10px;
                border: none;
                color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;background-color: #F3F3F3;font-size: 15px;overflow:scroll;">
                    {{formatTime(range)}}
                </button>
            </span>
            <span id = 'endRange' style="display: none;">
                <button *ngFor = "let range of currentEndTime"(click)="updateEndTime(range)"style="display:block;width: 281px;   border-radius: 10px;
                border: none;
                background-color: #F3F3F3;font-size: 15px;
                color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;background-color: #F3F3F3;font-size: 15px;overflow:scroll;">
                    {{formatTime(range)}}
                </button>
            </span>
        </div>
        <div  class="studentSelectTitle" style="overflow: hidden;">
            <div id = 'studentProfileTitle' style="margin-bottom: 11px;">
                <p class="text-4">Who Is This Session For?</p>
            </div>
            <div id = 'studentProfile' style="    border: 5px solid #C6C6C6;
            border-radius: 10px;">
                <span id ='Teacher' style="display:inline-block;padding:7px;padding-right: 20px;padding-left: 20px;border-radius: 25px;" >
                    <img id ='1ProPic' src="../../../assets/Giraf.png" style="border-radius: 35px;display: block;align-self: flex-end;height: 70px;mix-blend-mode: normal;object-fit: cover;width: 70px;">
                    <p style="text-align: center;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 34px;">Fred Jr</p> 
                </span>
                <span id ='Student1' style="display:inline-block;padding:7px;padding-right: 20px;padding-left: 20px;border-radius: 25px;">
                    <img id ='2ProPic' src="../../../assets/Giraf.png" style="border-radius: 35px;display: block;align-self: flex-end;height: 70px;mix-blend-mode: normal;object-fit: cover;width: 70px;">
                    <p style="text-align: center;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 34px;">Ted Jr</p> 
                </span>
                <span id ='Student2' style="display:inline-block;padding:7px;padding-right: 20px;padding-left: 20px;border-radius: 25px;">
                    <img id ='3ProPic' src="../../../assets/plus.svg" style="border-radius: 35px;display: block;align-self: flex-end;height: 70px;mix-blend-mode: normal;object-fit: cover;width: 70px;    border: solid 4px rgba(30, 86, 99, 1);">
                    <p style="text-align: center;color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;height: 34px;">Add</p> 
                </span>
    
            </div>
            <div id = 'fileTitle' style="margin-top: 8px;">
                <p class="text-4">Upload Files For This Session.</p>
            </div>
            <div style="height: 169px;
            width: 335px;
            margin-left: 0px;
            border-radius: 10px;
            border: 5px solid #C6C6C6;
            background-color: white;
            text-align: right;">
                <div style="    position: fixed;
                background: #C6C6C6;
                height: 30px;
                margin-top: -3px;
                margin-left: -3px;
                width: 87px;
                border-bottom-right-radius: 10px;">
                                    <p style = '    color: var(--chathams-blue);
                                    font-family: var(--font-family-inter);
                                    font-weight: 500;
                                    font-size: 15px;
                                    letter-spacing: 0;
                                    line-height: 26px;
                                    text-align: left;
                                    margin-left: 10px;'>(+) Add</p>
                </div>
                <img style="    width: 124px;
                height: auto;
                position: absolute;
                left: 110px;" src = '../../../assets/noFilesDown.svg'>
                <div id = 'fileTitle' style="    text-align: center;
                margin-top: 130px;">
                    <p style = '    color: var(--chathams-blue);
                    font-family: var(--font-family-inter);
                    font-weight: 500;
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 26px;'>No Files Uploaded</p>
                </div>

            </div>
        </div>

        <div id = 'preSessionInfo' class="preSessionInfo">
            <!-- <textarea style="width: 422px;height: 150px;"></textarea>-->
            <p class="text-3">What Is This Session For?</p>
            <textarea class="overlap-group3"  placeholder="Text" ngModel name="fn33" #fn33 ="ngModel" (change) = 'messageToTeacher = fn33.value'></textarea>
            <span style="position: absolute;
            top: 307px;
            height: 42px;
            margin-top: 28px;
            width: 410px;
            margin-left: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            border: none;
            background-color:  #C6C6C6;
            text-align: right;">
            <span style="    width: 313px;
            height: 36px;
            border-radius: 10px;
            position: absolute;
            left: 17px;overflow:scroll;text-align: left;">
                <span *ngFor='let file of uploadedFiles' 
                style="        height: 26px;
                margin-top: -5px;
                border: 1px solid #F3F3F3;
                display: inline-block;
                border-radius: 10px;
                margin-right: 6px;
                ">
                <p style="color: rgba(30, 86, 99, 1);
                font-family: var(--font-family-inter);
                font-weight: 500;
                font-size: 13px;
                top: -4px;
                padding-right: 7px;
                padding-left: 7px;
                margin-top: -9px;">
                    {{file.name}}
                </p>
                </span>  
            </span>
            <img style = 'width: 20px;margin-right: 10px;'src = '../../../assets/camera.svg'>
            <img  (click) ='uploadFileToggle()'  src = '../../../assets/document.svg' style="width: 20px;margin-right: 10px;z-index:1;cursor: pointer;">
        </span>
        </div>
        <button (click) ='addToCart()'style = "position: absolute;top: 520px;height: 51px;width: 100%;border-bottom-right-radius: 10px;border-bottom-left-radius: 10px;color: white;font-family: var(--font-family-inter);font-weight: 500;background-color: rgba(30, 86, 99, 1); border: none;">Enter Classroom</button>
      </div>
    </div>
  </div>
