import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-teacher-content',
  templateUrl: './teacher-content.component.html',
  styleUrls: ['./teacher-content.component.scss']
})
export class TeacherContentComponent implements OnInit {
  youtubeCode = "empty";
  blogCat = ''
  blogTitle = ''
  blogSubTitle = '' 
  blogImagePath = ''
  blogArticle = ''
  blogReadTime = ''
  url: string | ArrayBuffer = '';
  paragraphs = []
  name = ''
  path = ''   
  UID = ''
  url2: string | ArrayBuffer = '';
  date 
  yee = false;
  nah = false;
  @Output() errorMsg = new EventEmitter<any[]>();

  constructor(private router:ActivatedRoute,private db: AngularFireDatabase,private afm: AngularFireStorage) {}

  async ngOnInit(){
    this.UID = this.router.snapshot.params.UID
    var hold = ''
    this.url = '../../assets/images.jpeg'
    await this.db.database.ref('providers/userInfo/' + this.UID + '/immutable/name/val').once('value').then(function(snap){
      hold = snap.val()
    }) 
    this.getProPic()
    this.name = hold
    this.date = new Date().toString()
    this.date = this.date.split(' ')[0] + ' ' + this.date.split(' ')[1] + ' ' + this.date.split(' ')[2]
  }

  async getProPic(){
    // Create a reference from a Google Cloud Storage URI
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/teacherPics/' + this.UID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById('pro').setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      document.getElementById('pro').setAttribute('src','../../../assets/noPic.png');
      // document.getElementById(`${teacherHold}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }
  
  blogSwitch(val,type){
    if(type === 0){
      this.blogTitle = val
    }else if(type === 1){
      this.blogSubTitle = val
    }else if(type === 2){
      this.blogReadTime = val
    }else if(type === 3){
      this.blogArticle = val
      this.paragraphs = val.split('||')
    }else if(type === 4){
      this.blogCat = val
    }else if(type === 5){
      this.blogImagePath = val

      const files = val.target.files;
      if (files.length === 0)
          return;
  
      const mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
          return;
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(files[0]); 
      reader.onload = (_event) => { 
          this.url = reader.result; 
      }
    }else if(type === 6){
      this.youtubeCode = val
    }
  }

  async post2(){
    this.yee = false
    this.nah = false
    var title = this.blogTitle
    var subTitle = this.blogSubTitle
    var readTime = this.blogReadTime
    var article = this.blogArticle
    var cat = 'Teacher Articles'
    this.blogCat = cat
    var youtube = ''  
    var blogImagePath = this.blogImagePath
    if(this.blogTitle !== '' && this.blogSubTitle !== '' && this.blogReadTime !== '' && this.blogArticle !== '' && this.blogCat !== '' && this.blogImagePath !== ''){
      var TS = + new Date()
      await this.db.database.ref('blog/' + cat).update({[`${TS}`]:
        {
          title: title,
          subTitle: subTitle,
          readTime:readTime,
          article: article,
          cat: cat,
          youtube: youtube
        }}
      ).then(()=>{
        this.db.database.ref('providers/userInfo/' + this.UID + '/articles').update({[`${TS}`]: ''
        }).then(()=>{
          if(blogImagePath !== ''){
            this.upload2(blogImagePath,TS)
          }
          this.yee = true
        })
      })
    }else{
      this.nah = true
    }

  }

  async post(){
    var title = this.blogTitle
    var subTitle = this.blogSubTitle
    var readTime = this.blogReadTime
    var article = this.blogArticle
    var cat = this.blogCat
    var youtube = this.youtubeCode
    var blogImagePath = this.blogImagePath
    if(this.blogTitle !== '' && this.blogSubTitle !== '' && this.blogReadTime !== '' && this.blogArticle !== '' && this.blogCat !== '' && this.blogImagePath !== ''){
      var TS = + new Date()
      await this.db.database.ref('blog/' + cat).update({[`${TS}`]:
        {
          title: title,
          subTitle: subTitle,
          readTime:readTime,
          article: article,
          cat: cat,
          youtube: youtube
        }}
      ).then(()=>{
        this.db.database.ref('blog/new/nextIndex').once('value').then((snap)=>{
          var nextHold = snap.val()
          this.db.database.ref('blog/new/' +`${nextHold}` ).set({[`${TS}`]:
        {
          title: title,
          subTitle: subTitle,
          readTime:readTime,
          article: article,
          cat: cat,
          youtube: youtube
        }}).then(()=>{
          var newIndex:number = Number(nextHold)
          if(newIndex === 10){
            newIndex = 1
          }else{
            newIndex += 1
          } 
          this.db.database.ref('blog/new').update({'nextIndex':newIndex}).then(()=>{
            this.upload2(blogImagePath,TS)
          })
        }).then(()=>{
          this.db.database.ref('blog/cats').child('val').once('value').then((snaper)=>{
            var topics = String(snaper.val())
            var newS = true
            console.log(snaper.val())
            topics.split('|').forEach(val=>{
              if(val === cat){
                newS = false
              }
            })
            if(newS){
              console.log(1)
              topics += '|' + cat
              this.db.database.ref('blog/cats').set({
                val:topics
              })

            }
          })
        })
        })
      })
    }else{
      //you forgot something
    }
  }
  
  upload2($event,TS){
    this.path = $event.target.files[0]
    this.afm.upload("/ArticleImages/" + TS, this.path)
  }


}
