import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment-timezone';
import { storageFile } from 'src/app/models/storageFile';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-calendar-pop-up',
  templateUrl: './calendar-pop-up.component.html',
  styleUrls: ['./calendar-pop-up.component.scss']
})
export class CalendarPopUpComponent implements OnInit {
  @Output() close = new EventEmitter<any[]>();

  @Output() currentDate = new EventEmitter<any>();
  @Output() startTime = new EventEmitter<any>();
  @Output() endTime = new EventEmitter<any>();

  @Output() selectedTimes = new EventEmitter<any>();
  @Output() addedTimes = new EventEmitter<any>();

  currentTimeRanges = [];
  currentStartTime = [];
  currentEndTime = [];
  @Input() config = new EventEmitter<any[]>();
  @Input() rateInput = new EventEmitter<any[]>();
  rate = 0
  
  students = []

  uploadedFiles = []

  weekly = false
  biweekly = false
  
  teacher = false
  
  UID = ''

  emailToShareWith = ''
   
  buttonSequences = []
  timeRanges: {};
  currentDateValue: string;

  bookedTimes = []
  startOpen = false;
  endOpen = false;
  startTimeSelected = false
  endTimeSelected = false
  selectedStudent: any;
  messageToTeacher = ''
  endValue: string;
  startTimeValue: string;
  endTimeValue: string;
  listOfSelectedTimes = []
  @Output() recreateCalendar = new EventEmitter<any>();
  TUID: any;
  teacherName: any;

  constructor(private db: AngularFireDatabase,private afm: AngularFireStorage,public auth: AuthService,private router:ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.config !== undefined && changes.config.currentValue !== null){
      console.log(changes.config.currentValue)
      this.startTime.emit('Start Time')
      this.startTimeValue = 'Start Time'
      this.endTime.emit('End Time')
      this.endTimeValue = 'End Time'
      console.log(changes.config.currentValue)  
      var timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
      console.log(timezoneName)
      var masterTimeSegments = {}
      var first = true
      changes.config.currentValue.forEach(date=>{
        for(var dateValue in date){
          console.log(date[dateValue])
          var dateTimes = []
          date[dateValue].forEach(time=>{
            var start = moment(time['start']).tz(timezoneName)
            var ogStart = moment(time['start']).tz(timezoneName)
            var end = moment(time['end']).tz(timezoneName)
            var timeSegment = []
            while(start.isSameOrBefore(end)){
              timeSegment.push(start.format())
              start.add(15,'minutes')
            }
            var tmp = {}
            tmp[ogStart.format()+'|-|'+end.format()]= timeSegment
            dateTimes.push(tmp)
          })
          masterTimeSegments[dateValue] = dateTimes
        }
        if(first){
          first = false
          this.currentDate.emit(dateValue)
          this.currentDateValue = dateValue
          console.log(masterTimeSegments[dateValue])
          var tmp = []
          for(var keys in masterTimeSegments[dateValue]){
            console.log(masterTimeSegments[dateValue][keys])
            for(var innerKey in masterTimeSegments[dateValue][keys]){
              console.log(innerKey)
              tmp.push([innerKey,masterTimeSegments[dateValue][keys][innerKey]])
            }
          }
          console.log(tmp)
          this.currentTimeRanges = tmp
          var tmpStart = []
          var tmpEnd = []
          tmp.forEach(val=>{
            var start = true
            val[1].forEach(v=>{
              if(start){
                start = false
                tmpStart.push(v)
              }else{
                tmpStart.push(v)
                tmpEnd.push(v)
              }
            })
            tmpStart.pop()
            tmpStart.push('T00:00:00 break')
            tmpEnd.push('T00:00:00 break')
          })
          console.log(tmp)
          this.currentStartTime = tmpStart
          this.currentEndTime = tmpEnd
        }
      })
      console.log(masterTimeSegments)
      this.timeRanges = masterTimeSegments
    }else if(changes.rateInput !== undefined && changes.rateInput.currentValue !== null){
      console.log(changes.rateInput.currentValue)
      this.rate = Number(changes.rateInput.currentValue.split('~')[0])
      this.teacherName = changes.rateInput.currentValue.split('~')[1]
    }


  }
  formatDate(range){
    var formatedTime = moment(range).format("dddd, MMMM Do YYYY");

    return formatedTime
  }
  formatTime(range){   
    if(range === 'T00:00:00 break'){
      var formatedTime = '-';
    }else{
      var formatedTime = moment(range).format("dddd, MMMM Do YYYY, h:mm a");
    }
    return formatedTime
  }
  formatHourMin(range){
    var formatedTime = moment(range).format("h:mm a");

    return formatedTime
  }

  async ngOnInit(){ 
    this.TUID = this.router.snapshot.params.UID  
    await this.auth.user$.subscribe(usr=>{   
      this.UID = usr.uid
         var hld = [[1646179248029,2,'Fred Jr'],[1646179248028,4,'Ted Jr']]
          console.log(hld)
          this.students=hld
          var count = 0
          setTimeout(()=>{
            this.students.forEach(student=>{
              count += 1
              console.log(student)
              var proImg = student[1]
              console.log(proImg)
              document.getElementById(String(count)+'ProPic').setAttribute('style','border-radius: 35px;display: block;align-self: flex-end;height: 70px;mix-blend-mode: normal;object-fit: cover;width: 70px;')
              if(String(proImg) === '1'){
                document.getElementById(String(count)+'ProPic').setAttribute('src','../../../assets/owl.svg')
                
              }else if(String(proImg) === '2'){
                document.getElementById(String(count)+'ProPic').setAttribute('src','../../../assets/Frog.svg')
              }else if(String(proImg) === '3'){
                document.getElementById(String(count)+'ProPic').setAttribute('src','../../../assets/Hippo.svg')
              }else if(String(proImg) === '4'){
                document.getElementById(String(count)+'ProPic').setAttribute('src','../../../assets/Giraf.svg')
              }else if(String(proImg) === '5'){
                document.getElementById(String(count)+'ProPic').setAttribute('src','../../../assets/Cat.svg')
              }else if(String(proImg) === '6'){
                document.getElementById(String(count)+'ProPic').setAttribute('src','../../../assets/zebra.svg')
              }

            })
          })
      // this.db.database.ref('users/'+  `${usr.uid}`+'/students').once('value').then(snap=>{
      //   var hld = []
      //   if(snap.exists()){ 
      //     snap.forEach(child=>{
      //       var tmp = [child.key,child.val(),child.val().profile.name]
      //       // console.log(child)

      //       // tmp[child.key] = child.val().profile.name
      //       hld.push(tmp)
      //     })
      //     console.log(hld)
      //     this.students=hld
      //     setTimeout(()=>{
      //       this.students.forEach(student=>{
      //         console.log(student)
      //         var proImg = student[1].profile.proPic
      //         console.log(proImg)
  
      //         if(String(proImg) === '1'){
      //           document.getElementById(student[0]).setAttribute('src','../../../assets/owl.svg')
      //         }else if(String(proImg) === '2'){
      //           document.getElementById(student[0]).setAttribute('src','../../../assets/Frog.svg')
      //         }else if(String(proImg) === '3'){
      //           document.getElementById(student[0]).setAttribute('src','../../../assets/Hippo.svg')
      //         }else if(String(proImg) === '4'){
      //           document.getElementById(student[0]).setAttribute('src','../../../assets/Giraf.svg')
      //         }else if(String(proImg) === '5'){
      //           document.getElementById(student[0]).setAttribute('src','../../../assets/Cat.svg')
      //         }else if(String(proImg) === '6'){
      //           document.getElementById(student[0]).setAttribute('src','../../../assets/zebra.svg')
      //         }

      //       })
      //     },0)
      //   }else{
      //     //make them create student account
      //   } 
      // })
      document.getElementById('preSessionInfo').addEventListener('drop',event=>{
        this.dropHandler(event)
      })
      document.getElementById('preSessionInfo').addEventListener('dragover',event=>{
        document.getElementById('preSessionInfo').setAttribute('class','group-458-3 border-8px-blue')
        this.dragOverHandler(event)
      })
      document.getElementById('preSessionInfo').addEventListener('dragleave',()=>{
        document.getElementById('preSessionInfo').setAttribute('class','group-458-3 border-1px-blue')
      })

     })

  }

  dragOverHandler(ev) {      
    // console.log('File(s) in drop zone');
  
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  dropHandler(ev) {
    // Prevent default behavior (Prevent file from being opened)
    document.getElementById('preSessionInfo').setAttribute('class','group-458-3 border-1px-blue')
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      var TS = Number(+ new Date())
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          TS += 1 
          var file:File = ev.dataTransfer.items[i].getAsFile();
          var newFile = new storageFile('',TS,file.name,'','General','General',file)
          this.uploadedFiles.unshift(newFile)
          // this.upload(file)
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      var TS = Number(+ new Date())
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        TS += 1
        var file:File = ev.dataTransfer.items[i].getAsFile();
        var newFile = new storageFile('',TS,file.name,'','General','General',file)
        this.uploadedFiles.unshift(newFile)
      }
    }
  }

  toggleStartTime(){
    if(this.startOpen){
      document.getElementById('startRange').setAttribute('style','display:none')
    }else{
      document.getElementById('startRange').setAttribute('style','padding-right: 50px;display:inline-block;height: 145px;overflow: scroll;vertical-align: top;z-index: 1;position: absolute;top: 110px;background-color: white; padding: 10px;box-shadow: 4px 12px 16px #e5e5e5;border-radius: 10px;left:10px;')
    }
    this.startOpen = !this.startOpen
  }

  toggleEndTime(){
    if(this.endOpen){
      document.getElementById('endRange').setAttribute('style','display:none')
    }else{
      document.getElementById('endRange').setAttribute('style','padding-right: 50px;display:inline-block;height: 145px;overflow: scroll;vertical-align: top;z-index: 1;position: absolute;top: 110px;left: 341px;background-color: white; padding: 10px;box-shadow: 4px 12px 16px #e5e5e5;border-radius: 10px;')
    }  
    this.endOpen = !this.endOpen
  }

  selectStudent(studentID){
    console.log(studentID)
    this.selectedStudent = studentID
    var studentIDHold = studentID[0]
    this.students.forEach(val=>{
      console.log(val,val[0])
      document.getElementById(`${val[0]}`+ 'Select').setAttribute('style','display:inline-block;padding:7px;padding-right: 20px;padding-left: 20px;border-radius: 25px;')
    })
    document.getElementById(`${studentIDHold}` + 'Select').setAttribute('style','display:inline-block;padding:7px;padding-right: 20px;padding-left: 20px;border-radius: 25px;background-color:#B1E7F3;')
  }
  
  updateTimeFrameInner2(newTimeSpread,time){
    console.log(newTimeSpread,this.startTimeValue,this.endTimeValue)
    var tmpStart = []
    var tmpEnd = []
    var start = true
    var afterStart = false
    newTimeSpread[1].forEach(val=>{
      console.log('196:',val)
      if(start){
        start = false
        tmpStart.push(val)
      }else{
        tmpStart.push(val)
        console.log(time,val)
        console.log('224: ', this.startTimeSelected,this.startTimeValue)
        if(this.startTimeSelected){
          console.log(moment(val),moment(this.startTimeValue).format(),moment(val).isAfter(moment(this.startTimeValue)))
          if(moment(val).isAfter(moment(this.startTimeValue))){
            tmpEnd.push(val)
          }
        }else{
          tmpEnd.push(val)
        }
      }
    })
    tmpStart.pop()
    this.currentStartTime = tmpStart
    this.currentEndTime = tmpEnd
  }

  updateTimeFrameInner(newTimeSpread,time){
    console.log(newTimeSpread)
    var tmpStart = []
    var tmpEnd = []
    var start = true
    var afterStart = false
    newTimeSpread[1].forEach(val=>{
      console.log('196:',val)
      if(start){
        start = false
        tmpStart.push(val)
      }else{
        tmpStart.push(val)
        console.log(time,val)
        if(moment(val).isAfter(moment(time))){
          tmpEnd.push(val)
        }
      }
    })
    tmpStart.pop()
    this.currentStartTime = tmpStart
    this.currentEndTime = tmpEnd
  }   
   

  updateTimeFrame(newTimeSpread){
    console.log(newTimeSpread)
    this.startTime.emit('Start Time')
    this.startTimeValue = 'Start Time'
    this.startTimeSelected = false
    this.endTime.emit('End Time')
    this.endTimeValue = 'End Time'
    var tmpStart = []
    var tmpEnd = []
    var start = true
    newTimeSpread[1].forEach(val=>{
      console.log('216:',val)
      if(start){
        start = false
        tmpStart.push(val)
      }else{
        tmpStart.push(val)
        tmpEnd.push(val)
      }
    })
    tmpStart.pop()
    this.currentStartTime = tmpStart
    this.currentEndTime = tmpEnd
  }

  uploadFileToggle(){
    console.log('owe')
    document.getElementById('inputCalPopUp').click()
  }

  addToCart(){
    console.log(this.selectedStudent,this.listOfSelectedTimes,this.emailToShareWith,this.uploadedFiles,this.messageToTeacher)
    var fileKeys = ''
    var dates = ''
    this.listOfSelectedTimes.forEach(time=>{
      if(dates === ''){
        dates = time[1] + '|-|' + time[2]
      }else{
        dates += '~' + time[1] + '|-|' + time[2]
      }
    })
    
    this.uploadedFiles.forEach(file=>{
      if(fileKeys ===''){
        fileKeys = file.key
      }else{
        fileKeys += '|' + file.key
      }
    })
    console.log(this.selectedStudent[1],this.selectedStudent[1]['profile'])
    var returnVale = {'files':fileKeys,'rate':this.rate,'dates':dates,'E2I':this.emailToShareWith,'M2T':this.messageToTeacher,'TUID':this.TUID,'TN':this.teacherName,'SUIDs':this.selectedStudent[0],'SN':this.selectedStudent[2],'SProf':this.selectedStudent[1]['profile']['proPic']}
    var cartUID = + new Date()
    this.db.database.ref('users/' + this.UID + '/cart').update({[cartUID]:returnVale})
    this.db.database.ref('users/' + this.UID).update({['cartPort']:{[cartUID]:returnVale}})
    console.log(returnVale)
    this.closeMe()
    this.recreateCalendar.emit('')
  }

  storeFile($event){
    var files = $event.target.files
    var fileName = files[0].name
    // // var extension = files[0].type  $event,name,desc,topic,subtopic,type,TSHold
    var TS = + new Date() 
    var newFile = new storageFile('',TS,fileName,'','General','General',$event.target.files[0])
    this.upload($event.target.files[0],fileName,'','','General','General',+ new Date())
    this.uploadedFiles.push(newFile)
    console.log($event)
    console.log(newFile)
  }

  updateStartTime(time){
    console.log('205: ',time,this.currentTimeRanges,this.currentStartTime,this.currentEndTime)
    console.log(this.currentTimeRanges)
    var seen = false
    var valSeen = ''
    this.toggleStartTime()
    this.currentTimeRanges.forEach(timeRange=>{
      console.log(timeRange[0])
      console.log(moment(time).isSameOrAfter(timeRange[0].split('|-|')[0]),moment(time).isSameOrBefore(timeRange[0].split('|-|')[1]))
      if(moment(time).isSameOrAfter(timeRange[0].split('|-|')[0]) && moment(time).isSameOrBefore(timeRange[0].split('|-|')[1])){
        valSeen =  timeRange
        seen = true
      }
    })

    console.log(valSeen)
    if(seen){
      this.updateTimeFrameInner(valSeen,time)
    }
    
    this.startTime.emit(this.formatTime(time))
    this.startTimeValue = time
    this.startTimeSelected = true
    if(!this.endOpen){
      this.toggleEndTime()
    }

    if(this.endValue !== '' && !moment(time).isBefore(this.endValue)){
      this.endTimeSelected = false
      this.endTime.emit('End Time')
      this.endTimeValue = "End Time" 
      this.updateTimeFrameInner(valSeen,time)
    }

  }

  deletMe(v){
    console.log(v)
  }


  upload($event,name,desc,topic,subtopic,type,TSHold){
    setTimeout(() => {
      // this.path = $event
      var TS = TSHold
      // console.log("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path)

      var key = this.UID + '-' + TS
      if(this.teacher){
        // console.log(this.path)
        this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, $event).then(e=>{
          // if($event.type.includes('pdf')){
          //   e.ref.getDownloadURL().then(URL=>{
          //     this.convertPDF(URL)
          //   })
          // }
          this.db.database.ref('providers/userInfo/' + this.UID + '/storage').update({[key]:{'type':'PERSONAL','value':TS,'name':name,'desc':desc,'topic':topic,'subtopic':subtopic}}).then(()=>{
            this.db.database.ref('providers/userInfo/' + this.UID + '/').update({'newStorage':key +'/'+name + '/' + type})
          })
             
        })
      }else{
        this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, $event).then(()=>{
          this.db.database.ref('users/' + this.UID + '/storage').update({[key]:{'type':'PERSONAL','value':TS,'name':name,'desc':desc,'topic':topic,'subtopic':subtopic}}).then(()=>{
            this.db.database.ref('users/' + this.UID + '/').update({'newStorage':key +'/'+name + '/' + type})
          })
        })
      }
    }, 0);    
  }


  addTime(){
    if(this.startTimeValue === 'Start Time' || this.startTimeValue === '' || this.startTimeValue === null || this.endTimeValue === 'End Time' || this.endTimeValue === '' || this.endTimeValue === null){
      return
    }
    console.log(this.startTimeValue,this.endTimeValue,this.timeRanges)
    console.log(this.listOfSelectedTimes)
    var hld = this.formatTime(this.startTimeValue)
    var hld2 = this.formatTime(this.endTimeValue)
    console.log(hld,hld2)
    var newSelectedTime = [hld.split(',')[0] + ', ' + hld.split(',')[1] + ' |' + hld.split(',')[2] + ' -' + hld2.split(',')[2],this.startTimeValue,this.endTimeValue]
    // if(this.listOfSelectedTimes.length === 0){
    //   document.getElementById('noChosenTimes').setAttribute('style','display:none;')
    // }
    this.listOfSelectedTimes.push(newSelectedTime)
    console.log(newSelectedTime)

    this.selectedTimes.emit(this.startTimeValue + '|-|' + this.endTimeValue)
    this.clearTimeSelector()

  }

  addBackSelectedTimes(v){
    //issue when only 1 val
    console.log(v)
    console.log(this.listOfSelectedTimes)
    console.log(this.listOfSelectedTimes.filter(val => val[0] !== v[0] && val[1] !== v[1] && val[2] !== v[2]))
    this.listOfSelectedTimes = this.listOfSelectedTimes.filter(val => val[0] !== v[0] && val[1] !== v[1] && val[2] !== v[2])
    // if(this.listOfSelectedTimes.length === 0){
    //   document.getElementById('noChosenTimes').setAttribute('style','display:block;')
    // }
    this.addedTimes.emit(v)
  }

  clearTimeSelector(){
    this.startTimeValue = 'Start Time'
    this.endTimeValue = 'End Time'
    this.startTime.emit('Start Time')
    this.endTime.emit('End Time')
    this.startTimeSelected = false
    this.endTimeSelected = false
  }

  updateEndTime(time){
    console.log('205: ',time,this.currentTimeRanges,this.currentStartTime,this.currentEndTime)
    console.log(this.currentTimeRanges)
    var seen = false
    var valSeen = ''
    this.toggleEndTime()
    this.currentTimeRanges.forEach(timeRange=>{
      console.log(timeRange[0])
      console.log(moment(time).isSameOrAfter(timeRange[0].split('|-|')[0]),moment(time).isSameOrBefore(timeRange[0].split('|-|')[1]))
      if(moment(time).isSameOrAfter(timeRange[0].split('|-|')[0]) && moment(time).isSameOrBefore(timeRange[0].split('|-|')[1])){
        valSeen =  timeRange
        seen = true
      }
    })

    this.endValue = time
    console.log(valSeen)
    if(seen){
      this.updateTimeFrameInner2(valSeen,time)
    }
    
    this.endTimeSelected = true
    this.endTime.emit(this.formatTime(time))
    console.log(this.formatTime(time))
    this.endTimeValue = time
  }

  closeMe(){
    this.close.emit([])
  }

  next(){
    var switche = false
    var first = true
    var firstKey
    var firstVal
    this.startTime.emit('Start Time')
    this.startTimeValue = 'Start Time'
    this.startTimeSelected = false
    this.endTime.emit('End Time')
    this.endTimeValue = 'End Time'
    for(var key in this.timeRanges){
      if(first){
        first = false
        firstKey = key 
        firstVal =this.timeRanges[key]
      }
      if(switche){
        console.log(this.timeRanges[key])
        switche = false
        console.log(key,this.timeRanges[key])
        this.currentDate.emit(key)
        this.currentDateValue = key
        var tmp = []
        for(var keys in this.timeRanges[key]){
          console.log(this.timeRanges[key][keys])
          for(var innerKey in this.timeRanges[key][keys]){
            console.log(innerKey)
            tmp.push([innerKey,this.timeRanges[key][keys][innerKey]])
          }
          var tmpStart = []
          var tmpEnd = []
          tmp.forEach(val=>{
            var start = true
            val[1].forEach(v=>{
              if(start){
                start = false
                tmpStart.push(v)
              }else{
                tmpStart.push(v)
                tmpEnd.push(v)
              }
            })
            tmpStart.pop()
            tmpStart.push('T00:00:00 break')
            tmpEnd.push('T00:00:00 break')
          })
          this.currentStartTime = tmpStart
          this.currentEndTime = tmpEnd
        }
        if(this.timeRanges[key].length === 0){
          this.currentStartTime = ['T00:00:00 break']
          this.currentEndTime = ['T00:00:00 break']
        }
        console.log(tmp)
        this.currentTimeRanges = tmp
        
      }else if(key === this.currentDateValue){
        switche = true
      }
    }
   if(switche){
    console.log(firstKey,firstVal)
    console.log(this.timeRanges[key])
    this.currentDate.emit(firstKey)
    this.currentDateValue = firstKey
    var tmp = []
    for(var keys in firstVal){
      console.log(firstVal[keys])
      for(var innerKey in firstVal[keys]){
        console.log(innerKey)
        tmp.push([innerKey,firstVal[keys][innerKey]])
      }
      var tmpStart = []
      var tmpEnd = []
      tmp.forEach(val=>{
        var start = true
        val[1].forEach(v=>{
          if(start){
            start = false
            tmpStart.push(v)
          }else{
            tmpStart.push(v)
            tmpEnd.push(v)
          }
        })
        tmpStart.pop()
        tmpStart.push('T00:00:00 break')
        tmpEnd.push('T00:00:00 break')
      })
      this.currentStartTime = tmpStart
      this.currentEndTime = tmpEnd
    }
    this.currentTimeRanges = tmp
   }
  }

  prev(){
    var switche = false
    var first = true
    var firstKey
    var firstVal
    this.startTime.emit('Start Time')
    this.startTimeValue = 'Start Time'
    this.startTimeSelected = false
    this.endTime.emit('End Time')
    this.endTimeValue = 'End Time'
    console.log(this.timeRanges)
    for(var key in this.timeRanges){
      if(first){
        first = false
        firstKey = key 
        firstVal =this.timeRanges[key]
      }
      if(switche){
        console.log(this.timeRanges[key])
        switche = false
        console.log(key,this.timeRanges[key])
        this.currentDate.emit(key)
        this.currentDateValue = key
        var tmp = []
        for(var keys in this.timeRanges[key]){
          console.log(this.timeRanges[key][keys])
          for(var innerKey in this.timeRanges[key][keys]){
            console.log(innerKey)
            tmp.push([innerKey,this.timeRanges[key][keys][innerKey]])
          }
          var tmpStart = []
          var tmpEnd = []
          tmp.forEach(val=>{
            var start = true
            val[1].forEach(v=>{
              if(start){
                start = false
                tmpStart.push(v)
              }else{
                tmpStart.push(v)
                tmpEnd.push(v)
              }
            })
            tmpStart.pop()
            tmpStart.push('T00:00:00 break')
            tmpEnd.push('T00:00:00 break')
          })
          this.currentStartTime = tmpStart
          this.currentEndTime = tmpEnd
        }
        if(this.timeRanges[key].length === 0){
          this.currentStartTime = ['T00:00:00 break']
          this.currentEndTime = ['T00:00:00 break']
        }
        console.log(tmp)
        this.currentTimeRanges = tmp
        
      }else if(key === this.currentDateValue){
        switche = true
      }
    }
   if(switche){
    console.log(firstKey,firstVal)
    console.log(this.timeRanges[key])
    this.currentDate.emit(firstKey)
    this.currentDateValue = firstKey
    var tmp = []
    for(var keys in firstVal){
      console.log(firstVal[keys])
      for(var innerKey in firstVal[keys]){
        console.log(innerKey)
        tmp.push([innerKey,firstVal[keys][innerKey]])
      }
      var tmpStart = []
      var tmpEnd = []
      tmp.forEach(val=>{
        var start = true
        val[1].forEach(v=>{
          if(start){
            start = false
            tmpStart.push(v)
          }else{
            tmpStart.push(v)
            tmpEnd.push(v)
          }
        })
        tmpStart.pop()
        tmpStart.push('T00:00:00 break')
        tmpEnd.push('T00:00:00 break')
      })
      this.currentStartTime = tmpStart
      this.currentEndTime = tmpEnd
    }
    this.currentTimeRanges = tmp
   }
  }
}
