<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
<div>
  <div class="image" >
    <form style = "margin: 0 auto;" *ngIf = "admin | async">
      <h1 style = "max-width: 1200px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
        Creat Appointment
      </h1>
      <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 1200px;text-align: center;margin: 0 auto;;background-color:lightgray;border: 5px solid rgb(230,230,230)">
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Teacher Name</p></label>
          <input ngModel name="SUTeacherName1"  #SUTeacherName1  ="ngModel" (change) = "appointmentTName  = SUTeacherName1.value" id ="SUTeacherName1" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Teacher Email</p></label>
          <input ngModel name="SUTeacherName2"  #SUTeacherName2  ="ngModel" (change) = "appointmentTEmail  = SUTeacherName2.value" id ="SUTeacherName2" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div> 
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">TUID</p></label>
          <input ngModel name="signUpTUID" #signUpTUID ="ngModel" (change) = "appointmentTUID = signUpTUID.value" id ="signUpTUID" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Student Name 1</p></label>
          <input ngModel name="SUDATE1" #SUDATE1="ngModel" (change) = "appointmentSName1 = SUDATE1.value" id ="SUDATE1" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  

        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">SUID 1</p></label>
          <input ngModel name="SUParentName1" #SUParentName1 ="ngModel" (change) = "appointmentSUID1 = SUParentName1.value" id ="SUParentName1" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Parent Email 1</p></label>
          <input ngModel name="SUParentName33" #SUParentName33 ="ngModel" (change) = "appointmentPEmail1 = SUParentName33.value" id ="SUParentName33" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">PUID 1</p></label>
          <input ngModel name="SUParentName3" #SUParentName3 ="ngModel" (change) = "appointmentPUID1 = SUParentName3.value" id ="SUParentName3" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Student Name 2</p></label>
          <input ngModel name="signUpPUID" #signUpPUID ="ngModel" (change) = "appointmentSName2 = signUpPUID.value" id ="signUpPUID" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">SUID 2</p></label>
          <input ngModel name="PUDATE1" #PUDATE1="ngModel" (change) = "appointmentSUID2 = PUDATE1.value" id ="PUDATE1" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Parent Email 2</p></label>
          <input ngModel name="SUParentName373" #SUParentName373 ="ngModel" (change) = "appointmentPEmail2 = SUParentName373.value" id ="SUParentName373" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">PUID 2</p></label>
          <input ngModel name="SUParentName73" #SUParentName73 ="ngModel" (change) = "appointmentPUID2 = SUParentName73.value" id ="SUParentName73" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  

        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Student Profile Pic 1</p></label>
          <input ngModel name="Monday" #Monday ="ngModel" (change) = "monday = Monday.value" id ="Monday" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">TStudent Profile Pic 2</p></label>
          <input ngModel name="Tuesday" #Tuesday ="ngModel" (change) = "tuesday = Tuesday.value" id ="Tuesday" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Wednesday</p></label>
          <input ngModel name="Wednesday" #Wednesday ="ngModel" (change) = "wednesday= Wednesday.value" id ="Wednesday" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Thursday</p></label>
          <input ngModel name="Thursday" #Thursday ="ngModel" (change) = "thursday = Thursday.value" id ="Thursday" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Friday</p></label>
          <input ngModel name="Friday" #Friday ="ngModel" (change) = "friday = Friday.value" id ="Friday" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Saturday</p></label>
          <input ngModel name="Saturday" #Saturday ="ngModel" (change) = "saturday = Saturday.value" id ="Saturday" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Sunday</p></label>
          <input ngModel name="Sunday" #Sunday ="ngModel" (change) = "sunday = Sunday.value" id ="Sunday" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  


        <button (click) = 'createAppointments3()' type="button" style="font-size: 16px;" class="btn btn-secondary btn-sm">Book</button>
        <!-- <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Sub Title</p></label>
          <input ngModel name="SubTitleB" #SubTitleB ="ngModel" (change) = "blogSwitch(SubTitleB.value,1)" id ="SubTitleB" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div> -->
      </div>



      <h1 style = "max-width: 1200px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
        Blog
      </h1>
      <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 1200px;text-align: center;margin: 0 auto;;background-color:lightgray;border: 5px solid rgb(230,230,230)">
        <div>
          <label><p style="text-align: center; font-size: 20px;">Article Image</p></label>
        </div>
        <div class="custom-file" style = "max-width: 950px;margin-bottom: 20px;">
          <input type="file" class="custom-file-input" (change) = "blogSwitch($event,5)" accept = "*.png.jpg.pdf"id="inputGroupFile02">
          <label style=" font-size: 16px;margin:0 auto;text-align: center;background-color: #D8D8D8;" class="custom-file-label" for="inputGroupFile02"></label>
        </div>
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Category</p></label>
          <input ngModel name="Category" #Category ="ngModel" (change) = "blogSwitch(Category.value,4)" id ="Category" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Title</p></label>
          <input ngModel name="TitleB" #TitleB ="ngModel" (change) = "blogSwitch(TitleB.value,0)" id ="TitleB" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>
        <!-- <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Sub Title</p></label>
          <input ngModel name="SubTitleB" #SubTitleB ="ngModel" (change) = "blogSwitch(SubTitleB.value,1)" id ="SubTitleB" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div> -->
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Read Time</p></label>
          <input ngModel name="ReadTimeB" #ReadTimeB ="ngModel" (change) = "blogSwitch(ReadTimeB.value,2)" id ="ReadTimeB" type="text" class="form-control" style = "margin:0 auto;width: 85%;;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Link For Youtube</p></label>
          <input ngModel name="Youtube" #Youtube ="ngModel" (change) = "blogSwitch(Youtube.value,6)" id ="Youtube" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Article Content</p></label>
          <textarea id = 'article' ngModel name="article" #article ="ngModel" (change) = "blogSwitch(article.value,3)"minLength = "2" class="form-control" rows="3" style = "margin: 0 auto;width:980px;min-height: 250px; height:360px; min-height:100px;font-size:16px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)" placeholder="Article" value = "" ></textarea> 
        </div>
        <div style="margin: 0 auto;max-width:990px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230);height: 230px;margin-bottom: 15px;">
          <div style="display:inline-block;vertical-align: top;">
            <img src={{url}} style="width: 250px; height:200px;margin-top: 10px;">
          </div>
          <div style = 'width:30px;display: inline-block;margin-left: 40px;'>
          </div>
          <div style="display:inline-block;width:65%;text-align: left;margin-top: 15px;">
            <p style = 'font-size: 14px;line-height: 1.1'>{{blogCat}}</p>
            <p style = 'font-size: 24px;line-height: 1.1'>{{blogTitle}}</p>
            <p style = 'font-size: 22px;line-height: 1.1'>{{blogSubTitle}}</p>
            <p style = 'font-size: 14px;line-height: 1.1'>{{date}} · {{blogReadTime}} min read </p>
          </div>
        </div>
        <div style="margin: 0 auto;max-width:990px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230);height: 1000px;margin-bottom: 15px;overflow-y:auto;">
          <div style="margin:0 auto;width:67%;text-align: center;margin-top: 15px;">
            <p style = 'font-size: 14px;text-align: left;line-height: 1.1'>{{blogCat}}</p>
            <p style = 'font-size: 34px;text-align: left;line-height: 1.1'>{{blogTitle}}</p>
            <p style = 'font-size: 22px;text-align: left;line-height: 1.1'>{{blogSubTitle}}</p>
          </div>
          <div style="display:inline-block;vertical-align: top;">
            <img src={{url}} style="width:67%;margin-top: 10px;">
          </div>

          
          <div style="margin:0 auto;width:67%;text-align: left;">
            <img id = 'pro'src='../../assets/dude.jpeg' style="border: 5px solid #D8D8D8;width:50px;height:50px; vertical-align: middle;border-radius: 55px;">
            <span>
              Alexander Stanger - {{date}} · {{blogReadTime}} min read 
            </span>  
          </div>
          <div style="margin: 0 auto;" *ngFor = "let p of paragraphs">
            <p style="font-size: 22px;padding: 5px; margin-bottom: 20px;width:67%;text-align: left;margin: 0 auto;line-height: 1.1">
              {{p}}
            </p>
          </div>
        </div>
        <button (click) = 'post()' type="button" style="font-size: 16px;" class="btn btn-secondary btn-sm">POST</button>
      </div>
      <h1 style = "max-width: 1200px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
        Campaign Page
      </h1>
      <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 1200px;text-align: center;margin: 0 auto;;background-color:lightgray;border: 5px solid rgb(230,230,230)">

        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Title</p></label>
          <input ngModel name="TitleB" #TitleB ="ngModel" (change) = "campaignSwitch(TitleB.value,0)" id ="TitleB" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Sub Title</p></label>
          <input ngModel name="SubTitleB" #SubTitleB ="ngModel" (change) = "campaignSwitch(SubTitleB.value,1)" id ="SubTitleB" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>  
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Page Content</p></label>
          <textarea id = 'article' ngModel name="article" #article ="ngModel" (change) = "campaignSwitch(article.value,3)"minLength = "2" class="form-control" rows="3" style = "margin: 0 auto;width:980px;min-height: 250px; height:360px; min-height:100px;font-size:16px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)" placeholder="Article" value = "" ></textarea> 
        </div>
        <!-- <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Link For Youtube</p></label>
          <input ngModel name="Youtube" #Youtube ="ngModel" (change) = "campaignSwitch(Youtube.value,4)" id ="Youtube" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div> -->
        <div style="margin: 0 auto;max-width:990px;background-color: rgb(230,230,230);border: 5px solid rgb(230,230,230);height: 1000px;margin-bottom: 15px;overflow-y:auto;">
          <div class="image2" style="color:#292F45;" >
            <div style="display: inline-block;vertical-align: top; margin-top: 30px;width:210px;text-align: left;margin-right: 30px;">
                <img style = "width:200px;vertical-align: top;"src = '../../../assets/Original.png'>
            </div>
            <div style="display: inline-block;font-family: 'Avenir Light'; margin-top: 30px;margin-bottom: 30px;width: 60%;">
                <p style = 'font-size: 34px;text-align: left;line-height: 1.1'>{{campTitle}}</p>
                <p style = 'font-size: 22px;text-align: left;line-height: 1.1'>{{campSubTitle}}</p>
            </div>
          </div>
          <div style = 'display: inline-block;width: 375px;vertical-align: top;margin-right: 20px;'>
            <div *ngFor = "let p1 of paragraphsCamp1 | async"style=";width: 375px;">
              <div>
               <p style="font-size: 22px;padding: 5px;text-align: left;margin: 0 auto;line-height: 1.1;display: block;">
                 {{p1}}
               </p>
              </div>
             </div>
          </div>
          <div style = 'display: inline-block;width: 375px;vertical-align: top;'>
            <div *ngFor = "let p2 of paragraphsCamp2 | async">
              <div>
                <p style="font-size: 22px;padding: 5px; text-align: left;margin: 0 auto;line-height: 1.1">
                  {{p2}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button (click) = 'postCamp()' type="button" style="font-size: 16px;" class="btn btn-secondary btn-sm">POST</button>
        <div *ngFor = "let s of currentLink" [s] = "s">
          <div class = "txtBlock">{{s}}</div>
      </div>
      </div>
      <h1 style = "max-width: 800px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
        Create Question
      </h1>
      <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 800px;text-align: center;margin: 0 auto;">
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">AUID</p></label>
          <input ngModel name="question1" #question1 ="ngModel" (change) = "AUID = question1.value" id ="question1" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
        </div>
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Question</p></label>
          <input ngModel name="question2" #question2 ="ngModel" (change) = "Question = question2.value" id ="question2" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
        </div>
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Grade</p></label>
          <input ngModel name="question4" #question4 ="ngModel" (change) = "qGrade = question4.value" id ="question4" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
        </div>
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Subtopic</p></label>
          <input ngModel name="question6" #question6 ="ngModel" (change) = "qSubTopic = question6.value" id ="question6" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
        </div>
        <button (click) = addQuestion() type="button" style="font-size: 16px;" class="btn btn-secondary btn-sm">ADD Question</button>
      </div>
      <h1 style = "max-width: 800px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
        Create Influencer Code
      </h1>
      <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 800px;text-align: center;margin: 0 auto;">
        <button (click) = createInfCode() type="button" style="font-size: 16px;" class="btn btn-secondary btn-sm">Create Code</button>
        <div class = "txtBlock"style = 'margin-top:30px'>{{currentCode}}</div>
      </div>   

      <h1 style = "max-width: 800px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
        Create Account For Ambas.
      </h1>
      <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 800px;text-align: center;margin: 0 auto;">
        <input ngModel name="ambasAcctEmail" #ambasAcctEmail ="ngModel" (change) = "ambassName = ambasAcctEmail.value" id ="ambasAcctEmail" type="text" class="form-control" style = "margin:0 auto;width: 85%;"placeholder="Name">
        <input ngModel name="ambasAcctName" #ambasAcctName ="ngModel" (change) = "ambassEmail = ambasAcctName.value" id ="ambasAcctName" type="text" class="form-control" style = "margin:0 auto;width: 85%;" placeholder="Email">
        
        <button (click) = createAmbassAccount() type="button" style="font-size: 16px;" class="btn btn-secondary btn-sm">Create Account</button>
        <div class = "txtBlock"style = 'margin-top:30px'>{{ambasAccountInfo}}</div>
      </div>

      <h1 style = "max-width: 800px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
        ADD NEW TEACHER
      </h1>
      <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 800px;text-align: center;margin: 0 auto;">
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">UID</p></label>
          <input ngModel name="fn" #fn ="ngModel" (change) = "storeUID(fn.value)" id ="UID" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
        </div>
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">First name</p></label>
          <input ngModel name="fn" #fn ="ngModel" (change) = "storeFirstName(fn.value)" id ="firstName" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
        </div>
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Formal Title</p></label>
          <input ngModel name="fn" #fn ="ngModel" (change) = "storeformalTitle(fn.value)" id ="ft" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
        </div>
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Time Zone Offset</p></label>
          <input ngModel name="fn" #fn ="ngModel" (change) = "storeTZO(fn.value)" id ="TZO" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
        </div>
        <button (click) = addTeachers() type="button" style="font-size: 16px;" class="btn btn-secondary btn-sm">ADD TEACHER</button>
      </div>
      <h1 style = "max-width: 800px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
        Clear Stuff 
      </h1>
      <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 800px;text-align: center;margin: 0 auto;">
        <label><p style="text-align: center; font-size: 20px;">UID</p></label>
        <input ngModel name="fn122" #fn122 ="ngModel" (change) = "UID232=fn122.value" id ="UID232" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
        <button (click) = clearDays() type="button" style="font-size: 16px;margin-top: 20px;"  class="btn btn-secondary btn-sm">Days</button>
        <button (click) = clearTitles() type="button" style="font-size: 16px;margin-top: 20px;"  class="btn btn-secondary btn-sm">Titles</button>
        <button (click) = clearGrades() type="button" style="font-size: 16px;margin-top: 20px;"  class="btn btn-secondary btn-sm">Grades</button>
     
      </div>
      <h1 style = "max-width: 800px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
        ADD TO SEARCH
      </h1>
      <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 800px;text-align: center;margin: 0 auto;">
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">UID</p></label>
          <input ngModel name="fn1" #fn1 ="ngModel" (change) = "storeUID2(fn1.value)" id ="UID2" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
          <div *ngIf = "UID2 !== ''">
            <p class = "txtBlock">Currently selected:</p>
            <p>{{UID2}}</p>
          </div>
        </div>
        <div style="padding-top: 20px;">
          <div class="form-group boxSize">
              <!-- <label style="text-align: center; font-size: 20px;margin-bottom: 20px;">This is where you can update the subjects you offer and their descriptions</label> -->
              <div class="form-group boxSize" style = "display: inline-block;background-color: #D8D8D8;min-height:150px;border: 5px solid rgb(230,230,230);padding:15px;margin:0 auto;margin-bottom: 20px;border-radius: 25px;">
                  <label style="text-align: center; font-size: 20px;margin-bottom: 20px;">Age Range of Students</label>
                      <p style="font-size: 16px;max-width: 330px;text-align: center;margin:0 auto; margin-bottom: 20px;">
                          The first click adds the subject to your list, the second click takes it out.
                      </p>
                  <select multiple class="form-control" style="margin:0 auto;margin-top: 10px;background-color: rgb(230,230,230);height: 180px; width: 350px;">
                      <option id = 'General Education' (click) = 'updateSearch("General Education",1)'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;font-size:16px;">General Education</option>
                      <option id = '1_2' (click) = 'updateSearch("1_2",1)'class="time checkbox btn btn-secondary" style = "display:block; text-align: center;margin-bottom: 3px;font-size:16px;">1st and 2nd Graders</option>
                      <option id = '3_5' (click) = 'updateSearch("3_5",1)'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;font-size:16px;">3rd and 5th Graders</option>
                      <option id = '6_8' (click) = 'updateSearch("6_8",1)'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;font-size:16px;">6th and 8th Graders</option>
                      <option id = '9_12' (click) = 'updateSearch("9_12",1)'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;font-size:16px;">9th and 12th Graders</option>
                  </select>
              </div>
              <div style = "display: inline-block; width: 20px;"></div>
              <div style = "display: inline-block;background-color: #D8D8D8;min-height:150px;border: 5px solid rgb(230,230,230);padding:15px;margin:0 auto;margin-bottom: 20px;border-radius: 25px;vertical-align: top;">
                  <label style="text-align: center; font-size: 20px;margin-bottom: 20px;">Subjects</label>
                  <p style="font-size: 16px;max-width: 330px;text-align: center;margin:0 auto; margin-bottom: 20px;">
                      The first click adds the subject to your list, the second click takes it out.
                  </p>
                  <select multiple class="form-control" style="height: 180px; width: 350px;margin-top: 20px;margin:0 auto;display:inline-block;background-color: rgb(230,230,230);">
                      <option (click) = 'updateSearch("General",2)'id = 'General'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;">General</option>
                      <option (click) = 'updateSearch("Language Arts",2)' id = 'Language Arts' class="time checkbox btn btn-secondary" style = "display:block; text-align: center;margin-bottom: 3px;">Language Arts</option>
                      <option (click) = 'updateSearch("Mathematics",2)'id = 'Mathematics'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;">Mathematics</option>
                      <option (click) = 'updateSearch("Social Studies",2)'id = 'Social Studies'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;">Social Studies</option>
                      <option (click) = 'updateSearch("Science",2)'id = 'Science'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;">Science</option>
                      <option (click) = 'updateSearch("Creative Arts",2)'id = 'Creative Arts'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;">Creative Arts</option>
                      <option (click) = 'updateSearch("World Language",2)'id = 'World Language'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;">World Language</option>
                  </select> 
              </div>
              <div *ngIf = "chosenService !== undefined" class = "txtBlock" style="width: 90%;background-color: #D8D8D8;min-height:150px;border: 5px solid rgb(230,230,230);padding:15px;margin:0 auto;margin-bottom: 20px;">
                  <textarea maxLength = "250" minLength = "2" ngModel name="fn" #fn ="ngModel" (change) = "storeDesc(fn.value)" id ="desc" type="text" class="form-control"style = "margin:0 auto;width: 85%;margin-top: 20px; max-height:150px; min-height:150px;" value = '{{currentDesc | async}}'></textarea>
                  <button (click) = updateServiceDescription() type="button" style="font-size: 16px;margin-top: 20px;"  class="btn btn-secondary btn-sm">update</button>
              </div>
            </div>
      </div>
  </div>
      <h1 style = "max-width: 800px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
        Upload Image
      </h1>
      <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 800px;text-align: center;margin: 0 auto;">
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">UID</p></label>
          <input ngModel name="fn22" #fn22 ="ngModel" (change) = "UID3 = fn22.value" id ="UID22" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
        </div>
        <div class="custom-file">
          <input type="file" class="custom-file-input" (change) = "upload($event)" accept = "*.png.jpg.pdf"id="inputGroupFile01">
          <label style=" font-size: 16px;margin:0 auto;text-align: center;background-color: rgb(230,230,230);" class="custom-file-label" for="inputGroupFile01"></label>
        </div>
      </div>
      <h1 style = "max-width: 800px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
        add policy
      </h1>
      <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 800px;text-align: center;margin: 0 auto;">
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">UID</p></label>
          <input ngModel name="fn222" #fn222 ="ngModel" (change) = "UID4 = fn222.value" id ="UID222" type="text" class="form-control" style = "margin:0 auto;width: 85%;">
        </div>
        <button (click) = addPolicy() type="button" style="font-size: 16px;" class="btn btn-secondary btn-sm">ADD TEACHER TO SEARCH</button>
      </div>

    </form>

    <!-- auto add teacher to section of teacher search -->
    



    <!-- create meetings manually -->



           
    </div>
</div>
