import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {
  // TS,Value  
  @Input() info
  @Input() TUID
  @Output() reload = new EventEmitter<any>();
  title
  type = ''
  Link = ''
  isFile = false
  isLink = false
  isPersonal = false

  

  constructor(private db: AngularFireDatabase) { }

  ngOnInit(): void {
    var infoHold = this.info
    var T = ''
    var value = ''
    this.db.database.ref('providers/userInfo/' + `${this.TUID}` + '/meeting/storage/' + `${this.info}`).once('value').then(function(snap){
      T = snap.child('type').val()
      value = snap.child('value').val()
      console.log(infoHold,T)
    }).then(()=>{
      this.type = T
      if(T === 'LINK'){
        this.title = 'LINK'
        this.isLink = true
        this.Link = value
      }else if(T === 'FILE'){
        var newDate = new Date(infoHold)
        this.title = newDate.getHours() + ':' + newDate.getMinutes()
        this.isFile = true
      }else if(T === 'PERSONAL'){
        var newDate = new Date(infoHold)
        console.log(newDate.getHours() + ':' + newDate.getMinutes())
        this.title = newDate.getHours() + ':' + newDate.getMinutes()
        this.isPersonal = true
      }
    })
  }

  action(){
    var T = this.type
    if(T === 'LINK'){
      this.title = 'this is a link'
    }else if(T === 'LINKNOW'){
      this.title = 'this is a now link'
    }else if(T === 'FILE'){
      this.reloader()
    }
  }

  newTab(){

  }

  reloader(){
    console.log(this.info)
    this.reload.emit(this.info)
  }

}
