export class TeacherProfile{
    UID:string;
    name:string;
    rate:number;
    stars:number;
    credenitial:string;
    description:string;
    constructor(UID,name,rate,stars,credential,description){
        this.UID = UID
        this.name = name
        this.rate = rate
        this.stars = stars
        this.credenitial = credential
        this.description = description
    }
}
