import { Component, Input, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { answer } from 'src/app/models/answer';

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})
export class AnswerComponent implements OnInit {
  @Input() answer: answer
  date = ''
  time = ''
  name = ''
  formalTitle = ''

  constructor(private database:AngularFireDatabase,private fns:AngularFireFunctions) { }
  //1. link to teachers page(Book Me) 2. teachers name 3. teachers title 4. answer 5. time stamp
  async ngOnInit(){
    console.log(this.answer)
    var dateHold = new Date(this.answer.timeStamp).toString().split(' ')
    this.date = dateHold[1] + ' ' + dateHold[2] + ' ' + dateHold[3] 
    var timeHold = dateHold[4].split(':')
    // this.time = this.formatTime((Number(timeHold[0])*100) + (Number(timeHold[1])))

    var nameHold = ''
    await this.database.database.ref('providers/userInfo/' + `${this.answer.TUID}` + '/immutable/name/val').once('value').then(function(snap){
        nameHold = snap.val()
    })
    this.name = nameHold
    var formalTitleHold = ''
    await this.database.database.ref('providers/userInfo/' + `${this.answer.TUID}` + '/immutable/formalTitle/val').once('value').then(function(snap){
      formalTitleHold = snap.val()
    })
    this.formalTitle = formalTitleHold

  }
  
  formatTime(num:number):string{
    if(num < 1200){
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ " am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + " am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + " am"
        }      
      } 
    }else{
      var holdVar = Math.floor(num/100)-12
      if(holdVar === 0){
        holdVar = 12
      }
      if(num%100 === 0){
        return String(holdVar)+ " pm"
      }else{ 
        return String(holdVar)+":"+String(num%100) + " pm"
      }       

    }
  }
}
