<div style="padding-right:5px;padding-left: 5px;">
  <div class="row">
      <div class="col-md-9 col-sm-6">
          <div class="progress red"> <span class="progress-left"> <span class="progress-bar"></span> </span> <span class="progress-right"> <span class="progress-bar"></span> </span>
            <img class = 'centerImage' src = '../../../assets/Category{{topic}}.svg'>
            <div class = 'centerImage1 blueTheme'>
            </div>
            <p class = 'centerImage2'>{{level}}</p>
          </div>
      </div>
  </div>   
</div>
