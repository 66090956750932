export class Question{
    QUID
    TUIDs
    AUID
    question
    numberOfAnswers  
    timeStamp
    studentParent
    openClosed
    grade
    topic
    subTopic

    constructor(QUID,TUIDs,AUID,question,numberOfAnswers,timeStamp,studentParent,openClosed,grade,topic,subTopic){
        this.QUID = QUID
        this.TUIDs = TUIDs
        this.AUID = AUID
        this.question = question
        this.numberOfAnswers = numberOfAnswers
        this.timeStamp = timeStamp
        this.studentParent = studentParent
        this.openClosed = openClosed
        this.grade = grade
        this.topic = topic
        this.subTopic = subTopic
    }
}