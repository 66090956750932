import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-listing',
  templateUrl: './manage-listing.component.html',
  styleUrls: ['./manage-listing.component.scss']
})
export class ManageListingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
