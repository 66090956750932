<div style="max-width: 1130px;margin: 0 auto;margin-bottom: 30px;padding: 20px;vertical-align: top;border-top-right-radius: 25px; border-bottom-left-radius: 25px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230);font-family: 'Avenir Light';font-size: 18px;color: #292F45;">
    <div style="display: inline-block;vertical-align: top;text-align: left;">
        <p *ngIf = 'closedOpen === 0'style="font-size: 16px;text-align: left;color:green"><li>Active</li></p>
        <p *ngIf = 'closedOpen === 1'style="font-size: 16px;text-align: left;color:#292F45"><li>Closed</li></p>
        <br>
        <p style="font-size: 16px;">{{currResponseNumber}} / 10 responses</p>
        <br>
        <p style="font-size: 16px;">{{date}}</p>
        <p style="font-size: 16px;">{{time}}</p>
        <br>
        <br>
        <div *ngIf = 'teacher'>
            <div class="dropup" >
                <button class= "btn btn-primary dropbtn">Answer Question</button>
                <div class="dropup-content" style="padding:15px;text-align:center">
                    <textarea ngModel name="answerQ" #answerQ ="ngModel" (change) = "newQuestion = answerQ.value" id ="answerQ"  class="form-control txtBlock" style = "width: 600px;font-size:16px;background-color:rgb(230,230,230);vertical-align: top;display:inline-block;text-align: left;;margin:0 auto;min-height: 120px;max-height: 150px;min-height: 150px;" placeholder="Your Answer Here." value= '{{answerRespEmit | async}}' ></textarea>
                    <button class="btn btn-primary"style="background-color: #EA6F66;color: white;margin-top:10px" (click) = "addResponse()">Answer</button>
                    <div *ngFor = "let s of answerErros">
                        <div class = "txtBlock" style="padding-top: 5px;">{{s}}</div>
                    </div>
                </div>
              </div>
        </div>
    </div>
    <div style="display: inline-block; width: 50px;">
    </div>
    <div style="display: inline-block;font-size: 20px; max-width: 880px;margin: 0 auto;">
        <div style="height:120px;overflow-y:auto; max-width: 580px;margin: 0 auto;text-align: center;">
            {{questionEmitter}}
        </div>
        <div *ngIf = 'answers.length === 0'style = 'background-color: #D8D8D8;border: 5px solid rgb(230,230,230);border-radius: 25px; padding:15px;vertical-align:top; height: 180px;width:800px'>
            <div style="max-width: 760px;height:100px;margin: 0 auto;margin-bottom: 30px;padding: 20px;vertical-align: top;border-top-right-radius: 25px; border-bottom-left-radius: 25px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230);font-family: 'Avenir Light';font-size: 18px;">
                No answers yet
            </div>
        </div>
        <div *ngIf = 'answers.length !== 0'style = 'background-color: #D8D8D8;border: 5px solid rgb(230,230,230);border-radius: 25px; padding:15px;overflow-y:scroll;vertical-align:top;height: 180px;width:800px'>
            <app-answer *ngFor = "let answer of answers" [answer] = "answer" style="text-align: center;margin: 0 auto;">
            </app-answer> 
        </div>
    </div>
</div>
