<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
<div>
  <div class="image">
    <h1 style = "width: 85%;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #b0e6f3;color: #1e5663;margin: 0 auto;">
    Thank You For Enrolling!
    </h1>
    <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;width: 85%;;text-align: center;margin: 0 auto;border-top-left-radius: 0px; border-top-right-radius: 0px;">
        <p style="width: 90%;text-align: left;margin: 0 auto;font-size: 16;">
            Once we have completed your credential check you will gain access to your teacher profile. All categories in our
            search menu represent the credentials and skills of our site's active teachers. So, don't worry we will be reaching
            out to see what services you'd like added! Our goal is to have teacher representation from every nook and cranny. 
            It's hard to beat a child being educated by an educator. This is why it is the Verified Teacher vision to create a 
            space where teachers can easily provide their skills to the masses. Not just as a tutor, or a curriculum writer, but
            through any kind of teaching service they can imagine. We want to provide a teacher tailored tool to allow for the
            gig economy to benefit from what teachers have to offer. Let me be the first to say welcome to our platform, a place
            focused on making a difference in and empowering the teaching community.
            <br>
            <br>
            Sincerely, 
            <br>
            <br>
            Alfredo Mendoza, CEO Verified Teachers LLC
            <br>
            <br>
        </p>   
        <a class = "button teach" style =" text-align: center; "href = "#"> Home Page </a>
    </div>
</div>
</div>
