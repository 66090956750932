import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServiceDescription } from 'src/app/models/ServiceDescription';
import { ResizedEvent } from 'angular-resize-event';
import { HostListener } from "@angular/core";

@Component({
  selector: 'app-listing-item',
  templateUrl: './listing-item.component.html',
  styleUrls: ['./listing-item.component.scss']
}) 
export class ListingItemComponent implements OnInit {
  @Input() listing:  ServiceDescription;
  @Output() title = new EventEmitter<string>();
  checked:boolean;  
  count = 2
  width: number; 
  small:boolean;
  height: number;
  @Output() mobile = new EventEmitter<boolean>();
  screenHeight
  screenWidth
  
  constructor() { 
    this.onResize();
  }
 
  ngOnInit(): void {
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
   this.screenHeight = window.innerHeight;
   this.screenWidth = window.innerWidth;
   console.log('h ',this.screenHeight)
   console.log('w ',this.screenWidth)
   if(this.screenWidth < 500){
     console.log(true)
    this.mobile.emit(true)
    this.small = true
  }else{
    console.log(false)
    this.mobile.emit(false)
    this.small = false
  }
  }
  
  onResized(event: ResizedEvent) {
    // event
    // this.width = event.newWidth;
    // this.height = event.newHeight;
    // console.log("h ",this.width)
    // console.log("w ",this.height)
    // if(this.screenWidth < 500){
    //   this.mobile.emit(true)
    // }else{
    //   this.mobile.emit(false)
    // }
    
  }

  format(input){
    if(input === "TTT"){
      return "Teaching Technology Troubleshooting"
    }else if(input === "Special_needs"){
      return "Special Needs"
    }else if(input === "Resume"){
      return "Resume Help"
    }else if(input === "Interview"){
      return "Interview Help"
    }else if(input === "THT"){
      return "Teachers help teachers"
    }else if(input === "Digital_m"){
      return "Digital Media"
    }else if(input === "Digitial_d"){
      return "Digital Design"
    }else if(input === "Collab_tools"){
      return "Collaboration Tools"
    }else if(input === "Online_tools"){
      return "Online Learning Tools"
    }else if(input === "Higher_ed"){
      return "Higher Education"
    }else if(input === "Specialized"){
      return "Specialized Learning"
    }else{
      return input
    }
  }

  addToOrder(){
    this.title.emit(this.listing.title);
    if(this.count%2 ===0){
      this.checked = true
    }else{
      this.checked = false
    }
    this.count += 1
  }



}
