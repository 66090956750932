<div class="image"  style = "font-family: 'Avenir Light';">
    <div style="width: 40%;padding-top: 180px;padding-bottom: 100px;">
        
        <p style="font-size: 35px;line-height: 1;color: #F0F0F0;">
            <strong>
            Sign up to be a Verified Ambassador and start earning exclusive rewards today!
            </strong>
        </p>
        <input required type="text" class="form-control" ngModel name="fnName" #fnName ="ngModel" (change) = "nameNL = fnName.value" placeholder="Name"autocomplete="off" style="background-color:#F0F0F0;font-size: 18px; width: 200px;display: inline-block;margin-right: 10px;"> 
        <input required type="email" class="form-control" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Email"autocomplete="off" style="background-color:#F0F0F0;font-size: 18px; width: 200px;display: inline-block;">
        
        <!-- <div *ngIf = '!sentNL' style="text-align: right;display: inline-block;padding: 20px;">
            <button class="btn btn-primary"style="background-color: #EA6F66;border-color: #EA6F66;color: white;"(click) = "signUp()">Sign Up</button>
        </div> -->
        <div id = 'signUpButt' style="text-align: left;padding-top: 20px;display: block;">
            <button class="btn btn-primary"style="background-color: #EA6F66;border-color: #EA6F66;color: white;"(click) = "signUp()">Sign Up</button>
        </div>     
        <div id = 'signSucc' style="text-align: left;padding-top: 20px;display: none;">
            
            <img src = '../../../../assets/gc.png' style="width:30px;display: inline-block;margin-right:10px;">    
            <p style = 'font-size: 20px;line-height: 1;color: #F0F0F0;display: inline-block;'> Thank you, you will be contacted soon :).</p>
        </div>  
          
    </div>
</div>
<div style="text-align: center; background-color: lightgray; padding: 30px;vertical-align: top;">
    <div style="max-width: 400px;height: 170px;display: inline-block;padding: 15px;font-size: 20px;color:#292F45;vertical-align: top;">
        <p>
            Verified Ambassadors get access to unique promotions, deals, and more. Earn Verified swag, 
            discounted sessions, or even an hour long Verified VIP Experience.
        </p>
    </div>
    <div style="max-width: 400px;height: 170px;display: inline-block;padding: 15px;font-size: 20px;color:#292F45;vertical-align: top;">
        <p>
            Spread the word about Verified Teachers to your friends, family, and anyone else you know who’s looking to connect with an online tutor. 
            The more people you tell, the more rewards you earn!
        </p>
    </div>
    <div style="max-width: 400px;height: 170px;display: inline-block;padding: 15px;font-size: 20px;color:#292F45;vertical-align: top;">
        <p>
            Share your personalized Verified Ambassador code with your network, and climb to a higher reward tier every time it’s used for a new user’s sign up.

        </p>
    </div>
    <div style="width: 90%;margin: 0 auto; height: 10px;border-radius: 10px;background-color: #EA6F66;"></div>
</div>

<div style="text-align: center; background-color: lightgray; padding: 30px;vertical-align: top;">
    <div style="text-align:left;max-width: 400px;display: inline-block;padding: 15px;font-size: 20px;color:#292F45;vertical-align: top;">
        <strong>Spread the word about Verified Teachers</strong>
            <p>
                As a Verified Ambassador, all you have to do is share. Discuss your experience on Verified with friends and family, and mention our teachers to people you know who are looking to connect with online tutors.
            </p>
            <br>
            <p>
                Ambassadors are given a unique code to share with their network. For every sign-up tagged with their code, they’ll move closer to earning their next reward.
            </p>
    </div>
    <div style="text-align:left;max-width: 400px;display: inline-block;padding: 15px;font-size: 20px;color:#292F45;vertical-align: top;">
        <strong>Three tiers of special offers</strong>
            <p>
                Verified Ambassadors get access to exclusive rewards, and they’ll earn more rewards for every new user they refer. 
            </p>
            <br>
            <p>
                Rewards are broken down into tiers, starting a single referral, then 10 referrals, and finally 25 referrals. At every tier Ambassadors can choose between two different rewards.
            </p>
            <br>
            <p>
                With just a single referral, Ambassadors can choose a 10% discount on a session with one of our tutors, or a free copy of our E-book. With 10 referrals, 
                you get to pick between a 25% discount, or additional storage capacity on our platform. At tier 3, we’ll show our thanks with either a free t-shirt 
                (usually only available to Verified staff) or the VIP Verified Experience, where we’ll personality match you with a tutor for a free hour-long session.
            </p>
    </div>
    
    <div style="text-align:left;max-width: 400px;display: inline-block;padding: 15px;font-size: 20px;color:#292F45;vertical-align: top;">
        <strong>Verified Ambassadors help our teachers gain visibility</strong>
            <p>
                Our teachers can deliver a better learning experience than other online tutors, and we want to do everything we can to help them be seen. 
                Verified Ambassadors help get the word out, and give our teachers the traction they need.
            </p>
            <br>
            <p>
                Share your experiences with teachers on the Verified platform, and help them generate the attention they need to get their businesses rolling.
            </p>
    </div>
</div>
