<div class = "txtBlock" style = "width:260px;height: 355px; margin-bottom: 28px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
    <label for = "calendar"> <p style = "font-size: 16px;margin-top: 15px; margin-bottom: 0px;margin-right: 10px;">{{formDay(day.days)}}</p></label>
    <div class="dropdown">
        <li class = "options time">{{chosenDate}}</li>
        <div class="dropdown-content">
            <div *ngFor = "let day of validDays">
                <button 
                type="button"
                style = "text-align: center; margin-top: 3px;margin-bottom: 3px;"
                class="time checkbox btn btn-secondary" 
                (click)="timesForDay(day)"
                >
                 {{dateFormat(day)}}
                </button>     
            </div>            
        </div>
      </div>
    <div style = "width: 247px;height:195px;overflow-y: auto;">
        <div *ngFor = "let day of updateAvailableTimes | async" [day] = "day">
            <button 
            type="button"
            style = "text-align: center;width: 220px; margin-top: 3px;margin-bottom: 3px;"
            class="time checkbox btn btn-secondary" 
            (click)="updateDur(day)">
             {{format(day)}}
            </button>     
        </div>
    </div>
    <p style = "margin-top: 10px;font-size: 16px;">Start: {{getStart()}}    End: {{getEnd()}}   </p>
    
    <button *ngIf = "!errors" type="button" style= "font-size: 16px;color:white;background-color:#292F45;"  class="btn btn-light"(click) = "addToCart()">Add times</button>
    <button *ngIf = "errors" type="button" style= "font-size: 16px;color:white;background-color:#292F45;"  class="btn btn-danger"(click) = "addToCart()">Add times</button>
<div>
