import { Component, OnInit } from '@angular/core';
import { MessengerService } from 'src/app/services/messenger.service';
import { newProduct } from 'src/app/models/newProduct';
import { promise } from 'protractor';



@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})


export class CartComponent implements OnInit {

  cartItems = [

  ];
 
  cartTotal = 0;
  cartName = "";
  cartSession=0;
  constructor(private msg: MessengerService) { }

   ngOnInit(): void {
    this.msg.getMsg().subscribe((product: newProduct) =>{
      console.log(product)
      this.cartItems.push({
        time:product.lengthOfTime,
        rate:product.rate,
        title:product.title
      })

      console.log(this.cartItems)
      this.cartTotal=0;
      this.cartItems.forEach(item=>{
        this.cartTotal+=(item.time * item.rate)
      })
    });
  }
}


// import { Component, OnInit } from '@angular/core';
// import { AngularFireFunctions } from '@angular/fire/functions';
// import { loadStripe, Stripe } from '@stripe/stripe-js';
// import {environment} from '../../../environments/environment'

// @Component({
//   selector: 'app-cart',
//   templateUrl: './cart.component.html',
//   styleUrls: ['./cart.component.scss']
// })
// export class CartComponent implements OnInit {
//   stripe:Stripe;
//   total = 5;
//   isGettingCheckout = false;

//   constructor(private fns: AngularFireFunctions) { 
//   }

//   async ngOnInit(){
//     this.stripe = await loadStripe(environment.stripeKey);
//     const elements = this.stripe.elements();

//     const style = {
//       base: {
//         color: '#32325d',
//         fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
//         fontSmoothing: 'antialiased',
//         fontSize: (window.innerWidth <= 500) ? '12px' : '16px',
//         '::placeholder': {
//           color: '#aab7c4'
//         }
//       },
//       invalid: {
//         color: '#fa755a',
//         iconColor: '#fa755a'
//       }
//     };

//     const card = elements.create('card', { style });


//     card.mount('#card-element');

//     card.on('change', (event) => {
//       const displayError = document.getElementById('card-errors');
//       if (event.error) {
//         displayError.textContent = event.error.message;
//       } else {
//         displayError.textContent = '';
//       }

//     });

//     const button = document.getElementById('button');
//     button.addEventListener('click', (event) => {
//       event.preventDefault();
//       const ownerInfo = {
//         owner: {
//           name : 'user'
//         },
//         amount: 20000,
//         currency: 'usd'
//       };

//       this.stripe.createSource(card, ownerInfo).then((result) => {
//         console.log(result);
//         if (result.error) {
//           const errorElement = document.getElementById('card-errors');
//           errorElement.textContent = result.error.message;
//         } else {
//           this.stripeSourceHandler(result.source);
//         }
//       });
//     });
//   }

//   private stripeSourceHandler(source): void {
//     const callable = this.fns.httpsCallable('stripeChargeCall');
//     const obs = callable(source);
//     obs.subscribe(res => {
//       console.log(res);
//       if (res.result === 'SUCCESSFUL') {
//         document.getElementsByClassName('text')[0].innerHTML = 'Flower Paid 💸, Thanks';
//       } else {
//         document.getElementsByClassName('text')[0].innerHTML = 'Something went wrong. 😞';
//       }
//     });
//   }





  // async checkout(){
  //   console.log("hello")
  //   this.isGettingCheckout = true
  //   const createCheckoutSession = this.fns.httpsCallable('createCheckoutSession');
  //   console.log(createCheckoutSession)
  //   if(createCheckoutSession !== null){
  //     createCheckoutSession({
  //       product_name: "tutoring",
  //       unit_amount: 1,
  //     })
  //     .toPromise()
  //     .then((sessionId) => this.stripe.redirectToCheckout(sessionId))
  //     .catch((e) => console.log('ERR',e))
  //     .finally(()=>this.isGettingCheckout = false)
  //   }
  // }


