
<div class="container-center-horizontal">
    <div class="frame-340 screen">
      <div class="overlap-group1">
        <div class="group-288">
          <div class="group-158">
            <div class="place h4">Name</div>
            <input ngModel name="fn1" #fn1 ="ngModel" (change) = "name = fn1.value" class="overlap-group7 text-4 body" placeholder="Name...">
          </div>
          <div class="group-1">
            <div class="personal-email h4">Email</div>
            <input ngModel name="fn2" #fn2 ="ngModel" (change) = "contactEmail = fn2.value" class="overlap-group-1 text-4 body" placeholder="Email...">
          </div>
          <div class="group-163">
            <div class="personal-email h4">Question</div>
            <textarea ngModel name="fn3" #fn3 ="ngModel" (change) = "question = fn3.value"  class="overlap-group6 text-4" placeholder="Question..."></textarea>
          </div>
        </div>
        <div *ngIf = '!sent'class="frame-75" style="z-index: 1;cursor: pointer;" (click) = 'ask()'><div class="sign-up body">ASK</div></div>
        <div *ngIf = 'sent'class="frame-75"><div class="sign-up body">Thank You!</div></div>
      </div>
      <div class="overlap-group">
        <h1 class="text-10 h2">FAQ</h1>

        <div class="group-291">
          <p class="text-1-1 h4">Is this a tutoring site?</p>
          <p class="text-17 body">
            Kinda, we think of our teachers more as consultants than anything else. Whether you need tutoring, help 
            coordinating a pod or just have ask a personal question our Verified Teachers are here to help.
          </p>
          <p class="text-1-1 h4">How do I make an appointment?</p>
          <p class="text-17 body">
            It’s easy! You can search directly from our home screen, or head over to the Verified Marketplace to browse our
             entire roster of teachers directly. If you want help with something specific, posting a question on our Verified 
             Answers board will connect you with teachers who can help with exactly what you’re struggling with. If you like 
             the answer you get, booking a session with that teacher is as easy as clicking their name. Once you’re brought to 
             a teacher’s profile, all you need to do is send them a request with your details, and they’ll take it from there!
          </p>
          <p class="text-1-1 h4">Why do you use a request system for appointments?</p>
          <p class="text-17 body">
            We want to make connecting with teachers as easy as possible for everyone involved. By letting you share your 
            availability with the teachers that you’re interested in, both of you can work together to find the rates and times 
            that make everyone happy.
          </p>
          <p class="text-1-1 h4">How do you make money?</p>
          <p class="text-17 body">
            We keep 20% of the proceeds from all bookings made on the Verified Marketplace, and try to keep this number as low 
            as possible to put more money in our teachers’ hands. 
          </p>
          <p class="text-1-1 h4">How do teachers make money?</p>
          <p class="text-17 body">
            Teachers are paid 80% of the proceeds from each of their lessons, and we ’re working to bring that number up even 
            higher. We’re proud to tell you that we pay our teachers 300% as much as our competitors.
          </p>
          <p class="text-1-1 h4">Is there a minimum number of hours teachers have</p>
          <p class="text-1-1 h4">to work?</p>
          <p class="text-17 body">
            Nope, once you gain access to your Verified Teaching account, you are free to do with it as you will.
             Our goal is to give you an easy way to get noticed and get paid.
          </p>
          <p class="text-1-1 h4">Who can be a Verified Teacher?</p>
          <p class="text-17 body">
            You can ask as many questions to the Verified Teachers team as you’d like!
          </p>
          <p class="text-1-1 h4">How did you start?</p>
          <p class="text-17 body">
            Speaking with thousands of teachers across the country taught us one thing: teachers are struggling. As 
            classrooms shift into online spaces, we knew there was an opportunity to help teachers take ownership of their own value. It’s time to make a difference.
          </p>
          <p class="text-1-1 h4">Why was I charged 9% at check out?</p>
          <p class="text-17 body">
            Service taxes add a 9% surcharge to teacher’s posted rates.
          </p>

          <p class="text-1-1 h4">Can non-teacher members of the K-12 community</p>
          <p class="text-1-1 h4">be involved?</p>
          <p class="text-17 body">
            Not yet, but we are already working to bring more offerings to our platform. We’re currently working with school 
            counselors to explore how we can bring them aboard as well. We want to help in every way we can!
          </p>
          <p class="text-1-1 h4">How do cancellations work?</p>
          <p class="text-17 body">
              Each teacher has their own cancellation policies which are clearly displayed on their profile. Always review a
              teacher’s cancellation policies before cancelling an appointment.
          </p>
          <p class="text-1-1 h4">How can I help?</p>
          <p class="text-17 body">
            Book an appointment today!
          </p>
        </div>
      </div>
    </div>
  </div>