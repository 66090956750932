<div class="image" style = "font-family: 'Avenir Light';margin: 0 auto;">
        <div style = "max-height: 200px; overflow-y:scroll;">
            <p>This is a legal agreement ("Agreement") between you (“Independent Instructor”) and Verified Teachers LLC,("VerifiedTeachers"). The parties expressly agree to transact and enter into this Agreement by electronic means within the meaning of the Uniform Electronic Transactions Act ("UETA") and the Electronic Signatures and Records Act.
              1. Permission to use VerifiedTeachers.commarketplace. The Independent Instructor acknowledges that VerifiedTeachers.com provides an Internet-based marketplace for individuals seeking the services of an instructor to identify and retain the services of individuals seeking to provide instructional services. By entering into this Agreement, the Independent Instructor seeks permission to access VerifiedTeachers.com’s marketplace subject to the terms and conditions set forth in this Agreement.
              2. Instructor status. Independent Instructor acknowledges that VerifiedTeachers provides only an Internet-based marketplace for individuals seeking the services of an Instructor to identify and retain the services of individuals seeking to provide Instructional services. Independent Instructor further acknowledge that VerifiedTeachers does not directly or indirectly engage Independent Instructor to render any services whatsoever and that any engagement of Independent Instructor’s services through the VerifiedTeachers website (“Site”) is undertaken exclusively by the individual who has selected Independent Instructor through the Site to provide Instructoring services ("Instructor’s Student"). Neither this Agreement, the Terms of Use, nor use of the Site creates an independent contractor, employee/employer, partnership, joint venture, or franchiser-franchisee relationship between Independent Instructor and VerifiedTeachers. It is the parties' intention that Independent Instructor will be an independent business providing Instructoring services and not an independent contractor of VerifiedTeachers or VerifiedTeachers employee for any purposes, including, without limitation, the application of the Fair Labor Standards Act minimum wage and overtime provisions or any similar state law, Federal Insurance Contribution Act, the Social Security Act, the Federal Unemployment Tax Act, the provisions of the Internal Revenue Code, any state income tax law, or any local income tax law, any state workers' compensation laws, any state unemployment insurance law, and any other federal or state law.
              3. Non-exclusivity. Independent Instructor and VerifiedTeachers acknowledge that this Agreement is nonexclusive and Independent Instructor is free to engage in and simultaneously perform any employment or other services during the pendency of this Agreement. Nothing herein precludes Independent Instructor from advertising or providing Independent Instructor’s services to the general public. Independent Instructor may not advertise or offer to sell any goods or
              services for any commercial purpose on the Site that are not relevant to providing
              Instructoring services on an independent basis.
              4. Hours/Cancellation Fee. Independent Instructor shall set Independent Instructor’s
              own hours and schedule Independent Instructor’s own sessions with Instructor’s Students. Independent Instructor shall determine Independent Instructor’s cancelation fee, if any, in the event a Instructor’s Student cancels or does not show for a session.
              5. Independent Instructor consents to pay a fee for the use of the Site (“Platform Fee”) and authorizes VerifiedTeachers to deduct the Platform Fee directly from the Instructor’s Students’ payments VerifiedTeachers collects on behalf of Independent Instructor. The Platform Fee shall be a percentage of the amount paid by Instructor’s Students to VerifiedTeachers. The percentage shall be as set forth in the Instructor Payment Policies. Independent Instructor agrees that under no circumstances will Independent Instructor attempt to seek or receive compensation directly from Instructor’s Students.
              6. Payment system. Independent Instructor agrees to abide by VerifiedTeachers’ Instructor Payment Policies and agrees not to subvert the payment system or to divert funds ordinarily payable to Independent Instructor through VerifiedTeachers. Prior to commencing Instructoring sessions or meeting with Instructors Students for any purpose, Independent Instructor must verify with VerifiedTeachers that the prospective Instructor’s Student has submitted appropriate billing information to VerifiedTeachers. VerifiedTeachers is not responsible for payments for sessions given before a prospective Instructor’s Student has appropriate billing information on file. Independent Instructor will report the length of each session completed through the Independent Instructor's administrative page on the Site so that VerifiedTeachers may initiate collection of Independent Instructor’s fees. If requested by Instructor’s Student and agreed to by Independent Instructor, then Independent Instructor will include a summary of service performed with the length of the session reported for solely for Instructor’s Student’s use. Subject to the review by the Instructor’s Student of the session times submitted, Independent Instructor shall receive payment in accordance with VerifiedTeachers' Instructor Payment Policies.
              7. Expenses. Independent Instructor acknowledges that VerifiedTeachers will not reimburse Independent Instructor for any expenses incurred by Independent Instructor related to Independent Instructor’s rendering of services, including, without limitation, expenses associated with preparing for and administering sessions, educational and other materials, and transportation.
              8. Supplies. Independent Instructor acknowledges that VerifiedTeachers will not provide any supplies, materials or equipment required by Independent Instructor
              
              to complete the services for which Instructor’s Student retained Independent Instructor. Without notice or approval of VerifiedTeachers, Independent Instructor may enter into an agreement or arrangement with Instructor’s Student whereby Instructor’s Student provides some or all of the supplies, materials or equipment necessary for Independent Instructor to render the services for which Instructor’s Student retained Independent Instructor.
              9. Taxes. Independent Instructor is responsible for all federal, state, and/or local taxes due as a result of the services Independent Instructor provides to Instructor’s Students. Neither federal, nor state, nor local income tax, nor payroll tax of any kind shall be withheld by VerifiedTeachers on behalf of Independent Instructor. Independent Instructor understands that it is Independent Instructor’s responsibility to pay any applicable taxes.
              10.Benefits. Independent Instructor acknowledges that Independent Instructor is engaged in an independent business and not as an employee, and is not eligible to participate in any pension, health, or other fringe benefits plan of VerifiedTeachers.
              11.Insurance. No Workers' Compensation Insurance shall be obtained by VerifiedTeachers for Independent Instructor. Independent Instructor understands that it is Independent Instructor’s responsibility to comply with the Workers' Compensation Law. Independent Instructor further understands it is Independent Instructor’s responsibility to obtain any other insurance coverage applicable to the services Independent Instructor provides to Instructor’s Students.
              12.Confidentiality. Independent Instructor acknowledges that, during the performance of services, Independent Instructor will have access to information relating to student's identity, address, contact information, and other personal information or requests for services ("Confidential Information"). Independent Instructor agrees that Independent Instructor owes a duty to Instructor’s Students, during the term of this Agreement and thereafter, to hold all such Confidential Information in the strictest confidence and not to disclose it to any person, firm or corporation or to use it except as necessary in carrying out the services consistent with this Agreement.
              13.Instructor documents. Independent Instructor acknowledges that a copy of all documents created or received by Independent Instructor in connection with the administration of services performed hereunder ("Instructor Documents") may be retained by VerifiedTeachers and used for its administrative purposes. For purposes of this Agreement, Instructor Documents does not include materials solely associated with the teaching of Sessions including Session plans, notes, outlines, tests and homework and VerifiedTeachers acknowledges these materials belong to the Independent Instructor.
              
              14.Indemnity and hold harmless clause. Independent Instructor agrees to indemnify and hold harmless VerifiedTeachers, its subsidiaries, affiliates, other independent contractors and their respective directors, officers, employees and agents, from and against any and all claims, losses, expenses, costs, liabilities and damages (including any legal fee and expenses), any and all injuries, whether direct, consequential or incidental in nature, which result from, are connected with or arise out of the performance by Independent Instructor of work performed in relation to this Agreement, including any act by Independent Instructor inconsistent with Independent Instructor's obligations and duties under this Agreement. Independent Instructor understands that VerifiedTeachers offers an Internet-based marketplace for those seeking Instructoring services to connect with those seeking to provide Instructoring services. Accordingly, VerifiedTeachers makes no representations to Independent Instructor about the suitability, character or background of any VerifiedTeachers user (including, without limitation, Instructor’s Students or their parents or guardians), nor does VerifiedTeachers perform any type of background check of users including, without limitation, Instructor’s Students or their parents or guardians). It is Independent Instructor's sole duty to exercise Independent Instructor’s own judgment, protocols and/or standards when considering whether to engage in Instructoring services with a potential Instructor’s Students. Independent Instructor understands and agrees that it is Independent Instructor’s responsibility to conduct any and all background and reference checks regarding potential Instructor’s Students and other Site users. Independent Instructor is never obligated by VerifiedTeachers to pursue any Instructoring opportunity.
              15.Copyright Assignment. VerifiedTeachers does not claim ownership of content that Independent Instructor provides on the Site including, but is not limited to, articles, lessons, worksheets, Instructorials, files, testimonials, reviews, video submissions, and blog entries ("Site Content") and shall have no obligation of any kind with respect to such content. Unless otherwise stated herein, any Site Content that Independent Instructor provides in connection with the Site shall be deemed to be provided on a non-confidential basis. VerifiedTeachers shall be free to use or disseminate such Site Content on an unrestricted basis for any purpose, and Independent Instructor grants VerifiedTeachers an irrevocable, worldwide, royalty-free, nonexclusive license to use, reproduce, modify, distribute, transmit, display, perform, adapt, resell and publish any Site Content (including in digital form). Independent Instructor represents and warrants that Independent Instructor has proper authorization for the worldwide transfer and processing among VerifiedTeachers, its affiliates, and third-party providers of any information that Independent Instructor may provide on the Site.
              
              16.Assignment. Independent Instructor may not assign or transfer this Agreement without the prior written consent of VerifiedTeachers.
              17.Academic Dishonesty. Independent Instructor agrees not to use the Site to engage in academic dishonesty (​e.g.,​ completing assignments, writing papers, taking tests on someone’s behalf, or work completion in violation of conduct policies of a school, university, academic institution or workplace) or other misconduct involving a Instructor’s Student.
              18.Deactivation of Platform Use. Independent Instructor may deactivate their account at any time. VerifiedTeachers may deactivate an Independent Instructor’s account for the following reasons:
              (a) Independent Instructor’s failure to meet VerifiedTeachers’ platform vetting requirements.
              (b) Independent Instructor’s material breach of this Agreement, Independent Instructor’s violation of the VerifiedTeachers Terms of Use or Instructor Payment Policies, or misuse of the Site.
              (c) VerifiedTeachers’ good faith belief of Independent Instructor’s misappropriation of Instructor’s Students from the Site for Independent Instructor’s own pecuniary gain.
              (d) VerifiedTeachers’ good faith belief of entry of a restraining order or conviction of any felony or misdemeanor involving violence, sexual conduct, conduct involving a minor, abuse, fraud, larceny, or endangerment.
              (e) Independent Instructor’s failure to cooperate in good faith to resolve a Instructor Student’s complaint or excessive complaints.
              (f) Independent Instructor’s repeated failure to respond timely to direct contacts through Instructor’s account.
              (g) For any other reason on 30 days written notice to Independent Instructor.
              In the event VerifiedTeachers terminates Independent Instructor’s platform use, VerifiedTeachers will provide an email notification of deactivation.
              1. Amendment. This Agreement may only be amended or modified by a writing which makes express reference to this Agreement as the subject of such amendment and which is signed by the Independent Instructor and, on behalf of VerifiedTeachers, by its duly authorized officer. The parties agree that any amendment or modification hereunder may be transacted by electronic means
                
              within the meaning of the UETA and the Electronic Signatures and Records Act provided that any such amendments or modifications otherwise comply with the requirements in this paragraph.
              2. Severability. If any term, provision, covenant or condition of this Agreement or part thereof, or the application thereof to any person, place or circumstance, shall be held to be invalid, unenforceable or void, the remainder of this Agreement and such term, provision, covenant, or condition shall remain in full force and effect, and any such invalid, unenforceable or void term, provision, covenant or condition shall be deemed, without further action on the part of the parties hereto, modified, amended and limited to the extent necessary to render the same and the remainder of this Agreement valid, enforceable and lawful.
              3. Incorporation of terms of use. The VerifiedTeachers Terms of Use are hereby incorporated into this Agreement.
              4. Governing law. This Agreement shall be governed by and construed in accordance with the substantive laws of the State of California, without regard to conflict of law rules.
              5. Arbitration. Any dispute between the parties related to this Agreement shall be resolved by binding arbitration in accordance with the Arbitration provision in the VerifiedTeachers Terms of Use.
              Entire agreement. This Agreement contains the entire agreement and understanding between the parties hereto in respect to the subject matter hereof and supersedes, cancels and annuls any prior or contemporaneous written or oral agreements, understandings, commitments, and practices between them respecting the subject matter hereof, including all prior agreements, if any, between VerifiedTeachers and Independent Instructor, which agreement(s) hereby are terminated and shall be of no further force or effect.</p>
          </div>
</div>
