// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  firebaseConfig: {
        apiKey: "AIzaSyB2jRRhAeQbG-MqzZ_L71C08ko8KB0uMj4",
        authDomain: "v-t-dev.firebaseapp.com",
        databaseURL: "https://v-t-dev.firebaseio.com/",
        projectId: "v-t-dev",
        storageBucket: "v-t-dev.appspot.com",
        messagingSenderId: "1056890261822",
        appId: "1:1056890261822:web:be4eec4a1b1c3de2851c6f",
        measurementId: "G-63774201H0"
  },
  stripeKey: 'pk_test_51HaROnJOldQTBUMIaqLLQGH53741oOsBOpkDW7S6q5nTGn3sQQXXZpPyArbf4kMDGJsZS8bxc3zlmya9jcMWr6yI00kwvrEmE1',
  stripeKeyS: 'sk_test_51HaROnJOldQTBUMIHDGn7xasBsFJIcqNhfjd0yhV6JjxkuQB066LxlrJqXO1fynTIamtg321Jqimw4b4ABKrztQf00oK4fntOM',
  stripeS: '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',
  stripeP: 'pk_live_51HaROnJOldQTBUMIIVaOPOtCri5wJVc3RILJkAXtfFQVPC0iCAam0eysstxIxP28P8o28CppMty22b9aLr4gwSC600B1cvVtxW'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
