import { Component, OnInit,Output,EventEmitter, HostListener } from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/functions'
import { ResizedEvent } from 'angular-resize-event';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
}) 
export class FooterComponent implements OnInit {
  email = ""
  message = ""
  errorMsg = []
  width: number;
  height: number;   
  mobile1:boolean;
  @Output() mobile = new EventEmitter<boolean>();
  notSent = true
  @Output() notSent1 = new EventEmitter<boolean>();
  screenHeight   
  screenWidth
  constructor(private fns:AngularFireFunctions) { 
    this.onResize();
  }
   
  ngOnInit(): void {
    // this.notSent.emit(true) 
  }

  complete(){
    if(this.email !== "" && this.message !== ""){
      this.notSent = false
      this.sendEmail()
      this.errorMsg = ["Message sent!"]
    }else{

      this.errorMsg = ["You need both an email and a message"]
    }
  }

  reRoute(val){
    window.open(val, '_blank');
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if(this.screenWidth < 500){

      this.mobile.emit(true)
      this.mobile1 = false
    }else{

      this.mobile.emit(false)
      this.mobile1 = true
    }
  }  

  sendEmail(){
    const newTeacherEmail = this.fns.httpsCallable('newTeacherEmail')
    newTeacherEmail({
      name: 'this is an email from our footer',
      school:'',
      uid:this.email,
      emailP:this.message,
      emailS:Intl.DateTimeFormat().resolvedOptions().timeZone
    }).toPromise().then(()=> console.log("success")).catch((e)=> console.log('err',e))
  }
  

}
