
<div class="container-center-horizontal noSelect">
    <div class="frame-483 screen" data-id="1535:6841">
      <div class="overlap-group1" data-id="an|aXAmESDE">
        <div class="group-457" data-id="1535:6763">
          <img
            class="x"
            (click) = 'closeMe()'
            data-id="1535:6765"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png"
          />
        </div>
        <h1 class="title worksans-semi-bold-abbey-32px" data-id="1535:6768">New Lesson Name:</h1>
        <div id = 'lessonName' class="overlap-group6-4">
            <!-- <input type="email" class="name inter-normal-gunsmoke-14px-2" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Name"autocomplete="off"> -->
            <input id = 'name' maxLength = "25" minLength = "2" type="text" class = "text-7-8-1 inter-normal-steel-teal-14px"ngModel name="fn5" #fn5 ="ngModel" (change) = "lessonName = fn5.value" placeholder="Enter Lesson Name..." value= '{{lessonName}}'>
        </div>
        <input  required type="file" class="custom-file-input" (change) = "storeFile($event)" accept = ".xlsx,.xls,image/*,.doc,.docx,.ppt, .pptx,.txt,.pdf"id="inputGroupFile009" style="display: none;">
        <div style="position: absolute;left: 21px;top: 35px;">
            <div class="overlap-group-1-1"style="cursor: pointer;" (click)='toggleCat("History")'>
                <span id = 'HistoryNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">History</p></span>
                <img class="vector-93" src="../../../assets/CategoryHistory.svg" />
                <img id = 'HistorySelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                />
            </div>
            <div class="overlap-group-1-2"style="cursor: pointer;" (click)='toggleCat("Math")'>
                <span id = 'MathNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Math</p></span>
                <img class="vector-93" src="../../../assets/CategoryMath.svg" />
                <img id = 'MathSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                />
            </div>
            <div class="overlap-group-1-3"style="cursor: pointer;" (click)='toggleCat("Science")'>
                <span id = 'ScienceNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Science</p></span>
                <img class="vector-93" src="../../../assets/CategoryScience.svg" />
                <img id = 'ScienceSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                />
            </div>
    
            <div class="overlap-group-1-4"style="cursor: pointer;" (click)='toggleCat("LanguageArts")'>
                <span id = 'LanguageArtsNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">English</p></span>
                <img class="vector-93" src="../../../assets/CategoryLanguageArts.svg" />
                <img id = 'LanguageArtsSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                />
            </div>
            <div class="overlap-group-1-5"style="cursor: pointer;" (click)='toggleCat("WorldLangauge")'>
                <span id = 'WorldLangaugeNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">World Lang</p></span>
                <img class="vector-93" src="../../../assets/CategoryWorldLangauge.svg" />
                <img id = 'WorldLangaugeSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                />
            </div>  
            <div class="overlap-group-1-6"style="cursor: pointer;" (click)='toggleCat("CreativeArts")'>
                <span id = 'CreativeArtsNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Art</p></span>
                <img class="vector-93" src="../../../assets/CategoryCreativeArts.svg" />
                <img id = 'CreativeArtsSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                />
            </div>
    
            <div class="overlap-group-1-7"style="cursor: pointer;" (click)='toggleCat("LifeSkills")'>
                <span id = 'LifeSkillsNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Life Skills</p></span>
                <img class="vector-93" src="../../../assets/CategoryLifeSkills.svg" />
                <img id = 'LifeSkillsSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                />
            </div>
            <div class="overlap-group-1-8"style="cursor: pointer;" (click)='toggleCat("SocialDevelopment")'>
                <span id = 'SocialDevelopmentNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Social Dev</p></span>
                <img class="vector-93" src="../../../assets/CategorySocialDevelopment.svg" />
                <img id = 'SocialDevelopmentSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                />
            </div>
            <div class="overlap-group-1-9"style="cursor: pointer;" (click)='toggleCat("PersonalDevelopment")'>
                <span id = 'PersonalDevelopmentNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Personal Dev</p></span>
                <img class="vector-93" src="../../../assets/CategoryPersonalDevelopment.svg" />
                <img id = 'PersonalDevelopmentSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                />
            </div>
        </div>

        <div class="group-458 border-1px-blue"id="drop_zone" style="display: flex;
        flex-direction: row;
        justify-content: center;">
          <div class="frame-267" data-id="1535:6787">
            <img
              class="download"
              data-id="1535:6788"
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/download-1@2x.png"
            />
            <p class="text-1 worksans-semi-bold-chathams-blue-14px" data-id="1535:6792">
              Upload Files or Drag &amp; Drop
            </p>
          </div>
        </div>
        <!-- <div class="group-459" data-id="1535:6793">
          <div class="description inter-semi-bold-chathams-blue-16px" data-id="1535:6795">Description</div>
          <div class="overlap-group" data-id="an|rP5xbihm"><textarea class="text body" data-id="1535:6796" value="{{fileDesc}}" placeholder="Description" ngModel name="fn2" #fn2 ="ngModel" (change) = updateDesc(fn2.value)></textarea></div>
        </div>   
        <div class="frame-355" data-id="1535:6797">
          <div class="place inter-semi-bold-chathams-blue-16px" data-id="I1535:6797;709:191">Name</div>
          <div class="group-314-1" data-id="I1535:6797;709:257">
            <div class="overlap-group-1" data-id="an|1xJfq6l5">
              <input class="first-name-middle-i body" type = 'text' data-id="I1535:6797;709:192" id = 'name' placeholder="File Name" value="{{fileName}}" ngModel name="fn1" #fn1 ="ngModel" (change) = updateName(fn1.value)>
            </div>
          </div>
        </div> -->
        <div id = 'dropShip' class="group-314-2" data-id="I1535:6798;709:318" style="display: none;">
          <div *ngIf = 'topic'  style="z-index: 1;cursor: pointer;width: 100%;overflow-y: auto;">
            <div *ngFor = "let services of topics">    
              <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'z-index:1;cursor:pointer;width:100%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="{{services}}" id = '{{services}}' (click) = updateTopic(services)/>
            </div>
          </div> 
          <div *ngIf = 'subtopic' style="z-index: 1;cursor: pointer;width: 100%;overflow-y: auto;">
            <div *ngFor = "let services of subtopics">    
              <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:100%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="{{services}}" id = '{{services}}'(click) = updateSubtopic(services)/>
            </div>
          </div>   
        </div>
        <!-- <div *ngFor = "let topic of topics">   
          <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'display:inline-block;z-index: 1;cursor: pointer;width: 325px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);height:50px;position: relative;' value="{{topic}}" id = '{{topic}}'/>
        </div> -->
        <!-- <div class="frame-356" data-id="1535:6798">
          <div class="name-1 inter-semi-bold-chathams-blue-16px" data-id="I1535:6798;709:317">Topics</div>
          <div class="group-314" data-id="I1535:6798;709:318" style="z-index: 1;cursor: pointer;"(click) = 'logTopic()'>
            <div class="frame-353" data-id="I1535:6798;709:331">
              <div class="name body" data-id="I1535:6798;709:320">{{fileTopic}}</div>
              <img
                class="frame-205"
                data-id="I1535:6798;709:312"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/frame-205@2x.png"
              />
            </div>
          </div>
        </div>
        <div class="frame-357" data-id="1535:6799">
          <div class="name-2 inter-semi-bold-chathams-blue-16px" data-id="I1535:6799;709:317">Subtopics</div>
          <div class="group-314" data-id="I1535:6799;709:318"style="z-index: 1;cursor: pointer;" (click) = 'logSubtopic()'> 
            <div class="frame-353" data-id="I1535:6799;709:331">
              <div class="name body" data-id="I1535:6799;709:320">{{fileSubtopic}}</div>
              <img
                class="frame-205"
                data-id="I1535:6799;709:312"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/frame-205@2x.png"
              />
            </div>
          </div>
        </div> -->
        <img
          class="line-7"
          data-id="1535:6800"
          src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/line-6@1x.png"
          
        />
        <img *ngIf="loadedFiles.length === 0" id = 'emptySearch' src = '../../../assets/emptyFileSearch.svg' style = '
                  position: absolute;
                    width: 418px;
                    left: 51%;
                    top: 540px;
                    transform: translate(-50%, -50%);'
        >
        <img *ngIf="loadedFiles.length > 0"id = 'leftArrow' src="../../../assets/chevron-left.svg" style="
            position: absolute;
            top: 492px;
            left: 30px;
            z-index: 1;
            width: 35px;
            height: 35px;
            cursor: pointer;"
        (click) = prevPage()>
        <img *ngIf="loadedFiles.length > 0" id = 'rightArrow' src="../../../assets/chevron-right.svg" style = '
        position: absolute;
        top: 492px;
        left: 1039px;
        z-index: 1;
        width: 35px;
        height: 35px;
        cursor: pointer;'
        (click) = nextPage()>
        <div style="position:absolute;left:88px;top: 188px;">
            <img id = 'image1' class="frame-358" src = '' style="display: none;">
            <img id = 'image2' class="frame-358-1" src = '' style="display: none;">
            <img id = 'image3' class="frame-358-2" src = '' style="display: none;">
            <img id = 'image4' class="frame-358-3" src = '' style="display: none;">
            <div id = 'x1' class="group-460 border-1px-blue"(click) = 'deleteFile(1)' data-id="1535:6806" style="display: none;"><img data-id="1535:6808" src="../../../assets/greyX.png" /></div>
            <div id = 'x2' class="group-461 border-1px-blue" (click) = 'deleteFile(2)'data-id="1535:6811" style="display: none;"><img data-id="1535:6813" src="../../../assets/greyX.png" /></div>
            <div id = 'x3' class="group-462 border-1px-blue"(click) = 'deleteFile(3)'data-id="1535:6816" style="display: none;"><img data-id="1535:6818" src="../../../assets/greyX.png" /></div>
            <div id = 'x4' class="group-462-1 border-1px-blue" (click) = 'deleteFile(4)'style="display: none;"><img data-id="1535:6818" src="../../../assets/greyX.png" /></div>
            <div id = 'fileName1' class="overlap-group6"style="display: none;">
                <!-- <input type="email" class="name inter-normal-gunsmoke-14px-2" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Name"autocomplete="off"> -->
                <input id = 'name1' maxLength = "25" minLength = "2" type="text" class = "text-7-8 inter-normal-steel-teal-14px"ngModel name="fn1" #fn1 ="ngModel" (change) = "editNameOfFile(fn1.value,0)" placeholder="Name File" value= '{{fileName1}}'>
            </div>
            <div id = 'fileName2' class="overlap-group6-1"style="display: none;">
                <!-- <input type="email" class="name inter-normal-gunsmoke-14px-2" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Name"autocomplete="off"> -->
                <input id = 'name2' maxLength = "25" minLength = "2" type="text" class = "text-7-8 inter-normal-steel-teal-14px"ngModel name="fn2" #fn2 ="ngModel" (change) = "editNameOfFile(fn2.value,1)" placeholder="Name File" value= '{{fileName2}}'>
            </div>
            <div id = 'fileName3' class="overlap-group6-2"style="display: none;">
                <!-- <input type="email" class="name inter-normal-gunsmoke-14px-2" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Name"autocomplete="off"> -->
                <input id = 'name3' maxLength = "25" minLength = "2" type="text" class = "text-7-8 inter-normal-steel-teal-14px"ngModel name="fn3" #fn3 ="ngModel" (change) = "editNameOfFile(fn3.value,2)" placeholder="Name File" value= '{{fileName3}}'>
            </div>
            <div id = 'fileName4' class="overlap-group6-3"style="display: none;">
                <!-- <input type="email" class="name inter-normal-gunsmoke-14px-2" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Name"autocomplete="off"> -->
                <input id = 'name4' maxLength = "25" minLength = "2" type="text" class = "text-7-8 inter-normal-steel-teal-14px"ngModel name="fn4" #fn4 ="ngModel" (change) = "editNameOfFile(fn4.value,3)" placeholder="Name File" value= '{{fileName4}}'>
            </div>
        </div>
        <div class="frame-361" data-id="1535:6804"(click) = 'createLesson()' style="z-index: 1;cursor: pointer;">
          <div class="add-all-resources worksans-semi-bold-concrete-14px" data-id="1535:6805">Create Lesson</div>
        </div>

      </div>
    </div>
  </div>
