export class articleThumb{
    AUID:string;
    title:string;
    subTitle: string;
    readTime:string;
    cat:string
       
    constructor(AUID,title,subtitle,readTime,cat){
        this.AUID = AUID
        this.title = title
        this.subTitle = subtitle
        this.readTime = readTime
        this.cat = cat
    }
}