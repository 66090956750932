<div class="container-center-horizontal noSelect">
  <div class="marketplace screen">
    <div class="overlap-group">
      <div class="group-208">
        <img
        src="../../../assets/Group-215.png"
       />
      </div>
      <div class="group-207">
        <h1 class="text-3 worksans-semi-bold-abbey-32px">Find The Perfect<br />Verified Teacher</h1>
        <div class="rectangle-59"></div>
        <p class="text-4 inter-normal-mine-shaft-14px">
          Use our Verified Marketplace to find teachers based on their subject, grade level, and availability. Find the
          perfect teacher for your student’s needs!
        </p>
        <div class="group-101">
          <div class="overlap-group2-2">
            <div class="frame-97">
              <div  id = 'subject' (click)='select("subject")' class="frame-207">
                <div id = 'subjectTag' class="subject-13 inter-semi-bold-chathams-blue-14px">Subject</div>
                <div class="chevron-down">
                  <img id = 'subjectArrow'
                    class="vector-1"
                    src="../../../assets/vector-29@2x.png"
                  />
                </div>
              </div>
              <div class="frame-206" id = 'grade' (click)='select("grade")'>
                <div id = 'gradeTag'class="grade inter-semi-bold-chathams-blue-14px">Grade</div>
                <div class="chevron-down-1">
                  <img id = 'gradeArrow'
                    class="vector-1"
                    src="../../../assets/vector-29@2x.png"
                  />
                </div>
              </div>
              <div class="frame-205" id = 'days' (click)='select("days")'>
                <div id = 'daysTag'class="suitable-days-2 inter-semi-bold-chathams-blue-14px">Suitable Days</div>
                <div class="chevron-down-1">
                  <img id = 'daysArrow'
                    class="vector-1"
                    src="../../../assets/vector-29@2x.png"
                  />
                </div>
              </div>
            </div>
            <div class="reset-all" (click) = 'resetAll()'>Reset All</div>
          </div>
        </div>
        <div id = 'dropship' class="overlap-group1-1">
            <div id = 'subjectSelector'style="z-index: 1;cursor: pointer;display: none;">
              <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = 'Language Arts'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="Language Arts" (click) = 'onSelect("Language Arts",0)'/>
              <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = 'Mathematics'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="Mathematics"(click) = 'onSelect("Mathematics",0)'/>
              <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = 'Science'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="Science"(click) = 'onSelect("Science",0)'/>
              <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = 'Social Studies'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="Social Studies" (click) = 'onSelect("Social Studies",0)'/>
              <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = 'Creative Arts'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="Creative Arts"(click) = 'onSelect("Creative Arts",0)'/>
              <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = 'World Languages'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="World Langauges"(click) = 'onSelect("World Languages",0)'/>
              <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = 'General'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="General"(click) = 'onSelect("General",0)'/>
            </div>
            <div id = 'gradeSelector' style="z-index: 1;cursor: pointer;display: none;">
             <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = '1_2'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="1st - 2nd Grade"(click) = 'onSelect("1_2",1)'/>
             <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = '3_5'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="3rd - 5th Grade"(click) = 'onSelect("3_5",1)'/>
             <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = '6_8'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="6th - 8th Grade"(click) = 'onSelect("6_8",1)'/>
             <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = '9_12'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="9th - 12th Grade"(click) = 'onSelect("9_12",1)'/>   
             <input type = "button" class="inter-semi-bold-chathams-blue-14px" id = 'General Education'style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="General Education"(click) = 'onSelect("General Education",1)'/>  
            </div>
            <div id = 'daysSelector' style="z-index: 1;cursor: pointer;display: none;">
              <input type = "button" id = 'a' class="inter-semi-bold-chathams-blue-14px" style = 'width:210px;background-color: #F3F3F3;;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);'  value="Monday"(click) = 'onSelect("a",2)'/>
              <input type = "button" id = 'b' class="inter-semi-bold-chathams-blue-14px" style = 'width:210px;background-color: #F3F3F3;;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);'  value="Tuesday"(click) = 'onSelect("b",2)'/>
              <input type = "button" id = 'c' class="inter-semi-bold-chathams-blue-14px" style = 'width:210px;background-color: #F3F3F3;;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);'  value="Wednesday"(click) = 'onSelect("c",2)'/>
              <input type = "button" id = 'd' class="inter-semi-bold-chathams-blue-14px" style = 'width:210px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);'  value="Thursday"(click) = 'onSelect("d",2)'/>
              <input type = "button" id = 'e' class="inter-semi-bold-chathams-blue-14px" style = 'width:210px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);'  value="Friday"(click) = 'onSelect("e",2)'/>
              <input type = "button" id = 'f' class="inter-semi-bold-chathams-blue-14px" style = 'width:210px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);'  value="Saturday"(click) = 'onSelect("f",2)'/>
              <input type = "button" id = 'g' class="inter-semi-bold-chathams-blue-14px" style = 'width:210px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);'  value="Sunday"(click) = 'onSelect("g",2)'/>   
            </div>
          </div>
      </div>
      <div class="group-214">
        <img
          class="subtract"
          src="../../../assets/subtract-1@2x.png"
        />
      </div>
    </div>
    <div class="overlap-group1">
      <app-teacher-item *ngFor = "let teacher of currTeachers | async" [teacher] = "teacher" (chosenUID)="teacherPage($event)"style="text-align: center;">
      </app-teacher-item>
    </div>

  </div>
</div>