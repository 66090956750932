<a target="_blank" rel="noopener noreferrer" href = 'https://join.zoom.us./'>
    <div class = "txtBlock" style="min-height: 300px;">
        <div style="text-align: center;">
            <input type="number">
            <input type="string">
            <p style="font: 18px; margin-top: 75px; font-weight: bold;">Appointment with: {{app.teacherName}}</p>
            <p style="font: 18px;font-weight: bold;">On: {{app.dateTime}}</p>
            <p style="font: 18px;font-weight: bold;">For: {{app.services}}</p>
        </div>
    </div>  
</a>