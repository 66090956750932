import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ResizedEvent } from 'angular-resize-event';
import { auth } from 'firebase';
import { Question } from 'src/app/models/question';

@Component({
  selector: 'app-question-hub',
  templateUrl: './question-hub.component.html',  
  styleUrls: ['./question-hub.component.scss']
})

export class QuestionHubComponent implements OnInit {
  gradeLevelChosen = false
  topicChosen = false
  LowerElementarySchool = false  
  UpperElementarySchool = false
  MiddleSchool = false
  HighSchool = false
  computer = true
  currentPath = ''
  currentChoice = ''
  updateEmail = ''
  selectedTopic = ''
  newQuestion = ''
  chosenGradeLevel = ''
  location = ''
  questionEm
  displayLocation = ''
  questions:Question[] = []
  @Output() q = new EventEmitter<Question[]>();
  @Output() t = new EventEmitter<any>();
  @Output() s = new EventEmitter<any>();
  width: number;
  height: number;
  questionErros = []
  displayPath = ''
  topics = []
  subtopics = []
  topic = 'General'
  subtopic = 'General'
  community = 'General'

  constructor(private database:AngularFireDatabase,private fns:AngularFireFunctions) { 

     
  }    
  
  async ngOnInit(){
    var topic = ''
    this.updateQuestions()
    await this.database.database.ref('questionsSearch/Topics/val').once('value').then(function(snap){
      console.log(snap.val())
      topic  = snap.val()
    })
    this.topics = topic.split(',')
    this.t.emit(this.topics)
    console.log(this.topics)
  }

  onResized(event: ResizedEvent) {    
    this.width = event.newWidth;   
    this.height = event.newHeight;
    if(this.width < 700){
      this.computer = false 
    }
  }

  async selectTopic(top){
    this.subtopics = []
    this.topic = top
    this.s.emit(this.subtopics)
    var subTopic = ''
    await this.database.database.ref('questionsSearch/'+top+'/val').once('value').then(function(snap){
      console.log(snap.val())
      subTopic  = snap.val()
    })
    this.updateQuestions()
    this.subtopics = subTopic.split(',')
    this.s.emit(this.subtopics)
    console.log(this.subtopics)
    this.community = top
  }

  async selectSub(subtop){
    this.community = this.community.split(' ')[0] + ' ' +this.community.split(' ')[1]
    console.log(this.topic + subtop)
    this.subtopic = subtop
    this.updateQuestions()
    this.community += " " + subtop
  }

  startOver(){
    this.questions = []
    this.gradeLevelChosen = false
    this.topicChosen = false
    this.LowerElementarySchool = false  
    this.UpperElementarySchool = false
    this.MiddleSchool = false
    this.HighSchool = false
    this.selectedTopic = ''
    this.currentPath = ''
    this.displayPath = ''
  }

  back(){
    //qur zone
    if(this.currentPath.split('/').length === 3){
      //this will be where we distiniguis between general and selcted
      //grade/general/general
      this.location = ''
      this.displayLocation = ''
      this.topicChosen = false
      this.selectedTopic = ''
      this.currentPath = this.currentPath.split('/')[0] + '/general'
      this.displayPath = this.displayPath.split('/')[0] + '/general'
      this.updateQuestions()
    }else if(this.currentPath.split('/').length === 2){
      this.startOver()  
      //here    
      if(this.computer){
        document.getElementById('image').style.height = '650px'
      }
    }
  }

  async askQuestion3(){
    var holdpath = this.topic +'/' +this.subtopic
    console.log('questionSearch/' + holdpath[0] + '/' + holdpath[1] + '/' + holdpath[2])
    var pathHold = holdpath[0] + '/' + holdpath[1] + '/' + holdpath[2]
    await this.database.database.ref('questionSearch/' + holdpath[0] + '/' + holdpath[1] + '/' + holdpath[2]).push(
      {
        question: this.newQuestion,
        AUID: 'Xsfl8lwShuauODnwcV7WyoIHnOE3',
        TUIDs: '',
        openClosed: 0,
        timeStamp: + new Date(),
        path:pathHold
      }
    )
  }

  async askQuestion2(){
    var holdpath = this.currentPath.split('/')
    console.log('questionSearch/' + holdpath[0] + '/' + holdpath[1] + '/' + holdpath[2])
    var pathHold = holdpath[0] + '/' + holdpath[1] + '/' + holdpath[2]
    await this.database.database.ref('questionSearch/' + holdpath[0] + '/' + holdpath[1] + '/' + holdpath[2]).push(
      {
        question: this.newQuestion,
        AUID: 'Xsfl8lwShuauODnwcV7WyoIHnOE3',
        TUIDs: '',
        openClosed: 0,
        timeStamp: + new Date(),
        path:pathHold
      }
    )
  }

  askQuestion(){
    this.questionErros = []
    if(auth().currentUser){
      if(this.newQuestion !== ''){  
        this.sendEmail('fred.mendoza527@gmail.com','We Have A New Question', this.currentPath + ' || ' + this.newQuestion + '||' + auth().currentUser.uid)
        this.newQuestion = ''
        this.questionErros = ['Your question is pending approval!']
        // document.getElementById('fan1').nodeValue = ''
      }else{
        //display err message 
        this.questionErros = ['You gotta start somewhere...']
      }
    }else{
      if(this.newQuestion !== ''){  
        this.sendEmail('fred.mendoza527@gmail.com','We Have A New Question', this.currentPath + ' || ' + this.newQuestion + '||' + this.updateEmail)
        this.newQuestion = ''
        this.questionErros = ['Your question is pending approval!']
        // document.getElementById('fan1').nodeValue = ''
      }else{
        //display err message 
        this.questionErros = ['You gotta start somewhere...']
      }
    }
  }

  sendEmail(address,subject,msg){
    const sendMail = this.fns.httpsCallable('sendMail')
    sendMail({
      address: `${address}`,
      subject:`${subject}`,
      msg:`${msg}`,
    }).toPromise().then(() => console.log("success")).catch((e)=> console.log('err',e))
  }

  onSelect(val){
    console.log(val)
  }


  async updateQuestions(){
    var appQuestions:Question[] = []
    console.log()
    const query = await this.database.database.ref('questionSearch/' + `${this.topic}`).child(`${this.subtopic}`).orderByKey().limitToLast(10)
    var snapHold = null
    await query.once('value', snap =>{
      snapHold = snap
      console.log(snapHold)
    })
    if(snapHold !== null){
      await snapHold.forEach(function(snapp){
        var hold = snapp.child('path').val().split('/')
        appQuestions.push(new Question(snapp.key,snapp.child('TUIDs').val(),snapp.child('AUID').val(),snapp.child('question').val(),'',snapp.child('timeStamp').val(),'',snapp.child('openClosed').val(),hold[0],hold[1],hold[2]))
      })
      this.q.emit(appQuestions)
      this.questions = appQuestions
      console.log(this.questions)
    }
  }

}
