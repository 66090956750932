<div class="container-center-horizontal noSelect">
    <div class="teacher-sign-up screen">
      <div class="overlap-group">
        <div class="frame-273">
          <div class="object-2">
            <img
              class="object-3"
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/object-1@2x.png"
            />
          </div>
        </div>
        <div class="group-286">
          <div class="overlap-group1">
            <img
              src="../../../assets/Group-286.png"
            />
          </div>
        </div>
        <div class="group-279">
          <h1 class="text-3 worksans-semi-bold-abbey-32px">Connect with thousands of students</h1>
          <p class="text-4 inter-normal-mine-shaft-14px">
            Connect with thousands of students nationwide and broaden your reach as a teacher. Join the Verified Team and
            start building a network that can support your career as an educator. Sign up today!
          </p>
          <div class="group-278">
            <div class="group-277">
              <div class="overlap-group2-1">
                <input type="email" class="name inter-normal-gunsmoke-14px-2" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Name"autocomplete="off">
                <!-- <div class="name inter-normal-gunsmoke-14px-2">Name...</div> -->
              </div>
            </div>
            <div class="group-268">
              <div class="overlap-group1-1" (click) = "signUp()"><div class="sign-up worksans-semi-bold-white-14px">Sign Up</div></div>
            </div>
          </div>
        </div>
        <div class="group-284">
            <img
            src="../../../assets/teacherSignUp.png"
            />
        </div>
        <div class="object">
            <img
            src="../../../assets/Object-G.png"
          />
        </div>
      </div>
      <!-- <div class="group-97">
        <img
          class="image-1"
          src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/image-1@2x.png"
        />
        <div class="group-91">
          <div class="teachers worksans-medium-abbey-14px">TEACHERS</div>
          <div class="answers worksans-medium-abbey-14px">ANSWERS</div>
          <div class="blog-1 worksans-medium-abbey-14px">BLOG</div>
          <div class="teacher-sign-up-1 worksans-medium-abbey-14px">TEACHER SIGN UP</div>
          <div class="promotions-1 worksans-medium-abbey-14px">PROMOTIONS</div>
          <div class="faq worksans-medium-abbey-14px">FAQ</div>
        </div>
        <div class="group-58">
          <div class="overlap-group1-3"><div class="sign-in worksans-semi-bold-white-14px">Sign In</div></div>
        </div>
        <div class="sign-up-1 worksans-semi-bold-chathams-blue-14px">Sign Up</div>
      </div> -->
    </div>
  </div>