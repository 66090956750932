import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-manager',
  templateUrl: './content-manager.component.html',
  styleUrls: ['./content-manager.component.scss']
})
export class ContentManagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
