<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
<div>
    <form class = "txtBlock controls">
        <div class="form-group boxSize ">
            <label style="text-align: center; font-size: 20px;margin-bottom: 20px;">Your Schedule</label>
            <p style="font-size: 16px;max-width: 330px;text-align: center;margin:0 auto; margin-bottom: 20px;">
                Click a day and enter a start and end time. When you click a day it shows that days schedule.
            </p>
            <select multiple class="form-control" style="height: 270px;background-color:rgb(230,230,230);">
              <option (click) = 'storeDay("mon")'class="time checkbox btn btn-secondary" style = "display:block; text-align: center;margin-bottom: 3px;font-size:16px;">Monday</option>
              <option (click) = 'storeDay("tue")'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;font-size:16px;">Tuesday</option>
              <option (click) = 'storeDay("wed")'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;font-size:16px;">Wednesday</option>
              <option (click) = 'storeDay("thu")'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;font-size:16px;">Thursday</option>
              <option (click) = 'storeDay("fri")'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;font-size:16px;">Friday</option>
              <option (click) = 'storeDay("sat")'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;font-size:16px;">Saturday</option>
              <option (click) = 'storeDay("sun")'class="time checkbox btn btn-secondary" style = "display:block; text-align: center; margin-top: 3px;margin-bottom: 3px;font-size:16px;">Sunday</option>              
            </select>
            <div *ngFor = "let s of v" [s] = "s">
                <div class = "txtBlock">{{s}}</div>
            </div>
          </div>
        <!-- <input id = "switch1" type = "checkbox" (change) = "adjustSwitch(1)"style="display: inline-block;"> -->
        <div class="dropdown">
            <li class = "options time btn-secondary">{{slotPlaceHolder1}}</li>
            <div class="dropdown-content" style="max-height: 140px; overflow: auto;">
                <div *ngFor = "let day of timesOpen">
                    <button 
                    type="button"
                    style = "text-align: center;width: 110px; margin-top: 3px;margin-bottom: 3px;"
                    class="time checkbox btn btn-secondary" 
                    (click)="storeTime(day,1)"
                    >
                     {{format2(day)}}
                    </button>     
                </div>            
            </div>
        </div>
        <div class="dropdown" style = "margin-left: 5px;">
            <li class = "options time btn-secondary" style="text-decoration: none;">{{slotPlaceHolder2}}</li>
            <div class="dropdown-content"style="max-height: 140px; overflow: auto;">
                <div *ngFor = "let day of timesOpen">
                    <button 
                    type="button"
                    style = "text-align: center;width: 110px; margin-top: 3px;margin-bottom: 3px;"
                    class="time checkbox btn btn-secondary" 
                    (click)= "storeTime(day,2)"
                    >
                    {{format2(day)}}
                    </button>     
                </div>            
            </div>
        </div>
        <button type="button" style="font-size: 14px;"  (click) = "clearDay(1)"  class="btn btn-secondary btn-sm"style="width: 50px; margin-left: 5px;border-radius: 25px;">clear</button>
        <div style="margin-top: 15px;">
        <!-- <input id = "switch2" type = "checkbox" (change) = "adjustSwitch(2)"style="display: inline-block;"> -->
            <div class="dropdown">
                <li class = "options time btn-secondary">{{slotPlaceHolder3}}</li>
                <div class="dropdown-content" style="max-height: 140px; overflow: auto;">
                    <div *ngFor = "let day of timesOpen">
                        <button 
                        type="button"
                        style = "text-align: center;width: 110px; margin-top: 3px;margin-bottom: 3px;"
                        class="time checkbox btn btn-secondary" 
                        (click)="storeTime(day,3)"
                        >
                        {{format2(day)}}
                        </button>     
                    </div>            
                </div>
            </div>
            <div class="dropdown" style="margin-left: 5px">
                <li class = "options time btn-secondary">{{slotPlaceHolder4}}</li>
                <div class="dropdown-content"style="max-height: 140px; overflow: auto;">
                    <div *ngFor = "let day of timesOpen">
                        <button 
                        type="button"
                        style = "text-align: center;width: 110px; margin-top: 3px;margin-bottom: 3px;"
                        class="time checkbox btn btn-secondary" 
                        (click)= "storeTime(day,4)"
                        >
                        {{format2(day)}}
                        </button>     
                    </div>            
                </div>
            </div>
            <button type="button" style="font-size: 14px;"  (click) = "clearDay(2)"  class="btn btn-secondary btn-sm"style="width: 50px; margin-left: 5px;border-radius: 25px;">clear</button>
            <!-- <div style="text-align: left;">
                <input class="btn btn-secondary btn-sm" type="reset" value="Reset" (click) = "onReset()">
            </div> -->
        </div>
        <button type="button" style="font-size: 16px;font-size:16px;margin-top: 20px;"  (click) = "tryToSend()" class="btn btn-secondary btn-sm">update</button>
       
       
    </form>
</div>

