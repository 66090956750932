<div class="container-center-horizontal">
  <!-- <div id = 'calendar3'></div> -->
    <div class="update-profile-student-parent-v1 screen">
      <!-- <div id = 'calendar3'></div> -->
      <div class="flex-row-1">
        <div class="flex-col">
          <div class="frame-267-1" style = 'z-index:1;cursor: pointer;'routerLink="/profile/{{UID}}">
            <div class="arrow-left">
              <div class="overlap-group2-1">
                <img
                  src="../../../assets/backToProfile.svg"
                />
              </div>
            </div>
            <div class="back-to-profile worksans-semi-bold-chathams-blue-14px">Back to profile</div>
          </div>
          <div class="overlap-group">
            <div class="group-319">
              <div class="overlap-group2">
                <h1 class="text-9 worksans-semi-bold-abbey-24px">Your Services</h1>
                <div class="overlap-group4">
                    <div class="x7181696 component component-wrapper ready">
                        <div class="group-314-6jjQtm">
                          <input class="rectangle-72-eWET2B text-7-1" maxLength = "30" minLength = "2" type="text" placeholder="Enter New Service" ngModel name="perSub" #perSub ="ngModel" (change) = "perSuber = perSub.value">
                        </div>
                      </div>
                </div>
              </div>
            </div>
            <div class="frame-268" (click) = changeCustSubs() style="z-index: 1;cursor: pointer;">
              <div class="plus">
                <div class="overlap-group2-1">
                  <img
                    class="vector-6"
                    src="../../../assets/verticalPlus.svg"
                  />
                  <img
                    class="vector-1"
                    src="../../../assets/horiztonalPlus.svg"
                  />
                </div>
              </div>
              <div class="text-10 worksans-semi-bold-chathams-blue-14px">Add New Service</div>
            </div>

            <img
              class="line-7"
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/line-7@2x.png"
            />
            <div class="group-314-1">
                <div class="overlap-group2-2-1 text-7-1">
                    <div *ngFor = "let topic of ct1 | async">
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width: 233px;margin-right: 6px;background-color: #F3F3F3; padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' id = {{topic}} value = {{topic}}/>
                        <img src = "../../../assets/greyX.png" (click) = removeCustSubs(topic) type="button" style="height:20px;font-size: 16px;display: inline-block;border-radius:25px;vertical-align: top;z-index: 1;cursor: pointer;">
                    </div> 
                </div>
              </div>
          </div>
          <div class="group-320">
            <div class="overlap-group3">
              <div class="your-information worksans-semi-bold-abbey-24px">Booking Policies</div>
              <div class="x7181701 component component-wrapper ready">
                <div class="name-i71817017181-UQj6W3">Hourly Rate</div>
                <div class="group-314-UQj6W3">
                  <input class="rectangle-72-jkehNv text-7-1" ngModel name="func" #func ="ngModel" (change) = "newRate = func.value" id ="rate" type="number"value= '{{currentRate | async}}' min="1">
                </div>
              </div>
              <div class="x7181701 component component-wrapper ready">
                <div class="name-i71817017181-UQj6W3">Grace Period For Cancellations</div>
                <div class="group-314-UQj6W3">
                  <input class="rectangle-72-jkehNv text-7-1" value= '{{policy1| async}}' min="0" max="36" step="1" ngModel name="fan1" #fan1 ="ngModel" (change) = "changeRate(fan1.value,1)" id ="fan1" type="number">
                </div>
              </div>
              <div class="x7181701 component component-wrapper ready">
                <div class="name-i71817017181-UQj6W3">General Cancellation Fee</div>
                <div class="group-314-UQj6W3">
                  <input class="rectangle-72-jkehNv text-7-1" value= '{{policy2| async}}' min="0" max="100" step="1" ngModel name="fan2" #fan2 ="ngModel" (change) = "changeRate(fan2.value,2)" id ="fan2" type="number">
                </div>
              </div>
              <div class="x7181701-1 component component-wrapper ready" style='color: #1e5663;
              font-family: "Inter", Helvetica;
              font-size: 16px;
              font-weight: 600;'>
                <div class="name-i71817017181-UQj6W3">Cancellation Fee (%) With</div>
              </div>
              <div class="x7181701 component component-wrapper ready">
                <div class="name-i71817017181-UQj6W3">24 To 36 Hours Notice</div>
                <div class="group-314-UQj6W3">
                  <input class="rectangle-72-jkehNv text-7-1" value= '{{policy3 | async}}' min="0" max="100" step="1"ngModel name="fan3" #fan3 ="ngModel" (change) = "changeRate(fan3.value,3)" id ="fan3" type="number">
                </div>
              </div>
              <div class="x7181701 component component-wrapper ready">
                <div class="name-i71817017181-UQj6W3">12 To 24 Hours Notice</div>
                <div class="group-314-UQj6W3">
                  <input class="rectangle-72-jkehNv text-7-1"value= '{{policy4 | async}}' min="0" max="100" step="1" ngModel name="fan4" #fan4 ="ngModel" (change) = "changeRate(fan4.value,4)" id ="fan4" type="number">
                </div>
              </div>
              <div class="x7181701 component component-wrapper ready">
                <div class="name-i71817017181-UQj6W3">2 To 11 Hours Notice</div>
                <div class="group-314-UQj6W3">
                  <input class="rectangle-72-jkehNv text-7-1"value= '{{policy5 | async}}' min="0" max="100" step="1"ngModel name="fan5" #fan5 ="ngModel" (change) = "changeRate(fan5.value,5)" id ="fan5" type="number">
                </div>
              </div>
              <div class="x7181701 component component-wrapper ready">
                <div class="name-i71817017181-UQj6W3">2 Hours Notice</div>
                <div class="group-314-UQj6W3">
                  <input class="rectangle-72-jkehNv text-7-1"value= '{{policy6 | async}}' min="0" max="100" step="1"ngModel name="fan6" #fan6 ="ngModel" (change) = "changeRate(fan6.value,6)" id ="fan6" type="number">
                </div>
              </div>
              <div class="x7181701 component component-wrapper ready">
                <div class="name-i71817017181-UQj6W3">No Notice</div>
                <div class="group-314-UQj6W3">
                  <input class="rectangle-72-jkehNv text-7-1" value= '{{policy7 | async}}' min="0" max="100" step="1"ngModel name="fan7" #fan7  ="ngModel" (change) = "changeRate(fan7.value,7)" id ="fan7" type="number">
                </div>
              </div>
              <div class="frame-75" (click) = updatePolicies() style="z-index: 1;cursor: pointer;"><div class="save-changes">Update Policies</div></div>
            </div>
          </div>
        </div>
        <app-teacher-schedule-creator [config] = 'teacherInfo | async' (openCalendarE)="openCalendar()"></app-teacher-schedule-creator>
        <div class="overlap-group1">
          <div class="group-321">
            <div class="x1st-student-profile worksans-semi-bold-abbey-24px">Verified Profile</div>
            <textarea  class="overlap-group2-2-2 text-7-2" style = 'resize: none;'maxLength = "2250" minLength = "2" ngModel name="fj" #fj ="ngModel" (change) = "storeLongIntro(fj.value)" placeholder="This is where you can share your background as a teacher" value= '{{currentLong | async}}'>
            </textarea>
            <div class="frame-75" (click) = updateLongIntro() style="z-index: 1;cursor: pointer;"><div class="save-changes">Save Changes</div></div>
            <div class="form">
                <div class="flex-col-3">
                  <div class="frame-350-1">
                    <p class="name">Grades You Want To Help</p>
                    <div class="group-314">
                      <div id = 'subjectSelector' class="overlap-group2-2">
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("World Language",2)'(click) = 'updateSearch("General",2)'id = 'General'value = 'General'/> 
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("Language Arts",2)' id = 'Language Arts' value = 'Language Arts'/>
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("Mathematics",2)'id = 'Mathematics'value = 'Mathematics'/>
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("Social Studies",2)'id = 'Social Studies'value = 'Social Studies'/>
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("Science",2)'id = 'Science'value = 'Science'/>
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("Creative Arts",2)'id = 'Creative Arts'value = 'Creative Arts'/>
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("World Language",2)'id = 'World Language'value = 'World Language'/> 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-col-2">
                  <div class="frame-351">
                    <div class="name">Base Subjects</div>
                    <div class="group-314">
                      <div class="overlap-group2-3">
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' id = 'General Education' value = 'General Education'(click) = 'updateSearch("General Education",1)'/>
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' id = '1_2' value = 'Lower Elementary'(click) = 'updateSearch("1_2",1)'/>
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' id = '3_5' value = 'Upper Elementary'(click) = 'updateSearch("3_5",1)'/>
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' id = '6_8' value = 'Middle School'(click) = 'updateSearch("6_8",1)'/>
                        <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' id = '9_12' value = 'High School'(click) = 'updateSearch("9_12",1)'/>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <img  id="proPic" src="../../../../assets/defaultProfile.png" style="width: 80px;height: 80px;border-radius: 50px;position: absolute;margin-top: -20px;background-color: #b0e6f3;z-index: 1;cursor: pointer;mix-blend-mode: normal;object-fit: cover;" (click) = 'clickUpload()'>
          <input id = "imageUploader" type="file" (change) = "upload($event)" accept = "*.png.jpg.pdf" style="display: none;">
        </div>
      </div>
    </div>
  </div>   
  <script src="https://animaapp.s3.amazonaws.com/js/timeline.js"></script>
