import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-teacher-student-profile-widget',
  templateUrl: './teacher-student-profile-widget.component.html',
  styleUrls: ['./teacher-student-profile-widget.component.scss']
})
export class TeacherStudentProfileWidgetComponent implements OnInit {
  @Input() AUXIN
  name
  SUID
  PUID
  urlKey
  backImg
  color    
  proImg
  constructor() { }

  ngOnInit(): void {
    console.log(this.AUXIN)
    this.name = this.AUXIN.name
    this.SUID = this.AUXIN.SUID
    this.PUID = this.AUXIN.PUID
    this.urlKey = this.PUID + '-' + this.SUID
    this.backImg = this.AUXIN.backImg
    this.color = this.AUXIN.proColor
    this.proImg = this.AUXIN.proPic
    

    setTimeout(()=>{
      document.getElementById('2backImage' + this.SUID).setAttribute('src','../../../assets/pageImage'+'1' +'B.svg')
      document.getElementById('proPic' + this.SUID).setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png')
      document.getElementById('2proColor' + this.SUID).setAttribute('style','width: 277px;height:120px;background-color: #'+'f2feff'+';margin:13px;border-radius: 10px;padding: 10px;border: 3px solid lightgray;cursor: pointer;')
      // var color = this.color.split('-')[0]
      // if(this.color.split('-')[0] === 'F8F2FF'){
      //   color = this.color.split('-')[1]
      // }
      // document.getElementById('2proColor' + this.SUID).setAttribute('style','width: 264px;height:120px;background-color: #'+color+';margin:5px;border-radius: 10px;padding: 10px;border: 3px solid lightgray;cursor: pointer;')
      // if(String(this.proImg) === '1'){
      //   document.getElementById('proPic' + this.SUID).setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png')
      // }else if(String(this.proImg) === '2'){
      //   document.getElementById('proPic' + this.SUID).setAttribute('src','../../../assets/Frog.svg')
      // }else if(String(this.proImg) === '3'){
      //   document.getElementById('proPic' + this.SUID).setAttribute('src','../../../assets/Hippo.svg')
      // }else if(String(this.proImg) === '4'){
      //   document.getElementById('proPic' + this.SUID).setAttribute('src','../../../assets/Giraf.svg')
      // }else if(String(this.proImg) === '5'){
      //   document.getElementById('proPic' + this.SUID).setAttribute('src','../../../assets/Cat.svg')
      // }else if(String(this.proImg) === '6'){
      //   document.getElementById('proPic' + this.SUID).setAttribute('src','../../../assets/zebra.svg')
      // }

      // document.getElementById('2backImage' + this.SUID).setAttribute('src','../../../assets/pageImage'+this.backImg +'B.svg')
    },0)
  }
}
