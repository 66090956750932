<div *ngIf = "teacher && agreed !== ''" >
  <div class="group-240">
    <div class="overlap-group">
      <div class="x">
        <div class="overlap-group1">
          <img
            src="../../../assets/greyX.png"
          />
        </div>
      </div>
      <div class="group-266">
        <div class="frame-234">
          <img
            id = 'proPic{{UID}}'
            class="ellipse-26"
            src="../../../assets/ceramic.jpeg"
          />
          <div class="igor-belagorudsky body">{{displayName}}</div>
        </div>
        <div class="subject-history body"><span>Subject:</span><span class="span body"> {{service}}</span></div>
        <div class="text-1 body"><span class="span body">Offered Rate:</span><span class="span body"> {{offeredRate}}</span></div>
        <div class="reccuring-3-times body" style="font-style: italic;">Pending...</div>
        <div class="flex-row-2">
          <div class="group-234">
            <div *ngFor = "let req of validDays">
              <div class="overlap-group-1" style="background-color:#b0e6f3;z-index: 1;cursor: pointer;" (click) = 'selectTime(req)'>
                <div class="flex-row-1">
                  <div class="phone body">{{teacherDate2(req,1)}}</div>
                  <div class="overlap-group2 border-1px-main-teal">
                    <div class="ellipse-17-1"></div>
                  </div>
                </div>
                <div class="x1530-1630pm body">{{teacherDate2(req,2)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
</div>
<div *ngIf = "teacher && agreed === ''" >
    <div class="group-240">
        <div class="overlap-group">
          <div class="x">
            <div class="overlap-group1">
              <img
                src="../../../assets/greyX.png"
              />
            </div>
          </div>
          <div class="group-266">
            <div class="frame-234">
              <img
                id = 'proPic{{UID}}'
                class="ellipse-26"
                src="../../../assets/ceramic.jpeg"
              />
              <div class="igor-belagorudsky body">{{displayName}}</div>
            </div>
            <div class="subject-history body"><span>Subject:</span><span class="span body"> {{service}}</span></div>
            <div class="text-1 body"><span class="span body">Offered Rate:</span><span class="span body"> ${{offeredRate}}/h</span></div>
            <div class="reccuring-3-times body">Needs {{numSessions}} sessions</div>
            <div class="flex-row-2">
              <div class="group-234">
                <div *ngFor = "let req of validDays">
                  <div class="overlap-group-1" style="background-color:#b0e6f3;z-index: 1;cursor: pointer;" (click) = 'selectTime(req)'>
                    <div class="flex-row-1">
                      <div class="phone body">{{teacherDate2(req,1)}}</div>
                      <div class="overlap-group2 border-1px-main-teal">
                        <div class="ellipse-17-1"></div>
                      </div>
                    </div>
                    <div class="x1530-1630pm body">{{teacherDate2(req,2)}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-row">
              <div class="group-197">
                
                <input type = "number" class="inter-semi-bold-chathams-blue-14px" style = 'width:80%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="Hourl Rate" ngModel name="func44" #func44 ="ngModel" (change) = "setFinalRate(func44.value)"min="1" placeholder="  Hourly Fee"/>
                <p class="inter-semi-bold-chathams-blue-14px" style="font-size: 16px;margin-top: 12px;">$/hr</p>
              </div>
              <div class="group-236" style="z-index: 1;cursor: pointer;" (click) = 'secureTime()'>
                <div class="overlap-group5"><div class="accept body">Accept</div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
     <div *ngIf = "!teacher && agreed === ''">
      <div class="group-240">
        <div class="overlap-group">
          <div class="x">
            <div class="overlap-group1">
              <img
                src="../../../assets/greyX.png"
              />
            </div>
          </div>
          <div class="group-266">
            <div class="frame-234">
              <img
                id = 'proPic{{UID}}'
                class="ellipse-26"
                src="../../../assets/ceramic.jpeg"
              />
              <div class="igor-belagorudsky body">{{displayName}}</div>
            </div>
            <div class="subject-history body"><span>Subject:</span><span class="span body"> {{service}}</span></div>
            <div class="text-1 body"><span class="span body">Offered Rate:</span><span class="span body"> {{offeredRate}}</span></div>
            <div class="reccuring-3-times body" style="font-style: italic;">Pending...</div>
            <div class="flex-row-2">
              <div class="group-234">
                <div *ngFor = "let req of validDays">
                  <div class="overlap-group-1" style="background-color:#b0e6f3;z-index: 1;cursor: pointer;" (click) = 'selectTime(req)'>
                    <div class="flex-row-1">
                      <div class="phone body">{{teacherDate2(req,1)}}</div>
                      <div class="overlap-group2 border-1px-main-teal">
                        <div class="ellipse-17-1"></div>
                      </div>
                    </div>
                    <div class="x1530-1630pm body">{{teacherDate2(req,2)}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>          
    </div>

    <div *ngIf = "!teacher && agreed !== ''">
      <div class="group-240">
        <div class="overlap-group">
          <div class="x">
            <div class="overlap-group1">
              <img
                src="../../../assets/greyX.png"
              />
            </div>
          </div>
          <div class="group-266">
            <div class="frame-234">
              <img
                id = 'proPic{{UID}}'
                class="ellipse-26"
                src="../../../assets/ceramic.jpeg"
              />
              <div class="igor-belagorudsky body">{{displayName}}</div>
            </div>
            <div class="subject-history body"><span>Subject:</span><span class="span body"> {{service}}</span></div>
            <div class="text-1 body"><span class="span body">Offered Rate:</span><span class="span body"> ${{offeredRate}}/h</span></div>
            <div class="reccuring-3-times body">Needs {{numSessions}} sessions</div>
            <div class="flex-row-2">
              <div class="group-234">
                <div *ngFor = "let req of validDays">
                  <div class="overlap-group-1" style="background-color:#b0e6f3;z-index: 1;cursor: pointer;" (click) = 'selectTime(req)'>
                    <div class="flex-row-1">
                      <div class="phone body">{{teacherDate2(req,1)}}</div>
                      <div class="overlap-group2 border-1px-main-teal">
                        <div class="ellipse-17-1"></div>
                      </div>
                    </div>
                    <div class="x1530-1630pm body">{{teacherDate2(req,2)}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-row">
              <div class="group-197">
                <input type = "text" class="inter-semi-bold-chathams-blue-14px" style = 'width:80%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="Hourl Rate" ngModel name="func44" #func44 ="ngModel" (change) = "setFinalRate(func44.value)"min="1" placeholder="  Promo Code"/>
              </div>
              <div class="group-236" style="z-index: 1;cursor: pointer;" (click) = 'pay()'>
                <div class="overlap-group5"><div class="accept body">Pay</div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>



