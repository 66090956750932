<div routerLink="../../VerifiedPost/{{article.cat}}-{{article.AUID}}"  style="margin: 0 auto;background-color: #D8D8D8;border: 5px solid rgb(230,230,230);min-height: 230px;margin-bottom: 15px;min-width:260px;">
    <div style="display:inline-block;vertical-align: top;">
      <img id = '{{article.AUID}}'src = '../../../assets/ceramic.jpeg' style="width: 250px; height:200px;margin-top: 10px;">
    </div>
    <div style = 'width:30px;display: inline-block;margin-left: 40px;'>
    </div>
    <div style="display:inline-block;width:55%;text-align: left;margin-top: 15px;">
      <p style = 'font-size: 14px;'>{{article.cat}}</p>
      <p style = 'font-size: 24px;line-height: 1;'>{{article.title}}</p>
      <p style = 'font-size: 22px;line-height: 1;'>{{article.subTitle}}</p>
      <p>{{date}} · {{article.readTime}} Min Read</p>
    </div>
  </div>
