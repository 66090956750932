import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment-timezone'

@Component({
  selector: 'app-profile-upcoming-appointment',
  templateUrl: './profile-upcoming-appointment.component.html',
  styleUrls: ['./profile-upcoming-appointment.component.scss']
})
export class ProfileUpcomingAppointmentComponent implements OnInit {
  @Input() input: any[];
  @Input() Date: any;
  
  displayName = ''
  date = ''
  studentName = 'Noah'

  room = ''
  app = ''
  duration = ''
  path
  profilePicture: string;
  
  constructor() { }  

  ngOnInit(): void {
    for(const key in this.input){
      console.log(this.input[key])
      var val = this.input[key]
      this.app = val['room']
      this.room = val['room']
      this.duration =  val['duration']
      this.studentName = val['members']['1']['name']
      console.log(new Date(Number(this.Date)))
      this.date = moment(Number(this.Date)).format("dddd, MMMM Do YYYY, h:mm a")
      this.getProf(Number(val['members']['1']['proPic']))
    }
  }

  getProf(val:Number){
    var key = ''
    console.log(val)
    if(val === 1){
      key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png'
    }else if(val === 2){
      key = '../../../assets/Frog.svg'
    }else if(val === 3){
      key = '../../../assets/Hippo.svg'
    }else if(val === 4){
      key = '../../../assets/Giraf.svg'
    }else if(val === 5){
      key = '../../../assets/Cat.svg'
    }else if(val === 6){
      key = '../../../assets/zebra.svg'
    }
    console.log(key)
    this.path = val
    setTimeout(() => {
      document.getElementById('proPic' + this.app).setAttribute('src',`${key}`)
    }, 0);
    
    this.profilePicture = key
  }
}
