<div class="card mb-3 boxSize" style="max-width: 1000px; min-height: 300px; border-radius: 25px;">
    <div class="row no-gutters" >
      <div class="col-md-4" style="margin-top: 30px;">
        <img src="../../../assets/books.jpg" class="card-img" alt="..." style="border-radius: 25px;height: 230px; width: 200px">
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h5 class="card-title" style = "font-size: 20px;">Carl</h5>
          <p class="card-text"style = "font-size: 16px;">As a full-time teacher at a large public High School, my topics of expertise include Social Studies in general
            and AP World History in particular as well as Middle School and High School English.   As the parent of four kids
             myself, I am also available to help parents of high functioning kids as well as families trying to figure out how 
             to meet the needs of very challenged students.  I have first hand experience as the parent of both.  I have one 
             daughter who, due to Developmental Disorders, has never spoken in her life: and a second daughter who speaks five 
             languages. As a former Administrator I can also play the role of mentor and help current administrators in being 
             even more effective administrators.  Parents who would like to get advice and/or input from the perspective of a 
             fellow parent, an experienced teacher and a former Secondary Administrator can certainly call on me. I look forward
              to working with both students and adults! </p>
        </div>
      </div>
    </div>
  </div>
