import { Component, Input, OnInit } from '@angular/core';
import { comment } from '../../models/comment';
import {AngularFireDatabase} from '@angular/fire/database'


@Component({  
  selector: 'app-comment-bar',
  templateUrl: './comment-bar.component.html',
  styleUrls: ['./comment-bar.component.scss']
}) 
export class CommentBarComponent implements OnInit {
  comments: comment[] = [];
  @Input()UID:string
  constructor(private db: AngularFireDatabase) { }

  async ngOnInit(){
    var commentsHold = [];
    await this.db.database.ref('providers/userInfo/' + this.UID + '/comments').once('value').then(function(snap){
      // hold = snap.val()
      snap.forEach(function(child){
        var stars = child.child('stars').val()
        var comm = child.child('comment').val()
        var date = child.child('date').val()
        var name = child.child('name').val()
        var hold = new comment(name,date,comm,stars)
        commentsHold.push(hold)
      })
    })
    commentsHold.forEach(commen =>{
      this.comments.push(commen)
    })
  }

}
 
  // addComment(comment:comment){
  //   this.comment.addComment(comment).subscribe(
  //     comment => {
  //       this.comments.push(comment);
  //     });
  // }


