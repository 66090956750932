<div class = "aComment">
    <div><p>We verify all of our teachers so you can trust you are working with a credentialed K-12 instructor</p></div>
    <div class = "ownerInfo" style="margin-top: 20px;">
        <span class = "txt" style="font-size: 16px;" >Fred, CEO Verified Teachers</span>
        <!-- <span class = "txt">11 November 20</span> -->
        <span id = "star11" class="fa fa-star checked" style = "color:orange;"></span>
        <span id = "star21" class="fa fa-star checked" style = "color:orange;"></span>
        <span id = "star31" class="fa fa-star checked" style = "color:orange;"></span>
        <span id = "star41" class="fa fa-star checked" style = "color:orange;"></span>
        <span id = "star51" class="fa fa-star checked" style = "color:orange;"></span>            
    </div>
<div>
