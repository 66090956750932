<div (click) = 'editStudent()' class = 'inter-semi-bold-abbey-16px' id ='proColor{{SUID}}' style ='width: 264px;height:120px;background-color: #FFF1F8;margin:5px;border-radius: 10px;padding: 10px;border: 3px solid lightgray;cursor: pointer;'>
    <img id = 'backImage{{SUID}}'style = 'width: 258px;
    margin-left: -10px;
    margin-top: -10px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom: 3px solid lightgray;
    background-color: lightgray;'src = '../../../assets/pageImage3B.svg'>
    <img _ngcontent-bnp-c90="" id = 'proPic{{SUID}}'src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png" class="ellipse-26-1">
    <p>{{name}}</p>
    <p style="text-align: right;
    font-weight: 300;
    margin-top: -43px;
    margin-bottom: 24px;">No Appointments</p>
    <p style="  font-size: 14px;
    margin-top: -2px;
    font-weight: 300;
    width: 83%;
    display: inline;
    text-align: right;
    position: absolute;"><u>view profile</u></p>
  </div>
