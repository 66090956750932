import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment-timezone';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss']
})
export class StudentProfileComponent implements OnInit {  
  UID   
  PUID

  studentName
  
  @Output() trackerEmitter = new EventEmitter<any[]>();

  trackers = []

  profilePicURL
  sessions =[]

  @Output() profilePicURLEmitter = new EventEmitter<any>();
  sessionsEmitter = []
       
  constructor(private router:ActivatedRoute, private db: AngularFireDatabase,private afm: AngularFireStorage) { }

  ngOnInit(): void {
    this.UID = this.router.snapshot.params.UID.split('-')[1]
    this.PUID = this.router.snapshot.params.UID.split('-')[0]

    var name = ''
    var backgroundImage = ''
    var profileColor = ''
    var profilePicture = ''
    var alienColor = ''

    var sessions = []
    this.db.database.ref('users/' + this.PUID + '/students/' + `${this.UID}`).once('value').then(function(snap){
      name = snap.child('profile').child('name').val()
      backgroundImage = snap.child('profile').child('backImg').val()
      profileColor = snap.child('profile').child('proColor').val()
      profilePicture = snap.child('profile').child('proPic').val()
      alienColor = snap.child('profile').child('alien').val()
    }).then(()=>{
      this.db.database.ref('users/' + this.PUID + '/calendar/' + `${this.UID}`).once('value').then(function(snap){
        snap.forEach(snapp=>{
          for(const key in snapp.val()){
            console.log(snapp.key,snapp.val()[key]['duration'],snapp.val()[key]['members']['0']['name'],snapp.val()[key]['room'])
            sessions.unshift({'SN':name,'TN':snapp.val()[key]['members']['0']['name'],'cRef':snapp.val()[key]['room'],'date':moment(Number(snapp.key)).format("dddd, MMMM Do YYYY, h:mm a")})
            // console.log(snapp.key,snapp.child('duration').val(),snapp.child('members').child('0').child('name').val(),snapp.child('room').val())
          }
          console.log(snapp.key,snapp.val())
          // console.log(snapp.key,snapp.child('duration').val(),snapp.child('members').child('0').child('name').val(),snapp.child('room').val())
        })
        // name = snap.child('profile').child('name').val()
        // backgroundImage = snap.child('profile').child('backImg').val()
        // profileColor = snap.child('profile').child('proColor').val()
        // profilePicture = snap.child('profile').child('proPic').val()
        // alienColor = snap.child('profile').child('alien').val()
      
      }).then(()=>{
              this.studentName = name
      this.sessions  = sessions
      if(this.sessions.length === 0){
        document.getElementById('noSessions').setAttribute('style','position: absolute;left: 498px;top: 499px;')
      }
      console.log(sessions)
      this.sessionsEmitter = sessions
      this.getStudentProfilePicture(profilePicture).then(()=>{
        this.setAlienColor(alienColor)
        this.setPageBackground(backgroundImage)
        this.setPageColor(profileColor)
        this.profilePicURLEmitter.emit({'alien':alienColor,'name':name,'backImg':backgroundImage,'proPic':this.profilePicURL,'proColor':profileColor})
      })
      })
    })    
    .then(()=>{
      if(this.PUID === 'iI4xswLfjxe7BEmSL7eIAyeetC22'){
        document.getElementById('emptyXP1').setAttribute('style','display:none')
        document.getElementById('emptyXP2').setAttribute('style','display:none')
        document.getElementById('emptyXP3').setAttribute('style','display:none')
        document.getElementById('noSessions').setAttribute('style','display:none')
        document.getElementById('rocketStick').setAttribute('style','position: absolute;top:-167px;')
        document.getElementById('sessions').setAttribute('style','')
        document.getElementById('rocket').setAttribute('class','overlap-group1-4-1')
        document.getElementById('ach1').setAttribute('style','display:none')
        document.getElementById('ach2').setAttribute('style','')
        document.getElementById('alienLevel').setAttribute('src','../../../assets/SolarLevel10.svg')
        document.getElementById('sessionsHidden').setAttribute('style','top: 17px;position: absolute;')
        setTimeout(()=>{
          this.trackers.unshift(['Science','80','3','A'])
          this.trackers.unshift(['Math','60','2','B'])
          this.trackers.unshift(['History','80','5','C'])
          this.trackerEmitter.emit(this.trackers)
        },0)
      }
        
    })

   
    // this.db.database.ref('users/' + this.PUID + '/calendar/' + `${this.UID}`).once('value').then(function(snap){
    //   snap.forEach(snapp=>{
    //     for(const key in snapp.val()){
    //       console.log(snapp.key,snapp.val()[key]['duration'],snapp.val()[key]['members']['0']['name'],snapp.val()[key]['room'])
    //       sessions.unshift({'SN':'Noah','TN':snapp.val()[key]['members']['0']['name'],'cRef':snapp.val()[key]['room'],'date':snapp.key})
    //       // console.log(snapp.key,snapp.child('duration').val(),snapp.child('members').child('0').child('name').val(),snapp.child('room').val())
    //     }
    //     console.log(snapp.key,snapp.val())
    //     // console.log(snapp.key,snapp.child('duration').val(),snapp.child('members').child('0').child('name').val(),snapp.child('room').val())
    //   })
    //   // name = snap.child('profile').child('name').val()
    //   // backgroundImage = snap.child('profile').child('backImg').val()
    //   // profileColor = snap.child('profile').child('proColor').val()
    //   // profilePicture = snap.child('profile').child('proPic').val()
    //   // alienColor = snap.child('profile').child('alien').val()
    
    // }).then(()=>{
    //   this.db.database.ref('users/' + this.PUID + '/students/' + `${this.UID}`).once('value').then(function(snap){
    //     name = snap.child('profile').child('name').val()
    //     backgroundImage = snap.child('profile').child('backImg').val()
    //     profileColor = snap.child('profile').child('proColor').val()
    //     profilePicture = snap.child('profile').child('proPic').val()
    //     alienColor = snap.child('profile').child('alien').val()
    //   })
    //   // this.studentName = name
    //   // this.sessions  = sessions
    //   // if(this.sessions.length === 0){
    //   //   document.getElementById('noSessions').setAttribute('style','position: absolute;left: 498px;top: 499px;')
    //   // }
    //   // this.sessionsEmitter.emit(sessions)
    //   // this.getStudentProfilePicture(profilePicture).then(()=>{
    //   //   this.setAlienColor(alienColor)
    //   //   this.setPageBackground(backgroundImage)
    //   //   this.setPageColor(profileColor)
    //   //   this.profilePicURLEmitter.emit({'alien':alienColor,'name':name,'backImg':backgroundImage,'proPic':this.profilePicURL,'proColor':profileColor})
    //   // })
    // })    


    // .then(()=>{
    //   this.trackers.unshift(['Science','80','3','A'])
    //   this.trackers.unshift(['Math','60','2','B'])
    //   this.trackers.unshift(['History','80','5','C'])
    //   this.trackerEmitter.emit(this.trackers)
    // })
    // this.getProPic().then(()=>{
    //   this.trackers.unshift(['Science','80','3','A'])
    //   this.trackers.unshift(['Math','60','2','B'])
    //   this.trackers.unshift(['History','80','5','C'])
    //   this.trackerEmitter.emit(this.trackers)
    // })

  }   

  updatePageColor(hexValues){
    console.log(hexValues)
    this.db.database.ref('users/' + `${this.PUID}` + '/students/' + `${this.UID}` + '/profile/').update({'proColor':`${hexValues}`})
    var light = hexValues.split('-')[0]
    var dark = hexValues.split('-')[1]
    var load = hexValues.split('-')[2]
    document.getElementById('panel1').setAttribute('style','background-color:#'+light)
    document.getElementById('panel2').setAttribute('style','background-color:#'+dark)
    document.getElementById('panel3').setAttribute('style','background-color:#'+light)
  }

  setPageColor(hexValues){
    console.log(hexValues)
    var light = hexValues.split('-')[0]
    var dark = hexValues.split('-')[1]
    var load = hexValues.split('-')[2]
    document.getElementById('panel1').setAttribute('style','background-color:#'+light)
    document.getElementById('panel2').setAttribute('style','background-color:#'+dark)
    document.getElementById('panel3').setAttribute('style','background-color:#'+light)
  }

  async getStudentProfilePicture(profilePicture){
    var UID = this.UID
    var holdURL 
    // Create a reference from a Google Cloud Storage URI
    if(String(profilePicture) === '1'){
      document.getElementById('proPic').setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png')
      this.profilePicURL = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png'
    }else if(String(profilePicture) === '2'){
      document.getElementById('proPic').setAttribute('src','../../../assets/Frog.svg')
      this.profilePicURL = '../../../assets/Frog.svg'
    }else if(String(profilePicture) === '3'){
      document.getElementById('proPic').setAttribute('src','../../../assets/Hippo.svg')
      this.profilePicURL = '../../../assets/Hippo.svg'
    }else if(String(profilePicture) === '4'){
      document.getElementById('proPic').setAttribute('src','../../../assets/Giraf.svg')
      this.profilePicURL = '../../../assets/Giraf.svg'
    }else if(String(profilePicture) === '5'){
      document.getElementById('proPic').setAttribute('src','../../../assets/Cat.svg')
      this.profilePicURL = '../../../assets/Cat.svg'
    }else if(String(profilePicture) === '6'){
      document.getElementById('proPic').setAttribute('src','../../../assets/zebra.svg')
      this.profilePicURL = '../../../assets/zebra.svg'
    }else{
      var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/proPicS/' + UID)  
      //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
      await storage.getDownloadURL().then(function(url) {
        console.log(url)
        document.getElementById('proPic').setAttribute('src',url);
        holdURL = url
      }).catch(function(error) {
        // Handle any errors
        document.getElementById('proPic').setAttribute('src','../../../assets/noPic.png');
        // document.getElementById(`${teacherHold.UID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
      }).then(()=>{  
        this.profilePicURL = holdURL
        // this.profilePicURLEmitter.emit(holdURL)
      })
    }
  }

  openPopUp(){
    document.getElementById('editProfile').setAttribute('class','')
  }

  closePopUp(){
    document.getElementById('editProfile').setAttribute('class','hidden')
  }

  setAlienColor(newColor){
    document.getElementById('alienHead').setAttribute('src','../../../assets/'+`${newColor}` +'AlienHead.svg')
    document.getElementById('alienNeck').setAttribute('src','../../../assets/'+`${newColor}` +'AlienNeck.svg')
  }

  updateAlienColor(newColor){
    console.log(this.PUID,this.UID,newColor)
    this.db.database.ref('users/' + `${this.PUID}` + '/students/' + `${this.UID}` + '/profile/').update({'alien':`${newColor}`})
    document.getElementById('alienHead').setAttribute('src','../../../assets/'+`${newColor}` +'AlienHead.svg')
    document.getElementById('alienNeck').setAttribute('src','../../../assets/'+`${newColor}` +'AlienNeck.svg')
  }

  updatePageBackground(imageIndex){
    this.db.database.ref('users/' + `${this.PUID}` + '/students/' + `${this.UID}` + '/profile/').update({'backImg':`${imageIndex}`})
    document.getElementById('pageBackground').setAttribute('src','../../../assets/pageImage'+`${imageIndex}` +'B.svg')
  }

  setPageBackground(imageIndex){
    document.getElementById('pageBackground').setAttribute('src','../../../assets/pageImage'+`${imageIndex}` +'B.svg')
  }

  async getProPic(){
    var teacherUID = this.UID
    var holdURL 
    // Create a reference from a Google Cloud Storage URI
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/teacherPics/' + teacherUID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    await storage.getDownloadURL().then(function(url) {
      console.log(url)
      document.getElementById('proPic').setAttribute('src',url);
      holdURL = url
    }).catch(function(error) {
      // Handle any errors
      document.getElementById('proPic').setAttribute('src','../../../assets/noPic.png');
      // document.getElementById(`${teacherHold.UID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    }).then(()=>{  
      this.profilePicURLEmitter.emit(holdURL)
    })
  }

}
