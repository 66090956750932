import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-teacher-landing',
  templateUrl: './teacher-landing.component.html',
  styleUrls: ['./teacher-landing.component.scss']
})
export class TeacherLandingComponent implements OnInit {
  emailNL = ''
  constructor(private rerouter: Router) { }

  ngOnInit(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0
  }

  signUp(){
    if(this.emailNL === ''){
      this.rerouter.navigateByUrl('TeacherProfile/')
    }else{
      this.rerouter.navigateByUrl('TeacherProfile/' + `${this.emailNL}`)
    }
  }

}
