import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { storageFile } from 'src/app/models/storageFile';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-lesson-create',
  templateUrl: './lesson-create.component.html',
  styleUrls: ['./lesson-create.component.scss']
})
export class LessonCreateComponent implements OnInit {

  teacher = false

  selectedCats = []

  imageThumbnails = {}
  imageThumbnailsType = {}

  fileName1 = ''
  fileName2 = ''
  fileName3 = ''
  fileName4 = ''
  
  storedFiles = []

  lessonName

  path:string = ""
  UID = ''
  loadedFiles:storageFile[] = []
  pageNumber = 0  
  topic = false
  subtopic = false 
  topics = ['Practice Problems', 'Board Templates', 'Fun Pics', 'General']
  subtopics = ['Math', 'English', 'Social Studies', 'World Language', 'Creative Arts', 'Fun', 'General']

  // pdfjs = await import('pdfjs-dist/build/pdf');
  // pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');

    
  fileName = ''
  fileDesc = ''
  fileTopic = 'General'
  fileSubtopic = 'General'

  currentlySelected = 0
  @Output() close = new EventEmitter<any[]>();


  constructor(private db: AngularFireDatabase,private afm: AngularFireStorage,public auth: AuthService) { }
  // ngOnChanges(changes: any){
        
  //   console.log(changes.categoryId.currentValue);
  //   // You can also use categoryId.previousValue and 
  //   // categoryId.firstChange for comparing old and new values
    
  // } 

  async ngOnInit(){
    await this.auth.user$.subscribe(usr=>{   
      // GlobalWorkerOptions.workerSrc = '"//mozilla.github.io/pdf.js/build/pdf.worker.js"'
      this.UID = usr.uid
      var teacherHold = false
      this.db.database.ref('providers/userInfo/' +  `${usr.uid}`).once('value').then(function(snap){
        if(snap.exists()){ 
          teacherHold = true
        } 
      }).then(()=>{
        this.teacher = teacherHold
      })
    })
    document.getElementById('drop_zone').addEventListener('drop',event=>{
      this.dropHandler(event)
    })
    document.getElementById('drop_zone').addEventListener('dragover',event=>{
      document.getElementById('drop_zone').setAttribute('class','group-458 border-8px-blue')
      this.dragOverHandler(event)
    })
    document.getElementById('drop_zone').addEventListener('dragleave',()=>{
      document.getElementById('drop_zone').setAttribute('class','group-458 border-1px-blue')
    })
    document.getElementById('drop_zone').addEventListener('click',()=>{
      document.getElementById('inputGroupFile009').click()
    })

    document.getElementById('inputGroupFile009').addEventListener('change',val=>{
      console.log(val)
      this.storeFile(val)
    })
 
  }
  updateName(name){
    this.loadedFiles[this.currentlySelected].name = name
  }
  updateDesc(desc){
    this.loadedFiles[this.currentlySelected].desc = desc
  }

  closeMe(){
    this.close.emit([])
  }

  updateTopic(services){
    this.loadedFiles[this.currentlySelected].topic = services
    this.fileTopic = services
  }

  updateSubtopic(services){
    this.loadedFiles[this.currentlySelected].subtopic = services
    this.fileSubtopic = services
  }



  restFileDisplay(){
    document.getElementById('image1').setAttribute('style','display:none')
    document.getElementById('image1').setAttribute('src','')
    document.getElementById('x1').setAttribute('style','display:none')
    document.getElementById('fileName1').setAttribute('style','display:none')
    
    document.getElementById('image2').setAttribute('style','display:none')
    document.getElementById('image2').setAttribute('src','')
    document.getElementById('x2').setAttribute('style','display:none')
    document.getElementById('fileName2').setAttribute('style','display:none')

    document.getElementById('image3').setAttribute('style','display:none')
    document.getElementById('image3').setAttribute('src','')
    document.getElementById('x3').setAttribute('style','display:none')
    document.getElementById('fileName3').setAttribute('style','display:none')

    document.getElementById('image4').setAttribute('style','display:none')
    document.getElementById('image4').setAttribute('src','')
    document.getElementById('x4').setAttribute('style','display:none')
    document.getElementById('fileName4').setAttribute('style','display:none')


  }

  clearHighlightBox(){
    // document.getElementById('box1').setAttribute('style','display:none')
    // document.getElementById('box2').setAttribute('style','display:none')
    // document.getElementById('box3').setAttribute('style','display:none')
    // document.getElementById('box4').setAttribute('style','display:none')
  }

  highlight(slot){
    document.getElementById('dropShip').setAttribute('style','display:none')
    this.topic = false
    this.subtopic = false
    this.fileDesc = ''
    this.fileName = ''
    this.fileTopic = ''    
    this.fileSubtopic = ''   
    this.clearHighlightBox()
    this.currentlySelected = (this.pageNumber*4 + slot) - 1
    if(this.loadedFiles[this.currentlySelected].desc === ''){
      this.fileDesc = 'empty'
    }else{
      this.fileDesc = this.loadedFiles[this.currentlySelected].desc
    }

    this.fileName = this.loadedFiles[this.currentlySelected].name
    this.fileTopic = this.loadedFiles[this.currentlySelected].topic
    this.fileSubtopic = this.loadedFiles[this.currentlySelected].subtopic

    document.getElementById('box' + `${slot}`).setAttribute('style','')
  }

  deleteFile(num){
    var deleteIndex = (this.pageNumber * 4) + num
    this.loadedFiles.splice(deleteIndex-1,1)
    if(this.loadedFiles.length === 0){
      this.fileName = ''
    }else if(this.loadedFiles.length%4 === 0){
      this.prevPage()
    }
    this.displayNewFiles()
  }

  displayNewFiles(){
    var start = this.pageNumber * 4
    var end = this.loadedFiles.length
    var currCount = 0
    console.log(start,end)
    this.restFileDisplay()
    while(start < end){
      if(currCount === 0){   
        // document.getElementById('box1').setAttribute('style','')
        document.getElementById('image1').setAttribute('style','')
        document.getElementById('fileName1').setAttribute('style','')
        if(this.loadedFiles[start].file.type.includes('image/')){
          document.getElementById('image1').setAttribute('src',URL.createObjectURL(this.loadedFiles[start].file))
        }else{
          document.getElementById('image1').setAttribute('src','../../../assets/document.svg')
        }
        document.getElementById('x1').setAttribute('style','')
        var fileName = this.loadedFiles[start].name
        this.fileName1 = fileName
        var fileDesc = this.loadedFiles[start].desc
        var fileTopic = this.loadedFiles[start].topic
        var fileSubtopic = this.loadedFiles[start].subtopic
        this.fileName = fileName
        this.fileDesc = fileDesc
        this.fileSubtopic = fileSubtopic
        this.fileTopic = fileTopic

      }else if(currCount === 1){
        document.getElementById('image2').setAttribute('style','')
        document.getElementById('fileName2').setAttribute('style','')
        if(this.loadedFiles[start].file.type.includes('image/')){
          document.getElementById('image2').setAttribute('src',URL.createObjectURL(this.loadedFiles[start].file))
        }else{
          document.getElementById('image2').setAttribute('src','../../../assets/document.svg')
        }
        this.fileName2 = this.loadedFiles[start].name
        document.getElementById('x2').setAttribute('style','')
      }else if(currCount === 2){
        document.getElementById('image3').setAttribute('style','')
        document.getElementById('fileName3').setAttribute('style','')
        if(this.loadedFiles[start].file.type.includes('image/')){
          document.getElementById('image3').setAttribute('src',URL.createObjectURL(this.loadedFiles[start].file))
        }else{
          document.getElementById('image3').setAttribute('src','../../../assets/document.svg')
        }
        this.fileName3 = this.loadedFiles[start].name
        document.getElementById('x3').setAttribute('style','')
      }else if(currCount === 3){  
        document.getElementById('image4').setAttribute('style','')
        document.getElementById('fileName4').setAttribute('style','')
        if(this.loadedFiles[start].file.type.includes('image/')){
          document.getElementById('image4').setAttribute('src',URL.createObjectURL(this.loadedFiles[start].file))
        }else{
          document.getElementById('image4').setAttribute('src','../../../assets/document.svg')
        }
        this.fileName4 = this.loadedFiles[start].name
        document.getElementById('x4').setAttribute('style','')
      }
      currCount += 1  
      start += 1
    }
  }

  prevPage(){
    if(this.pageNumber > 0){
      this.pageNumber -= 1
      this.currentlySelected = (this.pageNumber*4) 
      // this.clearHighlightBox()
      this.displayNewFiles()
    }
  } 

  nextPage(){
    var end = Math.ceil(this.loadedFiles.length / 4)   
    if(this.pageNumber < end-1){
      this.pageNumber += 1
      this.currentlySelected = (this.pageNumber*4) 
      // this.clearHighlightBox()
      this.displayNewFiles()
    }
  }

  logTopic(){  
    if(this.topic === true){
      this.topic = false
      document.getElementById('dropShip').setAttribute('style','display:none')
    }else{
      this.subtopic = false
      this.topic = true
      document.getElementById('dropShip').setAttribute('style','')
    }
  }

  logSubtopic(){  
    if(this.subtopic === true){
      this.subtopic = false
      document.getElementById('dropShip').setAttribute('style','display:none')
    }else{
      this.subtopic = true
      this.topic = false
      document.getElementById('dropShip').setAttribute('style','')
    }
  }

  toggleCat(newCat){
    if(newCat === '') return
    if(this.selectedCats.includes(newCat)){
      this.selectedCats.splice(this.selectedCats.indexOf(newCat),1)
      document.getElementById(`${newCat}`+'Neck').setAttribute('class','alienNeck-1')
      document.getElementById(`${newCat}`+'Selected').setAttribute('src','../../../assets/catNotSelected.svg')
    }else{
      this.selectedCats.push(newCat)
      document.getElementById(`${newCat}`+'Neck').setAttribute('class','alienNeck')
      document.getElementById(`${newCat}`+'Selected').setAttribute('src','../../../assets/alienSelected.svg')
    }
  }

  dragOverHandler(ev) {      
    // console.log('File(s) in drop zone');
  
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  dropHandler(ev) {
    // Prevent default behavior (Prevent file from being opened)
    document.getElementById('drop_zone').setAttribute('class','group-458 border-1px-blue')
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          var TS = + new Date()
          var file:File = ev.dataTransfer.items[i].getAsFile();
          this.imageThumbnails[TS] = file
          var newFile = new storageFile('',TS,file.name,'','General','General',file)
          this.loadedFiles.unshift(newFile)
          // this.upload(file)
        }
      }
      this.displayNewFiles()
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        var TS = + new Date()
        var file:File = ev.dataTransfer.items[i].getAsFile();
        var newFile = new storageFile('',TS,file.name,'','General','General',file)
        this.loadedFiles.unshift(newFile)
      }
      this.displayNewFiles()
    }
    console.log(this.imageThumbnails)
  }

  storeFile(event){
    console.log(event.target.files[0],event.target.files[0].name)
    var files = event.target.files[0]
    var fileName = files[0].name
    // var extension = files[0].type
    var TS = + new Date()
    var newFile = new storageFile('',TS,fileName,'','General','General',event.target.files[0])
    this.loadedFiles.unshift(newFile)
    console.log(this.loadedFiles)
    this.displayNewFiles()
  }

  // up(){
  //   this.loadedFiles.forEach(file=>{
  //     this.upload(file.file,file.name,file.desc,file.topic,file.subtopic,file.file.type)
  //   })
  //   this.loadedFiles = []
  //   this.clearHighlightBox()
  //   this.restFileDisplay()
  //   this.closeMe()
  // }

  // convertPDF(file){
  //   getDocument(file).promise.then(doc=>{
  //     console.log(doc)
  //   })

  // }

  editNameOfFile(fileName,slotIndex){
    var loadedFilesIndex = (this.pageNumber * 4) + slotIndex
    this.loadedFiles[loadedFilesIndex].name = fileName
    if(slotIndex == 1){
      this.fileName1 = fileName
    }else if(slotIndex == 2){
      this.fileName2 = fileName
    }else if(slotIndex == 3){
      this.fileName3 = fileName
    }else if(slotIndex == 4){
      this.fileName4 = fileName
    }
  }

  createLesson(){
    console.log(this.lessonName,this.selectedCats,this.loadedFiles,this.selectedCats.toString())
    var lessonUID = + new Date()
    var fileUID = Number(lessonUID)
    this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/')
      .update({[lessonUID]:{'name':this.lessonName,'cats':this.selectedCats.toString()}})
      .then(()=>{
        this.loadedFiles.forEach(file=>{
          fileUID = fileUID + 1
          this.addLessonFile(file.file,file.name,fileUID,lessonUID)
        })
      }).then(()=>{
        this.close.emit([])
      })
  }

  addLessonFile(file,fileName,fileUID,lessonUID){
    this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${fileUID}`, file).then(e=>{
      this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' + lessonUID + '/files')
      .update({[fileUID]:{'name':fileName,'TS':fileUID}})
    })
  }
  upload($event,name,desc,topic,subtopic,type){
    setTimeout(() => {
      this.path = $event
      var TS = + new Date()
      console.log("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path)

      var key = this.UID + '-' + TS
      if(this.teacher){
        this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path).then(e=>{
          // if($event.type.includes('pdf')){
          //   e.ref.getDownloadURL().then(URL=>{
          //     this.convertPDF(URL)
          //   })
          // }
          this.db.database.ref('providers/userInfo/' + this.UID + '/storage').update({[key]:{'type':'PERSONAL','value':TS,'name':name,'desc':desc,'topic':topic,'subtopic':subtopic}}).then(()=>{
            this.db.database.ref('providers/userInfo/' + this.UID + '/').update({'newStorage':key +'/'+name + '/' + type})
          })
             
        })
      }else{
        this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path).then(()=>{
          this.db.database.ref('users/' + this.UID + '/storage').update({[key]:{'type':'PERSONAL','value':TS,'name':name,'desc':desc,'topic':topic,'subtopic':subtopic}}).then(()=>{
            this.db.database.ref('users/' + this.UID + '/').update({'newStorage':key +'/'+name + '/' + type})
          })
        })
      }
    }, 0);    
  }

}
