<!DOCTYPE html>
<html style="touch-action: none">
  <head>   
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=1440, maximum-scale=1.0" />
    <link rel="shortcut icon" type="image/png" href="https://animaproject.s3.amazonaws.com/home/favicon.png"/>
    <meta name="og:type" content="website" />
    <meta name="twitter:card" content="photo"/>
    <meta name="author" content="Alfredo Mendoza"/>
  </head>
  <body id = 'screenBod'
  style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;touch-action: none;" >
    <input type="hidden"  id="anPageName" name="page" value="hidden-video-display" />
    <div id = 'lobby'>  
      <app-lobby (enterClass) = 'exitLobby($event)'></app-lobby>     
    </div>
    <div class="container-center-horizontal unselectable blockHide" id = 'capture'>
      <div id = 'fileLoader' style = 'display: none;'>
        <app-file-uploader (close) = 'closeUploader()'></app-file-uploader>
      </div>
      <div id="myModal" class="modal">
        <div id = 'modal-content' class="modal-content">
          <span class="close" style="z-index: 1;cursor: pointer;width: 16px;margin-top: -10px;position:fixed;" (click) = 'toggle("sharePointer")'>&times;</span>
          <div style="margin-top: 33px;">
            <div style="position: absolute;left: 94px;top: 35px;">
              <div class="overlap-group5-1-1" style="z-index: 1;cursor: pointer;" (click) = sendToParent()>
                <div class="inter-semi-bold-chathams-blue-14px place-1">Send To Parent</div>
              </div>
              <div class="overlap-group-1-1"style="cursor: pointer;" (click)='toggleCat("History")'>
                  <span id = 'HistoryNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">History</p></span>
                  <img class="vector-93" src="../../../assets/CategoryHistory.svg" />
                  <img id = 'HistorySelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                  />
              </div>
              <div class="overlap-group-1-2"style="cursor: pointer;" (click)='toggleCat("Math")'>
                  <span id = 'MathNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Math</p></span>
                  <img class="vector-93" src="../../../assets/CategoryMath.svg" />
                  <img id = 'MathSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                  />
              </div>
              <div class="overlap-group-1-3"style="cursor: pointer;" (click)='toggleCat("Science")'>
                  <span id = 'ScienceNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Science</p></span>
                  <img class="vector-93" src="../../../assets/CategoryScience.svg" />
                  <img id = 'ScienceSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                  />
              </div>
      
              <div class="overlap-group-1-4"style="cursor: pointer;" (click)='toggleCat("LanguageArts")'>
                  <span id = 'LanguageArtsNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">English</p></span>
                  <img class="vector-93" src="../../../assets/CategoryLanguageArts.svg" />
                  <img id = 'LanguageArtsSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                  />
              </div>
              <div class="overlap-group-1-5"style="cursor: pointer;" (click)='toggleCat("WorldLangauge")'>
                  <span id = 'WorldLangaugeNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">World Lang</p></span>
                  <img class="vector-93" src="../../../assets/CategoryWorldLangauge.svg" />
                  <img id = 'WorldLangaugeSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                  />
              </div>  
              <div class="overlap-group-1-6"style="cursor: pointer;" (click)='toggleCat("CreativeArts")'>
                  <span id = 'CreativeArtsNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Art</p></span>
                  <img class="vector-93" src="../../../assets/CategoryCreativeArts.svg" />
                  <img id = 'CreativeArtsSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                  />
              </div>
      
              <div class="overlap-group-1-7"style="cursor: pointer;" (click)='toggleCat("LifeSkills")'>
                  <span id = 'LifeSkillsNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Life Skills</p></span>
                  <img class="vector-93" src="../../../assets/CategoryLifeSkills.svg" />
                  <img id = 'LifeSkillsSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                  />
              </div>
              <div class="overlap-group-1-8"style="cursor: pointer;" (click)='toggleCat("SocialDevelopment")'>
                  <span id = 'SocialDevelopmentNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Social Dev</p></span>
                  <img class="vector-93" src="../../../assets/CategorySocialDevelopment.svg" />
                  <img id = 'SocialDevelopmentSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                  />
              </div>
              <div class="overlap-group-1-9"style="cursor: pointer;" (click)='toggleCat("PersonalDevelopment")'>
                  <span id = 'PersonalDevelopmentNeck' class="alienNeck-1"><p class="worksans-semi-bold-chathams-blue-14px" style="color: white;text-align: center;padding-top: 3px;">Personal Dev</p></span>
                  <img class="vector-93" src="../../../assets/CategoryPersonalDevelopment.svg" />
                  <img id = 'PersonalDevelopmentSelected'class ='alienSelected' src="../../../assets/catNotSelected.svg"
                  />
              </div>
          </div>
            <div style="display: inline-block;width: 981px;min-height: 300px;max-height: 300px;margin-bottom: 30px;margin-top:218px;margin-left:113px;" >
              <div class="overlap-group5" style="cursor: pointer;z-index: 1;" >
                <input ngModel name="func" #func ="ngModel" (change) = "Attitude = func.value" type="number"value= '{{currentAttitude | async}}' type="number" class="place inter-semi-bold-chathams-blue-14px" style = 'outline: none;border: none;' placeholder="Attitude" max = '100' min = '0' maxlength = "3">
                <div class="chevron-down-3">
                  <img
                    class="vector-111"
                    src="../../../assets/vector-29@2x.png"
                  />
                </div>
              </div>
              <div class="overlap-group5" style="cursor: pointer;z-index: 1;">
                <input ngModel name="func1" #func1 ="ngModel" (change) = "Attention = func1.value" type="number"value= '{{currentAttention | async}}'type="number" class="place inter-semi-bold-chathams-blue-14px" style = 'outline: none;border: none;' placeholder="Attention" max = '100' min = '0' maxlength = "3">
                <div class="chevron-down-3">
                  <img
                    class="vector-111"
                    src="../../../assets/vector-29@2x.png"
                  />
                </div>
              </div>
              <div class="overlap-group5" style="cursor: pointer;z-index: 1;display: inline-block;">
                <input ngModel name="func2" #func2 ="ngModel" (change) = "Grasp = func2.value" type="number"value= '{{currentGrasp | async}}' type="number" class="place inter-semi-bold-chathams-blue-14px" style = 'outline: none;border: none;' placeholder="Grasp" max = '100' min = '0' maxlength = "3">
                <div class="chevron-down-3">
                  <img
                    class="vector-111"
                    src="../../../assets/vector-29@2x.png"
                  />
                </div>
              </div>
              <textarea ngModel name="func3" #func3 ="ngModel" (change) = "report = func3.value" type="number"value= '{{currentReport | async}}' class = 'worksans-medium-abbey-14px-1'style = 'min-height: 286px;max-height: 286px;width:802px;resize: none;border-radius: 15px;border:none;padding:20px;font-size: 16px;outline: none !important;margin-top: -131px;' placeholder="Note For Todays Lesson..."></textarea>
            </div>
            <!-- <div style="display: none;width: 369px;position:absolute;top:266px;">
              <div class="group-446" data-id="1183:9721">
                <div class="meeting-screenshot tab-bold" data-id="1183:9687">Meeting screenshot</div>
                <img
                  crossorigin="anonymous" 
                  style = 'width: 98%;box-shadow:2px 2px 5px #c3c3c3, -4px -4px 1px #ffffff;border-radius: 15px;margin-left: 4px;margin-top: 15px;'
                  id = 'modalImagePreview'
                />
              </div>
              <div class="maximize-2-1234" data-id="1183:9722" style="cursor: pointer;z-index: 1;" (click) = 'openModalImage()'>
                <div class="overlap-group1-1234" data-id="an|zMjhcOt6">
                  <img
                    class="vector-1-1234"
                    data-id="1183:9724"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6124124baf7899b8ef525bb1/img/vector-91@2x.png"
                  />
                  <img
                    class="vector-1234 "
                    data-id="1183:9726"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6124124baf7899b8ef525bb1/img/vector-92@2x.png"
                  />
                </div>
                <div class="overlap-group-1234" data-id="an|IuTWDdzH">
                  <img
                    class="vector-234"
                    data-id="1183:9723"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6124124baf7899b8ef525bb1/img/vector-90@2x.png"
                  />
                  <img
                    class="vector-1234 "
                    data-id="1183:9725"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6124124baf7899b8ef525bb1/img/vector-92@2x.png"
                  />
                </div>
              </div>
            </div> -->
          </div>
          <img crossorigin="anonymous" id = 'modalImage' style="display: none;"/>
        </div>
      </div>
      <div class="hidden-video-display screen" id = 'videoChat'>
        <!-- <iframe id = 'tester' width='100%' height='565px' frameborder='0'> </iframe> -->
        <span id = 'resizeSquares'>
          <span *ngFor = "let info of canvasImagesEmitter | async">
            <span   id = '{{info}}'(mousedown) = 'resize(info,0)'>
            </span>
            <span id = '1-{{info}}'(mousedown) = 'resize(info,1)' style="z-index: 100;cursor: pointer;">       
            </span>
            <span id = '2-{{info}}'(mousedown) = 'resize(info,2)'>
            </span>
            <span id = '3-{{info}}'(mousedown) = 'resize(info,3)'>
            </span>
            <span id = '4-{{info}}'(mousedown) = 'resize(info,4)'>
            </span>
          </span>
        </span>
        <span id = 'coverSquares'>
        </span>
        <span id = 'textBoxes'>
          <span *ngFor = "let text of textBoxEmitter | async">
            <textarea id = 'text-{{text}}' class = 'size20' style="display: none;" ngModel name = {{text}} (ngModelChange) = "onChange(text,$event)" (change) = "addTextChange(text)">

            </textarea>
            <span id = '{{text}}'(mousedown) = 'resizeText(text,0)' >
                
            </span>
            <span id = '1-{{text}}'(mousedown) = 'resizeText(text,1)'>
                
            </span>
            <span id = '2-{{text}}'(mousedown) = 'resizeText(text,2)'>
  
            </span>
          </span>
        </span> 
         <!-- 
           - we need a canvas grid system that will not throw a re render as it will clear the board and create space for race conditions.
           - since all screen is now fair game their is no need to put an invisible canvas over each individual canvas. we will now
           operate using the window reference itself. this will compromise our classrooms local locking procedure, but the can be fixed
           through a helper method.
           - This is where we will create our various 'BASE{row}-{col}' refernces
              - we will be able to abstract in some way a rowCol relation that will allow us to apply out base case 
              to be 
        -->
        <div id = 'canvi' style='cursor: url("../../../assets/vector-32@2x.svg") 0 19, auto'>
          <div id = 'canvasGrid0' style = 'width: 7200px;height:5000px'>
            <canvas id = 'BASE0-0' style = 'background-color: transparent;position: absolute;border: none'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE0-0' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE0-1' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE0-1' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE0-2' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE0-2' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE0-3' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE0-3' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
           </div>
  
           <div id = 'canvasGrid1' style = 'width: 7200px;height:5000px'>
            <canvas id = 'BASE1-0' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE1-0' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE1-1' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE1-1' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE1-2' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE1-2' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE1-3' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE1-3' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
           </div>
  
           <div id = 'canvasGrid2' style = 'width: 7200px;height:5000px'>
            <canvas id = 'BASE2-0' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE2-0' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE2-1' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE2-1' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE2-2' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE2-2' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE2-3' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE2-3' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
           </div>
  
           <div id = 'canvasGrid3' style = 'width: 7200px;height:5000px'>
            <canvas id = 'BASE3-0' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE3-0' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE3-1' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE3-1' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE3-2' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE3-2' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
  
            <canvas id = 'BASE3-3' style = 'background-color: transparent;position: absolute;border: none;'height = '5000'width="1440"></canvas>   
            <canvas id = 'SHAREDBASE3-3' style = 'background-color:whitesmoke;border: none;'height = '5000'width="1440"></canvas>
           </div>
        </div>
         <span id = 'videoDisplays' class="twilioDisplays2 rectangle-40" style="display: none;">  
          <div style="position: fixed;margin-top: -32px;">
              <img id = 'disp3' (click) = 'toggleDisplayView("3")' style = 'display:inline-block;margin-right: 8px;z-index: 1;cursor: pointer;'src = '../../../assets/boxGrey.svg'>
              <img id = 'disp2' (click) = 'toggleDisplayView("2")'style = 'display:inline-block;margin-right: 8px;z-index: 1;cursor: pointer;'src = '../../../assets/rowGreen.svg'>
              <img id = 'disp' (click) = 'toggleDisplayView("")'style = 'display:inline-block;z-index: 1;cursor: pointer;'src = '../../../assets/gridGrey2.svg'>
          </div>
          <span style="margin-top:20px">
              <div id ='avatarOtherT' style="display:none"></div>
              <div id ='TeacherTop' style="display: none">
              </div>
              <div  id ='sTop' style="display: none;margin-bottom:15px;">
                <div id ='avatarOtherS' style="display:none"></div>
                <div id ='StudentTop'style="background-color: transparent;height: 160px;display: inline-block;width:208px;border-radius: 15px;-webkit-transform: scaleX(-1);transform: scaleX(-1);">
                </div> 
                <div style="display: inline-block;vertical-align: top;padding-top: 5px;position: absolute;left:180px">
                  <div *ngIf = '!lock' id = 'drawOff' class="group-66-R3ERnh" style="z-index: 1;cursor: pointer;" (click) = lockStudentsCanvas()>
                    <div class="x1507410 component component-wrapper not-ready">
                      <div class="rectangle-45-N600Tf"></div>
                      <div class="ellipse-5-N600Tf-1"></div>
                      <div class="edit-2-N600Tf"><img class="vector-ZuO5ep" src="../../../assets/vector-32@2x.svg" /></div>
                    </div>
                  </div>
                  <div *ngIf = 'lock' id = 'drawOn' class="group-66-R3ERnh" style="z-index: 1;cursor: pointer;" (click) = unlockStudentsCanvas()>
                    <div class="x1507410 component component-wrapper not-ready">
                      <div class="rectangle-45-N600Tf"></div>
                      <div class="ellipse-5-N600Tf"></div>
                      <div class="edit-2-N600Tf"><img class="vector-ZuO5ep" src="../../../assets/vector-9@2x.svg" /></div>
                    </div>
                  </div>
                  <div *ngIf = '!mute' id = 'micOff' class="group-66-R3ERnh" style="z-index: 1;cursor: pointer;" (click) = muteStudentsAudio()>
                    <div class="x1507410 component component-wrapper not-ready">
                      <div class="rectangle-45-N600Tf"></div>
                      <div class="ellipse-5-N600Tf-1"></div>
                      <div class="edit-2-N600Tf"><img class="vector-ZuO5ep" src="../../../assets/mic.svg" /></div>
                    </div>
                  </div>
    
                  <div *ngIf = 'mute' id = 'micOn-1' class="group-66-R3ERnh" style="z-index: 1;cursor: pointer;" (click) = unmuteStudentsAudio()>
                    <div class="x1507410 component component-wrapper not-ready">
                      <div class="rectangle-45-N600Tf"></div>
                      <div class="ellipse-5-N600Tf"></div>
                      <div class="edit-2-N600Tf"><img class="vector-ZuO5ep" src="../../../assets/mic-grey.svg" /></div>
                    </div>
                  </div>
                </div>
              </div>
              <div  id ='local'>
                <div id ='avatar' style="background-color: transparent;height: 160px;display: inline-block;width:208px;border-radius: 15px;">
                </div>
                <div id ='LocalDisplay' style="display:none">
                </div>
                <div style="display: inline-block;vertical-align: top;padding-top: 5px;position: absolute;left: 180px;">
                  <!-- <div id = 'videoOn' class="group-66-R3ERnh" style="display: none;" (click) = turnOnVideo()>
                    <div class="x1507410 component component-wrapper not-ready">
                      <div class="rectangle-45-N600Tf"></div>
                      <div class="ellipse-5-N600Tf"></div>
                      <div class="edit-2-N600Tf"><img class="vector-ZuO5ep" src="../../../assets/vector-9@2x.svg" /></div>
                    </div>
                  </div> -->
                  <div  *ngIf = 'audio' id = 'microOff' class="group-66-R3ERnh-2" style="z-index: 1;cursor: pointer;"(click) = turnOffAudio()>
                    <div class="x1507410-2 component component-wrapper not-ready">
                      <div id = 'audioOnButtonP' class="roundButton"></div>
                      <div class="edit-2-N600Tf-2"><img class="vector-ZuO5ep-2" src="../../../assets/mic.svg" /></div>
                    </div>
                  </div>
                  <div *ngIf = '!audio' id = 'microOn' class="group-66-R3ERnh-2" style="z-index: 1;cursor: pointer;"(click) = turnOnAudio()>
                    <div class="x1507410-2 component component-wrapper not-ready">
                      <div id = 'audioOffButtonP' class="roundButton"></div>
                      <div class="edit-2-N600Tf-2"><img class="vector-ZuO5ep-2" src="../../../assets/mic-off.svg" /></div>
                    </div>
                  </div>
                  <div *ngIf = 'video' id = 'camOff' class="group-66-R3ERnh-2" style="z-index: 1;cursor: pointer;" (click) = turnOffVideo()>
                    <div class="x1507410-2 component component-wrapper not-ready">
                      <div id = 'videoOnButtonP' class="roundButton"></div>
                      <div class="edit-2-N600Tf-2"><img class="vector-ZuO5ep-2" src="../../../assets/video.svg" /></div>
                    </div>
                  </div>
                  <div *ngIf = '!video' id = 'camOn' class="group-66-R3ERnh-2" style="z-index: 1;cursor: pointer;" (click) = turnOnVideo()>
                    <div class="x1507410-2 component component-wrapper not-ready">
                      <div id = 'videoOffButtonP'class="roundButton"></div>
                      <div class="edit-2-N600Tf-2"><img class="vector-ZuO5ep-2" src="../../../assets/video-off.svg" /></div>
                    </div>
                  </div>
                </div>
              </div>
          </span>
        </span>
        <!-- <div class="stella-mur-C61RwL">Fred Mendoza</div> -->
        <!-- <span id="zoomBox" class = 'zoomState'>
          <img src="../../../assets/zoom-out.svg" style="display: inline-block;" >
          <span class = 'worksans-medium-abbey-14px-1' style="vertical-align: top;margin-top: 4px;display: inline-block;">{{zoom}}%</span>
          <img src="../../../assets/zoom-out.svg" style="display: inline-block;">
         </span> -->
        <img class="teal-5-C61RwL unselectable" src="../../../assets/teal-5@2x.png" />

        <a id = 'endSite' target="_blank" style="display: none;"></a>
        <div class="x1507412 component component-wrapper not-ready">
          <div class="rectangle-25-679gI0"id ='taskBarBackground'></div>
          <div class="group-48-679gI0" style="z-index: 1;cursor: pointer;" (click) = 'endCall()'>
            <div class="phone-75FAGS"><img class="vector-0izxiL" src="../../../assets/vector-14@2x.svg" /></div>
            <div class="end-this-call-i15074128073-75FAGS">End This Call</div>
          </div>
          <div class="frame-46-679gI0">
            <!-- <div class="rotate-ccw-p7pA9l" id = 'back' style="z-index: 1;cursor: pointer;"(click) = 'back()'>
              <img class="vector-xkVvD6" src="../../../assets/vector-15@2x.svg" />
              <img class="vector-n8HNbJ" src="../../../assets/vector-16@2x.svg" />
            </div> -->
            <div class="x-p7pA9l" style="z-index: 1;cursor: pointer;" id = 'clear' (click) = 'clearCanvas()' title="Clear Board">
              <img class="vector-o9qAbG" src="../../../assets/vector-17@2x.svg" />
              <img class="vector-dKhwsY" src="../../../assets/vector-18@2x.svg" />
            </div>
            <!-- <div class="x-p7pA9l-1" style="z-index: 1;cursor: pointer;" id = 'forward'>
              <img class="vector-3-1" src="../../../assets/vector-19@2x.svg">
              <img class="vector--1" src="../../../assets/vector-20@2x.svg">
            </div>  -->
          </div>
          <div class="frame-70-679gI0" >
            <div class="image-2bMqH9" id = 'files' (click) = 'toggle("files")' style="z-index: 1;cursor: pointer;" title="Drag & Drop">
              <img class="vector-LTx4EW" src="../../../assets/vector-28@2x.svg" />
              <img class="vector-6b1gVd" src="../../../assets/vector-29@2x.svg" />
              <img class="vector-0HQo1V" src="../../../assets/vector-30@2x.svg" />
              
              <div class="chevron-down-LTx4EW"><img class="vector-vWxEGh" src="../../../assets/vector-31@2x.svg" /></div>
            </div>
            <div id = 'dotUpdater' class="group-69-2bMqH9" (click) = 'toggle("dotUpdater")' style="z-index: 1;cursor: pointer;"title="Pencil Size">
              <div class="chevron-down-h3uYEB"><img class="vector-BJ7chN" src="../../../assets/vector-31@2x.svg" /></div>
              <div class="group-67-h3uYEB">
                <div class="rectangle-46-R6SOdV"></div>
                <div class="rectangle-49-R6SOdV"></div>
                <div class="rectangle-47-R6SOdV"></div>
                <div class="rectangle-48-R6SOdV"></div>
              </div>
            </div>
            <div id = 'earUpdater' class="move" style="z-index: 1;cursor: pointer;"(click) = 'toggle("earUpdater")' title="Eraser"><img  src="../../../assets/rectangle-39@2x.svg" /></div>
            <div id = 'capShape' class="group-68-2bMqH9" style="z-index: 1;cursor: pointer;"(click) = 'toggle("capShape")'title="Pencil Shape">
              <div class="square-KetGau"><img class="vector-zqCWVZ" src="../../../assets/vector-28@2x.svg" /></div>
              <div class="chevron-down-KetGau"><img class="vector-FPpwNf" src="../../../assets/vector-35@2x.svg" /></div>
            </div>
            <div id = 'penColor'class="circle-2bMqH9" (click) = 'toggle("penColor")' style="z-index: 1;cursor: pointer;"title="Pencil Color">
              <div class="chevron-down-l58JnV"><img class="vector-4DdHca" src="../../../assets/vector-36@2x.svg" /></div>
              <div class="group-81-l58JnV">
                <div class="ellipse-1-7obOkd"></div>
                <img class="vector-7obOkd" src="../../../assets/vector-37@2x.svg" />
              </div>
            </div>
            <div  id = 'camera' class="camera-2bMqH9" (click) = 'toggle("camera")' style="z-index: 1;cursor: pointer;"title="Camera">
              <img class="vector-fxPlXv" src="../../../assets/vector-41@2x.svg" />
              <img class="vector-EqS9NJ" src="../../../assets/vector-42@2x.svg" />
            </div>
            <div id = 'type' class='type-2bMqH9' style="z-index: 1;cursor: pointer;" (click) = 'toggle("type")' title="Add Text">
              <img src="../../../assets/type.svg" />
            </div>
            <div id = 'newFile' class='sharePointer-2bMqH9-1 ' style="z-index: 1;cursor: pointer;" title="Upload File(s)" (click) = 'toggle("newFile")'>
              
              <img style ='height:20px;width:20px'src="../../../assets/download.svg" />
            </div>
            <div id = 'openLesson' class='frame-2-2bMqH9' style="z-index: 1;cursor: pointer;" title="Upload File(s)" (click) = 'toggle("openLesson")'>
              
              <img style ='height:21px;width:21px'src="../../../assets/lessonIcon.svg" />
            </div>
            <div id = 'sharePointer' class='sharePointer-2bMqH9' style="z-index: 1;cursor: pointer;"  (click) = 'toggle("sharePointer")'  title="Lesson Write Up" >
              <img src="../../../assets/clipboard.svg" />
            </div>
            <div id = 'inbox' class='inbox-2bMqH9' style="z-index: 1;cursor: pointer;"(click) = 'toggle("inbox")'  title="Open Files"  title="Shared File(s)" >
              <img src="../../../assets/inbox.svg" />
            </div>
            <!-- <div id = 'document' class='document-2bMqH9' style="z-index: 1;cursor: pointer;"(click) = 'toggle("document")'  title="Share File(s)" >
              <img src="../../../assets/document.svg" />
            </div> -->
            <div id = 'move' class="sharePointer-2bMqH9-1-1" (click) = 'toggle("move")'  title="Move & Resize" >
              <img
                src="../../../assets/move.svg"
              />
            </div>  
          </div>
          <div id = 'screenCapture' class="screenCaptureWait">
            <div style="text-align:center;margin-bottom: 20px;z-index: 1;cursor: pointer;background-color: #b0e6f3;border-radius: 15px;"(click)="triggerSnapshot();">
              <img src="../../../assets/camera.svg" style="padding:5px"/>
            </div> 
            <webcam [height]="450" [width]="500" [trigger]="triggerObservable" (imageCapture)="upload($event)" *ngIf="showWebcam"
            [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions"
            [imageQuality]="1"
            (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)"
            ></webcam>  
          </div>

          <div id = 'dropship' class="overlap-group1-1">
            <div id = 'dropShipper' class="frame-309" style="display: none;">
              <app-stored-file *ngFor = "let info of personalFilesEmitter | async" style="z-index: 1;cursor: pointer;" (mousedown) = "logDropKey(info[4])"  id = '{{info}}'[AUXIN] = "info" [TUID] = "meetingID" (deleteMe) = 'deleteMe3($event)'(reloadInner)="reloaderInner($event)"(shareStorage)="shareStorage($event)"(nonInteractiveEmitter)="handle($event)"(imagePlusEmitter)="plusDraw()"(addPDFEmitter) ="addPDF($event)">
              </app-stored-file>
            </div>
            <div id = 'lessons' class="frame-309"style="display: none;">
              <div id = 'lessonBack'(click) = 'backToLessons()' style="display: none;">
                <img src="../../../assets/chevron-left.svg" style=" z-index: 1; width: 25px; height: 25px; cursor: pointer;margin-bottom: -7px;">
                <p class="inter-semi-bold-abbey-16px" style="display: inline-block;">Back To Lessons</p>
              </div>
              <div id = 'lessonsList'>
                <img id = 'noDownsYet' style="display: none;" src='../../../assets/noFilesDown.svg'>
                <div *ngFor = "let info of lessonsEmitter | async"  class = 'inter-semi-bold-abbey-16px'id = '{{info[0]}}' style ='width: 234px;height:70px;background-color: #B1E7F3;margin:5px;border-radius: 10px;padding: 10px;border: 3px solid lightgray;cursor: pointer;'(click) = 'openFolder(info)'>
                  <p style="font-size: 14px;margin-bottom: 5px;">{{info[1]}}</p>
                  <p style="    font-size: 12px;
                  margin-top: 12px;
                  font-weight: 400;">{{info[2]}}</p>
                  <p style="font-size: 14px;
                  margin-top: -10px;
                  font-weight: 400;
                  width: 203px;
                  text-align: right;"><u>edit</u></p>
                </div>
              </div>
              <div id = 'lessonsFileList' style="display: none;">
                <app-stored-file *ngFor = "let info of lessonsFileEmitter | async" style="z-index: 1;cursor: pointer;" (mousedown) = "logDropKey(info[4])"  id = '{{info}}'[AUXIN] = "info" [TUID] = "meetingID" (deleteMe) = 'deleteMe3($event)'(reloadInner)="reloaderInner($event)"(shareStorage)="shareStorage($event)"(nonInteractiveEmitter)="handle($event)"(imagePlusEmitter)="plusDraw()"(addPDFEmitter) ="addPDF($event)">
                </app-stored-file>
              </div>

            </div>
            <div id = 'documentFiles' class="frame-309" style="display: none;">
              <app-stored-file *ngFor = "let info of documentFilesEmitter | async" style="padding:12px;" id = '{{info}}'[AUXIN] = "info" [TUID] = "meetingID" (deleteMe) = 'deleteMe3($event)'(reloadInner)="reloaderInner($event)"(shareStorage)="shareStorage($event)"(nonInteractiveEmitter)="handle($event)">
              </app-stored-file>
            </div>
            <div id = 'inboxFiles' class="frame-309" style="display: none;">
              <app-stored-file *ngFor = "let info of inboxFilesEmitter | async"  id = '{{info}}'[AUXIN] = "info" [TUID] = "meetingID" (deleteMe) = 'deleteMe3($event)'(reloadInner)="reloaderInner($event)"(shareStorage)="shareStorage($event)"(nonInteractiveEmitter)="handle($event)">
              </app-stored-file>
            </div>
            <div id = 'pens' style="display:'none';">
              <img id = 'Pen1' style='z-index: 1;cursor: url("../../../assets/vector-32@2x.svg") 0 19, auto ;' src="../../../assets/vector-32@2x.svg"/>
              <img id = 'Pen2'style='z-index: 1;cursor: url("../../../assets/Vector (1).svg") 0 19, auto ;'src="../../../assets/Vector (1).svg"/>
              <img id = 'Pen3'style='z-index: 1;cursor: url("../../../assets/Vector (2).svg") 0 19, auto ;'src="../../../assets/Vector (2).svg"/>
              <img id = 'Pen4'style='z-index: 1;cursor: url("../../../assets/Vector (3).svg") 0 19, auto ;'src="../../../assets/Vector (3).svg"/>
              <img id = 'Pen5'style='z-index: 1;cursor: url("../../../assets/Vector (4).svg") 0 19, auto ;'src="../../../assets/Vector (4).svg"/>
              <img id = 'Pen6'style='z-index: 1;cursor: url("../../../assets/Vector (5).svg") 0 19, auto ;'src="../../../assets/Vector (5).svg"/>   
              <img id = 'Pen7'style='z-index: 1;cursor: url("../../../assets/Vector (6).svg") 0 19, auto ;'src="../../../assets/Vector (6).svg"/>
              <img id = 'Pen8'style='z-index: 1;cursor: url("../../../assets/Vector (7).svg") 0 19, auto ;'src="../../../assets/Vector (7).svg"/>
              <img id = 'Pen9'style='z-index: 1;cursor: url("../../../assets/Vector (8).svg") 0 19, auto ;'src="../../../assets/Vector (8).svg"/>
              <img id = 'Pen10'style='z-index: 1;cursor: url("../../../assets/Vector (9).svg") 0 19, auto ;'src="../../../assets/Vector (9).svg"/>           
            </div>
            <div id = 'penSize'style="display:'none';">
                <div id = 'size1' style="z-index:1;cursor: pointer;width: 5px;height: 5px;border-radius: 2.5px;background-color: #1E5663;display: inline-block;vertical-align: middle;margin:2px;">
                </div>
                <div id = 'size2'style="z-index:1;cursor: pointer;width: 10px;height: 10px;border-radius: 5px;background-color: #1E5663;display: inline-block;vertical-align:  middle;margin:2px;">
                </div>
                <div id = 'size3'style="z-index:1;cursor: pointer;width: 15px;height: 15px;border-radius: 7.5px;background-color: #1E5663;display: inline-block;vertical-align:  middle;margin:2px;">
                </div>
                <div id = 'size4'style="z-index:1;cursor: pointer;width: 35px;height: 35px;border-radius: 17.5px;background-color: #1E5663;display: inline-block;vertical-align:  middle;margin:2px;">
                </div>
                <div id = 'size5'style="z-index:1;cursor: pointer;width: 55px;height: 55px;border-radius: 27.5px;background-color: #1E5663;display: inline-block;vertical-align: middle;margin:2px;">
                </div>
                <div id = 'size6'style="z-index:1;cursor: pointer;width: 75px;height: 75px;border-radius: 37.5px;background-color: #1E5663;display: inline-block;vertical-align: middle;margin:2px;">
                </div>
            </div>  
            <div id = 'penShape' style="display:'none';">
              <div id = 'circle' style="z-index:1;cursor: pointer;width: 55px;height: 55px;border-radius: 27.5px;background-color: #1E5663;display: inline-block;vertical-align: middle;margin:2px;">
              </div>
              <div id = 'square' style="z-index:1;cursor: pointer;width: 55px;height: 55px;background-color: #1E5663;display: inline-block;vertical-align: middle;margin:2px;">
              </div>
            </div>
            <div id = 'reviewSlot1' style="display: none;">
              <input type="number" style="width:50px;">
            </div>
            <div id = 'reviewSlot2' style="display: none;">
              <input type="number"style="width:50px;">
            </div>
            <div id = 'reviewSlot3' style="display: none;">
              <input type="number"style="width:50px;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
