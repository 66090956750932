<div class="container-center-horizontal">
  <div id = 'timeRes' style="display: none;">
    <app-calendar-pop-up style = 'position: fixed;
    left: 0;
    top: 0;
    width:100%' [config] = 'selectedTimeFrame | async' [rateInput] = 'hourlyRate | async' (close) = 'closeMe2()' (selectedTimes)="removeTime($event)" (recreateCalendar) = 'recreateCalendar()' (addedTimes)="addTime($event)"></app-calendar-pop-up>
  </div>
    <div class="files screen">
      <div class="overlap-group17">
        <div class="overlap-group1">
          <img   
            class="rectangle-58"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/rectangle-58@1x.png"
          />
          <h1 class="title">My Calendar</h1>
          <div class="group-263">
            <!-- <img id = 'noRequestsYet' style="display: none;" src='../../../assets/noRequestsYet.svg'>
            <img id = 'noRequestsYet2' style="display: none;" src='../../../assets/No Requests Yet.svg'>
            <app-request *ngFor = "let req of validDays | async" style="display: inline-block;margin-right: 27px;margin-bottom: 25px;" [times] = "req.dateTime" [offeredRate] = "req.offeredRate" [numSessions] = "req.numSessions" [teacher] = "teacher" [agreed] = "req.agreed" [grade] = "req.grade" [agreedRate] = "req.finalRate"  [service] = "req.services" [parentUID] = "req.parentUID" [parentEmail] = "req.parentEmail"[ttz] = "req.ttz"[teacherOffsets] = "req.teacherTimeZoneoffset" [teacherUID] = "req.teacherUID" [teacherEmail] = "req.teacherEmail" [UID] = "req.UID" [teacherRate] = "req.teacherRate" (deleteMe) = 'deleteMe($event)' >
            </app-request> -->  
            <div id="menu" style="    background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 3px 3px 20px #dfdfdf;
            margin-top: 0px;
            width: 694px;">
                <span class="dropdown">
                  <button id="dropdownMenu-calendarType" class="btn btn-default btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"(click)='openViewToggle()'>
                      <i id="calendarTypeIcon" class="calendar-icon ic_view_week" style="margin-right: 4px;"></i>
                      <span id="calendarTypeName" class = 'inter-semi-bold-chathams-blue-14px'>Change View</span>&nbsp;
                      <i class="calendar-icon tui-full-calendar-dropdown-arrow"></i>
                  </button>
                  <ul class="dropdown-menu" role="menu" aria-labelledby="dropdownMenu-calendarType" id ='listOfViews'>
                      <li role="presentation" style="padding:5px;"(click) = 'calendar.changeView("day", true);'>
                          <a class="dropdown-menu-title inter-semi-bold-chathams-blue-14px" role="menuitem" data-action="toggle-daily">
                              <i class="calendar-icon ic_view_day"></i>Day View
                          </a>
                      </li>
                      <li role="presentation"style="padding:5px;"(click) = 'calendar.changeView("week", true);'>
                          <a class="dropdown-menu-title inter-semi-bold-chathams-blue-14px" role="menuitem" data-action="toggle-weekly">
                              <i class="calendar-icon ic_view_week"></i>Week View
                          </a>
                      </li>
                      <li role="presentation"style="padding:5px;"(click) = 'calendar.changeView("month", true);'>
                          <a class="dropdown-menu-title inter-semi-bold-chathams-blue-14px" role="menuitem" data-action="toggle-monthly">
                              <i class="calendar-icon ic_view_month"></i>Month View
                          </a>
                      </li>
                  </ul>
              </span>
              <span id="menu-navi">
                  <button type="button" class="btn btn-default btn-sm move-today inter-semi-bold-chathams-blue-14px" data-action="move-today"(click) = 'calendar.today();'>Today</button>
                  <button type="button" class="btn btn-default btn-sm move-day" data-action="move-prev"(click) = 'calendar.prev();'>
                    <img src = '../../../assets/chevron-left.svg'>
                  </button>
                  <button type="button" class="btn btn-default btn-sm move-day" data-action="move-next"(click) = 'calendar.next();'>
                      <img src = '../../../assets/chevron-right.svg'>
                  </button>
              </span>
              <span id="renderRange" style ='margin-left: 10px;'class="inter-semi-bold-chathams-blue-14px">{{calendar.getDate().toDate().toString().split(' ')[1]}} {{calendar.getDate().toDate().toString().split(' ')[3]}}</span>
          </div>    
          <div class="overlap-group" id = 'calendar2' style="height: 648px;overflow: hidden;width:694px;margin-top:58px;border-radius:10px">
          </div>
          </div>
        </div>
        <div id = 'fileLoader' style = 'display: none;'>
          <app-lesson-editor [AUXIN] = 'lessonEmitter | async'(close) = 'closeUploader()' (updateLessonEmitter) = 'updateLesson($event)'></app-lesson-editor> 
        </div>
        <div class="overlap-group">
          <img
            class="rectangle-81"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/rectangle-81@1x.png"
          />
          <img
            id = 'proPic'
            class="ellipse-15"
            src="../../../assets/ceramic.jpeg"
          />
          <div class="group-227" >
            <div class="overlap-group3-2">
              <div class="personal-info inter-normal-gunsmoke-14px-2" id = 'studentProfiles3'style="display: none;">My Students</div>
              <div class="personal-info inter-normal-gunsmoke-14px-2" id = 'teacherLessons3' style="display: none;">My Lessons</div>
            </div>
          </div>
          <div class="kyteja-davidson inter-semi-bold-abbey-16px">{{name}}</div>
          <div class="group-256" style="z-index: 1;cursor: pointer;" routerLink='{{profileLink}}'>
            <div class="write-a-blog-post worksans-semi-bold-chathams-blue-14px">Update My Profile</div>
            <img
              class="line-4"
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/line-4@2x.png"
            />
          </div>
          <div class="frame-265" style="display: none;" (click) = 'openUploader2()' id = 'teacherLessons1'>
            <div class="download">  
              <img src = '../../../assets/lessonIcon.svg'>
            </div>
            <div class="browse-files worksans-semi-bold-chathams-blue-14px">Create New Lesson</div>
          </div>
          <div class="frame-266"id = 'teacherLessons2'style="display: none;">
            <img id = 'noDownsYet' style="display: none;" src='../../../assets/noFilesDown.svg'>
            <div *ngFor = "let info of lessonsEmitter | async"  class = 'inter-semi-bold-abbey-16px'id = '{{info[0]}}' style ='width: 264px;height:70px;background-color: #B1E7F3;margin:5px;border-radius: 10px;padding: 10px;border: 3px solid lightgray;cursor: pointer;'(click) = 'editFolder(info)'>
              <p style="font-size: 14px;margin-bottom: 5px;">{{info[1]}}</p>
              <p style="    font-size: 12px;
              margin-top: 12px;
              font-weight: 400;
              display: inline;
              width: 45%;
              position: absolute;">{{info[2]}}</p>
              <p style="font-size: 14px;
              margin-top: 13px;
              font-weight: 400;
              display: inline;
              left: 233px;
              position: absolute;"><u>edit</u></p>
            </div>
          </div>
          
          <div class="frame-265" style="display: none;" routerLink='{{profileLink}}' id = 'studentProfiles1'>
            <div class="download">  
              <img src = '../../../assets/lessonIcon.svg'>
            </div>
            <div class="browse-files worksans-semi-bold-chathams-blue-14px">Create or Edit Student Profile(s)</div>
          </div>
          <div class="frame-266"id = 'studentProfiles2' style="display: none;">
            <img id = 'noDownsYet2' style="display: none;" src='../../../assets/noKids.svg'>
            <p id = 'noDownsYet2-T' class = 'worksans-semi-bold-chathams-blue-14px'style="display: none;">Please Create A Student Account</p>
            <app-student-profile-widget *ngFor = "let req of studentsEmitter | async" [AUXIN] = "req"></app-student-profile-widget>
          </div>

        </div>
        <div class="overlap-group2">
          <div class="maximize-2">
            <div class="overlap-group9">
              <img
                class="vector-13"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/vector-41@2x.png"
              />
              <img
                class="vector-6"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/vector-43@2x.png"
              />
            </div>
            <div class="overlap-group10">
              <img
                class="vector-12"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/vector-40@2x.png"
              />
              <img
                class="vector-6"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/vector-42@2x.png"
              />
            </div>
          </div>
          <div class="text-619">Upcoming Appointments</div>
          <div class="frame-235">
            <img id = 'noAppointmentsYet' style = 'text-align: center;margin-top:-52px;margin-left: 19px;' src='../../../assets/noAppointmentsYet.svg'>
            <app-profile-upcoming-appointment *ngFor = "let app of appointments | async" style="text-align: center;margin-right:15px" id = '{{app}}' [input] = "scheduleList[app]" [Date] = "app"></app-profile-upcoming-appointment>
            <!-- <app-collab *ngFor = "let app of appointments | async" [app] = "app" [teacher] = "teacher" [UID] = "UID" (newTeacher) = newTeacher($event) (deleteMe) = 'deleteMe2($event)' style="text-align: center;margin-right:15px"></app-collab> -->
          </div>
        </div>
        <div class="group-59" style="z-index: 1;cursor: pointer;" routerLink='{{profileLink}}'>
          <div class="overlap-group15">
            <div class="text-13 worksans-semi-bold-chathams-blue-14px">Update Your Verified Profile</div>
          </div> 
        </div>
        <div class="group-273">
          <div class="overlap-group16">
            <div class="calendar" id = 'studentsTitle' style="display:none;">Students</div>
            <div id = 'students' style="display: none;">
              <app-teacher-student-profile-widget *ngFor = "let req of studentsEmitter2 | async" [AUXIN] = "req"></app-teacher-student-profile-widget>
            </div>
            <div class="calendar" id = 'subTitle' style="display:none;">Subscriptions</div>
            <div id = 'subscriptions' style="display: none;">
              <div _ngcontent-xap-c94="" class="inter-semi-bold-abbey-16px" style="width: 264px; height: 70px; background-color: #B1E7F3; margin: 5px; border-radius: 10px; padding: 10px; border: 3px solid lightgray; cursor: pointer;margin-left: 19px;margin-top: 10px;">
                <img src = '../../../assets/Cat.svg' class = 'ellipse-26'>
                <p _ngcontent-xap-c94="" style="font-size: 14px;display: inline-block;
                margin-bottom: 5px;
                vertical-align: top;
                margin-left: 10px;">Instructor Ortega</p>
                <div style="height:9px"></div>
                <p _ngcontent-xap-c94="" style="font-size: 12px; margin-top: 12px; font-weight: 400; display: inline; width: 45%; ">Reading Teacher</p>
                <p _ngcontent-xap-c94="" style="font-size: 14px; margin-top: 33px; font-weight: 400; display: inline; margin-left: 110px;"><u _ngcontent-xap-c94="">edit</u></p>
              </div>
              <!-- <div _ngcontent-xap-c94="" class="inter-semi-bold-abbey-16px" style="width: 264px; height: 70px; background-color: #B1E7F3; margin: 5px; border-radius: 10px; padding: 10px; border: 3px solid lightgray; cursor: pointer;margin-left: 19px;">
                <img src = '../../../assets/zebra.svg' class = 'ellipse-26'>
                <p _ngcontent-xap-c94="" style="font-size: 14px;display: inline-block;
                margin-bottom: 5px;
                vertical-align: top;
                margin-left: 10px;">Instructor Jackie</p>
                <div style="height:9px"></div>
                <p _ngcontent-xap-c94="" style="font-size: 12px; margin-top: 12px; font-weight: 400; display: inline; width: 45%; ">Thu Sep 08 2022</p>
                <p _ngcontent-xap-c94="" style="font-size: 14px; margin-top: 33px; font-weight: 400; display: inline; margin-left: 110px;"><u _ngcontent-xap-c94="">edit</u></p>
              </div>

              <div _ngcontent-xap-c94="" class="inter-semi-bold-abbey-16px" style="width: 264px; height: 70px; background-color: #B1E7F3; margin: 5px; border-radius: 10px; padding: 10px; border: 3px solid lightgray; cursor: pointer;margin-left: 19px;">
                <img src = '../../../assets/Frog.svg' class = 'ellipse-26'>
                <p _ngcontent-xap-c94="" style="font-size: 14px;display: inline-block;
                margin-bottom: 5px;
                vertical-align: top;
                margin-left: 10px;">Instructor Paul</p>
                <div style="height:9px"></div>
                <p _ngcontent-xap-c94="" style="font-size: 12px; margin-top: 12px; font-weight: 400; display: inline; width: 45%; ">Thu Sep 08 2022</p>
                <p _ngcontent-xap-c94="" style="font-size: 14px; margin-top: 33px; font-weight: 400; display: inline; margin-left: 110px;"><u _ngcontent-xap-c94="">edit</u></p>
              </div>
              <div _ngcontent-xap-c94="" class="inter-semi-bold-abbey-16px" style="width: 264px; height: 70px; background-color: #B1E7F3; margin: 5px; border-radius: 10px; padding: 10px; border: 3px solid lightgray; cursor: pointer;margin-left: 19px;">
                <img src = '../../../assets/Hippo.svg' class = 'ellipse-26'>
                <p _ngcontent-xap-c94="" style="font-size: 14px;display: inline-block;
                margin-bottom: 5px;
                vertical-align: top;
                margin-left: 10px;">Instructor Isabella</p>
                <div style="height:9px"></div>
                <p _ngcontent-xap-c94="" style="font-size: 12px; margin-top: 12px; font-weight: 400; display: inline; width: 45%; ">Tue Sep 06 2022</p>
                <p _ngcontent-xap-c94="" style="font-size: 14px; margin-top: 33px; font-weight: 400; display: inline; margin-left: 110px;"><u _ngcontent-xap-c94="">edit</u></p>
              </div>

              <div _ngcontent-xap-c94="" class="inter-semi-bold-abbey-16px" style="width: 264px; height: 70px; background-color: #B1E7F3; margin: 5px; border-radius: 10px; padding: 10px; border: 3px solid lightgray; cursor: pointer;margin-left: 19px;">
                <img src = '../../../assets/zebra.svg' class = 'ellipse-26'>
                <p _ngcontent-xap-c94="" style="font-size: 14px;display: inline-block;
                margin-bottom: 5px;
                vertical-align: top;
                margin-left: 10px;">Instructor Joe</p>
                <div style="height:9px"></div>
                <p _ngcontent-xap-c94="" style="font-size: 12px; margin-top: 12px; font-weight: 400; display: inline; width: 45%; ">Tue Sep 06 2022</p>
                <p _ngcontent-xap-c94="" style="font-size: 14px; margin-top: 33px; font-weight: 400; display: inline; margin-left: 110px;"><u _ngcontent-xap-c94="">edit</u></p> -->
              </div>
            </div>

            <div id = 'classrooms' style="display: none;">
              <!-- <div routerLink="/VerifiedVideo/tQ6cCJv9fJZ34pWsY6rxlosbV0g1-Xsfl8lwShuauODnwcV7WyoIHnOE3-1646191878336" _ngcontent-xap-c94="" class="inter-semi-bold-abbey-16px" style="width: 264px; height: 70px; background-color: #B1E7F3; margin: 5px; border-radius: 10px; padding: 10px; border: 3px solid lightgray; cursor: pointer;margin-left: 19px;margin-top: 10px;">
                <img src = 'https://firebasestorage.googleapis.com/v0/b/v-t-dev.appspot.com/o/teacherPics%2FXsfl8lwShuauODnwcV7WyoIHnOE3?alt=media&token=a2fbd34a-9625-40b8-9c6e-d334e475f67f' class = 'ellipse-26'>
                <p _ngcontent-xap-c94="" style="font-size: 14px;display: inline-block;
                margin-bottom: 5px;
                vertical-align: top;
                margin-left: 10px;">Sarah</p>
                <div style="height:9px"></div>
                <p _ngcontent-xap-c94="" style="font-size: 12px; margin-top: 12px; font-weight: 400; display: inline; width: 45%; ">Sat Sep 10 2022</p>
                <p _ngcontent-xap-c94="" style="font-size: 14px; margin-top: 33px; font-weight: 400; display: inline; margin-left: 110px;"><u _ngcontent-xap-c94="">edit</u></p>
              </div>
              <div routerLink="/VerifiedVideo/tQ6cCJv9fJZ34pWsY6rxlosbV0g1-Xsfl8lwShuauODnwcV7WyoIHnOE3-1646191878337" _ngcontent-xap-c94="" class="inter-semi-bold-abbey-16px" style="width: 264px; height: 70px; background-color: #B1E7F3; margin: 5px; border-radius: 10px; padding: 10px; border: 3px solid lightgray; cursor: pointer;margin-left: 19px;">
                <img src = '../../../assets/Frog.svg' class = 'ellipse-26'>
                <p _ngcontent-xap-c94="" style="font-size: 14px;display: inline-block;
                margin-bottom: 5px;
                vertical-align: top;
                margin-left: 10px;">Alan</p>
                <div style="height:9px"></div>
                <p _ngcontent-xap-c94="" style="font-size: 12px; margin-top: 12px; font-weight: 400; display: inline; width: 45%; ">Thu Sep 08 2022</p>
                <p _ngcontent-xap-c94="" style="font-size: 14px; margin-top: 33px; font-weight: 400; display: inline; margin-left: 110px;"><u _ngcontent-xap-c94="">edit</u></p>
              </div>

              <div routerLink="/VerifiedVideo/tQ6cCJv9fJZ34pWsY6rxlosbV0g1-Xsfl8lwShuauODnwcV7WyoIHnOE3-1646191878338"_ngcontent-xap-c94="" class="inter-semi-bold-abbey-16px" style="width: 264px; height: 70px; background-color: #B1E7F3; margin: 5px; border-radius: 10px; padding: 10px; border: 3px solid lightgray; cursor: pointer;margin-left: 19px;">
                <img src = '../../../assets/Hippo.svg' class = 'ellipse-26'>
                <p _ngcontent-xap-c94="" style="font-size: 14px;display: inline-block;
                margin-bottom: 5px;
                vertical-align: top;
                margin-left: 10px;">Sandra</p>
                <div style="height:9px"></div>
                <p _ngcontent-xap-c94="" style="font-size: 12px; margin-top: 12px; font-weight: 400; display: inline; width: 45%; ">Thu Sep 08 2022</p>
                <p _ngcontent-xap-c94="" style="font-size: 14px; margin-top: 33px; font-weight: 400; display: inline; margin-left: 110px;"><u _ngcontent-xap-c94="">edit</u></p>
              </div>
              <div routerLink="/VerifiedVideo/tQ6cCJv9fJZ34pWsY6rxlosbV0g1-Xsfl8lwShuauODnwcV7WyoIHnOE3-1646191878339"_ngcontent-xap-c94="" class="inter-semi-bold-abbey-16px" style="width: 264px; height: 70px; background-color: #B1E7F3; margin: 5px; border-radius: 10px; padding: 10px; border: 3px solid lightgray; cursor: pointer;margin-left: 19px;">
                <img src = '../../../assets/Cat.svg' class = 'ellipse-26'>
                <p _ngcontent-xap-c94="" style="font-size: 14px;display: inline-block;
                margin-bottom: 5px;
                vertical-align: top;
                margin-left: 10px;">Blake</p>
                <div style="height:9px"></div>
                <p _ngcontent-xap-c94="" style="font-size: 12px; margin-top: 12px; font-weight: 400; display: inline; width: 45%; ">Tue Sep 06 2022</p>
                <p _ngcontent-xap-c94="" style="font-size: 14px; margin-top: 33px; font-weight: 400; display: inline; margin-left: 110px;"><u _ngcontent-xap-c94="">edit</u></p>
              </div>

              <div routerLink="/VerifiedVideo/tQ6cCJv9fJZ34pWsY6rxlosbV0g1-Xsfl8lwShuauODnwcV7WyoIHnOE3-1646191878340" _ngcontent-xap-c94="" class="inter-semi-bold-abbey-16px" style="width: 264px; height: 70px; background-color: #B1E7F3; margin: 5px; border-radius: 10px; padding: 10px; border: 3px solid lightgray; cursor: pointer;margin-left: 19px;">
                <img src = '../../../assets/zebra.svg' class = 'ellipse-26'>
                <p _ngcontent-xap-c94="" style="font-size: 14px;display: inline-block;
                margin-bottom: 5px;
                vertical-align: top;
                margin-left: 10px;">Stacey</p>
                <div style="height:9px"></div>
                <p _ngcontent-xap-c94="" style="font-size: 12px; margin-top: 12px; font-weight: 400; display: inline; width: 45%; ">Tue Sep 06 2022</p>
                <p _ngcontent-xap-c94="" style="font-size: 14px; margin-top: 33px; font-weight: 400; display: inline; margin-left: 110px;"><u _ngcontent-xap-c94="">edit</u></p>
              </div>
            </div> -->

          </div>
         </div>
      </div>
    </div>
    <input  required type="file" class="custom-file-input" (change) = "upload($event)" accept = ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"id="inputGroupFile01" style="display: none;">
  </div>






