<div class="container-center-horizontal noSelect">
  <div class="homepage screen">
    <div class="overlap-group1">

      <div  style="background-image: url('../../assets/tt1.jpg');    background-repeat: no-repeat;
background-size: contain;; height: 1189px; left: 0; mix-blend-mode: normal; position: absolute; width: 100%; min-width: 1440px;"></div>
      <div class="group-103">
        <div class="text-2 worksans-semi-bold-abbey-32px">Virtual Reading Lessons From K-12 Teachers</div>
        <div class=" worksans-semi-bold-abbey-32px" style="margin-top:25px;font-size: 20px;color:white">The verified network of credentialed K-12 Teachers.</div>
        <button  routerLink = '/OrgSignUp' style="align-items: flex-end;
        background-color: var(--chathams-blue);
        border-radius: 10px;
        display: inline-block;margin-right:25px;
        height: 45px;
        justify-content: flex-end;
        min-width: 139px;
        padding: 10px 0.9px;margin-top:25px;"><p style="    color: #ffffff;
    font-family: var(--font-family-work_sans);
    font-weight: 600;
    font-size:20px;
    line-height: 22px;
    min-height: 24px;
    min-width: 135px;
    mix-blend-mode: normal;
    text-align: center;
    white-space: nowrap;
    width: 135px">Enroll</p></button>
            <button  routerLink = '/TeacherWelcome' style="align-items: flex-end;display: inline-block;
            background-color: var(--chathams-blue);
            border-radius: 10px;
     
            height: 45px;
            justify-content: flex-end;
            min-width: 139px;
            padding: 10px 0.9px;margin-top:25px;"><p style="    color: #ffffff;
        font-family: var(--font-family-work_sans);
        font-weight: 600;
        font-size:20px;
        line-height: 22px;
        min-height: 24px;
        min-width: 135px;
        mix-blend-mode: normal;
        text-align: center;
        white-space: nowrap;
        width: 135px">Teach</p></button>
      </div>
    
      <div class="group-133"style="z-index: 1;">
        <div class="text-4 worksans-semi-bold-abbey-32px">1M Hours Of Reading</div>
        <div class="rectangle-61"></div>
        <p class="text-5 inter-normal-mine-shaft-14px">
          In the face of a growing reading level crisis, our mission has never been more critical. We are proud to announce our goal of providing 1,000,000 hours of reading lessons annually, dedicating ourselves to nurturing literacy across communities. Through our comprehensive program, we aim to tackle the challenges head-on, offering personalized, engaging, and impactful reading sessions designed to meet the needs of every learner.
        </p>
      </div>
      <div class="group-140" style="z-index: 1;">
        <div class="group-136">
          <div class="overlap-group3-4">
            <div class="group-114">
              <div class="overlap-group4-2">
                <img
                  class="vector-3"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector-9@2x.svg"
                />
                <img
                  class="vector-17"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector-10@2x.svg"
                />
                <img
                  class="vector-4"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector-11@2x.svg"
                />
                <img
                  class="vector-26"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector-12@2x.svg"
                />
              </div>
            </div>
            <div class="group-135">
              <div class="overlap-group5">
                <img
                  class="vector-2"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector-16@2x.svg"
                />
                <img
                  class="vector-2"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector-15@2x.svg"
                />
                <img
                  class="vector-2"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector-14@2x.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="group-134" style="z-index: 1;">
        <div class="group-130">
          <div class="ask-free-questions worksans-semi-bold-abbey-32px">Results In 5 Sessions</div>
          <div class="rectangle-62"></div>
        </div>
        <p class="text-9 inter-normal-mine-shaft-14px">
          Discover the power of transformation through reading with our unique program, designed to elevate students' reading levels within just five sessions. Our dedicated approach combines personalized attention, innovative strategies, and engaging materials that captivate young minds and encourage a deeper connection with the written word. 
        </p>
      </div>
      <div class="group-132"style="z-index: 1;">
        <div class="group-131">
          <div class="we-pay-3-x-more worksans-semi-bold-abbey-32px">95% For Teachers</div>
          <div class="rectangle-62-1"></div>
        </div>
        <p class="text-10 inter-normal-mine-shaft-14px"style="z-index: 1;">
          At the heart of our initiative lies a deep-seated belief in the value of teachers and the transformative power of education. Recognizing the pivotal role educators play in shaping the future, we are committed to ensuring they are rewarded for their passion and expertise. That's why we are proud to allocate 95% of all funds directly to our teachers, empowering them to focus on what they do best: inspiring and nurturing the next generation of learners.
        </p>
      </div>
      <div class="group-141"style="z-index: 1;">
        <img
          class="vector-22"
          src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector-17@2x.svg"
        />
      </div>
      <div class="group-142"style="z-index: 1;">
        <div class="frame">
          <img
            class="vector-18"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector-18@2x.svg"
          />
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 1000px;background-image: var(--concrete); ;top: 1050px;position: absolute;z-index: 1; background-size: 100% auto;
">
      <h1 class="about-us">Getting Started As Easy As 1,2,3...</h1>
      <div class  = 'p-6 shadow-lg rounded-lg bg-white text-gray-700' style="display: inline-block;width: 26%;margin: 1%;height: 600px;border-radius: 8px;margin-top: 30px;margin-left: 129px;vertical-align: top;padding:40px;">
        <div class="text-4 worksans-semi-bold-abbey-32px" >1) Onboarding + Program Creation</div>
        <p class="text-9 inter-normal-mine-shaft-14px" style="font-size: 20px;">
          1. Collect cohort sizes, age ranges and student interests work sheet.
        </p>
        <p class="text-9 inter-normal-mine-shaft-14px" style="font-size: 20px;">
          2. 10 Minute 1-on-1 assement with each participating student and teacher.
        </p>
        <p class="text-9 inter-normal-mine-shaft-14px" style="font-size: 20px;">
          3. Learner group creation and scheduling. 
        </p>
      </div>
      <div class  = 'p-6 shadow-lg rounded-lg bg-white text-gray-700'  style="display: inline-block;width: 26%;margin: 1%;height: 600px;border-radius: 8px;margin-top: 30px;vertical-align: top;padding:40px;">
        <div class="text-4 worksans-semi-bold-abbey-32px" >2) Group Lessons And Sessions</div>
        <p class="text-9 inter-normal-mine-shaft-14px" style="font-size: 20px;">
          1. Each group will meet with their instructor twice a week for 45 minutes.
        </p>
        <p class="text-9 inter-normal-mine-shaft-14px" style="font-size: 20px;">
          2. Curriculum will be designed and tailored to the real time results of the group.
        </p>
        <p class="text-9 inter-normal-mine-shaft-14px" style="font-size: 20px;">
          3. Weekly assements to make sure our learners are always making strides.
        </p>
      </div>
      <div class  = 'p-6 shadow-lg rounded-lg bg-white text-gray-700'  style="display: inline-block;width: 26%;margin: 1%;height: 600px;border-radius: 8px;margin-top: 30px;vertical-align: top;padding:40px;">
        <div class="text-4 worksans-semi-bold-abbey-32px" >3) Real Time Result Tracking</div>
        <p class="text-9 inter-normal-mine-shaft-14px" style="font-size: 20px;">
          1. Organizations get weekly custom reports to make sure there doners have visibility
        </p>
        <p class="text-9 inter-normal-mine-shaft-14px" style="font-size: 20px;">
          2. Students get a custom profile to keep track of what they are learning. 
        </p>
        <p class="text-9 inter-normal-mine-shaft-14px" style="font-size: 20px;">
          3. Parents can see their students profiles and get resources to help them in trouble areas. 
        </p>
      </div>
    </div>
    <div style="width: 100%;height: 565px;top: 1830px;position: absolute;z-index: 1;">
      <h1 class="about-us">Impacting Families And Teachers...</h1>
      <div class="testimonial-section">
        <div class="testimonial">
          <blockquote class="testimonial-text text-9 inter-normal-mine-shaft-14px" style="font-size:20px;">"Growing up I was a terrible student, school wasnt important in my house. Seeing my 4 and 6 year old engaged and reading reminded me how far we have come."</blockquote>
          <cite class="testimonial-author"  style="display: inline-block;vertical-align: top;">- Veronica (Parent)</cite>
        </div>
        <div class="testimonial">
          <blockquote class="testimonial-text text-9 inter-normal-mine-shaft-14px" style="font-size:20px;">"Why I wont work with other learning sites is they take most of the money after each session, this may work for tutors, but not teachers."</blockquote>
          <cite class="testimonial-author"  style="display: inline-block;vertical-align: top;">- Kyteja (Teacher)</cite>
        </div>
        <div class="testimonial">
          <blockquote class="testimonial-text text-9 inter-normal-mine-shaft-14px" style="font-size:20px;">"With a true mission of creating oppurtunities for Teachers and Students we hope Verified Teachers approach to empowerment becomes the new standard."</blockquote>
          <cite class="testimonial-author"  style="display: inline-block;vertical-align: top;">- Cassandra (Boys And Girls Club)</cite>
        </div>
        <!-- Repeat the .testimonial block for each testimonial -->
      </div>
    </div>
    <!-- <div class="group-97">
      <img
        routerLink = ''
        class="image-1"
        src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/image-1@2x.png"
      />
      <div class="group-91">
        <div routerLink = 'FindATeacher/fq2t7rkw268-EM-6-b' class="teachers-1 worksans-medium-abbey-14px">TEACHERS</div>
        <div class="answers worksans-medium-abbey-14px">ANSWERS</div>
        <div class="blog worksans-medium-abbey-14px">BLOG</div>
        <div class="teacher-sign-up worksans-medium-abbey-14px">TEACHER SIGN UP</div>
        <div class="promotions worksans-medium-abbey-14px">PROMOTIONS</div>
        <div class="faq worksans-medium-abbey-14px">FAQ</div>
      </div>
      <div class="group-58">
        <div class="overlap-group3-2"><div class="sign-in">Sign In</div></div>
      </div>
      <div class="sign-up-1 worksans-semi-bold-chathams-blue-14px">Sign Up</div>
    </div> -->
  </div>
</div>
<div style="  background-color:var(--concrete);
              height: 1189px;
              left: 0;  
              mix-blend-mode: normal;
              position: absolute;
              top: 630px;
              width: 100%;  
              min-width: 1440px;"></div>
<div style = "
height: 357px;left: 0;position: absolute;top: 2445px;width: 100%;min-width: 1440px;background-color: white;
">
<h1 class="about-us">The Verified Mission...</h1>
<p style="width: 80%;margin-left:10%;text-align:center;font-size: 20px;" class="inter-normal-mine-shaft-14px">
  Verified Teachers is dedicated to transforming the landscape of education by addressing the current reading crisis with a forward-thinking approach. Our mission is to empower educators by leveraging the power of virtual assistance, creating a bridge between credentialed K-12 teachers and non-profits focused on literacy. By prioritizing the allocation of 95% of all proceeds directly to our teachers, we not only underscore our belief in their value but also ensure they are motivated to provide the highest quality reading lessons. At Verified Teachers, we stand by the principle that teachers are indeed the best and most crucial agents of change in education. Through our innovative platform, we aim to facilitate meaningful connections, foster a love for reading among students, and contribute to a future where every child has the opportunity to excel in literacy.
</p>
<p style="width: 80%;margin-left:10%;text-align:center;margin-top:30px;font-size: 16px;" class="inter-normal-mine-shaft-14px">
  -Fred Mendoza, Founder & CEO
  </p>
</div>
