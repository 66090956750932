<div style="width:220px;height:100px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230);text-align: center;margin: 0 auto;margin-top: 10px;">
    <p>{{title}}</p>
    <a *ngIf = 'isLink' href= {{Link}} target="_blank" rel="noopener noreferrer">
        {{Link}}
    </a>
    <button *ngIf = 'isFile' (click)="reloader();">
        Open
    </button>  
    <button *ngIf = 'isPersonal' (click)="reloader();">
        Open
    </button>  
</div>
