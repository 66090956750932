<!DOCTYPE html>
<html>
  <head>   
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=1440, maximum-scale=1.0" />
    <link rel="shortcut icon" type="image/png" href="https://animaproject.s3.amazonaws.com/home/favicon.png"/>
    <meta name="og:type" content="website" />
    <meta name="twitter:card" content="photo"/>
    <meta name="author" content="Alfredo Mendoza"/>
  </head>
  <body id = 'screenBod'
  style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" >
    <input type="hidden"  id="anPageName" name="page" value="hidden-video-display"/>
    <div id = 'lobby'>
      <app-lobby (enterClass) = 'exitLobby($event)'></app-lobby>     
    </div>
    <div class="container-center-horizontal unselectable blockHide" id = 'capture'>
      <div id = 'fileLoader' style = 'display: none;'>
        <app-file-uploader (close) = 'closeUploader()'></app-file-uploader>
      </div>
      <div id="myModal" class="modal">
        <!-- Modal content -->
        <div id = 'modal-content' class="modal-content">
          <span class="close" style="z-index: 1;cursor: pointer;width: 16px;margin-top: -10px;position:fixed;" (click) = 'toggle("sharePointer")'>&times;</span>
          <div style="margin-top: 33px;">
            <div style="display: inline-block;width: 831px;min-height: 300px;max-height: 300px;margin-bottom: 30px;" >
              <div class="overlap-group5" style="cursor: pointer;z-index: 1;" >
                <input ngModel name="func" #func ="ngModel" (change) = "Attitude = func.value" type="number"value= '{{currentAttitude | async}}' type="number" class="place inter-semi-bold-chathams-blue-14px" style = 'outline: none;border: none;' placeholder="Attitude" max = '100' min = '0' maxlength = "3">
                <div class="chevron-down-3">
                  <img
                    class="vector-111"
                    src="../../../assets/vector-29@2x.png"
                  />
                </div>
              </div>
              <div class="overlap-group5" style="cursor: pointer;z-index: 1;">
                <input ngModel name="func1" #func1 ="ngModel" (change) = "Attention = func1.value" type="number"value= '{{currentAttention | async}}'type="number" class="place inter-semi-bold-chathams-blue-14px" style = 'outline: none;border: none;' placeholder="Attention" max = '100' min = '0' maxlength = "3">
                <div class="chevron-down-3">
                  <img
                    class="vector-111"
                    src="../../../assets/vector-29@2x.png"
                  />
                </div>
              </div>
              <div class="overlap-group5" style="cursor: pointer;z-index: 1;">
                <input ngModel name="func2" #func2 ="ngModel" (change) = "Grasp = func2.value" type="number"value= '{{currentGrasp | async}}' type="number" class="place inter-semi-bold-chathams-blue-14px" style = 'outline: none;border: none;' placeholder="Grasp" max = '100' min = '0' maxlength = "3">
                <div class="chevron-down-3">
                  <img
                    class="vector-111"
                    src="../../../assets/vector-29@2x.png"
                  />
                </div>
              </div>
              <div class="overlap-group5-1-1" style="z-index: 1;cursor: pointer;" (click) = sendToParent()>
                <div class="inter-semi-bold-chathams-blue-14px place-1">Send To Parent</div>
              </div>
              <textarea ngModel name="func3" #func3 ="ngModel" (change) = "report = func3.value" type="number"value= '{{currentReport | async}}' class = 'worksans-medium-abbey-14px-1'style = 'min-height: 300px;max-height: 300px;width:100%;resize: none;border-radius: 15px;border:none;padding:20px;font-size: 16px;outline: none !important;' placeholder="Note For Todays Lesson..."></textarea>
            </div>
            <div style="display: inline-block;width: 369px;;vertical-align: top;">
              <div class="group-446" data-id="1183:9721">
                <div class="meeting-screenshot tab-bold" data-id="1183:9687">Meeting screenshot</div>
                <img
                  crossorigin="anonymous" 
                  style = 'width: 98%;box-shadow:2px 2px 5px #c3c3c3, -4px -4px 1px #ffffff;border-radius: 15px;margin-left: 4px;margin-top: 15px;'
                  id = 'modalImagePreview'
                />
              </div>
              <div class="maximize-2-1234" data-id="1183:9722" style="cursor: pointer;z-index: 1;" (click) = 'openModalImage()'>
                <div class="overlap-group1-1234" data-id="an|zMjhcOt6">
                  <img
                    class="vector-1-1234"
                    data-id="1183:9724"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6124124baf7899b8ef525bb1/img/vector-91@2x.png"
                  />
                  <img
                    class="vector-1234 "
                    data-id="1183:9726"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6124124baf7899b8ef525bb1/img/vector-92@2x.png"
                  />
                </div>
                <div class="overlap-group-1234" data-id="an|IuTWDdzH">
                  <img
                    class="vector-234"
                    data-id="1183:9723"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6124124baf7899b8ef525bb1/img/vector-90@2x.png"
                  />
                  <img
                    class="vector-1234 "
                    data-id="1183:9725"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6124124baf7899b8ef525bb1/img/vector-92@2x.png"
                  />
                </div>
              </div>
              <div class="group-227">
                <div class="overlap-group3-2">
                  <div class="personal-info inter-normal-gunsmoke-14px-2">Meeting Files</div>
                  <div class="rectangle-36">
                    <app-stored-file *ngFor = "let info of inboxFilesEmitter | async" style="padding:12px;" id = '{{info}}'[AUXIN] = "info" [TUID] = "meetingID" (deleteMe) = 'deleteMe3($event)'(reloadInner)="reloaderInner($event)"(shareStorage)="shareStorage($event)"(nonInteractiveEmitter)="handle($event)">
                    </app-stored-file>
                  </div>
                </div>
              </div>
              
              <!-- <div class="overlap-group5-1">

              </div>
              <div style="height: 300px;width:494px;border:1px solid black;display: inline-block;margin-left: 20px;">
                
              </div> -->

            </div>
          </div>
          <img crossorigin="anonymous" id = 'modalImage' style="display: none;"/>
        </div>
      
      </div>
      <div class="hidden-video-display screen" id = 'videoChat'>

        <span id = 'resizeSquares'>
          <span *ngFor = "let info of canvasImagesEmitter | async">
            <span   id = '{{info}}'(mousedown) = 'resize(info,0)'>
            </span>
            <span id = '1-{{info}}'(mousedown) = 'resize(info,1)' style="z-index: 100;cursor: pointer;">       
            </span>
            <span id = '2-{{info}}'(mousedown) = 'resize(info,2)'>
            </span>
            <span id = '3-{{info}}'(mousedown) = 'resize(info,3)'>
            </span>
            <span id = '4-{{info}}'(mousedown) = 'resize(info,4)'>
            </span>
          </span>
        </span>
        <span id = 'coverSquares'>
        </span>
        <span id = 'textBoxes'>
          <span *ngFor = "let text of textBoxEmitter | async">
            <textarea id = 'text-{{text}}' class = 'size20' style="display: none;" ngModel name = {{text}} (ngModelChange) = "onChange(text,$event)" (change) = "addTextChange(text)">

            </textarea>
            <span id = '{{text}}'(mousedown) = 'resizeText(text,0)' >
                
            </span>
            <span id = '1-{{text}}'(mousedown) = 'resizeText(text,1)'>
                
            </span>
            <span id = '2-{{text}}'(mousedown) = 'resizeText(text,2)'>
  
            </span>
            <!-- <span id = '3-{{text}}'(mousedown) = 'resizeText(text,3)'>
  
            </span>
            <span id = '4-{{text}}'(mousedown) = 'resizeText(text,4)'>
  
            </span> -->
          </span>
        </span>  
        <canvas id = 'canvas' style = 'cursor: url("../../../assets/vector-32@2x.svg"), auto ;background-color: transparent;position: absolute;'height = '9200'width="1440"></canvas>   
        <canvas id = 'sharedCanvas' style = 'background-color:whitesmoke;'height = '9200'width="1440"></canvas>
        <span id = 'videoDisplays' class="twilioDisplays2 rectangle-40">  
            <div style="position: fixed;margin-top: -32px;">
                <img id = 'disp3' (click) = 'toggleDisplayView("3")' style = 'display:inline-block;margin-right: 8px;z-index: 1;cursor: pointer;'src = '../../../assets/boxGrey.svg'>
                <img id = 'disp2' (click) = 'toggleDisplayView("2")'style = 'display:inline-block;margin-right: 8px;z-index: 1;cursor: pointer;'src = '../../../assets/rowGreen.svg'>
                <img id = 'disp' (click) = 'toggleDisplayView("")'style = 'display:inline-block;z-index: 1;cursor: pointer;'src = '../../../assets/gridGrey2.svg'>
            </div>
            <span style="margin-top:20px">
                <app-video-display *ngFor = "let info of videoFeedsEmitter | async"></app-video-display>
            </span>
        </span>
        <!-- <div class="rectangle-23-C61RwL" id ='participants' style="background-color: lightblue;">
        </div> -->
        <!-- <div class="stella-mur-C61RwL">Fred Mendoza</div> -->
        <img class="teal-5-C61RwL unselectable" src="../../../assets/teal-5@2x.png" />

        <a id = 'endSite' target="_blank" style="display: none;"></a>
        <div class="x1507412 component component-wrapper not-ready">
          <div id ='taskBarBackground'class="rectangle-25-679gI0"></div>
          <div class="group-48-679gI0" style="z-index: 1;cursor: pointer;" (click) = 'endCall()'>
            <div class="phone-75FAGS"><img class="vector-0izxiL" src="../../../assets/vector-14@2x.svg" /></div>
            <div class="end-this-call-i15074128073-75FAGS">End This Call</div>
          </div>
          <div class="frame-46-679gI0">
            <!-- <div class="rotate-ccw-p7pA9l" id = 'back' style="z-index: 1;cursor: pointer;"(click) = 'back()'>
              <img class="vector-xkVvD6" src="../../../assets/vector-15@2x.svg" />
              <img class="vector-n8HNbJ" src="../../../assets/vector-16@2x.svg" />
            </div> -->
            <div class="x-p7pA9l" style="z-index: 1;cursor: pointer;" id = 'clear' (click) = 'clearCanvas()'>
              <img class="vector-o9qAbG" src="../../../assets/vector-17@2x.svg" />
              <img class="vector-dKhwsY" src="../../../assets/vector-18@2x.svg" />
            </div>
            <!-- <div class="x-p7pA9l-1" style="z-index: 1;cursor: pointer;" id = 'forward'>
              <img class="vector-3-1" src="../../../assets/vector-19@2x.svg">
              <img class="vector--1" src="../../../assets/vector-20@2x.svg">
            </div>  -->
          </div>
          <div class="frame-70-679gI0" >
            <div class="image-2bMqH9 " id = 'files' (click) = 'toggle("files")' style="z-index: 1;cursor: pointer;">
              <img class="vector-LTx4EW" src="../../../assets/vector-28@2x.svg" />
              <img class="vector-6b1gVd" src="../../../assets/vector-29@2x.svg" />
              <img class="vector-0HQo1V" src="../../../assets/vector-30@2x.svg" />
              
              <div class="chevron-down-LTx4EW"><img class="vector-vWxEGh" src="../../../assets/vector-31@2x.svg" /></div>
            </div>
            <div id = 'dotUpdater' class="group-69-2bMqH9" (click) = 'toggle("dotUpdater")' style="z-index: 1;cursor: pointer;">
              <div class="chevron-down-h3uYEB"><img class="vector-BJ7chN" src="../../../assets/vector-31@2x.svg" /></div>
              <div class="group-67-h3uYEB">
                <div class="rectangle-46-R6SOdV"></div>
                <div class="rectangle-49-R6SOdV"></div>
                <div class="rectangle-47-R6SOdV"></div>
                <div class="rectangle-48-R6SOdV"></div>
              </div>
            </div>
            <div id = 'earUpdater' class="frame-2-2bMqH9" style="z-index: 1;cursor: pointer;"(click) = 'toggle("earUpdater")' ><img class="rectangle-39-W67DZv" src="../../../assets/rectangle-39@2x.svg" /></div>
            <div id = 'capShape' class="group-68-2bMqH9" style="z-index: 1;cursor: pointer;"(click) = 'toggle("capShape")'>
              <div class="square-KetGau"><img class="vector-zqCWVZ" src="../../../assets/vector-28@2x.svg" /></div>
              <div class="chevron-down-KetGau"><img class="vector-FPpwNf" src="../../../assets/vector-35@2x.svg" /></div>
            </div>
            <div id = 'penColor'class="circle-2bMqH9" (click) = 'toggle("penColor")' style="z-index: 1;cursor: pointer;">
              <div class="chevron-down-l58JnV"><img class="vector-4DdHca" src="../../../assets/vector-36@2x.svg" /></div>
              <div class="group-81-l58JnV">
                <div class="ellipse-1-7obOkd"></div>
                <img class="vector-7obOkd" src="../../../assets/vector-37@2x.svg" />
              </div>
            </div>
            <div  id = 'camera' class="camera-2bMqH9" (click) = 'toggle("camera")' style="z-index: 1;cursor: pointer;">
              <img class="vector-fxPlXv" src="../../../assets/vector-41@2x.svg" />
              <img class="vector-EqS9NJ" src="../../../assets/vector-42@2x.svg" />
            </div>
            <div id = 'type' class='type-2bMqH9' style="z-index: 1;cursor: pointer;" (click) = 'toggle("type")'>
              <img src="../../../assets/type.svg" />
            </div>
            <div id = 'newFile' class='sharePointer-2bMqH9' style="z-index: 1;cursor: pointer;"  (click) = 'toggle("newFile")'>
              <img style ='height:20px;width:20px'src="../../../assets/download.svg" />
            </div>
            <div id = 'sharePointer' class='sharePointer-2bMqH9-1' style="z-index: 1;cursor: pointer;"  (click) = 'toggle("sharePointer")'>
              <img src="../../../assets/clipboard.svg" />
            </div>
            <div id = 'inbox' class='inbox-2bMqH9' style="z-index: 1;cursor: pointer;"(click) = 'toggle("inbox")'>
              <img src="../../../assets/inbox.svg" />
            </div>
            <div id = 'document' class='document-2bMqH9' style="z-index: 1;cursor: pointer;"(click) = 'toggle("document")'>
              <img src="../../../assets/document.svg" />
            </div>
            <div id = 'move' class="move" (click) = 'toggle("move")'>
              <img
                src="../../../assets/move.svg"
              />
            </div>  
          </div>
          <div id = 'screenCapture' class="screenCaptureWait">
            <div style="text-align:center;margin-bottom: 20px;z-index: 1;cursor: pointer;background-color: #b0e6f3;border-radius: 15px;"(click)="triggerSnapshot();">
              <img src="../../../assets/camera.svg" style="padding:5px"/>
            </div> 
            <webcam [height]="450" [width]="500" [trigger]="triggerObservable" (imageCapture)="upload($event)" *ngIf="showWebcam"
            [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions"
            [imageQuality]="1"
            (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)"
            ></webcam>  
          </div>

          <div id = 'dropship' class="overlap-group1-1">
            <div id = 'dropShipper' class="frame-309" style="display: none;">
              <app-stored-file *ngFor = "let info of personalFilesEmitter | async" style="z-index: 1;cursor: pointer;padding:12px;" (mousedown) = "logDropKey(info[4])"  id = '{{info}}'[AUXIN] = "info" [TUID] = "meetingID" (deleteMe) = 'deleteMe3($event)'(reloadInner)="reloaderInner($event)"(shareStorage)="shareStorage($event)">
              </app-stored-file>
            </div>
            <div id = 'documentFiles' class="frame-309" style="display: none;">
              <app-stored-file *ngFor = "let info of documentFilesEmitter | async" style="padding:12px;" id = '{{info}}'[AUXIN] = "info" [TUID] = "meetingID" (deleteMe) = 'deleteMe3($event)'(reloadInner)="reloaderInner($event)"(shareStorage)="shareStorage($event)"(nonInteractiveEmitter)="handle($event)">
              </app-stored-file>
            </div>
            <div id = 'inboxFiles' class="frame-309" style="display: none;">
              <app-stored-file *ngFor = "let info of inboxFilesEmitter | async" style="padding:12px;" id = '{{info}}'[AUXIN] = "info" [TUID] = "meetingID" (deleteMe) = 'deleteMe3($event)'(reloadInner)="reloaderInner($event)"(shareStorage)="shareStorage($event)"(nonInteractiveEmitter)="handle($event)">
              </app-stored-file>
            </div>
            <div id = 'pens' style="display:'none';">
              <img id = 'Pen1' style='z-index: 1;cursor: url("../../../assets/vector-32@2x.svg"), auto ;' src="../../../assets/vector-32@2x.svg"/>
              <img id = 'Pen2' style='z-index: 1;cursor: url("../../../assets/Vector (1).svg"), auto ;'src="../../../assets/Vector (1).svg"/>
              <img id = 'Pen3' style='z-index: 1;cursor: url("../../../assets/Vector (2).svg"), auto ;'src="../../../assets/Vector (2).svg"/>
              <img id = 'Pen4' style='z-index: 1;cursor: url("../../../assets/Vector (3).svg"), auto ;'src="../../../assets/Vector (3).svg"/>
              <img id = 'Pen5' style='z-index: 1;cursor: url("../../../assets/Vector (4).svg"), auto ;'src="../../../assets/Vector (4).svg"/>
              <img id = 'Pen6' style='z-index: 1;cursor: url("../../../assets/Vector (5).svg"), auto ;'src="../../../assets/Vector (5).svg"/>   
              <img id = 'Pen7'style='z-index: 1;cursor: url("../../../assets/Vector (6).svg"), auto ;'src="../../../assets/Vector (6).svg"/>
              <img id = 'Pen8'style='z-index: 1;cursor: url("../../../assets/Vector (7).svg"), auto ;'src="../../../assets/Vector (7).svg"/>
              <img id = 'Pen9'style='z-index: 1;cursor: url("../../../assets/Vector (8).svg"), auto ;'src="../../../assets/Vector (8).svg"/>
              <img id = 'Pen10'style='z-index: 1;cursor: url("../../../assets/Vector (9).svg"), auto ;'src="../../../assets/Vector (9).svg"/>           
            </div>
            <div id = 'penSize'style="display:'none';">
                <div id = 'size1' style="z-index:1;cursor: pointer;width: 5px;height: 5px;border-radius: 2.5px;background-color: #1E5663;display: inline-block;vertical-align: middle;margin:2px;">
                </div>
                <div id = 'size2'style="z-index:1;cursor: pointer;width: 10px;height: 10px;border-radius: 5px;background-color: #1E5663;display: inline-block;vertical-align:  middle;margin:2px;">
                </div>
                <div id = 'size3'style="z-index:1;cursor: pointer;width: 15px;height: 15px;border-radius: 7.5px;background-color: #1E5663;display: inline-block;vertical-align:  middle;margin:2px;">
                </div>
                <div id = 'size4'style="z-index:1;cursor: pointer;width: 35px;height: 35px;border-radius: 17.5px;background-color: #1E5663;display: inline-block;vertical-align:  middle;margin:2px;">
                </div>
                <div id = 'size5'style="z-index:1;cursor: pointer;width: 55px;height: 55px;border-radius: 27.5px;background-color: #1E5663;display: inline-block;vertical-align: middle;margin:2px;">
                </div>
                <div id = 'size6'style="z-index:1;cursor: pointer;width: 75px;height: 75px;border-radius: 37.5px;background-color: #1E5663;display: inline-block;vertical-align: middle;margin:2px;">
                </div>
            </div>
            <div id = 'penShape' style="display:'none';">
              <div id = 'circle' style="z-index:1;cursor: pointer;width: 55px;height: 55px;border-radius: 27.5px;background-color: #1E5663;display: inline-block;vertical-align: middle;margin:2px;">
              </div>
              <div id = 'square' style="z-index:1;cursor: pointer;width: 55px;height: 55px;background-color: #1E5663;display: inline-block;vertical-align: middle;margin:2px;">
              </div>   
            </div>
            <div id = 'reviewSlot1' style="display: none;">
              <input type="number" style="width:50px;">
            </div>
            <div id = 'reviewSlot2' style="display: none;">
              <input type="number"style="width:50px;">
            </div>
            <div id = 'reviewSlot3' style="display: none;">
              <input type="number"style="width:50px;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
