import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})

export class SessionEnvsService {
  /**
   * 
   * @param afm - access to media files
   * @param db - access to Real Time Database 
   * 
   * The Session Enviornments Service is used to transfer our in session classroom contributions. Our FireBase Real time ref is used 
   * to reference our absracted representions of the interactions on the collabortive canvas. Each FireBase object 
   * contains has a UID in the datbase with the addition of their indexing key, which qualifies a resources as one of our 
   * data types{LINE,TEXT,IMAGE,FILE}. We must be able to effectively retreive and update time stamps and postional data on all of these 
   * reosurces. Resources know there TS, size, postion, and service placement. Through these attributes we are able to maintain 
   * a unified state of a board. 
   * 
   */

  constructor(private db: AngularFireDatabase){

   }

   // all resources will be stored together as resource data packets 
   // the canvas config is a cluster of data packet which will be able to repopulate a classroom enviorn 
   addResource(DATAPACKET,MeetingUID,UID){
     var folder = DATAPACKET.split(':')[0]
     var newDBRef = + new Date()
     this.db.database.ref('sessionEnvironments/' + `${MeetingUID}` + '/' + `${UID}` + '/canvasConfig/' + `${folder}`)
     .update({[newDBRef]:`${DATAPACKET}`})
     .then(()=>{
       return '200'
     })
     .catch(e=>{return e})
   }

   updateResource(DATAPACKET,MeetingUID,UID){
    var folder = DATAPACKET.split(':')[0]
    var dBRef = DATAPACKET.split(':')[1].split('-')[0]
    this.db.database.ref('sessionEnvironments/' + `${MeetingUID}` + '/' + `${UID}` + '/canvasConfig/' + `${folder}`)
    .update({[dBRef]:`${DATAPACKET}`})
    .then(()=>{
      return '200'
    })
    .catch(e=>{return e})
   }

   removeResource(DATAPACKET,MeetingUID,UID){
    var folder = DATAPACKET.split(':')[0]
    var dBRef = DATAPACKET.split(':')[1].split('-')[0]
    this.db.database.ref('sessionEnvironments/' + `${MeetingUID}` + '/' + `${UID}` + '/canvasConfig/' + `${folder}` + '/' + `${dBRef}`)
    .remove()
    .then(()=>{
      return '200'
    })
    .catch(e=>{return e})
   }

   async initializeBoard(MeetingUID){
     var board = {}
     await MeetingUID.split('-').forEach(UID=>{
      this.db.database.ref('sessionEnvironments/' + `${MeetingUID}` + '/' + `${UID}` + '/canvasConfig/').once('value')
      .then(val=>{
        var curSet = {}
        val.forEach(childVal=>{
          curSet[childVal.key] = childVal.val()
        })
        board[val.key] = curSet 
      }).then(()=>{
        console.log(board)
        return board
      })
     })
     .catch(e=>{return e})
   }

}
