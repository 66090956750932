import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/user'; // optional
import { auth, database } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database'
import {Time} from '@angular/common'
import {time} from '../models/times'

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DBService {
  availability: time;
  currentUID: string = 'waiting for a teacher time request';

  constructor(private database: AngularFireDatabase) { }

  writeUserData(userId, name, email, imageUrl) {
    if (!this.database.database.ref('USERS/' + userId)){
      this.database.database.ref('USERS/' + userId).push({
        username: name,
        email: email,
        profile_picture: imageUrl
      });
    }
  }


}
