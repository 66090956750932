<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
<div>
  <div class="image">
    <h1 style = "width: 850px;font-size: 22px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin:0 auto;">
        THANK YOU FOR BOOKING!
        </h1>
        <div class = "jumbotron jumbotron-fluid" style = "border-bottom-left-radius: 25px; border-bottom-right-radius: 25px;max-width: 850px; height: 490px;overflow-y: auto;text-align: center;margin:0 auto;left:10%;right: 10%; margin-bottom: 30px;">
            <p style="text-align: center;">
                Thanks
            
            </p>
        </div>      
    </div>
</div>
