import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-create-comment',
  templateUrl: './create-comment.component.html',
  styleUrls: ['./create-comment.component.scss']
})
export class CreateCommentComponent implements OnInit {
  @Output() addComment: EventEmitter<any> = new EventEmitter();
  comment: string;
  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(){
    const comment = {
      comment: this.comment,
      // this is where user id and date go in 
    }
    this.addComment.emit(comment);
  }
}
