import { Component, OnInit,Output,EventEmitter, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import {Router} from '@angular/router'
import {ActivatedRoute} from '@angular/router'
import {AngularFireDatabase, snapshotChanges} from '@angular/fire/database'
import {receipt} from '../../models/receipt'
import { service } from 'firebase-functions/lib/providers/auth';
import { TeacherProfile } from 'src/app/models/TeacherProfile';
import {req} from '../../models/req'
import { AngularFireStorage } from '@angular/fire/storage';


import Calendar from 'tui-calendar'; /* ES6 */
import moment from 'moment-timezone';
 
        
@Component({  
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
}) 
export class UserProfileComponent implements OnInit {
  futureAppointments:any[] = []
  pastAppointments:receipt[] = []
  UID 
  teacher = false
  @Output() selectedTimeFrame = new EventEmitter<any[]>();
  @Output() lessonEmitter = new EventEmitter<any[]>();
  @Output() currTeachers = new EventEmitter<any[]>();
  @Output() lessonsEmitter = new EventEmitter<any[]>();
  @Output() validDays = new EventEmitter<any[]>();
  @Output() studentsEmitter = new EventEmitter<any[]>();
  @Output() studentsEmitter2 = new EventEmitter<any[]>();


  @Output() hourlyRate = new EventEmitter<any>();
  
  

  scheduleList: {};
  weekOfAvailabilities: {};

  error =['* is required']
  times = []
  requests:req[] = []
  teachers: TeacherProfile[] = [];
  title = ''
  description = ''
  name;
  cred;
  rate; 
  longIntro;
  shortIntro;
  future;  
  info;

  @Input() AUXIN: any[];
  

  lessons = {}

  initializeListner = false

  @Output() personalFilesEmitter = new EventEmitter<any[]>();
  personalFiles = []
  isTeacher = false
  calendar: Calendar;
  students = []
  students2 = []

  storage = []
  @Output() storageEmitter = new EventEmitter<any[]>();

  topics = 'Topics*'
  subTopics = 'Subtopics*'
  topicsList = ['Practice Problems','Board Templates','Fun Pics','General']
  subtopicsList = ['Math','English','Science','Social Studies','World Langauge','Creative Arts','Fun','General']
  profileLink = ''

  openView = false

  path:string = "";
  currentEvent
  uploaded = false  

  @Output() appointments = new EventEmitter<any[]>();
  @Output() pasts = new EventEmitter<any[]>();
  succMessages: string[];
  count = 0

  constructor(public auth: AuthService,private router:ActivatedRoute, private db: AngularFireDatabase,private afm: AngularFireStorage) { }

        
  

  async ngOnInit(){ 
    // var date = new Date(1645160939700)
    // console.log(date,date.toString(),date.toTimeString(),date.toDateString(),date.getTime())
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0
    // hold22.setDate(hold22.getDate() - 1);
    document.getElementById('rootHead').setAttribute('style','display:block')
    document.getElementById('rootFoot').setAttribute('style','display:block')
    this.UID = this.router.snapshot.params.UID
    this.getProPic()
    //new Date(year, month, date, hours, minutes, seconds, ms)
    var hold = false 
    console.log('HERE: ' + + new Date('2022-10-03T20:12:52Z'))
    this.calendar = new Calendar('#calendar2', {
      defaultView: 'month',
      // taskView: true,    // Can be also ['milestone', 'task']
      // scheduleView: true,  // Can be also ['allday', 'time']
      taskView: [],
      scheduleView: ['time'],
      useCreationPopup: false,
      useDetailPopup: false,
      // bgColor: 'red',
      // borderColor: 'calColor',
      // color: 'calColor',
      theme:{
        'common.holiday.color': 'rgba(30, 86, 99, 1)',
        'month.holidayExceptThisMonth.color': 'rgba(30, 86, 99, 1)',
        // 'week.currentTimeLinePast.border': 'none',
        // 'week.currentTimeLineBullet.backgroundColor': 'transparent',
        // 'week.currentTimeLineToday.border': 'none',
        // 'week.currentTimeLineFuture.border': 'none',
      },
      
      timezone: {
        zones: [
          {  
            timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }  
        ],
        offsetCalculator: function(timezoneName, timestamp){
          // matches 'getTimezoneOffset()' of Date API
          // e.g. +09:00 => -540, -04:00 => 240
  
          // console.log(moment.tz.zone(timezoneName).utcOffset(timestamp))
          return moment.tz.zone(timezoneName).utcOffset(timestamp);
        },
      },   
      template: {
          time: function(schedule) {
            // this conrols what goes in the schdule 
              return ' <i class="fa fa-refresh personal-info" style = " color: var(--chathams-blue);font-family: var(--font-family-inter);font-size: var(--font-size-m); font-style: normal;font-weight: 600;position: absolute;top: 43%;">Booked</i>';
          },
          timegridCurrentTime: function(timezone: any) {
            return null;
          },
          // weekDayname: function(model){
          //   var days = ['Sunday\'s','Monday\'s','Tuesday\'s','Wednesday\'s','Thursday\'s','Friday\'s','Saturday\'s'];
          //   return '<span class=\"tui-full-calendar-dayname-name\"style =\"font-size:15px;padding-left: 10px;\">' + days[model.day] + '</span>';
          // },
      },
      month: {
          daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          startDayOfWeek: 0,
          // narrowWeekend: true
      },
      week: {
          daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          startDayOfWeek: 0,
          narrowWeekend: false,
          hourStart:5,
      }
    });
    this.calendar.on({
      'clickSchedule': (e:any) => {
        console.log('beforeCreateSchedule', e);
       
        var startDate = moment(e.schedule.start._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        var endDate = moment(e.schedule.end._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)

        var openings = []

        if(startDate.date() === endDate.date() && startDate.month() === endDate.month() && startDate.year() === endDate.year()){
          var key = + startDate
          console.log(this.scheduleList,this.scheduleList[key])
          openings.push(this.scheduleList[key])
          // openings.push(hold)          
        }else{
          if(startDate.isBefore(endDate)){
            while(startDate.isSameOrBefore(endDate)){
              openings.push(this.scheduleList[+ startDate])
              startDate.add(24,'hours')
            }
          }else{
            console.log('error avoided: infinite loop')
          }
        }
        console.log(openings)
        if(openings.length > 0){
          document.getElementById('timeRes').setAttribute('style','position: absolute;z-index:102;')

          this.selectedTimeFrame.emit(openings)
          document.getElementById('')
          console.log('Nah g')
        }

        



      },
    
    'beforeCreateSchedule': (e:any) =>{
        console.log('beforeCreateSchedule', e);
        e.guide.clearGuideElement()
       
        var startDate = moment(e.start._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        var endDate = moment(e.end._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)

        // this.lastStartDate = moment(e.start._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        // this.lastEndDate = moment(e.end._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)

        var openings = []

        if(startDate.date() === endDate.date() && startDate.month() === endDate.month() && startDate.year() === endDate.year()){
          var key = startDate.toISOString(true).split('T')[0]
          console.log(key,this.weekOfAvailabilities,this.weekOfAvailabilities[key])
          if(this.weekOfAvailabilities[key] !== undefined && this.weekOfAvailabilities[key] !== null){
            if(this.weekOfAvailabilities[key].length > 0){
              var hold = {}
              hold[key] = this.weekOfAvailabilities[key]
              openings.push(hold)
            }
          }
          // openings.push(hold)          
        }else{
          if(startDate.isBefore(endDate)){
            while(startDate.isSameOrBefore(endDate)){
              var dateTracker = startDate.toISOString(true).split('T')[0]
              if(dateTracker in this.weekOfAvailabilities){
                var hold = {}
                hold[dateTracker] = this.weekOfAvailabilities[dateTracker]
                openings.push(hold)
                
              }   
              startDate.add(24,'hours')
            }
          }else{
            console.log('error avoided: infinite loop')
          }
        }
        console.log(openings)
        if(openings.length > 0){
          document.getElementById('timeRes').setAttribute('style','position: absolute;z-index:102;')

          this.selectedTimeFrame.emit(openings)
          console.log('Nah g')
        }
        // open a creation popup
    },

    })
    var hold2
    var hldList = {}
    var upcomingAppts =[]


    await this.db.database.ref('providers/userInfo/' + this.UID).once('value').then(function(snap){
      if(snap.exists()){
        hold = true 
      }
    })
    this.teacher = hold
    var request:req[] = []
    if(this.teacher){
      document.getElementById('studentsTitle').setAttribute('style','width:width;')
      document.getElementById('students').setAttribute('style','background: var(--concrete);height: 342px;width: 302px;border-radius: 10px;margin-top: 9px;overflow: scroll;')
      document.getElementById('teacherLessons1').setAttribute('style','z-index:1;cursor:pointer')
      document.getElementById('teacherLessons2').setAttribute('style','z-index:1;cursor:pointer')
      document.getElementById('teacherLessons3').setAttribute('style','z-index:1;')
      await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/timeZoneOffset').once('value').then(snap=>{
        hold2 = String(snap.val())
        console.log(hold)
        var students = []
        var IANAVal = ''
        if(hold2 === '7'){
          IANAVal = 'America/Los_Angeles'
        }else if(hold2 === '6'){
          IANAVal = 'America/Boise'
        }if(hold2 === '5'){
          IANAVal = 'America/Chicago'
        }if(hold2 === '4'){
          IANAVal = 'America/New_York'
        }
        this.db.database.ref('providers/userInfo/' + this.UID + '/calendar').once('value').then(function(snap){
          if(snap.exists()){
            snap.forEach(snapp =>{
              console.log(snapp.key,snapp.val())
              hldList[snapp.key] = snapp.val()
              for(const key in snapp.val()){
                if(moment(Number(snapp.key)).utc().add(Number(snapp.val()[key]['duration']),'minute').isAfter(moment.utc())){
                  upcomingAppts.push(snapp.key)
                }
              }
            }) 
          }
         }).then(()=>{
           console.log(hldList)
           console.log(upcomingAppts)
           this.futureAppointments = upcomingAppts
           if(this.futureAppointments.length > 0){
            document.getElementById('noAppointmentsYet').setAttribute('style','display:none')
          }
           this.appointments.emit(this.futureAppointments)
           this.scheduleList = hldList
           this.populateCalendar3(this.scheduleList,IANAVal)
           
           students.unshift({'SUID':'SUID','name':'name','backImg':'backImg','proColor':'proColor','proPic':'proPic','PUID':'holdUID'})

           this.students2 = students
           this.studentsEmitter2.emit(this.students2)
         })
      })
      this.profileLink = '/update-teacher/' + `${this.UID}`
      this.displayTeacher(this.UID)
      // await this.db.database.ref('providers/userInfo/' + this.UID +'/requests').once('value').then(function(snap){
      //   snap.forEach(function(snapp){
      //     request.push(new req(snapp.child('times').val(),snapp.child('services').val(),snapp.child('grade').val(),snapp.child('TeacherEmail').val(),snapp.child('ParentEmail').val(),snapp.child('TeacherUID').val(),snapp.child('ParentUID').val(),snapp.child('agreed').child('val').val(),snapp.child('UID').child('val').val(),snapp.child('teacherRate').val(),snapp.child('teacherOffset').val(),snapp.child('ttz').val(),snapp.child('numSessions').val(),snapp.child('rate').val(),snapp.child('total').child('val').val()))
      //   })
      // })    
      this.requests = request
      // if(this.requests.length === 0){
      //   document.getElementById('noRequestsYet').setAttribute('style','margin-left:21px;margin-top:-63px;')
      //   document.getElementById('noRequestsYet2').setAttribute('style','margin-left:264px;margin-top:38px;')
      // }else{
      //   document.getElementById('noRequestsYet').setAttribute('style','display:none')
      //   document.getElementById('noRequestsYet2').setAttribute('style','display:none')
      // }   
      // this.validDays.emit(request)    
           
    }else{   
      document.getElementById('subTitle').setAttribute('style','width:width;')
      document.getElementById('subscriptions').setAttribute('style','background: var(--concrete);height: 342px;width: 302px;border-radius: 10px;margin-top: 9px;overflow: scroll;')
      document.getElementById('studentProfiles1').setAttribute('style','z-index:1;cursor:pointer')
      document.getElementById('studentProfiles2').setAttribute('style','z-index:1;cursor:pointer')
      document.getElementById('studentProfiles3').setAttribute('style','z-index:1;')
      await this.db.database.ref('users/' + this.UID + '/schedule/timeZoneOffset').once('value').then(snap=>{
        hold2 = String(snap.val())
        console.log(hold)
        var IANAVal = ''
        if(hold2 === '7'){
          IANAVal = 'America/Los_Angeles'
        }else if(hold2 === '6'){
          IANAVal = 'America/Boise'
        }if(hold2 === '5'){
          IANAVal = 'America/Chicago'
        }if(hold2 === '4'){
          IANAVal = 'America/New_York'
        }
        this.db.database.ref('users/' + this.UID + '/calendar').once('value').then(function(snap){
          if(snap.exists()){
            snap.forEach(snappp=>{
              snappp.forEach(snapp =>{
                console.log(snapp.key,snapp.val())
                hldList[snapp.key] = snapp.val()
                for(const key in snapp.val()){
                  if(moment(Number(snapp.key)).utc().add(Number(snapp.val()[key]['duration']),'minute').isAfter(moment.utc())){
                    upcomingAppts.push(snapp.key)
                  }
                }
              }) 
            })

          }
         }).then(()=>{
           console.log(hldList)
           console.log(upcomingAppts)
           this.futureAppointments = upcomingAppts

           if(this.futureAppointments.length > 0){
            document.getElementById('noAppointmentsYet').setAttribute('style','display:none')
          }
           this.appointments.emit(this.futureAppointments)
           this.scheduleList = hldList
           this.populateCalendar3(this.scheduleList,IANAVal)
           
          //  students.unshift({'SUID':'SUID','name':'name','backImg':'backImg','proColor':'proColor','proPic':'proPic','PUID':'holdUID'})

          //  this.students2 = students
          //  this.studentsEmitter2.emit(this.students2)
         })
        // this.db.database.ref('users/' + this.UID + '/schedule/booked/').once('value').then(function(snap){
        //   if(snap.exists()){
        //     snap.forEach(snapp =>{
        //       console.log(snapp.key,snapp.val())
        //       hldList[snapp.key] = snapp.val()
        //       if(moment.unix(Number(snapp.key)).utc().isAfter(moment.utc())){
        //         upcomingAppts.push(snapp.key)
        //       }
        //     })
        //   }
        //  }).then(()=>{
        //    console.log(hldList)
        //    console.log(upcomingAppts)
        //    this.scheduleList = hldList
        //    this.populateCalendar(this.scheduleList,IANAVal)
        //  })
      })
      this.profileLink = '/ParentZone/' + `${this.UID}`
      var students = []
      var holdUID = this.UID
      await this.db.database.ref('users/' + this.UID +'/requests').once('value').then(function(snap){
        snap.forEach(function(snapp){
          request.push(new req(snapp.child('times').val(),snapp.child('services').val(),snapp.child('grade').val(),snapp.child('TeacherEmail').val(),snapp.child('ParentEmail').val(),snapp.child('TeacherUID').val(),snapp.child('ParentUID').val(),snapp.child('agreed').child('val').val(),snapp.child('UID').child('val').val(),snapp.child('teacherRate').val(),snapp.child('teacherOffset').val(),snapp.child('ttz').val(),snapp.child('numSessions').val(),snapp.child('rate').val(),snapp.child('total').child('val').val()))
        })
      }).then(()=>{
        this.db.database.ref('users/' + this.UID +'/students').once('value').then(function(snap){
          snap.forEach(student =>{
            var SUID = student.key
            var name = student.child('profile').child('name').val()
            var backImg = student.child('profile').child('backImg').val()
            var proColor = student.child('profile').child('proColor').val()
            var proPic = student.child('profile').child('proPic').val()
            students.unshift({'SUID':SUID,'name':name,'backImg':backImg,'proColor':proColor,'proPic':proPic,'PUID':holdUID})
          })
        }).then(()=>{
          this.students = students
          console.log(this.students)
          if(this.students.length === 0){
            document.getElementById('noDownsYet2').setAttribute('style','width: 275px;margin-top: 43px;margin-bottom: 15px')
            document.getElementById('noDownsYet2-T').setAttribute('style','text-align: center;')
          }
          this.studentsEmitter.emit(this.students)
        })
      })    
      this.requests = request
      // if(this.requests.length === 0){
      //   document.getElementById('noRequestsYet').setAttribute('style','margin-left:21px;margin-top:-63px;')
      //   document.getElementById('noRequestsYet2').setAttribute('style','margin-left:264px;margin-top:38px;')
      // }else{
      //   document.getElementById('noRequestsYet').setAttribute('style','display:none')
      //   document.getElementById('noRequestsYet2').setAttribute('style','display:none')
      // }
      this.validDays.emit(request)
    }
    if(this.teacher){
      this.getInfo()    
      this.getStars()
    }else{
      await this.db.database.ref('users/' + this.UID + '/userInfo/name/val').once('value').then(function(snap){
        hold = snap.val()
      }).then(()=>{
        this.name = hold
      })    
    }

    var future = []   
    if(this.teacher){
      this.listenToTeacher()
      this.getLessonFiles('providers/userInfo/')
      // await this.db.database.ref('providers/userInfo/' + this.UID + '/orders').orderByKey().once('value').then(function(snap){
      //   snap.forEach(function(snapp){ 
      //     var date = snapp.child('date').val()
      //     var parentEmail = snapp.child('parentEmail').val()
      //     var parentUID = snapp.child('parentUID').val()
      //     var studentUID = snapp.child('SUID').val()
      //     var services = snapp.child('services').val()
      //     var teacherEmail = snapp.child('teacherEmail').val()
      //     var teacherName = snapp.child('teacherName').val()
      //     var teacherUID = snapp.child('teacherUID').val()
      //     var orderUID = snapp.child('orderUID').val()
      //     var teacherDate = snapp.child('teacherDate').val()
      //     var activeResc = snapp.child('reschedule').child('active').child('val').val()
      //     //0000 UTC
      //     var timeOfPur = snapp.child('timeOfPur').val()
      //     var ttz = snapp.child('ttz').val()
      //     var ptz = snapp.child('ptz').val()
      //     var total = snapp.child('total').val()
      //     var data = [date,parentEmail,parentUID,services,teacherEmail,teacherName,teacherUID,orderUID,teacherDate,activeResc,timeOfPur,ttz,ptz,total,studentUID]
      //     future.push(data)
      //   })
      // }).finally(()=>{
      //   future.forEach(rec =>{
      //     this.futureAppointments.unshift(rec)
    
      //   })
      //   if(this.futureAppointments.length === 0){
      //     document.getElementById('noAppointmentsYet').setAttribute('style','text-align: center;margin-top:-52px;margin-left: 19px;')
      //   }else{
      //     document.getElementById('noAppointmentsYet').setAttribute('style','display:none')
      //   }
      //   this.appointments.emit(this.futureAppointments)
      // })
    }else{
      this.listenToStudent()
      this.getUserFiles('users/')
      // await this.db.database.ref('users/' + this.UID + '/orders').once('value').then(function(snap){
      //   snap.forEach(function(snapp){
      //     var date = snapp.child('date').val()
      //     var parentEmail = snapp.child('parentEmail').val()
      //     var parentUID = snapp.child('parentUID').val()
      //     var studentUID = snapp.child('SUID').val()
      //     var services = snapp.child('services').val()
      //     var teacherEmail = snapp.child('teacherEmail').val()
      //     var teacherName = snapp.child('teacherName').val()
      //     var teacherUID = snapp.child('teacherUID').val()
      //     var orderUID = snapp.child('orderUID').val()
      //     var teacherDate = snapp.child('teacherDate').val()
      //     var activeResc = snapp.child('reschedule').child('active').child('val').val()
      //     //0000 UTC
      //     var timeOfPur = snapp.child('timeOfPur').val()
      //     var ttz = snapp.child('ttz').val()
      //     var ptz = snapp.child('ptz').val()
      //     var total = snapp.child('total').val()
      //     var data = [date,parentEmail,parentUID,services,teacherEmail,teacherName,teacherUID,orderUID,teacherDate,activeResc,timeOfPur,ttz,ptz,total,studentUID]
      //     future.push(data)
      //   })
      // }).finally(()=>{
      //   future.forEach(rec =>{
      //     this.futureAppointments.unshift(rec)
      //   })
      //   if(this.futureAppointments.length === 0){
      //     document.getElementById('noAppointmentsYet').setAttribute('style','text-align: center;margin-top:-52px;margin-left: 19px;')
      //   }else{
      //     document.getElementById('noAppointmentsYet').setAttribute('style','display:none')
      //   }
      //   this.appointments.emit(this.futureAppointments)
      // }
      // )
    }
  
  }

  openViewToggle(){
    this.openView = !this.openView
    if(this.openView){
      document.getElementById('listOfViews').setAttribute('style',"display:block;top: 28px;")
    }else{
      document.getElementById('listOfViews').setAttribute('style',"display:none;top: 28px;")
    }
  }

  async listenToStudent(){
    await this.db.database.ref('users/'+ this.UID + '/newStorage/').on('value',val=>{
      if (val.val() == null){
        return
      }
      var valStr = String(val.val().split('/')[0])
      if(this.initializeListner === false){
        this.initializeListner = true
      }else{
        if(valStr.includes('-')){
          this.personalFiles.unshift(['PERSONAL',val.val().split('/')[0],false,this.UID,val.val().split('/')[0],val.val().split('/')[1]])
        }else{
          this.personalFiles.unshift(['PERSONAL',val.val().split('/')[0],false,this.UID,this.UID + '-' + val.val().split('/')[0],val.val().split('/')[1]])
        }
        this.personalFilesEmitter.emit(this.personalFiles)
        if(this.personalFiles.length === 0){
          document.getElementById('noDownsYet').setAttribute('style',"height: 240px;margin-left: 21px;margin-top: -8 px;")
        }else{
          document.getElementById('noDownsYet').setAttribute('style',"display:none")
        }
        
      }
    })
  }  

  populateCalendar3(appointments,IANATimeZoneValue){
    console.log(appointments)
    for(const date in appointments){
      for(const appt in appointments[date]){
        this.calendar.createSchedules([
          {
              id: `${moment(Number(date)).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format()}` + '~' + `${moment(Number(date)).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).add(appointments[date][appt]['duration'],'minutes').format()}`,
              calendarId: '1',  
              title: 'Opening(s) Available',
              body: 'body',
              category: 'time',  
              dueDateClass: '',
              start: moment(Number(date)).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(),
              end: moment(Number(date)).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).add(appointments[date][appt]['duration'],'minutes').format(),
              bgColor: '#B1E7F3',
              borderColor: 'rgba(79, 79, 79, 1)',
              color: 'black',
              isReadOnly: true    // schedule is read-only
          },
        ]);
      }
    }
    console.log(appointments,IANATimeZoneValue,Intl.DateTimeFormat().resolvedOptions().timeZone)

  }

  populateCalendar2(appointments,IANATimeZoneValue){
    console.log(appointments,IANATimeZoneValue,Intl.DateTimeFormat().resolvedOptions().timeZone)
    for (var appointment in appointments) {
      console.log(moment(Number(appointment)).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format())
      console.log(appointments[appointment])
      console.log(appointments[appointment].end)
      this.calendar.createSchedules([
        {
            id: `${moment(Number(appointment)).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format()}` + '~' + `${moment(Number(appointments[appointment]['end'])).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format()}`,
            calendarId: '1',  
            title: 'Opening(s) Available',
            body: 'body',
            category: 'time',  
            dueDateClass: '',
            start: moment(Number(appointment)).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(),
            end: moment(Number(appointments[appointment].end)).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(),
            bgColor: '#B1E7F3',
            borderColor: 'rgba(79, 79, 79, 1)',
            color: 'black',
            isReadOnly: true    // schedule is read-only
        },
      ]);
    }
  }

  populateCalendar(weeklyAvailability,IANATimeZoneValue){
    //create a moment var moment(+ new Date(TSID)).tz('America/New_York')  mon=07:00:00-12:00:00|13:30:00-15:00:00~
    //create the moments that represent the next 7 days (We need to see each day once)
    var weekOfAvailabilities = {}
    console.log(weeklyAvailability,IANATimeZoneValue)
    var TimeStamp = + new Date()
    var newMoment = moment(TimeStamp).tz(IANATimeZoneValue)
    var count = 0
    newMoment.subtract(60,'days')
    while(count < 260){
      count += 1
      console.log(newMoment.day())
      if(weeklyAvailability[`${newMoment.day()}`] !== ''){
        weeklyAvailability[newMoment.day()].split('|').forEach(opening=>{
          console.log(opening)
          console.log(newMoment,newMoment.format())


          var startHour = opening.split('-')[0].split(':')[0]
          var startMinutes = opening.split('-')[0].split(':')[1]

          var endHour = opening.split('-')[1].split(':')[0]
          var endMinutes = opening.split('-')[1].split(':')[1]

          console.log(newMoment)
          newMoment.hour(startHour)
          newMoment.minute(startMinutes)
          newMoment.second(0)

          var targetMoment = moment(newMoment)
          targetMoment.hour(endHour)
          targetMoment.minute(endMinutes)
          targetMoment.second(0)
          newMoment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          targetMoment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          console.log(newMoment.format(),targetMoment.format())
          // THIS IS is where the issue happens for overage times. We need to be able to start one day and end on another 
          //  need to mainatin this.weekOfAvailabilities 

          var timesHold = {'start':newMoment.format()}
          timesHold['end'] = targetMoment.format()

          if(`${newMoment.format().split('T')[0]}` in weekOfAvailabilities){
            weekOfAvailabilities[newMoment.format().split('T')[0]].push(timesHold)
          }else{
            weekOfAvailabilities[newMoment.format().split('T')[0]] = [timesHold]
          }

          newMoment.tz(IANATimeZoneValue)
        }) 
      }

      newMoment.add(24,'hours')
    }
    console.log(weekOfAvailabilities)
    this.weekOfAvailabilities = weekOfAvailabilities
    for (const key in weekOfAvailabilities) {
      weekOfAvailabilities[key].forEach(timeSlot=>{
        var startTime = timeSlot['start']
        var endTime = timeSlot['end']
        console.log(`${timeSlot['start']}` + '~' + `${timeSlot['end']}`)
        this.calendar.createSchedules([
          {
              id: `${timeSlot['start']}` + '~' + `${timeSlot['end']}`,
              calendarId: '1',  
              title: 'Opening(s) Available',
              body: 'body',
              category: 'time',  
              dueDateClass: '',
              start: new Date(startTime),
              end: new Date(endTime),
              bgColor: '#B1E7F3',
              borderColor: 'rgba(79, 79, 79, 1)',
              color: 'black',
              isReadOnly: true    // schedule is read-only
          },
        ]);
      })
    }
  }

  editFolder(folder){
    console.log(folder)
    this.openUploader()
    this.lessonEmitter.emit(folder)
  }
  
  async listenToTeacher(){
    await this.db.database.ref('providers/userInfo/'+ this.UID + '/newStorage/').on('value',val=>{
      var valStr = String(val.val().split('/')[0])
      if(this.initializeListner === false){
        this.initializeListner = true
      }else{
        if(valStr.includes('-')){
          this.personalFiles.unshift(['PERSONAL',val.val().split('/')[0],this.teacher,this.UID,val.val().split('/')[0],val.val().split('/')[1]])
        }else{
          this.personalFiles.unshift(['PERSONAL',val.val().split('/')[0],this.teacher,this.UID,this.UID + '-' + val.val().split('/')[0],val.val().split('/')[1]])
        }
        this.personalFilesEmitter.emit(this.personalFiles)
        if(this.personalFiles.length === 0){
          document.getElementById('noDownsYet').setAttribute('style',"height: 240px;margin-left: 21px;margin-top: -8 px;")
        }else{
          document.getElementById('noDownsYet').setAttribute('style',"display:none")
        }
        
      }
    })
  }

  closeMe2(){
    document.getElementById('timeRes').setAttribute('style','display:none')
  } 
  removeTime($event){

  } 
  recreateCalendar(){

  }
  addTime($event){

  }

  //update the order of lessons, moving ours to the 0 index --> then add in the new files
  //update the TS and Update Files nodes 
  //  note: store original values for title and cats, maintian list changes to files so we can distinguish 
  // updates = [[Meta Values][Update(s)]]
  //    Meta = 0:LessonUID,1:newTS
  //    Updates = 0:TYPE,1:updateValue
  //    Updates TYPE's :TITLE,CATS,FILEADD,FILEDELETE
  updateLesson(updates){
    this.closeUploader()
    console.log(updates)
    if(updates[0][0] === 'NEW'){
      if(Object.keys(this.lessons).length === 0){
        document.getElementById('noDownsYet').setAttribute('style','display:none')
      }
      console.log(this.lessons)
      console.log(updates[0])
      var update = updates[0][1]
      this.lessons[update.UID] = {'name':update.name,'TS':new Date(Number(update.UID)).toDateString(),'cats':update.cats,'files':update.files}
    }else{
      console.log(updates)
      var lessonUID = updates[0][1]
      var newTS = updates[0][0]
      this.lessons[lessonUID].TS = newTS
      updates[1].forEach(update=>{
        var TYPE = update[0]
        var value = update[1]
        this.lessons[lessonUID].TS = new Date(Number(newTS)).toDateString()
        if(TYPE === 'TITLE'){
          this.lessons[lessonUID].name = value
        }else if(TYPE === 'CATS'){
          this.lessons[lessonUID].cats = value
        }else if(TYPE === 'ADDFILE'){
          console.log(this.lessons,this.lessons[lessonUID],this.lessons[lessonUID].files,value)
          var hold = [value.UID,value.name,value.TS]
          console.log(hold)
          this.lessons[lessonUID].files.unshift(hold)
          console.log(this.lessons[lessonUID].files)
        }else if(TYPE === 'DELETEFILE'){
          this.lessons[lessonUID].files = this.lessons[lessonUID].files.filter(files => files[0] !== value.UID)
        }else if(TYPE === 'RENAMEFILE'){
          // TO-DO
          // var holda = this.lessons[lessonUID].find(files => files[0] === value.UID)
          // console.log(holda)
          // holda
        }
      })
    }
    var holder = []
    for (const property in this.lessons) {
      holder.unshift([property,this.lessons[property].name,this.lessons[property].TS,this.lessons[property].cats,this.lessons[property].files])
    }
    console.log(holder)
    this.lessonsEmitter.emit(holder)
  }

  async getLessonFiles(local){
    await this.db.database.ref(local + '/' + this.UID + '/lessons').once('value',lessons=>{
      var lessonsHold = []
      lessons.forEach(lesson=>{
        var lessonUID = lesson.key
        var name = lesson.child('name').val()
        var cats = lesson.child('cats').val()
        var files = []
        var date = new Date(Number(lesson.child('TS').val())).toDateString()
        lesson.child('files').forEach(file=>{
          files.unshift([file.key,file.child('name').val(),file.child('TS').val()])
        })
        var hold = []
        hold.push(name,date,cats,files)
        this.lessons[lessonUID] = {'name':name,'TS':date,'cats':cats,'files':files}
        lessonsHold.unshift([lessonUID,name,date,cats,files])
      })

      if(lessonsHold.length === 0){
        document.getElementById('noDownsYet').setAttribute('style',"height: 240px;margin-left: 21px;margin-top: -8 px;")
      }else{
        document.getElementById('noDownsYet').setAttribute('style',"display:none")
      }    
      this.lessonsEmitter.emit(lessonsHold)
    })
  }

  async getUserFiles(local){
    await this.db.database.ref(local + '/' + this.UID + '/storage/').orderByChild('value').once('value',val=>{
      val.forEach(STAMP=>{
        if(STAMP.key.includes('-')){
          this.personalFiles.unshift(['PERSONAL',STAMP.key,this.teacher,this.UID,STAMP.key,STAMP.child('name').val()])
        }else{
          this.personalFiles.unshift(['PERSONAL',STAMP.key,this.teacher,this.UID,this.UID + '-' + STAMP.key,STAMP.child('name').val()])
        }
      })

      if(this.personalFiles.length === 0){
        document.getElementById('noDownsYet').setAttribute('style',"height: 240px;margin-left: 21px;margin-top: -8 px;")
      }else{
        document.getElementById('noDownsYet').setAttribute('style',"display:none")
      }    
      this.personalFilesEmitter.emit(this.personalFiles)
    })
  }

  newTeacher(val:any){

  }

  //needs to deal wir abilit to republish without restarting the download / repost counter 
  async publish(){
    // var hold = ['SHAREDFILE',this.TS,true,this.AUID]
    // add to CommunityResources and Personal SharedFile 
    if(this.subTopics === 'Subtopics*' || this.topics === 'Topics*' || this.title == ''){
      this.error = ['Please complete required fields']
    }else{
      var $event = this.currentEvent
      var TS = + new Date()
      var value = $event[1]
      var AUID = $event[3]
      var holda = value 
      var RUID = value
      this.db.database.ref('providers/userInfo/' + this.UID + '/sharedStorage').update({[holda]:TS}).then(()=>{
        //we need to put it in the right categories bins 
        if(String(value).indexOf('-') === -1){
          RUID = AUID + '-' + value
        }
        var key = RUID.split('-')[0]
        var keyValue = RUID.split('-')[1]
        this.db.database.ref('resourceDirectory/' + `${this.topics}` + '/' + `${this.subTopics}` + '/').update({[RUID]:TS}).then(()=>{
          this.db.database.ref('communityResources/' + `${key}` + '/' + `${keyValue}`).once('value',val=>{
            if(val.exists()){
             this.db.database.ref('communityResources/' + `${key}` + '/' + `${keyValue}`).update({'Title':this.title, 'Desc':this.description})
            }else{
              this.db.database.ref('communityResources/' + `${key}`).update({[keyValue]:{'TS':TS, 'Title':this.title, 'Desc':this.description,'DownRepo':'0'}})
            }
          }).then(()=>{
            this.error = ['Uploaded!']
            this.close()
          })
        })
      })   
    }
  }

  
  toggleUploadModall(){
    document.getElementById('inputGroupFile01').click()
  }

  uploadPersonal(){
    document.getElementById('inputGroupFile01').click()
  }

  openUploader(){
    document.getElementById('fileLoader').setAttribute('style','z-index: 1000;position: relative;width: 1440px;')
  }

  openUploader2(){
    document.getElementById('fileLoader').setAttribute('style','z-index: 1000;position: relative;width: 1440px;')
    this.lessonEmitter.emit(["", "", "", "", []])
  }
  closeUploader(){
    document.getElementById('fileLoader').setAttribute('style','display:none')
  }

  //style for resource directory 
  // 'communityResources/' + `${this.topics}` + '/' + `${this.subTopics}` + '/'
  deleteMe3($event){
    this.storage = this.storage.filter(val => String(val[1]) !== String($event))
    this.storageEmitter.emit(this.storage)
  }

  async getProPic(){
    var teacherUID = this.UID
    // Create a reference from a Google Cloud Storage URI
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/teacherPics/' + teacherUID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById('proPic').setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      document.getElementById('proPic').setAttribute('src','../../../assets/Giraf.svg')
      // document.getElementById(`${teacherHold.UID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }

  async getStars(){
    var hold;
    // await this.db.database.ref('providers/userInfo/' + this.UID + '/immutable/stars/val').once('value').then(function(snap){
    //   hold = Number(snap.val())
    //   var count = 1
    //   while(count <= hold){
    //     var inpa = "star" + String(count)
    //     document.getElementById(inpa).outerHTML = '<span class="fa fa-star checked" style = "color:orange;"></span>';
    //     count += 1
    //   }
    // })
  }

  deleteMe2(event){
    var holdOrders:any[] = []
    this.futureAppointments.forEach(val=>{     
      if(val[7] !== event){
        holdOrders.push(val)
      }
    })
    this.futureAppointments = holdOrders
    if(this.futureAppointments.length === 0){
      document.getElementById('noAppointmentsYet').setAttribute('style','text-align: center;margin-top:-52px;margin-left: 19px;')
    }else{
      document.getElementById('noAppointmentsYet').setAttribute('style','display:none')
    }
    this.appointments.emit(holdOrders)     

  }
  close(){
    var files = document.getElementById('myF')
    files.setAttribute('style', 'background-color: #D8D8D8;border: 5px solid rgb(230,230,230);display:none;')
    document.getElementById('title').textContent = ''
    document.getElementById('description').textContent = ''
    this.topics = 'Topics*'
    this.subTopics = 'Subtopics*'
  
  }

  setTopic(sesh){
    this.topics = sesh
  }

  setSubTopic(sesh){
    this.subTopics = sesh
  }

  shareStorage($event){
    this.currentEvent = $event
    var files = document.getElementById('myF')
    files.setAttribute('style', 'background-color: #D8D8D8;border: 5px solid rgb(230,230,230);display:block;')
  }

  upload($event){
    this.path = $event.target.files[0]
    var TS = + new Date()
    if(this.teacher){
      this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path).then(()=>{
        this.db.database.ref('providers/userInfo/' + this.UID + '/storage').update({[TS]:{'type':'PERSONAL','value':TS}}).then(()=>{
          this.db.database.ref('providers/userInfo/' + this.UID + '/').update({'newStorage':TS})
        }).then(()=>{
          this.succMessages = ["File Added"]
        })
        
      })
    }else{
      this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path).then(()=>{
        this.db.database.ref('users/' + this.UID + '/storage').update({[TS]:{'type':'PERSONAL','value':TS}}).then(()=>{
          this.db.database.ref('users/' + this.UID + '/').update({'newStorage':TS})
        }).then(()=>{
          this.succMessages = ["File Added"]
        })
      })
    }
    
  }

  async displayTeacher(uid:string){
    var newTeachers: TeacherProfile[] = []
    await this.db.database.ref('providers/userInfo/' + uid).once('value').then(function(snap){
      var cred = ""
      var stars = 5 
      var name = ""
      snap.child('immutable').forEach(function(child){
        if(child.key === "formalTitle"){
          cred = child.child('val').val()
        }else if (child.key === "stars"){
          stars = child.child('val').val() 
        }else if(child.key === "name"){
          name = child.child('val').val()
        }
      })

      var description = snap.child('profile').child('shortIntro').child('val').val()
      var rate = snap.child('pageInfo').child('rate').val()
      var teach = new TeacherProfile(uid,name,rate,stars,cred,description)
      newTeachers.push(teach)
    })
    if(newTeachers.length > 0 ){
      newTeachers.forEach(teacher=>{
        this.teachers.push(teacher)
      })
    }
    //this.doneLoading.emit(true)
  }

  deleteMe(event){
    var holdReq:req[] = []
    this.requests.forEach(val=>{
      if(val.UID !== event){
        holdReq.push(val)
      }
    })
    this.requests = holdReq
    if(this.requests.length === 0){
      document.getElementById('noRequestsYet').setAttribute('style','margin-left:21px;margin-top:-63px;')
      document.getElementById('noRequestsYet2').setAttribute('style','margin-left:264px;margin-top:38px;')
    }else{
      document.getElementById('noRequestsYet').setAttribute('style','display:none')
      document.getElementById('noRequestsYet2').setAttribute('style','display:none')
    }
    this.validDays.emit(holdReq)
  }

  async getInfo(){
    var hold;
    await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/rate/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.rate = Number(hold)

    await this.db.database.ref('providers/userInfo/' + this.UID + '/immutable/name/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.name = hold
    
    await this.db.database.ref('providers/userInfo/' + this.UID + '/immutable/formalTitle/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.cred = hold

    await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/longIntro/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.longIntro = hold

    await this.db.database.ref('providers/userInfo/' + this.UID + '/profile/shortIntro/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.shortIntro = hold

    await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/future/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.future = hold
  }

}
