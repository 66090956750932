<div class="container-center-horizontal">
  <div class="update-profile-student-parent-v1 screen">
    <div style="display: none;" id = 'createNewStudent1'></div>
    <app-create-student-account id = 'createNewStudent'(newStudentEmitter) = 'addNewStudent($event)'(closeMeEmitter) = 'closeMe()' style="display: none;"></app-create-student-account>
    <div class="flex-row-1">
      <div class="flex-col">

        <div class="frame-267-1" style = 'z-index:1;cursor: pointer;'routerLink="/profile/{{UID}}">
          <div class="arrow-left">
            <div class="overlap-group2-1">
              <img
                src="../../../assets/backToProfile.svg"
              />
            </div>
          </div>
          <div class="back-to-profile worksans-semi-bold-chathams-blue-14px">Back to profile</div>
        </div>
        <div class="overlap-group">
          <div class="group-319">
            <div class="overlap-group2">
              <h1 class="text-9 worksans-semi-bold-abbey-24px">Your Child's Introduction</h1>
              <div class="overlap-group4">
                <p class="text-8 body">
                  Create and share your studnets profile with our Verified Teachers.
                </p>
                <div  style = 'margin-top: 40px;margin-top: 40px;height: 180px;overflow: hidden; overflow-y: auto;'>
                  <img id = 'emptyChildren2' style = 'display: none;' src = '../../../assets/noStudentProfiles.svg'>
                  <div style="align-items: center;
                  height: 157px;
                  left: 46px;
                  mix-blend-mode: normal;
                  overflow-y: scroll;
                  padding: 0 5px;
                  position: absolute;
                  top: 56px;
                  width: 290px;">
                    <app-student-profile-holder *ngFor = "let req of studentsEmitter | async" [AUXIN] = "req" [newName] = 'newNameEmitter | async' (studentUID) = 'updateProfileEdit($event)'></app-student-profile-holder>
                  </div>   
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="frame-360">
            <div class="x2nd-student-profile" style="font-size: 20px;">2nd Student Profile</div>
            <div class="name-1 body">No Name</div>
          </div> -->
          <div class="frame-268"(click)="createStudent()" style="cursor: pointer;z-index:1">
            <div class="plus">
              <div class="overlap-group2-1">
                <img
                  class="vector-6"
                  src="../../../assets/verticalPlus.svg"
                />
                <img
                  class="vector-1"
                  src="../../../assets/horiztonalPlus.svg"
                />
              </div>
            </div>
            <div class="text-10 worksans-semi-bold-chathams-blue-14px" >Add One More Student</div>
          </div>

          <img
            class="line-7"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/line-7@2x.png"
          />
        </div>
        <div class="group-320">
          <div class="overlap-group3">
            <div class="your-information worksans-semi-bold-abbey-24px">Your Information</div>
            <p class="text-7 body">Share a little bit about yourself with your child's instructor.</p>
            <div class="x7181696 component component-wrapper ready">
              <div class="name-i71816967181-6jjQtm">Your Name</div>
              <div class="group-314-6jjQtm">
                <input class="rectangle-72-eWET2B text-7-1" maxLength = "30" minLength = "2" ngModel name="fn9" #fn9 ="ngModel" (change) = "parentName = fn9.value" value = '{{currPN | async}}'type="text" placeholder="Enter Name">
              </div>
            </div>
            <div class="x7181701 component component-wrapper ready">
              <div class="name-i71817017181-UQj6W3">Prefered Pronouns</div>
              <div class="group-314-UQj6W3">
                <input class="rectangle-72-jkehNv text-7-1" placeholder="Prefered Pronouns" maxLength = "30" minLength = "2" ngModel name="fn8" #fn8 ="ngModel" (change) = "parentPronouns = fn8.value" value = '{{currPRO | async}}' type="text" >
              </div>
            </div>
            <div class="frame-350">
              <div class="frame-359">
                <div class="personal-note">Personal Note</div>
                <div class="text-5 inter-normal-gunsmoke-16px">0/250</div>
              </div>
              <div class="group-314">
                <textarea class="overlap-group2-2 text-7-1" style = 'resize: none;'maxLength = "250" minLength = "2" ngModel name="fn7" #fn7 ="ngModel" (change) = "personalParentNote = fn7.value" value = '{{currPPN | async}}' type="text" placeholder="Personal Note" >
                </textarea>
              </div>
            </div>
            <div class="frame-75" style = 'z-index:1;cursor: pointer;margin-top:20px;'  (click) = parentSave()><div class="save-changes">Save Changes</div></div>
          </div>
        </div>
      </div>
      <div class="overlap-group1">
        <div class="group-321">
          <div class="x1st-student-profile worksans-semi-bold-abbey-24px">Student's Profile</div>
          <div class="flex-col-2-1" style="z-index: 1;cursor: pointer;" (click) = 'uploadProfilePicture()'>
            <!--  -->
            <img id = 'proPic1'class="ellipse-18" src="../../../assets/zebra.svg" />
            <div class="frame-268-1" style="display: none;">
              <img class="download" src="../../../assets/download.svg" />
              <div class="upload-new">Upload New</div>
            </div>
          </div>
          <div class="frame-462">
              <div style = 'z-index: 1;cursor: pointer;'(click)='changeBackgroundColor("FFFFFF-F3F3F3-2A8499")'class="ellipse-89" id = 'FFFFFF-F3F3F3-2A8499-1'></div>
              <div style = 'z-index: 1;cursor: pointer;'(click)='changeBackgroundColor("F2FEFF-C6DFE1-81CBD1")'class="ellipse-90" id = 'F2FEFF-C6DFE1-81CBD1-1'></div>
              <div style = 'z-index: 1;cursor: pointer;'(click)='changeBackgroundColor("F8F2FF-DAD4E1-C1A1E8")' class="ellipse-95" id = 'F8F2FF-DAD4E1-C1A1E8-1'></div>
              <div style = 'z-index: 1;cursor: pointer;'(click)='changeBackgroundColor("FEFFD1-DADBA5-ECEF73")' class="ellipse-91" id = 'FEFFD1-DADBA5-ECEF73-1'></div>
              <div style = 'z-index: 1;cursor: pointer;'(click)='changeBackgroundColor("EDFFD0-D2E2B8-B4F152")' class="ellipse-94" id = 'EDFFD0-D2E2B8-B4F152-1'></div>
              <div style = 'z-index: 1;cursor: pointer;'(click)='changeBackgroundColor("FFF1F8-DCBACB-F398C6")' class="ellipse-93" id = 'FFF1F8-DCBACB-F398C6-1'></div>
          </div>
          <div class="choose-your-alian inter-semi-bold-abbey-16px" style="position: absolute;margin-top: 80px;margin-left: 334px;">
            Choose An Alien Explorer
          </div>
          <div class="group-754">
            <div class="overlap-group-container-1">
              <div class="overlap-group-1" style="cursor: pointer;z-index: 1;" (click)='changeAlienColor("pink")'>
                <img class="alienNeck" src="../../../assets/pinkAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/pinkAlienHead.svg" />
                <img id = 'pinkAlienSelected' class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                />
              </div>
              <div class="overlap-group-1" style="cursor: pointer;z-index: 1;" (click)='changeAlienColor("yellow")'>
                <img class="alienNeck" src="../../../assets/yellowAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/yellowAlienHead.svg" />
                <img id = 'yellowAlienSelected'class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                />
              </div>
            </div>
            <div class="overlap-group-container-2">
              <div class="overlap-group-1" style="cursor: pointer;z-index: 1;" (click)='changeAlienColor("green")'>
                <img class="alienNeck" src="../../../assets/greenAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/greenAlienHead.svg" />
                <img id = 'greenAlienSelected'class= 'alienNotSelected' src="../../../assets/alienSelected.svg"/>
              </div>
              <div class="overlap-group-1" style="cursor: pointer;z-index: 1;" (click)='changeAlienColor("blue")'>
                <img class="alienNeck" src="../../../assets/blueAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/blueAlienHead.svg" />
                <img id = 'blueAlienSelected'class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                />
              </div>
            </div>
            <div class="overlap-group-container-3">
              <div class="overlap-group-1"style="cursor: pointer;z-index: 1;" (click)='changeAlienColor("purple")'>
                <img class="alienNeck" src="../../../assets/purpleAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/purpleAlienHead.svg" />
                <img id = 'purpleAlienSelected'class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                />
              </div>
              <div class="overlap-group-1"style="cursor: pointer;z-index: 1;" (click)='changeAlienColor("orange")'>
                <img class="alienNeck" src="../../../assets/orangeAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/orangeAlienHead.svg" />
                <img id = 'orangeAlienSelected'class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                />
              </div>
            </div>
          </div>

          <div class="form">
            <div class="flex-col-3">
              <div class="x7132140 component component-wrapper ready">
                <div class="name-i71321407181-qkVx5u">Child's Name</div>
                <div class="group-314-qkVx5u">
                  <input style = 'border: none;padding-left: 15px;outline: none;' class="rectangle-72-Ci9X1o name-Ci9X1o"maxLength = "30" minLength = "2" ngModel name="fn6" #fn6 ="ngModel" (change) = "changeName(fn6.value)" value = '{{currName | async}}'type="text" placeholder="Enter Name">
                </div>
              </div>
            
              <div class="x7132141 component component-wrapper ready">
                <div class="name-i71321417181-3pfrxS">Child's Hobbies</div>
                <div class="group-314-3pfrxS">
                  <input style = 'border: none;padding-left: 15px;outline: none;' class="rectangle-72-iyhrQP diana-i7132141718-iyhrQP"maxLength = "30" minLength = "2" ngModel name="fn1" #fn1 ="ngModel" (change) = "changeHobbies(fn1.value)" value = '{{currHobbies | async}}' type="text" placeholder="Enter Hobbies">
                </div>
              </div>
            
              <div class="frame-350-1">
                <p class="name">Fun Fact About Your Child</p>
                <div class="group-314">
                  <textarea style = 'border: none;padding-left: 15px;outline: none;resize: none;' class="overlap-group2-2 text- body" maxLength = "250" minLength = "2" ngModel name="fn4" #fn4 ="ngModel" (change) = "changeFunFact(fn4.value)" value = '{{currFunFact | async}}' type="text" placeholder="Write the fun fact" >
                  </textarea>  
                </div>
              </div>
            </div>
            <div class="flex-col-2">
              <div class="x7132143 component component-wrapper ready">  
                <div class="name-i71321437181-KBgIBW">The Toughest Subject</div>
                <div class="group-314-KBgIBW">
                  <input style = 'border: none;padding-left: 15px;outline: none;' class="rectangle-72-oP7xft diana-tsyba-i713214-oP7xft"maxLength = "30" minLength = "2" ngModel name="fn3" #fn3 ="ngModel" (change) = "changeToughSub(fn3.value)" value = '{{currToughSub | async}}'type="text" placeholder="Enter Toughest Subject">
                </div>
              </div>
            
              <div class="x7132142 component component-wrapper ready">
                <div class="name-i71321427181-JWGmjf">Child's Favourite Subject</div>
                <div class="group-314-JWGmjf">
                  <input style = 'border: none;padding-left: 15px;outline: none;' class="rectangle-72-M3ugyi diana-tsyba-i713214-M3ugyi"maxLength = "30" minLength = "2" ngModel name="fn2" #fn2 ="ngModel" (change) = "changeFavSub(fn2.value)" value = '{{currFavSub | async}}'type="text" placeholder="Favourite Subject">
                </div>
              </div>
              
              <div class="frame-351">
                <div class="name">Parent Note</div>
                <div class="group-314">
                  <textarea style = 'border: none;padding-left: 15px;outline: none;resize: none;' class="overlap-group2-3 first-name-middle-i body" maxLength = "250" minLength = "2" ngModel name="fn5" #fn5 ="ngModel" (change) = "ParentNote = fn5.value" value = '{{currParentNote | async}}' type="text" placeholder="Your note" >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="group-318">
            <!-- <div class="frame-75" style = 'z-index:1;cursor: pointer;'  (click) = save()><div class="save-changes">Save Changes</div></div> -->
            <!-- <div class="frame-267" style = 'z-index:1;cursor: pointer;'(click) = removeProfile()>
              <div class="trash">
                <div class="overlap-group2-5">
                  <img
                    src="../../../assets/trash.svg"
                  />
                </div>
              </div>
              <div class="delete-profile worksans-semi-bold-chathams-blue-14px">Delete Profile</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<script src="https://animaapp.s3.amazonaws.com/js/timeline.js"></script>
