<button *ngIf = "!small" class = "txtBlock" style = "margin-top: -50px; width:380px;border-radius: 0px;height: 300px; border-top-right-radius: 25px; border-bottom-left-radius: 25px;margin-bottom: 65px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230); " (click) = addToOrder() (resized)="onResized($event)">
    <div *ngIf = "checked" style="margin-top: -50px;">
        <h1 style = "margin-left:-10px;max-width: 290px;font-size: 20px;text-align: center; padding-bottom:20px;background-color: #EA6F66;color: white; margin-top: -150px;height: 33px; border-bottom-right-radius: 25px;"></h1> 
        <p class = "txt"style= "padding-top: 20px;font-size:20px">{{format(listing.title)}}</p>
        <p class = "txt" style = "margin: 0 auto; height: 32px;">{{listing.description}}</p>
    </div>
    <div *ngIf = "!checked" style="margin-top: -50px;">
        <h1 style = "margin-left:-10px;max-width: 290px;font-size: 20px;text-align: center; padding-bottom:20px;background-color: #292F45;color: white; margin-top: -150px;height: 33px; border-bottom-right-radius: 25px;"></h1> 
        <p class = "txt"style= "padding-top: 20px;font-size:20px">{{format(listing.title)}}</p>
        <p class = "txt" style = "margin: 0 auto;height: 32px;" >{{listing.description}}</p>
    </div>
</button>
    <button *ngIf = "small" class = "txtBlock" style = "margin-top: -10px;margin-bottom: 25px;min-width:310px;max-width:770px;border-radius: 0px;height: 300px; border-top-right-radius: 25px; border-bottom-left-radius: 25px;" (click) = addToOrder() (resized)="onResized($event)">
        <div *ngIf = "checked" style="margin-top: -50px;">
            <h1 style = "margin-left:-10px;max-width: 218px;font-size: 20px;text-align: center; padding-bottom:20px;background-color: #EA6F66;color: white; margin-top: -145px;height: 53px; border-bottom-right-radius: 25px;"></h1> 
            <p class = "txt"style= "padding-top: 20px;font-size:20px">{{format(listing.title)}}</p>
            <p class = "txt" style = "margin: 0 auto; height: 32px;">{{listing.description}}</p>
        </div>
        <div *ngIf = "!checked" style="margin-top: -50px;">
            <h1 style = "margin-left:-10px;max-width: 218px;font-size: 20px;text-align: center; padding-bottom:20px;background-color: #292F45;color: white; margin-top: -145px;height: 53px; border-bottom-right-radius: 25px;"></h1> 
            <p class = "txt"style= "padding-top: 20px;font-size:20px">{{format(listing.title)}}</p>
            <p class = "txt" style = "margin: 0 auto;height: 32px;" >{{listing.description}}</p>
        </div>
    </button>  

