<a *ngIf = "doneLoading" routerLink="../../TeacherPage/{{teacher.UID}}" class = "txtBlock">
    <div style = 'display:inline-block; '>
        <img id = "{{teacher.UID}}" [src] = '' src = '../../../assets/ceramic.jpeg' style = "width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;">
    </div>
    
    <!-- Add icon library -->  
    <div style="display:inline-block;">
        <div  style = "font-size: 16px;text-align: left;margin-bottom: 15px;"><strong>{{teacher.name}}</strong></div>             
        <p style="font-size: 14px;text-align: left;color:green"><li>{{teacher.credenitial}}</li></p>
        <!-- <div style="background-color: #292F45;height: 5px;margin: 0 auto; border-radius: 25px; width: 90%;">
            
        </div>  -->
        <p style = "word-wrap: break-word;font-size: 14px;text-align: left; width: 90%;">{{teacher.description}}</p>
    </div>                 
</a>
