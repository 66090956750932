<div class="container-center-horizontal">
    <div class="blog-v2 screen">
      <div class="group-304">
        <div class="overlap-group2">
          <!-- <div class="chevron-right-3">
            <img
              class="vector-9"
              src="../../../assets/vector-15@2x.png"
            />
          </div> -->
          <div class="frame-335">
            <div class="frame-330" *ngFor = "let t of topicsE | async" id = '{{t}}' style = 'z-index: 1;cursor: pointer;'(click) = 'onSelect(t)'>
              <div class="new body">{{t}}</div>
              <div class="check">
                <img
                  class="vector-1"
                  src="../../../assets/check.svg"
                />
              </div>
            </div>
          </div>
          <!-- <div class="chevron-right">
            <img
              class="vector"
              src="../../../assets/vector-14@2x.png"
            />
          </div> -->
          <div class="reset-all body" style="z-index: 1;cursor: pointer;" (click) ='reset()'>Reset All</div>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-col-2">
          <div class="group-300">
            <div class="group-296">
              <div class="group-299">
                <h1 class="text-4 h1">Explore Our Verified Blog</h1>
                <div class="rectangle-60"></div>
              </div>
              <p class="text-3 body">
                Welcome to the Verified Blog! We&#39;ll be keeping this page up-to-date with the latest news about
                education, technology, and Verified&#39;s mission. Check back in or sign up for reminders to stay in the
                loop!
              </p>
            </div>
            <div class="group-279">
              
              <div class="group-277">
                <input type = 'email' class="overlap-group1 email body" placeholder="Email..." ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value">
              </div>
              <div *ngIf = '!sentNL' id = 'signUp' class="group-268" style="z-index: 1;cursor: pointer;" (click) = "signUpNL()">
                <div class="overlap-group"><div class="sign-up body">Sign Up</div></div>
              </div>
              <div id = 'thankYou' class="group-268" style="display: none;">
                <div class="overlap-group"><div class="sign-up body">Thank You!</div></div>
              </div>
            </div>
          </div>
          <div class="frame-3" style="z-index: 1;cursor: pointer;" routerLink="../../VerifiedPost/{{article1.cat}}-{{article1.AUID}}">
            <div class="frame-323">
              <div class="frame-322">
                <div class="frame-321">
                  <div class="folder">
                    <img
                      src="../../../assets/Vector.svg"
                    />
                  </div>
                  <div class="text-5 body">{{article1.cat}}</div>
                </div>
                <div class="wed-september-11 body">{{date1}}</div>
              </div>
              <img  
                class="line-6"
                src="../../../assets/line-6@2x.png"
              />
            </div>
            <p class="text-1-1 h4" style="word-wrap: break-word;">{{article1.title}}</p>
            <div class="frame-325-1">
              <img
                id = '1'
                class="rectangle-103"
                src="../../../assets/ceramic.jpeg"
              />
              <div class="frame-324-1">
                <p class="text--1 body">
                  {{article1.subTitle}}...
                </p>
                <div class="address-1 body">{{article1.readTime}} mins read</div>
              </div>
            </div>
          </div>
          <div class="frame-331"style="z-index: 1;cursor: pointer;" routerLink="../../VerifiedPost/{{article2.cat}}-{{article2.AUID}}">
            <div class="frame-323">
              <div class="frame-322">
                <div class="frame-321">
                  <div class="folder">
                    <img
                      src="../../../assets/Vector.svg"
                    />
                  </div>
                  <div class="text-5 body">{{article2.cat}}</div>
                </div>
                <div class="wed-september-11 body">{{date2}}</div>
              </div>
              <img
                class="line-6"
                src="../../../assets/line-6@2x.png"
              />
            </div>
            <p class="text-1-1 h4" style="word-wrap: break-word;">{{article2.title}}</p>
            <div class="frame-325">
              <img
                id = '2'
                class="rectangle-103"
                src="../../../assets/ceramic.jpeg"
              />
              <div class="frame-324">
                <p class="text-1-2 body">
                  {{article2.subTitle}}...
                </p>
                <div class="address-1 body">{{article2.readTime}} mins read</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col">
          <div class="frame-329" style="z-index: 1;cursor: pointer;" routerLink="../../VerifiedPost/{{article3.cat}}-{{article3.AUID}}">
            <div class="frame-323">
              <div class="frame-322">
                <div class="frame-321">
                  <div class="folder">
                    <img
                      src="../../../assets/Vector.svg"
                    />
                  </div>
                  <div class="text-5 body">{{article3.cat}}</div>
                </div>
                <div class="wed-september-11 body">{{date3}}</div>
              </div>
              <img
                class="line-6"
                src="../../../assets/line-6@2x.png"
              />
            </div>
            <p class="text-1-1 h4" style="word-wrap: break-word;">{{article3.title}}</p>
            <div class="frame-325-1">
              <img
                id = '3'
                class="rectangle-103"
                src="../../../assets/ceramic.jpeg"
              />
              <div class="frame-324-1">
                <p class="text--1 body">
                  {{article3.subTitle}}...
                </p>
                <div class="address-1 body">{{article3.readTime}} mins read</div>
              </div>
            </div>
          </div>
          <div class="frame-328" style="z-index: 1;cursor: pointer;"routerLink="../../VerifiedPost/{{article4.cat}}-{{article4.AUID}}">
            <div class="frame-323">
              <div class="frame-322">
                <div class="frame-321">
                  <div class="folder">
                    <img
                      src="../../../assets/Vector.svg"
                    />
                  </div>
                  <div class="text-5 body">{{article4.cat}}</div>
                </div>
                <div class="wed-september-11 body">{{date4}}</div>
              </div>
              <img
                class="line-6"
                src="../../../assets/line-6@2x.png"
              />
            </div>
            <p class="text-1-1 h4" style="word-wrap: break-word;">{{article4.title}}</p>
            <div class="frame-325">
              <img
                id = '4'
                class="rectangle-103"
                src="../../../assets/ceramic.jpeg"
              />
              <div class="frame-324">
                <p class="text-1-2 body">
                  {{article4.subTitle}}...
                </p>
                <div class="address-1 body">{{article4.readTime}} mins read</div>
              </div>
            </div>
          </div>
          <div class="frame-3" style="z-index: 1;cursor: pointer;"routerLink="../../VerifiedPost/{{article5.cat}}-{{article5.AUID}}">
            <div class="frame-323">
              <div class="frame-322">
                <div class="frame-321">
                  <div class="folder">
                    <img
                      src="../../../assets/Vector.svg"
                    />
                  </div>
                  <div class="text-5 body">{{article5.cat}}</div>
                </div>
                <div class="wed-september-11 body">{{date5}}</div>
              </div>
              <img
                class="line-6"
                src="../../../assets/line-6@2x.png"
              />
            </div>
            <p class="text-1-1 h4" style="word-wrap: break-word;">{{article5.title}}</p>
            <div class="frame-325-1">
              <img
                id = '5'
                class="rectangle-103"
                src="../../../assets/ceramic.jpeg"
              />
              <div class="frame-324-1">
                <p class="text--1 body">
                  {{article5.subTitle}}...
                </p>
                <div class="address-1 body">{{article5.readTime}} mins read</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col-3">
        <div class="group-305">
          <!-- <div class="group-274-1">
            <div class="load-more body">Load More</div>
            <img
              class="line-4"
              src="../../../assets/line-4@2x.png"
            />
          </div> -->
          <div class="group-274">
            <div class="chevron-right-2">
              <img
                class="vector-5"
                src="../../../assets/vector-33@2x.png"
              />
            </div>
            <div class="address h4">
              <span class="span h4">1</span><span class="span1h4">&nbsp;</span><span class="span2h4">2</span
              ><span class="span h4"> 3</span>
            </div>
            <div class="chevron-right-1">
              <img
                class="vector-6"
                src="../../../assets/vector-32@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
