import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {time} from '../../models/times';
import {AngularFireDatabase} from '@angular/fire/database';
import {hour_min} from '../../models/hour-min';
import { Subject } from 'rxjs';
import { MessengerService } from '../../services/messenger.service';
import { newProduct} from '../../models/newProduct';
import { min } from 'rxjs/operators';

@Component({ 
  selector: 'app-times-item',
  templateUrl: './times-item.component.html',
  styleUrls: ['./times-item.component.scss']
})
export class TimesItemComponent implements OnInit {
  listNum = []
  validDays = []
  errors = false
  timeDif:number = 0
  standardListNum = []
  currentDate =""
  maxDate = ""
  spreads = []
  cartItems = [];
  cartTotal = 0;
  dateDay:string = "";  
  @Input() day: hour_min;
  @Input() returns:number[] = []
  @Input() offSet:number
  @Input() productItem: newProduct
  @Input() upcomingApp: string
  @Input() future;
  @Output() newTime = new EventEmitter<any[]>();
  @Output() resetServices = new EventEmitter<any>();
  chosenDate = "Choose a date"
  errorString = []
  startRaw = 0
  endRaw = 0
  start = ""
  end = ""
  subject = new Subject();
  @Output() updateAvailableTimes = new EventEmitter<any[]>();


  constructor(private msg: MessengerService) { }
  
  async ngOnInit(){
    var d = this.getDayVal() 
    var today = new Date()
    var minDate= new Date(); 
    if(d === today.getDay()){
      //minDate = today
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var todays =  yyyy + '-' + mm + '-' + dd;
      this.currentDate = todays 
    }else{
      var count = 0
      var startDay = today.getDay()
      while(startDay !== d){
        startDay += 1
        if(startDay === 8){
          startDay = 1
        }
        count += 1
      }
      var newDate = new Date()
      newDate = new Date(newDate.getTime() + ((1000 * 60 * 60 * 24)*count))
     // minDate = newDate
      var dd = String(newDate.getDate()).padStart(2, '0');
      var mm = String(newDate.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = newDate.getFullYear();
      var todays =  yyyy + '-' + mm + '-' + dd;
      this.currentDate = todays 
    }

    var yourDate = new Date()
    yourDate = new Date(yourDate.getTime() + ((1000 * 60 * 60 * 24)*this.future['val']));
    var maxHold = new Date(yourDate.getTime())
    var dd2 = String(yourDate.getDate()).padStart(2, '0');
    var mm2 = String(yourDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy2 = yourDate.getFullYear();

    var tomorrows = yyyy2 + '-' + mm2 + '-' + dd2;
    this.maxDate = tomorrows

    var todaaa = new Date()
    var todaaaa = new Date()

    
    while(maxHold.getDate() !== minDate.getDate() || maxHold.getMonth() !== minDate.getMonth() || maxHold.getFullYear() !== minDate.getFullYear()){
      if(minDate.getDay() === d){
        var dd3 = String(minDate.getDate()).padStart(2, '0');
        var mm3 = String(minDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy3 = minDate.getFullYear();
        var todays3 =  yyyy3 + '-' + mm3 + '-' + dd3;
        this.validDays.push(todays3)
      }
      minDate = new Date(minDate.getTime() + ((1000 * 60 * 60 * 24)*1))
    }

    var timeDif = (new Date().getTimezoneOffset()/60) - this.offSet;//offset in hours
    this.timeDif = timeDif
    var count = 2
    var startTime;
    var times = []
    for(var val of this.day.hours.split(" ")){
      if(count % 2 === 0){
        startTime = val
      }else{
        times.push([startTime,val])
      }
      count += 1

    }
    await this.getSpread(times,timeDif)
    this.updateAvailableTimes.emit(this.listNum)
    this.standardListNum = this.listNum
  }

  getDayVal(){
    var hold;
    if(this.day.days === 'mon'){
      hold = 1
    }else if(this.day.days === 'tue'){
      hold = 2
    }else if(this.day.days === 'wed'){
      hold = 3
    }else if(this.day.days === 'thu'){
      hold = 4
    }else if(this.day.days === 'fri'){
      hold = 5
    }else if(this.day.days === 'sat'){
      hold = 6
    }else{
      hold = 7
    }
    return hold
  }

  // putBackListNum(st:number,en:number,currentTum){
  //   var newList = []
  //   this.listNum.forEach(val =>{
  //     if(val < this.startRaw || val > this.endRaw){
  //       newList.push(val)
  //     }
  //   })
  //   this.listNum = newList
  //   this.updateAvailableTimes.emit(this.listNum)
  // }

  updateListNum(){
    var newList = []
    this.listNum.forEach(val =>{
      if(val < this.startRaw || val > this.endRaw){
        newList.push(val)
      }
    })
    this.listNum = newList
    this.updateAvailableTimes.emit(this.listNum)
  }

  dateFormat(day){
    var hold = day.split("-")
    var day = hold[2]
    var month = hold[1]
    var year = hold[0]

    return this.getMonth(month) + " " +  day + " " + year
  }

  getMonth(month){
    month = Number(month)
    if(month === 1){
      return "Jan"
    }else if(month === 2){
      return "Feb"
    }else if(month === 3){
      return "Mar"
    }else if(month === 4){
      return "Apr"
    }else if(month === 5){
      return "May"
    }else if(month === 6){
      return "June"
    }else if(month === 7){
      return "July"
    }else if(month === 8){
      return "Aug"
    }else if(month === 9){
      return "Sep"
    }else if(month === 10){
      return "Oct"
    }else if(month === 11){
      return "Nov"
    }else{
      return "Dec"
    }
  }

  // this is where we check to that all inputs are filled
  // if everything is provided that means that we dont have to do any other error check on redundency becaus
  //the time being present and disapearing makes it imposible to add items twice 


  //format how I want to send the object to the add to cart widget
  addToCart() {
    if(this.getStart() !== "" && this.getEnd() !== "" && this.dateDay !== ""){
      this.errors = false
      var timesString = this.day.days +" "+ this.dateDay.split("-")[1] + "-" + this.dateDay.split("-")[2]+"-" + this.dateDay.split("-")[0]+" "+this.getStart() + "-" +this.getEnd()
      var timesValue = this.getTimeInHours()
      this.updateListNum()
      this.newTime.emit([timesString,timesValue,this.dateDay,this.startRaw,this.endRaw]);
      this.start = ""
      this.startRaw = 0
      this.end = ""
      this.endRaw = 0
      this.errorString = []
    }else{
      this.errors = true
      this.errorString = ["missing time or date"]
    }
    

    // var startTime = this.getStart()
    // var endTime = this.getEnd()
    // var dur = this.getDur()
    // var hold = (Math.abs((this.endRaw%100 - this.startRaw%100)) *60) + Math.round(this.endRaw/100) - Math.round(this.startRaw/100)
    // this.newTime.emit([startTime+"-"+endTime,dur,hold]);
  }

  getTimeInHours(){
    var hours = Math.abs(Math.floor(this.endRaw/100) - Math.floor(this.startRaw/100))
    var min = Math.abs((this.endRaw%100 - this.startRaw%100)/15)
    var cnt = 0
    var minVal = 0
    while(cnt< min){
      minVal += .25
      cnt += 1
    }
    return hours + minVal
  }

  formDay(day):string{
    if(day === "mon"){
      return "Monday"
    }else if(day === "tue"){
      return "Tuesday"
    }else if(day === "wed"){
      return "Wednesday"
    }else if(day === "thu"){
      return "Thursday"
    }else if(day === "fri"){
      return "Friday"
    }else if(day === "sat"){
      return "Saturday"
    }else{
      return "Sunday"
    }
  }

  getStart(){
    return this.start
  }

  getEnd(){
    return this.end
  }

  getDur(){
    var min = Math.abs((this.endRaw%100 - this.startRaw%100))
    var minString = ""
    if(min > 0){
      minString = " " + String(min) + " mins"
    }
    var hou = Math.round(this.endRaw/100) - Math.round(this.startRaw/100)
    var housrString = ""
    if(hou === 1){
      housrString = String(hou) + " hr"
    }
    else if(hou > 1){
      housrString = String(hou) + " hrs"
    }
    return housrString + minString
  }

   

  timesForDay(requestedDate){
    this.start = ''
    this.end = ''
    this.chosenDate = this.dateFormat(requestedDate) 
    this.dateDay = requestedDate
    var futureBookings = this.upcomingApp.split("|")
    var seen = false
    futureBookings.forEach(val=>{
      var date = val.split(":")[0]
      if(date === requestedDate){
        seen = true;
        var times = this.getBookedTimes(val.split(":")[1])
        times.forEach(time =>{
          this.listNum = this.listNum.filter(v => v !== time)
          
        })
      }
    })

    if(seen){
      this.updateAvailableTimes.emit(this.listNum)
    }else{
      this.updateAvailableTimes.emit(this.standardListNum)
    }
    
  }

  getBookedTimes(times:string): number[]{
    var timeDif = (new Date().getTimezoneOffset()/60) - this.offSet;//offset in hours
    this.timeDif = timeDif
    var chunks = times.split(",")
    var bookedTimes = []
    chunks.forEach(spre => {
      var start = Number(spre.split("-")[0])
      var startHold = (((Math.floor(start/100) - timeDif)*100) + Math.floor(start%100))
      bookedTimes.push(startHold)
      var end = Number(spre.split("-")[1])
      var endHold = (((Math.floor(end/100) - timeDif)*100) + Math.floor(end%100))
      var curr = startHold
      while(curr <= endHold){
        if(curr%100 ===5){
          curr = curr - 5
        }
        if(Math.floor(curr%100) < 60){
          bookedTimes.push(curr)
        }
        curr = curr + 15
      }
      if(curr%100 === 5){
        curr = curr - 5
        bookedTimes.push(curr)
      }

    })
    return bookedTimes
  }

  outsideRange(num): boolean{
    var ret;
    var val = this.spreads[0]
    if(this.startRaw >= val[0] && this.startRaw <= val[1]){
      if(num >= val[0] && num <= val[1]){
        ret = false;
      }else{
        ret = true
      }
    }else{
      if(num >= val[0] && num <= val[1]){
        ret = true
      }else {
        ret = false
      }
      
    }
    return ret
  }

  updateDur(num){
    if(this.start === "" ){
      this.startRaw = num
      this.start = this.format(num)
    }else if(this.outsideRange(num)){
      this.startRaw = num
      this.start = this.format(num)
      this.endRaw = 0
      this.end = ""
    }else if(this.end === ""){
      if(num === this.startRaw){
        this.start = ""
        this.startRaw = 0
      }else if (num > this.startRaw){
        this.endRaw = num
        this.end = this.format(num)
      }else{
        this.endRaw = this.startRaw
        this.end = this.start
        this.startRaw = num
        this.start = this.format(num)
      }
    }else{//3 values
      if(num === this.startRaw){
        this.startRaw = this.endRaw
        this.start = this.end
        this.endRaw = 0
        this.end = ""
      }else if(num === this.end){
        this.endRaw = 0
        this.end = ""
      }else if(num < this.startRaw){
        this.startRaw = num
        this.start = this.format(num)
      }else if(num > this.endRaw){
        this.endRaw = num
        this.end = this.format(num)
      }else{
        this.endRaw = num
        this.end = this.format(num)
      }
    }
  }

  format(num:number):string{
    if(num < 1200){
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ "am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + "am"
        }      
      } 
    }else{
      var holdVar = Math.floor(num/100)-12
      if(holdVar === 0){
        holdVar = 12
      }
      if(num%100 === 0){
        return String(holdVar)+ "pm"
      }else{ 
        return String(holdVar)+":"+String(num%100) + "pm"
      }       

    }
  }

  formatOG(num:number):string{
    if(Math.floor(num/100) === 0){
      num = (12 * 1000) + num%100
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ "am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + "am"
        }      
      } 
    }else if(num.toString().length ===4 && num/100 > 11){
      if (Math.floor(num/100) > 12){
        if(num%100 === 0){
          return String((Math.floor(num/100)-12))+ "pm"
        }else{ 
          return String((Math.floor(num/100)-12))+":"+String(num%100) + "pm"
        } 
      }else{
        if(num%100 === 0){
          return String((Math.floor(num/100)))+ "pm"
        }else{ 
          return String((Math.floor(num/100)))+":"+String(num%100) + "pm"
        }
      }

    }
    else{
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ "am"
      }else{ 
        return String((Math.floor(num/100)))+":"+String(num%100) + "am"
      } 
    }
  }

  // spread(num:string){
  //   var count = 2
  //   var startTime;
  //   var times = []
  //   for(var val of num.split(" ")){
  //     if(count % 2 === 0){
  //       startTime = val
  //     }else{
  //       times.push([startTime,val])
  //     }
  //     count += 1

  //   }
  //   this.getSpread(times)
  // }

  handleDate(){
    var hold  = new Date().getDate()
  }
  howFar(){
    console.log("hello")
  }

  getSpread(num,offSet){
    this.listNum = []
    num.forEach(element => {
      var startTime = (((Math.floor((Number(element[0]))/100) - offSet)*100) + Math.floor((Number(element[0]))%100))
      var endTime = (((Math.floor((Number(element[1]))/100) - offSet)*100) + Math.floor((Number(element[1]))%100))
      this.spreads.push([startTime,endTime])
      var currTime = startTime
      while(endTime >= currTime){
        if(currTime%100 ===5){
          currTime = currTime - 5
        }
        if(currTime%100 < 60){
          this.listNum.push(currTime)
        }
        currTime +=15
      }
      if(currTime%100 ===5){
        currTime = currTime - 5
        this.listNum.push(currTime)
      }
      
    });
  }



  

}
