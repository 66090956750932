import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  name = ''
  contactEmail = ''     
  question = ''
  sent = false
  
  constructor(private fns:AngularFireFunctions) { }
  
  ngOnInit(): void {
  }
  ask(){
    console.log(this.name,this.contactEmail,this.question)
    if(this.name !== '' && this.contactEmail !== '' && this.question !== ''){
      const sendMail = this.fns.httpsCallable('sendMail')
      var info = this.name + '||' + this.contactEmail + '||' + this.question
      sendMail({
        address: 'fred@verifiedteachers.com',
        subject:`${"New FAQ Question"}`,
        msg: 'Data: ' + `${info}`,
      }).toPromise().then(()=>{
        this.sent = true
      }).catch((e)=> console.log('err',e))
    }else{
      //please comp
    }
  }
}
  