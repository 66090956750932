import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {Router} from '@angular/router'
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-standard-signup',
  templateUrl: './standard-signup.component.html',
  styleUrls: ['./standard-signup.component.scss']
})
export class StandardSignupComponent implements OnInit {
  name  = ''
  email = ''
  password = ''
  city = ''
  stateZip = ''
  grades = ''
  subjects = ''
  UID = ''

  @Output() UIDEmitter = new EventEmitter<any>();

  constructor(private auth: AuthService,private rerouter: Router) { }

  ngOnInit(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0
  }

  openStudentMaker(){   
    if(this.password !== '' && this.name !== '' && this.email !== '' && this.city!== '' && this.stateZip !== '' && this.grades !== '' && this.subjects !== ''){
      document.getElementById('studentProfile').setAttribute('style','position: relative;top: -94px;')
      document.getElementById('parentProfile').setAttribute('style','display:none;')
    }   
  }
  
  async signUp(){
    console.log(this.password, this.name,this.email, this.city,this.stateZip,this.grades,this.subjects !== '')
    if(this.password !== '' && this.name !== '' && this.email !== '' && this.city!== '' && this.stateZip !== '' && this.grades !== '' && this.subjects !== ''){
      console.log(2)
      await this.auth.emailSignUpFull(this.name, this.email,this.city, this.stateZip,this.grades,this.subjects).then(uid=>{
        this.UID = uid
        this.rerouter.navigateByUrl('SignUp/' + `${this.UID}`)
        console.log(2)
        document.getElementById('parentProfile').setAttribute('style','display:none;')      
        document.getElementById('studentProfile').setAttribute('style','position: relative;top: -94px;')
         
      }).catch(e=>{console.log(e)})
    }
  }
  storeEmail(email){
    console.log(email)
    this.auth.storeEmail(email)
    this.email = email
  }  
 
  storePass(pass){
    this.auth.storePassword(pass)
    this.password = '123'
  }

  addNewStudent($event){
    this.rerouter.navigateByUrl('profile/' + `${this.UID}`)
  }

}
