<div (resized)="onResized($event)">
    <div id = 'image'class="image"  style = "font-family: 'Avenir Light';">
        <div *ngIf = 'computer' style="display: inline-block;width: 50%;padding-bottom: 15px;">
            <h1 style="font-size:84px;margin-top: 150px;padding: 13px;color: #292F45;">Unlimited</h1>
            <h1 style="font-size:84px;padding: 13px;color: #292F45;">Verified Answers</h1>
          </div>
          <div *ngIf = 'computer' style="display: inline-block; width: 35%;vertical-align: top;">
              <p style="font-size: 26px;line-height: 1;padding-top: 30px;color: #292F45;">
                Welcome to our Verified Question Communities. Feel free to ask questions to our awesome teachers and give them a 
                chance to show off their knowledge. While anyone can ask questions, only teachers can answer them.
              </p>   
          </div>
    </div>
    <div style="background-color: rgb(230,230,230);padding: 20px;margin: 0 auto;text-align: center;">
        <div *ngFor = "let topic of t | async" style="display: inline-block;">
            <input type = "button" class="time btn" id = '{{topic}}'style = 'color:white;background-color:#292F45;font-size:18px;border: 5px solid rgb(230,230,230);' value="{{topic}}" (click) = 'selectTopic(topic)'/>
        </div> 
        <div>
            <div *ngFor = "let subtopic of s | async" style="display: inline-block;">
                <input type = "button" class="time btn" id = '{{subtopic}}'style = 'color:white;background-color:#292F45;font-size:18px;border: 5px solid rgb(230,230,230);' value="{{subtopic}}" (click) = 'selectSub(subtopic)'/>
            </div> 
        </div>
        <div style = "display: inline-block;background-color: #D8D8D8;border: 5px solid rgb(230,230,230);padding:15px;border-radius: 25px;width: 85%;vertical-align: top;text-align: center;margin:0 auto; margin-bottom: 20px; margin-top: 30px;">
            <label style="font-size: 22px;text-align: center;;margin:0 auto;margin-bottom: 20px;;color:#292F45;">Welcome to our {{community}} community</label>
            <p style="font-size: 18px;max-width: 840px;text-align: center;;margin:0 auto;margin-bottom: 20px;;color:#292F45;">
                Once our moderator approves your question it will be live for our K-12 teachers to answer. The only individuals who 
                will answer your questions are licensed K-12 instructors 
            </p>
            <textarea ngModel name="fan1" #fan1 ="ngModel" (change) = "newQuestion = fan1.value" id ="fan1"  class="form-control txtBlock" style = "max-width: 1140px;font-size:16px;background-color:rgb(230,230,230);vertical-align: top;display:inline-block;text-align: left;;margin:0 auto;min-height: 120px;max-height: 200px;" placeholder="Your question here." value = ''></textarea>
            <div style = 'display: inline-block;max-width: 300px;margin-right: 25px;'>
                <input ngModel name="fan2" #fan2 ="ngModel" (change) = "updateEmail = fan2.value" id ="fan2"  class="form-control txtBlock" style = "font-size:16px;background-color:rgb(230,230,230);vertical-align: top;display:inline-block;text-align: left;;margin:0 auto;" placeholder="Email for Answers (Optional)" value = ''>
            </div>
            <div style = 'display: inline-block;text-align: left;'>
                <button 
                (click) = 'askQuestion()'
                type="button"
                style = "text-align: right; margin-top: 15px;margin-bottom: 3px;background-color: #292F45;"
                class="time checkbox btn btn-secondary" > 
                    Ask Question
                </button>
            </div>
        
            <div *ngFor = "let s of questionErros">
                <div class = "txtBlock">{{s}}</div>
            </div>
            <div style="margin-top: 15px;border: 5px solid rgb(230,230,230);border-radius: 25px;padding:15px;height: 700px;overflow:auto;max-width:1200px">
                <app-question *ngFor = "let question of q | async" [question] = "question" style="text-align: center;margin: 0 auto;">
                </app-question> 
            </div>
        </div> 
    </div>
</div>
