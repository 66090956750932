import { stringify } from '@angular/compiler/src/util';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { auth } from 'firebase';
import { answer } from 'src/app/models/answer';
import { Question } from 'src/app/models/question';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  // (QUID,TUIDs,AUID,question,numberOfAnswers,timeStamp,studentParent,openClosed)
  @Input() question: Question;
  answerErros  = []
  questionDisp = ''
  numberOfAnswersDisp = ''
  openClosed = 0
  @Output() answersEvent = new EventEmitter<answer[]>();
  answers: answer[] = []
  currResponseNumber
  closedOpen
  date
  questionEmitter
  time
  teacher = false
  newQuestion = ''
  answerInput = ''
  @Output() answerRespEmit = new EventEmitter<any>();
  
  
  constructor(private database:AngularFireDatabase,private fns:AngularFireFunctions) { }

  async ngOnInit(){  
    var hold = false 
    if(auth().currentUser){
      await this.database.database.ref('providers/userInfo/' + auth().currentUser.uid).once('value').then(function(snap){
        console.log(snap.exists())
        if(snap.exists()){
          hold = true 
        }
      })
    }
    
    this.teacher = hold
    if(this.openClosed === 1){
      this.teacher = false
    }else{
      var present = ''
      if(hold){
        await this.database.database.ref('providers/userInfo/' + auth().currentUser.uid + '/answers/' + `${this.question.QUID}`).once('value').then(function(snap){
          if(snap.exists()){
            present = snap.child('answer').val()
          }
      })
      this.answerInput = present
      this.answerRespEmit.emit(this.answerInput)
    }
    
    }

    this.questionEmitter = this.question.question
    var numResp = this.question.openClosed
    this.currResponseNumber = numResp
    if(numResp < 10){
      this.closedOpen = 0
    }else{
      this.closedOpen = 1
    }
    var dateHold = new Date(this.question.timeStamp).toString().split(' ')
    this.date = dateHold[1] + ' ' + dateHold[2] + ' ' + dateHold[3] 
    var timeHold = dateHold[4].split(':')
    this.time = this.formatTime((Number(timeHold[0])*100) + (Number(timeHold[1])))
    var questionHold = this.question 
    if(this.question.TUIDs !== null && this.question.TUIDs !== undefined && this.question.TUIDs !== ''){
      var holdAnswers = []
      await this.question.TUIDs.split('|').forEach(TUID =>{  
        this.database.database.ref('providers/userInfo/' + `${TUID}` + '/answers/' + `${this.question.QUID}`).once('value').then(function(snap){
          holdAnswers.push(new answer(questionHold.QUID,TUID,snap.child('answer').val(),snap.child('timeStamp').val()))
        })
      })
      console.log(holdAnswers)
      this.answersEvent.emit(holdAnswers)
      this.answers = holdAnswers
    }

    
  }


  sendEmail(address,subject,msg){
    const sendMail = this.fns.httpsCallable('sendMail')
    sendMail({
      address: `${address}`, 
      subject:`${subject}`,
      msg:`${msg}`,
    }).toPromise().then(() => console.log("success")).catch((e)=> console.log('err',e))
  }

  async addResponse(){
    var questionHold = this.question
    var databaseRef = this.database
    var numberOfAnswersDispHold = this.numberOfAnswersDisp
    console.log(this.newQuestion)
    if(this.newQuestion !== ''){
      if(this.newQuestion.length > 7){
        var numberOfAnswer = 0
        await databaseRef.database.ref('providers/userInfo/' + `${auth().currentUser.uid}` + '/answers').update({[`${this.question.QUID}`]: {answer:this.newQuestion,timeStamp:+ new Date()}})
        .then(()=>{
          // get the path of where to update the TUID string and update it
          console.log('questionSearch/' + questionHold.grade + '/' + questionHold.topic +  '/' + questionHold.QUID + '/TUIDs')
          databaseRef.database.ref('questionSearch/' + questionHold.grade + '/' + questionHold.topic +  '/' + questionHold.QUID + '/TUIDs').once('value').then(function(snap){
            console.log(snap.val())
            
            if(snap.val() === ''){
              databaseRef.database.ref('questionSearch/' + questionHold.grade + '/' + questionHold.topic + '/'  + questionHold.QUID).update({'TUIDs': auth().currentUser.uid}).then(()=>{
                databaseRef.database.ref('questionSearch/' + questionHold.grade + '/' + questionHold.topic + '/' + questionHold.QUID + '/openClosed').once('value').then(function(snap){
                  numberOfAnswer = Number(snap.val()) + 1
                }).then(()=>{
                  console.log(numberOfAnswer)
                  databaseRef.database.ref('questionSearch/' + questionHold.grade + '/' + questionHold.topic + '/' + questionHold.QUID).update({'openClosed': numberOfAnswer})
                  numberOfAnswersDispHold = String(numberOfAnswer)
                })
              })
            }else{
              var unique = true
              snap.val().split('|').forEach(val=>{
                if(auth().currentUser.uid === val){
                  unique = false
                }
              })  
              if(unique){
                
                databaseRef.database.ref('questionSearch/' + questionHold.grade + '/' + questionHold.topic + '/' + questionHold.QUID + '/openClosed').once('value').then(function(snap){
                  numberOfAnswer = Number(snap.val()) + 1
                })
                console.log(numberOfAnswer)
                if(numberOfAnswer < 11){
                  databaseRef.database.ref('questionSearch/' + questionHold.grade + '/' + questionHold.topic  + '/' + questionHold.QUID).update({'TUIDs': snap.val() + '|' +auth().currentUser.uid})
                  databaseRef.database.ref('questionSearch/' + questionHold.grade + '/' + questionHold.topic + '/' + questionHold.QUID).update({'openClosed': numberOfAnswer})
                  numberOfAnswersDispHold = String(numberOfAnswer)

                }else{
                  //looks like someone bid before you :(
                }
              }
            }
          })
        }).then(()=>[
          this.answerErros = ['Thank you for your answer!']
        ])
      }else{
      //to short
      this.answerErros = ['A question must be atleast 7 characters.']
      }
    }else{
      this.answerErros = ['No text enetered.']
    }
    
    //nothing at all
    
  }
  
  formatTime(num:number):string{
    if(num < 1200){
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ " am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + " am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + " am"
        }      
      } 
    }else{
      var holdVar = Math.floor(num/100)-12
      if(holdVar === 0){
        holdVar = 12
      }
      if(num%100 === 0){
        return String(holdVar)+ " pm"
      }else{ 
        return String(holdVar)+":"+String(num%100) + " pm"
      }       

    }
  }
}
