import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/user'; // optional
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database'
import { DBService } from '../services/db.service'

declare var gtag
 
@Injectable({ providedIn: 'root' })
export class AuthService {

    user$: Observable<User>;
    email: string;
    password: string;
    currentUID:string;
    
    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router,
        private database: DBService,
        private db : AngularFireDatabase
    ) {
      // Get the auth state, then fetch the Firestore user document or return null
      this.user$ = this.afAuth.authState.pipe(
        switchMap(user => {
            // Logged in
          if (user) {
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          } else {
            // Logged out
            return of(null);
          }
        })
      )
    }

    async storeEmail(email:string){
      if(email !== undefined){
        console.log(email)
        this.email = email
      }
    }
    async storePassword(password:string){
      if(password !== undefined){
        this.password = password
      }
    }

    async googleSignin() {
      const provider = new auth.GoogleAuthProvider();
      const credential = await this.afAuth.signInWithPopup(provider);
      gtag("event", "sign_up", {
        method: 'Google'
      });
      return this.updateUserData(credential.user);
    }

    async resetPass(){
      var message = ""
      await this.afAuth.sendPasswordResetEmail(this.email).then(function() {
        message = "reset email sent"
      }).catch(function(error) {
        // An error happened.
        message = "we dont recgonize that email"
      });
      return message
    }

    async aux(one,two){
      this.afAuth.signInWithEmailAndPassword(one,two)
    }

    async omompomo(){
      this.afAuth.signInWithEmailAndPassword('parker@verifiedteachers.com','parkerdog1')
    }

    async emailSignUpAmbass(email,password,name){
      var dbRef =  this.db.database
      return await this.afAuth.createUserWithEmailAndPassword(email,password).then(val=>{
        var valHold = val
        this.afAuth.signInWithEmailAndPassword('parker@verifiedteachers.com','parkerdog1').then(()=>{
          dbRef.ref('Ambass/').push({'UID':'','Refs':''}).then(push=>{
            var PUID = push.key
            dbRef.ref('users/' + valHold.user.uid).update({'ambassCode': PUID,'name':name}).then(()=>{
              dbRef.ref('Ambass/').update({'UID':val.user.uid})
            })
          })
        })
        return valHold.user.uid
      })
    }

    async emailSignUpFull(name, email,city, stateZip,grades,subjects){
      return await this.afAuth.createUserWithEmailAndPassword(this.email, this.password).then(val=>{
        this.updateUserData(val.user) 
        this.db.database.ref('users/').update({[val.user.uid]:{'past':'empty','email':val.user.email,'userInfo':{'name':{'val':name}},'city':city,'stateZip':stateZip,'studentAges':grades,'desiredSubs':subjects}})
        gtag("event", "sign_up", {
          method: 'Organic'
        });
        return val.user.uid;
      })  
    }

    async emailSignUp(){
      return await this.afAuth.createUserWithEmailAndPassword(this.email, this.password).then(val=>{
        this.updateUserData(val.user) 
        this.db.database.ref('users/' + val.user.uid + '/past').set({
          val: 'empty'
        })
        this.db.database.ref('users/' + val.user.uid + '/email').set({
          val: `${val.user.email}`
        })
        this.db.database.ref('users/' + val.user.uid + '/name').set({
          val: 'empty'
        })
        gtag("event", "sign_up", {
          method: 'Organic'
        });
        return val.user.uid;
      })  
    }

    // async emailSignUp(){
    //   await this.afAuth.createUserWithEmailAndPassword(this.email, this.password).then(function(hold) {
    //     this.updateUserData(hold.user)
    //   }).catch(function(error) {
    //     // An error happened.
    //   });
    // }

    async emailSignIn(){
      const ho = await (await this.afAuth.signInWithEmailAndPassword(this.email, this.password)).user
      gtag('event', 'login', {
        method: 'Verified'
      });
      return this.updateUserData(ho);
    }
    
    async updateUserData(user) {
      // Sets user data to firestore on login
      if(user != null){
        this.database.writeUserData(user.uid,user.email, user.displayName,user.photoURL)

        const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    
        const data = { 
          uid: user.uid, 
          email: user.email, 
          displayName: user.displayName, 
          photoURL: user.photoURL
        } 
        
        return userRef.set(data, { merge: true })
      }
    }
  
    async signOut() {
      await this.afAuth.signOut();
      this.router.navigate(['/']);
    }
}

