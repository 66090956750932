<div class="container-center-horizontal">
  <div class="comunity-resources screen">
    <div class="overlap-group11">
      <div class="overlap-group">
        <!-- <div class="spinner-grow spinner-grow-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div> -->
        <div class="filter">
          <div class="overlap-group1-4">
            <div class="frame-97">
              <div class="frame-207">
                <div class="topics-13 tab-bold">Topics</div>
                <div class="chevron-down">
                  <img
                    class="vector"
                    src="../../../assets/vector-29@2x.png"
                  />
                </div>
              </div>
              <div class="frame-206">
                <div class="subtopics tab-bold">Subtopics</div>
                <div class="chevron-down">
                  <img
                    class="vector"
                    src="../../../assets/vector-29@2x.png"
                  />
                </div>
              </div>
            </div>
            <div class="reset-all tab-bold">Reset All</div>
          </div>
        </div>
        <div id = 'dropship' class="overlap-group1-11">
          <!-- put an ngfor here -->
            <div id = '' style="z-index: 1;cursor: pointer;display: none;">
              <div *ngFor = "let sesh of topicsList">
                <input 
                type = "button" 
                class="inter-semi-bold-chathams-blue-14px" 
                style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' 
                value="{{sesh}}" 
                (click)="setTopic(sesh)"/>
              </div> 
            </div>
            <div style="z-index: 1;cursor: pointer;">
              <div *ngFor = "let sesh of subtopicsList">  
                <input 
                type = "button" 
                class="inter-semi-bold-chathams-blue-14px" 
                style = 'width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' 
                value="{{sesh}}" 
                (click)="setTopic(sesh)"/>  
              </div>     
            </div>
          </div>
        <div class="cards">
          <div class="flex-row">
            <div class="flex-col-1">
              <div class="frame-212">
                <div *ngIf="loading" style="padding-bottom:15px;">
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>  
                <img class="frame-210">
                <div class="frame-209">
                  <div class="kimberley h4">{{title1}}</div>
                  <p class="text-1 inter-normal-mine-shaft-12px">{{desc1}}</p>
                  <div class="frame-299">
                    <div class="address inter-normal-gunsmoke-12px">{{downloads1}} Downloads</div>
                    <div class="download">
                      <img src = '../../../assets/download.png'>
                    </div>
                  </div>
                </div>
              </div>
              <div class="frame-212">
                <div *ngIf="loading" style="padding-bottom:15px;">
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>  
                <img class="frame-210">
                <div class="frame-209">
                  <div class="kimberley h4">{{title1}}</div>
                  <p class="text-1 inter-normal-mine-shaft-12px">{{desc1}}</p>
                  <div class="frame-299">
                    <div class="address inter-normal-gunsmoke-12px">{{downloads1}} Downloads</div>
                    <div class="download">
                      <img src = '../../../assets/download.png'>
                    </div>
                  </div>
                </div>
              </div>
              <div class="frame-212">
                <div *ngIf="loading" style="padding-bottom:15px;">
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>  
                <img class="frame-210">
                <div class="frame-209">
                  <div class="kimberley h4">{{title1}}</div>
                  <p class="text-1 inter-normal-mine-shaft-12px">{{desc1}}</p>
                  <div class="frame-299">
                    <div class="address inter-normal-gunsmoke-12px">{{downloads1}} Downloads</div>
                    <div class="download">
                      <img src = '../../../assets/download.png'>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-col">
              <div class="frame-212">
                <div *ngIf="loading" style="padding-bottom:15px;">
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>  
                <img class="frame-210">
                <div class="frame-209">
                  <div class="kimberley h4">{{title1}}</div>
                  <p class="text-1 inter-normal-mine-shaft-12px">{{desc1}}</p>
                  <div class="frame-299">
                    <div class="address inter-normal-gunsmoke-12px">{{downloads1}} Downloads</div>
                    <div class="download">
                      <img src = '../../../assets/download.png'>
                    </div>
                  </div>
                </div>
              </div>
              <div class="frame-212">
                <div *ngIf="loading" style="padding-bottom:15px;">
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>  
                <img class="frame-210">
                <div class="frame-209">
                  <div class="kimberley h4">{{title1}}</div>
                  <p class="text-1 inter-normal-mine-shaft-12px">{{desc1}}</p>
                  <div class="frame-299">
                    <div class="address inter-normal-gunsmoke-12px">{{downloads1}} Downloads</div>
                    <div class="download">
                      <img src = '../../../assets/download.png'>
                    </div>
                  </div>
                </div>
              </div>
              <div class="frame-212">
                <div *ngIf="loading" style="padding-bottom:15px;">
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>                 
                  <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>  
                <img class="frame-210">
                <div class="frame-209">
                  <div class="kimberley h4">{{title1}}</div>
                  <p class="text-1 inter-normal-mine-shaft-12px">{{desc1}}</p>
                  <div class="frame-299">
                    <div class="address inter-normal-gunsmoke-12px">{{downloads1}} Downloads</div>
                    <div class="download">
                      <img src = '../../../assets/download.png'>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-col">
            <div class="frame-212">
              <div *ngIf="loading" style="padding-bottom:15px;">
                <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>                 
                <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>                 
                <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>  
              <img class="frame-210">
              <div class="frame-209">
                <div class="kimberley h4">{{title1}}</div>
                <p class="text-1 inter-normal-mine-shaft-12px">{{desc1}}</p>
                <div class="frame-299">
                  <div class="address inter-normal-gunsmoke-12px">{{downloads1}} Downloads</div>
                  <div class="download">
                    <img src = '../../../assets/download.png'>
                  </div>
                </div>
              </div>
            </div>
            <div class="frame-212">
              <div *ngIf="loading" style="padding-bottom:15px;">
                <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>                 
                <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>                 
                <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>  
              <img class="frame-210">
              <div class="frame-209">
                <div class="kimberley h4">{{title1}}</div>
                <p class="text-1 inter-normal-mine-shaft-12px">{{desc1}}</p>
                <div class="frame-299">
                  <div class="address inter-normal-gunsmoke-12px">{{downloads1}} Downloads</div>
                  <div class="download">
                    <img src = '../../../assets/download.png'>
                  </div>
                </div>
              </div>
            </div>
            <div class="frame-212">
              <div *ngIf="loading" style="padding-bottom:15px;">
                <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>                 
                <div class="text-center" style="display: inline-block;color: var(--blizzard-blue);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>                 
                <div class="text-center" style="display: inline-block;color: var(--australian-mint);">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>  
              <img class="frame-210">
              <div class="frame-209">
                <div class="kimberley h4">{{title1}}</div>
                <p class="text-1 inter-normal-mine-shaft-12px">{{desc1}}</p>
                <div class="frame-299">
                  <div class="address inter-normal-gunsmoke-12px">{{downloads1}} Downloads</div>
                  <div class="download">
                    <img src = '../../../assets/download.png'>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="frame-310">
          <div class="overlap-group3">
            <div class="group-208">
              <img src="../../../assets/EarthCap.png">
            </div>
          </div>
        </div>
        <div class="group-296">
          <h1 class="title worksans-semi-bold-abbey-32px">Resource Center</h1>
          <div class="rectangle-60"></div>
          <p class="text-17 body">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.
          </p>
        </div>
      </div>
      <div class="uploaded">
        <div class="overlap-group10">
          <div class="group-228">
            <div class="overlap-group1-6">
              <div class="personal tab-bold">Personal</div>
              <div class="shared body"></div>
              <div class="rectangle-36"></div>
            </div>
          </div>
          <div class="frame-307">
            <div id = 'myF' class="dropdown-content" style=" background-color: #D8D8D8;border: 5px solid rgb(230,230,230);display: none;">
              <div style="width: 417px;height:735px;background-color: rgb(230,230,230);overflow-y: auto;text-align: center;margin:0 auto;">
                  <div>
                      <button (click) = 'close()' style="margin-top:10px;margin-left:10px;background-color: #EA6F66;border-radius: 25px;color:rgb(230,230,230);display: block;">Close</button>
                      <div class="dropdown2">
                          <li class = "options2 time" style="padding: 10px;">{{topics}}</li>
                          <div class="dropdown-content2">
                              <div *ngFor = "let sesh of topicsList">
                                  <button 
                                  type="button"
                                  style = "text-align: center; margin-top: 3px;margin-bottom: 3px;"
                                  class="time checkbox btn btn-secondary" 
                                  (click)="setTopic(sesh)"
                                  >
                                  {{sesh}}
                                  </button>     
                              </div>            
                          </div>
                      </div>
                      <div class="dropdown2">
                          <li class = "options2 time" style="padding: 10px;">{{subTopics}}</li>
                          <div class="dropdown-content2">
                              <div *ngFor = "let sesh of subtopicsList">
                                  <button 
                                  type="button"
                                  style = "text-align: center; margin-top: 3px;margin-bottom: 3px;"
                                  class="time checkbox btn btn-secondary" 
                                  (click)="setSubTopic(sesh)"
                                  >
                                  {{sesh}}
                                  </button>     
                              </div>            
                          </div>
                      </div>
                  </div>
                  <div>
                      
                      <input style ='background-color: #D8D8D8;border: 5px solid rgb(230,230,230);height:40px;font-size: 16px;' type="text"placeholder = 'Title*'class="form-control" ngModel name="fn3" #fn3 ="ngModel"(change) = "title = fn3.value" id = "title" >
                  </div>
                  <div>
                      <textarea style ='background-color: #D8D8D8;border: 5px solid rgb(230,230,230);min-height: 540px;max-height: 590px;font-size: 16px;'placeholder='Description'class="form-control" ngModel name="fn33" #fn33 ="ngModel"(change) = "description = fn33.value" id = "description"></textarea>
                  </div>
                  
                  <div>
                      <button style="background-color: #EA6F66;color:rgb(230,230,230);font-size: 16px;" (click) = 'publish()'>Share</button>
                  </div>
                  <div *ngFor = "let err of error" >
                      <p>{{err}}</p>
                  </div>
              </div>   
          </div>
            <app-stored-file *ngFor = "let info of MyFiles | async"  id = '{{info}}'[AUXIN] = "info" [TUID] = "meetingID" (deleteMe) = 'deleteMe3($event)'(shareStorage)="shareStorage($event)">
            </app-stored-file>
          </div>
          <input  required type="file" class="custom-file-input" (change) = "upload($event)" accept = "*.png.jpg.pdf"id="inputGroupFile01" style="display: none;">
          <div class="frame-267"(click) = 'uploadPersonal()'>
            <div class="download-2">
              <img src = '../../../assets/download.png'>
            </div>
            <div class="browse-files worksans-semi-bold-chathams-blue-14px">Browse Files</div>
          </div>
        </div>
      </div>
    </div>
    <div class="group-274">
      <div class="address-1 inter-normal-white-16px">
        <span class="inter-normal-gunsmoke-16px">1</span><span class="inter-normal-chathams-blue-16px">&nbsp;</span
        ><span class="span2 inter-normal-chathams-blue-16px">2</span><span class="inter-normal-gunsmoke-16px"> 3</span>
      </div>
    </div>
  </div>
</div>
