import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
   
import {AngularFireModule} from "@angular/fire";
import {AngularFireStorageModule} from '@angular/fire/storage'
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from './../environments/environment'  
import { AngularFireFunctionsModule,REGION } from '@angular/fire/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
  
         
import { AngularResizedEventModule } from 'angular-resize-event';   
              
import { AppRoutingModule } from './app-routing.module'; 
import { AppComponent } from './app.component';
import { BookingComponent } from '../app/booking/booking.component';
import{ HeaderComponent } from '../app/components/header/header.component';
import { FooterComponent } from './components/footer/footer.component'; 
import { TeacherItemComponent } from './components/teacher-item/teacher-item.component';
import { HttpClientModule } from '@angular/common/http';
import { TeacherProfileComponent } from './components/pages/teacher-profile/teacher-profile.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeacherFormComponent } from './components/teacher-form/teacher-form.component';
import { TeacherPageComponent } from './components/teacher-page/teacher-page.component';
import { ListingItemComponent } from './components/listing-item/listing-item.component';
import { MeetTeachersComponent } from './components/meet-teachers/meet-teachers.component';
import { CommentHeaderComponent } from './components/comment-header/comment-header.component';
import { CommentBarComponent } from './components/comment-bar/comment-bar.component';
import { CommentItemComponent } from './components/comment-item/comment-item.component';
import { CreateCommentComponent } from './components/create-comment/create-comment.component';
import { TeacherBlogComponent } from './components/teacher-blog/teacher-blog.component';
import { UpdateTeacherInfoComponent } from './components/update-teacher-info/update-teacher-info.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import { SchedulerComponent } from './components/scheduler/scheduler.component';
import { ManageListingComponent } from './components/manage-listing/manage-listing.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AppointmentsComponent } from './components/appointments/appointments.component';
import { TimesItemComponent } from './components/times-item/times-item.component';
import { AddToCartComponent } from './components/add-to-cart/add-to-cart.component';
import { CartComponent } from './components/cart/cart.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { BookingItemComponent } from './components/booking-item/booking-item.component';
import { TeacherDisplayComponent } from './components/teacher-display/teacher-display.component';
import { SuccessComponent } from './components/success/success.component';
import { AcComponent } from './components/ac/ac.component';
import { TeacherSuccessComponent } from './components/teacher-success/teacher-success.component';
import { CollabComponent } from './components/collab/collab.component';
import { ParentZoneComponent } from './components/parent-zone/parent-zone.component';
import { ChildItemComponent } from './components/child-item/child-item.component';
import { RequestComponent } from './components/request/request.component';
import { MarketingComponent } from './components/marketing/marketing.component';
import { QuestionHubComponent } from './components/question-hub/question-hub.component';
import { QuestionComponent } from './components/question/question.component';
import { AnswerComponent } from './components/answer/answer.component';
import { SearchComponent } from './components/search/search.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ArticleLinkComponent } from './components/article-link/article-link.component';
import { PostComponent } from './components/post/post.component';
import { CampsComponent } from './components/camps/camps.component';
import { ContentManagerComponent } from './components/content-manager/content-manager.component';
import { VideoComponent } from './components/video/video.component';
import { VideoPageComponent } from './components/video-page/video-page.component';
import { TeacherContentComponent } from './components/teacher-content/teacher-content.component';
import { VerifiedChatComponent } from './components/verified-chat/verified-chat.component';
import { WebcamModule } from 'ngx-webcam';
import { TeacherLandingComponent } from './components/teacher-landing/teacher-landing.component';
import { UserAgreementComponent } from './components/pages/user-agreement/user-agreement.component';
import { FileComponent } from './components/file/file.component';
import { ClassroomComponent } from './components/classroom/classroom.component';
import { StoredFileComponent } from './components/stored-file/stored-file.component';
import { ResourceBuilderComponent } from './components/resource-builder/resource-builder.component';
import { CommunityResourcesComponent } from './components/community-resources/community-resources.component';
import { AmbassadorLandingComponent } from './components/pages/ambassador-landing/ambassador-landing.component';
import { TeacherSocialComponent } from './components/teacher-social/teacher-social.component';
import { StandardSignupComponent } from './components/standard-signup/standard-signup.component';
import { FirebaseService } from './services/firebase.service';
import { EventLandingPageComponent } from './components/event-landing-page/event-landing-page.component';
import { LobbyComponent } from './components/lobby/lobby.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { ClassroomSnapshotComponent } from './components/classroom-snapshot/classroom-snapshot.component';
import { GroupClassComponent } from './components/group-class/group-class.component';
import { VideoDisplayComponent } from './components/video-display/video-display.component';
import { StudentProfileComponent } from './components/student-profile/student-profile.component';
import { ProgressTrackerComponent } from './components/progress-tracker/progress-tracker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StudentProfilePopupComponent } from './components/student-profile-popup/student-profile-popup.component';
import { LessonEditorComponent } from './components/lesson-editor/lesson-editor.component';
import { LessonCreateComponent } from './components/lesson-create/lesson-create.component';
import { StudentProfileWidgetComponent } from './components/student-profile-widget/student-profile-widget.component';
import { CreateStudentAccountComponent } from './components/create-student-account/create-student-account.component';
import { StudentSignUpComponent } from './components/student-sign-up/student-sign-up.component';
import { StudentProfileHolderComponent } from './components/student-profile-holder/student-profile-holder.component';
import { TimeReservePopupComponent } from './components/time-reserve-popup/time-reserve-popup.component';
import { CartEditPopupComponent } from './components/cart-edit-popup/cart-edit-popup.component';
import { TeacherScheduleCreatorComponent } from './components/teacher-schedule-creator/teacher-schedule-creator.component';
import { SignupScheduleMakerComponent } from './components/signup-schedule-maker/signup-schedule-maker.component';
import { TeacherStudentProfileWidgetComponent } from './components/teacher-student-profile-widget/teacher-student-profile-widget.component';
import { CalendarPopUpComponent } from './components/calendar-pop-up/calendar-pop-up.component';
import { ProfileUpcomingAppointmentComponent } from './components/profile-upcoming-appointment/profile-upcoming-appointment.component';
import { OrgLandingPageComponent } from './components/org-landing-page/org-landing-page.component';
  
@NgModule({
  declarations: [
    AppComponent,
    BookingComponent,
    HeaderComponent,
    FooterComponent,
    TeacherItemComponent,
    TeacherProfileComponent,
    AboutComponent,
    TeacherFormComponent,
    TeacherPageComponent,
    ListingItemComponent,
    MeetTeachersComponent,
    CommentHeaderComponent,
    CommentBarComponent,
    CommentItemComponent,
    CreateCommentComponent,
    TeacherBlogComponent,
    UpdateTeacherInfoComponent,
    SchedulerComponent,
    ManageListingComponent,
    UserProfileComponent,
    AppointmentsComponent,
    TimesItemComponent,
    AddToCartComponent,
    CartComponent,
    BookingsComponent,
    BookingItemComponent,
    TeacherDisplayComponent,
    SuccessComponent,
    AcComponent,
    TeacherSuccessComponent,
    CollabComponent,
    ParentZoneComponent,
    ChildItemComponent,
    RequestComponent,
    MarketingComponent,
    QuestionHubComponent,
    QuestionComponent,
    AnswerComponent,
    SearchComponent,
    PrivacyComponent,
    ArticleLinkComponent,
    PostComponent,
    CampsComponent,
    ContentManagerComponent,
    VideoComponent,
    VideoPageComponent,
    TeacherContentComponent,
    VerifiedChatComponent,
    TeacherLandingComponent,  
    UserAgreementComponent,
    FileComponent,
    ClassroomComponent,
    StoredFileComponent,
    ResourceBuilderComponent,
    CommunityResourcesComponent, 
    AmbassadorLandingComponent,
    TeacherSocialComponent,
    StandardSignupComponent,
    EventLandingPageComponent,
    LobbyComponent,
    FileUploaderComponent,
    ClassroomSnapshotComponent,
    GroupClassComponent,
    VideoDisplayComponent,
    StudentProfileComponent,
    ProgressTrackerComponent,
    StudentProfilePopupComponent,
    LessonEditorComponent,
    LessonCreateComponent,
    StudentProfileWidgetComponent,
    CreateStudentAccountComponent,
    StudentSignUpComponent,
    StudentProfileHolderComponent,
    TimeReservePopupComponent,
    CartEditPopupComponent,
    TeacherScheduleCreatorComponent,
    SignupScheduleMakerComponent,
    TeacherStudentProfileWidgetComponent,
    CalendarPopUpComponent,
    ProfileUpcomingAppointmentComponent,
    OrgLandingPageComponent,
  ],   
  imports: [   
    BrowserModule,
    AppRoutingModule,   
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AngularResizedEventModule,
    WebcamModule,
    MatChipsModule,  
    MatFormFieldModule,
    MatIconModule,
    FontAwesomeModule,
    ShareButtonsModule,
    ShareIconsModule,
        
  ],
  providers: [{provide: REGION, useValue: "us-central1"},FirebaseService],
  bootstrap: [AppComponent]    
})
export class AppModule { }
