import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { auth } from 'firebase';
import {AngularFireFunctions} from '@angular/fire/functions'
import { AngularFireDatabase } from '@angular/fire/database';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {
  @Input() times;
  @Input() teacher;
  @Input() grade;    
  @Input() service;
  @Input() parentUID;
  @Input() parentEmail; 
  @Input() teacherUID;
  @Input() teacherEmail;
  @Input() agreed = '';
  @Input() UID = ''
  @Input() teacherRate;
  @Input() teacherOffsets;
  @Input() ttz
  @Input() offeredRate
  @Input() numSessions
  @Input() agreedRate = ''

  flex = false


  influencerCode = ''
  teacherErr = []

  @Output() deleteMe = new EventEmitter<any>();

  logging = false;
  stripe: Stripe;
  finalRate = 0;

  chosenTimes = 0
  acceptedTimes = ''
 
  chosenDate = 'Pick A Date'
  securedSent = false
  paySent = false
  validDays = []
  acceptedDate = ''   
  displayName = ''
  constructor(private afm: AngularFireStorage,private fns:AngularFireFunctions, private db: AngularFireDatabase,private rerouter: Router) { }

  ngOnInit(): void {
    this.times.split('|').forEach(val=>{
      var month:String = val.split('-')[1]
      var days:String = val.split('-')[2]
      if(month.length  === 1){
        month = '0' + month
      }
      if(days.length  === 1){
        days = '0' + days
      }
      var splitHold = val.split('-')
      val = splitHold[0] + '-' + month + '-' + days + '-' + splitHold[3] + '-' + splitHold[4]
      this.validDays.push(val)
    })
    if(this.agreed !== ''){
      this.chosenDate = this.dateFormat(this.agreed.split('-')[0] + '-' + this.agreed.split('-')[1] + '-' + this.agreed.split('-')[2]) 
      this.chosenDate = this.agreed
      // this.validDays = []
    }
    if(this.teacher){
      var nameHolder = ''
      this.db.database.ref('users/' + this.parentUID + '/userInfo/name/val').once('value').then(function(snaper){
        nameHolder = snaper.val()
      }).then(()=>{
        this.displayName = nameHolder
        //get profile picture 
        setTimeout(()=>{this.getProPic(this.parentUID)})
      })
    }else{
      var nameHolder = ''
      this.db.database.ref('providers/userInfo/' + this.teacherUID + '/immutable/name/val').once('value').then(function(snaper){
        nameHolder = snaper.val()
      }).then(()=>{
        this.displayName = nameHolder
        //get profile picture 
        setTimeout(()=>{this.getProPic(this.teacherUID)})
      })
    }
  }

  timesForDay(day){
    this.chosenDate = day
  }

  async getProPic(UID){
    var QUID = this.UID
    // Create a reference from a Google Cloud Storage URI
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/teacherPics/' + UID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById('proPic' + QUID).setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      var key = ''
      var randomNum = Math.random()
      console.log(randomNum)
      if(Number(randomNum) === 1){
        randomNum =.98
      }
      var randomNumber = Math.floor(randomNum * 7) 
      if(randomNumber === 0){
        randomNumber = 1
      }
       
      if(randomNumber === 1){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png'
      }else if(randomNumber === 2){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-48@2x.png'
      }else if(randomNumber === 3){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-49@2x.png'
      }else if(randomNumber === 4){
        key = '../../../assets/Giraf.png'
      }else if(randomNumber === 5){
        key = '../../../assets/Cat.png'
      }else if(randomNumber === 6){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-50@2x.png'
      }
      document.getElementById('proPic'+ QUID).setAttribute('src',`${key}`);
      // document.getElementById(`${teacherHold.UID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }

   getDayy(month){
    month = Number(month)
    if(month === 1){
      return "mon"  
    }else if(month === 2){
      return "tue"
    }else if(month === 3){
      return "wed"
    }else if(month === 4){
      return "thu"
    }else if(month === 5){   
      return "fri"
    }else if(month === 6){
      return "sat"
    }else if(month === 0){
      return "sun"
    }
   }


   button2(){

   }
   
   checkInf(){
     var flexHold = false
     var influencerCode = this.influencerCode
     this.db.database.ref('influencerCodes').once('value').then(function(snap:any){
      snap.forEach(function(snapp){
        if(snapp.key === influencerCode){
          flexHold = true
        }
      })
     }).then(()=>{
      this.flex = flexHold
     })
   }

   selectTime(time){
     if(this.acceptedTimes.includes(time)){
        this.chosenTimes -= 1
        // document.getElementById(time).style.background = '#868e96'
        var locate = this.acceptedTimes.split("|")
        if(locate.length === 1){
          this.acceptedTimes = ''
        }else if(locate[0] === time){
          this.acceptedTimes = this.acceptedTimes.replace(time + "|","")
        }else{
          this.acceptedTimes = this.acceptedTimes.replace("|" + time ,"")
        }
     }else{
       if(this.numSessions > this.chosenTimes){
         this.chosenTimes += 1
        //  document.getElementById(time).style.background = '#EA6F66'
         if(this.acceptedTimes === ''){
           this.acceptedTimes = time
         }else{
           this.acceptedTimes += '|' + `${time}`
         }
       }else{
         //can only select so many
         this.teacherErr = ['You Already Chose ' + `${this.chosenTimes}` + " Times"]
       }
     }
   }

   teacherOffset(val){
    if (val === 'Pick A Date'){
      return 'Pick A Date'
    }
    var timeDiff:number = Number(this.teacherOffsets) /100
    if(val.split(' ').length === 3){
      var teacherDate = val.split(' ')[2].split("-")
      var teacherDate2 = val.split(' ')[1].split("-")
      var newStartTime = 0
      var newEndTime = 0
      var holdStart = teacherDate[2]
      var holdEnd = teacherDate[3]

      newStartTime = Number(holdStart) + (Number(timeDiff)*100)
      newEndTime = Number(holdEnd) + (Number(timeDiff)*100)
      return  teacherDate2[0] + "-" + teacherDate2[1]  + "-"+ teacherDate2[2] + "-"+newStartTime +'-'+ newEndTime 
    }else{
      var date = ''
      var time1 
      var time2
      date = val.split('-')[0] + "-" + val.split('-')[1] + "-" + val.split('-')[2]
      time1 = Number(val.split('-')[3]) + (Number(timeDiff)*100)
      time2 = Number(val.split('-')[4]) + (Number(timeDiff)*100)
      return date + "-" + time1 + "-" + time2
    }
   }

  secureTime(){
    var UIDHold = this.UID
    var finalRate = this.finalRate
    if(this.acceptedTimes !== ''){
      if(this.finalRate !== 0){
        this.agreedRate = String(finalRate)
        this.securedSent = true
        this.agreed = this.acceptedTimes
        var chosen = this.acceptedTimes
        var teacherUID = this.teacherUID
        const secureTime = this.fns.httpsCallable('secureTime')
        secureTime({
          ParentUID:this.parentUID,
          TeacherUID:auth().currentUser.uid,
          requestedTimes: this.times,
          securedTime: this.acceptedTimes,
          ParentEmail: this.parentEmail,
          finalRate:this.finalRate
        }).toPromise().then(()=>{
          this.db.database.ref('providers/userInfo/' + `${teacherUID}`+ '/requests').once('value').then(function(snap:any){
            snap.forEach(function(snapp:any){
              if(snapp.child('UID').child('val').val() === UIDHold){
                snapp.child('agreed').ref.set({val:chosen})
                snapp.child('total').ref.set({val:finalRate})
              }
            })
          })
    
        })
      }else{
        //need a rate
        this.teacherErr = ['You need a rate']
      }
    }else{
      //need atleast 1 time
      this.teacherErr = ['Select a Date']
    }

  }

  async delete(){
    var UIDHold = this.UID
    var dbHold = this.db
    var teacherUID = this.teacherUID
    var parentUID = this.parentUID
    var teacherEmail = this.teacherEmail
    var parentEmail = this.parentEmail
    const deleteRequest = this.fns.httpsCallable('deleteRequest2')
    if(this.teacher){
      await this.db.database.ref('providers/userInfo/' + `${teacherUID}`+ '/requests').once('value').then(function(snap:any){
        snap.forEach(function(snapp:any){
          
          if(snapp.child('UID').child('val').val() === UIDHold){
            dbHold.database.ref('providers/userInfo/'  + `${teacherUID}`+ '/requests/'+ `${snapp.key}`).remove()
          }
        })
      }).then(()=>{
        deleteRequest({
          UID:parentUID,
          RequestUID:UIDHold,
          Type: 'p'
        }).toPromise().then(()=>{
          console.log('succ')
        })
      })
    }else{
      await this.db.database.ref('/users/' + `${parentUID}`+ '/requests').once('value').then(function(snap:any){
        snap.forEach(function(snapp:any){
          if(snapp.child('UID').child('val').val() === UIDHold){
            dbHold.database.ref('/users/' + `${parentUID}`+ '/requests/'+ `${snapp.key}`).remove()
          }
        })
      }).then(()=>{
        deleteRequest({
          UID:teacherUID,
          RequestUID:UIDHold,
          Type: 't'
        }).toPromise().then(()=>{
          console.log('succ')
        })
      })
    }
    this.deleteMe.emit(this.UID)
  }

  setFinalRate(rate){
    this.finalRate = rate
  }

  formatAccepted(agreed:string){
    var holdVar = ''
    agreed.split('|').forEach(val=>{
      if(holdVar === ''){
        holdVar = this.specialDate(val)
      }else{
        holdVar += ", " + this.specialDate(val)
      }
    })
    return holdVar
  }

  formatAcceptedT(agreed:string){
    var holdVar = ''
    agreed.split('|').forEach(val=>{
      if(holdVar === ''){
        holdVar = this.teacherDate(val)
      }else{
        holdVar += ", " + this.teacherDate(val)
      }
    })
    return holdVar
  }


  async flexer(){
    if(auth().currentUser){
      var codeHold = this.influencerCode
      this.paySent = true
      var ttzHold = this.ttz
      var tal = 0.0
      var dates = ''

      await this.agreed.split('|').forEach(val=>{
        if(dates === ''){
          var holdDate = new Date(val.split('-')[0] + '-'+val.split('-')[1]+ '-'+ val.split('-')[2])
          var date = val.split('-')[1] + '-' + val.split('-')[2] + '-' + val.split('-')[0] 
          dates = this.getDay2(holdDate.getDay()) +' '+ date +  " " + this.formatTime(Number(val.split('-')[3])) + '-' + this.formatTime(Number(val.split('-')[4])) + '-' + val.split('-')[3] + '-' + val.split('-')[4]
        }else{
          var holdDate = new Date(val.split('-')[0] + '-'+val.split('-')[1]+ '-'+ val.split('-')[2])
          var date =  val.split('-')[1] + '-' + val.split('-')[2] + '-' + val.split('-')[0]
          dates += "|"+ this.getDay2(holdDate.getDay()) +' '+ date + " " + this.formatTime(Number(val.split('-')[3])) + '-' + this.formatTime(Number(val.split('-')[4])) + '-' + val.split('-')[3] + '-' + val.split('-')[4]
        }
        var curr = Number(val.split('-')[3])
        var endHold = Number(val.split('-')[4])
        while(curr <= endHold){
          if(curr%100 ===5){
            curr = curr - 5
          }
          if(Math.floor(curr%100) < 60){
            tal += .25
          }
          curr = curr + 15
        }
        if(curr%100 === 5){
          curr = curr - 5
            tal += .25
        }
        tal -= .25
      })

      var cost = Math.floor(((tal * Number(this.agreedRate)) * 1.095) * 100) / 100
      await this.delete()
      this.deleteMe.emit(this.UID)
      this.stripe = await loadStripe(environment.stripeP);
      const flexer = await this.fns.httpsCallable('flexer')
      await flexer({
        dateTime:'',
        appointmentTimes1: `${dates}`,   
        teacherName: 'name',
        services: this.service,
        teacherEmail: this.teacherEmail,
        parentEmail: this.parentEmail,
        teacherUID: this.teacherUID,
        parentUID: this.parentUID,
        timeZone: (new Date().getTimezoneOffset()/60),
        timeZone2:ttzHold,
        requestType:`${this.UID}`,
        total:cost
      }).toPromise().then(()=>{
        const deleteCode = this.fns.httpsCallable('deleteCode')
        deleteCode({
          code:codeHold
        }).toPromise()
      }).then(()=>{
        this.logging = false
      })
    }
  }

  async pay(){
    if(auth().currentUser){
      this.paySent = true
      var ttzHold = this.ttz
      var tal = 0.0
      var dates = ''
      await this.agreed.split('|').forEach(val=>{
        if(dates === ''){
          var holdDate = new Date(val.split('-')[0] + '-'+val.split('-')[1]+ '-'+ val.split('-')[2])
          var date = val.split('-')[1] + '-' + val.split('-')[2] + '-' + val.split('-')[0] 
          dates = this.getDay2(holdDate.getDay()) +' '+ date +  " " + this.formatTime(Number(val.split('-')[3])) + '-' + this.formatTime(Number(val.split('-')[4])) + '-' + val.split('-')[3] + '-' + val.split('-')[4]
        }else{
          var holdDate = new Date(val.split('-')[0] + '-'+val.split('-')[1]+ '-'+ val.split('-')[2])
          var date =  val.split('-')[1] + '-' + val.split('-')[2] + '-' + val.split('-')[0]
          dates += "|"+ this.getDay2(holdDate.getDay()) +' '+ date + " " + this.formatTime(Number(val.split('-')[3])) + '-' + this.formatTime(Number(val.split('-')[4])) + '-' + val.split('-')[3] + '-' + val.split('-')[4]
        }
        var curr = Number(val.split('-')[3])
        var endHold = Number(val.split('-')[4])
        while(curr <= endHold){
          if(curr%100 ===5){
            curr = curr - 5
          }
          if(Math.floor(curr%100) < 60){
            tal += .25
          }
          curr = curr + 15
        }
        if(curr%100 === 5){
          curr = curr - 5
          tal += .25
        }
        tal -= .25
      })
      var cost = Math.floor(((tal * Number(this.agreedRate)) * 1.095) * 100) / 100

      this.stripe = await loadStripe(environment.stripeP);
      const stripeCheckout = await this.fns.httpsCallable('stripeCheckout')
      await stripeCheckout({
        dateTime:'',
        appointmentTimes1: `${dates}`,
        appointmentTimes2: '',
        teacherName: 'name',
        services: 'Tutoring',
        teacherEmail: this.teacherEmail,
        parentEmail: this.parentEmail,
        teacherUID: this.teacherUID,
        parentUID: this.parentUID,
        product_name: 'appointment',
        quantity:1, 
        unit_amount: cost,
        timeZone: (new Date().getTimezoneOffset()/60),
        timeZone2:ttzHold,
        requestType:`${this.UID}`
      }).toPromise().then((sessionId:string)=> this.stripe.redirectToCheckout({sessionId})
      .then(function(va){} )).catch((e)=> console.log('err',e)).finally(()=> {
        this.logging = false
      }).finally(()=>{
        this.paySent = false
      })
    }
  }

  specialDate2(val){
    if (val === 'Pick A Date'){
      return 'Pick A Date'
    }
    

  }

  teacherDate2(val:string,TYPE){
    if (val === 'Pick A Date'){
      return 'Pick A Date'
    }
    if(val.includes('ASAP')){
      this.numSessions = 1
      return 'ASAP'
    }
    var date = ''
    var time1 
    var time2
    var count = 1
    if(val.split(' ').length !== 3){
      val.split('-').forEach(val=>{
        if(count < 3){
          date += val + '-'
        }else if(count === 3){
          date += val
        }else if(count === 4){
          time1 = Number(val) + this.teacherOffsets
        }else{
          time2 = Number(val) + this.teacherOffsets
        }
        count += 1
      })
    }else{
      date = val.split(' ')[1].split('-')[2] + "-" + val.split(' ')[1].split('-')[0] + "-" + val.split(' ')[1].split('-')[1]
      time1 = val.split(' ')[2].split('-')[2]
      time2 = val.split(' ')[2].split('-')[3]
    }
    if(TYPE === 1){
      return this.dateFormat(date)
    }else{
      return this.formatTime(time1) + "-" + this.formatTime(time2)
    }

  }

  teacherDate(val:string){
    if (val === 'Pick A Date'){
      return 'Pick A Date'
    }
    if(val.includes('ASAP')){
      this.numSessions = 1
      return 'ASAP'
    }
    var date = ''
    var time1 
    var time2
    var count = 1
    if(val.split(' ').length !== 3){
      val.split('-').forEach(val=>{
        if(count < 3){
          date += val + '-'
        }else if(count === 3){
          date += val
        }else if(count === 4){
          time1 = Number(val) + this.teacherOffsets
        }else{
          time2 = Number(val) + this.teacherOffsets
        }
        count += 1
      })
    }else{
      date = val.split(' ')[1].split('-')[2] + "-" + val.split(' ')[1].split('-')[0] + "-" + val.split(' ')[1].split('-')[1]
      time1 = val.split(' ')[2].split('-')[2]
      time2 = val.split(' ')[2].split('-')[3]
    }
    
    return this.dateFormat(date) + " " + this.formatTime(time1) + "-" + this.formatTime(time2)

  }

  specialDate(val:string){
    if (val === 'Pick A Date'){
      return 'Pick A Date'
    }
    if(val.includes('ASAP')){
      this.numSessions = 1
      return 'ASAP'
    }
    var date = ''
    var time1 
    var time2
    var count = 1
    if(val.split(' ').length !== 3){
      val.split('-').forEach(val=>{
        if(count < 3){
          date += val + '-'
        }else if(count === 3){
          date += val
        }else if(count === 4){
          time1 = Number(val) 
        }else{
          time2 = Number(val)
        }
        count += 1
      })
    }else{
      date = val.split(' ')[1].split('-')[2] + "-" + val.split(' ')[1].split('-')[0] + "-" + val.split(' ')[1].split('-')[1]
      time1 = val.split(' ')[2].split('-')[2]
      time2 = val.split(' ')[2].split('-')[3]
    }
    
    return this.dateFormat(date) + " " + this.formatTime(time1) + "-" + this.formatTime(time2)

  }
  dateFormat(day){
    var hold = day.split("-")
    var hold2 = day
    var day = hold[2]
    var month = hold[1]
    var nn = new Date(`${hold2}`)

    return this.getDay(nn.getDay())+ " " + this.getMonth(month) + " " +  day 
  }
  getDay(month){
    month = Number(month)
    if(month === 0){
      return "Mon"
    }else if(month === 1){
      return "Tue"
    }else if(month === 2){
      return "Wed"
    }else if(month === 3){
      return "Thu"
    }else if(month === 4){
      return "Fri"
    }else if(month === 5){
      return "Sat"
    }else if(month === 6){
      return "Sun"
    }
  }

  getDay2(month){
    month = Number(month)
    if(month === 0){
      return "mon"
    }else if(month === 1){
      return "tue"
    }else if(month === 2){
      return "wed"
    }else if(month === 3){
      return "thu"
    }else if(month === 4){
      return "fri"
    }else if(month === 5){
      return "sat"
    }else if(month === 6){
      return "sun"
    }
  }

  getMonth(month){
    month = Number(month)
    if(month === 1){
      return "Jan"
    }else if(month === 2){
      return "Feb"
    }else if(month === 3){
      return "Mar"
    }else if(month === 4){
      return "Apr"
    }else if(month === 5){
      return "May"
    }else if(month === 6){
      return "June"
    }else if(month === 7){
      return "July"
    }else if(month === 8){
      return "Aug"
    }else if(month === 9){  
      return "Sep"
    }else if(month === 10){
      return "Oct"
    }else if(month === 11){
      return "Nov"
    }else{
      return "Dec"  
    }
  }
  
  formatTime(num:number):string{
    if(num < 1200){
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ "am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + "am"
        }      
      } 
    }else{
      var holdVar = Math.floor(num/100)-12
      if(holdVar === 0){
        holdVar = 12
      }
      if(num%100 === 0){
        return String(holdVar)+ "pm"
      }else{ 
        return String(holdVar)+":"+String(num%100) + "pm"
      }       

    }
  }
}
