import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-camps',
  templateUrl: './camps.component.html',
  styleUrls: ['./camps.component.scss']
})
export class CampsComponent implements OnInit {
  youtubeCodeCamp = "empty";
  campTitle = ''
  campSubTitle = '' 
  campImagePath = ''
  campArticle = ''
  campurl: string | ArrayBuffer = '';
  @Output() paragraphsCamp1 = new EventEmitter<any[]>();
  @Output() paragraphsCamp2 = new EventEmitter<any[]>();

  nLMsg = []
  emailNL = ''
  sentNL = false
  
  constructor(private router:ActivatedRoute, private db: AngularFireDatabase,private afm: AngularFireStorage,public auth: AuthService,private fns:AngularFireFunctions) { }

  async ngOnInit(){
    var UID = this.router.snapshot.params.UID
    await this.db.database.ref('camps/' + UID).once('value').then((snap)=>{
      this.campTitle = snap.child('title').val()
      this.campSubTitle= snap.child('subTitle').val()
      var val = snap.child('article').val()
      this.campArticle = val
      var halfWay = Math.ceil(val.split('||').length /2)
      console.log(halfWay)
      var count = 1
      var hold1 = []
      var hold2 = []
      val.split('||').forEach(val2=>{
        console.log(count, halfWay)
        console.log(val2)
        
        if(count <= halfWay){
          console.log(1)
          hold1.push(val2)  
        }else{
          console.log(2)
          hold2.push(val2)
        }
        count += 1
      })
      console.log(hold1,hold2)
      this.paragraphsCamp1.emit(hold1)
      this.paragraphsCamp2.emit(hold2)
    })
  }
  async signUpNL(){
    this.sentNL = true
    var emailHold = this.emailNL
    var titlelHold = this.campTitle
    if(this.emailNL !== ''){
      var addr = 'parker@verifiedteachers.com'
      var sub = 'Campgain Interest'
      const newEmail = this.fns.httpsCallable('sendMail')
      await newEmail({
        address: addr,
        subject: sub,
        msg:`${emailHold}` + '|||' + `${titlelHold}`
      }).toPromise().then(()=> {
        console.log("success")
        this.nLMsg = ['Thanks for signing up!']
        
      }).catch((e)=> {
        console.log('400',e)
        this.nLMsg = ['Something went wrong, please try again.']
        this.sentNL = false
      })
    }else{
      this.nLMsg = ['Only an Email is Necessary']
    }
  }

}
