export class answer {
    QUID
    TUID
    answer 
    timeStamp

    constructor(QUID,TUID,answer,timeStamp){
        this.QUID = QUID
        this.TUID = TUID
        this.answer = answer
        this.timeStamp = timeStamp
    }
  }