<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
<div style = "margin: 0 auto;text-align: center;font-family: 'Avenir Light';">
<form style = "margin: 0 auto;">
    <h1 style = "font-family: 'Avenir Light';max-width: 1300px;font-size: 23px; padding: 5px;margin-bottom: 0px; text-align: center; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #292F45;color: white;margin: 0 auto;">
      Sign Up to be a Verified Teacher Today!
    </h1>
    <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 1300px;text-align: center;margin: 0 auto;background-color: #D8D8D8;min-height:150px;border: 5px solid rgb(230,230,230);padding:15px;">
      <div style="display: inline-block;max-width:470px;text-align: left;vertical-align: top;padding-right: 30px;margin-right: 30px;color: #292F45;">
        <p style="font-size: 23px;line-height: 1;"><b>Welcome to the verified network </b></p>
        <p style="font-size: 16px;line-height: 1;"><b>Our platform is designed to give teachers free access to the tools they need to run a successful tutoring business. Here’s what it means to be a Verified Teacher:</b></p>
  
        <p style="font-size: 21px;line-height: 1;"><b>1. Access to the Verified Network</b></p>
        <p style="font-size: 16px;line-height: 1;">• When you become Verified, your profile is immediately added to the Verified Marketplace, where the thousands of students who use Verified Teachers to connect with experts can find it. </p>
        <p style="font-size: 16px;line-height: 1;">• Increase your profile’s visibility by sharing content directly on the Verified platform.  Teachers whose profiles share material that reflects their educational philosophy book 80% more than teachers who don’t.</p>
        <p style="font-size: 16px;line-height: 1;">• Set your hours, rates, and preferred subjects and let us do the rest. We’re here to help you show off, and we’ll make sure that your profile gets seen by the right students.</p>
        
        <p style="font-size: 21px;line-height: 1;margin-top: 30px;"><b>2. Fast and straightforward payments</b></p>
        <p style="font-size: 16px;line-height: 1;">• Verified Teachers enforces the booking policies you set with students and guarantees payment within 7 days of a session. We make sure that you get what you’re owed, when you’re owed it. </p>
        <p style="font-size: 16px;line-height: 1;">• Verified Teachers earn three times as much as teachers on other platforms. 20% of each booking helps us keep the site running - the rest is for you.</p>
        <p style="font-size: 16px;line-height: 1;">•  Teachers are team members at Verified, not customers. Our policies put teachers - and their incomes - first.</p>
        
        <p style="font-size: 21px;line-height: 1;margin-top: 30px;"><b>3. Free access to the Verified Teacher Toolkit</b></p>
        <p style="font-size: 16px;line-height: 1;">• The Verified Classroom is designed for teachers to be able to craft engaging, interactive learning spaces online. Chat one-on-one with students in a collaborative, easy-to-use workspace.</p>
        <p style="font-size: 16px;line-height: 1;">• Flexible tools let you work cooperatively with students to build activities and lesson plans that work for them.</p>
        <p style="font-size: 16px;line-height: 1;">• Clutter-free interfaces reduce the hassle of distance learning with younger students. The Teacher Toolkit helps you make what’s happening onscreen more interesting than what might be happening offscreen.</p>
        
      </div>
      <div style="display: inline-block;">
        <div class="form-group boxSize" style="margin-top:0px;">
          <input id = 'name' maxLength = "25" minLength = "2" type="text" class="form-control" ngModel name="fn1" #fn1 ="ngModel" (change) = "fun = fn1.value" placeholder="First Name, Middle Initial, and Last Name"autocomplete="off" style="background-color:#F0F0F0;font-size: 18px;" value= '{{name | async}}'>
        </div>
  
        <div class="form-group boxSize">
          <input required maxLength = "20" minLength = "2" ngModel name="fn2" #fn2 ="ngModel" (change) = "lastName = fn2.value"id ="lastName" type="text" class="form-control"placeholder="School Where You Currently Teach"autocomplete="off"style="background-color:#F0F0F0;font-size: 18px;">
        </div>
  
      <div class="form-group boxSize">
        <input required type="email" class="form-control" ngModel name="fn3" #fn3 ="ngModel"style="background-color:#F0F0F0;font-size: 18px;" (change) = "emailS = fn3.value" id = "emailS" placeholder="Your School Email"autocomplete="off">
      </div>
  
      <div class="form-group boxSize">
        <input required type="email" class="form-control" ngModel name="fn4"style="background-color:#F0F0F0;font-size: 18px;" #fn4 ="ngModel" (change) = "emailP = fn4.value" id = "publicEmail" placeholder="Your Personal Email"autocomplete="off">
      </div>
      <div class="form-group boxSize">
        <input required maxLength = "50" minLength = "8" type="password" style="background-color:#F0F0F0; font-size: 18px;" class="form-control" ngModel name="fn5" #fn5 ="ngModel" (change) = "password = fn5.value" placeholder="Use upper and lower case letters with a number and a special symbol"autocomplete="off">
      </div>
      <div class="form-group boxSize">
        <textarea required maxLength = "1100" minLength = "10" type="text" style="background-color:#F0F0F0; font-size: 18px;max-height: 450px;min-height: 400px;" class="form-control" ngModel name="fn6" #fn6 ="ngModel" (change) = "longDesc = fn6.value" placeholder="Tell us about yourself and your teaching career :)"autocomplete="off"></textarea>
      </div>
      <div class="form-group boxSize">
        <label style="text-align: center;font-size:20px;color:#292F45" class = "disp">Profile Picture</label>
        <div class="input-group mb-3"> 
          <!-- <div class="input-group-prepend">
            <span class="input-group-text" style="text-align: center; font-size: 16px;">Upload</span>
          </div> -->
          <div class="custom-file">
            <input  required type="file" class="custom-file-input" (change) = "upload($event)" accept = "*.png.jpg.pdf"id="inputGroupFile01 " style="background-color:#F0F0F0; font-size: 18px;">
            <label style=" font-size: 16px;margin:0 auto;text-align: center;background-color: rgb(230,230,230);" class="custom-file-label" for="inputGroupFile01"></label>
          </div>
        </div>
        <!-- <input required maxLength = "50" minLength = "8" type="password" class="form-control" ngModel name="fn7" #fn7 ="ngModel" (change) = "password = fn7.value" placeholder="use upper and lower case letters with a number and a special symbol"autocomplete="off"> -->
      </div>
      <div *ngIf = 'uploaded'>
        <p>Thanks!</p>
      </div>
      <div class="form-check boxSize">
        <div class="dropdown">
          <div style="margin-top: 15px;width: 600px;">
            <input type="checkbox" (change) = "adjustSwitch()"class="form-check-input" id="exampleCheck">
            <label class="form-check-label">Agree to <a routerLink="../TeacherAgreement" target="_blank">User Agreement</a></label>
          </div>  
        </div>
      </div>
    <div>      
      <div *ngIf="logging">
        <div class="spinner-grow text-success" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>  
    <button *ngIf = "notSent"  class="btn btn-primary boxSize" style="text-align: center; margin-top: 20px;background-color:#292F45;border-color:#292F45;" (click) = storeInfo()>
        Enroll
    </button> 
    <div *ngIf = "falseTry">Every field is required to continue</div> 
    <div *ngIf = "signInDidNotWork">Looks like we had a problem creating an account for that email and pass</div>
      </div>
  </div>  
  </div>
</form>
</div>


