<div class="group-247 border-1px-blue">
    <div class="overlap-group">
      <div class="group-244">
        <div class="overlap-group1"><div class="x1130am-1230pm body">{{date}}</div></div>
      </div>
      <!-- <div class="more-vertical">
        <img
          class="vector"
          src="../../../assets/vector-4@2x.png"
        />
        <img
          class="vector-1"
          src="../../../assets/vector-4@2x.png"
        />
        <img
          class="vector-1"
          src="../../../assets/vector-4@2x.png"
        />
      </div> -->
    </div>
    <div class="frame-234">
      <img
        id = 'proPic{{app[10]}}'
        class="ellipse-26"
        src="../../../assets/ceramic.jpeg"
      />
      <div class="igor-belagorudsky body">{{displayName}}</div>
    </div>
    <div class="group-243">
      <div class="subject-history body">
        <span class="span body">Subject:</span><span class="span body">{{app[3]}}</span>
      </div>
      <div class="rate-15h body"><span class="span body">Total:</span><span class="span body"> ${{app[13]}}/hr</span></div>
    </div>
    <div class="group-245">
      <div class="overlap-group2" style="z-index: 1;cursor: pointer;" routerLink="/VerifiedVideo/{{app[6]}}-{{app[2]}}-{{app[14]}}"><div class="start-meeting body">Enter Session</div></div>
    </div>
  </div>

