<div id = "{{SUID}}" class = 'frame-301'>  
    <div *ngIf = '!isLink && !isSharedFile' style="display:inline-block;vertical-align: top;">
        <img id = "{{ID}}" [src] = '' src = '../../../assets/ceramic.jpeg' style = "width:40px;height:40px; border-radius: 10px;border: 3px solid;border-color:grey;"draggable="false" (dragstart)="false;" class="unselectable">
    </div>
    <div *ngIf = 'isSharedFile' style="display:inline-block;margin:0 auto;text-align:center;vertical-align: top;margin-top: 15px;text-align: left;">
        <img id = "{{ID}}" [src] = '' src = '../../../assets/ceramic.jpeg' style = "width: 230px;height: 230px;border-radius: 25px;border: 3px solid;border-color:grey;"draggable="false" (dragstart)="false;" class="unselectable">
    </div>
    <!-- <div *ngIf = 'isPersonal || isPersonalShared' style="display:inline-block;width:20px">
    </div> -->
    <div style = "    display: inline-block;
                      margin-right: 10px;
                      margin-left: 25px;
                      width: 105px;
                      height: 50px;
                      overflow: hidden;
                      overflow-y: scroll;">
        <p *ngIf = '!isSharedFile && !isPersonalShared' class="worksans-semi-bold-chathams-blue-14px">{{fileName}} - </p>  
        <p *ngIf = '!isSharedFile&& !isPersonalShared'class = 'worksans-semi-bold-chathams-blue-14px'>{{title}}</p>
        <div *ngIf = 'isLink' style = 'width: 120px;word-wrap: break-word;'>
            <a  href= {{Link}} target="_blank">   
                {{Link}}
            </a> 
        </div>    
        <div *ngIf = 'isSharedFile' style="margin-top: 5px;">
            <button    
                type="button"
                style = "text-align: center;width: 60px; margin-top: 3px;margin-bottom: 3px;font-size: 14px;padding: 3px;background-color:#EA6F66"
                class="checkbox btn btn-secondary" 
                (click)="reloader()">
                Save
            </button> 
            <button 
                type="button"
                style = "text-align: center;width: 60px; margin-top: 3px;margin-bottom: 3px;font-size: 14px;padding: 3px;background-color:#EA6F66"
                class="checkbox btn btn-secondary" 
                (click)="reloaderInner()">
                Repost
            </button> 
        </div>
        <p *ngIf = 'isSharedFile' style="font-size: 16px;text-align: left;width: 225px;">{{title2}}</p>  
        <p *ngIf = 'isSharedFile'style="font-size: 14px;text-align: left;max-height:100px;overflow-y:auto;" >
            {{desc}}
        </p>
        <p *ngIf = 'isSharedFile' style="font-size: 14px;text-align: left;">
            {{downloads}} Downloads
        </p>    
        <p *ngIf = 'isPersonalShared' style="font-size: 16px;text-align: left;">{{title2}}</p>  
        <p *ngIf = 'isPersonalShared'style="font-size: 14px;text-align: left;max-height:100px;overflow-y:auto;" >
            {{desc}}
        </p>
        <p *ngIf = 'isPersonalShared' style="font-size: 14px;text-align: left;">
            {{downloads}} Downloads
        </p> 
        <div *ngIf = 'isPersonalShared'>
            <button 
                type="button"
                style = "text-align: center;width: 60px; margin-top: 3px;margin-bottom: 3px;font-size: 14px;padding: 3px;background-color:#EA6F66"
                class="checkbox btn btn-secondary" 
                (click)="deleteFile()">
                Delete
            </button>   
        </div>
        <!-- <p *ngIf = 'isSharedFile' style="font-size: 14px;text-align: left;">
            {{reposts}} Reposts
        </p>     -->
        <!-- <div *ngIf = 'isPersonal' >
            <button 
                
                type="button"
                style = "text-align: center;width: 40px; margin-top: 3px;margin-bottom: 3px;font-size: 14px;padding: 3px;background-color:#EA6F66"
                class="checkbox btn btn-secondary" 
                (click)="share()">
                Share
            </button>
        </div> -->
        <!-- <div *ngIf = 'isPersonal' style="z-index: 1;cursor: pointer;width:20px" (click)="remove()">
            <img
                src="../../../assets/greyX.png"
            />
        </div> -->
        <!-- <div *ngIf = 'isPersonal'>
            <button 
                
                type="button"
                style = "text-align: center;width: 60px; margin-top: 3px;margin-bottom: 3px;font-size: 14px;padding: 3px;background-color:#EA6F66"
                class="checkbox btn btn-secondary" 
                (click)="remove()">
                Remove
            </button>   
        </div> -->
        <div *ngIf = 'isFile'>
            <button 
                type="button"
                style = "text-align: center;width: 60px; margin-top: 3px;margin-bottom: 3px;font-size: 14px;padding: 3px;background-color:#EA6F66"
                class="checkbox btn btn-secondary" 
                (click)="reloader()">
                Open
            </button> 
        </div>

        <!-- <div *ngIf = 'isSharedFile'>
            <button 
                type="button"
                style = "text-align: center;width: 60px; margin-top: 3px;margin-bottom: 3px;font-size: 14px;padding: 3px;background-color:#EA6F66"
                class="checkbox btn btn-secondary" 
                (click)="reloaderInner()">
                Repost
            </button> 
        </div> -->
    </div>
    <div style="display:inline-block;margin-top: 10px;vertical-align: top">
        <div *ngIf = 'isPersonalDoc' style="z-index: 1;cursor: pointer;display:inline-block;"(click) = 'share()'>
            <img
            src="../../../assets/plus.svg"
          />
        </div>
        <div *ngIf = 'isPersonal' style="z-index: 1;cursor: pointer;display:inline-block;"(click) = 'plusDraw()'>
            <img
            src="../../../assets/plus.svg"
          />
        </div>
        <div *ngIf = 'isPersonalPDF' style="z-index: 1;cursor: pointer;display:inline-block;"(click) = 'addPDF()'>
            <img
            src="../../../assets/plus.svg"
          />
        </div>
        <div *ngIf = 'isInbox' style="z-index: 1;cursor: pointer;display:inline-block;"(click) = 'openExternal()'>
            <img
            style="transform: rotate(180deg);"
            src="../../../assets/upload.png"
          />
        </div>
        <!-- <div *ngIf = 'isPersonal' style="z-index: 1;cursor: pointer;width:20px;margin-left: 5px;display:inline-block;" (click)="remove()">
            <img
                src="../../../assets/greyX.png"
            />
        </div> -->
    </div>
    <!-- <div style="display:inline-block;">
    
    </div> -->
</div>
