import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { ResizedEvent } from 'angular-resize-event';
import { articleThumb } from 'src/app/models/articleThumb';
import{ TeacherProfile } from '../../models/TeacherProfile';
import {TeachersService} from '../../services/teachers.service';

@Component({
  selector: 'app-meet-teachers',  
  templateUrl: './meet-teachers.component.html',
  styleUrls: ['./meet-teachers.component.scss']  
})
export class MeetTeachersComponent implements OnInit {
  teachers: TeacherProfile[];
  currentlySelected: string[] = [];
  width 
  height 
  computer = true   
  nLMsg = []
  emailNL = ''
  sentNL = false
  articles = []
  topics = []
  BUID
  @Output() articlesE = new EventEmitter<articleThumb[]>();
  @Output() topicsE = new EventEmitter<any[]>();

  selectedTopics = []

  article1:articleThumb = new articleThumb('','','','','')
  article2:articleThumb = new articleThumb('','','','','')
  article3:articleThumb = new articleThumb('','','','','')
  article4:articleThumb = new articleThumb('','','','','')
  article5:articleThumb = new articleThumb('','','','','')

  date1 = ''
  date2 = ''
  date3 = ''
  date4 = ''
  date5 = ''

  constructor(private afm: AngularFireStorage,private fns:AngularFireFunctions,private teachersService: TeachersService,private db: AngularFireDatabase, private router:ActivatedRoute) { }

  async ngOnInit(){
    this.BUID = this.router.snapshot.params.BUID
    console.log(this.BUID)   
    if(this.BUID  === 'Welcome' || this.BUID  === 'New'){
      var articles:articleThumb[] = []
      const query = await this.db.database.ref('blog').child('new').orderByKey().limitToLast(11)
      var hold:string = ''
      this.db.database.ref('blog/cats').child('val').once('value').then((snap)=>{
        hold = snap.val()  
        this.topics = hold.split('|')
        this.topicsE.emit(this.topics)
      }).then(()=>{
        var snapHold = null
        query.once('value', snap =>{
          snapHold = snap
          console.log(snapHold)
        }).then(()=>{
          if(snapHold !== null){
            snapHold.forEach(function(snapp){
              snapp.forEach((snappp)=>{
                articles.push(new articleThumb(snappp.key,snappp.child('title').val(),String(snappp.child('article').val()).substr(0,186),snappp.child('readTime').val(),snappp.child('cat').val()))
              })   
            })
            var indexCount = 0
            var holdList = []
            articles.forEach(article=>{
              if(indexCount < 5){
                holdList.push(article)
              }else{
                this.articles.push(holdList)
                holdList = []
                indexCount = -1
              }
              indexCount += 1
            })
          }  
        }).then(()=>{
          console.log(this.articles)
          this.displayArticles(this.articles[0])
          document.getElementById('New').setAttribute('class','frame-330-1')
        })
      })
    }else{
      var articles:articleThumb[] = []
      const query = await this.db.database.ref('blog').child(`${this.BUID}`).orderByKey().limitToLast(11)
      var hold:string = ''
      this.db.database.ref('blog/cats').child('val').once('value').then((snap)=>{
        hold = snap.val()
        this.topics = hold.split('|')
        this.topicsE.emit(this.topics)
      }).then(()=>{
        var snapHold = null
        query.once('value', snap =>{
          snapHold = snap
          console.log(snapHold)
        }).then(()=>{
          if(snapHold !== null){
            snapHold.forEach(function(snappp){
              console.log(snappp.key)
              articles.push(new articleThumb(snappp.key,snappp.child('title').val(),String(snappp.child('article').val()).substr(0,186),snappp.child('readTime').val(),snappp.child('cat').val()))  
            })
            this.shuffleArray(articles).then(val=>{
              var indexCount = 0
              var holdList = []
              val.forEach(article=>{
                if(indexCount < 5){
                  holdList.push(article)
                  indexCount += 1
                }else{
                  this.articles.push(holdList)
                  holdList = []
                  indexCount = -1
                }
                indexCount += 1
              })
            })
        } 
        }).then(()=>{
          this.displayArticles(this.articles[0])
          document.getElementById(`${this.BUID}`).setAttribute('class','frame-330-1')
        })
      })
    }

  }
  onResized(event: ResizedEvent) {    
    this.width = event.newWidth;   
    this.height = event.newHeight;
    if(this.width < 700){
      this.computer = false
    }
  }
  
  async shuffleArray(array) {
    var i = 0
    , j = 0
    , temp = null

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1))
    temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
    console.log(array)
    return array
  }

  async signUpNL(){
    this.sentNL = true
    var emailHold = this.emailNL
    if(this.emailNL !== ''){
      var addr = 'fred.mendoza527@gmail.com'   
      var sub = 'Blog Sign Up Sign Up'
      const newEmail = this.fns.httpsCallable('sendMail')
      await newEmail({
        address: addr,
        subject: sub,
        msg:`${emailHold}`
      }).toPromise().then(()=> {
        console.log("success")
        this.nLMsg = ['Thanks for signing up!']
        document.getElementById('thankYou').setAttribute('style','z-index: 1;cursor: pointer;')
      }).catch((e)=> {
        console.log('400',e)
        this.nLMsg = ['Something went wrong, please try again.']
        this.sentNL = false
      })
    }else{
      this.nLMsg = ['Only an Email is Necessary']
    }
  }

  reset(){
    console.log(this.selectedTopics)
    this.selectedTopics.forEach(key=>{
      document.getElementById(key).setAttribute('class','frame-330')
    })
    this.selectedTopics = []
    this.onSelect('New')
  }

  async onSelect(val){  
    this.articles = []
    if(this.selectedTopics.includes(val)){
      document.getElementById(val).setAttribute('class','frame-330')
      this.selectedTopics.splice(this.selectedTopics.indexOf(val),1)
    }else{
      document.getElementById(val).setAttribute('class','frame-330-1')
      this.selectedTopics.push(val)
    }
    if(val === 'New'){
      val = 'new'
    }
    console.log(val)
    var articles:articleThumb[] = []
    const query = await this.db.database.ref('blog').child(`${val}`).orderByKey().limitToLast(11)
    var snapHold = null  
    query.once('value', snap =>{
      snapHold = snap
      if(snapHold !== null){
        if(val === 'new'){
          snapHold.forEach(function(snapp){
            snapp.forEach((snappp)=>{
              articles.push(new articleThumb(snappp.key,snappp.child('title').val(),String(snappp.child('article').val()).substr(0,186),snappp.child('readTime').val(),snappp.child('cat').val()))
            })   
          })
          var indexCount = 0
          var holdList = []
          articles.forEach(article=>{
            if(indexCount < 5){
              holdList.push(article)
              indexCount += 1
            }else{
              this.articles.push(holdList)
              holdList = []
              indexCount = -1
            }
          })
        }else{
          snapHold.forEach(function(snappp){
            console.log(snappp.key)
            articles.push(new articleThumb(snappp.key,snappp.child('title').val(),String(snappp.child('article').val()).substr(0,186),snappp.child('readTime').val(),snappp.child('cat').val()))  
          })
          this.shuffleArray(articles).then(val=>{
            console.log(val)
            var indexCount = 0
            var holdList = []
            val.forEach(article=>{
              if(indexCount < 5){
                holdList.push(article)
                indexCount += 1
              }else{
                this.articles.push(holdList)
                holdList = []
                indexCount = -1
              }
            })
          })
        }
    }}).then(()=>{
      console.log(this.articles[0])
      this.displayArticles(this.articles[0])
    })
  } 

  displayArticles(articleList){
    var count = 1
    console.log(articleList)
    articleList.forEach((article:articleThumb)=>{
      if(count === 1){
        this.article1 = article
        var hold = new Date(Number(article.AUID)).toString().split(' ')
        var year = new Date().getFullYear() - 1
        console.log(new Date(Number(article.AUID)).getMonth(),new Date().getMonth())
        if(new Date(Number(article.AUID)).getMonth() <= new Date().getMonth() && new Date(Number(article.AUID)).getDate() <= new Date().getDate()){
          year += 1
        }
        this.date1 = hold[0] + ' ' + hold[1] + ' ' + hold[2] + ' ' + year
        var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/ArticleImages/' + article.AUID)  
        storage.getDownloadURL().then(function(url) {
          document.getElementById('1').setAttribute('src',url);
        })
      }else if(count === 2){
        this.article2 = article
        var hold = new Date(Number(article.AUID)).toString().split(' ')
        var year = new Date().getFullYear() - 1
        if(new Date(Number(article.AUID)).getMonth() <= new Date().getMonth() && new Date(Number(article.AUID)).getDate() <= new Date().getDate()){
          year += 1
        }
        this.date2 = hold[0] + ' ' + hold[1] + ' ' + hold[2] + ' ' + year
        var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/ArticleImages/' + article.AUID)  
        storage.getDownloadURL().then(function(url) {
          document.getElementById('2').setAttribute('src',url);
        })
      }else if(count === 3){
        this.article3 = article
        var hold = new Date(Number(article.AUID)).toString().split(' ')
        var year = new Date().getFullYear() - 1
        if(new Date(Number(article.AUID)).getMonth() <= new Date().getMonth() && new Date(Number(article.AUID)).getDate() <= new Date().getDate()){
          year += 1
        }
        this.date3 = hold[0] + ' ' + hold[1] + ' ' + hold[2] + ' ' + year
        var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/ArticleImages/' + article.AUID)  
        storage.getDownloadURL().then(function(url) {
          document.getElementById('3').setAttribute('src',url);
        })  
      }else if(count === 4){
        this.article4 = article
        var hold = new Date(Number(article.AUID)).toString().split(' ')
        var year = new Date().getFullYear() - 1
        if(new Date(Number(article.AUID)).getMonth() <= new Date().getMonth() && new Date(Number(article.AUID)).getDate() <= new Date().getDate()){
          year += 1
        }
        this.date4 = hold[0] + ' ' + hold[1] + ' ' + hold[2] + ' ' +year
        var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/ArticleImages/' + article.AUID)  
        storage.getDownloadURL().then(function(url) {
          document.getElementById('4').setAttribute('src',url);
        })
      }else if(count === 5){
        this.article5 = article
        var hold = new Date(Number(article.AUID)).toString().split(' ')
        var year = new Date().getFullYear() - 1
        if(new Date(Number(article.AUID)).getMonth() <= new Date().getMonth() && new Date(Number(article.AUID)).getDate() <= new Date().getDate()){
          year += 1
        }
        this.date5 = hold[0] + ' ' + hold[1] + ' ' + hold[2] + ' ' + year
        var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/ArticleImages/' + article.AUID)  
        storage.getDownloadURL().then(function(url) {
          document.getElementById('5').setAttribute('src',url);
        })
      }
      count += 1
    })
  }
   
}
