<div style="max-width: 880px;height:120px;margin: 0 auto;margin-bottom: 30px;padding: 20px;vertical-align: top;border-top-right-radius: 25px; border-bottom-left-radius: 25px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230);font-family: 'Avenir Light';font-size: 18px;">
    <!-- 1. link to teachers page(Book Me) 2. teachers name 3. teachers title 4. answer 5. time stamp-->
    <div style="display: inline-block;vertical-align: top;">
        <p>{{date}} {{time}} <br> {{name}} - {{formalTitle}}</p>
        <a routerLink="../TeacherPage/{{answer.TUID}}" class = "txtBlock">Book Me</a>
    </div>
    <div style="width:20px;display: inline-block;vertical-align: top;">
        
    </div>
    <div style="display: inline-block;vertical-align: top; width: 300px;overflow-y: auto; height: 80px;margin-top: -10px;">
        <p style="text-align: left;">{{answer.answer}}</p>
    </div>
    

</div>
