import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {AuthService} from '../../services/auth.service'
import {AngularFireDatabase} from '@angular/fire/database'
import {AngularFireStorage} from '@angular/fire/storage'
import { DataSnapshot } from '@angular/fire/database/interfaces';
import { identifierModuleUrl } from '@angular/compiler';


@Component({
  selector: 'app-scheduler', 
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
}) 
export class SchedulerComponent implements OnInit {
  time;
  v:string[]= []
  day:string = ""
  slectedDay:string
  slot1:string
  slot2:string
  slot3:string
  slot4:string
  slotPlaceHolder1 = "Start time"
  slotPlaceHolder2 = "End time"
  slotPlaceHolder3 = "Start time"
  slotPlaceHolder4 = "End time"
  switch1:boolean = false
  switch2:boolean = false

  switch3:boolean = false


  //@Input() uid:string;
  @Output() error = new EventEmitter<string>();
  @Output() newAvailableDays = new EventEmitter<string>();
  @Input() currentTitles:string
  @Input() currentDays:string
  @Input() grades: string;
  @Input() UID: string;

  timesOpen = []
  constructor(private auth: AuthService, private db: AngularFireDatabase) { }

  async ngOnInit(){
    // this is where we will get the values of time 12 - 11:45
    var innerCount = 0

    // var holda = "";
    // await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + 'thu' + "/val").once('value').then(function(snap){
    //     holda = snap.val()
    // })
    // console.log(holda)
    // holda.split(" ")

    var count = 6
    while(count < 21){
      var innerCount = 0
      while(innerCount < 4){
        var currTime  = String(count) + ":"
        if(innerCount === 0){
          //00
          currTime += "00"
          this.timesOpen.push(currTime)
        }else if(innerCount === 1){
          //15
          currTime += "15"
          this.timesOpen.push(currTime)
        }else if(innerCount === 2){
          //30
          currTime += "30"
          this.timesOpen.push(currTime)
        }else{
          //45
          currTime += "45"
          this.timesOpen.push(currTime)
        }
        innerCount += 1
      }
      count += 1
    }
    var currTime  = String(count) + ":00" 
    this.timesOpen.push(currTime)
    console.log(this.timesOpen)
  }

  slotPlaceHolder(time:string,slotNumber:number){
    if(slotNumber === 1){
      this.switch1 = true;
      this.slotPlaceHolder1 = this.format2(time)
    }else if(slotNumber === 2){
      this.switch1 = true;
      this.slotPlaceHolder2 = this.format2(time)
    }else if(slotNumber === 3){
      this.switch2 = true;
      this.slotPlaceHolder3 = this.format2(time)
    }else if(slotNumber === 4){
      this.switch2 = true;
      this.slotPlaceHolder4 = this.format2(time)
    }
  }

  storeTime(time:string,slot:number){
    //this is where you need to take the first number up and the second number down 
    console.log(time, " ", slot)
    this.slotPlaceHolder(time,slot)
    var hope = time.split(":")
    if(slot === 1 || slot === 3){
      var testa = Number(hope[1])
      if(testa === 0){
        hope[1] = "00"
      }else if(testa < 46){
        while(testa%15 !== 0){
          testa += 1
        }
        hope[1] = String(testa)
      }else{
        hope[0] = String(Number(hope[0]) + 1) 
        hope[1] = "00"
      }
    }else{
      var testa = Number(hope[1])
      if(testa === 0){
        hope[1] = "00"
      }
      if(testa > 14){
        while(testa%15 !== 0){
          testa -= 1
        }
        hope[1] = String(testa)
      }else{
        hope[1] = "00"
      }
    }
    
    console.log(time, slot)
    if(slot=== 1){
      this.slot1 = hope[0] + hope[1]
    }else if(slot=== 2){
      this.slot2 = hope[0] + hope[1]
    }else if(slot=== 3){
      this.slot3 = hope[0] + hope[1]
    }else{
      this.slot4 = hope[0] + hope[1]
    }
    
  }

  validTime(time:number){
    console.log(time)
    if(time >= 0 && time < 2400){
      return true
    }else{
      return false
    }
  }

  checkDay(day:string){
    if (day === ""){
      return false
    }else{
      return true
    }
  }
  
  valid(num){
    if(num <2400 && num >= 0){
      return true
    }else{
      return false
    }
  }
 
  async clearDay(day){
    console.log(day)
    if(this.checkDay(this.day)){
        if(this.grades !== "" && this.currentTitles !== ""){
          this.removeDay(this.day)
        }
        if(day === 1){
          if(this.slotPlaceHolder1 !== "Start Time"){
            if(this.slotPlaceHolder3 === "Start Time"){
              var locate = this.currentDays.split(",")
              if(locate.length === 1){
                this.currentDays = ""
              }else if(locate[0] === this.day){
                this.currentDays = this.currentDays.replace(this.day + ",","")
              }else if(locate[locate.length - 1] === this.day){
                this.currentDays = this.currentDays.replace("," + this.day ,"")
              }else{
                this.currentDays = this.currentDays.replace("," + this.day + ",",",")
              }

              this.v = ["your schedule is cleared for " + this.day]
              await this.db.database.ref('providers/userInfo/' + this.UID + '/services/activeDays').set({
                val:this.currentDays
              })
              this.newAvailableDays.emit(this.currentDays)
              await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day)
              .set({
                val: "empty"
              });
              this.slotPlaceHolder1 = "Start Time";
              this.slotPlaceHolder2 = "End Time";
            }else{
              var holda = "";
              await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day + "/val").once('value').then(function(snap){
                holda = snap.val()
              })
              console.log(holda)
              holda = holda.replace(this.slot1 + " ","")
              holda = holda.replace(this.slot2 + " ","")
              await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day)
              .set({
                val: holda
              });
              this.slotPlaceHolder1 = "Start Time";
              this.slotPlaceHolder2 = "End Time";
            
            }
          }
        }else{
          if(this.slotPlaceHolder3 !== "Start Time"){
            if(this.slotPlaceHolder1 === "Start Time"){
              var locate = this.currentDays.split(",")
              if(locate.length === 1){
                this.currentDays = ""
              }else if(locate[0] === this.day){
                this.currentDays = this.currentDays.replace(this.day + ",","")
              }else if(locate[locate.length - 1] === this.day){
                this.currentDays = this.currentDays.replace("," + this.day ,"")
              }else{
                this.currentDays = this.currentDays.replace("," + this.day + ",",",")
              }

              this.v = ["your schedule is cleared for " + this.day]
              await this.db.database.ref('providers/userInfo/' + this.UID + '/services/activeDays').set({
                val:this.currentDays
              })
              this.newAvailableDays.emit(this.currentDays)
              await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day)
              .set({
                val: "empty"
              });
              this.slotPlaceHolder3 = "Start Time";
              this.slotPlaceHolder4 = "End Time";
            }else{
              var holda = "";
              await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day + "/val").once('value').then(function(snap){
                holda = snap.val()
              })
              console.log(holda)
              holda = holda.replace( " " + this.slot3 + " ","")
              holda = holda.replace(this.slot4,"")
              await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day)
              .set({
                val: holda
              });
              this.slotPlaceHolder3 = "Start Time";
              this.slotPlaceHolder4 = "End Time";
            }
          }
        }

        // var locate = this.currentDays.split(",")
        // if(locate.length === 1){
        //   this.currentDays = ""
        // }else if(locate[0] === this.day){
        //   this.currentDays = this.currentDays.replace(this.day + ",","")
        // }else if(locate[locate.length - 1] === this.day){
        //   this.currentDays = this.currentDays.replace("," + this.day ,"")
        // }else{
        //   this.currentDays = this.currentDays.replace("," + this.day + ",",",")
        // }
       
        // this.v = ["your schedule is cleared for " + this.day]
        // await this.db.database.ref('providers/userInfo/' + this.UID + '/services/activeDays').set({
        //   val:this.currentDays
        // })
        // this.newAvailableDays.emit(this.currentDays)
        // await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day)
        // .set({
        //   val: "empty"
        // });

      }
  }

  slots(val1,val2):boolean{
    var hold1 = Number(val1)
    var hold2 = Number(val2)
    var ret = false
    if(this.valid(hold1) && this.valid(hold2)){
      //round the the nearest 15 30 45 or 00
      if(hold1 < hold2){
        ret = true
      }else{
        this.error.emit("the time before has to be smaller then the time after the dash")
      }
    }else{
      this.error.emit("looks like one of your times was off")
    }
    return ret
  }


  async tryToSend(){
    if((this.switch1 || this.switch3) && this.switch2){
      if((this.switch1 || this.switch3) && this.switch2){
        if(this.slots(this.slot1,this.slot2)){
          if(this.slots(this.slot3,this.slot4)){
            if(this.checkDay(this.day)){
              this.sendIt()
            }
          }
        }
      }
    }else if((this.switch1 || this.switch3)){
      console.log(5)
      console.log(this.slot1,this.slot2)
      if(this.slots(this.slot1,this.slot2)){
        console.log(6)
        if(this.checkDay(this.day)){
          console.log(1)
          this.sendIt()
        }
      }else{
        //delete slots 1 and 2

      }
    }else if(this.switch2){
      console.log(9)
      if(this.slots(this.slot3,this.slot4)){
        if(this.checkDay(this.day)){
          console.log(1)
          this.sendIt()
        }
      }else{
        //delete slots 3 and 4
      }
    }else{

  

    }
    
  }
 
  // run this once input checked
  async addDay(day:string){
    this.currentTitles.split(",").forEach(title =>{
      this.grades.split(",").forEach(grade=>{
        this.db.database.ref('teacherSearch/' + title + '/' + grade+ '/'+ day).push(
          {
            uid: this.UID
          }
        )
      })
    })
  }

  async removeDay(day:string){
    var dbRef = this.db
    var UIDRef = this.UID
    this.currentTitles.split(",").forEach(title =>{
      this.grades.split(",").forEach(grade=>{
        console.log(day,title)
        this.db.database.ref('teacherSearch/' + title+ '/' + grade+ '/'+ day).once('value').then(function(sna){
          sna.forEach(function(s){
            if(s.child('uid').val() === UIDRef){
              dbRef.database.ref('teacherSearch/' + title+ '/' + grade+ '/'+ day +"/" + s.key).remove()
            }
          })
        })
      })
    })
  }

  uniqueDay(day):boolean{
    return this.currentDays.includes(this.day)
  }


  async sendIt(){      
    if(!this.uniqueDay(this.day)){
      if(this.currentDays.split(",")[0] === ""){
        this.currentDays += this.day
      }else{
        this.currentDays += "," + this.day
      }
      await this.db.database.ref('providers/userInfo/' + this.UID + '/services/activeDays').set({
        val:this.currentDays
      })
      this.newAvailableDays.emit(this.currentDays)

    }
    var oldVal: string = ""
    var newFront = ""
    var newBack = ""
    await this.db.database.ref('providers/userInfo/' + this.UID+ '/schedule/' + this.day).child("val").once('value').then(function(snap){
      console.log(snap.val())
      oldVal = String(snap.val())
     })


    if(this.switch1 && !this.switch2){
      if(this.slot1 === oldVal.split(" ")[0] && this.slot2 === oldVal.split(" ")[1]){
        newFront = ''
      }
    }
    if(!this.switch1 && this.switch2){
      if(this.slot3 === oldVal.split(" ")[0] && this.slot4 === oldVal.split(" ")[1]){
        newBack = ''
      }
    }

    console.log(oldVal)
    if(this.switch1){
      console.log(this.slot1)
      console.log(this.slot2)
      newFront = this.slot1 + " " + this.slot2
    }
    if(this.switch2){
      console.log(this.slot3)
      console.log(this.slot4)
      newBack = this.slot3 + " " + this.slot4
    }

    if(oldVal === 'empty' || oldVal === ""){
      if(this.currentTitles !== "" && this.grades !== ""){
        this.addDay(this.day)
      }
      var addit = ""
      if((this.switch1 || this.switch3) && this.switch2){
        addit += newFront + ' ' + newBack
      }else if((this.switch1 || this.switch3)){
        addit += newFront
      }else if(this.switch2){
        addit += newBack
      }
      await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day)
      .set({
        val: addit
      });

    }else if(newFront !== "" && newBack !==""){
      await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day)
      .set({
        val: newFront + ' ' + newBack
      });
    }else if(oldVal.split(" ").length === 2){
      if((this.switch1 || this.switch3)){
        await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day)
        .set({
          val: newFront + ' ' + oldVal
        });
      }else{
        await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day)
        .set({
          val: oldVal + ' ' +newBack
        });
      }
    }else if(oldVal.split(" ").length === 4){
    var holder = oldVal.split(" ")
      if((this.switch1 || this.switch3)){
        await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day)
        .set({
          val: newFront + ' ' + holder[2] + ' ' + holder[3]
        });
      }else{
        await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/' + this.day)
        .set({
          val: holder[0] + ' ' + holder[1] + ' ' +newBack
        });
      }

    }else{
      console.log("errrrr")
    }
    this.v = ["your schedule has been updated"]
  }

  adjustSwitch(switchNum:number){
    console.log(this.switch1,this.switch2,switchNum)
    if(switchNum === 1){
      this.switch1 = !this.switch1
    }else if(switchNum === 2){
      this.switch2 = !this.switch2
    }else if(switchNum === 3){
      this.switch3 = !this.switch3
    }
  }

  onReset(){
    this.switch1 = false;
    this.switch2 = false;
    this.switch3 = false;
    this.slot1 = ""; 
    this.slot2 = "";
    this.slot3 = "";
    this.slot4 = "";
    this.slotPlaceHolder1 = "Start Time";
    this.slotPlaceHolder2 = "End Time";
    this.slotPlaceHolder3 = "Start Time";
    this.slotPlaceHolder4 = "End Time";
  }

  //  this is where you can display the current hours 
  // is not printing correctly ******************
  async storeDay(day:string){
    this.onReset()
    this.v = []
    var currentSchedule = ""
    await this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/'+ day +'/val').once('value').then(function(snap){
      currentSchedule = snap.val()
    })
    this.day = day
    console.log(this.day)
    if(currentSchedule === "" || currentSchedule === "empty"){
      var hold = day + " has no hours booked yet"
      this.v = [hold]
    }else if(currentSchedule.split(" ").length === 2){
      var num1 = this.format(Number(currentSchedule.split(" ")[0])).trim()
      var num2 = this.format(Number(currentSchedule.split(" ")[1])).trim()
      console.log(Number(currentSchedule.split(" ")[0]))
      this.slotPlaceHolder1  = String(num1) 
      this.slotPlaceHolder2  = String(num2) 

      this.slot1 = currentSchedule.split(" ")[0]
      this.slot2 = currentSchedule.split(" ")[1]

    }else if(currentSchedule.split(" ").length === 4){
      var num1 = this.format2(currentSchedule.split(" ")[0])
      var num2 = this.format2(currentSchedule.split(" ")[1])
      var num3 = this.format2(currentSchedule.split(" ")[2])
      var num4 = this.format2(currentSchedule.split(" ")[3])
       
      this.slotPlaceHolder1  = String(num1) 
      this.slotPlaceHolder2  = String(num2) 
      this.slotPlaceHolder3  = String(num3) 
      this.slotPlaceHolder4  = String(num4) 

      this.slot1 = currentSchedule.split(" ")[0]
      this.slot2 = currentSchedule.split(" ")[1] 
      this.slot3 = currentSchedule.split(" ")[2]  
      this.slot4 = currentSchedule.split(" ")[3]

    }
  }
  
  format2(nuh:string):string{
    var hold = nuh
    var num = Number(hold.replace(":",''))
    if(Math.floor(num/100) === 0){
      num = (12 * 100) + num%100
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ "am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + "am"
        }      
      } 
    }else if(num.toString().length ===4&& num/100 > 11){
      if (Math.floor(num/100) > 12){
       if(num%100 === 0){
         return String((Math.floor(num/100)-12))+ "pm"
        }else{ 
          if(num%100 < 10){
            return String((Math.floor(num/100)-12))+":"+"0"+String(num%100) + "pm"
          }else{
            return String((Math.floor(num/100)-12))+":"+String(num%100) + "pm"
          }
        } 
      }else{
        if(num%100 === 0){
          return String((Math.floor(num/100)))+ "pm"
        }else{ 
          if(num%100 < 10){
            return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "pm"
          }else{
            return String((Math.floor(num/100)))+":"+String(num%100) + "pm"
          }
        }
      }
    }
    else{
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ "am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + "am"
        }      
      } 
    }
  }

format(num:number):string{
    if(Math.floor(num/100) === 0){
      num = (12 * 1000) + num%100
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ "am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + "am"
        }      
      } 
    }else if(num.toString().length ===4&& num/100 > 11){
      if (Math.floor(num/100) > 12){
       if(num%100 === 0){
         return String((Math.floor(num/100)-12))+ "pm"
        }else{ 
          if(num%100 < 10){
            return String((Math.floor(num/100)-12))+":"+"0"+String(num%100) + "pm"
          }else{
            return String((Math.floor(num/100)-12))+":"+String(num%100) + "pm"
          }
        } 
      }else{
        if(num%100 === 0){
          return String((Math.floor(num/100)))+ "pm"
        }else{ 
          if(num%100 < 10){
            return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "pm"
          }else{
            return String((Math.floor(num/100)))+":"+String(num%100) + "pm"
          }
        }
      }
    }
    else{
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ "am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + "am"
        }      
      } 
    }
  }
}
