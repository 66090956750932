import { Component, Input, OnInit,Output, EventEmitter} from '@angular/core';
import{ ServiceDescription } from '../../models/ServiceDescription'
import {AngularFireDatabase} from '@angular/fire/database'
import { hour_min } from 'src/app/models/hour-min';
import { title_rate_time } from 'src/app/models/title_rate_time';
import {ActivatedRoute} from '@angular/router'
import {MessengerService} from '../../services/messenger.service'
import { loadStripe, Stripe } from '@stripe/stripe-js';
import {AngularFireFunctions} from '@angular/fire/functions'
import { environment } from 'src/environments/environment.prod';
import {Router} from '@angular/router'
import {AuthService} from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ResizedEvent } from 'angular-resize-event';
import { auth } from 'firebase/app';
import { TeacherProfile } from 'src/app/models/TeacherProfile';
import { AngularFireStorage } from '@angular/fire/storage';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER,SPACE,TAB} from '@angular/cdk/keycodes';
import {Email} from '../../models/email'
import Calendar, { IMonthGuide, TEventBeforeCreateSchedule } from 'tui-calendar'; /* ES6 */
import moment from 'moment-timezone';


       
declare var gtag

@Component({   
  selector: 'app-teacher-page',     
  templateUrl: './teacher-page.component.html',
  styleUrls: ['./teacher-page.component.scss'] 
})

export class TeacherPageComponent implements OnInit {
  UID ="";
  teacherTimeZoneOffset:number; 
  name;

  open = false

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA, SPACE,TAB] as const;
  fruits: Email[] = [];

  openView = false

  future:number;
  @Output() mobile = new EventEmitter<boolean>();
  @Output() selectedTimeFrame = new EventEmitter<any[]>();
  @Output() hourlyRate = new EventEmitter<any>();
  
  
  computer = true
  //display what day of the week it is
  slotPlaceHolder1 = "Month"
  slotPlaceHolder2 = "Date"
  slotPlaceHolder22 = "End Date"
  slotPlaceHolder3 = "Year"
  slotPlaceHolder4 = "Start Time"   
  slotPlaceHolder5 = "End Time"  
  slotPlaceHolder6 = "# of Sessions"
  slotPlaceHolder7 = "Rate"
  linkToPage
  timesList = [15,30,45,60,75,90,105,120]
  sessionList = [1,2,3,4,5,6,7,8,9,10]
  isTeacher = false
  titlePiece = 'Request An Appointment'   

  description

  input1 = false
  input2 = false
  input3 = false
  input4 = false
  input5 = false
  input6 = false
    
  shareEmail
  shareNote

  serviceValues=[]

  @Output() MyFiles = new EventEmitter<any[]>();  
  myFiles = []
  
  width: number;
  height: number;

  notSent = true    
//grace     
  policy1
  //general
  policy2
  //no show
  policy3
  //36_24 ...
  policy4
  policy5
  policy6
  policy7

  subjects = 'Subjects'

  recurr = false
  nonrecurr = true
  now = false

  listNum1 = []
  listNum2 = []
  reqDate = ''
  reqDateEnd = ''
  reqStartTime = ''
  reqEndTime = ''
  reqSessionNum = ''
  reqSessionRate = 'Rate'

  @Output() currTeachers = new EventEmitter<any[]>();

  emailSen = ''
  emailRec = ''
  message = ''

  validDays1 = []
  validDays2 = []
  timeRequests = []
  months = []
  future2;
  upcomingApp:string;
  logging = false
  logging2 = false
  createLogin2: string[];
  selected:boolean 
  orders:title_rate_time[] = []
  chosenServices: string = ""
  listings: ServiceDescription[];  
  timeSlots: hour_min[] = [];
  correctOrder:hour_min[] = [];  
  errorString = []
  cred;
  teacherEmail = '';
  rate;
  empty:boolean = true;
  err = []
  errorP = false 
  longIntro;
  errorMsg = ['']
  shortIntro;
  gradesList = ['1st-2nd','3rd-5th','6th-8th','9th-12th']
  schedule = new Map(
    [
    ["mon", ""],
    ["tue", ""],
    ["wed", ""],
    ["thu", ""], 
    ["fri", ""],
    ["sat", ""],
    ["sun", ""] 
    ]
  );
    
  @Input() teacherRate;
  createLogin = [];
  stripe: Stripe;
  donationAmount = 5.00;
  isGettingCheckout = false; 
  
  previousTypeOfBook = 'NonRecurring'
  lastType: string;
  listingString: string;
  calendar: Calendar;
  weekOfAvailabilities: {};
  scheduleList: {};
  lastStartDate: moment.Moment;
  lastEndDate: moment.Moment;
  timesToReserve = []

  constructor(private afm: AngularFireStorage,private afAuth: AngularFireAuth,private database:AngularFireDatabase, private router:ActivatedRoute,private rerouter: Router,private msg:MessengerService,private fns:AngularFireFunctions,private auth: AuthService) { 

  }

  async ngOnInit(){  
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0
    this.getDates()
    this.getTimesDef(1300,2100)
    this.UID = this.router.snapshot.params.UID
    this.linkToPage = 'https://verifiedteachers.com/TeacherPage/' + this.UID
    this.getListing2() 
    this.getTimes()
    this.getStars()
    var hold;


    // this.database.database.ref('/users/' + 'Xsfl8lwShuauODnwcV7WyoIHnOE3' + '/requests').push({
    //   services:'test'
    // }).then((PUID2:any)=>{
    //   console.log(PUID2.key)
    //   this.database.database.ref('/users/' + 'Xsfl8lwShuauODnwcV7WyoIHnOE3'+ '/requests').set({val:PUID2.key})
    // })
    this.getProPic()

    var calendar = new Calendar('#calendar', {
      defaultView: 'week',
      // taskView: true,    // Can be also ['milestone', 'task']
      // scheduleView: true,  // Can be also ['allday', 'time']
      taskView: [],
      scheduleView: ['time'],
      useCreationPopup: false,
      useDetailPopup: false,
      // bgColor: 'red',
      // borderColor: 'calColor',
      // color: 'calColor',
      theme:{
        'common.holiday.color': 'rgba(30, 86, 99, 1)',
        'month.holidayExceptThisMonth.color': 'rgba(30, 86, 99, 1)',
        // 'week.currentTimeLinePast.border': 'none',
        // 'week.currentTimeLineBullet.backgroundColor': 'transparent',
        // 'week.currentTimeLineToday.border': 'none',
        // 'week.currentTimeLineFuture.border': 'none',
      },
      
      timezone: {
        zones: [
          {  
            timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }  
        ],
        offsetCalculator: function(timezoneName, timestamp){
          // matches 'getTimezoneOffset()' of Date API
          // e.g. +09:00 => -540, -04:00 => 240
  
          // console.log(moment.tz.zone(timezoneName).utcOffset(timestamp))
          return moment.tz.zone(timezoneName).utcOffset(timestamp);
        },
      },   
      template: {
          time: function(schedule) {
            // this conrols what goes in the schdule 
              return ' <i class="fa fa-refresh personal-info" style = " color: var(--chathams-blue);font-family: var(--font-family-inter);font-size: var(--font-size-m); font-style: normal;font-weight: 600;position: absolute;top: 43%;">Open Time</i>';
          },
          timegridCurrentTime: function(timezone: any) {
            return null;
          },
          weekDayname: function(model){
            var days = ['Sunday\'s','Monday\'s','Tuesday\'s','Wednesday\'s','Thursday\'s','Friday\'s','Saturday\'s'];
            return '<span class=\"tui-full-calendar-dayname-name\"style =\"font-size:15px;padding-left: 10px;\">' + days[model.day] + '</span>';
          },
      },
      month: {
          daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          startDayOfWeek: 0,
          // narrowWeekend: true
      },
      week: {
          daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          startDayOfWeek: 0,
          narrowWeekend: false,
          hourStart:5,
      }
    });
    calendar.next()
    this.calendar = calendar
    
    var hldList = {0:'',1:'',2:'',3:'',4:'',5:'',6:''}
    await this.database.database.ref('providers/userInfo/' + this.UID + '/schedule/timeZoneOffset').once('value').then(snap=>{
      hold = String(snap.val())
      console.log(hold)
      var IANAVal = ''
      if(hold === '7'){
        IANAVal = 'America/Los_Angeles'
      }else if(hold === '6'){
        IANAVal = 'America/Boise'
      }if(hold === '5'){
        IANAVal = 'America/Chicago'
      }if(hold === '4'){
        IANAVal = 'America/New_York'
      }
      this.database.database.ref('providers/userInfo/' + this.UID + '/schedule/avail').once('value').then(function(snap){
        if(snap.exists()){
          snap.forEach(snapp =>{
            console.log(snapp.key,snapp.val())
            hldList[snapp.key] = snapp.val()
          })
        }
       }).then(()=>{
         console.log(hldList)
         this.scheduleList = hldList
         this.populateCalendar(this.scheduleList,IANAVal)
       })
    })


    


    var TSID = + new Date()
    var calHold = this.calendar

    this.calendar.on({
      'clickSchedule': (e:any) => {
        // console.log('clickSchedule', e);
        // document.getElementById('timeRes').setAttribute('style','position: absolute;z-index:102;')
        // var AvailableTimes = []
        // // if(e.schedule.start)
        // console.log(e.schedule.start,e.schedule.end,e.schedule.start.valueOf(),e.schedule.end.valueOf(),e.schedule.start.toLocaleString(),e.schedule.end.toLocaleString())
        // // var momentItr = moment(e.schedule.start.valueOf()).tz(IANATimeZoneValue)
        // // this.lastStartDate = startDate
        // // this.lastEndDate = endDate
        // this.selectedTimeFrame.emit([e,e.schedule.start,e.schedule.end])
        console.log('beforeCreateSchedule', e);
        // e.guide.clearGuideElement()
       
        var startDate = moment(e.schedule.start._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        var endDate = moment(e.schedule.end._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)

        this.lastStartDate = moment(e.schedule.start._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        this.lastEndDate = moment(e.schedule.end._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)

        var openings = []

        if(startDate.date() === endDate.date() && startDate.month() === endDate.month() && startDate.year() === endDate.year()){
          var key = startDate.toISOString(true).split('T')[0]
          console.log(key,this.weekOfAvailabilities,this.weekOfAvailabilities[key])
          if(this.weekOfAvailabilities[key] !== undefined && this.weekOfAvailabilities[key] !== null){
            if(this.weekOfAvailabilities[key].length > 0){
              var hold = {}
              hold[key] = this.weekOfAvailabilities[key]
              openings.push(hold)
            }
          }
          // openings.push(hold)          
        }else{
          if(startDate.isBefore(endDate)){
            while(startDate.isSameOrBefore(endDate)){
              var dateTracker = startDate.toISOString(true).split('T')[0]
              if(dateTracker in this.weekOfAvailabilities){
                var hold = {}
                hold[dateTracker] = this.weekOfAvailabilities[dateTracker]
                openings.push(hold)
                
              }
              startDate.add(24,'hours')
            }
          }else{
            console.log('error avoided: infinite loop')
          }
        }
        console.log(openings)
        if(openings.length > 0){
          document.getElementById('timeRes').setAttribute('style','position: absolute;z-index:102;')

          this.selectedTimeFrame.emit(openings)
          console.log('Nah g')
        }

        



      },
    
    'beforeCreateSchedule': (e:any) =>{
        console.log('beforeCreateSchedule', e);
        e.guide.clearGuideElement()
       
        var startDate = moment(e.start._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        var endDate = moment(e.end._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)

        this.lastStartDate = moment(e.start._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        this.lastEndDate = moment(e.end._date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)

        var openings = []

        if(startDate.date() === endDate.date() && startDate.month() === endDate.month() && startDate.year() === endDate.year()){
          var key = startDate.toISOString(true).split('T')[0]
          console.log(key,this.weekOfAvailabilities,this.weekOfAvailabilities[key])
          if(this.weekOfAvailabilities[key] !== undefined && this.weekOfAvailabilities[key] !== null){
            if(this.weekOfAvailabilities[key].length > 0){
              var hold = {}
              hold[key] = this.weekOfAvailabilities[key]
              openings.push(hold)
            }
          }
          // openings.push(hold)          
        }else{
          if(startDate.isBefore(endDate)){
            while(startDate.isSameOrBefore(endDate)){
              var dateTracker = startDate.toISOString(true).split('T')[0]
              if(dateTracker in this.weekOfAvailabilities){
                var hold = {}
                hold[dateTracker] = this.weekOfAvailabilities[dateTracker]
                openings.push(hold)
                
              }
              startDate.add(24,'hours')
            }
          }else{
            console.log('error avoided: infinite loop')
          }
        }
        console.log(openings)
        if(openings.length > 0){
          document.getElementById('timeRes').setAttribute('style','position: absolute;z-index:102;')

          this.selectedTimeFrame.emit(openings)
          console.log('Nah g')
        }
        // open a creation popup
    },

    })
    console.log(calendar)
    
    var teacherHolder = false
    await this.auth.user$.subscribe(usr=>{
      this.database.database.ref('providers/userInfo/' + usr.uid).once('value').then(function(snap){
        if(snap.exists()){
          teacherHolder = true
        }
      }).then(()=>{
        this.isTeacher = teacherHolder
        if(this.isTeacher){
          this.titlePiece = 'Shared Resources and Follow List'
          // document.getElementById('teacherParent').setAttribute('style',"display:none;border-bottom-left-radius: 25px; border-bottom-right-radius: 25px;max-width: 900px;;background-color: #D8D8D8;border: 5px solid rgb(230,230,230); height: 380px;font-size: 16px;");
          // document.getElementById('teacherTeacher').setAttribute('style',"border-bottom-left-radius: 25px; border-bottom-right-radius: 25px;max-width: 900px;;background-color: #D8D8D8;border: 5px solid rgb(230,230,230); height: 380px;font-size: 16px;");
          this.getStorage().then(()=>{
            this.MyFiles.emit(this.myFiles)
            var newTeachers: TeacherProfile[] = []  
            this.database.database.ref('providers/userInfo/' + 'jhtWKnhwQAUg9cz5ZooIQZ6vuF32').once('value').then(function(snap){
              var cred = ""
              var stars = 5 
              var name = ""
              snap.child('immutable').forEach(function(child){
                if(child.key === "formalTitle"){
                  cred = child.child('val').val()
                }else if (child.key === "stars"){
                  stars = child.child('val').val() 
                }else if(child.key === "name"){
                  name = child.child('val').val()
                }
              })
              var description = snap.child('profile').child('shortIntro').child('val').val()
              var rate = snap.child('pageInfo').child('rate').val()
              var teach = new TeacherProfile('jhtWKnhwQAUg9cz5ZooIQZ6vuF32',name,rate,stars,cred,description)
              newTeachers.push(teach)
            }).then(()=>{
              this.database.database.ref('providers/userInfo/' + 'lPEBZOKJv1bbxgMQJdQh1yXOK1I3').once('value').then(function(snap){
                var cred = ""
                var stars = 5  
                var name = ""
                snap.child('immutable').forEach(function(child){
                  if(child.key === "formalTitle"){
                    cred = child.child('val').val()
                  }else if (child.key === "stars"){
                    stars = child.child('val').val() 
                  }else if(child.key === "name"){
                    name = child.child('val').val()
                  }
                })
          
                var description = snap.child('profile').child('shortIntro').child('val').val()
                var rate = snap.child('pageInfo').child('rate').val()
                var teach = new TeacherProfile('lPEBZOKJv1bbxgMQJdQh1yXOK1I3',name,rate,stars,cred,description)
                newTeachers.push(teach)
              }).then(()=>{
                this.currTeachers.emit(newTeachers)
              })
           }) 
        })
        }
      })
    })
    await this.database.database.ref('providers/userInfo/' + this.UID + '/profile/rate/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.rate = Number(hold)
    console.log(this.rate)
    
    
    await this.database.database.ref('providers/userInfo/' + this.UID + '/immutable/email/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.teacherEmail = hold

    await this.database.database.ref('providers/userInfo/' + this.UID + '/schedule/future/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.future2 = hold
    this.future = hold

    await this.database.database.ref('providers/userInfo/' + this.UID + '/immutable/name/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.name = hold
    this.hourlyRate.emit(this.rate + '~' + this.name)
    this.description = 'Hey, check out ' + this.name + '`s profile at '+ this.linkToPage +'. They are an awesome teacher!'
    this.message = "Check out " + this.name + "'s profile at " + this.linkToPage
    
    await this.database.database.ref('providers/userInfo/' + this.UID + '/immutable/formalTitle/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.cred = hold

    await this.database.database.ref('providers/userInfo/' + this.UID + '/profile/longIntro/val').once('value').then(function(snap){
      hold = snap.val()
    })
    this.longIntro = hold

    await this.database.database.ref('providers/userInfo/' + this.UID + '/profile/shortIntro/val').once('value').then(function(snap){
      hold = snap.val()
    }) 
    this.shortIntro = hold
    
///888888
    var gracePeriod 
    var general 
    var noShow
    var i36_24
    var i23_12  
    var i11_2
    var i2
    await this.database.database.ref('providers/userInfo/' + this.UID + '/policy').once('value').then(function(snap){
      snap.forEach(function(snapp){
        if(snapp.key === 'grace'){
          gracePeriod = snapp.child('val').val()
        }else if(snapp.key === 'generalFee'){
          general  = snapp.child('val').val()
        }else if(snapp.key === 'noShow'){
          noShow = snapp.child('val').val()
        }else if(snapp.key === '36_24'){
          i36_24 = snapp.child('val').val()
        }else if(snapp.key === '24_12'){
          i23_12 = snapp.child('val').val()
        }else if(snapp.key === '12_2'){
          i11_2 = snapp.child('val').val()
        }else if(snapp.key === '2_0'){
          i2 = snapp.child('val').val()
        }
      })
    })

    this.policy1 = gracePeriod
    this.policy2 = 100 - Number(general)
    this.policy3 = 100 - Number(noShow)
    this.policy4 = 100 - Number(i36_24)
    this.policy5 = 100 - Number(i23_12)
    this.policy6 = 100 - Number(i11_2)
    this.policy7 = 100 - Number(i2)

    var count = 1
    while(count < 13){
      this.months.push(count)    
      count += 1
    }
    gtag('event', 'view_item', {
      items: [{
        name: `${this.UID}`,
        brand: `${this.cred}`,
        price: `${this.teacherRate}`
      }]
    });  

  } 
  populateCalendar(weeklyAvailability,IANATimeZoneValue){
    //create a moment var moment(+ new Date(TSID)).tz('America/New_York')  mon=07:00:00-12:00:00|13:30:00-15:00:00~
    //create the moments that represent the next 7 days (We need to see each day once)
    var weekOfAvailabilities = {}
    console.log(weeklyAvailability,IANATimeZoneValue)
    var TimeStamp = + new Date()
    var newMoment = moment(TimeStamp).tz(IANATimeZoneValue)
    var count = 0
    newMoment.add(24,'hours')
    while(count < 200){
      count += 1
      console.log(newMoment.day())
      if(weeklyAvailability[`${newMoment.day()}`] !== ''){
        weeklyAvailability[newMoment.day()].split('|').forEach(opening=>{
          console.log(opening)
          console.log(newMoment,newMoment.format())


          var startHour = opening.split('-')[0].split(':')[0]
          var startMinutes = opening.split('-')[0].split(':')[1]

          var endHour = opening.split('-')[1].split(':')[0]
          var endMinutes = opening.split('-')[1].split(':')[1]

          console.log(newMoment)
          newMoment.hour(startHour)
          newMoment.minute(startMinutes)
          newMoment.second(0)

          var targetMoment = moment(newMoment)
          targetMoment.hour(endHour)
          targetMoment.minute(endMinutes)
          targetMoment.second(0)
          newMoment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          targetMoment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          console.log(newMoment.format(),targetMoment.format())
          // THIS IS is where the issue happens for overage times. We need to be able to start one day and end on another 
          //  need to mainatin this.weekOfAvailabilities 

          var timesHold = {'start':newMoment.format()}
          timesHold['end'] = targetMoment.format()

          if(`${newMoment.format().split('T')[0]}` in weekOfAvailabilities){
            weekOfAvailabilities[newMoment.format().split('T')[0]].push(timesHold)
          }else{
            weekOfAvailabilities[newMoment.format().split('T')[0]] = [timesHold]
          }

          newMoment.tz(IANATimeZoneValue)
        }) 
      }

      newMoment.add(24,'hours')
    }
    console.log(weekOfAvailabilities)
    this.weekOfAvailabilities = weekOfAvailabilities
    for (const key in weekOfAvailabilities) {
      weekOfAvailabilities[key].forEach(timeSlot=>{
        var startTime = timeSlot['start']
        var endTime = timeSlot['end']
        console.log(`${timeSlot['start']}` + '~' + `${timeSlot['end']}`)
        this.calendar.createSchedules([
          {
              id: `${timeSlot['start']}` + '~' + `${timeSlot['end']}`,
              calendarId: '1',  
              title: 'Opening(s) Available',
              body: 'body',
              category: 'time',  
              dueDateClass: '',
              start: new Date(startTime),
              end: new Date(endTime),
              bgColor: '#B1E7F3',
              borderColor: 'rgba(79, 79, 79, 1)',
              color: 'black',
              isReadOnly: true    // schedule is read-only
          },
        ]);
      })
    }
  }

  recreateCalendar(){
    this.calendar.clear()
    for (const key in this.weekOfAvailabilities) {
      this.weekOfAvailabilities[key].forEach(timeSlot=>{
        var startTime = timeSlot['start']
        var endTime = timeSlot['end']
        console.log(`${timeSlot['start']}` + '~' + `${timeSlot['end']}`)
        this.calendar.createSchedules([
          {
              id: `${timeSlot['start']}` + '~' + `${timeSlot['end']}`,
              calendarId: '1',  
              title: 'Opening(s) Available',
              body: 'body',
              category: 'time',  
              dueDateClass: '',
              start: new Date(startTime),
              end: new Date(endTime),
              bgColor: '#B1E7F3',
              borderColor: 'rgba(79, 79, 79, 1)',
              color: 'black',
              isReadOnly: true    // schedule is read-only
          },
        ]);
      })
    }
  }

  changeTimeZone(date, timeZone) {
    if (typeof date === 'string') {
      return new Date(
        new Date(date).toLocaleString('en-US', {
          timeZone,
        }),
      );
    }
  
    return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  addTime(spread){
    console.log(spread)
    var dateKey = spread[1].split('T')[0]
    console.log(dateKey)
    var timeFramesToFix = []
    var found = false
    var indexCounter = 0
    var index = 0

    this.weekOfAvailabilities[dateKey].forEach(timeFrame=>{
      console.log(spread)
      console.log(timeFrame)
      if(moment(spread[1]).isSameOrAfter(timeFrame.start) && moment(spread[1]).isSameOrBefore(timeFrame.end)){
        timeFramesToFix.push([indexCounter,timeFrame])
      }else if(moment(spread[2]).isSameOrAfter(timeFrame.start) && moment(spread[2]).isSameOrBefore(timeFrame.end)){
        timeFramesToFix.push([indexCounter,timeFrame])
      }
      if(moment(timeFrame.start).isAfter(spread[2]) && !found){
        found = true
        if(indexCounter > 0){
          indexCounter -=1
        }
        index = indexCounter
      }
      indexCounter += 1
    })
    if(!found){
      found = true
      index = indexCounter
    }
    if(timeFramesToFix.length === 0){
      var hlder = {'start':spread[1],'end':spread[2]}
      if(index === 0){
        index = 0
      }else{
        index -= 1
      }
      this.weekOfAvailabilities[dateKey].splice(index,0,hlder)
    }else{
      //turn all these values to one that is the first ones star time and the last ones end time
      if(index === 0){
        index = 0
      }else{
        index -= 1
      }
      console.log(timeFramesToFix)
      var offset = 0
      timeFramesToFix.forEach(val=>{
        console.log(Number(val[0]))
        this.weekOfAvailabilities[dateKey].splice(Number(val[0])-offset,1)
        offset += 1
        
      })
      console.log(this.weekOfAvailabilities[dateKey])
      var tfHld = [...timeFramesToFix]
      var hlder2 = [index,{'start':spread[1],'end':spread[2]} ]
      tfHld.splice(index,0,hlder2)
      console.log(tfHld[0])
      console.log(tfHld[tfHld.length-1])
      hlder = {'start':tfHld[0][1].start,'end':tfHld[tfHld.length-1][1].end}
      
      var firstIndex = timeFramesToFix[0][0]

      this.weekOfAvailabilities[dateKey].splice(firstIndex,0,hlder)
      console.log(this.weekOfAvailabilities[dateKey])
    }
    
    var startDate = moment(this.lastStartDate.format())
    var endDate = moment(this.lastEndDate.format())

    var openings = []

    if(startDate.date() === endDate.date() && startDate.month() === endDate.month() && startDate.year() === endDate.year()){
      var key = startDate.toISOString(true).split('T')[0]
      var hold = {}
      hold[key] = this.weekOfAvailabilities[key]
      openings.push(hold)
      // openings.push(hold)          
    }else{
      if(startDate.isBefore(endDate)){
        while(startDate.isSameOrBefore(endDate)){
          var dateTracker = startDate.toISOString(true).split('T')[0]
          if(dateTracker in this.weekOfAvailabilities){
            var hold = {}
            hold[dateTracker] = this.weekOfAvailabilities[dateTracker]
            openings.push(hold)
            
          }
          startDate.add(24,'hours')
        }
      }else{
        console.log('error avoided: infinite loop')
      }
    }
    this.selectedTimeFrame.emit(openings)

  }

  removeTime(spread){
    console.log(spread,this.weekOfAvailabilities)
    var startTime = spread.split('|-|')[0]
    var endTime = spread.split('|-|')[1]
    var dateKey = startTime.split('T')[0]
    var weekOfAvailabilitiesHld = []
    var indexHld
    var counter = 0
    console.log(this.weekOfAvailabilities[dateKey])
    this.weekOfAvailabilities[dateKey].forEach(frames=>{
      var hldStart = frames['start']
      var hldEnd = frames['end']
      if(moment(startTime).isSameOrAfter(hldStart) && moment(startTime).isSameOrBefore(hldEnd)){
        console.log(startTime,endTime,hldStart,hldEnd,frames)
        weekOfAvailabilitiesHld = [startTime,endTime,hldStart,hldEnd,frames,counter]
      }
      counter += 1
    })
    console.log(weekOfAvailabilitiesHld)
    console.log(this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]])

    // var newStart1 = ''
    // var newStart2 = ''
    // var newEnd1 = ''
    // var newEnd2 = ''

    if(moment(weekOfAvailabilitiesHld[0]).diff(weekOfAvailabilitiesHld[2],'minutes') === 0){
      console.log(1)
      if(moment(weekOfAvailabilitiesHld[1]).isBefore(moment(weekOfAvailabilitiesHld[3]))){
        var newStart = moment(weekOfAvailabilitiesHld[1]).format()
        var newEnd = moment(weekOfAvailabilitiesHld[3]).format()
        console.log(this.weekOfAvailabilities[dateKey])
        this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['start'] = newStart
        this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['end'] = newEnd
        console.log(this.weekOfAvailabilities[dateKey])
      }else{
        this.weekOfAvailabilities[dateKey].splice(weekOfAvailabilitiesHld[5],1)
      }
    }else if(moment(weekOfAvailabilitiesHld[1]).diff(weekOfAvailabilitiesHld[3],'minutes') === 0){
      console.log(2)
      if(moment(weekOfAvailabilitiesHld[2]).isBefore(moment(weekOfAvailabilitiesHld[0]))){
        var newStart = moment(weekOfAvailabilitiesHld[2]).format()
        var newEnd = moment(weekOfAvailabilitiesHld[0]).format()
        console.log(this.weekOfAvailabilities[dateKey])
        this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['start'] = newStart
        this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['end'] = newEnd
        console.log(this.weekOfAvailabilities[dateKey])
      }else{
        this.weekOfAvailabilities[dateKey].splice(weekOfAvailabilitiesHld[5],1)
      }
    }else{
      // creates 2 pieces 
      console.log(3)
      var startTime1 = moment(weekOfAvailabilitiesHld[2]).format()
      var endTime1 = moment(weekOfAvailabilitiesHld[0]).format()
      var startTime2 = moment(weekOfAvailabilitiesHld[1]).format()
      var endTime2 = moment(weekOfAvailabilitiesHld[3]).format()


      this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['start'] = startTime1
      this.weekOfAvailabilities[dateKey][weekOfAvailabilitiesHld[5]]['end'] = endTime1
      var hlder = {'start':startTime2,'end':endTime2}
      console.log(this.weekOfAvailabilities[dateKey])
      this.weekOfAvailabilities[dateKey].splice(weekOfAvailabilitiesHld[5]+1,0,hlder)
      console.log(this.weekOfAvailabilities[dateKey])

    }
    
    var startDate = moment(this.lastStartDate.format())
    var endDate = moment(this.lastEndDate.format())

    var openings = []
    console.log(startDate,endDate)
    console.log(startDate.isBefore(endDate),startDate.format(),endDate.format())
    if(startDate.date() === endDate.date() && startDate.month() === endDate.month() && startDate.year() === endDate.year()){
      var key = startDate.toISOString(true).split('T')[0]
      var hold = {}
      hold[key] = this.weekOfAvailabilities[key]
      openings.push(hold)
      // openings.push(hold)          
    }else{
      console.log(startDate.isBefore(endDate),startDate.format(),endDate.format())
      if(startDate.isBefore(endDate)){
        while(startDate.isSameOrBefore(endDate)){
          var dateTracker = startDate.toISOString(true).split('T')[0]
          if(dateTracker in this.weekOfAvailabilities){
            var hold = {}
            hold[dateTracker] = this.weekOfAvailabilities[dateTracker]
            openings.push(hold)
            
          }
          startDate.add(24,'hours')
        }
      }else{
        console.log('error avoided: infinite loop')
      }
    }
    console.log(openings)
    this.selectedTimeFrame.emit(openings)
  }

  openViewToggle(){
    this.openView = !this.openView
    if(this.openView){
      document.getElementById('listOfViews').setAttribute('style',"display:block;top: 28px;")
    }else{
      document.getElementById('listOfViews').setAttribute('style',"display:none;top: 28px;")
    }
  }
  openMe(){
    if(this.open){
      this.closeMe()
    }else{
      this.open = true
      document.getElementById('shareToggle').setAttribute('class','overlap-group1-1-3-1')
    }
    
  }

  closeMe(){
    console.log('closeMe')
    this.open = false
    document.getElementById('shareToggle').setAttribute('class','hidden')
  }

  closeMe2(){
    console.log('timeRes')
    this.open = false
    document.getElementById('timeRes').setAttribute('style','display:none')
  }

  closeUploader(){

  }
  async getStorage(){
    this.database.database.ref('providers/userInfo/' + this.UID + '/sharedStorage/').orderByValue().once('value',val=>{
      var key = ''
      var keyValue = ''
      if(val.key.indexOf('-') === - 1){
          key = this.UID
          keyValue = val.key
      }else{
          key = val.key.split('-')[0]
          keyValue = val.key.split('-')[1]
      }
      val.forEach(STAMP=>{
        var key = ''
        var keyValue = ''
        if(STAMP.key.indexOf('-') === - 1){
          key = this.UID
          keyValue = STAMP.key
        }else{
          key = STAMP.key.split('-')[0]
          keyValue = STAMP.key.split('-')[1]
        }
        this.database.database.ref('communityResources/' + `${key}` + '/' + `${keyValue}`).once('value').then(snappa=>{
          this.myFiles.unshift(['SHAREDFILE',STAMP.key,true,this.UID,snappa.child('DownRepo').val(),snappa.child('TS').val(),snappa.child('Title').val(),snappa.child('Desc').val()])
        })
      })
    })
  }

  add(event: MatChipInputEvent): void {
    event.input.value = ''
    const value = (event.value || '').trim();
    // Add our fruit
    if(this.fruits.length === 0){
      document.getElementById('emailContainer').setAttribute('class','overlap-group6-1')
      const boxes = document.querySelectorAll('.mat-form-field-label-wrapper');
      boxes.forEach(box => {
        console.log(box)
        box.remove();
      });
    }
    if (value) {
      var newVals:Email[] = []
      console.log(value,value.split(' ').length,value.split(',').length)
      if(value.split(',').length > 1){
        value.split(',').forEach(email=>{
          if(email.trim().includes('@')){
            this.fruits.unshift({name: email.trim(),valid:true});
            newVals.unshift({name: email.trim(),valid:true});
          }else{
            this.fruits.unshift({name: email.trim(),valid:false});
            newVals.unshift({name: email.trim(),valid:false});
          }
        })
      }else if(value.split(' ').length > 1){
        console.log(value.split(' '))
        value.split(' ').forEach(email=>{
          console.log(email)
          if(email.trim().includes('@')){
            this.fruits.unshift({name: email.trim(),valid:true});
            newVals.unshift({name: email.trim(),valid:true});
          }else{
            this.fruits.unshift({name: email.trim(),valid:false});
            newVals.unshift({name: email.trim(),valid:false});
          }
        })
      }else{
        if(value.trim().includes('@')){
          this.fruits.unshift({name: value.trim(),valid:true});
          newVals.unshift({name: value.trim(),valid:true});
        }else{
          this.fruits.unshift({name: value.trim(),valid:false});
          newVals.unshift({name: value.trim(),valid:false});
        }
      }
      setTimeout(() => {
        newVals.forEach((val:Email)=>{
          if(!val.valid){
            document.getElementById(`${val.name}`).setAttribute('style','background-color: red;border-radius: 15px;margin: 0px;margin-right: 6px;margin-bottom: 6px;')
          }
        })
      }, 0);
    }
    // Clear the input value
  }

  remove(fruit: Email): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
    if(this.fruits.length === 0){
      document.getElementById('emailContainer').setAttribute('class','overlap-group6-2')
    }
  }

  toggleInputs(TYPE){
    if(TYPE === "subject"){
      if(this.lastType === "subject"){
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
        this.lastType = ''
      }else{
        this.input1 = true
        this.input2 = false
        this.input3 = false
        this.input4 = false
        this.input5 = false
        this.input6 = false
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-1')
        this.lastType = TYPE
      }
    }else if(TYPE === "session"){
      if(this.lastType === "session"){
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
        this.lastType = ''
      }else{
        this.input1 = false
        this.input2 = true
        this.input3 = false
        this.input4 = false
        this.input5 = false
        this.input6 = false
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-2')
        this.lastType = TYPE
      }
    }else if(TYPE === "hourly"){
      if(this.lastType === "hourly"){
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
        this.lastType = ''
      }else{
        this.input1 = false
        this.input2 = false
        this.input3 = true
        this.input4 = false
        this.input5 = false
        this.input6 = false
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-3')
        this.lastType = TYPE
      }
    }else if(TYPE === "date"){
      if(this.lastType === "date"){
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
        this.lastType = ''
      }else{
        this.input1 = false
        this.input2 = false
        this.input3 = false
        this.input4 = true
        this.input5 = false
        this.input6 = false
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-4')
        this.lastType = TYPE
      }
    }else if(TYPE === "startTime"){
      if(this.lastType === "startTime"){
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
        this.lastType = ''
      }else{
        this.input1 = false
        this.input2 = false
        this.input3 = false
        this.input4 = false
        this.input5 = true
        this.input6 = false
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-5')
        this.lastType = TYPE
      }
    }else if(TYPE === "endTime"){
      if(this.lastType === "endTime"){
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
        this.lastType = ''
      }else{
        this.input1 = false
        this.input2 = false
        this.input3 = false
        this.input4 = false
        this.input5 = false
        this.input6 = true
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-6')
        this.lastType = TYPE
      }
    }
  }

  onResized(event: ResizedEvent) {    
    this.width = event.newWidth;   
    this.height = event.newHeight;

    if(this.width < 700){
      this.mobile.emit(true)
      this.computer = false
    }else{
      this.mobile.emit(false)
    }
  }

  toggleCircs(ID){
    document.getElementById('dropship').setAttribute('class','overlap-group1-1')
    var circ = document.getElementById(ID)
    circ.setAttribute('class','overlap-group2')
    var newChild = document.getElementById('dot')
    circ.append(newChild)
    console.log(ID)
    this.changeViews(ID)

  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(()=>resolve(''), ms)).then(()=>console.log("fired"));
  }

  async getProPic(){
    var teacherUID = this.UID
    // Create a reference from a Google Cloud Storage URI
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/teacherPics/' + teacherUID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById('proPic').setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      var key = ''
      var randomNum = Math.random()
      console.log(randomNum)
      if(Number(randomNum) === 1){
        randomNum =.98
      }
      var randomNumber = Math.floor(randomNum * 7) 
      if(randomNumber === 0){
        randomNumber = 1
      }
       
      if(randomNumber === 1){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png'
      }else if(randomNumber === 2){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-48@2x.png'
      }else if(randomNumber === 3){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-49@2x.png'
      }else if(randomNumber === 4){
        key = '../../../assets/Giraf.png'
      }else if(randomNumber === 5){
        key = '../../../assets/Cat.png'
      }else if(randomNumber === 6){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-50@2x.png'
      }
      document.getElementById('proPic').setAttribute('src',`${key}`);;
      // document.getElementById(`${teacherHold.UID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }

  complete(){
    if(this.emailRec !== "" && this.emailSen !== ""){
      this.notSent = false 
      this.sendEmail()
      this.errorMsg = ["Message sent!"]
    }else{
      this.errorMsg = ["You need both an email and a message"]
    }
  }
  formatTime(num:number):string{
    if(num < 1200){
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ "am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + "am"
        }      
      } 
    }else{
      var holdVar = Math.floor(num/100)-12
      if(holdVar === 0){
        holdVar = 12
      }
      if(num%100 === 0){
        return String(holdVar)+ "pm"
      }else{ 
        return String(holdVar)+":"+String(num%100) + "pm"
      }       

    }
  }
  
  async getStars(){
    // var hold;
    // await this.database.database.ref('providers/userInfo/' + this.UID + '/immutable/stars/val').once('value').then(function(snap){
    //   hold = Number(snap.val())
    //   var count = 1
    //   while(count <= hold){
    //     // var inpa = "star" + String(count)
    //     // document.getElementById(inpa).outerHTML = '<span class="fa fa-star checked" style = "color:orange;"></span>';
    //     count += 1
    //   }
    // })
  }

  getDates(){ 
    var minDate = new Date(); 
    var count = 1 
    while(count < 200){
        var dd3 = String(minDate.getDate()).padStart(2, '0');
        var mm3 = String(minDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy3 = minDate.getFullYear();
        var todays3 =  yyyy3 + '-' + mm3 + '-' + dd3;
        this.validDays1.push(todays3)
        this.validDays2.push(todays3)
        minDate = new Date(minDate.getTime() + ((1000 * 60 * 60 * 24)))
        count += 1
    }
 
  }

  getTimesSingle(startTime,endTime){
    var currTime = startTime
    var holdRet = []
    while(endTime >= currTime){
      if(currTime%100 ===5){
        currTime = currTime - 5
      }
      if(currTime%100 < 60){
        holdRet.push(currTime)
      }
      currTime +=15
    }
    if(currTime%100 ===5){
      currTime = currTime - 5
      holdRet.push(currTime)
    }
    return holdRet
  }

  getTimesDef(startTime,endTime){
    var currTime = startTime
    while(endTime >= currTime){
      if(currTime%100 ===5){
        currTime = currTime - 5
      }
      if(currTime%100 < 60){
        this.listNum1.push(currTime)
        this.listNum2.push(currTime)
      }
      currTime +=15
    }
    if(currTime%100 ===5){
      currTime = currTime - 5
      this.listNum1.push(currTime)
      this.listNum2.push(currTime)
    }
    
  }

  sendEmail(){
    const sendMail = this.fns.httpsCallable('sendMail')
    sendMail({
      address: `${this.emailRec}`,
      subject:`${"Someone has Shared a Verified Teacher's Page"}`,
      msg:`${this.message}`,
    }).toPromise().then(()=> console.log("success")).catch((e)=> console.log('err',e))
  }

  dateFormat(day){
    if(day ==='Monday'){
      return 'Monday'
    }else if(day ==='Tuesday'){
      return 'Tuesday'
    }else if(day ==='Wednesday'){
      return 'Wednesday'
    }else if(day ==='Thursday'){
      return 'Thursday'
    }else if(day ==='Friday'){
      return 'Friday'
    }else if(day ==='Saturday'){
      return 'Saturday'
    }else if(day ==='Sunday'){
      return 'Sunday'
    }else if(day ==='ASAP'){
      return 'ASAP'
    }
    else{
      var hold = day.split("-")
      var hold2 = day
      var day = hold[2]
      var month = hold[1]     
      var nn = new Date(`${hold2}`)
      var refit = ''
      hold2.split('-').forEach(timePiece =>{
        if(timePiece.length === 1){
          if(refit === ''){
            refit = '0' + timePiece
          }else{
            refit += '-0' + timePiece
          }
        }else{
          if(refit === ''){
            refit = timePiece
          }else{
            refit += '-' + timePiece
          }
        }
      })
      var nn2 = new Date(`${refit}`)
      return this.getDay(nn2.getDay()) + " " + this.getMonth(month) + " " +  day 
    }
  }

  reqRate(rate){
    this.reqSessionRate = '$' + rate + '/hr' 
  }

  

  getDay(month){
    month = Number(month)
    if(month === 0){
      return "Mon"
    }else if(month === 1){
      return "Tue"
    }else if(month === 2){
      return "Wed"
    }else if(month === 3){
      return "Thu"
    }else if(month === 4){
      return "Fri"
    }else if(month === 5){
      return "Sat"
    }else if(month === 6){
      return "Sun"
    }
  }

  getMonth(month){
    month = Number(month)
    if(month === 1){
      return "Jan"
    }else if(month === 2){
      return "Feb"
    }else if(month === 3){
      return "Mar"
    }else if(month === 4){
      return "Apr"
    }else if(month === 5){
      return "May"
    }else if(month === 6){
      return "June"
    }else if(month === 7){
      return "July"
    }else if(month === 8){
      return "Aug"
    }else if(month === 9){  
      return "Sep"
    }else if(month === 10){
      return "Oct"
    }else if(month === 11){
      return "Nov"
    }else{
      return "Dec"  
    }
  }
  
  async reqTime(){
    this.logging2 = true
    var dbRef = this.database
    var serviceHold = this.chosenServices
    var UIDHold = this.UID
    var teacherRate = this.rate
    var teacherOffset = ((new Date().getTimezoneOffset()/60) - this.teacherTimeZoneOffset) * 100 
    var ttzHold = this.teacherTimeZoneOffset
    var teacherName = this.name
    var teacherEmail = this.teacherEmail
    var credHold = this.cred
    var reqRate = this.reqSessionRate
    var numSessions = this.reqSessionNum 
    this.reqSessionRate = this.reqSessionRate.substr(1)
    this.reqSessionRate = this.reqSessionRate.split('/')[0]
    console.log(this.reqSessionRate,this.reqSessionNum)
    if(this.reqSessionRate !== '' && (this.reqSessionNum !== '' || this.nonrecurr)){
      if(this.now){
        await this.nowAdd()
      }
      if(this.timeRequests.length > 0){
        console.log(1)
        if(this.isTeacher === false){
          console.log(2)
          if(auth().currentUser){   
            console.log(3)
            var times = ''
            var teacherTime = ''
            var count = 1
            await this.timeRequests.forEach(val=>{
              if (count === 1){
                times += val[4] 
                var holdS = Number(val[4].split('-')[3]) + teacherOffset
                var holdE = Number(val[4].split('-')[4]) + teacherOffset
                teacherTime += val[4].split('-')[0] + '-' + val[4].split('-')[1] + '-' + val[4].split('-')[2] + '-' + String(holdS) + '-' + String(holdE)
              }else{
                times += "|" + val[4] 
                var holdS = Number(val[4].split('-')[3]) + teacherOffset
                var holdE = Number(val[4].split('-')[4]) + teacherOffset
                teacherTime += "|" + val[4].split('-')[0] + '-' + val[4].split('-')[1] + '-' + val[4].split('-')[2] + '-' + String(holdS) + '-' + String(holdE)
              }
              count += 1
            })
            const reqTime = this.fns.httpsCallable('reqTime')
            console.log()
            reqTime({
              ParentUID:auth().currentUser.uid,
              TeacherUID:this.UID,
              TeacherEmail:teacherEmail,
              ParentEmail:auth().currentUser.email,
              requestedTimes:times,
              services:this.chosenServices,  
              numSessions:this.reqSessionNum,
              UID:this.UID,
              teacherOffset: teacherOffset,
              teacherRate: teacherRate,
              teacherName:teacherName,
              ttz:ttzHold,
              teacherTime:teacherTime,
              rate:this.reqSessionRate
            }).toPromise().catch(e => console.log(e)).then(val=>{
              console.log('/users/' + `${auth().currentUser.uid}`+ '/requests')
              dbRef.database.ref('/users/' + `${auth().currentUser.uid}`+ '/requests').push({
                services:serviceHold,
                numSessions: numSessions,
                times:times,
                ParentUID:auth().currentUser.uid,
                TeacherUID:UIDHold,
                TeacherEmail:teacherEmail,
                ParentEmail:auth().currentUser.email,
                agreed:{val:''},
                UID: {val:val},
                total:{val:''},
                teacherOffset: teacherOffset,
                teacherRate: teacherRate,
                teacherName:teacherName,
                ttz:ttzHold,
                rate:reqRate,
              }).then(()=>{
                gtag('event', 'add_to_cart', {
                  currency: 'USD',
                  items: [{
                    name: `${UIDHold}`,
                    brand: `${credHold}`,
                    category: `${serviceHold}`,
                    price: `${reqRate}`,
                    quantity: Number(numSessions)
                  }]
                });
                console.log('profile/' + `${auth().currentUser.uid}`)
                this.rerouter.navigateByUrl('profile/' + `${auth().currentUser.uid}`)
              }).finally((()=>{
                this.logging2 = false
              }))        
            }).catch(e => console.log(e))
          }else{
            //make them login
            this.logging2 = false
            this.createLogin2 = ["Please create an account."]
          }
        }else{
          //please create a non teacher account
          this.logging2 = false
            this.createLogin2 = ["Sorry, you need a non teacher account"]
 
        }
      }else{
        this.logging2 = false
        this.createLogin2 = ["# of sessions and rate needed."]
      }
    }else{
      //error msg   
      this.logging2 = false
    }
  }

  nowAdd(){
    var value = Number(this.reqSessionNum)
    var time = '00' + this.reqSessionNum
    if(value > 60){
      time = '00'+ String(value)
    }else if(value === 60){
      time = '0100'
    }else if(value === 75){
      time = '0115'
    }else if(value === 90){
      time = '0130'    
    }else if(value === 105){
      time = '0145'
    }else if(value === 120){
      time = '0200'
    }
 
    this.timeRequests =[['ASAP-ASAP-ASAP','0000',time,'1',`${'ASAP-ASAP-ASAP-0000-' +time}`]]
    
  }

  nonRecurrAdd(){
    var unique = true;
    if(this.reqDate !== '' && this.reqStartTime!== '' && this.reqEndTime !== ''){
      this.timeRequests.forEach(val =>{
        if(val[4] === this.reqDate + "-" +this.reqStartTime + "-" +this.reqEndTime ){
          unique = false
        }
      })
      if(unique){
         
        this.timeRequests.push([this.reqDate,this.reqStartTime,this.reqEndTime,'',this.reqDate + "-" +this.reqStartTime + "-" +this.reqEndTime])
         
      }
    }else{
      //error
      var err = 'Looks like you forgot:  '
      if(this.reqDate !== '' ){
        err += 'a date'
      }
      if(this.reqStartTime!== ''){
        err += ', a startime'
      }
      if(this.reqEndTime !== ''){
        err += ', an end time'

      }
      if(this.reqSessionNum  !== ''){
        err += ', # of sessions'
      }
      this.err = [err]
    }
  }

  recurrAdd(){
    var unique = true;
    if(this.reqDate !== '' && this.reqDateEnd !== '' && this.reqStartTime!== '' && this.reqEndTime !== ''){
      var day = 0
      if(this.reqDate === 'Monday'){
        day =  0
      }else if(this.reqDate === 'Tuesday'){
        day = 1
      }else if(this.reqDate === 'Wednesday'){
        day = 2
      }else if(this.reqDate === 'Thursday'){
        day = 3
      }else if(this.reqDate === 'Friday'){
        day = 4
      }else if(this.reqDate === 'Saturday'){
        day = 5
      }else if(this.reqDate  === 'Sunday'){
        day = 6
      }

      var currDate = new Date()
      var count = 0
      var numSessions = Number(this.reqDateEnd)
      var dates = []
      if(this.reqSessionNum === ''){
        this.reqSessionNum = String(numSessions)
      }else{
        this.reqSessionNum = String(Number(this.reqSessionNum) + numSessions)
      }
      console.log(count,numSessions)
      while(count !== numSessions){
        console.log(count,numSessions)
        if(currDate.getDay() === day){
          dates.push(currDate.getFullYear() + '-' + `${currDate.getMonth() + 1}` + '-' + currDate.getDate())
          count += 1
        }
        currDate = new Date(currDate.getTime() + (1000 * 60 * 60 * 24));
      }
      dates.forEach(date=>{
        this.timeRequests.forEach(val =>{
          if(val[4] === date + "-" + this.reqStartTime + "-" +this.reqEndTime ){
            unique = false
          }
        })
        if(unique){
          this.timeRequests.push([`${date}`,this.reqStartTime,this.reqEndTime,'' ,date + "-" +this.reqStartTime + "-" +this.reqEndTime])
 
        }
      })

    }else{
      //error
      var err = 'Looks like you forgot:  '
      if(this.reqDate !== '' ){
        err += 'a date'
      }
      if(this.reqStartTime!== ''){
        err += ', a startime'
      }
      if(this.reqEndTime !== ''){
        err += ', an end time'

      }
      if(this.reqSessionNum  !== ''){
        err += ', # of sessions'
      }
      this.err = [err]
    }
  }

  addReqTime(){
    document.getElementById('dropship').setAttribute('class','overlap-group1-1')
    this.lastType = ''
    if(this.nonrecurr){
      this.nonRecurrAdd()
    }else if(this.now){
      this.nowAdd()
    }else if(this.recurr){
      this.recurrAdd()
    }
  }

  clearReq(val2){
    this.timeRequests = this.timeRequests.filter(val => val[4] !== val2)
  }


  async donate(){
    this.isGettingCheckout = true
    this.stripe = await loadStripe(environment.stripeP);
    const stripeCheckout = this.fns.httpsCallable('stripeCheckout')
    stripeCheckout({
      product_name: 'title List for order',
      quantity:1,
      unit_amount: this.donationAmount
    }).toPromise().then((sessionId:string)=> this.stripe.redirectToCheckout({sessionId})).catch((e)=> console.log('err',e)).finally(()=> this.isGettingCheckout = false)
  }

  getParentID(){
    
  }

  //take in the teachers hourly rate

  // this is where we will add in the parent times
  async handleAddToCart(){
    if(auth().currentUser){
      this.createLogin = []
      this.logging = true; 
      var title = ""
      var numberOfHours = 0
      var appointmentTimes1 = ''  
      var appointmentTimes2 = ''  
      var appointmentTimes3 = ''  
      var appointmentTimes4 = ''
      var teacherEmail = ''
      await this.database.database.ref('providers/userInfo/' + this.UID + '/immutable/email/val').once('value').then(function(snap){
        teacherEmail = snap.val()
      })
      var parentEmail = ''
      var holdAppointments:string = ''  
      var start = ''
      var end = ''
      var date = ''
      this.orders.forEach(val=>{
        if(title === "empty" ||title === ""){
          title +=  val.getTitle()
          numberOfHours += val.getTotalTime()
          start = String(val.rawStart)
          end = String(val.rawEnd)
          date = val.time
          holdAppointments += '|' + String(date) + '-' + String(start)+ '-' + String(end)
        }else{
          title += " " + val.getTitle()+ ", "
          numberOfHours += val.getTotalTime()
          start = String(val.rawStart)
          end = String(val.rawEnd)
          date = val.time
          holdAppointments += '|' + String(date) + '-' + String(start)+ '-' + String(end)
        }
      })
      if(holdAppointments.length < 475){
        appointmentTimes1 = holdAppointments.substr(1)
      }else if(holdAppointments.length >= 475 && holdAppointments.length < 950){
        appointmentTimes1 = holdAppointments.substr(1,475)
        appointmentTimes2 = holdAppointments.substr(476,950)
      }else if(holdAppointments.length >= 950 && holdAppointments.length < 1425){
        appointmentTimes1 = holdAppointments.substr(1,475)
        appointmentTimes2 = holdAppointments.substr(476,950)
        appointmentTimes3 = holdAppointments.substr(951,1425)
      }else if(holdAppointments.length >= 1425 && holdAppointments.length < 1900){
        appointmentTimes1 = holdAppointments.substr(1,475)
        appointmentTimes2 = holdAppointments.substr(476,950)
        appointmentTimes3 = holdAppointments.substr(951,1425)
        appointmentTimes4 = holdAppointments.substr(1426,1900)
      }    
      var parentUID = ''
      var parentEmail = ''
      var user = this.afAuth.currentUser.then(val =>{
        if(user){
          parentUID = `${val.uid}`
          parentEmail = `${val.email}`
        }else{
          //prompt to create an account, set boolean to allow them to continue
          //we just need an email to send verification emails to
        }
      });



  
      var tot = Math.floor(((numberOfHours * this.rate) * 1.095)*100) / 100 
      var ptz = (new Date().getTimezoneOffset()/60)
      this.isGettingCheckout = true
      this.stripe = await loadStripe(environment.stripeP);
      const stripeCheckout = this.fns.httpsCallable('stripeCheckout')
      stripeCheckout({
        dateTime:'',
        appointmentTimes1: appointmentTimes1,
        appointmentTimes2: appointmentTimes2,
        teacherName: this.name,
        services: this.chosenServices,
        teacherEmail: teacherEmail,
        parentEmail: parentEmail,
        teacherUID: this.UID,
        parentUID: parentUID,
        product_name: title,
        quantity:1, 
        unit_amount: tot,
        timeZone: ptz,
        timeZone2:this.teacherTimeZoneOffset,
        requestType:'0',
        total:tot
      }).toPromise().then((sessionId:string)=> this.stripe.redirectToCheckout({sessionId})
      .then(function(va){} )).catch((e)=> console.log('err',e)).finally(()=> {
        this.logging = false
      })
    }else{
      this.createLogin = ["you need an account to comtinue. Don't worry, you just need to enter an email and password in the account tab above"]
    }
  }

  getName(){

  } 
  
  format(input){
    if(input === "TTT"){
      return "Teaching Technology Troubleshooting"
    }else if(input === "Special_needs"){
      return "Special Needs"
    }else if(input === "Resume"){
      return "Resume Help"
    }else if(input === "Interview"){
      return "Interview Help"
    }else if(input === "THT"){
      return "Teachers help teachers"
    }else if(input === "Digital_m"){
      return "Digital Media"
    }else if(input === "Digitial_d"){
      return "Digital Design"
    }else if(input === "Collab_tools"){
      return "Collaboration Tools"
    }else if(input === "Online_tools"){
      return "Online Learning Tools" 
    }else if(input === "Higher_ed"){
      return "Higher Education"
    }else if(input === "Specialized"){
      return "Specialized Learning"
    }else{
      return input
    }
  }

  saveTitle(title1){
    var title = title1
 
    if(this.chosenServices.includes(title)){
      //grey
      document.getElementById(title).style.background = '#292F45'
      if(this.chosenServices.split(',').length === 1){
        this.chosenServices = ""
      }else if(this.chosenServices.split(",")[0] === title){
        this.chosenServices = this.chosenServices.replace(title + ",","")
      }else if(this.chosenServices.split(",")[this.chosenServices.split(",").length-1] === title){
        this.chosenServices = this.chosenServices.replace(","+title,"")
      }else{
        this.chosenServices = this.chosenServices.replace("," + title + ",","")
      }    
    }else{  
      document.getElementById(title).style.background = '#EA6F66'
      if(this.chosenServices === ""){
        this.chosenServices = title
      }else{
        this.chosenServices += "," + title 
      }
    }
  }

  setRegDate(val){
    this.reqDate = val
    this.slotPlaceHolder2 = this.dateFormat(val)
  }
  setRegDate2(val){
    this.reqDateEnd = val
    this.slotPlaceHolder22 = val
  }
  
  storeEmail(email){
    console.log(email)
  }

  setStartTime(val){
    //update list2 -- 
    this.listNum2 = this.getTimesSingle(1300,2100)
    this.listNum2 = this.listNum2.filter(num => num > val)
    this.reqStartTime = val
    this.slotPlaceHolder4 = this.formatTime(val)
  }

  setEndTime(val){
    this.listNum1 = this.getTimesSingle(1300,2100)
    this.listNum1 = this.listNum1.filter(num => num < val)
    this.reqEndTime = val
    this.slotPlaceHolder5 = this.formatTime(val)
  }

  setSessionNum(val:string){
    this.reqSessionNum = val
    this.reqDateEnd = val
    this.slotPlaceHolder6 = val
  }

  addToCar(time){
    if(this.chosenServices === ""){
      var hold = new title_rate_time("General",time[0],time[1],time[3],time[4])
      hold.total = time[2]
      hold.rawStart = time[3]
      hold.rawEnd = time[4]
      this.orders.push(hold)
    }else{
      var hold = new title_rate_time(this.chosenServices,time[0],time[1],time[3],time[4])
      hold.total = time[2]
      hold.rawStart = time[3]
      hold.rawEnd = time[4]
      this.orders.push(hold)
    }
  }

  resetServices(bool){ 
       
  }
   
  addBack(ord:title_rate_time){
    this.orders = this.orders.filter(val => val !== ord)
  }

  
  async getListing2(){
    var listingHold = []    
    var listingStringHold = ''
    await this.database.database.ref('providers/userInfo/' + this.UID + '/services/activeDays/val').once('value').then(function(snap){
      listingHold = snap.val().split(',')
      var split = snap.val().split(',')
      split.forEach(ser=>{
        if(listingStringHold === ''){
          listingStringHold = ser   
        }else{
          listingStringHold += ', ' + ser
        }
      })
    }).then(()=>{
      this.listings = listingHold  
      this.listingString = listingStringHold
    })
   
  }

  setServices(services){
    if(this.serviceValues.includes(services)){
      this.serviceValues.splice(this.serviceValues.indexOf(services),1)
      document.getElementById(services).setAttribute('style','width:100%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    }else{
      this.serviceValues.push(services)
      document.getElementById(services).setAttribute('style','width:100%;background-color:#b0e6f3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    }
    35
    if(this.serviceValues.toString().length > 35){
      this.subjects = this.serviceValues.toString().slice(0,35) + '...'
    }else{
      this.subjects = this.serviceValues.toString()
    }
    
  }
   
  changeViews(type){
    console.log(type)
    if(type === 'nonrecurr'){
      console.log('uo')
      this.reqSessionNum = ''
      this.slotPlaceHolder6 = '# Sessions'
      this.recurr = false
      this.now = false    
      this.nonrecurr = true
      this.slotPlaceHolder2 = 'Date' 
      // this.sessionList = [1,2,3,4,5,6,7,8,9,10]
      this.slotPlaceHolder2 = 'Date'
      this.validDays1 = []
      this.getDates()
      this.slotPlaceHolder6 = '# of Sessions'
    }else if(type === 'recurr'){
      this.slotPlaceHolder2 = 'Start Date' 
      this.recurr = true
      this.validDays1 = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
      this.validDays2 = [1,2,3,4,5,6,7,8,9,10]
      this.sessionList = [1,2,3,4,5,6,7,8,9,10]
      this.slotPlaceHolder2 = 'Day'
      this.slotPlaceHolder6 = '# Of Sessions'
      this.now = false
      this.nonrecurr = false 
    }else if(type === 'now'){
      this.reqSessionNum = ''
      this.recurr = true
      this.now = true
      this.nonrecurr = true
      this.sessionList = [15,30,45,60,75,90,105,120]
      this.slotPlaceHolder6 = 'Minutes Needed'
    }
 
    // var hold = this.previousTypeOfBook
    // document.getElementById(`${hold}`).style.background = '#292F45'
    // this.previousTypeOfBook = `${type}`
    // document.getElementById(`${type}`).style.background = '#EA6F66'
  }
 
  async getListing(){
    var returnInfo:ServiceDescription[] = []
    var hold = await this.database.database.ref('providers/userInfo/' + this.UID + '/services').once('value').then(function(snap){
      snap.forEach(function(childSnapshot) {
        if(childSnapshot.key !== 'activeDays' && childSnapshot.key !== 'currentTitles' && childSnapshot.key !== 'grades'){
          if(childSnapshot.child('title').child('val').val() !== "empty" && childSnapshot.child('title').child('val').val() !== ""){
            var key = childSnapshot.child('title').child('val').key;
            var childData = childSnapshot.child('title').child('val').val()
            returnInfo.push(new ServiceDescription(childSnapshot.child('title').child('val').val(),childSnapshot.child('desc').child('val').val()))
          } 
        }
      });
    });
    this.listings = returnInfo
   }

   async getTimes(){
    var returnInfo:hour_min[] = []
    var map = new Map(
      [
      ["mon", ""],
      ["tue", ""],
      ["wed", ""],
      ["thu", ""],
      ["fri", ""],
      ["sat", ""],
      ["sun", ""]
      ]
    );
    var timeOff:number;
    var future:number;
    var upcomingApp = "";  
    var hold = await this.database.database.ref('providers/userInfo/' + this.UID  + '/schedule').once('value').then(function(snap){
      snap.forEach(function(childSnapshot) {
        if(childSnapshot.key === 'timeZoneOffset'){
          timeOff = childSnapshot.val()
        } else if(childSnapshot.key === 'future'){
          future = childSnapshot.val()
        }else if(childSnapshot.key === 'futureBookings'){
          upcomingApp = childSnapshot.val().val
        }else{
          var hours:string = childSnapshot.val()
          var day:string = childSnapshot.key
          if(hours['val'] !== 'empty'){
            map.set(day,hours['val'])
            returnInfo.push(new hour_min(day,hours))
            
          }
        }
      });
    });
    this.upcomingApp = upcomingApp
    this.future = future
    this.teacherTimeZoneOffset = timeOff
    this.timeSlots = returnInfo
    this.schedule = map
    this.order()
   }
   
  order(){
    var empty = true
    for (let key of this.schedule.keys()) {
      if(this.schedule.get(key) !== ""){
        empty = false
        this.correctOrder.push(new hour_min(key,this.schedule.get(key)))
      }
    }
    if(!empty){
      this.empty = false
    }
  }

}
