<div style="width: 100%;
height: 1202px;
background: rgba(250, 250, 250, 0.9);
overflow: hidden;
z-index: 2;
top: 0px;
left:0px;
position: absolute;"></div>
<div class="group-852">
    <div class="flex-col">
      <div class="flex-row-3">
        <h1 class="title">Edit Profile</h1>
        <div class="overlap-group12">
          <div class="vector" style="cursor: pointer;" (click) = 'closeMe()'></div>
        </div>
      </div>
      <div class="flex-row-4">
        <div class="flex-col-1">
          <div class="flex-row-5">
            <div class="flex-col-2">
              <!--  -->
              <img id = 'proPic2'class="ellipse-18" src="../../../assets/zebra.svg" />
              <!-- <div class="frame-268">
                <img class="download" src="../../../assets/download.svg" />
                <div class="upload-new">Upload New</div>
              </div> -->
            </div>
            <div class="flex-col-3">
              <div class="frame-348">
                <div class="place inter-semi-bold-abbey-16px">Name</div>
                <div class="group-314">
                  <div class="overlap-group-6">
                    <input class="first-name-middle-i body" value="{{studentName}}">
                  </div>
                </div>
              </div>
              <div class="group-163">
                <div class="favourite-subjects inter-semi-bold-abbey-16px">
                  Favourite Subjects
                </div>
                <div class="overlap-group-7">
                  <input class="text body" value = 'Math, Ecology, Geology'>
                </div>
              </div>
            </div>
          </div>
          <p class="choose-the-color-of-your-profile inter-semi-bold-abbey-16px">
            Choose the color of your Profile
          </p>
          <div class="frame-462">
            <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("FFFFFF-F3F3F3-2A8499")'class="ellipse-89" id = 'FFFFFF-F3F3F3-2A8499'></div>
            <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("F2FEFF-C6DFE1-81CBD1")'class="ellipse-90" id = 'F2FEFF-C6DFE1-81CBD1'></div>
            <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("F8F2FF-DAD4E1-C1A1E8")' class="ellipse-95" id = 'F8F2FF-DAD4E1-C1A1E8'></div>
            <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("FEFFD1-DADBA5-ECEF73")' class="ellipse-91" id = 'FEFFD1-DADBA5-ECEF73'></div>
            <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("EDFFD0-D2E2B8-B4F152")' class="ellipse-94" id = 'EDFFD0-D2E2B8-B4F152'></div>
            <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("FFF1F8-DCBACB-F398C6")' class="ellipse-93" id = 'FFF1F8-DCBACB-F398C6'></div>
          </div>
          <div class="displayed-awards inter-semi-bold-abbey-16px">
            Choose Page Background
          </div>
        </div>
        <img class="line-7" src="line-7.png" />
        <div class="flex-col-4">
          <div class="choose-your-alian inter-semi-bold-abbey-16px">
            Choose Your Alien
          </div>
          <div class="group-754">
            <div class="overlap-group-container">
              <div class="overlap-group-container-1" style="cursor: pointer;" (click)='updateAlienColor("pink")'>
                <div class="overlap-group1">
                  <img class="alienNeck" src="../../../assets/pinkAlienNeck.svg" />
                  <img class="vector-93" src="../../../assets/pinkAlienHead.svg" />
                  <img id = 'pinkAlienSelected' class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                  />
                </div>
              </div>
              <div class="overlap-group2-1" style="cursor: pointer;" (click)='updateAlienColor("yellow")'>
                <img class="alienNeck" src="../../../assets/yellowAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/yellowAlienHead.svg" />
                <img id = 'yellowAlienSelected'class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                />
              </div>
            </div>
            <div class="overlap-group-container-2">
              <div class="overlap-group-1" style="cursor: pointer;" (click)='updateAlienColor("green")'>
                <img class="alienNeck" src="../../../assets/greenAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/greenAlienHead.svg" />
                <img id = 'greenAlienSelected'class= 'alienNotSelected' src="../../../assets/alienSelected.svg"/>
              </div>
              <div class="overlap-group-2" style="cursor: pointer;" (click)='updateAlienColor("blue")'>
                <img class="alienNeck" src="../../../assets/blueAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/blueAlienHead.svg" />
                <img id = 'blueAlienSelected'class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                />
              </div>
            </div>
            <div class="overlap-group-container-3">
              <div class="overlap-group-1"style="cursor: pointer;" (click)='updateAlienColor("purple")'>
                <img class="alienNeck" src="../../../assets/purpleAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/purpleAlienHead.svg" />
                <img id = 'purpleAlienSelected'class ='alienSelected' src="../../../assets/alienSelected.svg"
                />
              </div>
              <div class="overlap-group-2"style="cursor: pointer;" (click)='updateAlienColor("orange")'>
                <img class="alienNeck" src="../../../assets/orangeAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/orangeAlienHead.svg" />
                <img id = 'orangeAlienSelected'class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="flex-row-6">
        <img class="chevron-right" src="chevron-right-1.png" />
        <div class="frame-639">
          <div class="group-569">
            <div class="overlap-group-8">
              <div class="group-642-3">
                <img class="check-1" src="check-1.png" />
              </div>
            </div>
          </div>
          <div class="group">
            <div class="overlap-group-3">
              <div class="group-1">
                <div class="group-413">
                  <div class="overlap-group-4 border-6px-main-grey">
                    <img class="ellipse-62" src="ellipse-62.png" />
                  </div>
                </div>
              </div>
              <img class="image-14" src="image-14.png" />
              <div class="group-642">
                <img class="check" src="check.png" />
              </div>
            </div>
          </div>
          <div class="group">
            <div class="overlap-group-3">
              <div class="group-1">
                <div class="group-413">
                  <div class="overlap-group-4 border-6px-main-grey">
                    <img class="ellipse-62" src="ellipse-62.png" />
                  </div>
                </div>
              </div>
              <img class="image-14-1" src="image-14-1.png" />
              <div class="group-642-1">
                <img class="check" src="check.png" />
              </div>
            </div>
          </div>
          <div class="group">
            <div class="overlap-group-3">
              <div class="group-1">
                <div class="group-413">
                  <div class="overlap-group-4 border-6px-main-grey">
                    <img class="ellipse-62" src="ellipse-62.png" />
                  </div>
                </div>
              </div>
              <img class="image-14-2" src="image-14-2.png" />
              <div class="group-642">
                <img class="check" src="check.png" />
              </div>
            </div>
          </div>
          <div class="group">
            <div class="overlap-group-3">
              <div class="group-1">
                <div class="group-413">
                  <div class="overlap-group-4 border-6px-main-grey">
                    <img class="ellipse-62" src="ellipse-62.png" />
                  </div>
                </div>
              </div>
              <img class="image-14-3" src="image-14-3.png" />
              <div class="group-642-1">
                <img class="check" src="check.png" />
              </div>
            </div>
          </div>
          <div class="group">
            <div class="overlap-group-3">
              <div class="group-1">
                <div class="group-413">
                  <div class="overlap-group-4 border-6px-main-grey">
                    <img class="ellipse-62" src="ellipse-62.png" />
                  </div>
                </div>
              </div>
              <img class="image-14-4" src="image-14-4.png" />
              <div class="group-642">
                <img class="check" src="check.png" />
              </div>
            </div>
          </div>
          <div class="group-640">
            <div class="overlap-group-3">
              <div class="group-1">
                <div class="group-413-1">
                  <div class="overlap-group-4 border-6px-main-grey">
                    <img class="ellipse-62" src="ellipse-62.png" />
                  </div>
                </div>
              </div>
              <img class="image-13" src="image-13.png" />
              <div class="group-642-1">
                <img class="check" src="check.png" />
              </div>
            </div>
          </div>
        </div>
        <img class="chevron-right-1" src="chevron-right.png" />
      </div> -->
      <div class="flex-col-5">
        <img class="line-6" src="line-6.png" />
        <div class="flex-row-7">
          <div class="overlap-group-container-4">
            <div class="overlap-group9" style="cursor: pointer;" (click) ='updatePageBackground("1")'>
              <img
                id = 'backgroundBorder1'
                class="unsplashom-nc-hyk-d-hp8-2"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6207273ea435ea8ee11cd95d/img/unsplash-omnchykdhp8-2@1x.png"
              /><img
                class="unsplashom-nc-hyk-d-hp8-3"
                src="../../../assets/pageImage1.svg"
              />
              <!-- <div class="ellipse-165"></div>
              <img class="check-2" src="check.png" /> -->
            </div>
            <div id = 'backgroundBorder4' class="overlap-group-5"style="cursor: pointer;" (click) ='updatePageBackground("4")'>
              <img
                class="unsplashom-nc-hyk-d-hp8"
                src="../../../assets/pageImage4.svg"
              />
            </div>
          </div>
          <div class="flex-col-6">
            <div id = 'backgroundBorder2'class="overlap-group13"style="cursor: pointer;" (click) ='updatePageBackground("2")'>
              <img
                class="unsplashom-nc-hyk-d-hp8"
                src="../../../assets/pageImage2.svg"
              />
            </div>
            <div id = 'backgroundBorder5' class="overlap-group11"style="cursor: pointer;" (click) ='updatePageBackground("5")'>
              <img
                class="unsplashom-nc-hyk-d-hp8"
                src="../../../assets/pageImage5.svg"
              />
            </div>
            <div class="save-changes">Save Changes</div>
          </div>
          <div class="overlap-group-container-5">
            <div id = 'backgroundBorder3' class="overlap-group10" style="cursor: pointer;" (click) ='updatePageBackground("3")'>
              <img
                class="unsplashom-nc-hyk-d-hp8"
                src="../../../assets/pageImage3.svg"
              />
            </div>
            <div id = 'backgroundBorder6' class="overlap-group-5" style="cursor: pointer;" (click) ='updatePageBackground("6")'>
              <img
                class="unsplashom-nc-hyk-d-hp8"
                src="../../../assets/pageImage6.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
