import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-student-profile-popup',
  templateUrl: './student-profile-popup.component.html',
  styleUrls: ['./student-profile-popup.component.scss']
})
export class StudentProfilePopupComponent implements OnInit {
  @Output() closeMeEmitter = new EventEmitter<any>();
  @Output() updateAlienColorEmitter = new EventEmitter<any>();
  @Output() updatePageBackgroundEmitter = new EventEmitter<any>();
  @Output() updatePageBackgroundColor = new EventEmitter<any>();
  @Input() profilePicURL
  
  studentName
  constructor() { }

  ngOnInit(): void {
  }     

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    console.log(changes.profilePicURL.currentValue )
    if(changes.profilePicURL.currentValue !== null){
      console.log(changes.profilePicURL.currentValue)
      document.getElementById('proPic2').setAttribute('src',changes.profilePicURL.currentValue.proPic)
      this.studentName = changes.profilePicURL.currentValue.name
      this.initBackgroundColor(changes.profilePicURL.currentValue.proColor)
      this.initAlienColor(changes.profilePicURL.currentValue.alien)
      this.initPageBackground(changes.profilePicURL.currentValue.backImg)
    }
  }

  //Emitter to tell Student Profile to toggle pop up display
  closeMe(){
    this.closeMeEmitter.emit('')
  }

  //Emitter to tell Student Profile to update UI & update DB
  updateAlienColor(newColor){
    this.updateAlienColorEmitter.emit(newColor)
    this.clearAlienSelect()
    document.getElementById(`${newColor}`+'AlienSelected').setAttribute('class','alienSelected')
  }

  initAlienColor(newColor){
    this.clearAlienSelect()
    document.getElementById(`${newColor}`+'AlienSelected').setAttribute('class','alienSelected')
  }

  clearAlienSelect(){
    document.getElementById('pinkAlienSelected').setAttribute('class','alienNotSelected')
    document.getElementById('yellowAlienSelected').setAttribute('class','alienNotSelected')
    document.getElementById('greenAlienSelected').setAttribute('class','alienNotSelected')
    document.getElementById('blueAlienSelected').setAttribute('class','alienNotSelected')
    document.getElementById('purpleAlienSelected').setAttribute('class','alienNotSelected')
    document.getElementById('orangeAlienSelected').setAttribute('class','alienNotSelected')
  }

  initPageBackground(imageIndex){
    console.log(imageIndex)
    this.clearBackgroundSelected()
    if(imageIndex === '1'){
      document.getElementById('backgroundBorder1').setAttribute('src','../../../assets/backgroundSelected.svg')
    }else if(imageIndex === '2'){
      document.getElementById('backgroundBorder2').setAttribute('class','overlap-group13-1')
    }else if(imageIndex === '3'){
      document.getElementById('backgroundBorder3').setAttribute('class','overlap-group10-1')
    }else if(imageIndex === '4'){
      document.getElementById('backgroundBorder4').setAttribute('class','overlap-group-5-1')
    }else if(imageIndex === '5'){
      document.getElementById('backgroundBorder5').setAttribute('class','overlap-group11-1')
    }else if(imageIndex === '6'){
      document.getElementById('backgroundBorder6').setAttribute('class','overlap-group-5-1')
    }
  }

  updatePageBackground(imageIndex){
    console.log(imageIndex)
    this.clearBackgroundSelected()
    if(imageIndex === '1'){
      document.getElementById('backgroundBorder1').setAttribute('src','../../../assets/backgroundSelected.svg')
    }else if(imageIndex === '2'){
      document.getElementById('backgroundBorder2').setAttribute('class','overlap-group13-1')
    }else if(imageIndex === '3'){
      document.getElementById('backgroundBorder3').setAttribute('class','overlap-group10-1')
    }else if(imageIndex === '4'){
      document.getElementById('backgroundBorder4').setAttribute('class','overlap-group-5-1')
    }else if(imageIndex === '5'){
      document.getElementById('backgroundBorder5').setAttribute('class','overlap-group11-1')
    }else if(imageIndex === '6'){
      document.getElementById('backgroundBorder6').setAttribute('class','overlap-group-5-1')
    }
    this.updatePageBackgroundEmitter.emit(imageIndex)
  }
//  border: 2px solid var(--chathams-blue);
  updateBackgroundColor(colorHue){
    this.updatePageBackgroundColor.emit(colorHue)
    this.clearOtherBackgroundColors()
    document.getElementById(colorHue).setAttribute('style','border: 2px solid var(--chathams-blue);')

  }
  clearBackgroundSelected(){
    document.getElementById('backgroundBorder1').setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6207273ea435ea8ee11cd95d/img/unsplash-omnchykdhp8-2@1x.png')
    document.getElementById('backgroundBorder2').setAttribute('class','overlap-group13')
    document.getElementById('backgroundBorder3').setAttribute('class','overlap-group10')
    document.getElementById('backgroundBorder4').setAttribute('class','overlap-group-5')
    document.getElementById('backgroundBorder5').setAttribute('class','overlap-group11')
    document.getElementById('backgroundBorder6').setAttribute('class','overlap-group-5')
  }

  initBackgroundColor(colorHue){
    this.clearOtherBackgroundColors()
    document.getElementById(colorHue).setAttribute('style','border: 2px solid var(--chathams-blue);')
  }

  clearOtherBackgroundColors(){
    document.getElementById('FFFFFF-F3F3F3-2A8499').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('F2FEFF-C6DFE1-81CBD1').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('F8F2FF-DAD4E1-C1A1E8').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('FEFFD1-DADBA5-ECEF73').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('EDFFD0-D2E2B8-B4F152').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('FFF1F8-DCBACB-F398C6').setAttribute('style','z-index:1;cursor:pointer;')

  }

  clearOtherPageBackground(){
    document.getElementById('FFFFFF-F3F3F3-2A8499').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('F2FEFF-C6DFE1-81CBD1').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('F8F2FF-DAD4E1-C1A1E8').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('FEFFD1-DADBA5-ECEF73').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('EDFFD0-D2E2B8-B4F152').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('FFF1F8-DCBACB-F398C6').setAttribute('style','z-index:1;cursor:pointer;')

  }
  

}
