import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TeacherProfile } from 'src/app/models/TeacherProfile';
import {AngularFireStorage} from '@angular/fire/storage'
import {AngularFireDatabase} from '@angular/fire/database'

@Component({
  selector: 'app-teacher-social',
  templateUrl: './teacher-social.component.html',
  styleUrls: ['./teacher-social.component.scss']
})
export class TeacherSocialComponent implements OnInit {
  @Input() teacher: TeacherProfile;   
  @Output() uid = new EventEmitter<string>();
  url:string = ''
  public doneLoading:boolean;
  public showContent: boolean = false;
  constructor(private database:AngularFireDatabase,private afm: AngularFireStorage) { }

 async  ngOnInit(){
    this.doneLoading = true
    await this.getProPic() 
    await this.getStars()
  }

  addToOrder(){
    this.uid.emit(this.teacher.UID);
  }
 
  async getStars(){
    var hold; 
    var teacherHold = this.teacher
    await this.database.database.ref('providers/userInfo/' + this.teacher.UID + '/immutable/stars/val').once('value').then(function(snap){
      hold = Number(snap.val())
      var count = 1
      while(count <= hold){
        var inpa = "star"+ String(count) + teacherHold.UID 
        document.getElementById(inpa).setAttribute('class',"fa fa-star checked")
        // outerHTML = '<span class="fa fa-star checked" style = "color:orange;"></span>';
        count += 1
      }
    }) 
  }
 
  async getProPic(){
    var teacherHold = this.teacher
    // Create a reference from a Google Cloud Storage URI
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/teacherPics/' + this.teacher.UID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById(`${teacherHold.UID}`).setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      document.getElementById(`${teacherHold.UID}`).setAttribute('src','../../../assets/noPic.png');
      document.getElementById(`${teacherHold.UID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }

}

