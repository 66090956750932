
<div class="container-center-horizontal">
    <div class="frame-483 screen" data-id="1535:6841">
      <div class="overlap-group1" data-id="an|aXAmESDE">
        <div class="group-457" data-id="1535:6763">
          <img
            class="x"
            (click) = 'closeMe()'
            data-id="1535:6765"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png"
          />
        </div>
        <h1 class="title worksans-semi-bold-abbey-32px" data-id="1535:6768">Add Resources</h1>
        <input  required type="file" class="custom-file-input" (change) = "storeFile($event)" accept = ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"id="inputGroupFile0001" style="display: none;">
        <div class="group-458 border-1px-blue"id="drop_zone8">
          <div class="frame-267" data-id="1535:6787">
            <img
              class="download"
              data-id="1535:6788"
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/download-1@2x.png"
            />
            <p class="text-1 worksans-semi-bold-chathams-blue-14px" data-id="1535:6792">
              Upload Files or Drag &amp; Drop
            </p>
          </div>
        </div>
        <div class="group-459" data-id="1535:6793">
          <div class="description inter-semi-bold-chathams-blue-16px" data-id="1535:6795">Description</div>
          <div class="overlap-group" data-id="an|rP5xbihm"><textarea class="text body" data-id="1535:6796" value="{{fileDesc}}" placeholder="Description" ngModel name="fn2" #fn2 ="ngModel" (change) = updateDesc(fn2.value)></textarea></div>
        </div>   
        <div class="frame-355" data-id="1535:6797">
          <div class="place inter-semi-bold-chathams-blue-16px" data-id="I1535:6797;709:191">Name</div>
          <div class="group-314-1" data-id="I1535:6797;709:257">
            <div class="overlap-group-1" data-id="an|1xJfq6l5">
              <input class="first-name-middle-i body" type = 'text' data-id="I1535:6797;709:192" id = 'name' placeholder="File Name" value="{{fileName}}" ngModel name="fn1" #fn1 ="ngModel" (change) = updateName(fn1.value)>
            </div>
          </div>
        </div>
        <div id = 'dropShip' class="group-314-2" data-id="I1535:6798;709:318" style="display: none;">
          <div *ngIf = 'topic'  style="z-index: 1;cursor: pointer;width: 100%;overflow-y: auto;">
            <div *ngFor = "let services of topics">    
              <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'z-index:1;cursor:pointer;width:100%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="{{services}}" id = '{{services}}' (click) = updateTopic(services)/>
            </div>
          </div> 
          <div *ngIf = 'subtopic' style="z-index: 1;cursor: pointer;width: 100%;overflow-y: auto;">
            <div *ngFor = "let services of subtopics">    
              <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:100%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="{{services}}" id = '{{services}}'(click) = updateSubtopic(services)/>
            </div>
          </div>   
        </div>
        <!-- <div *ngFor = "let topic of topics">   
          <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'display:inline-block;z-index: 1;cursor: pointer;width: 325px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);height:50px;position: relative;' value="{{topic}}" id = '{{topic}}'/>
        </div> -->
        <div class="frame-356" data-id="1535:6798">
          <div class="name-1 inter-semi-bold-chathams-blue-16px" data-id="I1535:6798;709:317">Topics</div>
          <div class="group-314" data-id="I1535:6798;709:318" style="z-index: 1;cursor: pointer;"(click) = 'logTopic()'>
            <div class="frame-353" data-id="I1535:6798;709:331">
              <div class="name body" data-id="I1535:6798;709:320">{{fileTopic}}</div>
              <img
                class="frame-205"
                data-id="I1535:6798;709:312"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/frame-205@2x.png"
              />
            </div>
          </div>
        </div>
        <div class="frame-357" data-id="1535:6799">
          <div class="name-2 inter-semi-bold-chathams-blue-16px" data-id="I1535:6799;709:317">Subtopics</div>
          <div class="group-314" data-id="I1535:6799;709:318"style="z-index: 1;cursor: pointer;" (click) = 'logSubtopic()'> 
            <div class="frame-353" data-id="I1535:6799;709:331">
              <div class="name body" data-id="I1535:6799;709:320">{{fileSubtopic}}</div>
              <img
                class="frame-205"
                data-id="I1535:6799;709:312"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/frame-205@2x.png"
              />
            </div>
          </div>
        </div>
        <img
          class="line-7"
          data-id="1535:6800"
          src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/line-6@1x.png"
          
        />
        <img *ngIf="loadedFiles.length === 0" id = 'emptySearch' src = '../../../assets/emptyFileSearch.svg' style = '
              position: absolute;
              right: 23px;
              width: 450px;
              top: 273px;'
        >
        <img *ngIf="loadedFiles.length > 0"id = 'leftArrow' src="../../../assets/chevron-left.svg" style="
        position: absolute;
        top: 202px;
        left: 400px;
        z-index: 1;
        cursor: pointer;"
        (click) = prevPage()>
        <img *ngIf="loadedFiles.length > 0" id = 'rightArrow' src="../../../assets/chevron-right.svg" style = '
        position: absolute;
        left: 430px;
        top: 202px;
        z-index: 1;
        cursor: pointer;'
        (click) = nextPage()>
        <img id = 'image1' class="frame-358" src = '' style="display: none;"(click) = 'highlight(1)'>
        <img id = 'image2' class="frame-358-1" src = '' style="display: none;"(click) = 'highlight(2)'>
        <img id = 'image3' class="frame-358-2" src = '' style="display: none;"(click) = 'highlight(3)'>
        <img id = 'image4' class="frame-358-3" src = '' style="display: none;"(click) = 'highlight(4)'>
        <div id = 'box1' class="frame-358 border-6px-blue" style="display: none;"></div>
        <div id = 'box2'class="frame-358-1 border-6px-blue" style="display: none;"></div>
        <div id = 'box3'class="frame-358-2 border-6px-blue" style="display: none;"></div>
        <div id = 'box4'class="frame-358-3 border-6px-blue" style="display: none;"></div>
        <div id = 'x1' class="group-460 border-1px-blue"(click) = 'deleteFile(1)' data-id="1535:6806" style="display: none;"><img data-id="1535:6808" src="../../../assets/greyX.png" /></div>
        <div id = 'x2' class="group-461 border-1px-blue" (click) = 'deleteFile(2)'data-id="1535:6811" style="display: none;"><img data-id="1535:6813" src="../../../assets/greyX.png" /></div>
        <div id = 'x3' class="group-462 border-1px-blue"(click) = 'deleteFile(3)'data-id="1535:6816" style="display: none;"><img data-id="1535:6818" src="../../../assets/greyX.png" /></div>
        <div id = 'x4' class="group-462-1 border-1px-blue" (click) = 'deleteFile(4)'style="display: none;"><img data-id="1535:6818" src="../../../assets/greyX.png" /></div>

        <div class="frame-361" data-id="1535:6804"(click) = 'up()' style="z-index: 1;cursor: pointer;">
          <div class="add-all-resources worksans-semi-bold-concrete-14px" data-id="1535:6805">Add All Resources</div>
        </div>

      </div>
    </div>
  </div>
