import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ActivatedRoute } from '@angular/router';
import { student } from 'src/app/models/student';
import { studentProfileWidget } from 'src/app/models/studentProfileWidget';
   
@Component({ 
  selector: 'app-parent-zone',
  templateUrl: './parent-zone.component.html',  
  styleUrls: ['./parent-zone.component.scss']
})

export class ParentZoneComponent implements OnInit {
  @Output() children = new EventEmitter<any[]>();
  @Output() newNameEmitter = new EventEmitter<any>();

  children2 = []
  child = []    
  students = [] 
  localStudents = {}
  @Output() child2 = new EventEmitter<any>();
  UID = ''      
  currChildUID = ''

  currentSUID = ''
  
  @Output() currName = new EventEmitter<any>();
  @Output() currHobbies = new EventEmitter<any>();
  @Output() currFavSub = new EventEmitter<any>();
  @Output() currToughSub = new EventEmitter<any>();
  @Output() currFunFact = new EventEmitter<any>();
  @Output() currParentNote = new EventEmitter<any>();
  @Output() currPN= new EventEmitter<any>();
  @Output() currPPN = new EventEmitter<any>();
  @Output() currPRO = new EventEmitter<any>();
  @Output() chosen = new EventEmitter<boolean>();
  @Output() UIDEmitter = new EventEmitter<any>();
  @Output() studentsEmitter = new EventEmitter<any>();
  

  lastStudent = ''
  parentName =''
  parentPronouns = ''
  personalParentNote = ''
  Name = '';
  Hobbies = '';
  FavSub = '';
  ToughSub = '';
  FunFact = '';
  ParentNote = '';

  constructor(private router:ActivatedRoute, private db: AngularFireDatabase) { }
    
  async ngOnInit(){
    this.chosen.emit(false)
    
    this.UID = this.router.snapshot.params.UID
    this.UIDEmitter.emit(this.UID)
    var holdUID = this.UID
    var childrenHold = []
    var students = []
    var localStudents = []
    
    this.db.database.ref('users/' + this.UID +'/students').once('value').then(function(snap){
      snap.forEach(student =>{
        var SUID = student.key
        var name = student.child('profile').child('name').val()
        var backImg = student.child('profile').child('backImg').val()
        var proColor = student.child('profile').child('proColor').val()
        var proPic = student.child('profile').child('proPic').val()

        var alien = student.child('profile').child('alien').val()
        var favSub = student.child('info').child('favSub').val()
        var grade = student.child('info').child('grade').val()
        var hobbies = student.child('info').child('hobbies').val()
        var note = student.child('info').child('note').val()
        var toughSub = student.child('info').child('toughSub').val()

        localStudents[SUID] = {'alien':alien,'grade':grade,'hobbies':hobbies,'note':note,'favSub':favSub,'SUID':SUID,'name':name,'backImg':backImg,'proColor':proColor,'proPic':proPic,'PUID':holdUID,'toughSub':toughSub}
        students.unshift({'SUID':SUID,'name':name,'backImg':backImg,'proColor':proColor,'proPic':proPic,'PUID':holdUID})
      })
    }).then(()=>{
      this.students = students
      this.localStudents = localStudents
      console.log(this.students)
      this.studentsEmitter.emit(this.students)
    }).then(()=>{
        this.children.emit(childrenHold)
        this.children2 = childrenHold
        console.log(this.children2)
        console.log(this.localStudents[this.students[0].SUID])
        this.currentSUID = this.students[0].SUID
        var studentRef = this.localStudents[this.students[0].SUID]
        this.setProfileImage(studentRef.proPic)
        this.updateAlienColor(studentRef.alien)
        this.updateBackgroundColor(studentRef.proColor)
        this.currName.emit(studentRef.name)
        this.currHobbies.emit(studentRef.hobbies)
        this.currFavSub.emit(studentRef.favSub)
        this.currToughSub.emit(studentRef.toughSub)
        this.currFunFact.emit(studentRef.note)
        this.currParentNote.emit('Apple of my eye') 
    }
    ).then(()=>{
      if(this.children2.length > 0){
        setTimeout(()=>{
          console.log(this.children2[0].UID)
          document.getElementById(`${this.children2[0].UID}`).click()
        },0)
      }
      // else{
      //   document.getElementById('emptyChildren2').setAttribute('style','width: 324px;margin-left: 22px;margin-top: 27px;margin-left:36px')
      // }

    })

    // this is all fucked up, the amount of calls being made here is stupid 
    var holdPN
    var holdPPN
    var holdPro
    await this.db.database.ref('users/' + this.UID + '/userInfo/name/val').once('value').then(function(snap){
      console.log(snap.val())
      holdPN = snap.val()
    })
    await this.db.database.ref('users/' + this.UID + '/userInfo/personalNote/val').once('value').then(function(snap){
      holdPPN = snap.val()
    })
    await this.db.database.ref('users/' + this.UID + '/userInfo/pronouns/val').once('value').then(function(snap){
      holdPro = snap.val()
    })
    this.parentName = holdPN
    this.parentPronouns = holdPro
    this.personalParentNote = holdPPN
    this.currPN.emit(holdPN)
    this.currPRO.emit(holdPro)
    this.currPPN.emit(holdPPN)
  }

  async removeProfile(){
    this.lastStudent = ''
    var prof = await this.db.database.ref('users/' + this.UID + '/children')
    await prof.child(`${this.currChildUID}`).remove().then(()=>{
      this.Name = '';
      this.Hobbies = '';
      this.FavSub = '';
      this.ToughSub = '';
      this.FunFact = '';
      this.ParentNote = '';
      this.currChildUID = '';
      this.child = [];

      var childrenHold = []
      var found = false
      this.db.database.ref('users/' + this.UID + '/children').once('value').then(function(snap){
          if(snap.exists()){
            console.log(snap.val())
            found = true
            snap.forEach(function(snapp){
              var name = snapp.child('name').child('val').val()
              var hobbies = snapp.child('hobbies').child('val').val()
              var favSub = snapp.child('favSub').child('val').val()
              var toughestSub = snapp.child('toughestSub').child('val').val()
              var funFact = snapp.child('funFact').child('val').val()
              var parentNote = snapp.child('parentNote').child('val').val()
              childrenHold.push(new student(name,hobbies,favSub,toughestSub,funFact,parentNote,snapp.key))
            })
          }else{
            //prompt to click the add child button
          }
          
        }).then(()=>{
          
          this.children.emit(childrenHold)
          this.children2 = childrenHold
          if(this.children2.length > 0){
            setTimeout(()=>{
              console.log(this.children2[0].UID)
              document.getElementById(`${this.children2[0].UID}`).click()
            },0)
          }else{
            document.getElementById('emptyChildren2').setAttribute('style','width: 324px;margin-left: 22px;margin-top: 27px;margin-left:36px')
          }
          this.currName.emit('Choose a child to get started')
        this.currHobbies.emit('Choose a child to get started')
        this.currFavSub.emit('Choose a child to get started')
        this.currToughSub.emit('Choose a child to get started')
        this.currFunFact.emit('Choose a child to get started')
        this.currParentNote.emit('Choose a child to get started')
        this.chosen.emit(false)
        })
    });
  }

  save(){
    console.log(this.Name,this.Hobbies,this.FavSub,this.ToughSub,this.FunFact,this.FunFact,this.ParentNote)
    
  }

  updateProfileEdit(SUID){
    console.log(SUID)
    console.log(this.localStudents[SUID])
    this.currentSUID = SUID
    var studentRef = this.localStudents[SUID]
    this.setProfileImage(studentRef.proPic)
    this.updateAlienColor(studentRef.alien)
    this.updateBackgroundColor(studentRef.proColor)
    this.currName.emit(studentRef.name)
    this.currHobbies.emit(studentRef.hobbies)
    this.currFavSub.emit(studentRef.favSub)
    this.currToughSub.emit(studentRef.toughSub)
    this.currFunFact.emit(studentRef.note)
    this.currParentNote.emit('Apple of my eye')
  }


  setProfileImage(proImg){
    console.log(proImg)
    if(String(proImg) === '1'){
      document.getElementById('proPic1').setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png')
    }else if(String(proImg) === '2'){
      document.getElementById('proPic1').setAttribute('src','../../../assets/Frog.svg')
    }else if(String(proImg) === '3'){
      document.getElementById('proPic1').setAttribute('src','../../../assets/Hippo.svg')
    }else if(String(proImg) === '4'){
      document.getElementById('proPic1').setAttribute('src','../../../assets/Giraf.svg')
    }else if(String(proImg) === '5'){
      document.getElementById('proPic1').setAttribute('src','../../../assets/Cat.svg')
    }else if(String(proImg) === '6'){
      document.getElementById('proPic1').setAttribute('src','../../../assets/zebra.svg')
    }
  }
  // save(){
  //   this.updateName()
  //   this.updateFavSub()
  //   this.updateHobbies()      
  //   this.updateParentNote()
  //   this.updateToughSub()
  //   this.updateFunFact()
  // }

  updateAlienColor(newColor){
    console.log(newColor)
    this.clearAlienSelect()
    document.getElementById(`${newColor}`+'AlienSelected').setAttribute('class','alienSelected')
    console.log(document.getElementById(`${newColor}`+'AlienSelected'))
  }

  changeAlienColor(newColor){
    console.log(newColor)
    this.clearAlienSelect()
    document.getElementById(`${newColor}`+'AlienSelected').setAttribute('class','alienSelected')
    console.log(document.getElementById(`${newColor}`+'AlienSelected'))

    this.localStudents[this.currentSUID].alien = newColor
    this.db.database.ref('users/' + `${this.UID}` + '/students/' + `${this.currentSUID}` + '/profile/').update({'alien':`${newColor}`})
  }

  clearAlienSelect(){
    console.log(1)
    document.getElementById('pinkAlienSelected').setAttribute('class','alienNotSelected')
    document.getElementById('yellowAlienSelected').setAttribute('class','alienNotSelected')
    document.getElementById('greenAlienSelected').setAttribute('class','alienNotSelected')
    document.getElementById('blueAlienSelected').setAttribute('class','alienNotSelected')
    document.getElementById('purpleAlienSelected').setAttribute('class','alienNotSelected')
    document.getElementById('orangeAlienSelected').setAttribute('class','alienNotSelected')
  }

  uploadProfilePicture(){

  }
  parentSave(){
    console.log(this.parentName,this.parentPronouns,this.personalParentNote)
    var change = {'name':{'val':this.parentName},'personalNote':{'val':this.personalParentNote},'pronouns':{'val':this.parentPronouns}}
    this.db.database.ref('users/' + `${this.UID}`).update({'userInfo':change})
  }
  updateBackgroundColor(colorHue){
    console.log(this.updateBackgroundColor)
    this.clearOtherBackgroundColors()
    document.getElementById(colorHue+'-1').setAttribute('style','border: 2px solid var(--chathams-blue);')

  }

  changeBackgroundColor(colorHue){
    console.log(this.updateBackgroundColor)
    this.clearOtherBackgroundColors()
    document.getElementById(colorHue+'-1').setAttribute('style','border: 2px solid var(--chathams-blue);')

    this.localStudents[this.currentSUID].proColor = colorHue
    this.db.database.ref('users/' + `${this.UID}` + '/students/' + `${this.currentSUID}` + '/profile/').update({'proColor':`${colorHue}`})
  }

  clearOtherBackgroundColors(){
    document.getElementById('FFFFFF-F3F3F3-2A8499-1').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('F2FEFF-C6DFE1-81CBD1-1').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('F8F2FF-DAD4E1-C1A1E8-1').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('FEFFD1-DADBA5-ECEF73-1').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('EDFFD0-D2E2B8-B4F152-1').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('FFF1F8-DCBACB-F398C6-1').setAttribute('style','z-index:1;cursor:pointer;')

  }

  createStudent(){
    document.getElementById('createNewStudent').setAttribute('style','')
    document.getElementById('createNewStudent1').setAttribute('style','width: 100%;height: 1202px;background: rgba(250, 250, 250, 0.9);overflow: hidden;z-index: 101;top: 0px;left:0px;position: absolute;')
  }

  addNewStudent($event){
    console.log($event)
    this.students.unshift($event)
    this.localStudents[$event.SUID] = $event
    this.studentsEmitter.emit(this.students)
    this.updateProfileEdit($event.SUID)
    this.closeMe()
  }
  closeMe(){
    document.getElementById('createNewStudent').setAttribute('style','display:none')
    document.getElementById('createNewStudent1').setAttribute('style','display:none')
  }

  changeName(newName){
    this.Name = newName
    this.localStudents[this.currentSUID].name = newName
    this.newNameEmitter.emit([newName,this.currentSUID])
    this.db.database.ref('users/' + `${this.UID}` + '/students/' + `${this.currentSUID}` + '/profile/').update({'name':`${newName}`})
  }
  changeHobbies(newHobbies){
    this.Hobbies = newHobbies
    this.localStudents[this.currentSUID].hobbies = newHobbies
    this.db.database.ref('users/' + `${this.UID}` + '/students/' + `${this.currentSUID}` + '/info/').update({'hobbies':`${newHobbies}`})
  }
  changeFunFact(newFunFact){
    this.FunFact = newFunFact
    this.localStudents[this.currentSUID].hobbies = newFunFact
    this.db.database.ref('users/' + `${this.UID}` + '/students/' + `${this.currentSUID}` + '/info/').update({'note':`${newFunFact}`})
  }
  changeToughSub(newToughSub){
    this.ToughSub = newToughSub
    this.localStudents[this.currentSUID].toughSub = newToughSub
    this.db.database.ref('users/' + `${this.UID}` + '/students/' + `${this.currentSUID}` + '/info/').update({'toughSub':`${newToughSub}`})
  }
  changeFavSub(newFavSub){
    this.FavSub = newFavSub
    this.localStudents[this.currentSUID].favSub = newFavSub
    this.db.database.ref('users/' + `${this.UID}` + '/students/' + `${this.currentSUID}` + '/info/').update({'favSub':`${newFavSub}`})
  }

}
