export class title_rate_time{
    title: string;
    day: string;
    time: string;
    rate: number;
    total:number
    rawStart:number
    rawEnd:number
    
    constructor(title, time, rate,rawStart, rawEnd){
        this.title = title
        this.time = time 
        this.rate = rate

        console.log(time)
        console.log(title)
        console.log(rate)
        console.log(rawStart)
        console.log(rawEnd)

    } 


    
    getTotalTime(){
        var hold = this.rawStart
        var totalTime = 0
        while(hold < this.rawEnd){
            hold += 15
            if(hold%100 > 0 && hold%100 <= 60){
                totalTime += .25
            }
            
        }
        return totalTime
    }

    getRawStart(){
        return this.rawStart
    }
    getRawEnd(){
        return this.rawEnd
    }

    getTitle(){
        return this.title
    }

    getTime(){
        return this.time
    }

    getRate(){
        return this.rate
    }
    
}