<a *ngIf = "doneLoading" routerLink="../../TeacherPage/{{teacher.UID}}" class = "txtBlock">
    <div style=" width: 55%;border-bottom-right-radius:25px; background-color: grey;">
        <span id = "star1{{teacher.UID}}" class="fa fa-star"></span>
        <span id = "star2{{teacher.UID}}" class="fa fa-star"></span>
        <span id = "star3{{teacher.UID}}" class="fa fa-star"></span>
        <span id = "star4{{teacher.UID}}" class="fa fa-star"></span>
        <span id = "star5{{teacher.UID}}" class="fa fa-star"></span>
    
    </div>
    <!-- style = "width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;" -->
    <img class = 'frame-210'id = "{{teacher.UID}}" [src] = '' src = '../../../assets/ceramic.jpeg' >
    <!-- Add icon library -->  
    <div style="width:200px;margin: 0 auto;">
        <div class="name inter-semi-bold-abbey-16px" style="margin-top: 10px; margin-bottom: 10px;">{{teacher.name}}</div>
        <!-- <div  style = "font-size: 16px;text-align: left;margin-bottom: 15px;"><strong>{{teacher.name}}</strong></div>              -->
        <!-- <p style="font-size: 14px;text-align: left;color:green"><li>{{teacher.credenitial}}</li></p> -->
        <p class="text-1 inter-normal-chathams-blue-14px" style="margin-bottom: 10px;color:#1E5663;font-family: var(--font-family-inter);font-size: 14px;height: 75px;overflow-y: scroll;">{{teacher.description}}</p>
        <!-- <div style="background-color: #292F45;height: 5px;margin: 0 auto; border-radius: 25px; width: 90%;">
            
        </div>  -->
        <!-- <p style = "word-wrap: break-word;font-size: 14px;text-align: left;">{{teacher.description}}</p> -->
        <div class="text-1 inter-semi-bold-mine-shaft-14px">
            <span class="span inter-semi-bold-mine-shaft-14px"style="margin-bottom: 10px;color:#333333;font-family: var(--font-family-inter);font-size: 14px;font-weight: 700;">Hourly:</span>
            <span class="span inter-normal-mine-shaft-14px"style="margin-bottom: 10px;color:#333333;font-family: var(--font-family-inter);font-size: 14px;"> ${{teacher.rate}}</span>
        </div>
        <div class="frame-212" style="margin-top: -25px;"> 
            <div id = '{{"LE" + teacher.UID}}' style="margin: 0 auto;margin-left:9px;display: inline-block;display:none;">
                <div class="ellipse-25-1"></div>
                <div class="group-21-1" style="margin: 0 auto;">LE</div>
            </div>
            <div id = '{{"UE" + teacher.UID}}'  style="margin: 0 auto;margin-left:9px;display: inline-block;display:none;">
                <div class="ellipse-25-2"></div>
                <div class="group-21-1"style="margin: 0 auto;">UE</div>
            </div>
            <div id = '{{"MS" + teacher.UID}}'   style="margin: 0 auto;margin-left:9px;display: inline-block;display:none;">
                <div class="ellipse-25"></div>
                <div class="group-21"style="margin: 0 auto;">MS</div>
            </div>
            <div id = '{{"HS" + teacher.UID}}'   style="margin: 0 auto;margin-left:9px;display: inline-block;display:none;">
                <div class="ellipse-22"></div>
                <div class="group-21"style="margin: 0 auto;">HS</div>
            </div>
        </div>  
    </div>  
          
</a>


