<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
<div *ngIf="cartItems.length === 0"class="list-group" style="font-size: 20px; width: 400px;color: black; height: 370px;">
  <p style="color: black;font-size: 20px;margin-top: 150px;">Your cart is empty!</p>
  <strong style="font-size: 20px;">Total: {{ cartTotal | currency }}</strong>
</div>

<ul *ngIf="cartItems.length > 0"  class="list-group" style="font-size: 20px; width: 400px;color: black;height: 370px;">
  <ul class="list-group-item" *ngFor="let cartItem of cartItems">
    <li class="list-group-item">{{cartItem.title}}{{cartItem.time}}{{cartItem.rate}}</li>
  </ul>

  <li class="list-goup-item">
    <strong style="font-size: 20px;">Total: {{ cartTotal | currency }}</strong>
    <button>checkout</button>
  </li>
</ul>


