import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-student-profile-holder',
  templateUrl: './student-profile-holder.component.html',
  styleUrls: ['./student-profile-holder.component.scss']
})
export class StudentProfileHolderComponent implements OnInit {
  @Input() AUXIN
  @Input() newName

  @Output() studentUID = new EventEmitter<any>();
  
  name
  SUID
  PUID
  urlKey
  backImg
  color
  proImg

  constructor() { }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    console.log(changes.newName.currentValue )
    if(changes.newName.currentValue !== null && this.SUID ===changes.newName.currentValue[1]){
      console.log(changes.newName.currentValue)
      this.name = changes.newName.currentValue[0]
    }
  }
  ngOnInit(): void {
    console.log(this.AUXIN)
    this.name = this.AUXIN.name
    this.SUID = this.AUXIN.SUID
    this.PUID = this.AUXIN.PUID
    this.urlKey = this.PUID + '-' + this.SUID
    this.backImg = this.AUXIN.backImg
    this.color = this.AUXIN.proColor
    this.proImg = this.AUXIN.proPic
    

    setTimeout(()=>{
      var color = this.color.split('-')[0]
      if(this.color.split('-')[0] === 'F8F2FF'){
        color = this.color.split('-')[1]
      }
      document.getElementById('proColor' + this.SUID).setAttribute('style','width: 264px;height:120px;background-color: #'+color+';margin:5px;border-radius: 10px;padding: 10px;border: 3px solid lightgray;cursor: pointer;')
      if(String(this.proImg) === '1'){
        document.getElementById('proPic' + this.SUID).setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png')
      }else if(String(this.proImg) === '2'){
        document.getElementById('proPic' + this.SUID).setAttribute('src','../../../assets/Frog.svg')
      }else if(String(this.proImg) === '3'){
        document.getElementById('proPic' + this.SUID).setAttribute('src','../../../assets/Hippo.svg')
      }else if(String(this.proImg) === '4'){
        document.getElementById('proPic' + this.SUID).setAttribute('src','../../../assets/Giraf.svg')
      }else if(String(this.proImg) === '5'){
        document.getElementById('proPic' + this.SUID).setAttribute('src','../../../assets/Cat.svg')
      }else if(String(this.proImg) === '6'){
        document.getElementById('proPic' + this.SUID).setAttribute('src','../../../assets/zebra.svg')
      }

      document.getElementById('backImage' + this.SUID).setAttribute('src','../../../assets/pageImage'+this.backImg +'B.svg')
    },0)
  }
  
  editStudent(){
    this.studentUID.emit(this.SUID)
  }
}
