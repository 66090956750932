import { Component,EventEmitter,OnInit, Output} from '@angular/core';
import { AngularFireDatabase, snapshotChanges } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { connect, Room } from 'twilio-video';
import { SessionEnvsService } from '../../services/session-envs.service';

@Component({
  selector: 'app-group-class',
  templateUrl: './group-class.component.html',
  styleUrls: ['./group-class.component.scss']
})
export class GroupClassComponent implements OnInit {
  public showWebcam = false;
  private trigger: Subject<void> = new Subject<void>();
  public webcamImage: WebcamImage = null;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  url

  initializeListner = false

  holdText = ''

  avatar = ''

  state = []

  actions = []

  @Output() currentAttitude = new EventEmitter<any>();
  @Output() currentAttention = new EventEmitter<any>();
  @Output() currentGrasp = new EventEmitter<any>();
  @Output() currentReport = new EventEmitter<any>();
  Attitude
  Attention
  Grasp
  report

  lockedClipboard = false


  attitudeRateing = -1
  attentionRateing = -1
  graspRateing = -1

  audio = true
  video = true

  resourceNames = {}

  
  isPainting = false
  canvas
  sharedCanvas
  lastX    
  lastY
  personalLines = ''
  sharedLines = ''
  newLine = ''
  userAccount: User
  personalFiles = []
  documentFiles = []
  inboxFiles = []
  @Output() inboxFilesEmitter = new EventEmitter<any[]>();
  isTeacher = false
  local = 'users/'   
  @Output() personalFilesEmitter = new EventEmitter<any[]>();
  @Output() documentFilesEmitter= new EventEmitter<any[]>();
  lastTogg = ''
  dropDrag = false
  over = false
  currentDropKey = ''
  textBoxVals = {}
  canvasImages = []
  textBoxs = []
  @Output() canvasImagesEmitter = new EventEmitter<any>()
  @Output() textBoxEmitter = new EventEmitter<any[]>();
  currentDot = -1
  splitter = ''
  currentBoxId = ''
  currentBoxType = ''
  imageURLS = {}
  cacheImages = {}

  otherAvatar = ''

  lock = false
  mute = false

  realTimeDBRefs = {}

  subInitialized = false

  movedMediaFinal = {}
  movedMediaInter = {}
  movedMediaTS = {}

  movedTextFinal = {}
  movedTextInter = {}
  movedTextTS = {}

  room:Room
  participants 

  blockPositions = []

  localDrawColor = '#1E5663'
  localHoldDrawColor = '#1E5663'
  localDrawShape:CanvasLineCap = 'round'
  localDrawSize = 5
  ear = false

  freshWidth = -1
  freshHeight = -1   
  freshOriginX = -1
  freshOriginY = -1

  
  initialX:number = -1
  initialY:number = -1
  meetingID = ''

  otherUID = ''

  videoFeeds = [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true]
  @Output() videoFeedsEmitter= new EventEmitter<any[]>();
   
 
  // time line is the colletion of board interactions based on time stamp order. represents the current stat of the board
   
  //when we we go back or forward what we should is send the command to remove

  timeLine = []
  lastTextTS: string;

  constructor(private db: AngularFireDatabase,public auth: AuthService,private firebase: FirebaseService,private router:ActivatedRoute,private fns:AngularFireFunctions,private afm: AngularFireStorage,private SES:SessionEnvsService){} 
  
  async ngOnInit(){
    // Get account info
    // this.participants = document.getElementById('participants')
    this.meetingID = await `${this.router.snapshot.params.BUID}`
    this.removeHeaderAndFooter()
    await this.auth.user$.subscribe(usr=>{
      this.userAccount = usr
      if(this.meetingID.split('-')[0] === this.userAccount.uid){
        this.otherUID = this.meetingID.split('-')[1]
      }else{
        this.otherUID = this.meetingID.split('-')[0]
      }
      var TEACHER = false
      var local = 'users/'
      this.db.database.ref('providers/userInfo/' +  `${this.userAccount.uid}`).once('value').then(function(snap){
        if(snap.exists()){ 
          TEACHER = true
          local = 'providers/userInfo/'
        }     
      }).then(()=>{   
        if(TEACHER){
          this.listenToTeacher()
        }else{
          this.listenToStudent()
        }
        this.isTeacher = TEACHER   
        this.local = local       
        this.getUserFiles()  
        this.classroomEnv()   
        this.initializeCanvas2() 
        this.subOtherUsers()
        // this.sharedCanvas
      })
    }) //instead of key being -1 or a number make it _1 or a key  
  }   
  exitLobby(ev){   
    console.log(ev)   
    this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + `${this.userAccount.uid}`)
    .update({'avatar':ev[0]}).then(()=>{
      document.getElementById('lobby').remove()
      document.getElementById('capture').setAttribute('class','container-center-horizontal unselectable')
      // this.enagageTwillio()     
      this.videoFeedsEmitter.emit(this.videoFeeds)
      var avatar = ev[0]
      var taskBar = ev[1]  
      var pencil = ev[2]    
      document.getElementById('taskBarBackground').setAttribute('style','background-color:' + `${taskBar}`)
      // document.getElementById('videoOnButtonP').setAttribute('style','background-color:' + `${taskBar}`)
      // document.getElementById('audioOnButtonP').setAttribute('style','background-color:' + `${taskBar}`)
      // document.getElementById('viewOffButtonP').setAttribute('style','background-color:' + `${taskBar}`)
      // document.getElementById('audioOffButtonP').setAttribute('style','background-color:' + `${taskBar}`)
      
      // document.getElementById('videoOffButtonP').setAttribute('style','background-color:' + `${taskBar}`)
  
      document.getElementById('Pen' + `${pencil}`).click()
      // document.getElementById('localDisplay').setAttribute('style','display:none')
      // document.getElementById('Avatar').setAttribute('style','background-image:' + 'url("../../../assets/Frog.svg")')
  
      if(Number(avatar) === 1){
        document.getElementById('avatar').setAttribute('class','turtle centerAvatar')
        this.avatar = 'turtle'
      }else if(Number(avatar) === 2){
        this.avatar = 'hippo'
        document.getElementById('avatar').setAttribute('class','hippo centerAvatar')
      }else if(Number(avatar) === 3){
        this.avatar = 'zebra'
        document.getElementById('avatar').setAttribute('class','zebra centerAvatar')
      }else if(Number(avatar) === 4){
        this.avatar = 'giraf'
        document.getElementById('avatar').setAttribute('class','giraf centerAvatar')
      }else if(Number(avatar) === 5){
        this.avatar = 'cat'
        document.getElementById('avatar').setAttribute('class','cat centerAvatar')
      }else if(Number(avatar) === 6){
        this.avatar = 'owl'
        document.getElementById('avatar').setAttribute('class','owl centerAvatar')
      }
    })
  }

  async listenToTeacher(){
    await this.db.database.ref('providers/userInfo/'+ this.userAccount.uid + '/newStorage/').on('value',val=>{
      // console.log(val.val())
      var valStr = String(val.val().split('/')[0])
      console.log(valStr)
  
      if(this.initializeListner === false){
        this.initializeListner = true
      }else{
        var fileType = val.val().split('/')[2]
        if(fileType.toLowerCase().includes('image')){
          if(valStr.includes('-')){
            this.personalFiles.unshift(['PERSONAL',val.val().split('/')[0],true,this.userAccount.uid,val.val().split('/')[0],val.val().split('/')[1]])
          }else{
            this.personalFiles.unshift(['PERSONAL',val.val().split('/')[0],true,this.userAccount.uid,this.userAccount.uid + '-' + val.val().split('/')[0],val.val().split('/')[1]])
          }
          console.log(this.personalFiles)
          this.personalFilesEmitter.emit(this.personalFiles)
        }else{   
          // this.inboxFiles.push(['INBOX',nonCanvasFile.key,this.isTeacher,this.userAccount.uid,nonCanvasFile.key])
          if(valStr.includes('-')){
            this.documentFiles.unshift(['PERSONALDOC',val.val().split('/')[0],true,this.userAccount.uid,val.val().split('/')[0],val.val().split('/')[1]])
          }else{
            this.documentFiles.unshift(['PERSONALDOC',val.val().split('/')[0],true,this.userAccount.uid,this.userAccount.uid + '-' + val.val().split('/')[0],val.val().split('/')[1]])
          }
          console.log(this.documentFiles)
          this.documentFilesEmitter.emit(this.documentFiles)
        }   
      //   if(valStr.includes('-')){
      //     this.personalFiles.unshift(['PERSONAL',val.val().split('/')[0],true,this.userAccount.uid,val.val().split('/')[0],val.val().split('/')[1]])
      //   }else{
      //     this.personalFiles.unshift(['PERSONAL',val.val().split('/')[0],true,this.userAccount.uid,this.userAccount.uid + '-' + val.val().split('/')[0],val.val().split('/')[1]])
      //   }
      //   console.log(this.personalFiles)
      //   this.personalFilesEmitter.emit(this.personalFiles)
      }   
    })  
  }
  toggleDisplayView(tail){ 
    document.getElementById('disp3').setAttribute('src','../../../assets/boxGrey.svg')
    document.getElementById('disp2').setAttribute('src','../../../assets/rowGrey.svg')
    document.getElementById('disp').setAttribute('src','../../../assets/gridGrey2.svg')
    if(tail == '3'){
      document.getElementById('disp3').setAttribute('src','../../../assets/boxGreen.svg')
    }else if(tail == '2'){
      document.getElementById('disp2').setAttribute('src','../../../assets/rowGreen.svg')
    }else{
      document.getElementById('disp').setAttribute('src','../../../assets/gridGreen.svg')
    }
    document.getElementById('videoDisplays').setAttribute('class','rectangle-40 twilioDisplays' + `${tail}`)

  }

  async listenToStudent(){
    await this.db.database.ref('users/'+ this.userAccount.uid + '/newStorage/').on('value',val=>{
      // console.log(val.val())
      console.log(val.val())
      var valStr = String(val.val().split('/')[0])
      console.log(valStr)
  
      if(this.initializeListner === false){
        this.initializeListner = true
      }else{
        var fileType = val.val().split('/')[2]
        if(fileType.toLowerCase().includes('image')){
          if(valStr.includes('-')){
            this.personalFiles.unshift(['PERSONAL',val.val().split('/')[0],false,this.userAccount.uid,val.val().split('/')[0],val.val().split('/')[1]])
          }else{
            this.personalFiles.unshift(['PERSONAL',val.val().split('/')[0],false,this.userAccount.uid,this.userAccount.uid + '-' + val.val().split('/')[0],val.val().split('/')[1]])
          }
          console.log(this.personalFiles)
          this.personalFilesEmitter.emit(this.personalFiles)
        }else{
          // this.inboxFiles.push(['INBOX',nonCanvasFile.key,this.isTeacher,this.userAccount.uid,nonCanvasFile.key])
          if(valStr.includes('-')){
            this.documentFiles.unshift(['PERSONALDOC',val.val().split('/')[0],false,this.userAccount.uid,val.val().split('/')[0],val.val().split('/')[1]])
          }else{
            this.documentFiles.unshift(['PERSONALDOC',val.val().split('/')[0],false,this.userAccount.uid,this.userAccount.uid + '-' + val.val().split('/')[0],val.val().split('/')[1]])
          }
          console.log(this.documentFiles)
          this.documentFilesEmitter.emit(this.documentFiles)
        }
    
      }
    })
  }

  sendToParent(){
    this.toggle("sharePointer")
    this.lockedClipboard = true
    var TS = + new Date()
    var newVal = {'Attention':this.Attention,'Attitude':this.Attitude,'Grasp':this.Grasp,'Report':this.report}
    this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/reco/').update({[TS]:newVal})
  }
     
  turnOffView(){
    document.getElementById('local').setAttribute('style','display:none;')
    document.getElementById('localHidden').setAttribute('style','display:block;margin-top: 15px;')
    
  }
  turnOnView(){
    document.getElementById('local').setAttribute('style','display:block;margin-top: 15px;')
    document.getElementById('localHidden').setAttribute('style','display:none;')
  }
  enagageTwillio(){
    this.enterRoom()
  }

  public get triggerObservable(): Observable<void> {   
    return this.trigger.asObservable();  
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }  

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  // public handleImage(webcamImage: WebcamImage): void {
  //   console.info('received webcam image', webcamImage);
  //   this.webcamImage = webcamImage;
  //   this.url = this.convertDataUrlToBlob(webcamImage.imageAsDataUrl)
  //   var TS = + new Date()
  //   this.afm.upload("/personalFiles/" + this.userAccount.uid + '/' + TS, this.url).then(()=>{
  //     this.personalFiles.unshift(['PERSONAL',TS,this.isTeacher,this.userAccount.uid,this.userAccount.uid + '-' + TS,'Snap Shot'])
  //     this.personalFilesEmitter.emit(this.personalFiles)
  //   }).catch(err=>{console.log(err)})
  // }

  upload(webcamImage: WebcamImage){
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.url = this.convertDataUrlToBlob(webcamImage.imageAsDataUrl)
    var TS = + new Date()
    if(this.isTeacher){
      this.afm.upload("/personalFiles/" + `${this.userAccount.uid}` + '/' + `${TS}`, this.url).then(()=>{
        this.db.database.ref('providers/userInfo/' + this.userAccount.uid + '/storage').update({[TS]:{'type':'PERSONAL','value':TS,'name':'Screen Shot','desc':'','topic':'General','subtopic':'General'}}).then(()=>{
          // this.db.database.ref('providers/userInfo/' + this.userAccount.uid + '/').update({'newStorage':TS})
          this.personalFiles.unshift(['PERSONAL',TS,this.isTeacher,this.userAccount.uid,this.userAccount.uid + '-' + TS,'Snap Shot'])
          this.personalFilesEmitter.emit(this.personalFiles)
        })
      })
    }else{
      this.afm.upload("/personalFiles/" + `${this.userAccount.uid}` + '/' + `${TS}`, this.url).then(()=>{
        this.db.database.ref('users/' + this.userAccount.uid + '/storage').update({[TS]:{'type':'PERSONAL','value':TS,'name':'Screen Shot','desc':'','topic':'General','subtopic':'General'}}).then(()=>{
          // this.db.database.ref('users/' + this.userAccount.uid + '/').update({'newStorage':TS})
          this.personalFiles.unshift(['PERSONAL',TS,this.isTeacher,this.userAccount.uid,this.userAccount.uid + '-' + TS,'Snap Shot'])
          this.personalFilesEmitter.emit(this.personalFiles)
        })
      })
    }
    
  }

  convertDataUrlToBlob(dataUrl): Blob {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], {type: mime});
}

  subOtherUsers(){
    var userList = this.meetingID.split('-')
    userList.forEach(UID=>{
      if(`${UID}` !== `${this.userAccount.uid}`){
        this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + `${UID}` + '/avatar').on('value',ava=>{
          var avatar = ava.val()
          if(Number(avatar) === 1){
            this.otherAvatar = 'turtle'
          }else if(Number(avatar) === 2){
            this.otherAvatar = 'hippo'
          }else if(Number(avatar) === 3){
            this.otherAvatar = 'zebra'
          }else if(Number(avatar) === 4){
            this.otherAvatar = 'giraf'
          }else if(Number(avatar) === 5){
            this.otherAvatar = 'cat'
          }else if(Number(avatar) === 6){
            this.otherAvatar = 'owl'
          }
          console.log(this.otherAvatar)
        })
        this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + `${UID}` + '/port').on('value',val=>{
          if(this.subInitialized === false || val === null|| val === undefined){
            this.subInitialized = true
          }else{
            var TYPE = val.val().split(':')[0]
            var DATAPACKET = val.val().split(':')[1]
            if(TYPE === 'LINE'){
                // this is where we should be adding to the non us drawings 
                //  #1E5663-5-round-282.341|282.341-1629410444063
                //   color-pixelSize-tipShape-pointsCollection-TS
                //           var newLine = `${this.localDrawColor}` +'-'+ `${this.localDrawSize}` +'-'+ `${this.localDrawShape}` +'-'+ `${this.newLine}`+'-'+ `${TS}`
              this.state.push('LINE:' + DATAPACKET)
              this.drawOnSharedCanvas(DATAPACKET)
              if(this.sharedLines === ''){
                this.sharedLines = DATAPACKET 
              }else{    
                this.sharedLines += '||' + DATAPACKET 
              } 
            }else if(TYPE === 'IMAGE'){
              // we need to be relo
              this.state.push('IMAGE:' + DATAPACKET)
              var splitter = DATAPACKET.split('-')
              this.firebase.storageURL(splitter[1],splitter[2]).then(url=>{
                var image = new Image()
                image.src = `${url}`;  
                image.crossOrigin = "Anonymous"
                this.imageURLS[DATAPACKET] = url
                image.addEventListener('load',()=>{
                  var RUID = splitter[0]
                  var AUID = splitter[1]
                  var DBKey = splitter[2]
                  var OriginX = splitter[3]
                  var OriginY = splitter[4]
                  var Width = splitter[5]
                  var Height = splitter[6]
                  var TS = splitter[7]
                  var config = AUID + '-' + DBKey + '-' +  OriginX + '-' + `${OriginY}` + '-' +  Width + '-' + `${Height}` + '-' + `${TS}`+ '-' + `${RUID}`  
                  this.cacheImages[config] = image
                  this.drawImageOnSharedCanvas(DATAPACKET)
                })
                image.onload
              }).catch(e=>console.log(e))
            }else if(TYPE === 'm-IMAGE'){
              DATAPACKET.split('|').forEach(change=>{
                var splitter = change.split('->')
                var oldKey = splitter[0]
                var newKey = splitter[1]
                // this is not working because the initial values are not properly formated 
                
                this.imageURLS[newKey] = this.imageURLS[oldKey]
                this.cacheImages[newKey]= this.cacheImages[oldKey]
                this.updateFireBase(oldKey,newKey)
                delete this.imageURLS[oldKey]
                delete this.cacheImages[oldKey]
                var index = this.canvasImages.indexOf(oldKey)
                this.canvasImages[index] = newKey
              })
              this.localRedraw2()
            }else if(TYPE === 'TEXT'){
              DATAPACKET = val.val().replace('TEXT:','')
              this.state.push('TEXT:' + DATAPACKET)
              this.writeOnSharedCanvas(DATAPACKET)
            }else if(TYPE === 'm-TEXT'){
              DATAPACKET = val.val().replace('m-TEXT:','')
              this.writeMoveSharedCanvas(DATAPACKET)
              this.localRedraw2()
            }else if(TYPE === 'MUTEAUDIO'){
              this.teacherMuteAudio()
            }else if(TYPE === 'LOCKCANVAS'){
              this.lockCanvas()
            }else if(TYPE === 'UNMUTEAUDIO'){
              this.teacherUnMuteAudio()
            }else if(TYPE === 'UNLOCKCANVAS'){
              this.unlockCanvas()
            }else if(TYPE === 'CLEAR'){
              //CLEar 
             this.sharedCanvas.width = this.sharedCanvas.width
             this.sharedLines = ''
             this.personalLines = ''
             this.textBoxs = []
             this.textBoxVals = {}
             this.imageURLS = {}
             this.cacheImages = {}
             this.canvasImages = []
            }else if(TYPE === 'INBOX'){
              // var holdDP = ''
              // DATAPACKET.split(',').forEach(chunk=>{
              //   if(holdDP = ''){
              //     holdDP = chunk
              //   }else{
              //     holdDP += '-' + chunk
              //   }  
              // })
              console.log(DATAPACKET)
              this.inboxFiles.unshift(DATAPACKET.split(','))
              this.inboxFilesEmitter.emit(this.inboxFiles)  
            }else if(TYPE === 'AVATARON'){
              this.showOtherAvatar()
            }else if(TYPE === 'AVATAROFF'){
              this.hideOtherAvatar()  
            }
          }
        })
      }
    })
  }

  lockCanvas(){
    document.getElementById('canvas').setAttribute('style','display:none')
  }
  unlockCanvas(){
    document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/vector-32@2x.svg"), auto ;background-color: transparent;position: absolute;')
  }

  updateFireBase(oldKey,newKey){
    console.log(oldKey, '------>',newKey)
  }

  
  muteStudentsAudio(){
    this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${'MUTEAUDIO:' + new Date()}`})
    this.mute = true
  }

  unmuteStudentsAudio(){
    this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${'UNMUTEAUDIO:' + new Date()}`})
    this.mute = false
  }

  lockStudentsCanvas(){
    this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${'LOCKCANVAS:' + new Date()}`})
    this.lock = true
  }
  unlockStudentsCanvas(){
    this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${'UNLOCKCANVAS:' + new Date()}`})
    this.lock = false
  }
  
  writeOnSharedCanvas(DATAPACKET){
    var splitter = DATAPACKET.split('-')
    var TS = splitter[0]
    var textValue = ''
    var index = 0
    DATAPACKET.split('-').forEach(chunck=>{
      if(index > 6){
        if(textValue === ''){
          textValue = chunck
        }else{
          textValue += '-'  + chunck
        }
      }
      index += 1
    })
    var h = Math.ceil((textValue.length/((Number(splitter[4])/20)*2.2)) * 80)
    this.textBoxs.push(splitter[1] + '-' + splitter[6] + '-' + splitter[2] + '-' + splitter[3] + '-' + splitter[4]+ '-'+ `${h}`)
    this.textBoxVals[`${TS}`] = textValue

    this.writeOnCanvas(textValue,splitter[2],splitter[3],splitter[4])
  }

  writeOnSharedCanvas2(DATAPACKET){
    var splitter = DATAPACKET.split('-')
    var TS = splitter[0]
    var textValue = ''
    var index = 0
    DATAPACKET.split('-').forEach(chunck=>{
      if(index > 6){
        if(textValue === ''){
          textValue = chunck
        }else{
          textValue += '-'  + chunck
        }
      }
      index += 1
    })
    // var h = Math.ceil((textValue.length/((Number(splitter[4])/20)*2.2)) * 80)
    // this.textBoxs.push(splitter[1] + '-' + splitter[6] + '-' + splitter[2] + '-' + splitter[3] + '-' + splitter[4]+ '-'+ `${h}`)
    // this.textBoxVals[`${TS}`] = textValue

    this.writeOnCanvas(textValue,splitter[2],splitter[3],splitter[4])
  }

  writeMoveSharedCanvas(DATAPACKET){
    // def just not passing in the right value 
    DATAPACKET.split('--||$%^&*#|||_+@*^&**#^EDcfhxrt%@&*#($yexcjfghvhfgj.,.,.,.,.][2jkn-------').forEach(change=>{
      var splitter = change.split('->')
      var oldKey = splitter[0]
      var newKey = splitter[1]
      var textValue = ''
      var indexChunk = 0
      change.split('->').forEach(chunk=>{
        if(indexChunk > 2){
          textValue += chunk
        }
        indexChunk += 1
      })
      this.textBoxVals[newKey.split('-')[0]] = textValue
      this.textBoxs.push(newKey)
      var holdTB = []
      this.textBoxs.forEach(boxs=>{
        if(boxs!==oldKey){
          holdTB.push(boxs)
        }
      })
      this.textBoxs = holdTB
    })
  }

  async registerImage(config,AUID,DBKey){
    await this.firebase.storageURL(AUID,DBKey).then(url=>{
      this.imageURLS[config] = url
      let image = new Image()
      image.src = `${url}`;  
      image.crossOrigin = "Anonymous"
      image.addEventListener('load',() =>{
        this.cacheImages[config] = image
      },{once:true})
      image.onload
    })
  }

  async getInitialBoardImages(DATAPACKET){
    var splitter = DATAPACKET.split('-')
    var RUID = splitter[0]
    var AUID = splitter[1]
    var DBKey = splitter[2]
    var OriginX = splitter[3]
    var OriginY = splitter[4]
    var Width = splitter[5]
    var Height = splitter[6]
    var TS = splitter[7]
    var config = AUID + '-' + DBKey + '-' +  OriginX + '-' + `${OriginY}` + '-' +  Width + '-' + `${Height}` + '-' + `${TS}`+ '-' + `${RUID}`
    this.canvasImages.push(config)
    await this.firebase.storageURL(AUID,DBKey).then(url=>{
      this.imageURLS[config] = url
      let image = new Image()
      image.src = `${url}`;  
      image.crossOrigin = "Anonymous"
      image.addEventListener('load',() =>{
        this.cacheImages[config] = image
      },{once:true})
      image.onload
    })
  }

  async drawImageOnSharedCanvas(DATAPACKET){
    var splitter = DATAPACKET.split('-')
    var RUID = splitter[0]
    var AUID = splitter[1]
    var DBKey = splitter[2]
    var OriginX = splitter[3]
    var OriginY = splitter[4]
    var Width = splitter[5]
    var Height = splitter[6]
    var TS = splitter[7]
    var config = AUID + '-' + DBKey + '-' +  OriginX + '-' + `${OriginY}` + '-' +  Width + '-' + `${Height}` + '-' + `${TS}`+ '-' + `${RUID}`
    this.canvasImages.push(config)
    await this.firebase.storageURL(AUID,DBKey).then(url=>{
      var image =this.cacheImages[config]
      this.imageURLS[config] = url
      var canvasHold = this.sharedCanvas
      canvasHold.getContext('2d').drawImage(image, OriginX ,OriginY, Width,Height);

    })
  }

  logDropKey(KEY){       
    // turn on content enagaged mode and tell us where it is
    // make it so that the fingers stay when moving on canvas 
    this.dropDrag = true
    this.currentDropKey = KEY
  }
  removeBoundedBoxs(){
    this.canvasImagesEmitter.emit([])
    this.textBoxEmitter.emit([])
  }
  removeCoverBox(){
    this.blockPositions.forEach(img=>{
      document.getElementById(img + 'cover').remove()   
    })
    this.blockPositions = []
  }          
  createCoverBox(originX:number,originY:number,w:number,h:number,ID){
    var newBox = document.createElement('span')
    newBox.setAttribute('id',`${ID}` + 'cover')
    var box = document.getElementById('coverSquares')
    if(h < 50){
      h = 50
    }
    newBox.setAttribute('style','position: absolute;left:' + `${originX}` + 'px;top:' + `${originY}` + 'px;width:'+ `${w}` + 'px;height:' + `${h}` + 'px;background:whitesmoke;z-index:1')
    box.appendChild(newBox)

  }

  createBoundedTextBox(originX:number,originY:number,w:number,h:number,ID){
    var newBox:HTMLTextAreaElement = <HTMLTextAreaElement>document.getElementById('text-'+`${ID}`)
    newBox.setAttribute('style','z-index:2;border:1px solid #0096FF;background-color: transparent;position: absolute;left:' + `${originX}` + 'px;top:' + `${originY}` + 'px;width:'+ `${w}` + 'px;height:' + `${h}` + 'px;padding: 35px;padding-top: 25px;')
    // newBox = `${this.textBoxVals[ID.split('-')[0]]}`
    newBox.value = this.textBoxVals[ID.split('-')[0]]
    var mover = document.getElementById(`${ID}`)
    mover.setAttribute('style','z-index:2;border-bottom-right-radius: 12.5px;border-bottom-left-radius: 12.5px;background-color: #0096FF;position: absolute;;left:' + `${(originX+(w/2)-50)}` + 'px;top:' + `${originY}` + 'px;width:'+ `${100}` + 'px;height:' + `${25}` + 'px;')
    var topLeftCorner = document.getElementById('1-' + `${ID}`)
    topLeftCorner.setAttribute('style','z-index:2;border-bottom-right-radius: 12.5px;background-color: #0096FF;position: absolute;;left:' + `${originX}` + 'px;top:' + `${originY}` + 'px;width:'+ `${25}` + 'px;height:' + `${25}` + 'px;')
    var topRightCorner = document.getElementById('2-' + `${ID}`)
    topRightCorner.setAttribute('style','z-index:2;border-bottom-left-radius: 12.5px;background-color: #0096FF;position: absolute;;left:' + `${originX+w-26}` + 'px;top:' + `${originY}` + 'px;width:'+ `${25}` + 'px;height:' + `${25}` + 'px;')
    // var bottomLeftCorner = document.getElementById('3-' + `${ID}`)
    // bottomLeftCorner.setAttribute('style','z-index:2;border-top-right-radius: 12.5px;background-color: #0096FF;position: absolute;;left:' + `${originX}` + 'px;top:' + `${originY+h-26}` + 'px;width:'+ `${25}` + 'px;height:' + `${25}` + 'px;')
    // var bottomRightCorner = document.getElementById('4-' + `${ID}`)
    // bottomRightCorner.setAttribute('style','z-index:2;border-top-left-radius: 12.5px;background-color: #0096FF;position: absolute;;left:' + `${originX+w-26}` + 'px;top:' + `${originY+h-26}` + 'px;width:'+ `${25}` + 'px;height:' + `${25}` + 'px;')
    
  }

  
  createBoundedBox(originX:number,originY:number,w:number,h:number,ID){
    var newBox = document.getElementById(`${ID}`)
    var url = this.imageURLS[ID]
    if(url !== undefined){
      newBox.setAttribute('style','z-index:2;border:1px solid #0096FF;position: absolute;left:' + `${originX}` + 'px;top:' + `${originY}` + 'px;width:'+ `${w}` + 'px;height:' + `${h}` + 'px;background:url(' +url+ ');background-size:'+ `${w}` + 'px ' + `${h}` + 'px;background-repeat: no-repeat;')
    }else{
      newBox.setAttribute('style','z-index:2;border:1px solid #0096FF;position: absolute;left:' + `${originX}` + 'px;top:' + `${originY}` + 'px;width:'+ `${w}` + 'px;height:' + `${h}` + 'px;')
    }
    var topLeftCorner = document.getElementById('1-' + `${ID}`)
    topLeftCorner.setAttribute('style','z-index:2;border-bottom-right-radius: 12.5px;background-color: #0096FF;position: absolute;;left:' + `${originX}` + 'px;top:' + `${originY}` + 'px;width:'+ `${25}` + 'px;height:' + `${25}` + 'px;')
    var topRightCorner = document.getElementById('2-' + `${ID}`)
    topRightCorner.setAttribute('style','z-index:2;border-bottom-left-radius: 12.5px;background-color: #0096FF;position: absolute;;left:' + `${originX+w-26}` + 'px;top:' + `${originY}` + 'px;width:'+ `${25}` + 'px;height:' + `${25}` + 'px;')
    var bottomLeftCorner = document.getElementById('3-' + `${ID}`)
    bottomLeftCorner.setAttribute('style','z-index:2;border-top-right-radius: 12.5px;background-color: #0096FF;position: absolute;;left:' + `${originX}` + 'px;top:' + `${originY+h-26}` + 'px;width:'+ `${25}` + 'px;height:' + `${25}` + 'px;')
    var bottomRightCorner = document.getElementById('4-' + `${ID}`)
    bottomRightCorner.setAttribute('style','z-index:2;border-top-left-radius: 12.5px;background-color: #0096FF;position: absolute;;left:' + `${originX+w-26}` + 'px;top:' + `${originY+h-26}` + 'px;width:'+ `${25}` + 'px;height:' + `${25}` + 'px;')
  }

  updateEnv(key,packet){

  }

  enableHost(){
    // one person will be resposnibile for the allocation of resources
  }

  envUpdate(){
    // create 
   }

  //line: ?{||} color-size-shape-points
  addSharedLine(newLine){
    var resourceUID = + new Date()
    this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid + '/lines').update({[resourceUID]:`${newLine}`}).then(()=>{
      var pubVal = 'LINE:' + newLine
      this.actions.push(pubVal)
      this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${pubVal}`})
    })
  } 


  //image: OriginX-OriginY-W-H-DBLoc
  addSharedImage(newImage,TS){
    this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid + '/files').update({[TS]:`${newImage}`}).then(()=>{
      var pubVal = 'IMAGE:' + TS + '-' +newImage
      // this.actions.push(pubVal)
      this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${pubVal}`})
    })
  } 

  addSharedText(newText){
    var TS = newText.split('-')[0]
    this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid + '/text').update({[TS]:`${newText}`}).then(()=>{
      var pubVal = 'TEXT:' + TS + '-' + newText
      // this.actions.push(pubVal)
      this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${pubVal}`}).then(()=>{
        this.movedTextFinal = {}
        this.movedTextInter = {}
        this.movedTextTS = {}
      })
    })
  } 

  moveSharedImage(){
    // this is why we get the mystery m-Image send when nothing should be sent
    var pubVal = 'm-IMAGE:'
    var newMoves = ''
    var dbRef = this.db.database
    for (const property in this.movedMediaFinal) {
      if(property !== this.movedMediaFinal[property]){
        if(this.movedMediaFinal[property].split('-')[0] === this.userAccount.uid){
          var FBRef = this.movedMediaFinal[property].split('-')[7]
          dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid + '/files').update({[FBRef]:`${this.movedMediaFinal[property]}`})
        }else{
          var FBRef = this.movedMediaFinal[property].split('-')[7]
          dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.otherUID + '/files').update({[FBRef]:`${this.movedMediaFinal[property]}`})
        }
        if(newMoves === ''){
          newMoves = `${property}`+ '->' + `${this.movedMediaFinal[property]}`
          // this.actions.push(`${property}`+ '->' + `${this.movedMediaFinal[property]}`)
        }else{
          newMoves += '|' + `${property}`+ '->' + `${this.movedMediaFinal[property]}`
          // this.actions.push(`${property}`+ '->' + `${this.movedMediaFinal[property]}`)
        }
      }
    }
    if(newMoves !== ''){
      pubVal += newMoves
      dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${pubVal}`}).then(()=>{
        this.movedMediaFinal = {}
        this.movedMediaInter = {}
        this.movedMediaTS = {}
      })
    }
  } 
  //["PERSONALDOC", "yUMfnK5HE5hbOAMGij7QnE28IxF3-1627416745424", true, "yUMfnK5HE5hbOAMGij7QnE28IxF3", "yUMfnK5HE5hbOAMGij7QnE28IxF3-1627416745424"]
  handle(event){
    var key = event[1]
    var TS = + new Date()
    event[0] = 'INBOX'
    this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid + '/nonCanvasFiles').update({[key]:`${TS}`}).then(()=>{
      this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${'INBOX:' + event.toString()}`})
      this.inboxFiles.unshift(event)
      this.inboxFilesEmitter.emit(this.inboxFiles)
    })
  }

  localRedraw2(){
    // add to cached images when image is passed from port and go through the current state of the board
    // 
    this.sharedCanvas.width = this.sharedCanvas.width
    setTimeout(()=>{
      var state = []
      var properState = []
      this.canvasImages.forEach(img=>{
        state.push('FILE:' + img)
      })   
      if(this.personalLines !== ''){
        this.personalLines.split('||').forEach(line1=>{
          state.push('LINE:' + line1)
        }) 
      }
  
      if(this.sharedLines !== ''){
        this.sharedLines.split('||').forEach(line2=>{
          state.push('LINE:' + line2)
        }) 
      }

      this.textBoxs.forEach(textBox=>{
        state.push('TEXT:' + textBox)
      }) 
      var TSHOLD = []
      state.forEach(config=>{
        var key = config.split(':')[0]
        var packet = String(config.split(':')[1])
        var TS2 = ''
        if(key === 'TEXT'){
          TS2 = packet.split('-')[1]
        }else if(key === 'LINE'){
          TS2 = packet.split('-')[4]
        }else if(key === 'FILE'){
          TS2 = packet.split('-')[6]
        }
        if(TSHOLD.length === 0){
          TSHOLD.push(TS2)
          properState.push(config)
        }else{
          var index = 0
          var end = TSHOLD.length - 1
          var placed = false
          TSHOLD.forEach(stamp=>{
            if(Number(TS2) > Number(stamp) && !placed){
              placed = true
              TSHOLD.splice(index,0,TS2)
              properState.splice(index,0,config)
            }else if(index === end && !placed){
              placed = true
              TSHOLD.push(TS2)
              properState.push(config)
            }
            index += 1
          })
        }
      })
      properState.reverse()
      properState.forEach(res=>{
        var resKey = res.split(':')[0]
        var resPacket = res.split(':')[1]
        if(resKey === 'TEXT'){
          var splitt = resPacket.split('-')
          var specialDraw = splitt[0] + '-' + splitt[0] + '-' + splitt[2] + '-' + splitt[3] + '-' + splitt[4]  + '-' + splitt[5] + '-' + splitt[1] + '-' + this.textBoxVals[resPacket.split('-')[0]]
          this.writeOnSharedCanvas2(specialDraw)
        }else if(resKey === 'FILE'){
          var splitter = resPacket.split('-')
          var RUID = splitter[7]
          var AUID = splitter[0]
          var DBKey = splitter[1]
          var OriginX = splitter[2]
          var OriginY = splitter[3]
          var Width = splitter[4]
          var Height = splitter[5]
          var TS = splitter[6]
          var config = AUID + '-' + DBKey + '-' +  OriginX + '-' + `${OriginY}` + '-' +  Width + '-' + `${Height}` + '-' + `${TS}`+ '-' + `${RUID}`
          var canvasHold = this.sharedCanvas
          var imageHold: HTMLImageElement = this.cacheImages[config]
          canvasHold.getContext('2d').drawImage(imageHold, OriginX ,OriginY, Width,Height);
        }else if(resKey === 'LINE'){
          var line = resPacket
          var splitter = line.split('-')
          var color = splitter[0]
          var shape:CanvasLineCap
          if(splitter[2] === 'round'){
            shape = 'round'
          }else{
            shape = 'square'
          }
          var size = splitter[1]
          var points = splitter[3]
          var lastX = -1
          var lastY = -1
          points.split('|').forEach(point=>{
            var x = Number(point.split('.')[0])
            var y = Number(point.split('.')[1])
            if (lastX === -1 || lastY === -1) {
              var context = this.sharedCanvas.getContext('2d')  
              lastX = x;
              lastY = y;
              context.beginPath()
              context.moveTo(lastX,lastY)  
              context.lineTo(x,y)
              context.lineWidth = size;
              context.lineCap = shape;
              context.strokeStyle = color;
              context.stroke();
            } else{
              var context = this.sharedCanvas.getContext('2d') 
              context.beginPath()
              context.moveTo(lastX,lastY)  
              context.lineTo(x,y)
              context.lineWidth = size;
              context.lineCap = shape;
              context.strokeStyle = color;
              context.stroke();
              lastX = x;
              lastY = y;
            }
          })
        }
      })
    },0)
  }

  localRedraw(){
  this.sharedCanvas.width = this.sharedCanvas.width
   setTimeout(()=>{
    var state = []
    this.canvasImages.forEach(img=>{
      state.push(img)
      var image = new Image()
      image.src = this.imageURLS[img];  
      image.crossOrigin = "Anonymous"
      var canvasHold = this.sharedCanvas
      var splitter = img.split('-')
      image.onload = function(){
        canvasHold.getContext('2d').drawImage(image, Number(splitter[2]),Number(splitter[3]),Number(splitter[4]),Number(splitter[5]));
      }
    })     
    if(this.personalLines !== ''){
      this.personalLines.split('||').forEach(line=>{
        state.push(line)
        var color = line.split('-')[0]
        var shape:CanvasLineCap
        if(line.split('-')[2] === 'round'){
          shape = 'round'
        }else{
          shape = 'square'
        }
        var size = Number(line.split('-')[1])
        //points
        var lastX = -1
        var lastY = -1
        line.split('-')[3].split('|').forEach(point=>{
          var x = Number(point.split('.')[0])
          var y = Number(point.split('.')[1])
          if (lastX === -1 || lastY === -1) {
            var context = this.sharedCanvas.getContext('2d')  
            lastX = x;
            lastY = y;
            context.beginPath()
            context.moveTo(lastX,lastY)  
            context.lineTo(x,y)
            context.lineWidth = size;
            context.lineCap = shape;
            context.strokeStyle = color;
            context.stroke();
          } else{
            var context = this.sharedCanvas.getContext('2d') 
            context.beginPath()
            context.moveTo(lastX,lastY)  
            context.lineTo(x,y)
            context.lineWidth = size;
            context.lineCap = shape;
            context.strokeStyle = color;
            context.stroke();
            lastX = x;
            lastY = y;
          }
        })
      }) 
    }

    if(this.sharedLines !== ''){
      this.sharedLines.split('||').forEach(line=>{
        state.push(line)
        var color = line.split('-')[0]
        var shape:CanvasLineCap
        if(line.split('-')[2] === 'round'){
          shape = 'round'
        }else{
          shape = 'square'
        }
        var size = Number(line.split('-')[1])
        //points
        var lastX = -1
        var lastY = -1
        line.split('-')[3].split('|').forEach(point=>{
          var x = Number(point.split('.')[0])
          var y = Number(point.split('.')[1])
          if (lastX === -1 || lastY === -1) {
            var context = this.sharedCanvas.getContext('2d')  
            lastX = x;
            lastY = y;
            context.beginPath()
            context.moveTo(lastX,lastY)  
            context.lineTo(x,y)
            context.lineWidth = size;
            context.lineCap = shape;
            context.strokeStyle = color;
            context.stroke();
          } else{
            var context = this.sharedCanvas.getContext('2d') 
            context.beginPath()
            context.moveTo(lastX,lastY)  
            context.lineTo(x,y)
            context.lineWidth = size;
            context.lineCap = shape;
            context.strokeStyle = color;
            context.stroke();
            lastX = x;
            lastY = y;
          }
        })
      }) 
    }
    this.textBoxs.forEach(textBox=>{
      state.push(textBox)
      this.writeOnCanvas(this.textBoxVals[textBox.split('-')[0]], Number(textBox.split('-')[2]), Number(textBox.split('-')[3]), Number(textBox.split('-')[4]))
    }) 
    // this.textBoxs.forEach(box=>{
    //   this.writeOnCanvas(this.textBoxVals[box.split('-')[0]], box.split('-')[2],Number(box.split('-')[3]),box.split('-')[4])
    // })
   },0)
  }

  showOtherAvatar(){
    if(this.isTeacher){
      document.getElementById('StudentTop').setAttribute('style','display:none')
      document.getElementById('avatarOtherS').setAttribute('style','background-color: transparent;height: 175px;display: inline-block;width:264px;border-radius: 15px;')
      document.getElementById('avatarOtherS').setAttribute('class',`${this.otherAvatar}` + ' centerAvatar')
    }else{
      document.getElementById('TeacherTop').setAttribute('style','display:none')
      document.getElementById('avatarOtherT').setAttribute('style','background-color: transparent;height: 175px;border-radius: 15px;margin-bottom: 15px;-webkit-transform: scaleX(-1);transform: scaleX(-1);width: 263px;')
      document.getElementById('avatarOtherT').setAttribute('class',`${this.otherAvatar}` + ' centerAvatar')
    }   

  }

  hideOtherAvatar(){
    if(this.isTeacher){
      document.getElementById('StudentTop').setAttribute('style','background-color: transparent;height: 175px;display: inline-block;width:264px;border-radius: 15px;-webkit-transform: scaleX(-1);transform: scaleX(-1)')
      document.getElementById('avatarOtherS').setAttribute('style','display:none')
      document.getElementById('avatarOtherS').setAttribute('class','')
    }else{
      document.getElementById('TeacherTop').setAttribute('style','background-color: transparentheight: 175px;width: 315px;border-radius: 15px;margin-bottom: 15px;width: 263px;-webkit-transform: scaleX(-1);transform: scaleX(-1)')
      document.getElementById('avatarOtherT').setAttribute('style','display:none')
      document.getElementById('avatarOtherT').setAttribute('class','')
    }8
  }



  showAvatar(){
    document.getElementById('LocalDisplay').setAttribute('style','display:none')
    document.getElementById('avatar').setAttribute('style','background-color: transparent;height: 175px;display: inline-block;width:264px;border-radius: 15px')
    document.getElementById('avatar').setAttribute('class',`${this.avatar}` + ' centerAvatar')
  }

  hideAvatar(){
    document.getElementById('avatar').setAttribute('style','display:none')
    document.getElementById('avatar').setAttribute('class','')
    document.getElementById('LocalDisplay').setAttribute('style','background-color: transparent;height: 175px;display: inline-block;width:264px;border-radius: 15px;-webkit-transform: scaleX(-1);transform: scaleX(-1)')
  }

  turnOnVideo(){
    var dbRef = this.db.database
    dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':'AVATAROFF:' + `${+ new Date()}`}).then(()=>{
      this.hideAvatar()
      this.room.localParticipant.videoTracks.forEach(
        publication => publication.track.enable()
      );
      this.video = true
    })

  }
  turnOffVideo(){
    var dbRef = this.db.database
    dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':'AVATARON:' + `${+ new Date()}`}).then(()=>{
      this.showAvatar()
      this.room.localParticipant.videoTracks.forEach(
        publication => publication.track.disable()
      );
      this.video = false
    })

  }
  turnOnAudio(){
    this.room.localParticipant.audioTracks.forEach(
      publication => publication.track.enable()
    );
    this.audio = true
  }
  turnOffAudio(){
    this.room.localParticipant.audioTracks.forEach(
      publication => publication.track.disable()
    );
    this.audio = false
  }

  teacherMuteAudio(){
    this.room.localParticipant.audioTracks.forEach(
      publication => publication.track.disable()
    );
    this.audio = false
  }
  teacherUnMuteAudio(){
    this.room.localParticipant.audioTracks.forEach(
      publication => publication.track.enable()
    );
    this.audio = true
  }

  textChangeResize(key){
    this.currentDot = 0
    this.splitter = key
    this.currentBoxId = key
    this.currentBoxType = 'TEXT'
    this.initialX = -1
    this.initialY = -1
    var index = this.textBoxs.indexOf(this.currentBoxId)
    var splitHold = this.textBoxs[index].split('-')
    var idHold = `${this.textBoxs[index]}`
    var splitter = this.splitter.split('-')
    this.freshOriginX= Number(splitter[2])
    this.freshOriginY = Number(splitter[3])
    this.freshWidth = Number(splitter[4])
    this.freshHeight = Number(splitter[5])
    
    

    this.freshHeight = Math.ceil((this.textBoxVals[idHold.split('-')[0]].length /((Number(idHold.split('-')[4])/20)*2.2)) * 80)
    if(this.freshWidth < 151){
      this.freshWidth = 150
    }
    if(this.freshHeight < 51){
      this.freshHeight = 50
    }
    this.textBoxs[index] = splitHold[0] + '-'+ `${+ new Date()}` +'-' + this.freshOriginX + '-' + this.freshOriginY + '-'  + this.freshWidth + '-'  + this.freshHeight
    document.getElementById(idHold).setAttribute('id',`${this.textBoxs[index]}`)
    this.splitter = ''
    this.currentBoxId = ''
    this.currentBoxType = ''
    var newID = this.textBoxs[index]
    this.observeMovedText(idHold,newID)
    document.getElementById('text-' + `${idHold}`).setAttribute('id','text-' +newID)
    document.getElementById('1-' + `${idHold}`).setAttribute('id','1-' +newID)
    document.getElementById('2-' + `${idHold}`).setAttribute('id','2-' +newID)
    // document.getElementById('3-' + `${idHold}`).setAttribute('id','3-' +newID)
    // document.getElementById('4-' + `${idHold}`).setAttribute('id','4-' +newID)

    // this is where we should be updateing the DB based on the region in which it is located
    // we need an array of key value pairs where the keys are the 
    setTimeout(() => {
      this.createBoundedTextBox(this.freshOriginX , this.freshOriginY , this.freshWidth, this.freshHeight, this.textBoxs[index])
    }, 0);
  }

  onChange(key,val){
    // every text change shold result in a reszeing out bounding box coordinates 
    // all we need to do is update this placements infos height 
    // var splitta = key.split('-')
    // this.textBoxVals[splitta[0]] = val
    // this.textChangeResize(key)
    this.holdText = val
  }

  addTextChange(key){
    var splitta = key.split('-')
    this.textBoxVals[splitta[0]] = this.holdText
    this.holdText = ''
    this.textChangeResize(key)
  }
  

  updateTextBoxVal(key,text){
    this.textBoxVals[key] = text
  }

  resizeText(val,key){
    this.currentDot = key     
    this.splitter = val
    this.currentBoxId = val
    this.currentBoxType = 'TEXT'
    this.initialX = -1
    this.initialY = -1
    
    document.addEventListener('mouseup',e=>{   
      var index = this.textBoxs.indexOf(this.currentBoxId)
      var splitHold = this.textBoxs[index].split('-')
      var idHold = `${this.textBoxs[index]}`
      var splitter = this.splitter.split('-')
      this.freshOriginX= Number(splitter[2])
      this.freshOriginY = Number(splitter[3])
      this.freshWidth = Number(splitter[4])
      this.freshHeight = Number(splitter[5])
      

      this.freshHeight = Math.ceil((this.textBoxVals[idHold.split('-')[0]].length /((Number(idHold.split('-')[4])/20)*2.2)) * 80)
      if(this.freshWidth < 151){
        this.freshWidth = 150
      }
      if(this.freshHeight < 51){
        this.freshHeight = 50
      }
      this.textBoxs[index] = splitHold[0] + '-' + `${+ new Date()}`+ '-' + this.freshOriginX + '-' + this.freshOriginY + '-'  + this.freshWidth + '-'  + this.freshHeight
      document.getElementById(idHold).setAttribute('id',`${this.textBoxs[index]}`)
      this.splitter = ''
      this.currentBoxId = ''
      this.currentBoxType = ''
      var newID = this.textBoxs[index]
      this.observeMovedText(idHold,newID)
      document.getElementById('text-' + `${idHold}`).setAttribute('id','text-' +newID)
      document.getElementById('1-' + `${idHold}`).setAttribute('id','1-' +newID)
      document.getElementById('2-' + `${idHold}`).setAttribute('id','2-' +newID)
      // document.getElementById('3-' + `${idHold}`).setAttribute('id','3-' +newID)
      // document.getElementById('4-' + `${idHold}`).setAttribute('id','4-' +newID)
      setTimeout(() => {
        this.createBoundedTextBox(this.freshOriginX , this.freshOriginY , this.freshWidth, this.freshHeight, this.textBoxs[index])
      }, 0);
    },
    {once:true})
  }
  
  resize(val,key){
    this.currentDot = key     
    this.splitter = val
    this.currentBoxId = val
    this.currentBoxType = 'FILE'
    this.initialX = -1
    this.initialY = -1  
    document.addEventListener('mouseup',e=>{    
      var tempSplit = this.splitter.split('-')
      this.freshOriginX = Number(tempSplit[2])
      this.freshOriginY = Number(tempSplit[3])
      this.freshWidth = Number(tempSplit[4])
      this.freshHeight = Number(tempSplit[5])
      var index = this.canvasImages.indexOf(this.currentBoxId)
      var splitHold = this.canvasImages[index].split('-')
      var idHold = `${this.canvasImages[index]}`

      if(this.freshWidth < 51){
        this.freshWidth = 50
      }
      if(this.freshHeight < 51){
        this.freshHeight = 50
      }

      this.canvasImages[index] = splitHold[0] + '-'  + splitHold[1] + '-'  + this.freshOriginX + '-' + this.freshOriginY + '-'  + this.freshWidth + '-'  + this.freshHeight +  '-'  + `${+ new Date()}` + '-'  + splitHold[7]
      document.getElementById(idHold).setAttribute('id',`${this.canvasImages[index]}`)
      this.splitter = ''
      this.currentBoxId = ''
      this.currentBoxType = ''
      
      if(idHold !== this.canvasImages[index]){
        this.imageURLS[this.canvasImages[index]] = this.imageURLS[idHold]
        this.cacheImages[this.canvasImages[index]] = this.cacheImages[idHold]
        delete this.imageURLS[idHold]
        delete this.cacheImages[idHold]
      }
      var newID = this.canvasImages[index]
      this.observeMovedMedia(idHold,newID)
      
      document.getElementById('1-' + `${idHold}`).setAttribute('id','1-' +newID)
      document.getElementById('2-' + `${idHold}`).setAttribute('id','2-' +newID)
      document.getElementById('3-' + `${idHold}`).setAttribute('id','3-' +newID)
      document.getElementById('4-' + `${idHold}`).setAttribute('id','4-' +newID)
      setTimeout(() => {
        this.createBoundedBox(this.freshOriginX , this.freshOriginY , this.freshWidth, this.freshHeight, this.canvasImages[index])
      }, 0);
      // this is where we should update the image config and redraw the board
    },{once:true})
  }

  observeMovedMedia(oldID,newID){
    if(this.movedMediaInter[oldID] === undefined){
      //new engaged media
      this.movedMediaFinal[oldID] = newID
      this.movedMediaInter[newID] = oldID
      this.movedMediaTS[oldID] = + new Date()
    }else {
      //update engaged media
      var originalKey = this.movedMediaInter[oldID]
      delete this.movedMediaInter[oldID]
      this.movedMediaInter[newID] = originalKey
      this.movedMediaFinal[originalKey] = newID
      this.movedMediaTS[originalKey] = + new Date()
    }
  }

  observeMovedText(oldID,newID){
    if(this.movedTextInter[oldID] === undefined){
      //new engaged media
      this.movedTextFinal[oldID] = newID
      this.movedTextInter[newID] = oldID
      this.movedTextTS[oldID] = + new Date()
    }else {
      //update engaged media
      var originalKey = this.movedTextInter[oldID]
      delete this.movedTextInter[oldID]
      this.movedTextInter[newID] = originalKey
      this.movedTextFinal[originalKey] = newID
      this.movedTextTS[originalKey] = + new Date()
    }
  }

  async enterRoom(){
    const createVideoCall = this.fns.httpsCallable('createVideoCall')
    await createVideoCall('')  
    .toPromise().then(results => {  
      connect(results.token,{name:`${this.meetingID}`,video:{ width: 265, height:185},audio:true}).then(room=>{
        this.room = room
        this.localConnected(room.localParticipant)
        room.participants.forEach(parti=>{
          this.participantConnected(parti)
        })
        room.on('participantConnected', participant => {
          if(!this.video){
            this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':'AVATARON:' + `${+ new Date()}`})
          }
          this.participantConnected(participant)  
        })
        room.on('participantDisconnected', participant => {
          this.participantDisconnected(room.localParticipant)
        })
      })      
    }).catch((e)=> console.log('err',e)).then(()=>{
      
    })
  }

  connectToDisplay(trackPub,location){
    const el = document.getElementById(location)
    const trackSubscribed = (track) =>{
      el.appendChild(track.attach())
    }
    if(trackPub.track){
      trackSubscribed(trackPub.track)
    }
    trackPub.on("subscribed",trackSubscribed)
  }

  async componentDidMount() {       
    const createVideoCall = this.fns.httpsCallable('createVideoCall')
    await createVideoCall('')  
    .toPromise().then(results => {  
      connect(results.token,{name:`${this.meetingID}`,video:{ width: 265, height:185},audio:true}).then(room=>{
        this.room = room
        
        this.participantConnected(room.localParticipant)
        room.participants.forEach(parti=>{
          this.participantConnected(parti)
        })
        room.on('participantConnected', participant => {
          this.participantConnected(participant)  
        })
        room.on('participantDisconnected', participant => {
          this.participantDisconnected(room.localParticipant)
        })
      })      
    }).catch((e)=> console.log('err',e))
  }
  
  trackPublished(trackPub,par){
    const el = document.getElementById(`${par}`)
    const trackSubscribed = (track) =>{
      var hold = track.attach()
      hold.setAttribute('style','border-radius:15px')
      el.appendChild(hold)
    }
    if(trackPub.track){
      trackSubscribed(trackPub.track)
    }
    trackPub.on("subscribed",trackSubscribed)
  }

  localConnected(par){
    document.getElementById('LocalDisplay').setAttribute('style','background-color: transparent;height: 175px;display: inline-block;width:264px;border-radius: 15px;-webkit-transform: scaleX(-1);transform: scaleX(-1)')
    document.getElementById('avatar').setAttribute('style','display:none')
    document.getElementById('local').setAttribute('style','display:block;margin-bottom: 15px;margin-top: 15px;')
    setTimeout(()=>{
      par.tracks.forEach(trackPublication=>{
        this.trackPublished(trackPublication,'LocalDisplay')
      })
      par.on("trackPublished",()=>{
        par.tracks.forEach(trackPublication=>{ 
          this.trackPublished(trackPublication,'LocalDisplay')
        })
      })
    },0)
  }

  participantConnected(par){
    // const el = document.createElement("div")
    // el.setAttribute('id',par.identity)
    // document.getElementById('LocalDisplay').appendChild(el)

    //315px
    var key = 'TeacherTop'
    if(this.isTeacher){
      key = 'StudentTop'
      document.getElementById('sTop').setAttribute('style','display:block;')
    }else{
      document.getElementById('TeacherTop').setAttribute('style','display:block;margin-bottom: 15px;-webkit-transform: scaleX(-1);transform: scaleX(-1);width: 263px;')
    }
    par.tracks.forEach(trackPublication=>{
      this.trackPublished(trackPublication,key)
    })
    par.on("trackPublished",()=>{
      par.tracks.forEach(trackPublication=>{ 
        this.trackPublished(trackPublication,key)
      })
    })
  }

  participantDisconnected(par){
    par.removeAllListeners()
    if(this.isTeacher){
      document.getElementById('sTop').setAttribute('style','display:none')
    }else{
      document.getElementById('TeacherTop').setAttribute('style','display:none')  
    }
  }

  toggleRating(TYPE){
    if(TYPE === 1){
      //attitude
      document.getElementById('reviewSlot1').setAttribute('style','display:block')
    }else if(TYPE === 2){
      //attention
      document.getElementById('reviewSlot2').setAttribute('style','display:block')
    }else if(TYPE === 3){
      //grasp
      document.getElementById('reviewSlot3').setAttribute('style','display:block')
    }
  }
  
  toggle(TYPE){
    if(TYPE === 'files'){
      if(this.lastTogg !== 'files'){
        this.lastTogg = TYPE
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-1')
        document.getElementById('dropShipper').setAttribute('style','display:block;')
        document.getElementById('penSize').setAttribute('style','display:none;')
        document.getElementById('pens').setAttribute('style','display:none;')
        document.getElementById('penShape').setAttribute('style','display:none;')
        document.getElementById('screenCapture').setAttribute('class','screenCaptureWait')
        document.getElementById('documentFiles').setAttribute('style','display:none;')
        document.getElementById('inboxFiles').setAttribute('style','display:none;')
      }else{
        this.lastTogg = ''  
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
      }
    }else if(TYPE === 'dotUpdater'){
      if(this.lastTogg !== 'dotUpdater'){
        this.lastTogg = TYPE
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-2')
        document.getElementById('dropShipper').setAttribute('style','display:none;')
        document.getElementById('penSize').setAttribute('style','display:block;')
        document.getElementById('pens').setAttribute('style','display:none;')
        document.getElementById('penShape').setAttribute('style','display:none;')
        document.getElementById('screenCapture').setAttribute('class','screenCaptureWait')
        document.getElementById('documentFiles').setAttribute('style','display:none;')
        document.getElementById('inboxFiles').setAttribute('style','display:none;')
      }else{
        this.lastTogg = ''  
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
      }
    }else if(TYPE === 'newFile'){
      // this is a special commad, it is one and done since you have to close the compenent outside
      document.getElementById('fileLoader').setAttribute('style','')
      document.getElementById('videoChat').setAttribute('style','display:none')
      
    }else if(TYPE === 'document'){
      if(this.lastTogg !== 'document'){  
        this.lastTogg = TYPE
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-2')
        document.getElementById('dropShipper').setAttribute('style','display:none;')
        document.getElementById('penSize').setAttribute('style','display:none;')
        document.getElementById('pens').setAttribute('style','display:none;')
        document.getElementById('penShape').setAttribute('style','display:none;')
        document.getElementById('screenCapture').setAttribute('class','screenCaptureWait')
        document.getElementById('documentFiles').setAttribute('style','display:block;')
        document.getElementById('inboxFiles').setAttribute('style','display:none;')
      }else{
        this.lastTogg = ''  
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
      }
    }else if(TYPE === 'inbox'){
      if(this.lastTogg !== 'inbox'){
        this.lastTogg = TYPE
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-2')
        document.getElementById('dropShipper').setAttribute('style','display:none;')
        document.getElementById('penSize').setAttribute('style','display:none;')
        document.getElementById('pens').setAttribute('style','display:none;')
        document.getElementById('penShape').setAttribute('style','display:none;')
        document.getElementById('screenCapture').setAttribute('class','screenCaptureWait')
        document.getElementById('documentFiles').setAttribute('style','display:none;')
        document.getElementById('inboxFiles').setAttribute('style','display:block;')
      }else{
        this.lastTogg = ''  
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
      }
    }else if(TYPE === 'sharePointer'){
      if(!this.lockedClipboard){
        var modal = document.getElementById("myModal");
        if(this.lastTogg !== 'sharePointer'){
          var img  = this.sharedCanvas.toDataURL("image/png");
          document.getElementById('modalImage').setAttribute('src',img)
          document.getElementById('modalImagePreview').setAttribute('src',img)
          // html2canvas(document.querySelector("#sharedCanvas")).then(canvas => {
          //   console.log(canvas)
          //   var modalInner = document.getElementById("modal-content");
          //   console.log('hit')
          //   modalInner.appendChild(canvas)
          // });
          this.lastTogg = TYPE
          document.getElementById('dropship').setAttribute('class','overlap-group1-1')
          document.getElementById('dropShipper').setAttribute('style','display:none;')
          document.getElementById('penSize').setAttribute('style','display:none;')
          document.getElementById('pens').setAttribute('style','display:none;')
          document.getElementById('penShape').setAttribute('style','display:none;')
          document.getElementById('screenCapture').setAttribute('class','screenCaptureWait')
          document.getElementById('documentFiles').setAttribute('style','display:none;')
          document.getElementById('screenBod').setAttribute('style','overflow: hidden;')
          document.getElementById('inboxFiles').setAttribute('style','display:none;')
          modal.style.display = "block";
        }else{
          this.lastTogg = ''  
          document.getElementById('dropship').setAttribute('class','overlap-group1-1')
          document.getElementById('modalImage').setAttribute('style','display:none')
          modal.style.display = "none";
        }
      }
    }else if (TYPE === 'earUpdater'){
      if(this.lastTogg !== 'earUpdater'){
        this.lastTogg = TYPE
      }else{
        this.lastTogg = ''  
      }
      document.getElementById('dropship').setAttribute('class','overlap-group1-1')
      document.getElementById('penSize').setAttribute('style','display:none;')
      document.getElementById('dropShipper').setAttribute('style','display:none;')
      document.getElementById('documentFiles').setAttribute('style','display:none;')
      document.getElementById('inboxFiles').setAttribute('style','display:none;')
    }else if (TYPE === 'capShape'){
      if(this.lastTogg !== 'capShape'){
        this.lastTogg = TYPE
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-3')
        document.getElementById('dropShipper').setAttribute('style','display:none;')
        document.getElementById('penSize').setAttribute('style','display:none;')
        document.getElementById('pens').setAttribute('style','display:none;')
        document.getElementById('penShape').setAttribute('style','display:block;')
        document.getElementById('screenCapture').setAttribute('class','screenCaptureWait')
        document.getElementById('documentFiles').setAttribute('style','display:none;')
        document.getElementById('inboxFiles').setAttribute('style','display:none;')
      }else{
        this.lastTogg = ''  
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
      }
    }else if (TYPE === 'penColor'){
      if(this.lastTogg !== 'penColor'){
        this.lastTogg = TYPE
        document.getElementById('dropship').setAttribute('class','overlap-group1-1-4')
        document.getElementById('dropShipper').setAttribute('style','display:none;')
        document.getElementById('penSize').setAttribute('style','display:none;')
        document.getElementById('pens').setAttribute('style','display:block;')
        document.getElementById('penShape').setAttribute('style','display:none;')
        document.getElementById('screenCapture').setAttribute('class','screenCaptureWait')
        document.getElementById('documentFiles').setAttribute('style','display:none;')
        document.getElementById('inboxFiles').setAttribute('style','display:none;')
      }else{
        this.lastTogg = ''  
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
      }
    }else if (TYPE === 'camera'){
      if(this.lastTogg !== 'camera'){
        this.toggleWebcamOn()
        this.lastTogg = TYPE
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
        document.getElementById('dropShipper').setAttribute('style','display:none;')
        document.getElementById('penSize').setAttribute('style','display:none;')
        document.getElementById('pens').setAttribute('style','display:block;')
        document.getElementById('penShape').setAttribute('style','display:none;')
        document.getElementById('screenCapture').setAttribute('class','overlap-group-screenCapture')
        document.getElementById('documentFiles').setAttribute('style','display:none;')
        document.getElementById('inboxFiles').setAttribute('style','display:none;')
      }else{
        this.toggleWebcamOff()
        this.lastTogg = ''  
        document.getElementById('screenCapture').setAttribute('class','screenCaptureWait')
      }
    }else if (TYPE === 'move'){
      if(this.lastTogg !== 'move'){
        // add sizeing boxes go to page 
        this.canvasImagesEmitter.emit(this.canvasImages)
        this.textBoxEmitter.emit(this.textBoxs)
        this.lastTogg = TYPE
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
        document.getElementById('dropShipper').setAttribute('style','display:none;')
        document.getElementById('penSize').setAttribute('style','display:none;')
        document.getElementById('pens').setAttribute('style','display:block;')   
        document.getElementById('penShape').setAttribute('style','display:none;')
        document.getElementById('screenCapture').setAttribute('class','screenCaptureWait')
        document.getElementById('documentFiles').setAttribute('style','display:none;')
        document.getElementById('inboxFiles').setAttribute('style','display:none;')
        this.canvasImages.forEach(img=>{
          this.blockPositions.push(img)
          var splitter = img.split('-')
          setTimeout(()=>{
            this.createBoundedBox(Number(splitter[2]),Number(splitter[3]),Number(splitter[4]),Number(splitter[5]),img)
            this.createCoverBox(Number(splitter[2]),Number(splitter[3]),Number(splitter[4]),Number(splitter[5]),img)
          },0)  
        }) 
        this.textBoxs.forEach(box=>{
          this.blockPositions.push(box)
          var splitter = box.split('-')
          setTimeout(()=>{
            var h = Number(splitter[5])
            if(h === 600 && this.textBoxVals[Number(splitter[0])] !== '' ){
              h = Math.ceil((this.textBoxVals[Number(splitter[0])].length/((Number(splitter[4])/20)*2.2)) * 80)
            }
            this.createBoundedTextBox(Number(splitter[2]),Number(splitter[3]),Number(splitter[4]),h,box)
            this.createCoverBox(Number(splitter[2]),Number(splitter[3]),Number(splitter[4]),h,box)
          },0) 
        })
         
      }else{
        // remove sizeing boxes
        //
        this.moveSharedImage()
        if(this.textBoxs.length > 0){
          this.moveSharedText()
        }
        this.localRedraw2()
        this.removeCoverBox()
        this.removeBoundedBoxs()
        this.lastTogg = ''         
      }
    }else if(TYPE = 'type'){
      console.log(this.lastTogg,this.textBoxs)
      if(this.lastTogg === 'move' && this.textBoxs.length > 0){
                // remove sizeing boxes
        // add the text to the shared canvas 
        this.removeCoverBox()
        //["1629234711266--350-125-300-600"]
        var box = this.textBoxs[this.textBoxs.length-1]
        var splitta = box.split('-')
        var newH = Math.ceil((this.textBoxVals[box.split('-')[0]].length /((Number(box.split('-')[4])/20)*2.2)) * 80)
        var newKeyComp = splitta[0] + '-' + splitta[1] + '-' + splitta[2] + '-' + splitta[3] + '-' + splitta[4] + '-' + newH
        box = newKeyComp
        this.textBoxs[this.textBoxs.length-1] = newKeyComp
        this.state.push('TEXT:' + newKeyComp)
        this.writeOnCanvas(this.textBoxVals[box.split('-')[0]], box.split('-')[2],Number(box.split('-')[3]),box.split('-')[4])
        this.removeBoundedBoxs()
        this.lastTogg = ''  
        this.addSharedText(box.split('-')[0]  + '-' + box.split('-')[2] + '-' + Number(box.split('-')[3]) + '-' + box.split('-')[4]+ '-'+ this.userAccount.uid + box.split('-')[1]  + '-' + this.textBoxVals[box.split('-')[0]])
      }
      else if(this.lastTogg !== 'type'){
        // add sizeing boxes go to page 
        this.lastTogg = TYPE
        document.getElementById('dropship').setAttribute('class','overlap-group1-1')
        document.getElementById('dropShipper').setAttribute('style','display:none;')
        document.getElementById('penSize').setAttribute('style','display:none;')
        document.getElementById('pens').setAttribute('style','display:block;')   
        document.getElementById('penShape').setAttribute('style','display:none;')
        document.getElementById('screenCapture').setAttribute('class','screenCaptureWait')
        document.getElementById('documentFiles').setAttribute('style','display:none;')
        document.getElementById('inboxFiles').setAttribute('style','display:none;')
        var TS = + new Date()
        var heightPush = window.scrollY + 150
        var newTextBox = `${TS}`+ '-' + `${TS}` +'-225'+ '-'+`${heightPush}`+ '-300-600'
        this.textBoxVals[`${TS}`] = ''
        this.lastTextTS = `${TS}`
        this.textBoxs.push(newTextBox)
        this.textBoxEmitter.emit(this.textBoxs)
        setTimeout(()=>{
          var textBox = newTextBox
          var originX = Number(textBox.split('-')[2])
          var originY = Number(textBox.split('-')[3])
          var w = Number(textBox.split('-')[4])
          var h = Number(textBox.split('-')[5])
          this.createBoundedTextBox(originX,originY,w,h,textBox)  
        },0) 
      }else{
        // remove sizeing boxes
        // add the text to the shared canvas 
        console.log('Here')
        this.removeCoverBox()
        //["1629234711266--350-125-300-600"]
        var counter = 1
        var testID = this.textBoxs[this.textBoxs.length-counter].split('-')[0]
        while(testID !== this.lastTextTS){
          counter += 1
          testID = this.textBoxs[this.textBoxs.length-counter].split('-')[0]
        }
        var index = this.textBoxs.length - counter
        var box = this.textBoxs[index]
        var splitta = box.split('-')
        var newH = Math.ceil((this.textBoxVals[box.split('-')[0]].length /((Number(box.split('-')[4])/20)*2.2)) * 80)
        var newKeyComp = splitta[0] + '-' + splitta[1] + '-' + splitta[2] + '-' + splitta[3] + '-' + splitta[4] + '-' + newH
        box = newKeyComp
        this.state.push('TEXT:' + newKeyComp)
        this.textBoxs[index] = newKeyComp
        
        this.writeOnCanvas(this.textBoxVals[box.split('-')[0]], box.split('-')[2],Number(box.split('-')[3]),box.split('-')[4])
        this.removeBoundedBoxs()
        this.lastTogg = '' 
        this.addSharedText(box.split('-')[0]  + '-' + box.split('-')[2] + '-' + Number(box.split('-')[3]) + '-' + box.split('-')[4]+ '-'+ this.userAccount.uid + '-' + box.split('-')[1] + '-' + this.textBoxVals[box.split('-')[0]])
      }
    }
  }

  public toggleWebcamOff(): void {
    this.showWebcam = false;
  }  

  closeUploader(){
    document.getElementById('fileLoader').setAttribute('style','display:none')
    document.getElementById('videoChat').setAttribute('style','')
    if(this.lastTogg !== 'files'){
      document.getElementById('files').click()
    }
  }

  writeOnCanvas(text,x,y,w){
    x = Number(x)
    y = Number(y)
    w = Number(w)
    var ctx = this.sharedCanvas.getContext('2d');
    ctx.font = '20px serif';
    y += 45
    x += 0
    //
    var maxCharacters = Math.floor((w/20)* 2.6) 
    var line = ''
    text.trim().split('\n').forEach(l=>{
      l.replace(/\s+/g,' ').trim().split(' ').forEach(word=>{
        var testLine = line + ' ' +word
        if(testLine.length < maxCharacters){
          if(line === ''){
            line = word
          }else{
            line += ' ' + word
          }
        }else{
          ctx.fillText(`${line}`, x, y, w);
          y += 20
          line = word
        }
      })
      ctx.fillText(`${line}`, x, y, w);
      y += 20
      line = ''
    })
  }

  modalRateing(key){
    console.log(key)
  }

  clearCanvas(){
    var dbRef = this.db.database
    dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${'CLEAR:' + new Date()}`}).then(()=>{
      this.sharedCanvas.width = this.sharedCanvas.width
      this.meetingID.split('-').forEach(UID=>{
        dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + `${UID}` + '/files').remove()
        dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + `${UID}` + '/lines').remove()
        dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + `${UID}` + '/text').remove()
      })
      // this.actions.push('CLEAR')
      this.sharedLines = ''
      this.personalLines = ''
      this.textBoxs = []
      this.textBoxVals = {}
      this.imageURLS = {}
      this.cacheImages = {}
      this.canvasImages = []
    })
  }

  async moveSharedText(){
    var dbRef = this.db.database
    var pubVal = 'm-TEXT:'
    var newMoves = ''
    for (var property in this.movedTextFinal) {
      var timeStamp = this.movedTextTS[property]
      var splitta = this.movedTextFinal[property].split('-')
      console.log(this.movedTextFinal[property],property)
      await dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid + '/text/' + `${property.split('-')[0]}`)
      .once('value').then(async snap=>{
        if(snap.exists()){
          console.log(snap.val())
          var newConf = splitta[0] + '-' + splitta[2] + '-' + splitta[3] + '-' + splitta[4] + '-' + `${this.userAccount.uid}` + '-' + splitta[1] + '-' + this.textBoxVals[splitta[0]]
          await dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid + '/text').update({[property.split('-')[0]]:`${newConf}`})
        }else{
          var newConf = splitta[0] + '-' + splitta[2] + '-' + splitta[3] + '-' + splitta[4] + '-' + `${this.otherUID}` + '-' + splitta[1] + '-' + this.textBoxVals[splitta[0]]
          await dbRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.otherUID + '/text').update({[property.split('-')[0]]:`${newConf}`})
        }
      })
      if(newMoves === ''){
        console.log(this.movedTextInter[this.movedTextFinal[property]])
        newMoves = `${property}`+ '->' + `${this.movedTextFinal[property]}` + '->' + timeStamp + '->' + this.textBoxVals[this.movedTextFinal[property].split('-')[0]]
        // this.actions.push(`${property}`+ '->' + `${this.movedTextFinal[property]}` + '->' + timeStamp + '->' + this.textBoxVals[this.movedTextFinal[property].split('-')[0]])
      }else{
        newMoves += '--||$%^&*#|||_+@*^&**#^EDcfhxrt%@&*#($yexcjfghvhfgj.,.,.,.,.][2jkn-------' + `${property}`+ '->' + `${this.movedTextFinal[property]}` + '->' + timeStamp + '->' + this.textBoxVals[this.movedTextFinal[property].split('-')[0]]
        // this.actions.push(`${property}`+ '->' + `${this.movedTextFinal[property]}` + '->' + timeStamp + '->' + this.textBoxVals[this.movedTextFinal[property].split('-')[0]])
      }
    }
    pubVal += newMoves
    this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${pubVal}`}).then(()=>{
      
      this.movedTextFinal = {}
      this.movedTextInter = {}
      this.movedTextTS = {}
    })
  }

  // moveSharedText(newText){
  //   var TS = `${+ new Date()}`
  //   this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid + '/files').update({[TS]:`${newText}`}).then(()=>{
  //     var pubVal = 'm-TEXT:' + TS + '-' + newText
  //     this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${pubVal}`})
  //   })
  // }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }
  public toggleWebcamOn(): void {
    this.showWebcam = true;
  }

  back(){
    console.log(this.actions)
  }

  // addTextBox(){
  //   var TS = + new Date()
  //   var newTextBox = `${TS}` + '--400-400-300-600'
  //   this.textBoxVals[`${TS}`] = ''
  //   this.textBoxs.push(newTextBox)
  //   this.textBoxEmitter.emit(this.textBoxs)
  //   setTimeout(()=>{
  //     this.createBoundedBox(400,400,300,300,newTextBox)
  //   },0)
  // }

  placeTextBox(){
    console.log('doubleClicked')
  }

  drawOnSharedCanvas(data){
    var line = data
    var color = line.split('-')[0]
    var size = Number(line.split('-')[1])
    var shape:CanvasLineCap
    if(line.split('-')[2] === 'round'){
      shape = 'round'
    }else{
      shape = 'square'
    }

    var lastX = -1
    var lastY = -1
    line.split('-')[3].split('|').forEach(point=>{
      var x = Number(point.split('.')[0])
      var y = Number(point.split('.')[1])
      if (lastX === -1 || lastY === -1) {
        var context = this.sharedCanvas.getContext('2d')  
        lastX = x;
        lastY = y;
        context.beginPath()
        context.moveTo(lastX,lastY)  
        context.lineTo(x,y)
        context.lineWidth = size;
        context.lineCap = shape;
        context.strokeStyle = color;
        context.stroke();
      } else{ 
        var context = this.sharedCanvas.getContext('2d') 
        context.beginPath()
        context.moveTo(lastX,lastY)  
        context.lineTo(x,y)
        context.lineWidth = size;
        context.lineCap = shape;
        context.strokeStyle = color;
        context.stroke();
        lastX = x;
        lastY = y;
      }
    })
  }

  openModalImage(){
    document.getElementById('modalImage').setAttribute('style','width: 68.5%;margin-top: -256px;box-shadow: 2px 2px 5px #c3c3c3, -4px -4px 1px #ffffff;border-radius: 15px;')
  }

  shareResource(type,data){
    //add a time stamp, add to personal contributiobs, publish key to share
    // this.db.database.ref('Sessions' + '/' + this.meetingID + '/sharePort').update({[this.userAccount.uid]:PACKET})
    if(type === 'LINE'){
      // this is where we are going to update out line contrinutions and send out new value to the port
      this.drawOnSharedCanvas(data)
    }else if(type === 'FILE'){
      var PACKET = + new Date() + '-' + data
      
    }
  }
  removeSharedResource(RUID){
    //remove resource from personal contribtuions, publish key to remove
  }

  endCall(){
    try{
      this.room.disconnect()
      // window.open('https://verifiedteachers.com/', '_blank');
      window.location.href = 'https://verifiedteachers.com/profile/' + this.userAccount.uid
      return false;
    }catch{
      window.location.href = 'https://verifiedteachers.com/profile/' + this.userAccount.uid
    }

  } 

  async triggerSnapshot(){
    document.getElementById('screenCapture').setAttribute('class','screenCaptureWait')
    this.trigger.next();
    this.toggle('camera')
    this.toggle("files")
    this.showWebcam = false   
  }

  tester(){

  }
  
 async getUserFiles(){
    var holdList = []
    var promises = []
    this.db.database.ref(this.local + '/' + this.userAccount.uid + '/storage/').orderByKey().once('value',val=>{
      val.forEach(STAMP=>{
        var resourceKey = ''
        if(STAMP.key.includes('-')){
          resourceKey = STAMP.key
        }else{
          resourceKey = this.userAccount.uid + '-' + STAMP.key
        }
        this.resourceNames[resourceKey] = STAMP.child('name').val()
        holdList.unshift(resourceKey)
      })   
    }).then(()=>{
      holdList.forEach(resourceKey=>{
        promises.push(this.afm.ref('personalFiles/'+resourceKey.split('-')[0]+'/'+resourceKey.split('-')[1]).getMetadata().toPromise())
        // this.afm.ref('personalFiles/'+resourceKey.split('-')[0]+'/'+resourceKey.split('-')[1]).getMetadata().toPromise().then(val=>{
        //   // disitinguish images vs non images and pass in the approprite URL 
        //   var contentType = val.contentType
        //   console.log(contentType,String(contentType).toLowerCase().includes('image/'))
        //   if(String(contentType).toLowerCase().includes('image/')){
        //     this.personalFiles.push(['PERSONAL',resourceKey,this.isTeacher,this.userAccount.uid,resourceKey])
        //   }else{
        //     this.documentFiles.push(['PERSONAL',resourceKey,this.isTeacher,this.userAccount.uid,resourceKey])
        //   }
        // })
      })
    }).then(async ()=>{
      var count = 0 
      Promise.all(promises).then((values) => {
        values.forEach(prom=>{
          var contentType = prom.contentType
          if(String(contentType).toLowerCase().includes('image/')){
            this.personalFiles.push(['PERSONAL',holdList[count],this.isTeacher,this.userAccount.uid,holdList[count],this.resourceNames[holdList[count]]])
          }else{
            this.documentFiles.push(['PERSONALDOC',holdList[count],this.isTeacher,this.userAccount.uid,holdList[count],this.resourceNames[holdList[count]]])
          }
          count += 1
        })
      }).then(()=>{
        this.personalFilesEmitter.emit(this.personalFiles)
        this.documentFilesEmitter.emit(this.documentFiles)
      })

    })
  }
  


  // getUserFiles(){
  //   // this is where we should be get the URLs for the files we are uploading
  //   // this will allow us to distinguish all URLs to load resources and put them into the right place
  //   // there should be a image / non image emiiter -- not personalFileEmitter
  //   this.db.database.ref(this.local + '/' + this.userAccount.uid + '/storage/').orderByChild('value').once('value',val=>{
  //     val.forEach(STAMP=>{
  //       var resourceKey = ''
  //       if(STAMP.key.includes('-')){
  //         resourceKey = STAMP.key
  //         this.personalFiles.unshift(['PERSONAL',STAMP.key,this.isTeacher,this.userAccount.uid,STAMP.key])
  //       }else{
  //         resourceKey = this.userAccount.uid + '-' + STAMP.key
  //       }
  //       this.personalFiles.unshift(['PERSONAL',STAMP.key,this.isTeacher,this.userAccount.uid,resourceKey])
  //     })   
  //   }).then(()=>{
  //     this.personalFilesEmitter.emit(this.personalFiles)
  //   })
  // }

  classroomEnv(){
    document.addEventListener('mousemove',e=>{
      if(this.splitter !== ''){
        if(this.initialX === -1){
          this.initialX = e.clientX
          this.initialY= e.clientY
        }
        var splitter = this.splitter.split('-')
        var newOriginX = Number(splitter[2])
        var newOriginY = Number(splitter[3])
        var newWidth = Number(splitter[4])
        var newHeight = Number(splitter[5])
        if(this.currentDot === 1){
          //top left
          newWidth = Number(splitter[4]) + (this.initialX - e.clientX)
          newHeight = Number(splitter[5]) + (this.initialY - e.clientY)
          newOriginY = newOriginY - (this.initialY - e.clientY)
          newOriginX = newOriginX - (this.initialX - e.clientX)
          if(newWidth < 51){
            newWidth = 50
            newOriginX = Number(splitter[2])
          }
          if(newHeight < 51){
            newHeight = 50
            newOriginY = Number(splitter[3])
          }
          if(newOriginY < 0){
            newOriginY = 0
          }
          if(newOriginX < 0){
            newOriginX = 0
          }
        }else if(this.currentDot === 2){
          //top right
          newWidth = Number(splitter[4]) - (this.initialX - e.clientX)
          newHeight = Number(splitter[5]) + (this.initialY - e.clientY)
          newOriginY = newOriginY - (this.initialY - e.clientY)
          if(newWidth < 51){
            newWidth = 50
            
          }
          if(newHeight < 51){
            newHeight = 50
            newOriginY = Number(splitter[3])
          }
          if(newOriginY < 0){
            newOriginY = 0
          }
          if(newOriginX < 0){
            newOriginX = 0
          }
        }else if(this.currentDot === 3){
          //bottom left
          newWidth = Number(splitter[4]) + (this.initialX - e.clientX)
          newHeight = Number(splitter[5]) - (this.initialY - e.clientY)
          newOriginX = newOriginX - (this.initialX - e.clientX)
          if(newWidth < 51){
            newWidth = 50
            newOriginX = Number(splitter[2])
          }
          if(newHeight < 51){
            newHeight = 50
          }
          if(newOriginY < 0){
            newOriginY = 0
          }
          if(newOriginX < 0){
            newOriginX = 0
          }
        }else if(this.currentDot === 4){
          newWidth = Number(splitter[4]) + (e.clientX - this.initialX)
          newHeight = Number(splitter[5]) + (e.clientY - this.initialY)
          if(newWidth < 51){
            newWidth = 50
          }
          if(newHeight < 51){
            newHeight = 50
          }
          if(newOriginY < 0){
            newOriginY = 0
          }
          if(newOriginX < 0){
            newOriginX = 0
          }
        }else if(this.currentDot === 0){
          newOriginX = newOriginX - (this.initialX - e.clientX)
          newOriginY = newOriginY - (this.initialY - e.clientY)
          if(newOriginY < 0){
            newOriginY = 0
          }
          if(newOriginX < 0){
            newOriginX = 0
          }
        }
        // this is where the if stmnts go to see in what way the change will effect the box. 
        this.freshWidth = newWidth
        this.freshHeight = newHeight
        this.freshOriginX = newOriginX
        this.freshOriginY = newOriginY
        if(this.currentBoxType === 'FILE'){
          this.createBoundedBox(Number(newOriginX),Number(newOriginY),newWidth,newHeight,this.currentBoxId)
        }else if(this.currentBoxType === 'TEXT'){
          this.createBoundedTextBox(Number(newOriginX),Number(newOriginY),newWidth,newHeight,this.currentBoxId)
        }
        this.splitter = splitter[0] + '-' + splitter[1] + '-' + newOriginX + '-' + newOriginY + '-' + newWidth + '-' + newHeight
        this.initialX = e.clientX
        this.initialY = e.clientY
      }
    })
    document.getElementById('earUpdater').addEventListener('click',()=>{
      if(this.ear === true){
        this.ear = false
        if(this.localHoldDrawColor=== '#1E5663'){
          document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/vector-32@2x.svg"), auto;background-color: transparent;position: absolute;')
        }else if(this.localHoldDrawColor === '#E6FFBE'){
          document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (1).svg"), auto;background-color: transparent;position: absolute;')
        }else if(this.localHoldDrawColor === '#B1E7F3'){
          document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (2).svg"), auto;background-color: transparent;position: absolute;')
        }else if(this.localHoldDrawColor === '#3E3E3E'){
          document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (3).svg"), auto;background-color: transparent;position: absolute;')
        }else if(this.localHoldDrawColor === '#931313'){
          document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (4).svg"), auto;background-color: transparent;position: absolute;')
        }else if(this.localHoldDrawColor === '#4F6B22'){
          document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (5).svg"), auto;background-color: transparent;position: absolute;')
        }else if(this.localHoldDrawColor === '#CD919E'){
          document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (6).svg"), auto;background-color: transparent;position: absolute;')
        }else if(this.localHoldDrawColor === '#F5BD1F'){
          document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (7).svg"), auto;background-color: transparent;position: absolute;')
        }else if(this.localHoldDrawColor === '#0000FF'){
          document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (8).svg"), auto;background-color: transparent;position: absolute;')
        }else if(this.localHoldDrawColor === '#964B00'){
          document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (9).svg"), auto;background-color: transparent;position: absolute;')
        }
        this.localDrawColor = this.localHoldDrawColor
      }else{
        this.ear = true
        this.localHoldDrawColor = this.localDrawColor
        document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/rectangle-39@2x.svg"), auto ;background-color: transparent;position: absolute;')
        this.localDrawColor = 'whitesmoke'
      }
    })
    document.getElementById('Pen1').addEventListener('click',e=>{ 
      this.localDrawColor = '#1E5663'
      document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/vector-32@2x.svg"), auto ;background-color: transparent;position: absolute;')
    })
    document.getElementById('Pen2').addEventListener('click',e=>{ 
      this.localDrawColor = '#E6FFBE'
      document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (1).svg"), auto ;background-color: transparent;position: absolute;')
    })
    document.getElementById('Pen3').addEventListener('click',e=>{ 
      this.localDrawColor = '#B1E7F3'
      document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (2).svg"), auto;background-color: transparent;position: absolute;')
    }) 
    document.getElementById('Pen4').addEventListener('click',e=>{ 
      this.localDrawColor = '#3E3E3E'
      document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (3).svg"), auto;background-color: transparent;position: absolute;')
    })
    document.getElementById('Pen5').addEventListener('click',e=>{ 
      this.localDrawColor = '#931313'
      document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (4).svg"), auto;background-color: transparent;position: absolute;')
    })
    document.getElementById('Pen6').addEventListener('click',e=>{ 
      this.localDrawColor = '#4F6B22'
      document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (5).svg"), auto;background-color: transparent;position: absolute;')
    })
    document.getElementById('Pen7').addEventListener('click',e=>{ 
      this.localDrawColor = '#CD919E'
      document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (6).svg"), auto;background-color: transparent;position: absolute;')
    }) 
    document.getElementById('Pen8').addEventListener('click',e=>{ 
      this.localDrawColor = '#F5BD1F'
      document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (7).svg"), auto;background-color: transparent;position: absolute;')
    })    
    document.getElementById('Pen9').addEventListener('click',e=>{ 
      this.localDrawColor = '#0000FF'
      document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (8).svg"), auto;background-color: transparent;position: absolute;')
    })    
    document.getElementById('Pen10').addEventListener('click',e=>{ 
      this.localDrawColor = '#964B00'
      document.getElementById('canvas').setAttribute('style','cursor: url("../../../assets/Vector (9).svg"), auto;background-color: transparent;position: absolute;')
    })
  
    document.getElementById('size1').addEventListener('mousedown',e=>{ 
      this.localDrawSize = 5
    })
    document.getElementById('size2').addEventListener('mousedown',e=>{ 
      this.localDrawSize = 10
    })
    document.getElementById('size3').addEventListener('mousedown',e=>{ 
      this.localDrawSize = 15
    }) 
    document.getElementById('size4').addEventListener('mousedown',e=>{ 
      this.localDrawSize = 35
    })
    document.getElementById('size5').addEventListener('mousedown',e=>{ 
      this.localDrawSize = 55
    })
    document.getElementById('size6').addEventListener('mousedown',e=>{ 
      this.localDrawSize = 75
    })    
    document.getElementById('circle').addEventListener('mousedown',e=>{ 
      this.localDrawShape = 'round'
    })
    document.getElementById('square').addEventListener('mousedown',e=>{ 
      this.localDrawShape = 'square'
    })
    document.getElementById('clear').addEventListener('click',()=>{
      this.lastX = -1
      this.lastY = -1
      this.canvas.width = this.canvas.width;
      this.personalLines = ''
      this.canvasImages = []
      this.canvasImagesEmitter.emit([])
    })
    // 1. engage canvas 2. engage buttons 3. engage camera
    this.canvas = document.getElementById('canvas')
    this.sharedCanvas = document.getElementById('sharedCanvas')

    this.canvas.addEventListener('mousedown',e=>{ 
      this.startPostion(e)
    })



    this.canvas.addEventListener("mousemove", e=>{
      this.draw(e.clientX,e.clientY+window.scrollY,this.localDrawSize,this.localDrawShape,this.localDrawColor)
    }) 
    this.canvas.addEventListener("mouseup",e=>{
      if(this.isPainting){ 
        this.canvas.width = this.canvas.width; 
        var TS = `${+ new Date()}`
        var newLine = `${this.localDrawColor}` +'-'+ `${this.localDrawSize}` +'-'+ `${this.localDrawShape}` +'-'+ `${this.newLine}`+'-'+ `${TS}`
        if(this.personalLines === ''){
          this.personalLines = newLine 
        }else{    
          this.personalLines += '||' + newLine 
        }
        this.state.push('LINE:' + newLine)
        this.addSharedLine(newLine)
      }
      if(this.over && this.dropDrag){
        var changedY = Number(e.clientY) + Number(window.scrollY)
        var TS = `${+ new Date()}`
        this.canvasImages.push(this.currentDropKey + '-' +  e.clientX + '-' + `${changedY}` + '-200-200-' + `${TS}`+ '-' + `${TS}`)
        this.state.push('IMAGE:' + this.currentDropKey + '-' +  e.clientX + '-' + `${changedY}` + '-200-200')
        this.firebase.storageURL(this.currentDropKey.split('-')[0],this.currentDropKey.split('-')[1]).then(url=>{
          var image = new Image()
          image.src = `${url}`;  
          image.crossOrigin = "Anonymous"
          this.imageURLS[this.currentDropKey + '-' +  e.clientX + '-' + `${changedY}` + '-200-200-' + `${TS}`+ '-' + `${TS}`] = url
          var canvasHold = this.sharedCanvas
          var keyRef = this.currentDropKey
          image.addEventListener('load',()=>{
            this.cacheImages[keyRef + '-' +  e.clientX + '-' + `${changedY}` + '-200-200-' + `${TS}`+ '-' + `${TS}`] = image
            canvasHold.getContext('2d').drawImage(image, Number(e.clientX),Number(e.clientY + window.scrollY), 200,200);
          })
          image.onload
        }).then(()=>{
          
          this.addSharedImage(this.currentDropKey + '-' +  e.clientX + '-' + `${changedY}` + '-200-200-' + `${TS}`+ '-' + `${TS}` , TS)
          // this.shareResource('FILE',this.currentDropKey)
          this.dropDrag = false
          this.currentDropKey = ''
          
        })
        // var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/personalFiles/' + this.currentDropKey.split('-')[0] + '/' +  this.currentDropKey.split('-')[1])  
        // //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
        // storage.getDownloadURL().then(function(url) {
        //   image2.src = `${url}`;  
        //   image2.onload = function(){
        //     context.drawImage(image2, e.clientX,e.clientY +window.scrollY, 300,300);
        //   }
        // })
      }else if(!this.over && this.dropDrag){
        this.dropDrag = false
        this.currentDropKey = ''
      }
      // personalLines += '||'
      this.finishedPostion()
      // this.db.database.ref('providers/userInfo/' + `${this}` + '/meeting').update({[pub]:`${newLine}`})
      // if(painitng){
      //   painitng = false
      //   finishedPostion()
      // }

    })
    this.canvas.addEventListener("mouseover", ()=>{
      this.over = true 
    })
    
    this.canvas.addEventListener("mouseout", ()=>{
      this.over = false
      if(this.isPainting){
        this.isPainting = false
        var TS = `${+ new Date()}`
        var newLine = `${this.localDrawColor}` +'-'+ `${this.localDrawSize}` +'-'+ `${this.localDrawShape}` +'-'+ `${this.newLine}`+'-'+ `${TS}`
        var key = 'LINE:' + newLine
        this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${key}`}).then(()=>{
          if(this.personalLines === ''){
            // i can add in the size, shape, and color into this piece of every process
            // each line is assumed to contain points of the same style
            this.personalLines = newLine 
          }else{
            this.personalLines += '||' + newLine 
          } 
          this.shareResource('LINE',newLine)
          this.finishedPostion()
        })

        // this.db.database.ref('providers/userInfo/' + `${meetingHold}` + '/meeting').update({[pub]:`${newLine}`})
      }
    })  
    this.canvas.addEventListener('contextmenu', e =>{
      this.over = false
      if(this.isPainting){
        this.isPainting = false
        var TS = `${+ new Date()}`
        var newLine = `${this.localDrawColor}` +'-'+ `${this.localDrawSize}` +'-'+ `${this.localDrawShape}` +'-'+ `${this.newLine}`+'-'+ `${TS}`
        var key = 'LINE:' + newLine
        this.db.database.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + this.userAccount.uid).update({'port':`${key}`}).then(()=>{
          if(this.personalLines === ''){
            // i can add in the size, shape, and color into this piece of every process
            // each line is assumed to contain points of the same style
            this.personalLines = newLine 
          }else{
            this.personalLines += '||' + newLine 
          } 
          this.shareResource('LINE',newLine)
          this.finishedPostion()
        })
      }
      return false
    });
  }

  finishedPostion(){
    this.lastX = -1
    this.lastY = -1
    this.isPainting = false
    this.newLine = ''
  }  

  startPostion(e){   
    this.isPainting = true  
    this.draw(e.clientX,e.clientY+window.scrollY,this.localDrawSize,this.localDrawShape,this.localDrawColor)
  }

  draw(x,y,dotSize,dotShape,color){
    y += 19 
    if(!this.isPainting) return
    if (this.lastX === -1 || this.lastY === -1) {
      var context = this.sharedCanvas.getContext('2d')  
      this.lastX = x;
      this.lastY = y;
      context.beginPath()
      context.moveTo(this.lastX,this.lastY)  
      context.lineTo(x,y)
      context.lineWidth = dotSize;
      context.lineCap = dotShape;
      context.strokeStyle = color;
      context.stroke();
    } else{
      var context = this.sharedCanvas.getContext('2d') 
      context.beginPath()
      context.moveTo(this.lastX,this.lastY)  
      context.lineTo(x,y)
      context.lineWidth = dotSize;
      context.lineCap = dotShape;
      context.strokeStyle = color;
      context.stroke();
      this.lastX = x;
      this.lastY = y;
    }
    if(this.newLine === ''){
      this.newLine = String(x) + '.' + String(y)
    }else{
      this.newLine += '|' + String(x) + '.' + String(y)
    }  
  }

  finalTest(val){
    console.log(val)
  }

  async initializeCanvas2(){
    // this is where we should observe the initial timeline to go back and forth 
    // actually this is not where we should be doing this because we are not brining over the state 


    // the time sync function is holding thids together, we need to make this work on some type of locking mechanism. loading
    //images is incredibly slow, and thus the preload supllement is still not ideal, while functional
    var firebaseRef = this.db.database
    var state = []
    var properState = []
    var done = false
    var count = 1
 
    await this.meetingID.split('-').forEach(UID=>{
      var local = ''
      if(count === 1){
        //teacher
        local = 'providers/userInfo/'
      }else{
        local = 'users/'
      }
      count += 1
      firebaseRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + `${UID}`).once('value',val=>{
        val.child('text').forEach(textBox =>{
          state.push('TEXT:' + textBox.key + '-' + textBox.val())
        })
        val.child('files').forEach(image =>{
          state.push('FILE:' + image.key + '-' + image.val())
          this.getInitialBoardImages(image.key + '-' + image.val())
        })
        val.child('lines').forEach(line =>{
          state.push('LINE:' + line.key + '-' + line.val())
          if(this.userAccount.uid === UID){
            if(this.personalLines === ''){
              this.personalLines = line.val()
            }else{
              this.personalLines += '||' + line.val()
            }
          }else{
            if(this.sharedLines === ''){
              this.sharedLines = line.val()
            }else{
              this.sharedLines += '||' + line.val()
            }
          }
        })    
        val.child('nonCanvasFiles').forEach(nonCanvasFile =>{
          
          var hold = nonCanvasFile.key
          if(!nonCanvasFile.key.includes('-')){
            hold = this.userAccount.uid + '-' +  hold
          }
          var hold2 = hold.split('-')[1]
          this.db.database.ref(local + '/' + UID + '/storage/' + `${hold2}`+'/name').once('value').then(ev=>{
            console.log(nonCanvasFile,nonCanvasFile.key,nonCanvasFile.val(),ev.val())  
            this.inboxFiles.push(['INBOX',hold,this.isTeacher,this.userAccount.uid,hold,ev.val()])
          })
        })
        this.inboxFilesEmitter.emit(this.inboxFiles)
      }).then(()=>{
        if(done){
          var TSHOLD = []
          state.forEach(config=>{
            var key = config.split(':')[0]
            var packet = config.split(':')[1]
            var TS = ''
            if(key === 'TEXT'){
              TS = packet.split('-')[6]
            }else if(key === 'LINE'){
              TS = packet.split('-')[0]
            }else if(key === 'FILE'){
              TS = packet.split('-')[7]
            }

            if(TSHOLD.length === 0){
              TSHOLD.push(TS)
              properState.push(config)
            }else{
              var index = 0
              var end = TSHOLD.length - 1
              var placed = false
              TSHOLD.forEach(stamp=>{
                if(Number(TS) > Number(stamp) && !placed){
                  placed = true
                  TSHOLD.splice(index,0,TS)
                  properState.splice(index,0,config)
                }else if(index === end && !placed){
                  placed = true
                  TSHOLD.push(TS)
                  properState.push(config)
                }
                index += 1
              })
            }
          })
        }else{
          done = true
        }
      }).then(()=>{
        // this is where we signal we have finished laoding

        // we will properly cach everythins


        if(properState.length > 0){
          setTimeout(()=>{
            properState.reverse()
            properState.forEach(res=>{
              var resKey = res.split(':')[0]
              var resPacket = res.split(':')[1]
              if(resKey === 'TEXT'){
                this.writeOnSharedCanvas(resPacket)
              }else if(resKey === 'FILE'){
                var splitter = resPacket.split('-')
                var RUID = splitter[0]
                var AUID = splitter[1]
                var DBKey = splitter[2]
                var OriginX = splitter[3]
                var OriginY = splitter[4]
                var Width = splitter[5]
                var Height = splitter[6]
                var TS = splitter[7]
                var config = AUID + '-' + DBKey + '-' +  OriginX + '-' + `${OriginY}` + '-' +  Width + '-' + `${Height}` + '-' + `${TS}`+ '-' + `${RUID}`
                var canvasHold = this.sharedCanvas
                var imageHold: HTMLImageElement = this.cacheImages[config]
                canvasHold.getContext('2d').drawImage(imageHold, OriginX ,OriginY, Width,Height);
              }else if(resKey === 'LINE'){
                var line = resPacket
                var splitter = line.split('-')
                var color = splitter[1]
                var shape:CanvasLineCap
                if(splitter[3] === 'round'){
                  shape = 'round'
                }else{
                  shape = 'square'
                }
                var size = splitter[2]
                var points = splitter[4]
                var lastX = -1
                var lastY = -1
                points.split('|').forEach(point=>{
                  var x = Number(point.split('.')[0])
                  var y = Number(point.split('.')[1])
                  if (lastX === -1 || lastY === -1) {
                    var context = this.sharedCanvas.getContext('2d')  
                    lastX = x;
                    lastY = y;
                    context.beginPath()
                    context.moveTo(lastX,lastY)  
                    context.lineTo(x,y)
                    context.lineWidth = size;
                    context.lineCap = shape;
                    context.strokeStyle = color;
                    context.stroke();
                  } else{
                    var context = this.sharedCanvas.getContext('2d') 
                    context.beginPath()
                    context.moveTo(lastX,lastY)  
                    context.lineTo(x,y)
                    context.lineWidth = size;
                    context.lineCap = shape;
                    context.strokeStyle = color;
                    context.stroke();
                    lastX = x;
                    lastY = y;
                  }
                })
              }
            })
          },5000)
        }
      })
    })
  }

  
  async initializeCanvas(){
    // var canvas = document.querySelector('canvas')
    // canvas.height = 7400;
    // canvas.width = 1440  
    // console.log(await this.SES.initializeBoard(this.meetingID))
    // first we need to reorsgnzie the way we write to the database
    var firebaseRef = this.db.database
    this.meetingID.split('-').forEach(UID=>{
      firebaseRef.ref('sessionEnvironments/' + `${this.meetingID}` + '/' + `${UID}`).once('value',val=>{
        val.child('text').forEach(textBox =>{
          this.writeOnSharedCanvas(textBox.key + '-' + textBox.val())
        })
        val.child('files').forEach(image =>{
          this.drawImageOnSharedCanvas(image.key + '-' + image.val())
        })
        val.child('lines').forEach(line =>{
          if(this.userAccount.uid === UID){
            if(this.personalLines === ''){
              this.personalLines = line.val()
            }else{
              this.personalLines += '||' + line.val()
            }
          }else{
            if(this.sharedLines === ''){
              this.sharedLines = line.val()
            }else{
              this.sharedLines += '||' + line.val()
            }
          }
          var splitter = line.val().split('-')
          var color = splitter[0]
          var shape:CanvasLineCap
          if(splitter[2] === 'round'){
            shape = 'round'
          }else{
            shape = 'square'
          }
          var size = splitter[1]
          var points = splitter[3]
          var lastX = -1
          var lastY = -1
          points.split('|').forEach(point=>{
            var x = Number(point.split('.')[0])
            var y = Number(point.split('.')[1])
            if (lastX === -1 || lastY === -1) {
              var context = this.sharedCanvas.getContext('2d')  
              lastX = x;
              lastY = y;
              context.beginPath()
              context.moveTo(lastX,lastY)  
              context.lineTo(x,y)
              context.lineWidth = size;
              context.lineCap = shape;
              context.strokeStyle = color;
              context.stroke();
            } else{
              var context = this.sharedCanvas.getContext('2d') 
              context.beginPath()
              context.moveTo(lastX,lastY)  
              context.lineTo(x,y)
              context.lineWidth = size;
              context.lineCap = shape;
              context.strokeStyle = color;
              context.stroke();
              lastX = x;
              lastY = y;
            }
          })
        })
        val.child('nonCanvasFiles').forEach(nonCanvasFile =>{
          this.inboxFiles.push(['INBOX',nonCanvasFile.key,this.isTeacher,this.userAccount.uid,nonCanvasFile.key])
          this.inboxFilesEmitter.emit(this.inboxFiles)
        })
      })
    })
  
  }

  removeHeaderAndFooter(){
    if(document.getElementById('rootHead') !== null){
      document.getElementById('rootHead').setAttribute('style','display:none')
    } 
    if(document.getElementById('rootFoot') !== null){
      document.getElementById('rootFoot').setAttribute('style','display:none')
    }
  }

}
