<div class="group-247 border-1px-blue">
    <div class="overlap-group">
      <div class="group-244">
        <div class="overlap-group1"><div class="x1130am-1230pm body">{{date}}</div></div>
      </div>
      <!-- <div class="more-vertical">
        <img
          class="vector"
          src="../../../assets/vector-4@2x.png"
        />
        <img
          class="vector-1"
          src="../../../assets/vector-4@2x.png"
        />
        <img
          class="vector-1"
          src="../../../assets/vector-4@2x.png"
        />
      </div> -->
    </div>
    <div class="frame-234">
      <img
        id = 'proPic{{app}}'
        class="ellipse-26"
        src="https://firebasestorage.googleapis.com/v0/b/v-t-dev.appspot.com/o/teacherPics%2FyUMfnK5HE5hbOAMGij7QnE28IxF3?alt=media&token=5c74eb83-8b2b-4ef8-990c-5b768eedc1da"
      />
      <div class="igor-belagorudsky body">{{studentName}}</div>
    </div>
    <div class="group-243">
      <div class="subject-history body">
        <span class="span body">Subject:</span><span class="span body">Reading Lesson</span>
      </div>
      <div class="rate-15h body"><span class="span body">Duration:</span><span class="span body">{{duration}} Minutes</span></div>
    </div>
    <div class="group-245">
        <div class="overlap-group2" style="z-index: 1;cursor: pointer;" routerLink="/VerifiedVideo/{{room}}"><div class="start-meeting body">Enter Session</div></div>
      <!-- <div class="overlap-group2" style="z-index: 1;cursor: pointer;" routerLink="/VerifiedVideo/{{app[6]}}-{{app[2]}}-{{app[14]}}"><div class="start-meeting body">Enter Session</div></div> -->
    </div>
  </div>

