import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-community-resources',
  templateUrl: './community-resources.component.html',
  styleUrls: ['./community-resources.component.scss']
})
export class CommunityResourcesComponent implements OnInit {
  topics = 'Topics'
  subTopics = 'Subtopics'   
  @Output() results = new EventEmitter<any[]>();

  @Output() MyFiles = new EventEmitter<any[]>();
  myFiles = []

  @Output() SharedFiles = new EventEmitter<any[]>();
  sharedFiles = []

  title1 = 'title1'
  desc1 = ''
  downloads1 = -1

  topicsList = ['Practice Problems','Board Templates','Fun Pics','General']
  subtopicsList = ['Math','English','Science','Social Studies','World Langauge','Creative Arts','Fun','General']
  resultList = []
  userID
  succMessages = []
  teacher = true
  Personal = true
  path
  openCount = 2
  NSCount = 0
  NS2Count = 0

  title = ''
  description = ''
  error = []
  currentEvent

  loading = false
  currentSelector = ''

  constructor(private db: AngularFireDatabase,public auth: AuthService,private afm: AngularFireStorage) { }

  async ngOnInit(){
    //Artificial time stamp just in case
    console.log(+ new Date())
    await this.auth.user$.subscribe(usr=>{ 
      this.userID = usr.uid
      var resultListHolder = []
      var dbRef = this.db
      
      this.db.database.ref('resourceDirectory/General/General').orderByValue().once('value').then(val=>{
        val.forEach(function(snap){
          var key = snap.key.split('-')[0]
          var keyValue = snap.key.split('-')[1]
          dbRef.database.ref('communityResources/' + `${key}` + '/' + `${keyValue}`).orderByChild('TS').once('value',val=>{
            resultListHolder.unshift(['SHAREDFILE',snap.key,true,usr.uid,val.child('DownRepo').val(),val.child('TS').val(),val.child('Title').val(),val.child('Desc').val()])
          })
        })
      }).then(()=>{   
        this.resultList = resultListHolder
        this.results.emit(this.resultList)

        this.db.database.ref('providers/userInfo/' + this.userID + '/storage/').orderByChild('value').once('value',val=>{
          val.forEach(STAMP=>{
            console.log(['PERSONAL',STAMP.key,this.teacher,this.userID,STAMP.val()])
            this.myFiles.unshift(['PERSONAL',STAMP.key,this.teacher,this.userID,STAMP])
          })
        }).then(()=>{
          this.db.database.ref('providers/userInfo/' + this.userID + '/sharedStorage/').orderByValue().once('value',val=>{
            val.forEach(STAMP=>{
              var key = ''
              var keyValue = ''
              if(STAMP.key.indexOf('-') === - 1){
                key = this.userID
                keyValue = STAMP.key
              }else{
                key = STAMP.key.split('-')[0]
                keyValue = STAMP.key.split('-')[1]
              }
              dbRef.database.ref('communityResources/' + `${key}` + '/' + `${keyValue}`).once('value').then(snappa=>{
                console.log(['PERSONALSHARED',STAMP.key,this.teacher,this.userID,STAMP.val()])
                this.sharedFiles.unshift(['PERSONALSHARED',STAMP.key,this.teacher,this.userID,snappa.child('DownRepo').val(),snappa.child('TS').val(),snappa.child('Title').val(),snappa.child('Desc').val()])
              })
            })
          })
        }).then(()=>{
          this.MyFiles.emit(this.myFiles)
        }).then(()=>{
          this.db.database.ref('providers/userInfo/' + this.userID + '/storage/').orderByChild('value').on('value',async val=>{
            if(this.NS2Count > 0){
              this.MyFiles.emit(this.myFiles)
            }
            this.NS2Count += 1
          })
          this.db.database.ref('providers/userInfo/' + this.userID + '/newStorage').on('value',val=>{
            console.log('hit ',this.NSCount)
            if(this.NSCount > 0){
              this.myFiles.unshift(['PERSONAL',val.val(),this.teacher,this.userID])
              console.log(this.myFiles)
              this.MyFiles.emit(this.myFiles)
            }
            this.NSCount += 1
          })
        })
      })
    })
  }

  displayMyFiles(){
    var files = document.getElementById('myF')
    if(this.openCount % 2 === 0){
      console.log(this.myFiles)
      files.setAttribute('style', 'background-color: #D8D8D8;border: 5px solid rgb(230,230,230);display:block;')
      this.MyFiles.emit(this.myFiles)
      this.openCount += 1
    }else{
      files.setAttribute('style', 'background-color: #D8D8D8;border: 5px solid rgb(230,230,230);display:none;')
      this.openCount += 1
    }
  }


  deleteMe3($event){
    if(this.Personal){
      this.myFiles = this.myFiles.filter(val => String(val[1]) !== String($event))
      this.MyFiles.emit(this.myFiles)
    }else{
      this.sharedFiles = this.sharedFiles.filter(val => String(val[1]) !== String($event))
      this.MyFiles.emit(this.sharedFiles)
    } 
  }

  uploadPersonal(){
    document.getElementById('inputGroupFile01').click()
  }


  shareStorage($event){
    this.currentEvent = $event
    var files = document.getElementById('myF')
    files.setAttribute('style', 'background-color: #D8D8D8;border: 5px solid rgb(230,230,230);display:block;')
  }

  async publish(){
    // var hold = ['SHAREDFILE',this.TS,true,this.AUID]
    // add to CommunityResources and Personal SharedFile 
    if(this.subTopics === 'Subtopics*' || this.topics === 'Topics*' || this.title == ''){
      this.error = ['Please complete required fields']
    }else{
      var $event = this.currentEvent
      console.log($event)
      var TS = + new Date()
      var value = $event[1]
      var AUID = $event[3]
      var holda = value 
      var RUID = value
      this.db.database.ref('providers/userInfo/' + this.userID + '/sharedStorage').update({[holda]:TS}).then(()=>{
        //we need to put it in the right categories bins 
        if(String(value).indexOf('-') === -1){
          RUID = AUID + '-' + value
        }
        var key = RUID.split('-')[0]
        var keyValue = RUID.split('-')[1]
        this.db.database.ref('resourceDirectory/' + `${this.topics}` + '/' + `${this.subTopics}` + '/').update({[RUID]:TS}).then(()=>{
          this.db.database.ref('communityResources/' + `${key}` + '/' + `${keyValue}`).once('value',val=>{
            if(val.exists()){
             this.db.database.ref('communityResources/' + `${key}` + '/' + `${keyValue}`).update({'Title':this.title, 'Desc':this.description})
            }else{
              this.db.database.ref('communityResources/' + `${key}`).update({[keyValue]:{'TS':TS, 'Title':this.title, 'Desc':this.description,'DownRepo':'0'}})
            }
          }).then(()=>{
            this.error = ['Uploaded!']
            this.close()
            //update
            var topic = this.topics
            var subtopic = this.subTopics
            if(topic === 'Topics'){
              topic = 'General'
            }
            if(subtopic === 'Subtopics'){
              subtopic = 'General'
            }
            console.log(topic,subtopic)
            this.resultList = []
            this.query(topic,subtopic)
          })
        })
      })
    }
  }
  setTopic(sesh){
    this.topics = sesh
    this.resultList = []
    if(this.subTopics === 'Subtopics'){
      this.query(sesh,'General')
    }else{
      this.query(sesh,this.subTopics)
    }
  }

  close(){
    console.log('deleeee')
    var files = document.getElementById('myF')
    files.setAttribute('style', 'background-color: #D8D8D8;border: 5px solid rgb(230,230,230);display:none;')
    document.getElementById('title').textContent = ''
    document.getElementById('description').textContent = '' 
  }

  setSubTopic(sesh){
    this.subTopics = sesh
    this.resultList = []    
    if(this.topics === 'Topics'){
      this.query('General',sesh)
    }else{
      this.query(this.topics,sesh)
    }
  }

  addToStorage(key){
    var TS = + new Date()
    var keyAdj = key
    var key2 
    var keyValue
    if(key.split('-')[0] === this.userID){
      keyAdj = key.split('-')[1]
    }
   
    var key2 
    var keyValue
    if(keyAdj.indexOf('-') === -1){
        key2 = this.userID
        keyValue = keyAdj
    }else{
      key2 = keyAdj.split('-')[0]
      keyValue = keyAdj.split('-')[1]
    }
    this.db.database.ref('providers/userInfo/' + this.userID + '/storage/' + `${keyAdj}`).once('value',val=>{
      if(!val.exists()){
        this.db.database.ref('communityResources/' + `${key2}` + '/' + `${keyValue}`).once('value',vale=>{
          var count = `${Number(vale.child('DownRepo').val().split('-')[0]) + 1}`
          this.db.database.ref('communityResources/' + `${key2}` + '/' + `${keyValue}`).update({'DownRepo':`${count}`})
        })
      }
      this.db.database.ref('providers/userInfo/' + this.userID + '/storage/' + keyAdj).once('value').then(snap=>{
        if(!snap.exists()){
          this.db.database.ref('providers/userInfo/' + this.userID + '/storage').update({[keyAdj]:{'type':'PERSONAL','value':TS}}).then(()=>{
            if(key.split('-')[0] === this.userID){
              this.db.database.ref('providers/userInfo/' + this.userID + '/').update({'newStorage':keyAdj})
            }else{
              this.db.database.ref('providers/userInfo/' + this.userID + '/').update({'newStorage':keyAdj})
            }
            
          }).then(()=>{
            this.succMessages = ["File Added"]
          })
        }
      })
    })
  }

  select(val){
    var selectedElem = document.getElementById(`${val}`)
    var dropship = document.getElementById('dropship')
    var arrow = document.getElementById(`${val}` + 'Arrow')
    var tag = document.getElementById(`${val}` + 'Tag')
    if(this.currentSelector !== ''){
      if(this.currentSelector === val){
        this.currentSelector =''
        if(val === 'days'){
          selectedElem.setAttribute('class','group-99')
          dropship.setAttribute('class','overlap-group1-1')
          tag.setAttribute('class','suitable-days inter-semi-bold-chathams-blue-14px')
          arrow.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }else if(val === 'subject'){
          
          selectedElem.setAttribute('class','group-194')
          dropship.setAttribute('class','overlap-group1-1')
          tag.setAttribute('class','subject inter-semi-bold-chathams-blue-14px')
          arrow.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }
      }else{
        var selectedElem2 = document.getElementById(`${this.currentSelector}`)
        var arrow2 = document.getElementById(`${this.currentSelector}` + 'Arrow')
        var tag2 = document.getElementById(`${this.currentSelector}` + 'Tag')
        if(this.currentSelector === 'days'){
          console.log(1)
          selectedElem2.setAttribute('class','group-99')
          tag2.setAttribute('class','suitable-days inter-semi-bold-chathams-blue-14px')
          arrow2.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }else if(this.currentSelector === 'subject'){
          console.log(2)
          selectedElem2.setAttribute('class','group-194')
          tag2.setAttribute('class','subject inter-semi-bold-chathams-blue-14px')
          arrow2.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }

        this.currentSelector = val
        if(val === 'days'){
          console.log(4)
          selectedElem.setAttribute('class','group-99-1')
          tag.setAttribute('class','suitable-days inter-normal-white-14px')
          arrow.setAttribute('src','../../assets/vector-54@2x.svg')
          dropship.setAttribute('class','overlap-group1-1-3')
        }else if(val === 'subject'){
          console.log(5)
          selectedElem.setAttribute('class','group-194-1')
          tag.setAttribute('class','subject inter-normal-white-14px')
          arrow.setAttribute('src','../../assets/vector-54@2x.svg')
          dropship.setAttribute('class','overlap-group1-1-1')
        }
      }
    }else{
      this.currentSelector = val
      var selectedElem = document.getElementById(`${val}`)
      if(val === 'days'){
        selectedElem.setAttribute('class','group-99-1')
        tag.setAttribute('class','suitable-days inter-normal-white-14px')
        arrow.setAttribute('src','../../assets/vector-54@2x.svg')
        dropship.setAttribute('class','overlap-group1-1-3')
      }else if(val === 'subject'){
        selectedElem.setAttribute('class','group-194-1')
        tag.setAttribute('class','subject inter-normal-white-14px')
        arrow.setAttribute('src','../../assets/vector-54@2x.svg')
        dropship.setAttribute('class','overlap-group1-1-1')
      }
    }
  }

  addToSharedStorage(key){
    var TS = + new Date()
    var keyAdj = key
    if(key.split('-')[0] === this.userID){
      keyAdj = key.split('-')[1]
    }
    this.db.database.ref('providers/userInfo/' + this.userID + '/sharedStorage').update({[keyAdj]:TS})    
  }

  upload($event){
    this.path = $event.target.files[0]
    var TS = + new Date()
    if(this.teacher){
      this.afm.upload("/personalFiles/" + `${this.userID}` + '/' + `${TS}`, this.path).then(()=>{
        this.db.database.ref('providers/userInfo/' + this.userID + '/storage').update({[TS]:{'type':'PERSONAL','value':TS}}).then(()=>{
          this.db.database.ref('providers/userInfo/' + this.userID + '/').update({'newStorage':TS})
        }).then(()=>{
          this.succMessages = ["File Added"]
        })
      })
    }
  }

  // change between shared files and personal
  changeTab(type){
    if(type === 1){
      //Personal Files
      document.getElementById('But1').setAttribute('style', "border: #D8D8D8;border-bottom: none; width: 100px;height:30px;border-top-left-radius: 25px;border-top-right-radius: 25px;background-color: #D8D8D8;color:#292F45;font-size: 16px;width:50%")
      document.getElementById('But2').setAttribute('style', 'width: 100px;height:30px;border-top-left-radius: 25px;border-top-right-radius: 25px;background-color: #292F45;color:rgb(230,230,230);font-size: 16px;width:50%')
      this.Personal = true
      this.MyFiles.emit(this.myFiles)
      
    }else{
      //Shared Files

      document.getElementById('But1').setAttribute('style', 'border: #D8D8D8;width: 100px;height:30px;border-top-left-radius: 25px;border-top-right-radius: 25px;background-color: #292F45;color:rgb(230,230,230);font-size: 16px;width:50%')
      document.getElementById('But2').setAttribute('style', 'border-bottom: none; width: 100px;height:30px;border-top-left-radius: 25px;border-top-right-radius: 25px;background-color: #D8D8D8;color:#292F45;font-size: 16px;width:50%')
      this.Personal = false
      this.MyFiles.emit(this.sharedFiles)
    }
  }
  
  async query(topic,subtopic){
    var resultListHolder = []
    var dbRef = this.db
    var userID = this.userID
    this.db.database.ref('resourceDirectory/' + `${topic}`+'/' + `${subtopic}`).orderByValue().once('value').then(val=>{
      val.forEach(function(snap){
        var key = snap.key.split('-')[0]
        var keyValue = snap.key.split('-')[1]
        dbRef.database.ref('communityResources/' + `${key}` + '/' + `${keyValue}`).once('value',val=>{
          resultListHolder.unshift(['SHAREDFILE',snap.key,true,userID,val.child('DownRepo').val(),val.child('TS').val(),val.child('Title').val(),val.child('Desc').val()])
        })
      })
    }).then(()=>{
      this.resultList = resultListHolder
      this.results.emit(this.resultList)
    })
  }
}
