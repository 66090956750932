<div class="container-center-horizontal">
    <div class="overlap-group">
      <div class="flex-row-1">
        <img
          style="z-index: 100;cursor: pointer;"routerLink = ''
          class="grey-2"
          src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/grey-2@2x.png"
        />
        <div class="group-178">
          <div class="group-177">
            <div class="teachers inter-normal-white-14px"style="z-index: 100;cursor: pointer;"routerLink = ''>Home</div>
            <div class="teacher-sign-up inter-normal-white-14px"style="z-index: 100;cursor: pointer;"routerLink = '/TeacherWelcome'>Teacher Sign Up</div>
          </div>
          <div class="group-176">
            <div class="answers inter-normal-white-14px"style="z-index: 100;cursor: pointer;"routerLink = 'OrgSignUp'>Enroll</div>
            <div class="promotions inter-normal-white-14px"style="z-index: 100;cursor: pointer;"routerLink = 'EventPage'>May Event</div>
          </div>
          <div class="group-175">
            <div class="blog inter-normal-white-14px"style="z-index: 100;cursor: pointer;"routerLink = 'VerifiedBlog/Welcome'>Blog</div>
            <!-- <div class="faq inter-normal-white-14px"style="z-index: 100;cursor: pointer;"routerLink = 'FAQ'>FAQ</div> -->
          </div>
        </div>   
        <div class="flex-col">
          <div class="group-174">
            <div class="linkedin" (click) ='reRoute("https://www.linkedin.com/company/verified-teachers")' style="z-index:1;cursor: pointer;">
              <img
                src="../../../assets/linkedIn.svg"
              />
            </div>
            <div class="facebook" (click) ='reRoute("https://www.facebook.com/VerifiedTeachers")' style="z-index:1;cursor: pointer;">
              <img
                src="../../../assets/facebook.svg"
              />
            </div>
          </div>
          <div class="text-1 inter-normal-white-14px">help@verifiedteachers.com</div>
        </div>
      </div>
      <p class="text-2 inter-normal-celeste-14px">Copyright © Verified Teachers, Inc</p>
    </div>
  </div>
