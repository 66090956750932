<div class="image" style="color:#292F45;margin: 0 auto;text-align: center;" >
    <div style="display: inline-block;vertical-align: top; margin-top: 30px;width:210px;text-align: left;margin-right: 30px;">
        <img style = "width:200px;vertical-align: top;"src = '../../../assets/Original.png'>
    </div>
    <div style="display: inline-block;font-family: 'Avenir Light'; margin-top: 30px;margin-bottom: 30px;width: 60%;">
        <p style = 'font-size: 34px;text-align: left;line-height: 1.1'>{{campTitle}}</p>
        <p style = 'font-size: 22px;text-align: left;line-height: 1.1'>{{campSubTitle}}</p>
    </div>
</div>
<div style="background-color: rgb(230,230,230);margin: 0 auto;text-align: center;padding-top: 20px;">
    <input type="email" class="form-control" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Email Address"autocomplete="off" style="background-color:#F0F0F0;font-size: 18px; width: 300px;display: inline-block;">
    <div *ngIf = '!sentNL' style="text-align: right;display: inline-block;padding: 20px;">
        <button class="btn btn-primary"style="background-color: #EA6F66;border-color: #EA6F66;color: white;"(click) = "signUpNL()">Get More Info</button>
    </div>
    <div></div>
    <div style = 'display: inline-block;max-width: 500px;vertical-align: top;margin-right: 20px;'>
        <div *ngFor = "let p1 of paragraphsCamp1 | async">
          <div>
           <p style="font-size: 22px;padding: 5px;text-align: left;margin: 0 auto;line-height: 1.1;display: block;">
             {{p1}}
           </p>
          </div>
         </div>
    </div>
    <div style = 'display: inline-block;max-width: 500px;vertical-align: top;'>
        <div *ngFor = "let p2 of paragraphsCamp2 | async">
          <div>
            <p style="font-size: 22px;padding: 5px; text-align: left;margin: 0 auto;line-height: 1.1">
              {{p2}}
            </p>
          </div>
        </div>
    </div>
</div>

