<div class="container-center-horizontal">
    <div class="session-history screen">
        <app-student-profile-popup id ='editProfile'class ='hidden' (closeMeEmitter) = 'closePopUp()' (updateAlienColorEmitter) = 'updateAlienColor($event)' (updatePageBackgroundEmitter) = 'updatePageBackground($event)' (updatePageBackgroundColor) = 'updatePageColor($event)' [profilePicURL] = 'profilePicURLEmitter | async'>
        </app-student-profile-popup>
      <div class="overlap-group14">   
        <div class="rectangle-83-s">
        </div>
        <div id= 'panel1' class="rectangle-83-1">
        </div>
        <!-- <img
          class="rectangle-83"
         
          src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/rectangle-83@1x.png"
        /> -->
        <img
          class="rectangle-82"
          id = 'pageBackground'
          src="../../../assets/pageImage6B.svg"
        />
        <img
          class="ellipse-18"
          id = 'proPic'
          src="../../../assets/ceramic.jpeg"
        />
        <div class="frame-374">
          <h1 class="name worksans-semi-bold-abbey-24px">{{studentName}}</h1>
          <p class="favourite-classes-m tab-bold">
            <span class="span0 tab-bold">Favourite classes:</span><span class="span1 body"> Math, Ecology, Geology</span>
          </p>
        </div>
        <div class="group-433">
          <div class="flex-row">
            <img
              class="chevron-right"
             
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/chevron-right-1@2x.png"
            />
            <div class="group-435">
              <img id = 'emptyXP1' src = '../../../assets/emptyXP.svg' style="padding-right: 5px;padding-left: 5px;">
              <img id = 'emptyXP2' src = '../../../assets/emptyXP.svg' style="padding-right: 5px;padding-left: 5px;">
              <img id = 'emptyXP3' src = '../../../assets/emptyXP.svg' style="padding-right: 5px;padding-left: 5px;">
              <app-progress-tracker *ngFor = "let trackerInfo of trackerEmitter | async"  id = {{trackerInfo[0]}} [AUXIN] = "trackerInfo"></app-progress-tracker>
            </div>
            <img
              class="chevron-right-1"
             
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/chevron-right@2x.png"
            />
          </div>
          <div class="frame-383" (click)='openPopUp()'>
            <div class="group-252">
              <div class="all-trophies worksans-semi-bold-chathams-blue-14px">My Student Card</div>
              <img
                class="line-3"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/line-3@2x.png"
              />
            </div>
          </div>
        </div>
        <div class="overlap-group12">
          <div class="rectangle-84-s"></div>
          <div class="rectangle-84-1" id = 'panel3'></div>
          <div class="rocket-diagram">
            <div class="overlap-group">
              <div class="number worksans-semi-bold-gunsmoke-18px">5</div>
              <div class="astronaut inter-medium-gunsmoke-12px">Astronaut</div>
            </div>
            <div class="overlap-group-1">
              <div class="number worksans-semi-bold-gunsmoke-18px">4</div>
              <div class="place inter-medium-gunsmoke-12px">Ranger</div>
            </div>
            <div class="overlap-group7">
              <div class="number worksans-semi-bold-gunsmoke-18px">3</div>
              <div class="name inter-medium-gunsmoke-12px">Spacewalker</div>
            </div>
            <div class="overlap-group">
              <div class="number worksans-semi-bold-gunsmoke-18px">2</div>
              <div class="explorer inter-medium-gunsmoke-12px">Explorer</div>
            </div>
            <div class="overlap-group">
              <div class="number worksans-semi-bold-gunsmoke-18px">1</div>
              <div class="place-1 inter-medium-gunsmoke-12px">Cadet</div>
            </div>
          </div>
          <div class="overlap-group-6">
            <img
              class="rectangle-116"
             
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/rectangle-116@1x.png"
            />
            <div style="position: absolute;top:12px;"id = 'rocketStick'>
              <div class="rectangle-131"></div>
              <div class="ellipse-84"></div>
            </div>
            <div class="group-427">
              <img
                class="line-16"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/line-16@2x.png"
              />
              <img
                class="line-17"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/line-16@2x.png"
              />
              <img
                class="line-18"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/line-16@2x.png"
              />
            </div>
            <div id = 'rocket'class="overlap-group1-4">
              <img
                class="rectangle-125"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/rectangle-125@2x.png"
              />
              <img
                class="rectangle-124"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/rectangle-124@2x.png"
              />
              <img
                class="vector-44"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/vector-44@2x.png"
              />
              <img
                class="vector-45"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/vector-45@2x.png"
              />
              <img
                class="vector-46"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/vector-46@2x.png"
              />
              <div class="group-387">
                <div class="ellipse-58"></div>
              </div>
              <div class="group-388">
                <div class="ellipse-58"></div>
              </div>
              <div class="group-389">
                <div class="ellipse-58"></div>
              </div>
              <img
                class="vector-47"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/vector-47@2x.png"
              />
              <img
                class="vector-48"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/vector-48@2x.png"
              />
            </div>
          </div>
        </div>
        <div class="rectangle-146"></div>
        <div class="rectangle-146-1">
          <h1 class="title">My Trophies</h1>
          <div id = 'ach1'>
            <img src="../../../assets/welcome.svg" class="rectangle-121">
            <div class="rectangle-121-1"></div>
            <div class="rectangle-121-2"></div>
          </div>
          <div id = 'ach2' style="display: none;">
            <img src="../../../assets/firstWorkSpace.svg" class="rectangle-121">
            <img src="../../../assets/inviteFriend.svg"class="rectangle-121-1">
            <img src="../../../assets/firstCourse.svg"class="rectangle-121-2">
          </div>

          <div class="frame-383-1">
            <div class="all-trophies-1 worksans-semi-bold-chathams-blue-14px">
              Student Card
            </div>
            <img class="line-3-1" src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/line-3@2x.png" />
          </div>
          
        </div>
        <div class="group-376-1">
          <img
          class="star-7-4"
          id ='alienNeck'
          style = 'margin-left: 33px;margin-top: 49px;'
          src="../../../assets/purpleAlienNeck.svg"
        />
          <img
          id = 'alienLevel'
          style = 'height: 140px;width: 138px;margin-top: -26px;margin-left: -112px;' src="../../../assets/SolarLevel1.svg"
          />
        <img
        id ='alienHead'
        style = 'margin-top: 10px;margin-left: -91px;height: 53px;width: 53px;'
        src="../../../assets/purpleAlienHead.svg"
      />
        </div>
        
        <div class="progress" style = 'position: absolute;top:  268px;left: 217px;width: 151px; height: 6px;'>
          <div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width:10%;background-color: #2A8499!important;border-radius: 5px;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div style = 'position: absolute;top:  281px;left: 217px'>
          <span style = 'display: inline-block;padding-right: 79px;'>
            <p class = 'worksans-light-grey'>10%</p>
          </span> 
          <span style="display: inline-block;">
            <p class = 'worksans-light-grey'>10/100</p>
          </span>
        </div>
        <div class="frame-269" style="z-index: 1;cursor: pointer;" (click)='openPopUp()'>
          <img
            class="edit"
           
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/edit@2x.png"
          />
          <div class="edit-profile worksans-semi-bold-chathams-blue-14px">Edit My Student Card</div>
        </div>
        <div class="overlap-group13-s">
        </div>
        <div class="overlap-group13" id = 'panel2'>
          <img
            class="rectangle-37"
           
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2123088d11a3290aff3dc/img/rectangle-37@1x.png"
          />
          <div class="assigned-activities tab-bold">My Appointments</div>
          <!-- <div class="session-history-1 body">My Classrooms</div>
          <div class="session-history-2 body">My Projects</div> -->
          <!-- group-332 -->
          <div class="hidden">   
            <img
              class="chevron-right-2"
             
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/chevron-right-1@2x.png"
            />
            <div class="address-1 inter-normal-white-16px">
              <span class="inter-normal-gunsmoke-16px">1</span><span class="inter-normal-chathams-blue-16px">&nbsp;</span
              ><span class="span2 inter-normal-chathams-blue-16px">2</span
              ><span class="inter-normal-gunsmoke-16px"> 3</span>
            </div>
            <img
              class="chevron-right-3"
             
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/chevron-right@2x.png"
            />
          </div>
        </div>
        <div id = 'noSessions' style="position: absolute;
        left: 498px;
        top: 499px;
        display: none;">
          <img src = '../../../assets/noSessions.svg'>
        </div>
        
        <div id = 'sessions' style="top: 17px;position: absolute;">
          <div class="frame-376-1 border-1px-blue" style = 'z-index:1;cursor:pointer;' *ngFor = "let session of sessionsEmitter "  id = {{session.date}}  routerLink="/VerifiedVideo/{{session.cRef}}">
            <div class="group-379">
              <img
                class="rectangle-118"
               
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/rectangle-118@2x.png"
              />
              <div class="frame-376">
                <div class="x241121-english-lesson worksans-semi-bold-chathams-blue-14px">
                  Meeting with {{session.SN}} & {{session.TN}}
                </div>
                <div class="with-mary-stevenson worksans-normal-gunsmoke-14px">{{session.date}}</div>
              </div>
            </div>
            <div class="frame-267">
              <img
                class="download"
               
                src="../../../assets/vector-28@2x.svg"
              />
              <div class="export-files worksans-semi-bold-chathams-blue-14px">Enter Lesson</div>
            </div>
          </div>

          <div id = 'sessionsHidden' style="display: none;">
            <div class="frame-376-1 border-1px-blue">
              <div class="group-379">
                <img
                  class="rectangle-118"
                
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/rectangle-118@2x.png"
                />
                <div class="frame-376">
                  <div class="x241121-english-lesson worksans-semi-bold-chathams-blue-14px">
                    03/02/22 English lesson
                  </div>
                  <div class="with-mary-stevenson worksans-normal-gunsmoke-14px">Made By Mary Steve</div>
                </div>
              </div>
              <div class="frame-267">
                <img
                  class="download"
                
                  src="../../../assets/vector-28@2x.svg"
                />
                <div class="export-files worksans-semi-bold-chathams-blue-14px">Open Lesson</div>
              </div>
            </div>
            <div class="frame-377 border-1px-blue">
              <div class="group-379">
                <img
                  class="rectangle-118"
                
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/rectangle-118@2x.png"
                />
                <div class="frame-376">
                  <div class="x241121-english-lesson-1 worksans-semi-bold-chathams-blue-14px">
                    02/28/22 Math lesson
                  </div>
                  <div class="with-mary-stevenson-1 worksans-normal-gunsmoke-14px">
                    Made By Janet Page
                  </div>
                </div>
              </div>
              <div class="frame-267">
                <img
                  class="download"
                
                  src="../../../assets/vector-28@2x.svg"
                />
                <div class="export-files worksans-semi-bold-chathams-blue-14px">
                  Open Lesson
                </div>
              </div>
            </div> 
            <div class="frame-378 border-1px-blue">
              <div class="group-379">
                <img
                  class="rectangle-118"
                
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/rectangle-118@2x.png"
                />
                <div class="frame-376">
                  <div class="x241121-english-lesson-2 worksans-semi-bold-chathams-blue-14px">
                    02/25/22 Science lesson
                  </div>
                  <div class="with-mary-stevenson-2 worksans-normal-gunsmoke-14px">
                    Made By Frank Lutz
                  </div>
                </div>
              </div>
              <div class="frame-267">
                <img
                  class="download"
                
                  src="../../../assets/vector-28@2x.svg"
                />
                <div class="export-files worksans-semi-bold-chathams-blue-14px">
                  Open Lesson
                </div>
              </div>
            </div>
            <div class="frame-379 border-1px-blue">
              <div class="group-379">
                <img
                  class="rectangle-118"
                
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/rectangle-118@2x.png"
                />
                <div class="frame-376">
                  <div class="x241121-english-lesson-3 worksans-semi-bold-chathams-blue-14px">
                    02/22/22 Math lesson
                  </div>
                  <div class="with-mary-stevenson-3 worksans-normal-gunsmoke-14px">
                    Made By Mark Hughes
                  </div>
                </div>
              </div>
              <div class="frame-267">
                <img
                  class="download"
                
                  src="../../../assets/vector-28@2x.svg"
                />
                <div class="export-files worksans-semi-bold-chathams-blue-14px">
                  Open Lesson
                </div>
              </div>
            </div>
            <div class="frame-380 border-1px-blue">
              <div class="group-379">
                <img
                  class="rectangle-118"
                
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/rectangle-118@2x.png"
                />
                <div class="frame-376">
                  <div class="x241121-english-lesson-4 worksans-semi-bold-chathams-blue-14px">
                    02/19/22 English lesson
                  </div>
                  <div class="with-mary-stevenson-4 worksans-normal-gunsmoke-14px">
                    Made By Frank Evans
                  </div>
                </div>
              </div>
              <div class="frame-267">
                <img
                  class="download"
                
                  src="../../../assets/vector-28@2x.svg"
                />
                <div class="export-files worksans-semi-bold-chathams-blue-14px">
                  Open Lesson
                </div>
              </div>
            </div>
            <div class="frame-381 border-1px-blue">
              <div class="group-379">
                <img
                  class="rectangle-118"
                
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61c2128126aedea5b1847e50/img/rectangle-118@2x.png"
                />
                <div class="frame-376">
                  <div class="x241121-english-lesson-5 worksans-semi-bold-chathams-blue-14px">
                    02/16/22 Science lesson
                  </div>
                  <div class="with-mary-stevenson-5 worksans-normal-gunsmoke-14px">
                    Made By Bruce James
                  </div>
                </div>
              </div>
              <div class="frame-267">
                <img
                  class="download"
                
                  src="../../../assets/vector-28@2x.svg"
                />
                <div class="export-files worksans-semi-bold-chathams-blue-14px">
                  Open Lesson
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


