import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.scss']
})
export class ProgressTrackerComponent implements OnInit {
  @Input() AUXIN: any[];
  topic
  percent 
  level 
  slot
  constructor() { }

  // ngOnChanges(changes: any){
        
  //   console.log(changes.categoryId.currentValue);
  //   // You can also use categoryId.previousValue and 
  //   // categoryId.firstChange for comparing old and new values
    
  // } 

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log(changes);
  //   console.log(changes.categoryId.currentValue);
  // }
  
  ngOnInit(): void {
    this.topic = this.AUXIN[0]  
    this.percent = this.AUXIN[1]  
    this.level = this.AUXIN[2]    
    this.slot = this.AUXIN[3] 
    var style = document.createElement('style');
    // style..type = 'text/css';
    var keyFrame = '@keyframes loading-3 {\
    0% {\
        -webkit-transform: rotate(RIGHT_START);\
        transform: rotate(RIGHT_START)\
    }\
    100% {\
        -webkit-transform: rotate(RIGHT_END);\
        transform: rotate(RIGHT_END)\
    }\
}\
@keyframes loading-4 {\
  0% {\
      -webkit-transform: rotate(LEFT_START);\
      transform: rotate(LEFT_START)\
  }\
  100% {\
      -webkit-transform: rotate(LEFT_END);\
      transform: rotate(LEFT_END)\
  }';
    var rightStart = `${0}` + 'deg'
    var rightEnd = `${0}` + 'deg'
    var leftStart = `${0}` + 'deg'
    var leftEnd = `${0}` + 'deg'
    if(Number(this.percent) > 50){
      rightEnd = `${180}` + 'deg'
      var radians = Math.round(((this.percent-50)/50)*180)
      leftEnd = `${radians}` + 'deg'
    }else{
      var radians = Math.round((this.percent/50)*180)
      rightEnd = `${radians}` + 'deg'
    }
    console.log(rightStart,rightEnd,leftStart,leftEnd)
    keyFrame = keyFrame.replace(/RIGHT_START/g, rightStart);
    keyFrame = keyFrame.replace(/RIGHT_END/g, rightEnd);
    keyFrame = keyFrame.replace(/LEFT_START/g, leftStart);
    keyFrame = keyFrame.replace(/LEFT_END/g, leftEnd);
    keyFrame = keyFrame.replace(/SLOT/g, this.slot);

    style.innerHTML = keyFrame
    document.getElementsByTagName('head')[0].appendChild(style);
    setTimeout(()=>{
      console.log(document.getElementById('progress progress-right progress-bar'))
    },0)
    console.log(this.AUXIN)
  }

}
