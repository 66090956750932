import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
@Component({
  selector: 'app-org-landing-page',
  templateUrl: './org-landing-page.component.html',
  styleUrls: ['./org-landing-page.component.scss']
})
export class OrgLandingPageComponent implements OnInit {
  emailNL = ''
  positionNL = ''
  nameNL = ''
  constructor(private rerouter: Router,private db: AngularFireDatabase) { }

  ngOnInit(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0
  }

  signUp(){
    if(this.emailNL !== '' && this.positionNL !== '' && this.nameNL !== ''){
      this.db.database.ref('OrgSignUp/').push({'name':this.nameNL,'title':this.positionNL,'email':this.emailNL}).then(()=>{
        this.rerouter.navigateByUrl('/')
      })
    }
  }

}
