import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import {AuthService} from '../../../services/auth.service'
import {AngularFireDatabase} from '@angular/fire/database'
import {AngularFireStorage} from '@angular/fire/storage'
import {ActivatedRoute, Router} from '@angular/router'
import {AngularFireFunctions} from '@angular/fire/functions'

@Component({ 
  selector: 'app-teacher-profile',
  templateUrl: './teacher-profile.component.html',
  styleUrls: ['./teacher-profile.component.scss']
})
export class TeacherProfileComponent implements OnInit {
  @Output() teacherInfo = new EventEmitter<any[]>();
  @Output() selectedSubjects = new EventEmitter<any>();
  selectedSubjectsVal = ''
  @Output() selectedAges = new EventEmitter<any>();
  selectedAgesVal = ''
  
  currentSchedule = ''
  path;    
  uploaded = false
  notSent = true
  logging = false   
  currentUID:string = "";
  signInDidNotWork:boolean;
  suggestions: string= "";
  services: string[] = [];
  grades: string[] = [];
  days: string[] = [];
  shortDesc: string= "";
  longDesc: string= "";
  fun: string= "";
  emailP: string= "";
  password:string= "";
  lastName:string= "";
  emailS:string= "";
  license:string= "";
  falseTry:boolean;
  accept = false
  ageRangeDropDownOpen = false
  searchDropDownOpen = false

  isLoading = false
  

  constructor(private router:Router,private router2:ActivatedRoute,private auth: AuthService, private db: AngularFireDatabase, private afm: AngularFireStorage,private fns:AngularFireFunctions) {  }
  @Output() name = new EventEmitter<any>();
  ngOnInit(): void {
    
    if(this.router2.snapshot.params.NAME){
      console.log(this.router2.snapshot.params.NAME)
      this.fun = this.router2.snapshot.params.NAME
      setTimeout((()=>{
        this.name.emit(this.router2.snapshot.params.NAME)
      }),500)
    }
    setTimeout((()=>{
      this.selectedSubjects.emit('Click Me')
      this.selectedAges.emit('Click Me')
    }),0)
  }
  storeNgModel(msg:NgModel){
    console.log(msg.value);
  }

  log2(){
    
  }
  //name, school, uid, emailP, emailS
  async storeInfo(){
    this.isLoading = true
    if(this.formComplete()){
      console.log('success')
      this.notSent = false
      this.logging = true
      const newTeacherEmail = this.fns.httpsCallable('newTeacherEmail')
      await newTeacherEmail({
        name: this.fun,
        school:this.lastName,
        uid:'',
        emailP:this.emailS,
        emailS:Intl.DateTimeFormat().resolvedOptions().timeZone
      }).toPromise().then(()=> console.log('success')).catch((e)=> this.isLoading = false).finally(()=>this.logging = false)
      await this.storePublicInfo()
    }else{
      this.isLoading = false
      this.falseTry = true 
      this.notSent = true
      this.logging = false
    }
    
    
  }

  adjustSwitch(){
    this.accept = !this.accept
  }

  // make sure all vars that are gonna be stored have values
  formComplete():boolean{
    console.log(this.longDesc,this.fun,this.emailP,this.path,this.password,this.lastName,this.emailS)
    console.log(this.path != null,this.path !== "",this.longDesc !== "")
    if(
      this.path != null
      && this.path !== ""
       &&
      // this.services.length > 0 &&
      // this.grades.length > 0 &&
      // this.days.length > 0 &&
      // this.shortDesc !== ""&&
      this.longDesc !== ""&&
      this.fun !== ""&&
      this.emailP !== ""&&
      this.password !== ""&&
      this.lastName !== ""&&
      this.emailS !== ""
      // this.license!== ""&&
      // this.accept === true
      ){  
        return true

    }else{
      return false;
    }
  }

  upload($event){   
    this.path = $event.target.files[0]
    this.uploaded = true
    document.getElementById('proPic').setAttribute('src',URL.createObjectURL(this.path));
  }


  async storePublicInfo(){
    console.log(1)
    this.auth.storeEmail(this.emailP)
    this.auth.storePassword(this.password)
    this.auth.emailSignUp().then(val =>{
      console.log(2)
      if(val !== null){
        console.log(3)
        // var holdServices = "|"
        // this.services.forEach(val =>{holdServices += val + "|"})
        // var holdGrades = "|" 
        // this.grades.forEach(val =>{holdGrades += val + "|"})
        // var holdDays = "|"
        // this.days.forEach(val =>{holdDays += val + "|"})
        // this.storeInfo()
        //******* */
        this.afm.upload("/applied/" + val, this.path)
        var providers = this.db.database.ref('waitingToBeChecked')
        console.log(val)
        console.log(providers)
        providers.push(
          {
            val:{ 
              // service: holdServices,
              // grades: holdGrades,
              // days: holdDays,
              // shortDesc:this.shortDesc,
              //********* */
              longDesc: this.longDesc,
              fn: this.fun,
              school: this.lastName,
              emailP:this.emailP,
              emailS: this.emailS,
              timeZone: new Date().getTimezoneOffset()/60,
              location: Intl.DateTimeFormat().resolvedOptions().timeZone,
              schdl:this.currentSchedule,
              grades:this.selectedAgesVal,
              subs:this.selectedSubjectsVal,
              // license:this.license,
              uid:val
            }
            
          }
        ).then(()=>{
          this.router.navigateByUrl('/welcome')
        })
      }
    }).catch((e)=>{
      this.signInDidNotWork = true
    })
  }

  uploadPersonal(){
    document.getElementById('inputGroupFile01').click()
  }
 
  storeGrade(gradeLevel:string){
    if(this.gradeOn(gradeLevel)){
      this.grades.push(gradeLevel)
    }else{
      this.grades = this.grades.filter(val =>val !== gradeLevel)
    }
  }

  gradeOn(gradeLevel:string):boolean{
    var hold = true
    this.grades.forEach(grade=>{
      if(grade === gradeLevel){
        hold =  false
      }
    })
    return hold;
  }
  storeDay(day:string){
    if(this.dayOn(day)){
      this.days.push(day)
    }else{
      this.days = this.days.filter(val =>val !== day)
    }
  }

  dayOn(day:string):boolean{
    var hold = true
    this.days.forEach(serv=>{
      if(serv === day){
        hold =  false
      }
    })
    return hold;
  }

  storeService(serviceTitle:string){
    if(this.serviceOn(serviceTitle)){
      this.services.push(serviceTitle)
    }else{
      this.services = this.services.filter(val => val !== serviceTitle)
    }
  }

  serviceOn(service:string):boolean{
    var hold = true
    this.services.forEach(serv=>{
      if(serv === service){
        hold = false;
      }
    })
    return hold;
  }
  
  toggleageRangeDropDown(){
    this.ageRangeDropDownOpen = !this.ageRangeDropDownOpen
    if(this.searchDropDownOpen){
      this.toggleSearchDropDown()
    }
    if(this.ageRangeDropDownOpen){
      document.getElementById('ageRangeDropDown').setAttribute('class','overlap-group2-3')
    }else{
      document.getElementById('ageRangeDropDown').setAttribute('class','hide')
    }
    
  }

  toggleSearchDropDown(){
    this.searchDropDownOpen = !this.searchDropDownOpen
    if(this.ageRangeDropDownOpen){
      this.toggleageRangeDropDown()
    }
    if(this.searchDropDownOpen){
      document.getElementById('searchDropDown').setAttribute('class','overlap-group2-2')
    }else{
      document.getElementById('searchDropDown').setAttribute('class','hide')
    }
  }

  updateSearch(keyValue){
    if(this.selectedSubjectsVal.split(',').includes(keyValue)){
      //filter it out
      var hld = ''
      var holdList = this.selectedSubjectsVal.split(',').filter(val=>val != keyValue)
      if(holdList.length === 0){
        this.selectedSubjects.emit('Choose Subjects...')
        this.selectedSubjectsVal = ''
      }else{
        holdList.forEach(v=>{
          if(hld === ''){
            hld = v
          }else{
            hld += ', ' + v
          }
        })
        this.selectedSubjects.emit(hld)
        this.selectedSubjectsVal = hld
      }
    }else{
      //add it
      if(this.selectedSubjectsVal === ''){
        this.selectedSubjectsVal = keyValue
      }else{
        this.selectedSubjectsVal += ', ' + keyValue
      }
      this.selectedSubjects.emit(this.selectedSubjectsVal)
    }
  }
  updateAgeRange(keyValue){
    if(this.selectedAgesVal.split(', ').includes(keyValue)){
      //filter it out
      var hld = ''
      var holdList = this.selectedAgesVal.split(', ').filter(val=>val != keyValue)
      if(holdList.length === 0){
        this.selectedAges.emit('Choose Ages...')
        this.selectedAgesVal = ''
      }else{
        holdList.forEach(v=>{
          if(hld === ''){
            hld = v
          }else{
            hld += ', ' + v
          }
        })
        this.selectedAges.emit(this.formatAgeRange(hld))
        this.selectedAgesVal = hld
      }
    }else{
      //add it
      if(this.selectedAgesVal === ''){
        this.selectedAgesVal = keyValue
      }else{
        this.selectedAgesVal += ', ' + keyValue
      }
      this.selectedAges.emit(this.formatAgeRange(this.selectedAgesVal))
    }
  }

  updateCurrentSchedule($event){
    var returnString = ''
    for(const v in $event){
      if($event[v] !== ''){
        if(returnString === ''){
          returnString = v + ':'+ $event[v]
        }else{
          returnString += ', ' + v + ':'+ $event[v]
        }
      }
    }
    console.log(returnString)
    this.currentSchedule = returnString
  }

  formatAgeRange(val){
    var returnVal = ''
    val.split(',').forEach(v=>{
      console.log(v.trim())
      v = v.trim()
      if(v === '1_2'){
        if(returnVal === ''){
          returnVal = 'Lower Elementary'
        }else{
          returnVal += ', Lower Elementary'
        }
      }else if(v === '3_5'){
        if(returnVal === ''){
          returnVal = 'Upper Elementary'
        }else{
          returnVal += ', Upper Elementary'
        }
      }else if(v === '6_8'){
        if(returnVal === ''){
          returnVal = 'Middle School'
        }else{
          returnVal += ', Middle School'
        }
      }else if(v === '9_12'){
        if(returnVal === ''){
          returnVal = 'High School'
        }else{
          returnVal += ', High School'
        }
      }else{
        if(returnVal === ''){
          returnVal = v
        }else{
          returnVal += ', ' + v
        }
      }
    })
    return returnVal
  }
}
  