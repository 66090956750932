import { Component, OnInit, Input } from '@angular/core';
import { comment } from '../../models/comment'
@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent implements OnInit {
  @Input() comment: comment;
  constructor() { }

  ngOnInit(): void {
    var hold = this.comment.stars
    var count = 1
    while(count <= hold){
      var inpa = "star" + String(count)
      console.log(inpa)
      document.getElementById(inpa).outerHTML = '<span class="fa fa-star checked" style = "color:orange;"></span>';
      count += 1
    }
  }



}
