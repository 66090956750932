import { deepStrictEqual } from 'assert'
import { relative } from 'path'

export class queries{
    stream: string[] = ["","",""]

    constructor(value,type){
        this.stream[type] = value
    } 

    relatedService(filter:string): string{

        return 
    }
    
    updateStream(val:string,index:number){
        this.stream[index] = val
    }

    simUpdateStream(val:string,i:number):string[]{
        var hold:string[] =[]
        var index = 0
        while(index < 3){
            if(index !== i){
                hold[index] = this.stream[index]
            }
            index +=1
        }
        hold[i] = val
        return hold
    }

    getStream():string[]{
        return this.stream
    }

    toString():string{
        var querie = ""
        if(this.stream[0] !== ""){
            querie += this.stream[0] + "/"
        } if(this.stream[1] !== ""){
            querie += this.stream[1]+ "/"
        } if (this.stream[2] !== ""){
            querie += this.stream[2]
        }
        return querie
    }
}