export class req {
    dateTime: string;
    services:string;
    grade:string;
    teacherEmail:string;
    parentEmail:string;
    teacherUID:string;
    parentUID:string; 
    agreed:string;   
    UID:string
    teacherRate;
    teacherTimeZoneoffset 
    ttz
    numSessions
    offeredRate
    finalRate
    constructor(dateTime,services,grade,teacherEmail,parentEmail, teacherUID, parentUID,agreed,UID,teacherRate,teacherTimeZoneoffset,ttz,numSessions,offeredRate,finalRate){
        this.dateTime = dateTime
        this.services = services
        this.grade = grade
        this.parentEmail = parentEmail
        this.teacherEmail = teacherEmail
        this.teacherUID = teacherUID
        this.parentUID = parentUID
        this.agreed = agreed
        this.UID = UID 
        this.teacherRate = teacherRate
        this.teacherTimeZoneoffset = teacherTimeZoneoffset 
        this.ttz = ttz
        this.numSessions = numSessions
        this.offeredRate = offeredRate
        this.finalRate = finalRate
    }
  }