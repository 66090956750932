<span>
    <img
    id = 'avatar'
    class="rectangle-47"
    data-id="1598:7264"
    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/61899ac6d7c61efd7662cf0f/img/rectangle-47@2x.png"
  />
    <div style="display: inline-block;
    margin-left: -49px;
    margin-right: 15px;
    width: -3px;
    vertical-align: top;
    padding-top: 8px;">
        <!-- <div id = 'videoOn' class="group-66-R3ERnh" style="display: none;" (click) = turnOnVideo()>
          <div class="x1507410 component component-wrapper not-ready">
            <div class="rectangle-45-N600Tf"></div>
            <div class="ellipse-5-N600Tf"></div>
            <div class="edit-2-N600Tf"><img class="vector-ZuO5ep" src="../../../assets/vector-9@2x.svg" /></div>
          </div>
        </div> -->
        <div  *ngIf = 'audio' id = 'microOff' class="group-66-R3ERnh-2" style="z-index: 1;cursor: pointer;"(click) = turnOffAudio()>
            <div class="x1507410-2 component component-wrapper not-ready">
              <div id = 'audioOnButtonP' class="roundButton"></div>
              <div class="edit-2-N600Tf-2"><img class="vector-ZuO5ep-2" src="../../../assets/mic.svg" /></div>
            </div>
          </div>
          <div *ngIf = '!audio' id = 'microOn' class="group-66-R3ERnh-2" style="z-index: 1;cursor: pointer;"(click) = turnOnAudio()>
            <div class="x1507410-2 component component-wrapper not-ready">
              <div id = 'audioOffButtonP' class="roundButton"></div>
              <div class="edit-2-N600Tf-2"><img class="vector-ZuO5ep-2" src="../../../assets/mic-off.svg" /></div>
            </div>
          </div>
          <div *ngIf = 'video' id = 'camOff' class="group-66-R3ERnh-2" style="z-index: 1;cursor: pointer;" (click) = turnOffVideo()>
            <div class="x1507410-2 component component-wrapper not-ready">
              <div id = 'videoOnButtonP' class="roundButton"></div>
              <div class="edit-2-N600Tf-2"><img class="vector-ZuO5ep-2" src="../../../assets/video.svg" /></div>
            </div>
          </div>
          <div *ngIf = '!video' id = 'camOn' class="group-66-R3ERnh-2" style="z-index: 1;cursor: pointer;" (click) = turnOnVideo()>
            <div class="x1507410-2 component component-wrapper not-ready">
              <div id = 'videoOffButtonP'class="roundButton"></div>
              <div class="edit-2-N600Tf-2"><img class="vector-ZuO5ep-2" src="../../../assets/video-off.svg" /></div>
            </div>
          </div>
        <div *ngIf = '!lock' id = 'drawOff' class="group-66-R3ERnh" style="z-index: 1;cursor: pointer;" (click) = lockStudentsCanvas()>
          <div class="x1507410 component component-wrapper not-ready">
            <div class="rectangle-45-N600Tf"></div>
            <div class="edit-2-N600Tf"><img class="vector-ZuO5ep" src="../../../assets/vector-32@2x.svg" /></div>
          </div>
        </div>
        <div *ngIf = 'lock' id = 'drawOn' class="group-66-R3ERnh" style="z-index: 1;cursor: pointer;" (click) = unlockStudentsCanvas()>
          <div class="x1507410 component component-wrapper not-ready">
            <div class="rectangle-45-N600Tf"></div>
            <div class="edit-2-N600Tf"><img class="vector-ZuO5ep" src="../../../assets/vector-9@2x.svg" /></div>
          </div>
        </div>
        <!-- <div *ngIf = '!mute' id = 'micOff' class="group-66-R3ERnh" style="z-index: 1;cursor: pointer;" (click) = muteStudentsAudio()>
          <div class="x1507410 component component-wrapper not-ready">
            <div class="rectangle-45-N600Tf"></div>
            <div class="ellipse-5-N600Tf-1"></div>
            <div class="edit-2-N600Tf"><img class="vector-ZuO5ep" src="../../../assets/mic.svg" /></div>
          </div>
        </div>

        <div *ngIf = 'mute' id = 'micOn-1' class="group-66-R3ERnh" style="z-index: 1;cursor: pointer;" (click) = unmuteStudentsAudio()>
          <div class="x1507410 component component-wrapper not-ready">
            <div class="rectangle-45-N600Tf"></div>
            <div class="ellipse-5-N600Tf"></div>
            <div class="edit-2-N600Tf"><img class="vector-ZuO5ep" src="../../../assets/mic-grey.svg" /></div>
          </div>
        </div> -->
      </div>
</span>
