import { Component, EventEmitter, OnInit, Output } from '@angular/core'; 
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-post', 
  templateUrl: './post.component.html', 
  styleUrls: ['./post.component.scss'] 
})
export class PostComponent implements OnInit {  
  cat 
  AUID
  Title 
  Subtitle  
  article 
  readTime 
  date = ''
  dateTop = ''
  dateBottom = ''
  topics = []
  urll
  @Output() video = new EventEmitter<boolean>();
  @Output() topicsE = new EventEmitter<any[]>();
  text1 = ''
  text2 = ''
  text3 = ''
  text4 = ''

  constructor(private router:ActivatedRoute,private db: AngularFireDatabase,private afm: AngularFireStorage,private sanitizer: DomSanitizer) { }

  async ngOnInit(){   
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0
    this.video.emit(false)
    var cat = this.router.snapshot.params.AUID.split('-')[0]
    this.cat = cat
    var AUID = this.router.snapshot.params.AUID.split('-')[1]
    this.AUID = AUID
    console.log(this.AUID)
    this.date = new Date(Number(AUID)).toString()
    this.dateTop = this.date.split(' ')[1] + ' ' + this.date.split(' ')[2]
    this.dateBottom = this.corectDay(this.date.split(' ')[0])
    var videoCode = ''
    // this.date = this.date.split(' ')[0] + ' ' + this.date.split(' ')[1] + ' ' + this.date.split(' ')[2]
    await this.db.database.ref('blog/' + cat + '/' + AUID).once('value').then((snap)=>{
      this.Title = snap.child('title').val()
      this.Subtitle= snap.child('subTitle').val()
      this.article = snap.child('article').val().split('||')
      var articleLen = snap.child('article').val().split('||').length
      var counter = 1
      var wordCount = 0 
      var usedPs = 0 
      var firstBoxCount = 0 
      this.article.forEach((para:String)=>{
        usedPs += 1
        var splitter = []
        if(counter === 1){
          if(wordCount + para.split(' ').length < 125){
            splitter = [para]
            wordCount += para.split(' ').length   
          }else{
            var split1 = ''   
            var split2 = ''
            para.split(' ').forEach(word=>{
              console.log(wordCount)
              if(wordCount < 110){
                if(split1 === ''){
                  split1 += word
                }else{
                  split1 += ' ' + word
                }
              }else{
                if(split2 === ''){
                  split2 += word
                }else{
                  split2 += ' ' + word
                }
              }
              wordCount += 1
            })
            console.log(split1)
            console.log(split2)
            splitter = [split1,split2]
          }
        }else if(counter === 2){
          splitter = [para]
          wordCount + para.split(' ').length 
        }
        var counta = 0 
        splitter.forEach((para2:String)=>{
          if(counta === 1){
            counter += 1
          }
          counta += 1
          if(para2.includes('<<<')){
            ///<<<title;url>>> ** no space
            var elem = document.createElement("p")
            var count = 0
            para2.split('>>>').forEach(section=>{
              var link = document.createElement("a")
              if(section.includes('<<<')){
                console.log(section.split('<<<')[0])
                var text = document.createTextNode(section.split('<<<')[0])
                var urlTitle = section.split('<<<')[1].split(';')[0]
                var url = section.split('<<<')[1].split(';')[1]
                console.log(text,urlTitle,url)
                link.setAttribute('href',`${url}`)
                count += 1
                link.setAttribute('id',`${count}`)
                link.textContent = `${urlTitle}`
                elem.append(text)
                elem.append(link)
              }else{
                var text = document.createTextNode(section)
                elem.append(text)
              }
            })
          }else{
            var elem = document.createElement("p")
            elem.textContent = `${para2}`
          } 
          var breaker = document.createElement('br')
          document.getElementById('box' + `${counter}`).appendChild(elem)
          document.getElementById('box' + `${counter}`).appendChild(breaker)
        })
      })


      this.readTime = snap.child('readTime').val()
      videoCode = snap.child('youtube').val()
    }).then(()=>{
      if(videoCode !== 'empty'){
        console.log(videoCode)
        this.video.emit(true)
        this.urll = this.sanitizer.bypassSecurityTrustResourceUrl( 'https://www.youtube.com/embed/' + `${videoCode}`);
      }
      var hold:string = ''
      this.db.database.ref('blog/cats').child('val').once('value').then((snap)=>{
        hold = snap.val()
        this.topics = hold.split('|')
        this.topicsE.emit(this.topics)
      })
    })

    var articleHold = this.article
    // Create a reference from a Google Cloud Storage URI
    console.log(AUID)
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/ArticleImages/' + AUID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById(`${AUID}`).setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      document.getElementById(`${AUID}`).setAttribute('src','../../../assets/noPic.png');
      document.getElementById(`${AUID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }
  corectDay(day:string){
    if(day.toLowerCase() == 'mon'){
      return 'Monday'
    }else if(day.toLowerCase() == 'tue'){
      return 'Tuesday'
    }else if(day.toLowerCase() == 'wed'){
      return 'Wednesday'
    }else if(day.toLowerCase() == 'thu'){
      return 'Thursday'
    }else if(day.toLowerCase() == 'fri'){
      return 'Friday'
    }else if(day.toLowerCase() == 'sat'){
      return 'Saturday'
    }else if(day.toLowerCase() == 'sun'){
      return 'Sunday'
    }
  }

}
