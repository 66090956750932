import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';


@Component({  
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})   
export class VideoComponent implements OnInit {
  state = {}
  constructor(private fns:AngularFireFunctions) { }

  ngOnInit(): void {

  }

  componentDidMount() {
    const createVideoCall = this.fns.httpsCallable('createVideoCall')
    createVideoCall('')
    .toPromise().then(room => {
      console.log(`Successfully joined a Room: ${room}`);
      room.on('participantConnected', participant => {
        console.log(`A remote Participant connected: ${participant}`);
      });
    });}
    
    
  //   .then(results => {
  //     console.log("success")
  //     const { identity, token } = results.data;
  //     this.state[identity] = identity;
  //     this.state[token] = token;
  //   }).catch((e)=> console.log('err',e))
    
  // }
  // connect('$TOKEN', { name:'my-new-room' })
  
  // .then(room => {
  //   console.log(`Successfully joined a Room: ${room}`);
  //   room.on('participantConnected', participant => {
  //     console.log(`A remote Participant connected: ${participant}`);
  //   });
  // });

  // handleRoomNameChange(e) {
  //   /* Fetch room name from text field and update state */
  //       let roomName = e.target.value; 
  //       this.state[roomName] = roomName
  // }

  

}
    