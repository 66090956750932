import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent implements OnInit {
  name = ''
  contactEmail = ''     
  studentGrades = ''
  subjects = ''
  location = ''
  sent = false   

  constructor(public auth: AuthService,private fns:AngularFireFunctions) { }
        
  ngOnInit(): void {
   
  }

  signUpReq(){
    console.log(this.name,this.contactEmail,this.studentGrades,this.subjects,this.location)
    if(this.name !== '' && this.contactEmail !== '' && this.studentGrades !== '' && this.subjects !== '' && this.location !== ''){
      const sendMail = this.fns.httpsCallable('sendMail')
      var info = this.name + '||' + this.contactEmail + '||' + this.studentGrades + '||' + this.subjects + '||' + this.location + '||'
      sendMail({
        address: 'fred@verifiedteachers.com',
        subject:`${"Email Raffle Sign Up"}`,
        msg: 'Data: ' + `${info}`,
      }).toPromise().then(()=>{
        this.sent = true
      }).catch((e)=> console.log('err',e))
    }else{
      //please comp
    }
  }
}
