import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private afm: AngularFireStorage) { }

  async storageURL(UID,TS){
    return await this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/personalFiles/' + UID + '/' +  TS).getDownloadURL() 
  }
}
