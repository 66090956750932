export class student {
    UID:string;
    name:string;
    hobbies:string;
    favSub:string;
    toughestSub:string;
    funFact:string;
    parentNote:string;

 
    constructor(name,hobbies,favSub,toughestSub,funFact,parentNote,UID){
        this.name = name;
        this.hobbies = hobbies;
        this.favSub = favSub;
        this.toughestSub = toughestSub;
        this.funFact = funFact;
        this.parentNote = parentNote;
        this.UID = UID
    }
  }