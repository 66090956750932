export class storageFile {
    url = ''
    key = ''
    name = ''
    desc = ''
    topic = ''
    subtopic =''
    file:File
    constructor(url,key,name,desc,topic,subtopic,file=new File([],'')){
        this.url = url
        this.key = key
        this.name = name
        this.desc = desc
        this.topic = topic
        this.subtopic = subtopic
        this.file = file
    }
  }