<div class="container-center-horizontal noSelect">
  <div id = 'timeRes' style="display: none;">
    <app-time-reserve-popup style = 'position: fixed;
    left: 0;
    top: 0;
    width:100%' [config] = 'selectedTimeFrame | async' [rateInput] = 'hourlyRate | async' (close) = 'closeMe2()' (selectedTimes)="removeTime($event)" (recreateCalendar) = 'recreateCalendar()' (addedTimes)="addTime($event)"></app-time-reserve-popup>
  </div>
    <div class="booking-page-r screen">
      <div class="flex-row-2">
        <div class="flex-col-1">
          <div class="overlap-group1">
            <img
              class="ellipse-14"
              id = 'proPic'
              style="border-radius: 40px;"
              [src] = '' src = '../../../assets/ceramic.jpeg'
            />
            <div class="group-182">
              <div class="kyteja-davidson inter-semi-bold-abbey-16px">{{name}}</div>
              <div class="group-180">   
                <img 
                  class="star-2"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/star-2@2x.png"
                />
                <img
                  class="star-"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/star-2@2x.png"
                />
                <img
                  class="star-"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/star-2@2x.png"
                />
                <img
                  class="star-"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/star-2@2x.png"
                />
                <img
                  class="star-"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/star-2@2x.png"
                />
              </div>
            </div>
            <div class="group-183" (click) = 'openMe()'>
              <div class="share-2">
                <div class="overlap-group2-1">
                  <img
                    src="../../../assets/shareBubbles.svg"
                  />
                </div>
              </div>
              <div class="share-profile worksans-semi-bold-chathams-blue-14px">Share Profile</div>
            </div>
          </div>
                          <!-- <input id = 'name' maxLength = "25" minLength = "2" type="text" class = "text-7-1 inter-normal-steel-teal-14px"ngModel name="fn1" #fn1 ="ngModel" (change) = "shareEmail = fn1.value" placeholder="Recipients Email"> -->
          <div id = 'shareToggle' class = 'hidden' > 
            <img
            class="x"
            (click) = 'closeMe()'
            data-id="1535:6765"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png"
          />
            <share-buttons [theme]="'circles-dark'"
            [include]="['facebook','twitter','linkedin','pinterest','reddit','tumblr','mix','viber','vk','telegram','messenger','whatsapp','xing','line','sms','email','print','copy']"
            [exclude]="['viber','messenger']"
            [show]="18"
            [url]="linkToPage"
            [title]="'Check Out This Awesome Teacher'"
            [description]="description"
            [autoSetMeta]="false"
            style="outline: none !important;"
          ></share-buttons>
          </div>
          <div id = 'dropship' class="overlap-group1-1">
            <!-- put an ngfor here -->
              <div style="z-index: 1;cursor: pointer;width: 100%" *ngIf = 'input1'>
                <div *ngFor = "let services of listings">   
                  <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:100%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="{{services}}" id = '{{services}}'(click)="setServices(services)"/>
                </div>
              </div>
              <div style="z-index: 1;cursor: pointer;width: 100%;" *ngIf = 'input2'>
                <div *ngFor = "let sesh of sessionList">   
                  <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:100%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="{{sesh}}" (click)="setSessionNum(sesh)"/>
                </div>     
              </div>
              <div style="z-index: 1;cursor: pointer;width: 100%;" *ngIf = 'input3'>
                <input type = "number" class="inter-semi-bold-chathams-blue-14px" style = 'width:80%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value="Hourl Rate" ngModel name="func44" #func44 ="ngModel" (change) = "reqRate(func44.value)"min="1"/>$/hr 
              </div>
              <div style="z-index: 1;cursor: pointer;width: 100%;" *ngIf = 'input4'>
                <div *ngFor = "let day of validDays1">
                  <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:100%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value='{{dateFormat(day)}}' (click)="setRegDate(day)"/>  
                </div>  
              </div>
              <div style="z-index: 1;cursor: pointer;width: 100%;" *ngIf = 'input5'>
                <div *ngFor = "let day of listNum1" style="width:100%;">
                  <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:100%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value='{{formatTime(day)}} ' (click)="setStartTime(day)"/>  
                </div> 
              </div>
              <div style="z-index: 1;cursor: pointer;width: 100%;" *ngIf = 'input6'>
                <div *ngFor = "let day of listNum2">
                  <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:100%;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' value='{{formatTime(day)}} ' (click)="setEndTime(day)"/>      
              </div>
              </div>
            </div>
            <!-- <div id="menu">
              <span id="menu-navi">
                <button type="button" class="btn btn-default btn-sm move-today" data-action="move-today">Today</button>
                <button type="button" class="btn btn-default btn-sm move-day" data-action="move-prev">
                  <i class="calendar-icon ic-arrow-line-left" data-action="move-prev"></i>
                </button>
                <button type="button" class="btn btn-default btn-sm move-day" data-action="move-next">
                  <i class="calendar-icon ic-arrow-line-right" data-action="move-next"></i>
                </button>
              </span>
              <span id="renderRange" class="render-range"></span>
            </div> -->   
        <!-- <div id="menu" style="    background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 3px 3px 20px #dfdfdf;
        margin-top: 12px;
        width: 754px;">
            <span class="dropdown">
                <button id="dropdownMenu-calendarType" class="btn btn-default btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"(click)='openViewToggle()'>
                    <i id="calendarTypeIcon" class="calendar-icon ic_view_week" style="margin-right: 4px;"></i>
                    <span id="calendarTypeName" class = 'inter-semi-bold-chathams-blue-14px'>Change View</span>&nbsp;
                    <i class="calendar-icon tui-full-calendar-dropdown-arrow"></i>
                </button>
                <ul class="dropdown-menu" role="menu" aria-labelledby="dropdownMenu-calendarType" id ='listOfViews'>
                    <li role="presentation" style="padding:5px;"(click) = 'calendar.changeView("day", true);'>
                        <a class="dropdown-menu-title inter-semi-bold-chathams-blue-14px" role="menuitem" data-action="toggle-daily">
                            <i class="calendar-icon ic_view_day"></i>Day View
                        </a>
                    </li>
                    <li role="presentation"style="padding:5px;"(click) = 'calendar.changeView("week", true);'>
                        <a class="dropdown-menu-title inter-semi-bold-chathams-blue-14px" role="menuitem" data-action="toggle-weekly">
                            <i class="calendar-icon ic_view_week"></i>Week View
                        </a>
                    </li>
                    <li role="presentation"style="padding:5px;"(click) = 'calendar.changeView("month", true);'>
                        <a class="dropdown-menu-title inter-semi-bold-chathams-blue-14px" role="menuitem" data-action="toggle-monthly">
                            <i class="calendar-icon ic_view_month"></i>Month View
                        </a>
                    </li>
                </ul>
            </span>
            <span id="menu-navi">
                <button type="button" class="btn btn-default btn-sm move-today inter-semi-bold-chathams-blue-14px" data-action="move-today"(click) = 'calendar.today();'>Today</button>
                <button type="button" class="btn btn-default btn-sm move-day" data-action="move-prev"(click) = 'calendar.prev();'>
                  <img src = '../../../assets/chevron-left.svg'>
                </button>
                <button type="button" class="btn btn-default btn-sm move-day" data-action="move-next"(click) = 'calendar.next();'>
                    <img src = '../../../assets/chevron-right.svg'>
                </button>
            </span>
            <span id="renderRange" style ='margin-left: 10px;'class="inter-semi-bold-chathams-blue-14px">{{calendar.getDate().toDate().toString().split(' ')[1]}} {{calendar.getDate().toDate().toString().split(' ')[3]}}</span>
        </div> -->
          <!-- <div id="menu">
            <span id="menu-navi">
              <button type="button" class="btn btn-default btn-sm move-today" data-action="move-today">{{calendar.getDate().toDate().toString().split(' ')[1]}} {{calendar.getDate().toDate().toString().split(' ')[3]}}</button>
              <button type="button" class="btn btn-default btn-sm move-today" data-action="move-today"style = 'background-color: rgba(30, 86, 99, 1);color:white'(click) = 'calendar.today();'>Today</button>
              <button type="button" class="btn btn-default btn-sm move-day" data-action="move-prev"style = 'background-color: rgba(30, 86, 99, 1);color:white'(click) = 'calendar.prev();'>
                prev
              </button>   
              <button type="button" class="btn btn-default btn-sm move-day" data-action="move-next" style = 'background-color: rgba(30, 86, 99, 1);color:white'(click) = 'calendar.next();'>
                next
              </button>
              <button type="button" class="btn btn-default btn-sm move-day" data-action="move-next" style = 'background-color: rgba(30, 86, 99, 1);color:white'(click) = 'calendar.changeView("day", true);'>
                day
              </button>
              <button type="button" class="btn btn-default btn-sm move-day" data-action="move-next" style = 'background-color: rgba(30, 86, 99, 1);color:white'(click) = 'calendar.changeView("week", true);'>
                week
              </button>
              <button type="button" class="btn btn-default btn-sm move-day" data-action="move-next" style = 'background-color: rgba(30, 86, 99, 1);color:white'(click) = 'calendar.changeView("month", true);'>
                month
              </button>
            </span>
            <span id="renderRange" class="render-range"></span>
          </div> -->
          <div class="overlap-group" id = 'calendar' style="height: 648px;overflow: hidden;">
          </div>
        </div>
        <div class="group-224">
          <div class="overlap-group11">
            <div class="group-228">
              <div class="overlap-group2-3">
                <div class="flex-col">
                  <div class="flex-row-4">
                    <div class="personal-info inter-semi-bold-chathams-blue-14px" style="z-index: 1;cursor: pointer;">Personal Info</div>
                    <!-- <div class="policies inter-normal-gunsmoke-14px-2" style="z-index: 1;cursor: pointer;">Policies</div>
                    <div class="reviews inter-normal-gunsmoke-14px-2" style="z-index: 1;cursor: pointer;">Reviews</div> -->
                  </div>
                  
                  <div class="text-4 inter-semi-bold-mine-shaft-14px">
                    <span>Subjects: </span
                    ><span class="span inter-normal-mine-shaft-14px">{{listingString}}</span>
                  </div>
                  <p class="text-4-1 inter-semi-bold-mine-shaft-14px">
                    <span class="span inter-semi-bold-mine-shaft-14px">Credential: </span
                    ><span class="span inter-normal-mine-shaft-14px">{{cred}}</span>
                  </p>
                  <div class="text-4-1 inter-semi-bold-mine-shaft-14px">
                    <span class="span inter-semi-bold-mine-shaft-14px">Rate: </span
                    ><span class="span inter-normal-mine-shaft-14px">{{rate}}$/hour</span>
                  </div>
                  <p class="text-3 inter-normal-mine-shaft-14px">
                    {{longIntro}}
                   </p>
                   <div class="text-3 inter-normal-mine-shaft-14px" style="max-height: 318px;margin-top: -1px;">
                    <div class="flex-col-item inter-semi-bold-mine-shaft-14px">
                      <span class="span inter-semi-bold-mine-shaft-14px">The maximium days in advance I will book: </span>
                      <span class="span inter-normal-mine-shaft-14px">{{future2}} days ahead.</span>
                    </div>      
                    <div class="flex-col-item inter-semi-bold-mine-shaft-14px">
                      <span class="span inter-semi-bold-mine-shaft-14px">Grace Period to receive a Full Refund: </span>
                      <span class="span inter-normal-mine-shaft-14px">{{policy1}} hour after booking.</span>
                    </div>      
                    <div class="flex-col-item inter-semi-bold-mine-shaft-14px">
                      <span class="span inter-semi-bold-mine-shaft-14px">Refund if you cancel after grace period: </span>
                      <span class="span inter-normal-mine-shaft-14px">{{policy2}}%</span>
                    </div>      
                    <div class="flex-col-item inter-semi-bold-mine-shaft-14px">
                      <span class="span inter-semi-bold-mine-shaft-14px">Refund for a no show no notice: </span>
                      <span class="span inter-normal-mine-shaft-14px">{{policy3}}%</span>
                    </div>      
                    <div class="flex-col-item inter-semi-bold-mine-shaft-14px">
                      <span class="span inter-semi-bold-mine-shaft-14px">Refund if you cancel with between 36 and 24 hours notice: </span>
                      <span class="span inter-normal-mine-shaft-14px">{{policy4}}%</span>
                    </div>      
                    <div class="flex-col-item inter-semi-bold-mine-shaft-14px">
                      <span class="span inter-semi-bold-mine-shaft-14px">Refund if you cancel with between 23 and 12 hours notice: </span>
                      <span class="span inter-normal-mine-shaft-14px">{{policy5}}%</span>
                    </div>      
                    <div class="flex-col-item inter-semi-bold-mine-shaft-14px">
                      <span class="span inter-semi-bold-mine-shaft-14px">Refund if you cancel with between 11 and 2 hours notice: </span>
                      <span class="span inter-normal-mine-shaft-14px">{{policy6}}%</span>
                    </div>      
                    <div class="flex-col-item inter-semi-bold-mine-shaft-14px">
                      <span class="span inter-semi-bold-mine-shaft-14px">Refund if you cancel with less than 2 hours notice: </span>
                      <span class="span inter-normal-mine-shaft-14px">{{policy7}}%</span>
                    </div>   
                   </div>


                  <!-- Policies -->
                
                </div>
                <div class="rectangle-36"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- 

  <div id = 'shareToggle' class = 'overlap-group1-1-3-1' style="display: inline-block;"> 
    <div class="group-158" style="display: inline-block;vertical-align: top;">
      <div class="worksans-semi-bold-chathams-blue-16px">Email(s)</div>
      <div class="overlap-group6">
        <input placeholder="Enter Email..."
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event)"
               id = 'emailInput'>
      </div>
    </div>
    <div style="width:2%;display: inline-block;"></div>
    <div class="group-163" style="display: inline-block;">
      <p class="worksans-semi-bold-chathams-blue-16px">Message</p>
      <textarea class="overlap-group3-1 inter-normal-steel-teal-14px" style="font-size:16px" placeholder="enter your message..." ngModel name="fn33" #fn33 ="ngModel" (change) = "shareNote = fn33.value" ></textarea>
    </div>
    <div style="width:45%;height:100px;display: inline-block;vertical-align: top;margin-top:-133px;">
      <div class="overlap-group6-2" id = 'emailContainer'>
        <mat-form-field class="example-chip-list" appearance="fill">
          <mat-chip-list #chipList aria-label="Fruit selection">    
            <mat-chip *ngFor="let fruit of fruits" id = {{fruit.name}} (removed)="remove(fruit)" style="background-color: #C6C6C6;border-radius: 15px;margin: 0px;margin-right: 6px;margin-bottom: 6px;" >
              {{fruit.name}}
              <button matChipRemove style="border:none;border-radius: 25px;">
                <mat-icon style="position: absolute;right:5px;bottom: 4px;">cancel</mat-icon>
              </button>
            </mat-chip>

          </mat-chip-list>
        </mat-form-field>  
      </div>
    </div>
    <share-buttons [theme]="'circles-dark'"
    [include]="['facebook','twitter','linkedin','pinterest','reddit','tumblr','mix','viber','vk','telegram','messenger','whatsapp','xing','line','sms','email','print','copy']"
    [exclude]="['viber']"
    [show]="18"
    [url]="'https://verifiedteachers.com/'"
    [title]="'Check Out This Awesome Teacher'"
    [description]="'Check Out This Awesome Teache Check Out This Awesome Teache Check Out This Awesome Teache Check Out This Awesome TeacheCheck Out This Awesome Teache'"
    [autoSetMeta]="false"
   ></share-buttons>
  </div> -->