import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import { type } from 'os';
import { start } from 'repl';
import {Observable} from 'rxjs'
import { timestamp } from 'rxjs/operators';




import { time } from '../../models/times'


@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent implements OnInit {
  @Output() newAvailable: EventEmitter<time> = new EventEmitter();

  timeSlots = [];
  
  availableTime:time;
  timeCatch = []; 
  currentUID:string;
  schedule = new Map(
    [
    ["mon", ""],
    ["tue", ""],
    ["wed", ""],
    ["thu", ""],
    ["fri", ""],
    ["sat", ""],
    ["sun", ""]
    ]
  );

  mon = false;
  tue = false;
  wed = false;
  thu = false;
  fri = false;
  sat = false;
  sun = false;


  constructor(private database: AngularFireDatabase) {
      
   }




  ngOnInit(): void {

  }

  update(UID:string):any{
    this.currentUID = UID
    this.scheduleUpdate().then(newSchedule =>{
      this.days(newSchedule)
      this.availableTime = newSchedule
      return this.availableTime
    })
  }

  days(t:time): time{
    var count = 0
    this.schedule = new Map(
      [
      ["mon", ""],
      ["tue", ""],
      ["wed", ""],
      ["thu", ""],
      ["fri", ""],
      ["sat", ""],
      ["sun", ""]
      ]
    );
    for( var val of t[0]){
      if(val === 'mon'){
        this.schedule.set("mon", t[1][count]);
        count = count + 1
      }else if(val === 'tue'){
        this.schedule.set("tue", t[1][count])
        count = count + 1
      }else if(val === 'wed'){
        this.schedule.set("wed", t[1][count])
        count = count + 1
      }else if(val === 'thu'){
        this.schedule.set("thu", t[1][count])
        count = count + 1
      }else if(val === 'fri'){
        this.schedule.set("fri", t[1][count])
        count = count + 1
      }else if(val === 'sat'){
        this.schedule.set("sat", t[1][count])
        count = count + 1
      }else if(val === 'sun'){
        this.schedule.set("sun", t[1][count])
        count = count + 1
      }
    }
    return t;
  }

  getSchedule(){
    this.timeSlots = []
    this.schedule.forEach((value: string, key: string) => {
      if (value !== ''){
        var firstSecond = 3
        var startHour = ''
        var dailyHours = []
        for(var v of value.split(" ")){
          if (firstSecond % 2 === 0){     
            dailyHours.push([startHour,v]) 
          }else{
            startHour = v
          }
          firstSecond = firstSecond + 1
        }   
        this.timeSlots.push([key,dailyHours])
      }

    });     
  }

  async scheduleUpdate(): Promise<time>{
    if(this.currentUID !== ""){
      this.getProviderSchedule(this.currentUID).then(cast =>{
        this.availableTime = cast
        this.days(this.availableTime)
        return this.availableTime
       }
    )}
   else{
    return 
   } 
  }

  getProviderSchedule(uid:string): any{
    var days = [];
    var hours = [];
    var hold = this.database.database.ref('providers/userInfo/' + uid + "/schedule").once('value').then(function(snap){
      snap.forEach(function(childSnapshot) {
        // key will be "ada" the first time and "alan" the second time
        var key = childSnapshot.key;
        var hold:string = key.toString()
        for (var val of hold.split(" ")) {
          days.push(val)
        }
        var childData = childSnapshot.val().toString();
        var hold:string = childData.toString().split(",")
        for (var val of hold) {
          hours.push(val)
        }
      });
      return [days,hours]
    });
    return hold
   }

}
