<div class="container-center-horizontal noSelect">
    <div class="frame-312 screen">
      <div class="teacher-sign-upformv1">
        <div class="overlap-group">
          <div class="overlap-group8">
            <div class="overlap-group1">
              <img
                class="rectangle-96" 
                src="../../../../assets/rectangle-96@1x.png"
              />
              <!-- <img
                class="vector-10"
                src="../../../../assets/Vector-1111.png"
              />
              <img
                class="vector-1"
                src="../../../../assets/Vector-2222.png"
              /> -->
              <!-- <img
                class="vector-6"
                src="../../../../assets/vector-2@3x.png"
              /> -->
              <!-- <img
                class="vector-5"
                src="../../../../assets/Vector-4444.png"
              />
              <img
                class="vector-9"
                src="../../../../assets/Vector-3333.png"
              /> -->
              <img
                class="rectangle-97"
                src="../../../../assets/Group-299.png"
              />
              <!-- <img
                class="subtract"
                src="../../../../assets/subtract@3x.png"
              /> -->
              <img
                *ngIf = '!isLoading'
                id = 'saver'
                class="frame-75"
                style="z-index: 1;cursor: pointer;outline: none !important;"
                (click) = 'storeInfo()'
                src="../../../../assets/frame-75@1x.png"
              />
              <img
              *ngIf = 'isLoading'
              id = 'loader'
              class="frame-75"
              style="z-index: 1;cursor: pointer;outline: none !important;"
              src="../../../../assets/loading.svg"
            />
              <div class="group-288">
                <div class="flex-col-1">
                  <div class="group-158">
                    <div class="place">Name</div>
                    <div class="overlap-group6">
                      <!-- <input type="email" class="name inter-normal-gunsmoke-14px-2" ngModel name="fnEmail" #fnEmail ="ngModel" (change) = "emailNL = fnEmail.value" placeholder="Name"autocomplete="off"> -->
                      <input id = 'name' maxLength = "25" minLength = "2" type="text" class = "text-7 inter-normal-steel-teal-14px"ngModel name="fn1" #fn1 ="ngModel" (change) = "fun = fn1.value" placeholder="First Name, Middle Initial, and Last Name" value= '{{name | async}}'>
                    </div>
                  </div>
                  <div class="group-159">
                    <p class="text-3">School Where You Currently Teach</p>
                    <div class="overlap-group-1">
                      <input class = "text-7 inter-normal-steel-teal-14px" required maxLength = "20" minLength = "2" ngModel name="fn2" #fn2 ="ngModel" (change) = "lastName = fn2.value"id ="lastName" type="text" placeholder="School Where You Currently Teach">
                    </div>
                  </div>
                  <div class="group-160">
                    <div class="personal-email">Personal Email</div>
                    <div class="overlap-group-1">
                      <input required type="email" class = "text-7 inter-normal-steel-teal-14px" ngModel name="fn4"#fn4 ="ngModel"(change) = "emailP = fn4.value" id = "publicEmail" placeholder="Your Personal Email">
                    </div>
                  </div>
                  <div class="group-16">
                    <div class="your-school-email">Your School Email</div>
                    <div class="overlap-group4">
                      <input required type="email" class = "text-7 inter-normal-steel-teal-14px" ngModel name="fn3" #fn3 ="ngModel" (change) = "emailS = fn3.value" id = "emailS" placeholder="Your School Email">
                    </div>
                  </div>
                  <div class="group-16">
                    <div class="create-password">Create Password</div>
                    <div class="overlap-group-1">
                      <input required maxLength = "50" class = "text-7 inter-normal-steel-teal-14px" minLength = "8" type="password"  ngModel name="fn5" #fn5 ="ngModel" (change) = "password = fn5.value" placeholder="Use upper and lower case letters with a number and a special symbol">
                    </div>
                  </div>
                </div>
                <div class="frame-296">
                  <div class="group-290">
                    <img
                      class="ellipse-16"
                      
                      id = 'proPic'
                      src="../../../../assets/defaultProfile.png"
                    />
                    <div class="frame-267"style="z-index: 1;cursor: pointer;" (click) = 'uploadPersonal()'>
                      <input  required type="file" class="custom-file-input" (change) = "upload($event)" accept = "*.png.jpg"id="inputGroupFile01" style="display: none;">
                      <div class="download">
                        <img src = '../../../../assets/download.png'>
                      </div>
                      <div class="upload-photo worksans-semi-bold-chathams-blue-14px">Upload Photo</div>
                    </div>
                  </div>
                  <div class="ageRangeDropDown">
                    <p class="text-3">Age Range(s)</p>
                    <button class="overlap-group3-1"  (click) = 'toggleageRangeDropDown()'><p style="white-space: nowrap;
                      overflow: scroll;
                      height: 23px;
                      margin-top: -2px;">{{selectedAges|async}}</p></button>
                  </div> 
                  <div id = 'ageRangeDropDown'class="hide">
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' id = 'General Education' value = 'General Education'(click) = 'updateAgeRange("General Education")'/>
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' id = '1_2' value = 'Lower Elementary'(click) = 'updateAgeRange("1_2")'/>
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' id = '3_5' value = 'Upper Elementary'(click) = 'updateAgeRange("3_5")'/>
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' id = '6_8' value = 'Middle School'(click) = 'updateAgeRange("6_8")'/>
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color: #F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' id = '9_12' value = 'High School'(click) = 'updateAgeRange("9_12")'/>  
                  </div>
                  <div class="ageRangeDropDown">
                    <p class="text-3">Subjects You Feel Comfortable Teaching</p>
                    <button class="overlap-group3-1"  (click) ="toggleSearchDropDown()" ><p style="white-space: nowrap;
                      overflow: scroll;
                      height: 23px;
                      margin-top: -2px;">{{selectedSubjects|async}}</p></button>
                  </div> 
                  <div id = 'searchDropDown' class="hide">
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("General")'id = 'General'value = 'General'/> 
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("Language Arts")' id = 'Language Arts' value = 'Language Arts'/>
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("Mathematics")'id = 'Mathematics'value = 'Mathematics'/>
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("Social Studies")'id = 'Social Studies'value = 'Social Studies'/>
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("Science")'id = 'Science'value = 'Science'/>
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("Creative Arts")'id = 'Creative Arts'value = 'Creative Arts'/>
                    <input type = "button" class="inter-semi-bold-chathams-blue-14px" style = 'width:266px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);' (click) = 'updateSearch("World Language")'id = 'World Language'value = 'World Language'/> 
                  </div>
                  <div class="group-163">
                    <p class="text-3">Tell More About Your Career</p>
                    <textarea class="overlap-group3"  placeholder="Text" ngModel name="fn33" #fn33 ="ngModel" (change) = "longDesc = fn33.value" ></textarea>
                  </div>
                  <!-- <div class="expertDropDown">
                    <p class="text-3">Tell More About Your Career</p>
                    <textarea class="overlap-group3-2"  placeholder="Text" ngModel name="fn33" #fn33 ="ngModel" (change) = "longDesc = fn33.value" ></textarea>
                  </div> -->
                </div>
              </div>
              <app-signup-schedule-maker class="frame-274" [config] = 'teacherInfo | async' (currentSchedule) = 'updateCurrentSchedule($event)'></app-signup-schedule-maker>
            </div>
            <!-- <div class="group-97">
              <img
                class="image-1"
                src="../../../../assets/image-1@2x.png"
              />
              <div class="group-91">
                <div class="teachers-1 worksans-medium-abbey-14px">TEACHERS</div>
                <div class="answers-1 worksans-medium-abbey-14px">ANSWERS</div>
                <div class="blog-1 worksans-medium-abbey-14px">BLOG</div>
                <div class="teacher-sign-up worksans-medium-abbey-14px">TEACHER SIGN UP</div>
                <div class="promotions worksans-medium-abbey-14px">PROMOTIONS</div>
                <div class="faq-1 worksans-medium-abbey-14px">FAQ</div>
              </div>
              <div class="group-58">
                <div class="overlap-group2"><div class="sign-in">Sign In</div></div>
              </div>
              <div class="sign-up worksans-semi-bold-chathams-blue-14px">Sign Up</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
