<div class="group-852">
    <input  required type="file" class="custom-file-input" (change) = "storeFile($event)" accept = ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"id="inputGroupFile01" style="display: none;">
    <div class="flex-col">
      <div class="flex-row-3">
        <h1 class="title">Create Your First Student Profile</h1>
        <div class="frame-361" id = 'editButton1' style="display: none;" (click) = 'createStudent()'>
            <div class="add-all-resources worksans-semi-bold-concrete-14px" data-id="1535:6805">Create Profile</div>
        </div>
        <div class="frame-361-1" id = 'editButton2' (click) = 'createStudent()' style = 'cursor: pointer;'>
            <div class="add-all-resources worksans-semi-bold-concrete-14px" data-id="1535:6805">Create Profile</div>
        </div>
        <!-- <div class="save-changes">Save Changes</div> -->
        <div class="overlap-group12"(click) = 'closeMe()'>
          <div class="vector" style="cursor: pointer;" ></div>
        </div>
      </div>
      <div class="flex-row-4">
        <div class="flex-col-1">
          <div class="flex-row-5">
            <div class="flex-col-2" style="z-index: 1;cursor: pointer;" (click) = 'uploadProfilePicture()'>
              <!--  -->
              <img id = 'proPic2'class="ellipse-18" src="../../../assets/zebra.svg" />
              <!-- <div class="frame-268">
                <img class="download" src="../../../assets/download.svg" />
                <div class="upload-new">Upload New</div>
              </div> -->
            </div>
            <div class="flex-col-3">
              <div class="frame-348">
                <div class="place inter-semi-bold-abbey-16px">Name</div>
                <div class="group-314">
                  <div class="overlap-group-6">
                    <input class="first-name-middle-i body"  maxLength = "25" minLength = "2" type="text" ngModel name="fn1" #fn1 ="ngModel" (change) = "updateName(fn1.value)" placeholder="Student Name..." value= '{{studentName}}'>
                  </div>
                </div>
              </div>
              <div class="group-163">
                <div class="favourite-subjects inter-semi-bold-abbey-16px">
                  Grade Level
                </div>
                <div class="overlap-group-7">
                  <input class="text body" maxLength = "25" minLength = "2" type="text" ngModel name="fn2" #fn2 ="ngModel" (change) = "updateGradeLevel(fn2.value)" placeholder="Grade Level..." value= '{{gradeLevel}}'>
                </div>
              </div>
              <div class="group-163">
                <div class="favourite-subjects inter-semi-bold-abbey-16px">
                  Favourite Subjects
                </div>
                <div class="overlap-group-7">
                  <input class="text body" maxLength = "25" minLength = "2" type="text" ngModel name="fn3" #fn3 ="ngModel" (change) = "favoriteSubject = fn3.value" placeholder="Favorite Subject..." value= '{{favoriteSubject}}'>
                </div>
              </div>
            </div>
          </div>
          <p class="choose-the-color-of-your-profile inter-semi-bold-abbey-16px">
            Profile Color Theme
          </p>
          <div class="frame-462">
              <div>
                <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("FFFFFF-F3F3F3-2A8499")'class="ellipse-89" id = 'FFFFFF-F3F3F3-2A8499'></div>
                <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("F2FEFF-C6DFE1-81CBD1")'class="ellipse-90" id = 'F2FEFF-C6DFE1-81CBD1'></div>
                <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("F8F2FF-DAD4E1-C1A1E8")' class="ellipse-95" id = 'F8F2FF-DAD4E1-C1A1E8'></div>
              </div>
              <div style="margin-top: 18px;">
                <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("FEFFD1-DADBA5-ECEF73")' class="ellipse-91" id = 'FEFFD1-DADBA5-ECEF73'></div>
                <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("EDFFD0-D2E2B8-B4F152")' class="ellipse-94" id = 'EDFFD0-D2E2B8-B4F152'></div>
                <div style = 'z-index: 1;cursor: pointer;'(click)='updateBackgroundColor("FFF1F8-DCBACB-F398C6")' class="ellipse-93" id = 'FFF1F8-DCBACB-F398C6'></div>
              </div>
          </div>
          <!-- <div class="displayed-awards inter-semi-bold-abbey-16px">
            Choose Page Background
          </div> -->
        </div>
        <img class="line-7" src="line-7.png" />
        <div class="flex-col-4">
            <div class="group-163">
                <div class="favourite-subjects inter-semi-bold-abbey-16px">
                  Toughest Subjects
                </div>
                <div class="overlap-group-7">
                  <input class="text body" maxLength = "25" minLength = "2" type="text" ngModel name="fn4" #fn4 ="ngModel" (change) = "toughestSubject  = fn4.value" placeholder="Toughest Subject ..." value= '{{toughestSubject }}'>
                </div>
              </div>
              <div class="group-163">
                <div class="favourite-subjects inter-semi-bold-abbey-16px">
                  Hobbies
                </div>
                <div class="overlap-group-7">
                  <input class="text body" maxLength = "25" minLength = "2" type="text" ngModel name="fn5" #fn5 ="ngModel" (change) = "hobbies = fn5.value" placeholder="Hobbies..." value= '{{hobbies}}'>
                </div>
              </div>
              <div class="group-163">
                <div class="favourite-subjects inter-semi-bold-abbey-16px">
                  Parent Note
                </div>
                <div class="overlap-group-7">
                  <input class="text body" maxLength = "25" minLength = "2" type="text" ngModel name="fn6" #fn6 ="ngModel" (change) = "parentNote = fn6.value" placeholder="Note..." value= '{{parentNote}}'>
                </div>
              </div>
          <div class="choose-your-alian inter-semi-bold-abbey-16px">
            Choose An Alien Explorer
          </div>
          <div class="group-754">
            <div class="overlap-group-container">
              <div class="overlap-group-container-1" style="cursor: pointer;" (click)='updateAlienColor("pink")'>
                <div class="overlap-group1">
                  <img class="alienNeck" src="../../../assets/pinkAlienNeck.svg" />
                  <img class="vector-93" src="../../../assets/pinkAlienHead.svg" />
                  <img id = 'pinkAlienSelected2' class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                  />
                </div>
              </div>
            </div>
            <div class="overlap-group-container-2">
              <div class="overlap-group-1" style="cursor: pointer;" (click)='updateAlienColor("green")'>
                <img class="alienNeck" src="../../../assets/greenAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/greenAlienHead.svg" />
                <img id = 'greenAlienSelected2'class= 'alienNotSelected' src="../../../assets/alienSelected.svg"/>
              </div>
            </div>
            <div class="overlap-group-container-3">
              <div class="overlap-group-1"style="cursor: pointer;" (click)='updateAlienColor("purple")'>
                <img class="alienNeck" src="../../../assets/purpleAlienNeck.svg" />
                <img class="vector-93" src="../../../assets/purpleAlienHead.svg" />
                <img id = 'purpleAlienSelected2'class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                />
              </div>
            </div>
            <div class="overlap-group-container-3">
                <div class="overlap-group-1" style="cursor: pointer;" (click)='updateAlienColor("yellow")'>
                    <img class="alienNeck" src="../../../assets/yellowAlienNeck.svg" />
                    <img class="vector-93" src="../../../assets/yellowAlienHead.svg" />
                    <img id = 'yellowAlienSelected2'class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                    />
                  </div>
            </div>
            <div class="overlap-group-container-3">
                <div class="overlap-group-1" style="cursor: pointer;" (click)='updateAlienColor("blue")'>
                    <img class="alienNeck" src="../../../assets/blueAlienNeck.svg" />
                    <img class="vector-93" src="../../../assets/blueAlienHead.svg" />
                    <img id = 'blueAlienSelected2'class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                    />
                  </div>
            </div>
            <div class="overlap-group-container-3">
                <div class="overlap-group-1"style="cursor: pointer;" (click)='updateAlienColor("orange")'>
                    <img class="alienNeck" src="../../../assets/orangeAlienNeck.svg" />
                    <img class="vector-93" src="../../../assets/orangeAlienHead.svg" />
                    <img id = 'orangeAlienSelected2'class ='alienNotSelected' src="../../../assets/alienSelected.svg"
                    />
                  </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col-5">
        <img class="line-6" src="line-6.png" />
        <div class="flex-row-7">
          <div class="overlap-group-container-4">
            <div class="overlap-group9" style="cursor: pointer;" (click) ='updatePageBackground("1")'>
              <img
                id = 'backgroundBorder1'
                class="unsplashom-nc-hyk-d-hp8-2"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6207273ea435ea8ee11cd95d/img/unsplash-omnchykdhp8-2@1x.png"
              /><img
                class="unsplashom-nc-hyk-d-hp8-3"
                src="../../../assets/pageImage1.svg"
              />

            </div>
            <div id = 'backgroundBorder4' class="overlap-group-5"style="cursor: pointer;" (click) ='updatePageBackground("4")'>
                <img
                class="unsplashom-nc-hyk-d-hp8"
                src="../../../assets/pageImage4.svg"
              />
            </div>
          </div>
          <div class="flex-col-6">
            <div id = 'backgroundBorder2' class="overlap-group13"style="cursor: pointer;" (click) ='updatePageBackground("2")'>
              <img
                class="unsplashom-nc-hyk-d-hp8"
                src="../../../assets/pageImage2.svg"
              />
            </div>
            <div id = 'backgroundBorder5' class="overlap-group11"style="cursor: pointer;" (click) ='updatePageBackground("5")'>
              <img
                class="unsplashom-nc-hyk-d-hp8"
                src="../../../assets/pageImage5.svg"
              />
            </div>
          </div>
          <div class="overlap-group-container-5">
            <div id = 'backgroundBorder3' class="overlap-group10" style="cursor: pointer;" (click) ='updatePageBackground("3")'>
              <img
                class="unsplashom-nc-hyk-d-hp8"
                src="../../../assets/pageImage3.svg"
              />
            </div>
            <div id = 'backgroundBorder6'class="overlap-group-5" style="cursor: pointer;" (click) ='updatePageBackground("6")'>
              <img
                class="unsplashom-nc-hyk-d-hp8"
                src="../../../assets/pageImage6.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  

<!-- <div class="container-center-horizontal noSelect">
    <div class="frame-483 screen">
        <div class="overlap-group1">
            <div class="group-457">
                <img
                class="x"
                (click) = 'closeMe()'
                data-id="1535:6765"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6168cac3368c4debc9d7cd8a/img/x@2x.png"
                />
            </div>

            <h1 class="title-1 worksans-semi-bold-abbey-32px">Student's Name:</h1>
            <div id = 'studentName' class="overlap-group6-1">
                <input maxLength = "25" minLength = "2" type="text" class = "text-7-8-1 inter-normal-steel-teal-14px"ngModel name="fn1" #fn1 ="ngModel" (change) = "studentName = fn1.value" placeholder="Studnet Name..." value= '{{studentName}}'>
            </div>

            <h1 class="title-2 worksans-semi-bold-abbey-32px">Favorite Subject:</h1>
            <div id = 'favoriteSubject' class="overlap-group6-2">
                <input maxLength = "25" minLength = "2" type="text" class = "text-7-8-1 inter-normal-steel-teal-14px"ngModel name="fn2" #fn2 ="ngModel" (change) = "favoriteSubject = fn2.value" placeholder="Favorite Subject..." value= '{{favoriteSubject}}'>
            </div>

            <h1 class="title-3 worksans-semi-bold-abbey-32px">Toughest Subject:</h1>
            <div id = 'toughestSubject' class="overlap-group6-3">
                <input maxLength = "25" minLength = "2" type="text" class = "text-7-8-1 inter-normal-steel-teal-14px"ngModel name="fn3" #fn3 ="ngModel" (change) = "toughestSubject = fn3.value" placeholder="Toughest Subject..." value= '{{toughestSubject}}'>
            </div>

            <h1 class="title-4 worksans-semi-bold-abbey-32px">Hobbies:</h1>
            <div id = 'hobbies' class="overlap-group6-4">
                <input maxLength = "25" minLength = "2" type="text" class = "text-7-8-1 inter-normal-steel-teal-14px"ngModel name="fn4" #fn4 ="ngModel" (change) = "hobbies = fn4.value" placeholder="Hobbies Name..." value= '{{hobbies}}'>
            </div>

            <h1 class="title-5 worksans-semi-bold-abbey-32px">Fun Fact:</h1>
            <div id = 'funFact' class="overlap-group6-5">
                <input maxLength = "25" minLength = "2" type="text" class = "text-7-8-1 inter-normal-steel-teal-14px"ngModel name="fn5" #fn5 ="ngModel" (change) = "funFact = fn5.value" placeholder="Fun Fact..." value= '{{funFact}}'>
            </div>
            
            <h1 class="title-6 worksans-semi-bold-abbey-32px">Parent's Note:</h1>
            <div id = 'parentNote' class="overlap-group6-6">
                <input maxLength = "25" minLength = "2" type="text" class = "text-7-8-1 inter-normal-steel-teal-14px"ngModel name="fn6" #fn6 ="ngModel" (change) = "parentNote = fn6.value" placeholder="Note..." value= '{{parentNote}}'>
            </div>
            <input  required type="file" class="custom-file-input" (change) = "storeFile($event)" accept = ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"id="inputGroupFile01" style="display: none;">
        </div>
    </div>
</div> -->
