import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import moment from 'moment-timezone';

@Component({
  selector: 'app-signup-schedule-maker',
  templateUrl: './signup-schedule-maker.component.html',
  styleUrls: ['./signup-schedule-maker.component.scss']
})
export class SignupScheduleMakerComponent implements OnInit {
  @Input() config = new EventEmitter<any[]>();

  @Output() currentSchedule = new EventEmitter<any>();

  @Output() selectedStartMoTime = new EventEmitter<any>();
  selectedStartMoTimeVal = ''
  @Output() selectedStartTuTime = new EventEmitter<any>();
  selectedStartTuTimeVal = ''
  @Output() selectedStartWeTime = new EventEmitter<any>();
  selectedStartWeTimeVal = ''
  @Output() selectedStartThTime = new EventEmitter<any>();
  selectedStartThTimeVal = ''
  @Output() selectedStartFrTime = new EventEmitter<any>();
  selectedStartFrTimeVal = ''
  @Output() selectedStartSaTime = new EventEmitter<any>();
  selectedStartSaTimeVal = ''
  @Output() selectedStartSuTime = new EventEmitter<any>();
  selectedStartSuTimeVal = ''

  @Output() selectedEndMoTime = new EventEmitter<any>();
  selectedEndMoTimeVal = ''
  @Output() selectedEndTuTime = new EventEmitter<any>();
  selectedEndTuTimeVal = ''
  @Output() selectedEndWeTime = new EventEmitter<any>();
  selectedEndWeTimeVal = ''
  @Output() selectedEndThTime = new EventEmitter<any>();
  selectedEndThTimeVal = ''
  @Output() selectedEndFrTime = new EventEmitter<any>();
  selectedEndFrTimeVal = ''
  @Output() selectedEndSaTime = new EventEmitter<any>();
  selectedEndSaTimeVal = ''
  @Output() selectedEndSuTime = new EventEmitter<any>();
  selectedEndSuTimeVal = ''


  UID = ''

  currentStartMoTime = []
  currentStartTuTime = []
  currentStartWeTime = []
  currentStartThTime = []
  currentStartFrTime = []
  currentStartSaTime = []
  currentStartSuTime = []

  currentEndMoTime = []
  currentEndTuTime = []
  currentEndWeTime = []
  currentEndThTime = []
  currentEndFrTime = []
  currentEndSaTime = []
  currentEndSuTime = []

  mondayUnselectedTimes = []
  tuesdayUnselectedTimes = []
  wednesdayUnselectedTimes = []
  thursdayUnselectedTimes = []
  fridayUnselectedTimes = []
  saturdayUnselectedTimes = []
  sundayUnselectedTimes = []

  sundaySelectedTimes = []
  mondaySelectedTimes = []
  tuesdaySelectedTimes = []
  wednesdaySelectedTimes = []
  thursdaySelectedTimes = []
  fridaySelectedTimes = []
  saturdaySelectedTimes = []

  currentStartRange = []

  sundayOpenS = false
  mondayOpenS = false
  tuesdayOpenS = false
  wednesdayOpenS = false
  thursdayOpenS = false
  fridayOpenS = false
  saturdayOpenS = false

  sundayOpenE = false
  mondayOpenE = false
  tuesdayOpenE = false
  wednesdayOpenE = false
  thursdayOpenE = false
  fridayOpenE = false
  saturdayOpenE = false
  
  
  listOfSelectedTimes = {0:'',1:'',2:'',3:'',4:'',5:'',6:''}
  unselectedTimes = {}
  calenda3r:any = {0:'',1:'08:00:00-10:00:00|12:00:00-14:30:00|17:00:00-19:00:00|22:00:00-23:00:00',2:'',3:'',4:'',5:'',6:'',}
  // calendar:any = {1:{'08:00:00-10:00:00':{'startSpread':[],'endSpread':[]},'12:00:00-14:30:00':{'startSpread':[],'endSpread':[]},'17:00:00-19:00:00':{'startSpread':[],'endSpread':[]},'22:00:00-23:30:00':{'startSpread':[],'endSpread':[]}}}
  calendar:any = {1:[{'08:00:00-10:00:00':{'startSpread':[],'endSpread':[]}},{'12:00:00-14:30:00':{'startSpread':[],'endSpread':[]}},{'17:00:00-19:00:00':{'startSpread':[],'endSpread':[]}},{'22:00:00-23:30:00':{'startSpread':[],'endSpread':[]}}]}
  
  constructor(private db: AngularFireDatabase) { }
  ngOnChanges(changes: SimpleChanges) {
    if(changes.config !== undefined && changes.config.currentValue !== null){
      this.UID = changes.config.currentValue[0]
      this.listOfSelectedTimes = changes.config.currentValue[1]
      this.initTeacherSchedule(this.listOfSelectedTimes)
      console.log(this.UID,this.listOfSelectedTimes)
    }
  }
  ngOnInit(): void {
    console.log(this.calendar)
    setTimeout(()=>{
      this.initTeacherSchedule(this.listOfSelectedTimes)
      this.selectedStartMoTime.emit('Start Time')
      this.selectedEndMoTime.emit('End Time')
      this.selectedStartTuTime.emit('Start Time')
      this.selectedEndTuTime.emit('End Time')
      this.selectedStartWeTime.emit('Start Time')
      this.selectedEndWeTime.emit('End Time')
      this.selectedStartThTime.emit('Start Time')
      this.selectedEndThTime.emit('End Time')
      this.selectedStartFrTime.emit('Start Time')
      this.selectedEndFrTime.emit('End Time')
      this.selectedStartSaTime.emit('Start Time')
      this.selectedEndSaTime.emit('End Time')
      this.selectedStartSuTime.emit('Start Time')
      this.selectedEndSuTime.emit('End Time')
    },)
  }

  updateTimeFrame(dayKey,range){


  }

  updateUnSelectedTimes(key){
    if(this.listOfSelectedTimes[key] !== ''){
      console.log(this.listOfSelectedTimes,this.unselectedTimes)
      var startMoment = moment(+ new Date()).hour(6).minute(0).second(0).millisecond(0)
      var endMoment = moment(+ new Date()).hour(Number(this.listOfSelectedTimes[key].split('|')[0].split('-')[0].split(':')[0])).minute(Number(this.listOfSelectedTimes[key].split('|')[0].split('-')[0].split(':')[1])).second(0).millisecond(0)
      this.unselectedTimes[key] = {}
      this.unselectedTimes[key]['startSpread'] = []
      this.unselectedTimes[key]['endSpread'] = []
      var firstRun1 = true
      while(startMoment.isBefore(endMoment)){
        if(firstRun1){
          firstRun1 = false
          this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
        }else{
          this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
          this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
        }
        startMoment.add(15,'minute')
      }
      console.log(this.unselectedTimes[key])
      if(!firstRun1){
        this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
        this.unselectedTimes[key]['startSpread'].push('~')
        this.unselectedTimes[key]['endSpread'].push('~')
      }


      var index = 0
      console.log(key)
      // if(key === '0'){
      //   this.sundaySelectedTimes = this.listOfSelectedTimes[key].split('|')
      // }else if(key === '1'){
      //   this.mondaySelectedTimes = this.listOfSelectedTimes[key].split('|')
      // }else if(key === '2'){
      //   this.tuesdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
      // }else if(key === '3'){
      //   this.wednesdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
      // }else if(key === '4'){
      //   this.thursdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
      // }else if(key === '5'){
      //   this.fridaySelectedTimes = this.listOfSelectedTimes[key].split('|')
      // }else if(key === '6'){
      //   this.saturdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
      // }
      var keyHolder = Number(key)
      this.listOfSelectedTimes[key].split('|').forEach(spread=>{
        console.log(this.listOfSelectedTimes,this.listOfSelectedTimes[keyHolder],keyHolder,key)
        if(index === this.listOfSelectedTimes[key].split('|').length-1){
          var startMoment = moment(+ new Date()).hour(Number(spread.split('-')[1].split(':')[0])).minute(Number(spread.split('-')[1].split(':')[1])).second(0).millisecond(0)
          var endMoment = moment(+ new Date()).hour(23).minute(45).second(0).millisecond(0)
        }else{
          var key2 = this.listOfSelectedTimes[key].split('|')[index+1]
          var startMoment = moment(+ new Date()).hour(Number(spread.split('-')[1].split(':')[0])).minute(Number(spread.split('-')[1].split(':')[1])).second(0).millisecond(0)
          var endMoment = moment(+ new Date()).hour(Number(key2.split('-')[0].split(':')[0])).minute(Number(key2.split('-')[0].split(':')[1])).second(0).millisecond(0)
        }
        index += 1
        var firstRun = true
        while(startMoment.isBefore(endMoment)){
          if(firstRun){
            firstRun = false
            this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
          }else{
            this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
            this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
          }
          startMoment.add(15,'minute')
        }
  
        this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
        this.unselectedTimes[key]['startSpread'].push('~')
        this.unselectedTimes[key]['endSpread'].push('~')
        
      })
      console.log(this.unselectedTimes)

    }else{
      var TS = + new Date()
      var startMoment = moment(TS).hour(6).minute(0).second(0).millisecond(0)
      var endMoment = moment(TS).hour(23).minute(45).second(0).millisecond(0)
      this.unselectedTimes[key] = {}
      this.unselectedTimes[key]['startSpread'] = []
      this.unselectedTimes[key]['endSpread'] = []
      var firstRun1 = true
      while(startMoment.isBefore(endMoment)){
        if(firstRun1){
          firstRun1 = false
          this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
        }else{
          this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
          this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
        }
        startMoment.add(15,'minute')
      }
      console.log(this.unselectedTimes[key])
      if(!firstRun1){
        this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
      }

    }
    if(key === '0'){
      this.sundaySelectedTimes = this.listOfSelectedTimes[key].split('|')
    }else if(key === '1'){
      this.mondaySelectedTimes = this.listOfSelectedTimes[key].split('|')
    }else if(key === '2'){
      this.tuesdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
    }else if(key === '3'){
      this.wednesdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
    }else if(key === '4'){
      this.thursdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
    }else if(key === '5'){
      this.fridaySelectedTimes = this.listOfSelectedTimes[key].split('|')
    }else if(key === '6'){
      this.saturdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
    }
    // var startMoment = moment(+ new Date()).hour(6).minute(0).second(0).millisecond(0)
    // var endMoment = moment(+ new Date()).hour(Number(this.listOfSelectedTimes[dateKey].split('|')[0].split('-')[0].split(':')[0])).minute(Number(this.listOfSelectedTimes[dateKey].split('|')[0].split('-')[0].split(':')[1])).second(0).millisecond(0)
    // this.unselectedTimes[dateKey] = {}
    // this.unselectedTimes[dateKey]['startSpread'] = []
    // this.unselectedTimes[dateKey]['endSpread'] = []
    // var firstRun1 = true
    // while(startMoment.isBefore(endMoment)){
    //   if(firstRun1){
    //     firstRun1 = false
    //     this.unselectedTimes[dateKey]['startSpread'].push(startMoment.format('hh:mm A'))
    //   }else{
    //     this.unselectedTimes[dateKey]['startSpread'].push(startMoment.format('hh:mm A'))
    //     this.unselectedTimes[dateKey]['endSpread'].push(startMoment.format('hh:mm A'))
    //   }
    //   startMoment.add(15,'minute')
    // }
    // console.log(this.unselectedTimes[dateKey])
    // this.unselectedTimes[dateKey]['endSpread'].push(startMoment.format('hh:mm A'))
    // this.unselectedTimes[dateKey]['startSpread'].push('~')
    // this.unselectedTimes[dateKey]['endSpread'].push('~')

    // var index = 0
    // this.listOfSelectedTimes[dateKey].split('|').forEach(spread=>{
    //   if(index === this.listOfSelectedTimes[dateKey].split('|').length-1){
    //     var startMoment = moment(+ new Date()).hour(Number(spread.split('-')[1].split(':')[0])).minute(Number(spread.split('-')[1].split(':')[1])).second(0).millisecond(0)
    //     var endMoment = moment(+ new Date()).hour(23).minute(45).second(0).millisecond(0)
    //   }else{
    //     console.log(key)
    //     var key = this.listOfSelectedTimes[dateKey].split('|')[index+1]
    //     var startMoment = moment(+ new Date()).hour(Number(spread.split('-')[1].split(':')[0])).minute(Number(spread.split('-')[1].split(':')[1])).second(0).millisecond(0)
    //     var endMoment = moment(+ new Date()).hour(Number(key.split('-')[0].split(':')[0])).minute(Number(key.split('-')[0].split(':')[1])).second(0).millisecond(0)
    //   }
    //   index += 1
    //   var firstRun = true
    //   while(startMoment.isBefore(endMoment)){
    //     if(firstRun){
    //       firstRun = false
    //       this.unselectedTimes[dateKey]['startSpread'].push(startMoment.format('hh:mm A'))
    //     }else{
    //       this.unselectedTimes[dateKey]['startSpread'].push(startMoment.format('hh:mm A'))
    //       this.unselectedTimes[dateKey]['endSpread'].push(startMoment.format('hh:mm A'))
    //     }
    //     startMoment.add(15,'minute')
    //   }
    //   if(!firstRun){
    //     this.unselectedTimes[dateKey]['endSpread'].push(startMoment.format('hh:mm A'))
    //     this.unselectedTimes[dateKey]['startSpread'].push('~')
    //     this.unselectedTimes[dateKey]['endSpread'].push('~')
    //   }

      
    // })
    // console.log(this.unselectedTimes)
  }

  removeTime(dateKey,range){
    console.log(dateKey,range)
    console.log(range,this.listOfSelectedTimes[dateKey].split('|'))
    var newTimes = this.listOfSelectedTimes[dateKey].split('|').filter(val=>val!==range)
    console.log(newTimes)
    var returnString = ''
    newTimes.forEach(v=>{
      if(returnString === ''){
        returnString = v
      }else{
        returnString += '|' + v
      }
    })
    this.listOfSelectedTimes[dateKey] = returnString
    this.updateUnSelectedTimes(dateKey)
    // this.updateFireBase(dateKey,this.listOfSelectedTimes[dateKey])
    this.currentSchedule.emit(this.listOfSelectedTimes)
    console.log(this.listOfSelectedTimes[dateKey],dateKey === '1')
     if(dateKey === '0'){
      if(this.listOfSelectedTimes[dateKey] !== ''){
        this.sundaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')
      }else{
        this.sundaySelectedTimes = []
      }
      
      this.selectedStartSuTime.emit('Start Time')
      this.selectedEndSuTime.emit('End Time')
      this.selectedStartSuTimeVal = ''
      this.selectedEndSuTimeVal = ''

      document.getElementById('startSpreadSu').setAttribute('class','hide')
      document.getElementById('endSpreadSu').setAttribute('class','hide')
      this.sundayOpenS = false
      this.sundayOpenE = false
    }else if(dateKey === '1'){
      console.log(1)
      if(this.listOfSelectedTimes[dateKey] !== ''){
        this.mondaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')
      }else{
        this.mondaySelectedTimes = []
      }
      
      this.selectedStartMoTime.emit('Start Time')
      this.selectedEndMoTime.emit('End Time')
      this.selectedStartMoTimeVal = ''
      this.selectedEndMoTimeVal = ''

      document.getElementById('startSpreadMo').setAttribute('class','hide')
      document.getElementById('endSpreadMo').setAttribute('class','hide')
      this.mondayOpenS = false
      this.mondayOpenE = false
      
    }else if(dateKey === '2'){
      if(this.listOfSelectedTimes[dateKey] !== ''){
        this.tuesdaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')
      }else{
        this.tuesdaySelectedTimes = []
      }
      

      this.selectedStartTuTime.emit('Start Time')
      this.selectedEndTuTime.emit('End Time')
      this.selectedStartTuTimeVal = ''
      this.selectedEndTuTimeVal = ''

      document.getElementById('startSpreadTu').setAttribute('class','hide')
      document.getElementById('endSpreadTu').setAttribute('class','hide')
      this.tuesdayOpenS = false
      this.tuesdayOpenE = false

    }else if(dateKey === '3'){
      if(this.listOfSelectedTimes[dateKey] !== ''){
        this.wednesdaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')
      }else{
        this.wednesdaySelectedTimes = []
      }
      

      this.selectedStartWeTime.emit('Start Time')
      this.selectedEndWeTime.emit('End Time')
      this.selectedStartWeTimeVal = ''
      this.selectedEndWeTimeVal = ''

      document.getElementById('startSpreadWe').setAttribute('class','hide')
      document.getElementById('endSpreadWe').setAttribute('class','hide')
      this.wednesdayOpenS = false
      this.wednesdayOpenE = false

    }else if(dateKey === '4'){
      if(this.listOfSelectedTimes[dateKey] !== ''){
        this.thursdaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')
      }else{
        this.thursdaySelectedTimes = []
      }
      
      this.selectedStartThTime.emit('Start Time')
      this.selectedEndThTime.emit('End Time')
      this.selectedStartThTimeVal = ''
      this.selectedEndThTimeVal = ''

      document.getElementById('startSpreadTh').setAttribute('class','hide')
      document.getElementById('endSpreadTh').setAttribute('class','hide')
      this.thursdayOpenS = false
      this.thursdayOpenE = false

    }else if(dateKey === '5'){
      if(this.listOfSelectedTimes[dateKey] !== ''){
        this.fridaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')
      }else{
        this.fridaySelectedTimes = []
      }

      this.selectedStartFrTime.emit('Start Time')
      this.selectedEndFrTime.emit('End Time')
      this.selectedStartFrTimeVal = ''
      this.selectedEndFrTimeVal = ''

      document.getElementById('startSpreadFr').setAttribute('class','hide')
      document.getElementById('endSpreadFr').setAttribute('class','hide')
      this.fridayOpenS = false
      this.fridayOpenE = false
    }else if(dateKey === '6'){
      if(this.listOfSelectedTimes[dateKey] !== ''){
        this.saturdaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')
      }else{
        this.saturdaySelectedTimes = []
      }
      

      this.selectedStartSaTime.emit('Start Time')
      this.selectedEndSaTime.emit('End Time')
      this.selectedStartSaTimeVal = ''
      this.selectedEndSaTimeVal = ''
      
      document.getElementById('startSpreadSa').setAttribute('class','hide')
      document.getElementById('endSpreadSa').setAttribute('class','hide')
      this.saturdayOpenS = false
      this.saturdayOpenE = false
    }
    console.log(this.listOfSelectedTimes)

  }

  updateFireBase(dateKey,value){
    console.log(dateKey,value)
    this.db.database.ref('providers/userInfo/' + this.UID + '/schedule/avail/').update({[dateKey]:value})
  }

  formatAvailableTime(mondayTime){
    var startTimeHour = mondayTime.split('-')[0].split(':')[0]
    var startTimeMin = mondayTime.split('-')[0].split(':')[1]
    var endTimeHour = mondayTime.split('-')[1].split(':')[0]
    var endTimeMin = mondayTime.split('-')[1].split(':')[1]
    var startMoment = moment(+ new Date()).hour(startTimeHour).minute(startTimeMin).second(0)
    var endMoment = moment(+ new Date()).hour(endTimeHour).minute(endTimeMin).second(0)
    return startMoment.format('hh:mm A') + '-' + endMoment.format('hh:mm A')
  }

  toggleStartTime2(day){
    if(Number(day) === 0){
      if(!this.sundayOpenS){
        document.getElementById('startSpreadSu').setAttribute('class','start0')
        this.currentStartSuTime = this.unselectedTimes[day]['startSpread']
      }else{
        document.getElementById('startSpreadSu').setAttribute('class','hide')
      }
      this.sundayOpenS = !this.sundayOpenS
    }else if(Number(day) === 1){
      if(!this.mondayOpenS){
        document.getElementById('startSpreadMo').setAttribute('class','start1')
        this.currentStartMoTime = this.unselectedTimes[day]['startSpread']
      }else{
        document.getElementById('startSpreadMo').setAttribute('class','hide')
      }
      this.mondayOpenS = !this.mondayOpenS
    }else if(Number(day) === 2){
      if(!this.tuesdayOpenS){
        document.getElementById('startSpreadTu').setAttribute('class','start2')
        this.currentStartTuTime = this.unselectedTimes[day]['startSpread']
      }else{
        document.getElementById('startSpreadTu').setAttribute('class','hide')
      }
      this.tuesdayOpenS = !this.tuesdayOpenS
    }else if(Number(day) === 3){
      if(!this.wednesdayOpenS){
        document.getElementById('startSpreadWe').setAttribute('class','start3')
        this.currentStartWeTime = this.unselectedTimes[day]['startSpread']
      }else{
        document.getElementById('startSpreadWe').setAttribute('class','hide')
      }
      this.wednesdayOpenS = !this.wednesdayOpenS
    }else if(Number(day) === 4){
      if(!this.thursdayOpenS){
        document.getElementById('startSpreadTh').setAttribute('class','start4')
        this.currentStartThTime = this.unselectedTimes[day]['startSpread']
      }else{
        document.getElementById('startSpreadTh').setAttribute('class','hide')
      }
      this.thursdayOpenS = !this.thursdayOpenS
    }else if(Number(day) === 5){
      if(!this.fridayOpenS){
        document.getElementById('startSpreadFr').setAttribute('class','start5')
        this.currentStartFrTime = this.unselectedTimes[day]['startSpread']
      }else{
        document.getElementById('startSpreadFr').setAttribute('class','hide')
      }
      this.fridayOpenS = !this.fridayOpenS
    }else if(Number(day) === 6){
      if(!this.saturdayOpenS){
        document.getElementById('startSpreadSa').setAttribute('class','start6')
        this.currentStartSaTime = this.unselectedTimes[day]['startSpread']
      }else{
        document.getElementById('startSpreadSa').setAttribute('class','hide')
      }
      this.saturdayOpenS = !this.saturdayOpenS
    }
  }

  toggleEndTime(day){
    if(Number(day) === 0){
      if(!this.sundayOpenE){
        document.getElementById('endSpreadSu').setAttribute('class','end0')
        this.currentEndSuTime = this.unselectedTimes[day]['endSpread']
      }else{
        document.getElementById('endSpreadSu').setAttribute('class','hide')  
      }
      this.sundayOpenE = !this.sundayOpenE
    }else if(Number(day) === 1){
      if(!this.mondayOpenE){
        document.getElementById('endSpreadMo').setAttribute('class','end1')
        this.currentEndMoTime = this.unselectedTimes[day]['endSpread']
      }else{
        document.getElementById('endSpreadMo').setAttribute('class','hide')
      }
      this.mondayOpenE = !this.mondayOpenE
    }else if(Number(day) === 2){
      if(!this.tuesdayOpenE){
        document.getElementById('endSpreadTu').setAttribute('class','end2')
        this.currentEndTuTime = this.unselectedTimes[day]['endSpread']
      }else{
        document.getElementById('endSpreadTu').setAttribute('class','hide')
      }
      this.tuesdayOpenE = !this.tuesdayOpenE
    }else if(Number(day) === 3){
      if(!this.wednesdayOpenE){
        document.getElementById('endSpreadWe').setAttribute('class','end3')
        this.currentEndWeTime = this.unselectedTimes[day]['endSpread']
      }else{
        document.getElementById('endSpreadWe').setAttribute('class','hide')
      }
      this.wednesdayOpenE = !this.wednesdayOpenE
    }else if(Number(day) === 4){
      if(!this.thursdayOpenE){
        document.getElementById('endSpreadTh').setAttribute('class','end4')
        this.currentEndThTime = this.unselectedTimes[day]['endSpread']
      }else{
        document.getElementById('endSpreadTh').setAttribute('class','hide')
      }
      this.thursdayOpenE = !this.thursdayOpenE
    }else if(Number(day) === 5){
      if(!this.fridayOpenE){
        document.getElementById('endSpreadFr').setAttribute('class','end5')
        this.currentEndFrTime = this.unselectedTimes[day]['endSpread']
      }else{
        document.getElementById('endSpreadFr').setAttribute('class','hide')
      }
      this.fridayOpenE = !this.fridayOpenE
    }else if(Number(day) === 6){
      if(!this.saturdayOpenE){
        document.getElementById('endSpreadSa').setAttribute('class','end6')
        this.currentEndSaTime = this.unselectedTimes[day]['endSpread']
      }else{
        document.getElementById('endSpreadSa').setAttribute('class','hide')
      }
      this.saturdayOpenE = !this.saturdayOpenE
    }
  }
  formatTime3(time):any{
    var returnString = ''
    console.log(time)
    if(time.includes('PM')){
      if(time.split(':')[0] === '12'){
        returnString = `${Number(time.split('-')[0].split(' ')[0].split(':')[0])}` + ':' + time.split('-')[0].split(' ')[0].split(':')[1]
      }else{
        returnString = `${Number(time.split('-')[0].split(' ')[0].split(':')[0]) + 12 }` + ':' + time.split('-')[0].split(' ')[0].split(':')[1]
      }

    }else{
      if(time.split(':')[0] === '12'){
        returnString = '00:00'
      }else{
        returnString = `${Number(time.split('-')[0].split(' ')[0].split(':')[0])}` + ':' + time.split('-')[0].split(' ')[0].split(':')[1]
      }
      
    }
    console.log(returnString)
    return returnString
  }

  formatTime2(time):any{
    var returnString = ''
    if(time.split('-')[0].includes('pm')){
      if(time.split('-')[0] === '12'){
        returnString = `${Number(time.split('-')[0].split(' ')[0].split(':')[0])}` + ':' + time.split('-')[0].split(' ')[0].split(':')[1]
      }else{
        returnString = `${Number(time.split('-')[0].split(' ')[0].split(':')[0]) + 12 }` + ':' + time.split('-')[0].split(' ')[0].split(':')[1]
      }

    }else{
      if(time.split('-')[0] === '12'){
        returnString = '00:00'
      }else{
        returnString = `${Number(time.split('-')[0].split(' ')[0].split(':')[0])}` + ':' + time.split('-')[0].split(' ')[0].split(':')[1]
      }
      
    }

    if(time.split('-')[1].includes('pm')){
      if(time.split('-')[1] === '12'){
        returnString += '-' + `${Number(time.split('-')[1].split(' ')[0].split(':')[0])}` + ':' + time.split('-')[1].split(' ')[0].split(':')[1]
      }else{
        returnString += '-' + `${Number(time.split('-')[1].split(' ')[0].split(':')[0]) + 12 }` + ':' + time.split('-')[1].split(' ')[0].split(':')[1]
      }

    }else{
      if(time.split('-')[0] === '12'){
        returnString += '-' + '00:00'
      }else{
        returnString += '-' + `${Number(time.split('-')[1].split(' ')[0].split(':')[0])}` + ':' + time.split('-')[1].split(' ')[0].split(':')[1]
      }
      
    }
    console.log

    return returnString
  }

  updateStartTime(time,key){
    this.toggleStartTime2(key)
    console.log(time,key)
    if(key === '0'){
      this.selectedStartSuTime.emit(time)
      this.selectedStartSuTimeVal = this.formatTime3(time)
    }else if(key === '1'){
      this.selectedStartMoTime.emit(time)
      this.selectedStartMoTimeVal = this.formatTime3(time)
    }else if(key === '2'){
      this.selectedStartTuTime.emit(time)
      console.log(time)
      this.selectedStartTuTimeVal = this.formatTime3(time)
      console.log(this.selectedStartTuTimeVal)
    }else if(key === '3'){
      this.selectedStartWeTime.emit(time)
      this.selectedStartWeTimeVal = this.formatTime3(time)
    }else if(key === '4'){
      this.selectedStartThTime.emit(time)
      this.selectedStartThTimeVal = this.formatTime3(time)
    }else if(key === '5'){
      this.selectedStartFrTime.emit(time)
      this.selectedStartFrTimeVal = this.formatTime3(time)
    }else if(key === '6'){
      this.selectedStartSaTime.emit(time)
      this.selectedStartSaTimeVal = this.formatTime3(time)
    }
  }

  updateEndTime(time,key){
    this.toggleEndTime(key)
    if(key === '0'){
      this.selectedEndSuTime.emit(time)
      this.selectedEndSuTimeVal = this.formatTime3(time)
    }else if(key === '1'){
      this.selectedEndMoTime.emit(time)
      this.selectedEndMoTimeVal = this.formatTime3(time)
    }else if(key === '2'){
      this.selectedEndTuTime.emit(time)
      this.selectedEndTuTimeVal = this.formatTime3(time)
    }else if(key === '3'){
      this.selectedEndWeTime.emit(time)
      this.selectedEndWeTimeVal = this.formatTime3(time)
    }else if(key === '4'){
      this.selectedEndThTime.emit(time)
      this.selectedEndThTimeVal = this.formatTime3(time)
    }else if(key === '5'){
      this.selectedEndFrTime.emit(time)
      this.selectedEndFrTimeVal = this.formatTime3(time)
    }else if(key === '6'){
      this.selectedEndSaTime.emit(time)
      this.selectedEndSaTimeVal = this.formatTime3(time)
    }
  }

  // updateStartTime(time){
  //   console.log('205: ',time,this.currentTimeRanges,this.currentStartTime,this.currentEndTime)
  //   console.log(this.currentTimeRanges)
  //   var seen = false
  //   var valSeen = ''
  //   this.toggleStartTime()
  //   this.currentTimeRanges.forEach(timeRange=>{
  //     console.log(timeRange[0])
  //     console.log(moment(time).isSameOrAfter(timeRange[0].split('|-|')[0]),moment(time).isSameOrBefore(timeRange[0].split('|-|')[1]))
  //     if(moment(time).isSameOrAfter(timeRange[0].split('|-|')[0]) && moment(time).isSameOrBefore(timeRange[0].split('|-|')[1])){
  //       valSeen =  timeRange
  //       seen = true
  //     }
  //   })

  //   console.log(valSeen)
  //   if(seen){
  //     this.updateTimeFrameInner(valSeen,time)
  //   }
    
  //   this.startTime.emit(this.formatTime(time))
  //   this.startTimeValue = time
  //   this.startTimeSelected = true
  //   if(!this.endOpen){
  //     this.toggleEndTime()
  //   }

  //   if(this.endValue !== '' && !moment(time).isBefore(this.endValue)){
  //     this.endTimeSelected = false
  //     this.endTime.emit('End Time')
  //     this.endTimeValue = "End Time" 
  //     this.updateTimeFrameInner(valSeen,time)
  //   }

  // }


  formatTime(range){   
    console.log(range)
    if(range === 'T00:00:00 break'){
      var formatedTime = '-';
    }else{
      var formatedTime = moment(range).format("dddd, MMMM Do YYYY, h:mm a");
    }
    console.log(formatedTime)
    return formatedTime
  }

  addTime2(dateKey){
    console.log(dateKey)
    var newSpread:any = ''
    if(dateKey === '0'){
      newSpread = this.selectedStartSuTimeVal + '-' + this.selectedEndSuTimeVal
    }else if(dateKey === '1'){
      newSpread = this.selectedStartMoTimeVal + '-' + this.selectedEndMoTimeVal
    }else if(dateKey === '2'){
      newSpread = this.selectedStartTuTimeVal + '-' + this.selectedEndTuTimeVal
    }else if(dateKey === '3'){
      newSpread = this.selectedStartWeTimeVal + '-' + this.selectedEndWeTimeVal
    }else if(dateKey === '4'){
      newSpread = this.selectedStartThTimeVal + '-' + this.selectedEndThTimeVal
    }else if(dateKey === '5'){
      newSpread = this.selectedStartFrTimeVal + '-' + this.selectedEndFrTimeVal
    }else if(dateKey === '6'){
      newSpread = this.selectedStartSaTimeVal + '-' + this.selectedEndSaTimeVal
    }
    console.log(newSpread)
    var newStartTime = moment(+ new Date()).hour(newSpread.split('-')[0].split(':')[0]).minute(newSpread.split('-')[0].split(':')[1]).second(0).millisecond(0)
    var newEndTime = moment(+ new Date()).hour(newSpread.split('-')[1].split(':')[0]).minute(newSpread.split('-')[1].split(':')[1]).second(0).millisecond(0)
    var timesToFix = []
    var indexPositon = 0
    var trueIndex = 0
    var found = false
    if(this.listOfSelectedTimes[dateKey] !== ''){
      this.listOfSelectedTimes[dateKey].split('|').forEach(spread=>{
        console.log(spread)

        var testStartTime = moment(+ new Date()).hour(spread.split('-')[0].split(':')[0]).minute(spread.split('-')[0].split(':')[1]).second(0).millisecond(0)
        var testEndTime = moment(+ new Date()).hour(spread.split('-')[1].split(':')[0]).minute(spread.split('-')[1].split(':')[1]).second(0).millisecond(0)
        if(testStartTime.isSameOrAfter(newStartTime) && testStartTime.isSameOrBefore(newEndTime)){
          timesToFix.push(indexPositon)
        }else if(testEndTime.isSameOrAfter(newStartTime) && testEndTime.isSameOrBefore(newEndTime)){
          timesToFix.push(indexPositon)
        }
        if(testStartTime.isSameOrAfter(newStartTime) && !found){
          trueIndex = indexPositon
          found = true
        }
        indexPositon += 1
      })
      if(!found){
        found = true
        trueIndex = indexPositon
      }

      var hldVal = this.listOfSelectedTimes[dateKey].split('|')
      console.log(timesToFix)

      if(timesToFix.length === 0){
        hldVal.splice(trueIndex,0,newSpread)
      }else{
        var startIndex = timesToFix[0]
        var endIndex = timesToFix[timesToFix.length-1]

        var newTime = newSpread
        var placeIndex = startIndex
        var deleteSize = timesToFix.length
        console.log(placeIndex,endIndex,deleteSize)

        while(startIndex <= endIndex){
          var TS = + new Date()
          var testStartTimeInner = moment(TS).hour(hldVal[startIndex].split('-')[0].split(':')[0]).minute(hldVal[startIndex].split('-')[0].split(':')[1]).second(0).millisecond(0)
          var testEndTimeInner = moment(TS).hour(hldVal[startIndex].split('-')[1].split(':')[0]).minute(hldVal[startIndex].split('-')[1].split(':')[1]).second(0).millisecond(0)
          startIndex += 1
          console.log(testStartTimeInner.format("HH:mm") + '-' + testEndTimeInner.format("HH:mm"))
          if(testStartTimeInner.isBefore(newStartTime)){
            newTime = testStartTimeInner.format("HH:mm") + '-' + newTime.split('-')[1]
          }
          if(testEndTimeInner.isAfter(newEndTime)){
            newTime = newTime.split('-')[0] + '-' + testEndTimeInner.format("HH:mm")
          }
        }
        hldVal.splice(placeIndex,deleteSize)
        hldVal.splice(placeIndex,0,newTime)
        console.log(newTime,hldVal,newSpread,timesToFix)
      }
      var returnString = ''
      console.log(hldVal)
      hldVal.forEach(v=>{
        if(returnString === ''){
          returnString = v
        }else{
          returnString += '|' + v
        }
      })
      this.listOfSelectedTimes[dateKey] = returnString
      this.updateUnSelectedTimes(dateKey)
      // this.updateFireBase(dateKey,this.listOfSelectedTimes[dateKey])
      this.currentSchedule.emit(this.listOfSelectedTimes)

      console.log(dateKey)
      console.log(this.listOfSelectedTimes)
    }else{
      newStartTime = moment(+ new Date()).hour(newSpread.split('-')[0].split(':')[0]).minute(newSpread.split('-')[0].split(':')[1]).second(0).millisecond(0)
      newEndTime = moment(+ new Date()).hour(newSpread.split('-')[1].split(':')[0]).minute(newSpread.split('-')[1].split(':')[1]).second(0).millisecond(0)
      this.listOfSelectedTimes[dateKey] = newStartTime.format("HH:mm") + '-' + newEndTime.format("HH:mm")
      this.updateUnSelectedTimes(dateKey)
      // this.updateFireBase(dateKey,this.listOfSelectedTimes[dateKey])
      this.currentSchedule.emit(this.listOfSelectedTimes)

      console.log(this.listOfSelectedTimes)
    }
    if(dateKey === '0'){
      this.sundaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')
      this.selectedStartSuTime.emit('Start Time')
      this.selectedEndSuTime.emit('End Time')
      this.selectedStartSuTimeVal = ''
      this.selectedEndSuTimeVal = ''

      document.getElementById('startSpreadTu').setAttribute('class','hide')
      document.getElementById('endSpreadTu').setAttribute('class','hide')
      this.tuesdayOpenS = false
      this.tuesdayOpenE = false
    }else if(dateKey === '1'){
      console.log(1)
      this.mondaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')

      this.selectedStartMoTime.emit('Start Time')
      this.selectedEndMoTime.emit('End Time')
      this.selectedStartMoTimeVal = ''
      this.selectedEndMoTimeVal = ''

      document.getElementById('startSpreadMo').setAttribute('class','hide')
      document.getElementById('endSpreadMo').setAttribute('class','hide')
      this.mondayOpenS = false
      this.mondayOpenE = false
      
    }else if(dateKey === '2'){
      this.tuesdaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')

      this.selectedStartTuTime.emit('Start Time')
      this.selectedEndTuTime.emit('End Time')
      this.selectedStartTuTimeVal = ''
      this.selectedEndTuTimeVal = ''

      document.getElementById('startSpreadTu').setAttribute('class','hide')
      document.getElementById('endSpreadTu').setAttribute('class','hide')
      this.tuesdayOpenS = false
      this.tuesdayOpenE = false

    }else if(dateKey === '3'){
      this.wednesdaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')

      this.selectedStartWeTime.emit('Start Time')
      this.selectedEndWeTime.emit('End Time')
      this.selectedStartWeTimeVal = ''
      this.selectedEndWeTimeVal = ''

      document.getElementById('startSpreadWe').setAttribute('class','hide')
      document.getElementById('endSpreadWe').setAttribute('class','hide')
      this.wednesdayOpenS = false
      this.wednesdayOpenE = false

    }else if(dateKey === '4'){
      this.thursdaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')

      this.selectedStartThTime.emit('Start Time')
      this.selectedEndThTime.emit('End Time')
      this.selectedStartThTimeVal = ''
      this.selectedEndThTimeVal = ''

      document.getElementById('startSpreadTh').setAttribute('class','hide')
      document.getElementById('endSpreadTh').setAttribute('class','hide')
      this.thursdayOpenS = false
      this.thursdayOpenE = false

    }else if(dateKey === '5'){
      this.fridaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')

      this.selectedStartFrTime.emit('Start Time')
      this.selectedEndFrTime.emit('End Time')
      this.selectedStartFrTimeVal = ''
      this.selectedEndFrTimeVal = ''

      document.getElementById('startSpreadFr').setAttribute('class','hide')
      document.getElementById('endSpreadFr').setAttribute('class','hide')
      this.fridayOpenS = false
      this.fridayOpenE = false
    }else if(dateKey === '6'){
      this.saturdaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')

      this.selectedStartSaTime.emit('Start Time')
      this.selectedEndSaTime.emit('End Time')
      this.selectedStartSaTimeVal = ''
      this.selectedEndSaTimeVal = ''
      
      document.getElementById('startSpreadSa').setAttribute('class','hide')
      document.getElementById('endSpreadSa').setAttribute('class','hide')
      this.saturdayOpenS = false
      this.saturdayOpenE = false
    }
  }

  addTime(dateKey){
    var newSpread:any = this.selectedStartMoTimeVal + '-' + this.selectedEndMoTimeVal
    var newStartTime = moment(+ new Date()).hour(newSpread.split('-')[0].split(':')[0]).minute(newSpread.split('-')[0].split(':')[1]).second(0).millisecond(0)
    var newEndTime = moment(+ new Date()).hour(newSpread.split('-')[1].split(':')[0]).minute(newSpread.split('-')[1].split(':')[1]).second(0).millisecond(0)
    var timesToFix = []
    var indexPositon = 0
    var trueIndex = 0
    var found = false
    if(dateKey in this.listOfSelectedTimes){
      this.listOfSelectedTimes[dateKey].split('|').forEach(spread=>{
        console.log(spread)

        var testStartTime = moment(+ new Date()).hour(spread.split('-')[0].split(':')[0]).minute(spread.split('-')[0].split(':')[1]).second(0).millisecond(0)
        var testEndTime = moment(+ new Date()).hour(spread.split('-')[1].split(':')[0]).minute(spread.split('-')[1].split(':')[1]).second(0).millisecond(0)
        if(testStartTime.isSameOrAfter(newStartTime) && testStartTime.isSameOrBefore(newEndTime)){
          timesToFix.push(indexPositon)
        }else if(testEndTime.isSameOrAfter(newStartTime) && testEndTime.isSameOrBefore(newEndTime)){
          timesToFix.push(indexPositon)
        }
        if(testStartTime.isSameOrAfter(newStartTime) && !found){
          trueIndex = indexPositon
          found = true
        }
        indexPositon += 1
      })
      if(!found){
        found = true
        trueIndex = indexPositon
      }

      var hldVal = this.listOfSelectedTimes[dateKey].split('|')
      console.log(timesToFix)

      if(timesToFix.length === 0){
        hldVal.splice(trueIndex,0,newSpread)
      }else{
        var startIndex = timesToFix[0]
        var endIndex = timesToFix[timesToFix.length-1]
        console.log(startIndex,endIndex)
        if(startIndex === endIndex){
          if(trueIndex <= startIndex){
            // goes before
            console.log(trueIndex,startIndex)

            hldVal[startIndex] = newSpread.split('-')[0] + '-' + hldVal[startIndex].split('-')[1] 
          }else{
            // goes after
            console.log(trueIndex,startIndex)
            hldVal[startIndex] = hldVal[startIndex].split('-')[0]  + '-' +  newSpread.split('-')[1]
          }
        }else{
          var copyHldVal = [... hldVal]
          hldVal.splice(startIndex,endIndex-startIndex+1)
          
          if(hldVal === []){
            if(trueIndex <= startIndex){
              hldVal[0] =  newSpread.split('-')[0]  + '-' +  copyHldVal[startIndex].split('-')[1]
            }else if(trueIndex > endIndex){
              hldVal[0] = copyHldVal[startIndex].split('-')[0]  + '-' +  newSpread.split('-')[1]
            }else{
              hldVal[0] = copyHldVal[startIndex].split('-')[0]  + '-' +  copyHldVal[endIndex].split('-')[1]
            }
          }else{
            if(trueIndex <= startIndex){
              hldVal[startIndex] =  newSpread.split('-')[0]  + '-' +  copyHldVal[startIndex].split('-')[1]
            }else if(trueIndex > endIndex){
              hldVal[startIndex] = copyHldVal[startIndex].split('-')[0]  + '-' +  newSpread.split('-')[1]
            }else{
              hldVal[startIndex] = copyHldVal[startIndex].split('-')[0]  + '-' +  copyHldVal[endIndex].split('-')[1]
            }
          }

        }
      }
      var returnString = ''
      console.log(hldVal)
      hldVal.forEach(v=>{
        if(returnString === ''){
          returnString = v
        }else{
          returnString += '|' + v
        }
      })
      this.listOfSelectedTimes[dateKey] = returnString
      this.mondaySelectedTimes = this.listOfSelectedTimes[dateKey].split('|')
      console.log(this.listOfSelectedTimes)
    }else{

    }
    
    // console.log(spread)
    // var dateKey = spread[1].split('T')[0]
    // console.log(dateKey)
    // var timeFramesToFix = []
    // var found = false
    // var indexCounter = 0
    // var index = 0

    // this.calendar[dateKey].forEach(timeFrame=>{
    //   console.log(spread)
    //   console.log(timeFrame)
    //   if(moment(spread[1]).isSameOrAfter(timeFrame.start) && moment(spread[1]).isSameOrBefore(timeFrame.end)){
    //     timeFramesToFix.push([indexCounter,timeFrame])
    //   }else if(moment(spread[2]).isSameOrAfter(timeFrame.start) && moment(spread[2]).isSameOrBefore(timeFrame.end)){
    //     timeFramesToFix.push([indexCounter,timeFrame])
    //   }
    //   if(moment(timeFrame.start).isAfter(spread[2]) && !found){
    //     found = true
    //     if(indexCounter > 0){
    //       indexCounter -=1
    //     }
    //     index = indexCounter
    //   }
    //   indexCounter += 1
    // })
    // if(!found){
    //   found = true
    //   index = indexCounter
    // }
    // if(timeFramesToFix.length === 0){
    //   var hlder = {'start':spread[1],'end':spread[2]}
    //   if(index = 0){
    //     index = 0
    //   }else{
    //     index -= 1
    //   }
    //   this.weekOfAvailabilities[dateKey].splice(index,0,hlder)
    // }else{
    //   //turn all these values to one that is the first ones star time and the last ones end time
    //   if(index = 0){
    //     index = 0
    //   }else{
    //     index -= 1
    //   }
    //   console.log(timeFramesToFix)
    //   var offset = 0
    //   timeFramesToFix.forEach(val=>{
    //     console.log(Number(val[0]))
    //     this.weekOfAvailabilities[dateKey].splice(Number(val[0])-offset,1)
    //     offset += 1
        
    //   })
    //   console.log(this.weekOfAvailabilities[dateKey])
    //   var tfHld = [...timeFramesToFix]
    //   var hlder2 = [index,{'start':spread[1],'end':spread[2]} ]
    //   tfHld.splice(index,0,hlder2)
    //   console.log(tfHld[0])
    //   console.log(tfHld[tfHld.length-1])
    //   hlder = {'start':tfHld[0][1].start,'end':tfHld[tfHld.length-1][1].end}
      
    //   var firstIndex = timeFramesToFix[0][0]

    //   this.weekOfAvailabilities[dateKey].splice(firstIndex,0,hlder)
    //   console.log(this.weekOfAvailabilities[dateKey])
    // }
    
    // var startDate = moment(this.lastStartDate.format())
    // var endDate = moment(this.lastEndDate.format())

    // var openings = []

    // if(startDate.date() === endDate.date() && startDate.month() === endDate.month() && startDate.year() === endDate.year()){
    //   var key = startDate.toISOString(true).split('T')[0]
    //   var hold = {}
    //   hold[key] = this.weekOfAvailabilities[key]
    //   openings.push(hold)
    //   // openings.push(hold)          
    // }else{
    //   if(startDate.isBefore(endDate)){
    //     while(startDate.isSameOrBefore(endDate)){
    //       var dateTracker = startDate.toISOString(true).split('T')[0]
    //       if(dateTracker in this.weekOfAvailabilities){
    //         var hold = {}
    //         hold[dateTracker] = this.weekOfAvailabilities[dateTracker]
    //         openings.push(hold)
            
    //       }
    //       startDate.add(24,'hours')
    //     }
    //   }else{
    //     console.log('error avoided: infinite loop')
    //   }
    // }
    // this.selectedTimeFrame.emit(openings)

  }
  

  // listOfSelectedTimes = {1:'08:00:00-10:00:00|12:00:00-14:30:00|17:00:00-19:00:00|22:00:00-23:00:00'}
  // unselectedTimes = {}
  initTeacherSchedule(calendar:any,) {
    //calendar is an array with key representaing day of week
    //{0:'...',3:'...',5:'...'}
    console.log(calendar)
    for(const key in calendar){
      console.log(calendar[key],key)
      if(calendar[key] !== ''){
        console.log(this.listOfSelectedTimes,this.unselectedTimes)
        var startMoment = moment(+ new Date()).hour(6).minute(0).second(0).millisecond(0)
        var endMoment = moment(+ new Date()).hour(Number(this.listOfSelectedTimes[key].split('|')[0].split('-')[0].split(':')[0])).minute(Number(this.listOfSelectedTimes[key].split('|')[0].split('-')[0].split(':')[1])).second(0).millisecond(0)
        this.unselectedTimes[key] = {}
        this.unselectedTimes[key]['startSpread'] = []
        this.unselectedTimes[key]['endSpread'] = []
        var firstRun1 = true
        while(startMoment.isBefore(endMoment)){
          if(firstRun1){
            firstRun1 = false
            this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
          }else{
            this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
            this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
          }
          startMoment.add(15,'minute')
        }
        console.log(this.unselectedTimes[key])
        if(!firstRun1){
          this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
          this.unselectedTimes[key]['startSpread'].push('~')
          this.unselectedTimes[key]['endSpread'].push('~')
        }
  
  
        var index = 0
        console.log(key)
        if(key === '0'){
          this.sundaySelectedTimes = this.listOfSelectedTimes[key].split('|')
        }else if(key === '1'){
          this.mondaySelectedTimes = this.listOfSelectedTimes[key].split('|')
        }else if(key === '2'){
          this.tuesdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
        }else if(key === '3'){
          this.wednesdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
        }else if(key === '4'){
          this.thursdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
        }else if(key === '5'){
          this.fridaySelectedTimes = this.listOfSelectedTimes[key].split('|')
        }else if(key === '6'){
          this.saturdaySelectedTimes = this.listOfSelectedTimes[key].split('|')
        }
        var keyHolder = Number(key)
        this.listOfSelectedTimes[key].split('|').forEach(spread=>{
          console.log(this.listOfSelectedTimes,this.listOfSelectedTimes[keyHolder],keyHolder,key)
          if(index === this.listOfSelectedTimes[key].split('|').length-1){
            var startMoment = moment(+ new Date()).hour(Number(spread.split('-')[1].split(':')[0])).minute(Number(spread.split('-')[1].split(':')[1])).second(0).millisecond(0)
            var endMoment = moment(+ new Date()).hour(23).minute(45).second(0).millisecond(0)
          }else{
            var key2 = this.listOfSelectedTimes[key].split('|')[index+1]
            var startMoment = moment(+ new Date()).hour(Number(spread.split('-')[1].split(':')[0])).minute(Number(spread.split('-')[1].split(':')[1])).second(0).millisecond(0)
            var endMoment = moment(+ new Date()).hour(Number(key2.split('-')[0].split(':')[0])).minute(Number(key2.split('-')[0].split(':')[1])).second(0).millisecond(0)
          }
          index += 1
          var firstRun = true
          while(startMoment.isBefore(endMoment)){
            if(firstRun){
              firstRun = false
              this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
            }else{
              this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
              this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
            }
            startMoment.add(15,'minute')
          }
    
          this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
          this.unselectedTimes[key]['startSpread'].push('~')
          this.unselectedTimes[key]['endSpread'].push('~')
          
        })
        console.log(this.unselectedTimes)
  
      }else{
        var TS = + new Date()
        var startMoment = moment(TS).hour(6).minute(0).second(0).millisecond(0)
        var endMoment = moment(TS).hour(23).minute(45).second(0).millisecond(0)
        this.unselectedTimes[key] = {}
        this.unselectedTimes[key]['startSpread'] = []
        this.unselectedTimes[key]['endSpread'] = []
        var firstRun1 = true
        while(startMoment.isBefore(endMoment)){
          if(firstRun1){
            firstRun1 = false
            this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
          }else{
            this.unselectedTimes[key]['startSpread'].push(startMoment.format('hh:mm A'))
            this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
          }
          startMoment.add(15,'minute')
        }
        console.log(this.unselectedTimes[key])
        if(!firstRun1){
          this.unselectedTimes[key]['endSpread'].push(startMoment.format('hh:mm A'))
        }
  
      }
    }
  }
}
