import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TeacherProfile } from 'src/app/models/TeacherProfile';
import {AngularFireStorage} from '@angular/fire/storage'
import {AngularFireDatabase} from '@angular/fire/database'

@Component({   
  selector: 'app-teacher-item',
  templateUrl: './teacher-item.component.html',
  styleUrls: ['./teacher-item.component.scss']
})
export class TeacherItemComponent implements OnInit {
  @Input() teacher: TeacherProfile;   
  @Output() uid = new EventEmitter<string>();  
  url:string = ''
  titles = ''
  public doneLoading:boolean;
  public showContent: boolean = false;  

  constructor(private database:AngularFireDatabase,private afm: AngularFireStorage) { }

  async ngOnInit(){  
    //this.doneLoading = false
    this.doneLoading = true
    await this.getProPic()   
    await this.getStars()
    var gradeHold = this.teacher.credenitial
    if(gradeHold === 'General Education' || gradeHold === ''){
      // All Dots
      document.getElementById('LE'+ this.teacher.UID).setAttribute('style',"margin: 0 auto;margin-left:9px;display: inline-block;")
      document.getElementById('UE'+ this.teacher.UID).setAttribute('style',"margin: 0 auto;margin-left:9px;display: inline-block;")
      document.getElementById('MS'+ this.teacher.UID).setAttribute('style',"margin: 0 auto;margin-left:9px;display: inline-block;")
      document.getElementById('HS'+ this.teacher.UID).setAttribute('style',"margin: 0 auto;margin-left:9px;display: inline-block;")
    }else{
      //specified dots excluding General Education 
      console.log(gradeHold)
      gradeHold.split(',').forEach(grade=>{
        if(grade === '1_2'){
          document.getElementById('LE'+ this.teacher.UID).setAttribute('style',"margin: 0 auto;margin-left:9px;display: inline-block;")
        }else if(grade === '3_5'){
          document.getElementById('UE'+ this.teacher.UID).setAttribute('style',"margin: 0 auto;margin-left:9px;display: inline-block;")
        }else if(grade === '6_8'){
          document.getElementById('MS'+ this.teacher.UID).setAttribute('style',"margin: 0 auto;margin-left:9px;display: inline-block;")
        }else if(grade === '9_12'){
          document.getElementById('HS'+ this.teacher.UID).setAttribute('style',"margin: 0 auto;margin-left:9px;display: inline-block;")
        }
      })
    }  
  }

  addToOrder(){
    this.uid.emit(this.teacher.UID);  
  }
 
  async getStars(){
    var hold; 
    var teacherHold = this.teacher
    await this.database.database.ref('providers/userInfo/' + this.teacher.UID + '/immutable/stars/val').once('value').then(function(snap){
      hold = Number(snap.val())
      var count = 1
      while(count <= hold){
        var inpa = "star"+ String(count) + teacherHold.UID 
        document.getElementById(inpa).setAttribute('class',"fa fa-star checked")
        // outerHTML = '<span class="fa fa-star checked" style = "color:orange;"></span>';
        count += 1
      }
    }) 
  }
 
  async getProPic(){
    var teacherHold = this.teacher
    // Create a reference from a Google Cloud Storage URI
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/teacherPics/' + this.teacher.UID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById(`${teacherHold.UID}`).setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      var key = ''
      var randomNum = Math.random()
      console.log(randomNum)
      if(Number(randomNum) === 1){
        randomNum =.98
      }
      var randomNumber = Math.floor(randomNum * 7) 
      if(randomNumber === 0){
        randomNumber = 1
      }
       
      if(randomNumber === 1){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png'
      }else if(randomNumber === 2){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-48@2x.png'
      }else if(randomNumber === 3){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-49@2x.png'
      }else if(randomNumber === 4){
        key = '../../../assets/Giraf.png'
      }else if(randomNumber === 5){
        key = '../../../assets/Cat.png'
      }else if(randomNumber === 6){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-50@2x.png'
      }

      document.getElementById(`${teacherHold.UID}`).setAttribute('src',`${key}`);
      // document.getElementById(`${teacherHold.UID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }
  
}
