<div class="container-center-horizontal">
    <div class="frame-312 screen">
      <div class="teacher-sign-upformv1">
        <div class="overlap-group">
          <div class="overlap-group8">
            <div class="overlap-group1">
              <img
                class="rectangle-96" 
                src="../../../../assets/rectangle-96@1x.png"
              />
              <!-- <img
                class="vector-10"
                src="../../../../assets/Vector-1111.png"
              />
              <img
                class="vector-1"
                src="../../../../assets/Vector-2222.png"
              /> -->
              <!-- <img
                class="vector-6"
                src="../../../../assets/vector-2@3x.png"
              /> -->
              <!-- <img
                class="vector-5"
                src="../../../../assets/Vector-4444.png"
              />
              <img
                class="vector-9"
                src="../../../../assets/Vector-3333.png"
              /> -->
              <img
                class="rectangle-97"
                src="../../../../assets/Group-299.png"
              />
              <!-- <img
                class="subtract"
                src="../../../../assets/subtract@3x.png"
              /> -->
              <img
                *ngIf = '!sent'     
                class="frame-75" 
                id = 'signUpButtom'
                style="cursor: pointer;"
                (click) = 'signUpReq()'
                src="../../../../assets/frame-75@1x.png"
              />
              <img
                *ngIf = 'sent'
                class="frame-75" 
                id = 'signUpButtom'
                src="../../../../assets/promoThanks.png"
              />
              <div class="group-288">
                <div class="flex-col-1">
                  <div class="group-158">
                    <div class="place inter-semi-bold-chathams-blue-16px">Name</div>
                    <input ngModel name="fn1" #fn1 ="ngModel" (change) = "name = fn1.value" class="overlap-group6 text-7 inter-normal-steel-teal-14px" placeholder="Name...">
                  </div>
                  <div class="group-159">
                    <p class="text-3 inter-semi-bold-chathams-blue-16px">Contact Email</p>
                    <input ngModel name="fn2" #fn2 ="ngModel" (change) = "contactEmail = fn2.value"class="overlap-group-1 text-7 inter-normal-steel-teal-14px"placeholder="Email...">
                  </div>
                  <div class="group-160">
                    <div class="personal-email inter-semi-bold-chathams-blue-16px">Age of Student(s)</div>
                    <input ngModel name="fn3" #fn3 ="ngModel" (change) = "studentGrades = fn3.value" class="overlap-group-1 text-7 inter-normal-steel-teal-14px"placeholder="Age or Grade...">
                  </div>
                  <div class="group-16">
                    <div class="your-school-email inter-semi-bold-chathams-blue-16px">What subject do you most need help with?</div>
                    <input ngModel name="fn4" #fn4 ="ngModel" (change) = "subjects = fn4.value" class="overlap-group4 text-7 inter-normal-steel-teal-14px"placeholder="Subjects...">
                  </div>
                  <div class="group-16">
                    <div class="create-password inter-semi-bold-chathams-blue-16px">City, State Zip</div>
                    <input ngModel name="fn5" #fn5 ="ngModel" (change) = "location = fn5.value" class="overlap-group-1 text-7 inter-normal-steel-teal-14px"placeholder="City, State Zip...">
                  </div>
                </div>
              </div>
              <div class="frame-274">
                <!-- <div class="frame-294">
                  <h1 class="text-9 worksans-semi-bold-abbey-24px">Enter our verified raffel</h1>
                  <p class="text-10 inter-normal-mine-shaft-14px">
                    Enter our Raffle for a Chance to Win a Free 30 Minute Session Here!
                    Every two weeks we give out 3 sessions
                  </p>
                </div> -->
                <div class="frame-293">
                  <p class="text-1-1 inter-semi-bold-abbey-16px">VERIFIED TEACHERS TUTORING RAFFLE</p>
                  <p class="text-12 inter-normal-mine-shaft-14px">
                    Once a month, ENTER FOR A CHANCE TO WIN 
                  </p>
                  <p class="text-12 inter-normal-mine-shaft-14px">
                    Grand Prize (1 Winner)
                    The VIP Experience: We'll work with you personally to find a 
                    tutor tailored to you, with a free session to boot! 
                  </p>
                  <p class="text-12 inter-normal-mine-shaft-14px">
                    2nd Place (2 Winners)
                    The Verified Swag Bag! Make a custom pencil case
                    based on your student card, for free! 
                  </p>
                  <p class="text-12 inter-normal-mine-shaft-14px">
                    3rd Place (3 Winners)
                    A 50% discounted session with a tutor of your choice!
                  </p>
                  <p class="text-12 inter-normal-mine-shaft-14px">
                    Terms & Conditions
                    Prizes must be claimed electronically by the winner during the drawing date. If unable to, another winner 
                    will be drawn. Winner must claim  raffle ticket on the drawing date for verification.
                  </p>
                </div>
            </div>
          </div> 
      </div>
    </div>
  </div>
