import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import{ TeacherProfile } from '../../models/TeacherProfile';
import {TeachersService} from '../../services/teachers.service';
import {queries} from '../../models/queries'
import {AngularFireDatabase} from '@angular/fire/database'
import { DataSnapshot } from '@angular/fire/database/interfaces';
import { ResizedEvent } from 'angular-resize-event';
import { ActivatedRoute } from '@angular/router';
import { Content } from '@angular/compiler/src/render3/r3_ast';
  
 
     
@Component({        
  selector: 'app-search',     
  templateUrl: './search.component.html',  
  styleUrls: ['./search.component.scss'] 
}) 
export class SearchComponent  implements OnInit {  

  width: number;
  height: number;
  currentSlide = 1  

  computer = true

  s = false
  g = false
  d = false
  currentSelector = ''

  fresh:boolean = true;
  teachers: TeacherProfile[] = [];
  @Output() currTeachers = new EventEmitter<any[]>();
  blank:boolean
  @Output() mobile = new EventEmitter<boolean>();
  currentlySelected: string[] = [];
  activeQueries: Set<string[]> = new Set();
  currentQueries: queries[] = [] 
  relay:string[][] = []
  teacherUID:string[] = []
  zeros = 0 
  ones = 0   
  twos = 0
  

  constructor(private teachersService: TeachersService, private database:AngularFireDatabase,private router:ActivatedRoute) {}

  //life cycle method 
  async ngOnInit(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0
    if(this.router.snapshot.params.UID){
      var subs = this.router.snapshot.params.UID.split('-')[1]  
      var grades = this.router.snapshot.params.UID.split('-')[2]
      var times = this.router.snapshot.params.UID.split('-')[3]
 
      if(subs === ''){
        subs = 'ME'
      }
      if(grades === ''){
        grades = '6'
      }
      if(times === ''){
        times = 'b'
      }
      var quriesHold = []
      for (var i = 0; i < subs.length; i++) {
         var char = this.decodeSequence(subs[i])
         console.log(char) 
         this.changeColor(char)
          
         for (var j = 0; j < grades.length; j++) {
           
          var char2 = grades[j]
          
          if(char2 === '1'){
            char2 = '1_2'
          }else if(char2 === '3'){
            char2 = '3_5'
          }else if(char2 === '6'){
            char2 = '6_8'
          }else if(char2 === '9'){
            char2 = '9_12'
          }
           
          this.changeColor(char2)
          var newQ = new queries(char,0)
          newQ.updateStream(char2,1)
          newQ.updateStream('tue',2)
          quriesHold.push(newQ)
        }
         
      }
      this.currentQueries = quriesHold
      this.runQurieies(this.currentQueries)
    }
  }
  decodeSequence(char){
    if(char === 'M'){
      return 'Mathematics'
    }else if(char === 'E'){
      return 'Language Arts'
    }
    else if(char === 'S'){
      return 'Science'
    }
    else if(char === 'H'){
      return 'Social Studies'
    }
    else if(char === 'A'){
      return 'Creative Arts'
    }
    else if(char === 'W'){
      return 'World Languages'
    }else if(char === '1'){
      return '1_3'
    }else if(char === '3'){
      return '4_5'
    }else if(char === '6'){
      return '6_8'
    }else if(char === '9'){
      return '9_12'
    }else{
      return 'tue'
    }

  }

  async displayTeacher(uid:string){
    var newTeachers: TeacherProfile[] = []
    var dbRef = this.database.database
    var stars = 5 
    var name = ""
    var topics = ""
    var ages = ""
    var rate = ''
    // await dbRef.ref('providers/userInfo/' + uid + '/immutable').once('value').then(function(snap){
    //   stars = Number(snap.child('stars').child('val').val())
    //   name = snap.child('name').child('val').val()
    // }).then(()=>{
    //   dbRef.ref('providers/userInfo/' + uid + '/services').once('value').then(function(snapp){
    //     snapp.child('currentTitles').val().split(',').forEach(title=>{
    //       if(topics === ''){
    //         topics = title
    //       }else{
    //         topics += ' | ' + title
    //       }
    //     })
    //     ages = snapp.child('grades').val()

    //   }).then(()=>{
    //     var teach = new TeacherProfile(uid,name,rate,stars,ages,topics)
    //     newTeachers.push(teach)
    //   })
    // })

    await this.database.database.ref('providers/userInfo/' + uid).once('value').then(function(snap){
      stars = Number(snap.child('immutable').child('stars').child('val').val())
      name = snap.child('immutable').child('name').child('val').val()
      snap.child('services').child('currentTitles').child('val').val().split(',').forEach(title=>{
        if(topics === ''){
          topics = title
        }else{
          topics += ' | ' + title
        }
      })
      ages = snap.child('services').child('grades').child('val').val()
      rate = snap.child('profile').child('rate').child('val').val()
    }).then(()=>{
      var teach = new TeacherProfile(uid,name,rate,stars,ages,topics)
      newTeachers.push(teach)
    }).then(()=>{
      if(newTeachers.length > 0 ){
        newTeachers.forEach(teacher=>{
          this.teachers.push(teacher)
        })
      }
    })
    //this.doneLoading.emit(true)
  }
 
  //this is where we will get the category info 
  async displayTeacher2(uid:string){
    var newTeachers: TeacherProfile[] = []
    await this.database.database.ref('providers/userInfo/' + uid).once('value').then(function(snap){
      var cred = ""
      var stars = 5 
      var name = ""
      snap.child('immutable').forEach(function(child){
        if(child.key === "formalTitle"){
          cred = child.child('val').val()
        }else if (child.key === "stars"){
          stars = child.child('val').val() 
        }else if(child.key === "name"){
          name = child.child('val').val()
        }
      })

      var description = snap.child('profile').child('shortIntro').child('val').val()
      var rate = snap.child('pageInfo').child('rate').val()
      var teach = new TeacherProfile(uid,name,rate,stars,cred,description)
      newTeachers.push(teach)
    })
    if(newTeachers.length > 0 ){
      newTeachers.forEach(teacher=>{
        this.teachers.push(teacher)
      })
    }

    //this.doneLoading.emit(true)
  }

  teacherPage(s){
    console.log(s)
  } 

  onResized(event: ResizedEvent) {    
    this.width = event.newWidth;   
    this.height = event.newHeight;
    if(this.width < 700){
      this.computer = false
    }
  }


  incrementCounters(type:number){
    if(type === 0){ 
      this.zeros += 1
    }else if(type === 1){
      this.ones += 1
    }else{
      this.twos += 1
    }
  }

  select(val){  
    var selectedElem = document.getElementById(`${val}`)
    var dropship = document.getElementById('dropship')  
    var arrow = document.getElementById(`${val}` + 'Arrow')
    var tag = document.getElementById(`${val}` + 'Tag')
    if(this.currentSelector !== ''){
      if(this.currentSelector === val){
        this.currentSelector =''   
        if(val === 'days'){
          selectedElem.setAttribute('class','frame-205')
          dropship.setAttribute('class','overlap-group1-1')
          var content = document.getElementById(val + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display: none;')
          tag.setAttribute('class','suitable-days-2 inter-semi-bold-chathams-blue-14px')
          arrow.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }else if(val === 'subject'){
          selectedElem.setAttribute('class','frame-207')
          dropship.setAttribute('class','overlap-group1-1')
          var content = document.getElementById(val + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display: none;')
          tag.setAttribute('class','subject-13 inter-semi-bold-chathams-blue-14px')
          arrow.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }else if(val === 'grade'){
          selectedElem.setAttribute('class','frame-206')
          dropship.setAttribute('class','overlap-group1-1') 
          var content = document.getElementById(val + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display: none;')  
          tag.setAttribute('class','grade inter-semi-bold-chathams-blue-14px')
          arrow.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }
      }else{
        var selectedElem2 = document.getElementById(`${this.currentSelector}`)
        var arrow2 = document.getElementById(`${this.currentSelector}` + 'Arrow')
        var tag2 = document.getElementById(`${this.currentSelector}` + 'Tag')
        if(this.currentSelector === 'days'){
          console.log(1)
          selectedElem2.setAttribute('class','frame-205')
          var content = document.getElementById(this.currentSelector + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display:none;')
          tag2.setAttribute('class','suitable-days-2 inter-semi-bold-chathams-blue-14px')
          arrow2.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }else if(this.currentSelector === 'subject'){
          console.log(2)
          selectedElem2.setAttribute('class','frame-207')
          var content = document.getElementById(this.currentSelector + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display:none;')
          tag2.setAttribute('class','subject-13 inter-semi-bold-chathams-blue-14px')
          arrow2.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }else if(this.currentSelector === 'grade'){
          console.log(3)
          selectedElem2.setAttribute('class','frame-206')
          var content = document.getElementById(this.currentSelector + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display:none;')
          tag2.setAttribute('class','grade inter-semi-bold-chathams-blue-14px')
          arrow2.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }

        this.currentSelector = val    
        if(val === 'days'){
          console.log(4)
          selectedElem.setAttribute('class','frame-205-1')
          var content = document.getElementById(this.currentSelector + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;')
          tag.setAttribute('class','suitable-days-2 inter-normal-white-14px')
          arrow.setAttribute('src','../../assets/vector-54@2x.svg')
          dropship.setAttribute('class','overlap-group1-1-3')
        }else if(val === 'subject'){
          console.log(5)
          selectedElem.setAttribute('class','frame-207-1')
          var content = document.getElementById(this.currentSelector + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;')
          tag.setAttribute('class','subject-13 inter-normal-white-14px')
          arrow.setAttribute('src','../../assets/vector-54@2x.svg')
          dropship.setAttribute('class','overlap-group1-1-1')
        }else if(val === 'grade'){
          console.log(6)
          this.s = false
          this.g = true
          this.d = false
          selectedElem.setAttribute('class','frame-206-1')
          var content = document.getElementById(this.currentSelector + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;')
          tag.setAttribute('class','grade inter-normal-white-14px')
          arrow.setAttribute('src','../../assets/vector-54@2x.svg')
          dropship.setAttribute('class','overlap-group1-1-2')
        }
      }
    }else{
      this.currentSelector = val
      var selectedElem = document.getElementById(`${val}`)
      if(val === 'days'){
        selectedElem.setAttribute('class','frame-205-1')
        tag.setAttribute('class','suitable-days-2 inter-normal-white-14px')
        var content = document.getElementById(this.currentSelector + 'Selector')  
        content.setAttribute('style','z-index: 1;cursor: pointer;')
        arrow.setAttribute('src','../../assets/vector-54@2x.svg')
        dropship.setAttribute('class','overlap-group1-1-3')
      }else if(val === 'subject'){
        selectedElem.setAttribute('class','frame-207-1')
        tag.setAttribute('class','subject-13 inter-normal-white-14px')
        arrow.setAttribute('src','../../assets/vector-54@2x.svg')
        var content = document.getElementById(this.currentSelector + 'Selector')  
        content.setAttribute('style','z-index: 1;cursor: pointer;')
        dropship.setAttribute('class','overlap-group1-1-1')
      }else if(val === 'grade'){
        selectedElem.setAttribute('class','frame-206-1')
        tag.setAttribute('class','grade inter-normal-white-14px')
        var content = document.getElementById(this.currentSelector + 'Selector')  
        content.setAttribute('style','z-index: 1;cursor: pointer;')
        arrow.setAttribute('src','../../assets/vector-54@2x.svg')
        dropship.setAttribute('class','overlap-group1-1-2')
      }
    }
  }

  resetAll(){
    // this.onSelect("General",0)
    // unslect
    this.currentQueries = []
    document.getElementById('Language Arts').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('Mathematics').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('Science').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('Social Studies').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('Creative Arts').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('World Languages').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('General').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')

    document.getElementById('1_2').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('3_5').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('6_8').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('9_12').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('General Education').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')

    document.getElementById('a').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('b').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('c').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('d').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('e').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('f').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')
    document.getElementById('g').setAttribute('style','width:210px;background-color:#F3F3F3;padding: 10px 0px;border-radius: 10px;border-color: var(--chathams-blue);')

    this.onSelect("General",0)

    
  }

  decrementCounters(type:number){
    if(type === 0){
      this.zeros -= 1
    }else if(type === 1){  
      this.ones -= 1
    }else{  
      this.twos -= 1
    }
  } 
  removeEle(value, type):boolean{
    var ret = false
    this.currentQueries.forEach(function(q) {
      if(q.getStream()[type] === value){
        ret = true;
      }
    })
    return ret
  }
  changeColor(val){
    console.log(val)
    document.getElementById(val).style.background = '#B1E7F3'
  }      
  onSelect2(value:string,type:number){
    this.fresh = false
    if(this.removeEle(value,type)){
      document.getElementById(value).style.background = '#F3F3F3'
      this.removeFilter(value,type)
      if(this.currentQueries.length === 1){
        if(this.currentQueries[0].getStream()[0] === "" && this.currentQueries[0].getStream()[1] === "" && this.currentQueries[0].getStream()[2] === ""){
          this.currentQueries = []
          this.blank = true
        }else{
          this.blank = false
        }
      }  
      this.decrementCounters(type)
      this.teachers = []
      this.teacherUID = []
      this.runQurieies(this.currentQueries)
       
      this.currTeachers.emit(this.teachers)
    }else{
      //add new search filter
      document.getElementById(value).style.background = '#EA6F66'
      this.updateSearch(value,type)
      this.incrementCounters(type)
      this.teachers = []
      this.teacherUID = []
      this.runQurieies(this.currentQueries)
       
      this.currTeachers.emit(this.teachers)
    }
  }

  funcSort(a, b) {  
    return 0.5 - Math.random();
  } 


  async shuffleArray(array) {
    var i = 0
    , j = 0
    , temp = null

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1))
    temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
     
    return array
  }

  randomArrayShuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
     
    return array;
  }
  async onSelect(value:string,type:number){
    this.fresh = false
    if(this.removeEle(value,type)){
      document.getElementById(value).style.background = 'none'
      this.removeFilter2(value,type)
      if(this.currentQueries.length === 1){
        if(this.currentQueries[0].getStream()[0] === "" && this.currentQueries[0].getStream()[1] === "" && this.currentQueries[0].getStream()[2] === ""){
          this.currentQueries = []
          this.blank = true
        }else{
          this.blank = false
        }
      }  
      this.decrementCounters(type)
      this.teachers = []
      this.teacherUID = []
      this.currTeachers.emit([])
      await this.runQurieies(this.currentQueries).then(()=>{
        console.log(this.teachers)
      })
    }else{
      //add new search filter
      document.getElementById(value).style.background = '#B1E7F3'
      this.updateSearch(value,type)
      this.incrementCounters(type)
      this.teachers = []
      this.teacherUID = []
      this.currTeachers.emit([])
      await this.runQurieies(this.currentQueries).then(()=>{
        console.log(this.teachers)
      })
    }
  }

  async removeFilter2(value:string, type:number){
     
    var list = []
    this.currentQueries.forEach(querie =>{
      if(querie.getStream()[type] === value){
        list.push(querie)
      }
    });
    list.forEach(element=>{
      this.currentQueries = this.currentQueries.filter(val => val !== element)
    });
    this.currTeachers.emit([])
    await this.runQurieies(this.currentQueries)
  }
  
  async removeFilter(value:string, type:number){
    if(this.occurances(type) < 2){
       
      var removedQs =[]
      var newQs = []
      this.currentQueries.forEach(element =>{
        if(element.getStream()[type] === value){
          removedQs.push(element)
          if(!(element.simUpdateStream(value,type)[0] === "" && element.simUpdateStream(value,type)[1] === "" && element.simUpdateStream(value,type)[2] === "")){
            element.updateStream("",type)
            newQs.push(element.getStream())
          }
        }
      })
    }else{
       
      var list = []
      this.currentQueries.forEach(querie =>{
        if(querie.getStream()[type] === value){
          list.push(querie)
        }
      });
      list.forEach(element=>{
        this.currentQueries = this.currentQueries.filter(val => val !== element)
      });
      this.currTeachers.emit([])
      await this.runQurieies(this.currentQueries)
    }
  }

  occurances(type:number):number{
    if(type === 0){
      return this.zeros
    }else if (type === 1){
      return this.ones
    }else{
      return this.twos
    }
  }

  async runQurieies(qList:queries[]){
    var active:queries[] = []
    qList.forEach(element => {
      var count = 0
      var newQ = new queries("",1)
      element.getStream().forEach(ele => {
        if(ele === ""){
          var newValue = this.complete2(count)
          newQ.updateStream(newValue,count)
        }else{
          newQ.updateStream(ele,count)
        } 
        count += 1
      })
      active.push(newQ)
    });
    await active.forEach(qu=>{
     this.runQurieie2(qu,0)
    })
    await this.teachers.forEach(val =>{
      var vale = val.UID
      if(this.teacherUID.find(ele => ele === vale) === undefined){
        if(this.newUid(vale)){
          this.teacherUID.push(vale)
          this.displayTeacher(vale)
            
        }
      }
    })
     
    this.currTeachers.emit(this.teachers)
    
  }

  //get the teacher UIDs from the db to rank based on stars
  //just like old time where you have to get all values from a database section
  // new when you need to get all values from paticular sections of a database section
  async runQurieie(q:queries,run:number):Promise<string[]>{
    var val = q
    var request = "teacherSearch/" + val.getStream()[0]
     
    var ref = this.database.database.ref(request)
    var teachers = []
    var RG = this.relatedGuess(val.getStream()[1],1)
    var BG = this.relatedGuess(val.getStream()[2],2)
    var sub = ""
    await ref.once("value").then(function(snapshot){
      if(snapshot.hasChild(val.getStream()[1])){
        if(snapshot.child(val.getStream()[1]).hasChild(val.getStream()[2])){
          var hol:DataSnapshot = snapshot.child(val.getStream()[1]).child(val.getStream()[2])
          hol.forEach(ele=>{
            teachers.push(ele.val())
          })
        }else{ 
          // BG / days if => else
          if(snapshot.child(val.getStream()[1]).hasChildren()){
            snapshot.child(val.getStream()[1]).forEach(function(val) {
            var hol:DataSnapshot = val
              hol.forEach(ele=>{
                teachers.push(ele.val())
              })
            })
          }
        }
      }else{
          if(snapshot.hasChild(RG)){
            if(snapshot.child(RG).hasChild(val.getStream()[2])){
              var hol:DataSnapshot = snapshot.child(RG).child(val.getStream()[2])
              hol.forEach(ele=>{
                if(ele.key !== "sub"){
                  teachers.push(ele.val())
                }
              })
            }else{
              // RG for the day
              if(snapshot.child(RG).hasChild(BG)){
                var hol:DataSnapshot = snapshot.child(RG).child(BG)
                hol.forEach(ele=>{
                  teachers.push(ele.val())
                })
              }else{
                //any keys / any days?
                if(snapshot.child(RG).hasChildren()){
                  snapshot.child(RG).forEach(function(val) {
                    var hol:DataSnapshot = val
                    hol.forEach(ele=>{
                      teachers.push(ele.val())
                    })
                  })
                }
              }
            }
          }
        }
        sub = snapshot.child("sub").val()
      });
      run += 1
      var hold
      if(run < 1){
        var nQ = new queries("",0)
        var cnt = 0
        q.simUpdateStream(sub,0).forEach(val =>{
          nQ.updateStream(val,cnt)
          cnt += 1
        })
        hold = await this.runQurieie(nQ,run)
        hold.forEach(val=>{
          teachers.push(val)
        })
      }
      teachers.forEach(val =>{
        val = val["uid"]
        if(this.teacherUID.find(ele => ele === val) === undefined){
          if(this.newUid(val)){
            this.teacherUID.push(val)
            this.displayTeacher(val)
 
            this.currTeachers.emit(this.teachers)
          }
        }
      })   
      return this.teacherUID  
      
  }

  async runQurieie2(q:queries,run:number){
   
    var subject = q.stream[0]
    var grade = q.stream[1]
    var teachers = []
    var teachersRef = this.teacherUID
    this.database.database.ref('teacherSearch/'+`${grade}`+'/'+ `${subject}`).once("value").then(function(snapshot){
      if(snapshot.exists()){
        snapshot.forEach(function(snap){
          if(!teachers.includes(snap.key) && !teachersRef.includes(snap.key)){teachers.push(snap.key)}
        })
      }
    }).then(()=>{
      this.shuffleArray(teachers).then(valee=>{
        valee.forEach(val=>{
          if(this.teacherUID.find(ele => ele === val) === undefined){
            if(this.newUid(val)){
              this.teacherUID.push(val)
              this.displayTeacher(val)
  
            }
          }
        })  
      })
    }).then(()=>{
      this.currTeachers.emit(this.teachers)
    })
  }
 
  newUid(val):boolean{
    var ret = true;
    this.teacherUID.forEach(teacher=>{
      if(val === teacher){
        ret = false 
      } 
    })
    return ret
  } 
  
  relatedGuess(val:string,type:number):string{
    if(type === 1){
      if(val === "1_2"){
        return "3_5"
      }else if(val === "3_5"){
        return "6_8"
      }else if(val === "6_8"){ 
        return "9_12"
      }else{
        return "6_8"
      } 
    }else if(type === 2){
      if(val === "mon"){
        return "tue"
      }else if(val === "tue"){
        return "wed"
      }else if(val === "wed"){
        return "thu"
      }else if(val === "thu"){
        return "fri"
      }else if(val === "fri"){
        return "sat"
      }else if(val === "sat"){
        return "sun"
      }else{
        return "sat" 
      }
    }
    return ""
  }

  complete(type:number):string{
    if(type === 0){  
      return "English" 
    }else if(type === 1){
      return "6_8" 
    }else{
      return "thursday" 
    }
  }
  complete2(type:number):string{
    if(type === 0){  
      return "Language Arts" 
    }else if(type === 1){
      return "6_8" 
    }else{
      return "thursday" 
    }
  }

  async updateSearch(value:string,type:number): Promise<boolean>{
    var added = false;
    if(this.currentQueries.length === 0){
      let newQ = new queries(value,type)
      this.currentQueries.push(newQ)
      added = true
    }
    else{
      this.currentQueries.forEach(search=>{ 
        var stream = search.getStream()
        if(stream[type] === ""){
          search.updateStream(value,type)
          added = true
        }else{ 
          var newQ = new queries("",0)
          var count = 0
          search.simUpdateStream(value,type).forEach(element =>{
            newQ.updateStream(element,count)
            count += 1
          });
          var plus = true
          this.currentQueries.forEach(element =>{
            if(element.getStream()[0] === newQ.getStream()[0] && element.getStream()[1] === newQ.getStream()[1] && element.getStream()[2] === newQ.getStream()[2]){
              plus = false
            }
          });
          if(plus){
            this.currentQueries.push(newQ)
            added = true 
          };
        }  
    });
  }
  return added;
}

  newUnique(q,value,type):boolean{
    var resp = true
    var test = q
    test[type] = value
    this.currentQueries.forEach(val =>{
      if (val.getStream() === test){
        resp = false
      }
    })
    return resp

  }
}