import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FirebaseService } from 'src/app/services/firebase.service';
import { getDocument } from "pdfjs-dist";

@Component({
  selector: 'app-stored-file',
  templateUrl: './stored-file.component.html',
  styleUrls: ['./stored-file.component.scss']
})
export class StoredFileComponent implements OnInit {
  //{TYPE,TS,isTEACHER,AUTHORUID}
  @Input() AUXIN   
  @Input() TUID
  @Input() SUID   
  @Output() reload = new EventEmitter<any>();
  @Output() reloadInner = new EventEmitter<any>();
  @Output() deleteMe = new EventEmitter<any>();
  @Output() shareStorage = new EventEmitter<any[]>();
  @Output() contentTypeEmmitter = new EventEmitter<any[]>();
  @Output() nonInteractiveEmitter = new EventEmitter<any>();
  @Output() imagePlusEmitter = new EventEmitter<any>();
  @Output() addPDFEmitter = new EventEmitter<any>();
  TYPE = ''
  TS 
  AUID = ''
  TEACHER = ''
  title
  fileName = 'File'
  Link = ''       
  isLink = false
  isFile = false
  isSharedFile = false
  isPersonal = false
  isPersonalDoc = false
  isPersonalPDF = false
  isPersonalShared = false
  isInbox = false
  AuthorUID = ''
  ID
  userUID
  downloads
  reposts
  desc
  title2
  url:string = ''

  

  contentType = ''

  location = ''
  ID2 = 'container' + new Date()

  constructor(private db: AngularFireDatabase,private afm: AngularFireStorage,private firebase: FirebaseService) { }

  async ngOnInit(){
    
    this.ID = this.AUXIN[4] + '-' + this.AUXIN[0]

    
    this.TYPE = this.AUXIN[0]
    var possibleLink = this.AUXIN[1]
    
    this.TEACHER = this.AUXIN[2]
    if(!this.AUXIN[4].includes('-')){
      this.TS = this.AUXIN[4]
      this.AUID = this.AUXIN[3]
    }else{
      this.AUID = this.AUXIN[4].split('-')[0]
      this.TS = Number(this.AUXIN[4].split('-')[1])
    }
    this.userUID = this.AUXIN[3]
    this.fileName = String(this.AUXIN[5])


    var TSHold = this.TS
    var T = ''              
    // if its no longer in the directory, delete it 
    // this.db.database.ref('resourceDirectory/' + `${this.topics}` + '/' + `${this.subTopics}` + '/')
    // this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/personalFiles/' +  this.AUID + '/' + this.TUID)
    T = this.TYPE
      if(T === 'PERSONAL'){
        this.getProPic2() 
        // this.isPersonal = true   
        var newDate = new Date(TSHold)
        this.title = this.getDay(newDate.getDay()) + ' ' + this.month(newDate.getMonth()) + ' ' + newDate.getDate() + 'th ' 
      }else if(T === 'PERSONALPDF'){
        this.getProPic2() 
        console.log('yo')
        // this.isPersonalPDF = true   
        var newDate = new Date(TSHold)
        this.title = this.getDay(newDate.getDay()) + ' ' + this.month(newDate.getMonth()) + ' ' + newDate.getDate() + 'th ' 
      }else if(T === 'PERSONALDOC'){
        this.getProPic2() 
        // this.isPersonalDoc = true   
        var newDate = new Date(TSHold)
        this.title = this.getDay(newDate.getDay()) + ' ' + this.month(newDate.getMonth()) + ' ' + newDate.getDate() + 'th ' 
      }else if(T === 'INBOX'){
        console.log(this.AUXIN)
        this.getProPic() 
        this.isInbox = true   
        var newDate = new Date(TSHold)
        this.title = this.getDay(newDate.getDay()) + ' ' + this.month(newDate.getMonth()) + ' ' + newDate.getDate() + 'th ' 
      }else if(T === 'FILE'){
        this.getProUpdated()
        this.isFile = true
        var newDate = new Date(TSHold)  
        this.title = this.getDay(newDate.getDay()) + ' ' + this.month(newDate.getMonth()) + ' ' + newDate.getDate() + 'th ' 
      }else if(T === 'PERSONALSHARED'){
        this.getProPic()
        this.isPersonalShared = true   
        var newDate = new Date(TSHold)
        this.title2 = this.AUXIN[6]
        this.desc = this.AUXIN[7]
        this.downloads = this.AUXIN[4].split('-')[0]
        this.reposts = this.AUXIN[4].split('-')[1]
      }else if(T === 'SHAREDFILE'){
        this.getProPic().then(()=>{
          document.getElementById(this.SUID).setAttribute('style',"display:inline-block;margin:0 auto;text-align:center;width:260px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230);padding:10px;max-height:600px;overflow-y:auto;vertical-align:top");
        }) 
        this.isSharedFile = true
        var newDate = new Date(TSHold)
        this.title2 = this.AUXIN[6]
        this.desc = this.AUXIN[7]
        this.downloads = this.AUXIN[4].split('-')[0]
        this.reposts = this.AUXIN[4].split('-')[1]
        // this.title = this.getDay(newDate.getDay()) + ' ' + this.month(newDate.getMonth()) + ' ' + newDate.getDate() + 'th ' 

      }else if(T === 'LINK'){
        this.title = 'LINK'
        this.isLink = true
        this.Link = possibleLink
      }else if(T === 'NOWLINK'){
        this.title = 'LINK'
        this.isLink = true
        this.Link = possibleLink
      }
  }

  getType(){
    return this.contentType
  }

  plusDraw(){
    this.imagePlusEmitter.emit('')
  }

  addPDF(){
    console.log('1',this.url)
    this.addPDFEmitter.emit(this.url)
  }

  openExternal(){
    fetch(this.url)
    .then(res => res.blob()) // Gets the response and returns it as a blob
    .then(blob => {
      console.log(blob)
      
      var fileLink = document.createElement('a');
      fileLink.href = URL.createObjectURL(blob);

      // it forces the name of the downloaded file
      fileLink.download = this.fileName;

      // triggers the click event
      fileLink.click();
      // window.open(`${URL.createObjectURL(blob)}`, '_blank')
  });
  }

  deleteFile(){
    //check if this piece belongs to me
    // if it does belong to me delete file and remove from directory/search
    //other wise just remove our reference

    ///delete all placements in the resources directory, and in my personal files. delete all trace of 
    if(this.AUID === this.userUID){
      this.db.database.ref('providers/userInfo/' + `${this.AUID}` + '/sharedStorage/' + `${this.TS}`).remove().then(()=>{ 
        this.afm.storage.ref('personalFiles/' + `${this.AUID}` + '/' + `${this.TS}`).delete().then(()=>{
          this.deleteMe.emit(this.TS)
        }).catch(err=>{console.log(err)})  
      })
    }else{
      this.db.database.ref('providers/userInfo/' + `${this.userUID}` + '/sharedStorage/' + `${this.TS}`).remove().then(()=>{
        this.deleteMe.emit(this.TS)
      }).catch(err=>{console.log(err)})  
    }

  }

  share(){
    // 1. publish the article to our port
    // 2. add it manually to our own UI
    // use an emiter so we dont have to pass in the meetingID
    this.nonInteractiveEmitter.emit(this.AUXIN)
    

  }

  externalOpen(){
    // 1. download resource / open it in PDF viewer 
  }

  remove(){
    //check if it is shared or is someone else, only delete if they are the owner and it is not shared
    var TSHold = this.TS
    if(this.TEACHER){
      if(this.AUID === this.userUID){
        this.db.database.ref('providers/userInfo/' + `${this.AUID}` + '/storage/' + `${this.TS}`).remove().then(()=>{
          this.deleteMe.emit(this.TS)
          //if not shared
          var shared = false
          this.db.database.ref('providers/userInfo/' + `${this.userUID}` + '/sharedStorage/').once('value', val=>{
            if(val.exists()){
              val.forEach(function(snap){
                if(`${snap.key}` === `${TSHold}`){
                  shared = true
                }
              })
            }
          }).then(()=>{
            if(!shared){
              this.afm.storage.ref('personalFiles/' + `${this.AUID}` + '/' + `${this.TS}`).delete().catch(err=>{console.log(err)})
            }
          })
        })
      }else{
        this.db.database.ref('providers/userInfo/' + `${this.userUID}` + '/storage/' + `${this.AUXIN[4]}`).remove().then(()=>{
          this.deleteMe.emit(this.AUID + '-' + this.TS)
        })
      }
    }else{
      this.db.database.ref('users/' + `${this.AUID}` + '/storage/' + `${this.TS}`).remove().then(()=>{
        this.deleteMe.emit(this.TS)
        this.afm.storage.ref('personalFiles/' + `${this.AUID}` + '/' + `${this.TS}`).delete().catch(err=>{console.log(err)})
      })
    }
    
  }

  getDay(month){
    month = Number(month)
    if(month === 0){
      return "Mon"
    }else if(month === 1){
      return "Tue"
    }else if(month === 2){
      return "Wed"
    }else if(month === 3){
      return "Thu"
    }else if(month === 4){
      return "Fri"
    }else if(month === 5){
      return "Sat"
    }else if(month === 6){
      return "Sun"
    }
  }

  reloader(){
    this.reload.emit(this.AUID + '-'+this.TS)
  }

  reloaderInner(){
    this.reloadInner.emit(this.AUID + '-'+this.TS)
  }
  
  month(month){
    month = Number(month)
    if(month === 0){
      return "Jan"
    }else if(month === 1){
      return "Feb"
    }else if(month === 2){
      return "Mar"
    }else if(month === 3){
      return "Apr"
    }else if(month === 4){
      return "May"
    }else if(month === 5){
      return "June"
    }else if(month === 6){
      return "July"
    }else if(month === 7){
      return "Aug"
    }else if(month === 8){  
      return "Sep"
    }else if(month === 9){
      return "Oct"
    }else if(month === 10){
      return "Nov"
    }else{
      return "Dec"  
    }
  }

  // action(){
  //   var T = this.TYPE
  //   if(T === 'PERSONAL'){
  //     this.reloader()
  //   }
  // }

  // reloader(){
  //   console.log(this.TS)
  //   this.reload.emit(this.TS)
  // }

  async getProUpdated(){
    // Create a reference from a Google Cloud Storage URI
    // var TSHold = this.TS
    var IDHOLD = this.ID
    // var AUIDHold = this.AUID
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/meetingPics/' + this.AUID + '-' + this.TS)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById(`${IDHOLD}`).setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      document.getElementById(`${IDHOLD}`).setAttribute('src','../../../assets/noPic.png');
      document.getElementById(`${IDHOLD}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }

  async getProPic(){
    var IDHOLD = this.ID
    await this.afm.ref('personalFiles/'+this.AUID+'/'+this.TS).getMetadata().toPromise().then(val=>{
      this.contentType = val.contentType
      console.log()
      if(String(val.contentType).toLowerCase().includes('image/')){
        this.firebase.storageURL(this.AUID,this.TS).then(url=>{
          this.url = url
          console.log
          document.getElementById(`${IDHOLD}`).setAttribute('src',url);
        }).catch(function(error) {
          document.getElementById(`${IDHOLD}`).setAttribute('src','../../../assets/noPic.png');
        });
      }else{
        //
        this.firebase.storageURL(this.AUID,this.TS).then(url=>{
          this.url = url
        })
        if(this.isPersonal){
          this.isPersonalDoc = true
        }
        document.getElementById(`${IDHOLD}`).setAttribute('src','../../../assets/document.svg');
        document.getElementById(`${IDHOLD}`).setAttribute('style',"width:40px;height:40px; border-radius: 10px;border: 3px solid;border-color:grey;"); 
      }
    })
  }


  async getProPic2(){
    var IDHOLD = this.ID
    await this.afm.ref('personalFiles/'+this.AUID+'/'+this.TS).getMetadata().toPromise().then(val=>{
      this.contentType = val.contentType
      console.log()
      if(String(val.contentType).toLowerCase().includes('image/')){
        this.isPersonal = true
        this.firebase.storageURL(this.AUID,this.TS).then(url=>{
          this.url = url
          console.log
          document.getElementById(`${IDHOLD}`).setAttribute('src',url);
        }).catch(function(error) {
          document.getElementById(`${IDHOLD}`).setAttribute('src','../../../assets/noPic.png');
        });
      }
      // else if((String(val.contentType).toLowerCase().includes('pdf'))){
      //   this.firebase.storageURL(this.AUID,this.TS).then(url=>{
      //     this.url = url
      //   })
      //   this.isPersonalPDF = true
      //   document.getElementById(`${IDHOLD}`).setAttribute('src','../../../assets/document.svg');
      //   document.getElementById(`${IDHOLD}`).setAttribute('style',"width:40px;height:40px; border-radius: 10px;border: 3px solid;border-color:grey;"); 
      // }
      else{
        //
        this.isPersonalDoc = true
        this.firebase.storageURL(this.AUID,this.TS).then(url=>{
          this.url = url
        })
        if(this.isPersonal){
          this.isPersonalDoc = true
        }
        document.getElementById(`${IDHOLD}`).setAttribute('src','../../../assets/document.svg');
        document.getElementById(`${IDHOLD}`).setAttribute('style',"width:40px;height:40px; border-radius: 10px;border: 3px solid;border-color:grey;"); 
      }
    })
  }
}
