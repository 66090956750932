import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { storageFile } from 'src/app/models/storageFile';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-lesson-editor',
  templateUrl: './lesson-editor.component.html',
  styleUrls: ['./lesson-editor.component.scss']
})
export class LessonEditorComponent implements OnInit {
  teacher = false

  selectedCats = []

  imageThumbnails = {}
  imageThumbnailsType = {}
  nameChanges = {}

  

  //  [['TYPE','UPDATE']] Name,Cats,FileAdd,FiledDel

  mode = ''
  fileName1 = ''
  fileName2 = ''
  fileName3 = ''
  fileName4 = ''

  originalName = ''
  originalCats = ''

  deletedFiles = []
  loadedFiles = []
  newFiles =[]

  lessonName

  path:string = ""
  UID = ''
  
  pageNumber = 0  
  topic = false
  subtopic = false 
  topics = ['Practice Problems', 'Board Templates', 'Fun Pics', 'General']
  subtopics = ['Math', 'English', 'Social Studies', 'World Language', 'Creative Arts', 'Fun', 'General']

  // pdfjs = await import('pdfjs-dist/build/pdf');
  // pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');

    
  fileName = ''
  fileDesc = ''
  fileTopic = 'General'
  fileSubtopic = 'General'

  currentlySelected = 0
  @Input() AUXIN: any[];
  @Output() close = new EventEmitter<any[]>();
  @Output() updateLessonEmitter = new EventEmitter<any[]>();


  constructor(private db: AngularFireDatabase,private afm: AngularFireStorage,public auth: AuthService) { }
  // ngOnChanges(changes: any){
        
  //   console.log(changes.categoryId.currentValue);
  //   // You can also use categoryId.previousValue and 
  //   // categoryId.firstChange for comparing old and new values
    
  // } 

  ngOnChanges(changes: SimpleChanges) {
    if(changes.AUXIN.currentValue !== null){
      this.loadedFiles = []
      this.newFiles = []
      this.deletedFiles = []
      this.nameChanges = {}
      this.restFileDisplay()
      this.pageNumber = 0
      console.log(changes.AUXIN.currentValue)
      if(this.selectedCats.toString() !== ''){
        this.selectedCats.toString().split(',').forEach(catKey=>{
          this.toggleCat(catKey)
        })
      }
      if(changes.AUXIN.currentValue[4].length > 0){
        document.getElementById('editButton').setAttribute('style','z-index: 1;cursor: pointer;')
        document.getElementById('createButton').setAttribute('style','display:none')
        changes.AUXIN.currentValue[4].forEach(file=>{
          console.log(file)
          console.log('dbLoc: ' + file[0],' name: ' + file[1],' TS: ' + file[2])
          var URL = ''
          var META = ''
          if(typeof this.imageThumbnails[file[0]] === 'undefined'){
            this.getFileImageURL(this.UID,file[0]).then(url=>{
              this.getFileImageMetaData(this.UID,file[0]).then(meta=>{
                this.imageThumbnails[file[0]] = {'url':url,'type':meta}
                console.log(this.imageThumbnails)
                URL = url
                META = meta
              }).then(()=>{
                this.loadedFiles.unshift({'url':URL,'name':file[1],'type':META,'UID':file[0]})
                this.displayNewFiles()
              })
            })
          }else{
            URL = this.imageThumbnails[file[0]].url
            META = this.imageThumbnails[file[0]].type
            this.loadedFiles.unshift({'url':URL,'name':file[1],'type':META})
            this.displayNewFiles()
          }       
        })
      }else{
        document.getElementById('editButton').setAttribute('style','display:none')
        document.getElementById('createButton').setAttribute('style','z-index: 1;cursor: pointer;')
      }
      console.log(changes.AUXIN.currentValue[1])
      this.lessonName = changes.AUXIN.currentValue[1]
      this.originalName = changes.AUXIN.currentValue[1]
      this.originalCats = changes.AUXIN.currentValue[3].toString()
      changes.AUXIN.currentValue[3].split(',').forEach(catKey=>{
        this.toggleCat(catKey)
      })
    }
  }
  async ngOnInit(){
    await this.auth.user$.subscribe(usr=>{   
      // GlobalWorkerOptions.workerSrc = '"//mozilla.github.io/pdf.js/build/pdf.worker.js"'
      this.UID = usr.uid
      var teacherHold = false
      this.db.database.ref('providers/userInfo/' +  `${usr.uid}`).once('value').then(function(snap){
        if(snap.exists()){ 
          teacherHold = true
        } 
      }).then(()=>{
        this.teacher = teacherHold
      })
    })
    document.getElementById('drop_zone').addEventListener('drop',event=>{
      this.dropHandler(event)
    })
    document.getElementById('drop_zone').addEventListener('dragover',event=>{
      document.getElementById('drop_zone').setAttribute('class','group-458 border-8px-blue')
      this.dragOverHandler(event)
    })
    document.getElementById('drop_zone').addEventListener('dragleave',()=>{
      document.getElementById('drop_zone').setAttribute('class','group-458 border-1px-blue')
    })
    document.getElementById('drop_zone').addEventListener('click',()=>{
      document.getElementById('inputGroupFile01').click()
    })
 
  }
  updateName(name){
    this.loadedFiles[this.currentlySelected].name = name
  }
  updateDesc(desc){
    this.loadedFiles[this.currentlySelected].desc = desc
  }

  closeMe(){
    this.close.emit([])
  }

  confirm(){
    
  }

  updateTopic(services){
    this.loadedFiles[this.currentlySelected].topic = services
    this.fileTopic = services
  }

  updateSubtopic(services){
    this.loadedFiles[this.currentlySelected].subtopic = services
    this.fileSubtopic = services
  }



  restFileDisplay(){
    document.getElementById('image1').setAttribute('style','display:none')
    document.getElementById('image1').setAttribute('src','')
    document.getElementById('x1').setAttribute('style','display:none')
    document.getElementById('fileName1').setAttribute('style','display:none')
    
    document.getElementById('image2').setAttribute('style','display:none')
    document.getElementById('image2').setAttribute('src','')
    document.getElementById('x2').setAttribute('style','display:none')
    document.getElementById('fileName2').setAttribute('style','display:none')

    document.getElementById('image3').setAttribute('style','display:none')
    document.getElementById('image3').setAttribute('src','')
    document.getElementById('x3').setAttribute('style','display:none')
    document.getElementById('fileName3').setAttribute('style','display:none')

    document.getElementById('image4').setAttribute('style','display:none')
    document.getElementById('image4').setAttribute('src','')
    document.getElementById('x4').setAttribute('style','display:none')
    document.getElementById('fileName4').setAttribute('style','display:none')


  }

  clearHighlightBox(){
    // document.getElementById('box1').setAttribute('style','display:none')
    // document.getElementById('box2').setAttribute('style','display:none')
    // document.getElementById('box3').setAttribute('style','display:none')
    // document.getElementById('box4').setAttribute('style','display:none')
  }

  highlight(slot){
    document.getElementById('dropShip').setAttribute('style','display:none')
    this.topic = false
    this.subtopic = false
    this.fileDesc = ''
    this.fileName = ''
    this.fileTopic = ''    
    this.fileSubtopic = ''   
    this.clearHighlightBox()
    this.currentlySelected = (this.pageNumber*4 + slot) - 1
    if(this.loadedFiles[this.currentlySelected].desc === ''){
      this.fileDesc = 'empty'
    }else{
      this.fileDesc = this.loadedFiles[this.currentlySelected].desc
    }

    this.fileName = this.loadedFiles[this.currentlySelected].name
    this.fileTopic = this.loadedFiles[this.currentlySelected].topic
    this.fileSubtopic = this.loadedFiles[this.currentlySelected].subtopic

    document.getElementById('box' + `${slot}`).setAttribute('style','')
  }

  deleteFile(num){
    var deleteIndex = (this.pageNumber * 4) + num
    var deletedFile = this.loadedFiles[deleteIndex-1]
    this.deletedFiles.unshift(deletedFile)
    this.loadedFiles.splice(deleteIndex-1,1)
    if(this.loadedFiles.length === 0){
      this.fileName = ''
    }else if(this.loadedFiles.length%4 === 0){
      this.prevPage()
    }
    this.displayNewFiles()
  }

  removeFilesFromDB(lessonUID,fileUID){
    this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' +`${lessonUID}`+ '/files/'+`${fileUID}`).remove()
    .then(()=>{
      this.afm.storage.ref("/personalFiles/" + `${this.UID}` + '/' + `${fileUID}`).delete().catch(e=>{console.log(e)})
    })
  }
  
  displayNewFiles(){
    var start = this.pageNumber * 4
    var end = this.loadedFiles.length
    var currCount = 0
    this.restFileDisplay()
    while(start < end){
      var url = ''
      var type = ''
      var name = ''
      console.log(this.loadedFiles,this.loadedFiles[start])
      url = this.loadedFiles[start].url
      type = this.loadedFiles[start].type
      name = this.loadedFiles[start].name
      console.log(type,url)
      if(currCount === 0){   
        // document.getElementById('box1').setAttribute('style','')
        document.getElementById('image1').setAttribute('style','')
        document.getElementById('fileName1').setAttribute('style','')
        if(type.includes('image/')){
          document.getElementById('image1').setAttribute('src',url)
        }else{
          document.getElementById('image1').setAttribute('src','../../../assets/document.svg')
        }
        document.getElementById('x1').setAttribute('style','')
        this.fileName1 = name

      }else if(currCount === 1){
        document.getElementById('image2').setAttribute('style','')
        document.getElementById('fileName2').setAttribute('style','')
        if(type.includes('image/')){
          document.getElementById('image2').setAttribute('src',url)
        }else{
          document.getElementById('image2').setAttribute('src','../../../assets/document.svg')
        }
        this.fileName2 = name
        document.getElementById('x2').setAttribute('style','')
      }else if(currCount === 2){
        document.getElementById('image3').setAttribute('style','')
        document.getElementById('fileName3').setAttribute('style','')
        if(type.includes('image/')){
          document.getElementById('image3').setAttribute('src',url)
        }else{
          document.getElementById('image3').setAttribute('src','../../../assets/document.svg')
        }
        this.fileName3 = name
        document.getElementById('x3').setAttribute('style','')
      }else if(currCount === 3){  
        document.getElementById('image4').setAttribute('style','')
        document.getElementById('fileName4').setAttribute('style','')
        if(type.includes('image/')){
          document.getElementById('image4').setAttribute('src',url)
        }else{
          document.getElementById('image4').setAttribute('src','../../../assets/document.svg')
        }
        this.fileName4 = name
        document.getElementById('x4').setAttribute('style','')
      }
      currCount += 1  
      start += 1
    }
  }

  prevPage(){
    if(this.pageNumber > 0){
      this.pageNumber -= 1
      this.currentlySelected = (this.pageNumber*4) 
      // this.clearHighlightBox()
      this.displayNewFiles()
    }
  } 

  nextPage(){
    var end = Math.ceil(this.loadedFiles.length / 4)   
    if(this.pageNumber < end-1){
      this.pageNumber += 1
      this.currentlySelected = (this.pageNumber*4) 
      // this.clearHighlightBox()
      this.displayNewFiles()
    }
  }

  logTopic(){  
    if(this.topic === true){
      this.topic = false
      document.getElementById('dropShip').setAttribute('style','display:none')
    }else{
      this.subtopic = false
      this.topic = true
      document.getElementById('dropShip').setAttribute('style','')
    }
  }

  logSubtopic(){  
    if(this.subtopic === true){
      this.subtopic = false
      document.getElementById('dropShip').setAttribute('style','display:none')
    }else{
      this.subtopic = true
      this.topic = false
      document.getElementById('dropShip').setAttribute('style','')
    }
  }

  toggleCat(newCat){
    if(newCat === '') return
    if(this.selectedCats.includes(newCat)){
      this.selectedCats.splice(this.selectedCats.indexOf(newCat),1)
      document.getElementById(`${newCat}`+'Neck').setAttribute('class','alienNeck-1')
      document.getElementById(`${newCat}`+'Selected').setAttribute('src','../../../assets/catNotSelected.svg')
    }else{
      this.selectedCats.push(newCat)
      document.getElementById(`${newCat}`+'Neck').setAttribute('class','alienNeck')
      document.getElementById(`${newCat}`+'Selected').setAttribute('src','../../../assets/alienSelected.svg')
    }
  }

  dragOverHandler(ev) {      
    // console.log('File(s) in drop zone');
  
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  storeFile2(event){
    var files = event.target.files
    // var extension = files[0].type
    var TS = + new Date()
    var file:File = files[0];
    console.log(file)
    // var newFile = new storageFile('',TS,file.name,'','General','General',file
    this.loadedFiles.unshift({'url':URL.createObjectURL(file),'name':file.name,'type':file.type,'file':file})
    this.newFiles.unshift({'url':URL.createObjectURL(file),'name':file.name,'type':file.type,'file':file,'TS':TS,'UID':TS})
    this.displayNewFiles()
  }

  dropHandler(ev) {
    // Prevent default behavior (Prevent file from being opened)
    document.getElementById('drop_zone').setAttribute('class','group-458 border-1px-blue')
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      var TS = Number(+ new Date())
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        console.log(ev.dataTransfer.items[i])
        if (ev.dataTransfer.items[i].kind === 'file') {
          TS += 1
          var file:File = ev.dataTransfer.items[i].getAsFile();
          console.log(file)
          // var newFile = new storageFile('',TS,file.name,'','General','General',file
          this.loadedFiles.unshift({'url':URL.createObjectURL(file),'name':file.name,'type':file.type,'file':file})
          this.newFiles.unshift({'url':URL.createObjectURL(file),'name':file.name,'type':file.type,'file':file,'TS':TS,'UID':TS})
          // this.upload(file)
        }
      }
      this.displayNewFiles()
    } else {
      // Use DataTransfer interface to access the file(s)
      var TS = Number(+ new Date())
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        TS += 1
        var file:File = ev.dataTransfer.items[i].getAsFile();
        console.log(file)
        this.loadedFiles.unshift({'url':URL.createObjectURL(file),'name':file.name,'type':file.type})
        this.newFiles.unshift({'url':URL.createObjectURL(file),'name':file.name,'type':file.type,'file':file,'TS':TS,'UID':TS})
      }
      this.displayNewFiles()
    }
    console.log(this.imageThumbnails)
  }

  storeFile(event){
    this.dropHandler(event)
  }

  // up(){
  //   this.loadedFiles.forEach(file=>{
  //     this.upload(file.file,file.name,file.desc,file.topic,file.subtopic,file.file.type)
  //   })
  //   this.loadedFiles = []
  //   this.clearHighlightBox()
  //   this.restFileDisplay()
  //   this.closeMe()
  // }

  // convertPDF(file){
  //   getDocument(file).promise.then(doc=>{
  //     console.log(doc)
  //   })

  // }

  editNameOfFile(fileName,slotIndex){
    var loadedFilesIndex = (this.pageNumber * 4) + slotIndex
    this.loadedFiles[loadedFilesIndex].name = fileName
    console.log(this.loadedFiles[loadedFilesIndex].UID,fileName)
    this.nameChanges
    this.nameChanges[this.loadedFiles[loadedFilesIndex].UID] = fileName
    console.log(this.loadedFiles)
    if(slotIndex == 0){
      this.fileName1 = fileName
    }else if(slotIndex == 1){
      this.fileName2 = fileName
    }else if(slotIndex == 2){
      this.fileName3 = fileName
    }else if(slotIndex == 3){
      this.fileName4 = fileName
    }
  }

  addFiles(){
    this.newFiles.forEach(file=>{
      console.log(file)
    })
  }

  removeFiles(){

  }

  createLesson(){
    console.log(this.lessonName,this.selectedCats,this.loadedFiles,this.selectedCats.toString())
    var lessonUID = + new Date()
    var fileUID = Number(lessonUID)
    var holder = []
    this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/')
      .update({[lessonUID]:{'name':this.lessonName,'cats':this.selectedCats.toString(),'TS':lessonUID}})
      .then(()=>{
        this.loadedFiles.forEach(file=>{
          fileUID = fileUID + 1
          this.addLessonFile(file.file,file.name,fileUID,lessonUID)
          holder.unshift([fileUID,file.name,fileUID])
        })
      }).then(()=>{
        var updates = []
        updates.push(['NEW',{'TS':lessonUID,'name':this.lessonName,'UID':lessonUID,'files':holder,'cats':this.selectedCats.toString()}])
        this.updateLessonEmitter.emit(updates)
      })
  }

  addLessonFile(file,fileName,fileUID,lessonUID){
    this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${fileUID}`, file).then(e=>{
      this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' + lessonUID + '/files')
      .update({[fileUID]:{'name':fileName,'TS':fileUID}})
    })
  }

  async getFileImageURL(UID,TS){
    return await this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/personalFiles/' + UID + '/' + TS).getDownloadURL()
  }
  async getFileImageMetaData(UID,TS){
    return await this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/personalFiles/' + UID + '/' + TS).getMetadata().then(ne=>{
      return ne.contentType
    })
  }

  // need to add an outputer so that we can update the contents of the lesson emitters 
  // this way, when we update a value, it will be reflected in real time.

  // we also need to hadle delting a file 

  // and adding a new lesson to the list of lessons 

  // and maintain order
  updateLesson(){
    if(this.newFiles.length === 0 && this.deletedFiles.length === 0 && this.originalCats === this.selectedCats.toString() && this.originalName === this.lessonName && Object.keys(this.nameChanges).length === 0){
      this.closeMe()
      console.log('Skipped -> close me')
      return;
    }
    var TS = + new Date()
    var TSHold = TS + 0
    var dataPacket = [[TSHold,this.AUXIN[0]],[]]
    var updates = []
    var nameChange = false
    var catChange = false
    var deletedFile = false
    var fileRename = false

    if(this.originalName !== this.lessonName){
      updates.push(['TITLE',this.lessonName])
      nameChange = true
    }
    if(this.originalCats !== this.selectedCats.toString()){
      updates.push(['CATS',this.selectedCats.toString()])
      catChange = true
    }
    console.log(this.newFiles)
    this.newFiles.forEach(newFile=>{
      console.log(newFile)
      updates.push(['ADDFILE',newFile])
    })

    this.deletedFiles.forEach(file=>{
      deletedFile = true
      updates.push(['DELETEFILE',file])
    })
    

    var dp = {}

    for (const property in this.nameChanges) {
      fileRename = true
      TS += 1
      dp[property] = {'TS':TS,'name':`${this.nameChanges[property]}`}
      updates.push(['RENAMEFILE',{'TS':TS,'name':`${this.nameChanges[property]}`,'UID':property}])
    }
    dataPacket[1] = updates
    this.updateLessonEmitter.emit(dataPacket)

    this.newFiles.forEach(newFile=>{
      this.addLessonFile(newFile.file,newFile.name,newFile.UID,this.AUXIN[0])
    })
    if(deletedFile){
      this.deletedFiles.forEach(file=>{
        console.log(file)
        this.removeFilesFromDB(this.AUXIN[0],file.UID)
      })
    }
    
    if(nameChange && catChange){
      this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' + this.AUXIN[0])
      .update({'name':this.lessonName,'cats':this.selectedCats.toString(),'TS':TSHold})
    }else if(nameChange){
      this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' + this.AUXIN[0])
      .update({'name':this.lessonName,'TS':TSHold})
    }else if(catChange){
      this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' + this.AUXIN[0])
      .update({'cats':this.selectedCats.toString(),'TS':TSHold})
    }

    if(fileRename){
      this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' + this.AUXIN[0]+'/files')
      .update(dp)
      this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' + this.AUXIN[0])
      .update({'TS':TSHold})
    }
    
  }


  updateLesson2(){
    console.log(this.newFiles)
    var TS = + new Date()
    var dataPacket = [[TS,this.AUXIN[0]],[]]
    var updates = []
    var nameChange = false
    var catChange = false
    var deletedFile = false
    var fileRename = false

    if(this.originalName !== this.lessonName){
      updates.push(['TITLE',this.lessonName])
      nameChange = true
    }
    if(this.originalCats !== this.selectedCats.toString()){
      updates.push(['CATS',this.selectedCats.toString()])
      catChange = true
    }
    this.newFiles.forEach(newFile=>{
      updates.push(['ADDFILE',newFile])
    })

    this.deletedFiles.forEach(file=>{
      deletedFile = true
      updates.push(['DELETEFILE',file])
    })
    

    var dp = {}

    for (const property in this.nameChanges) {
      fileRename = true
      TS += 1
      dp[property] = {'TS':TS,'name':`${this.nameChanges[property]}`}
      updates.push(['RENAMEFILE',{'TS':TS,'name':`${this.nameChanges[property]}`,'UID':property}])
    }
    dataPacket[1] = updates
    this.updateLessonEmitter.emit(dataPacket)

    this.newFiles.forEach(newFile=>{
      TS +=1
      this.addLessonFile(newFile.file,newFile.name,TS,this.AUXIN[0])
    })
    if(deletedFile){
      this.deletedFiles.forEach(file=>{
        console.log(file)
        this.removeFilesFromDB(this.AUXIN[0],file.UID)
      })
    }
    
    if(nameChange && catChange){
      this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' + this.AUXIN[0])
      .update({'name':this.lessonName,'cats':this.selectedCats.toString()})
    }else if(nameChange){
      this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' + this.AUXIN[0])
      .update({'name':this.lessonName})
    }else if(catChange){
      this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' + this.AUXIN[0])
      .update({'cats':this.selectedCats.toString()})
    }

    if(fileRename){
      this.db.database.ref('providers/userInfo/' + this.UID + '/lessons/' + this.AUXIN[0]+'/files')
      .update(dp)
    }
    
  }

  upload($event,name,desc,topic,subtopic,type,TSHold){
    setTimeout(() => {
      this.path = $event
      var TS = TSHold
      console.log("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path)

      var key = this.UID + '-' + TS
      if(this.teacher){
        this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path).then(e=>{
          // if($event.type.includes('pdf')){
          //   e.ref.getDownloadURL().then(URL=>{
          //     this.convertPDF(URL)
          //   })
          // }
          this.db.database.ref('providers/userInfo/' + this.UID + '/storage').update({[key]:{'type':'PERSONAL','value':TS,'name':name,'desc':desc,'topic':topic,'subtopic':subtopic}}).then(()=>{
            this.db.database.ref('providers/userInfo/' + this.UID + '/').update({'newStorage':key +'/'+name + '/' + type})
          })
             
        })
      }else{
        this.afm.upload("/personalFiles/" + `${this.UID}` + '/' + `${TS}`, this.path).then(()=>{
          this.db.database.ref('users/' + this.UID + '/storage').update({[key]:{'type':'PERSONAL','value':TS,'name':name,'desc':desc,'topic':topic,'subtopic':subtopic}}).then(()=>{
            this.db.database.ref('users/' + this.UID + '/').update({'newStorage':key +'/'+name + '/' + type})
          })
        })
      }
    }, 0);    
  }

}
