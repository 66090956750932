export const environment = {
  production: true,
  firebaseConfig: {
        apiKey: "AIzaSyB2jRRhAeQbG-MqzZ_L71C08ko8KB0uMj4",
        authDomain: "v-t-dev.firebaseapp.com",
        databaseURL: "https://v-t-dev.firebaseio.com/",
        projectId: "v-t-dev",
        storageBucket: "v-t-dev.appspot.com",
        messagingSenderId: "1056890261822",
        appId: "1:1056890261822:web:be4eec4a1b1c3de2851c6f",
        measurementId: "G-63774201H0"
  },
  accountSid:'ACb7104826dcabeaa8d627989da70fcc91',
  authToken:'41d89b73098db6737eaebcb32928321d',
  stripeKey: 'pk_test_51HaROnJOldQTBUMIaqLLQGH53741oOsBOpkDW7S6q5nTGn3sQQXXZpPyArbf4kMDGJsZS8bxc3zlmya9jcMWr6yI00kwvrEmE1',
  stripeKeyS: 'sk_test_51HaROnJOldQTBUMIHDGn7xasBsFJIcqNhfjd0yhV6JjxkuQB066LxlrJqXO1fynTIamtg321Jqimw4b4ABKrztQf00oK4fntOM',
  stripeS: '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',
  stripeP: 'pk_live_51HaROnJOldQTBUMIIVaOPOtCri5wJVc3RILJkAXtfFQVPC0iCAam0eysstxIxP28P8o28CppMty22b9aLr4gwSC600B1cvVtxW',
  email: 'fred@verifiedteachers.com',
  pass: '*Derfderf527*',
  zoomAPIKey: 'FpYI8qrcS1Sw_D-jKOap7g',
  zoomAPIS: 'NhVq3txKBCI8ciZ7vTMQIwdmyPAAikFyzJvj'
  
};
