<div class="container-center-horizontal">
    <div class="landing screen" data-id="1430:5861">
      <div class="flex-row" data-id="an|lo0Y9bWD">
        <div class="flex-col" data-id="an|5mUKLayA">
          <div class="group-504" data-id="1430:5868">
            <h1 class="text-4 titles2 nunito-bold-abbey-39px" data-id="1430:5870">
              Lunches and Lessons: <br />Food Drive and Tutoring <br />by Verified Teachers
            </h1>
          </div>
          <p class="text-5 normalText sourcesanspro-normal-mine-shaft-16px" data-id="1430:5872">
            Save the date! On May 23, Verified Teachers is running our famous Lunches and Lessons event for the
            communities in West Los Angeles. Attendees will receive free meals, a free 45-minute lesson with one of our
            tutors, and a chance to meet the team behind Verified. If all of the slots are filled, send us an email at
            help@verifiedteachers and we'll get back to you ASAP!
          </p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfmkX269RX608CdVwL20TxT1SvD0TNz_yckrpTymMb-kBXhFg/viewform?usp=sf_link" target="_blank">
            <div class="frame-76" data-id="1430:5866">
              <div class="sign-up-now nunito-semi-bold-elephant-16px" data-id="1430:5867">Sign Up Now</div>
            </div>
          </a>

        </div>
        <div class="overlap-group6" data-id="an|26gNuTk7">
          <img
            class="group-512"
            data-id="1445:6070"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/group-512@2x.png"
          />
          <img
            class="rectangle-202"
            data-id="1435:5944"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/rectangle-202@2x.png"
          />
          <img
            class="hat"
            data-id="1445:6041"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/hat@1x.png"
          />
          <img
            class="hand"
            data-id="1445:6048"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/hand@1x.png"
          />
        </div>
      </div>
      <div class="rectangle-205" data-id="1445:5890"></div>
      <div class="overlap-group5" data-id="an|xFI0ez5u">
        <img
          class="vector"
          data-id="1439:6105"
          src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector@2x.png"
        />
        <div class="group-510" data-id="1445:5888">
          <div class="text-8 titles2 nunito-bold-abbey-39px" data-id="1435:5946">Local Communities Are Our Backbone</div>
          <div class="group-509" data-id="1445:5886">
            <div class="overlap-group2" data-id="an|vHJ133yK">
              <div class="overlap-group1" data-id="an|2xZy4Lyy">
                <div class="group-492" data-id="1439:5955">
                  <img
                    class="hat-1"
                    data-id="1439:5967"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/hat-2@1x.png"
                  />
                  <div class="group-508" data-id="1439:5988">
                    <img
                      class="vector-1"
                      data-id="1439:5987"
                      src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-13@2x.png"
                    />
                    <div class="overlap-group" data-id="an|orWgl1Pw">
                      <img
                        class="vector-2"
                        data-id="1439:5956"
                        src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-10@2x.png"
                      />
                      <img
                        class="vector-3"
                        data-id="1439:5957"
                        src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-11@2x.png"
                      />
                      <img
                        class="vector-4"
                        data-id="1439:5958"
                        src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-12@2x.png"
                      />
                      <img
                        class="hat-2"
                        data-id="1439:5975"
                        src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/hat-3@1x.png"
                      />
                    </div>
                    <img
                      class="vector-5"
                      data-id="1439:5985"
                      src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-14@2x.png"
                    />
                  </div>
                  <img
                    class="hat-3"
                    data-id="1439:5959"
                    src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/hat-1@1x.png"
                  />
                </div>
                <img
                  class="group"
                  data-id="1439:6111"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/group-1@2x.png"
                />
                <img
                  class="group-1"
                  data-id="1439:6168"
                  src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/group-5@2x.png"
                />
              </div>
              <img
                class="group-2"
                data-id="1439:6078"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/group@2x.png"
              />
              <img
                class="vector-6"
                data-id="1439:6095"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-1@2x.png"
              />
              <img
                class="vector-7"
                data-id="1439:6101"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-2@2x.png"
              />
              <img
                class="vector-8"
                data-id="1439:6103"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-3@2x.png"
              />
              <img
                class="vector-9"
                data-id="1439:6106"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-4@2x.png"
              />
              <img
                class="vector-10"
                data-id="1439:6109"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-5@2x.png"
              />
              <img
                class="vector-11"
                data-id="1439:6110"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-6@2x.png"
              />
              <img
                class="vector-12"
                data-id="1439:6134"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-7@2x.png"
              />
              <img
                class="group-3"
                data-id="1439:6157"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/group-2@2x.png"
              />
              <img
                class="group-4"
                data-id="1439:6159"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/group-3@2x.png"
              />
              <img
                class="group-5"
                data-id="1439:6163"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/group-4@2x.png"
              />
              <img
                class="vector-13"
                data-id="1439:6204"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-8@2x.png"
              />
              <img
                class="vector-14"
                data-id="1442:6245"
                src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-9@2x.png"
              />
            </div>
          </div>
          <div class="group-507" data-id="1439:5983">
            <div class="flex-col-1" data-id="an|6Ld7H8Du">
              <div class="fostering-education titles nunito-semi-bold-abbey-28px" data-id="1435:5949">Fostering Education</div>
              <p class="surname normalText sourcesanspro-normal-mine-shaft-16px" data-id="1435:5947">
                Good education starts with the relationship between teachers and students. That&#39;s why Verified is
                committed to connecting every student with the teacher that&#39;s right for them, no matter where they
                are.
              </p>
            </div>
            <div class="flex-col-2" data-id="an|rSxzyfDu">
              <div class="text-10 titles nunito-semi-bold-abbey-28px" data-id="1435:5950">Supporting Our Community</div>
              <p class="text-9 normalText sourcesanspro-normal-mine-shaft-16px" data-id="1435:5948">
                It&#39;s our turn to say thank you. We want to give back to the communities that have helped grow Verified
                Teachers into what it is today. We&#39;ll be offering free meals and lessons all season long, so keep your
                eyes peeled for more!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="rectangle-206" data-id="1445:5891"></div>
      <div class="overlap-group4" data-id="an|gek5yVFs">
        <div class="group-511" data-id="1445:5889">
          <img
            class="rectangle-203"
            data-id="1439:5952"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/rectangle-203@1x.png"
          />
          <div class="flex-col-3" data-id="an|zt9bdYd3">
            <div class="text-7 titles nunito-semi-bold-abbey-28px" data-id="1439:5951">
              If you can't make it but are still interested in free, premium tutoring from real K-12 teachers
              click the link below to sign up for our waitlist!
            </div>
            <a href = 'https://docs.google.com/forms/d/e/1FAIpQLSeDxfo1Cu_thf_j5ZpMzWC3e47ASZfxr1MhP7Pdrb-fyuWWAA/viewform?usp=sf_link'>
              <div class="frame-77" style = 'z-index: 1;cursor:pointer;'>
                <p class="text-6 nunito-semi-bold-elephant-16px" >Sign Up For Verified Teachers Today</p>
              </div>
            </a>

          </div>
        </div>
        <div class="overlap-group3" data-id="an|NIAPi7wo">
          <img
            class="group-157"
            data-id="1445:5893"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/group-157@2x.png"
          />
          <img
            class="group-155"
            data-id="1445:5910"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/group-155@2x.png"
          />
          <div class="ellipse-13" data-id="1445:5930"></div>
          <img
            class="vector-13-1"
            data-id="1445:5931"
            src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615b8dbe36d62e8a4b5a5fb7/img/vector-13-1@2x.png"
          />
        </div>
      </div>
  </div>
