<div class="container-center-horizontal">
  <div class="blog-post screen">
    <div class="overlap-group3">   
      <div class="overlap-group">
        <div class="rectangle-57"></div>
        <img
          id = '{{AUID}}'
          class="rectangle-106"
          src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/rectangle-106@2x.png"
        />
        <div class="frame-336">
          <img
            class="ellipse-18"
            src="../../../assets/dude.jpeg"
          />
          <div class="name body">Alexander Smith</div>
        </div>
        <div class="group-307">
          <h1 class="sep-11 h1">{{dateTop}}</h1>
          <div class="wednesday body">{{dateBottom}}</div>
        </div>
        <div class="frame-338">
          <div class="frame-339">
            <div class="text-3 h1">{{Title}}</div>
            <div class="frame-337">
              <div class="frame-321">
                <div class="folder">
                  <img
                    class="vector-1"
                    src="../../../assets/Vector.svg"
                  />
                </div>
                <div class="text-4 body">{{cat}}</div>
              </div>
              
            </div>
          </div>
          <div class="address body"> A {{readTime}} min read</div>
          <p id = 'box1' class="text-5 body">
           
          </p>
        </div>
        <p id = 'box2'class="text-6 body">

        </p>
        <div class="frame-332" style = 'z-index: 1;cursor: pointer;' routerLink="../../VerifiedBlog/Welcome">
          <div class="frame-323">
            <div class="frame-322">
              <div class="frame-321-1">
                <div class="folder">
                  <img
                    class="vector-1"
                    src="../../../assets/Vector.svg"
                  />
                </div>
                <div class="text- body">Educational Technology</div>
              </div>
              <!-- <div class="wed-september-11 body">Wed, September 11</div> -->
            </div>
            <img
              class="line-6"
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/line-6@2x.png"
            />
          </div>
          <div class="frame-325">
            <img
              class="rectangle-103"
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/rectangle-103@2x.png"
            />
            <div class="frame-328">
              <p class="text-8 h4">The Verified Classroom Is in Session</p>
              <div class="arrow-right">
                <div class="overlap-group1">
                  <img
                    src="../../../assets/arrowRight.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="frame-333"style = 'z-index: 1;cursor: pointer;' routerLink="../../VerifiedBlog/Welcome">
          <div class="frame-323">
            <div class="frame-322-1">
              <div class="frame-321-1">
                <div class="folder">
                  <img
                    class="vector-1"
                    src="../../../assets/Vector.svg"
                  />
                </div>
                <div class="text- body">Educational Technology</div>
              </div>
              <!-- <div class="wed-september-11-1 body">Wed, September 11</div> -->
            </div>
            <img
              class="line-6"
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/line-6@2x.png"
            />
          </div>
          <div class="frame-325">
            <img
              class="rectangle-103"
              src="../../../assets/Rectangle 103.png"
            />
            <div class="frame-328">
              <p class="text-1-1 h4">Our Newest Update Helps Teachers Tap Into</p>
              <div class="arrow-right">
                <div class="overlap-group1">
                  <img
                    src="../../../assets/arrowRight.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="frame-334"style = 'z-index: 1;cursor: pointer;' routerLink="../../VerifiedBlog/Welcome">
          <div class="frame-323">
            <div class="frame-322-1">
              <div class="frame-321-1">
                <div class="folder">
                  <img
                    class="vector-1"
                    src="../../../assets/Vector.svg"
                  />
                </div>
                <div class="text- body">Educational Technology</div>
              </div>
              <!-- <div class="wed-september-11-1 body">Wed, September 11</div> -->
            </div>
            <img
              class="line-6"
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/line-6@2x.png"
            />
          </div>
          <div class="frame-325">
            <img
              class="rectangle-103"
              src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/img/rectangle-103-1@2x.png"
            />
            <div class="frame-328">
              <p class="text-1-1 h4">The Verified Classroom Is in Session</p>
              <div class="arrow-right">
                <div class="overlap-group1">
                  <img
                    src="../../../assets/arrowRight.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p id = 'box3' class="text-13 body">
        </p>
        <p id = 'box4' class="text-14 body">
        </p>
      </div>
    </div>
  </div>
</div>