import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database'
import { AngularFireFunctions } from '@angular/fire/functions'
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { auth } from 'firebase';
import { student } from 'src/app/models/student';
import { receipt } from '../../models/receipt';
import moment from 'moment-timezone';

declare var gtag

@Component({ 
  selector: 'app-collab',     
  templateUrl: './collab.component.html',
  styleUrls: ['./collab.component.scss'] 
})
 
export class CollabComponent implements OnInit {
  @Input() parentUID:  string;
  @Input() teacherUID:  string;
  @Input() orderID:  string; 
  @Input() teacher: boolean
  childsName = ''
  // app should become the only input -- agre payload      
  @Input() app:any[] 
  @Input() UID:any;
  //***********
  @Output() currentID= new EventEmitter<any>();
  @Output() currentPass= new EventEmitter<any>();
  @Output() newTeacher = new EventEmitter<any>();
  @Output() currentExplain = new EventEmitter<any>();
  @Output() currentParentNote = new EventEmitter<any>();
  @Output() deleteMe = new EventEmitter<any>();
  slotPlaceHolder2 = "Date"
  slotPlaceHolder3 = "Year"
  slotPlaceHolder4 = "Start Time"
  slotPlaceHolder5 = "End Time"
  slotPlaceHolder6 = "Grade Level"

  displayName = ''

  listNum1 = []
  listNum2 = []
  reqDate = ''
  reqStartTime = ''
  reqEndTime = ''
  reqGradeLevel = '' 

  currentRefundRate = ''

  validDays1 = []
  zoomID = ""
  zoomPass = ""
  explainProblem = "" 
  parentNote = ""
  children = []
  currentChild:student
  readyToSendInfo = false
  childChosen = false;
  @Output() childChosen2 = new EventEmitter<any>();
  logging2: boolean;
  gradesList = ['1st-2nd','3rd-5th','6th-8th','9th-12th']
  err: string[];
  chosenServices: any;
  timeRequests = []
  date = ''
  
  constructor(private afm: AngularFireStorage,private database:AngularFireDatabase,private fns:AngularFireFunctions,private router:ActivatedRoute,private rerouter: Router) { }

  async ngOnInit() {
    //if this is actively being rescheudled 
    console.log(this.app)
    if(this.app[9] === 1){
      //do I have the talking stick?
      //if I do 1. show times being presented to me 2. allow me to counter offer
      //if i dont display a message about waiting
    }
    this.getDates()
    this.getTimesDef(500,2100)
    this.UID = this.router.snapshot.params.UID
    this.childChosen2.emit(false)
    var childrenHold = []
    var found = false;
    if(!this.teacher){
      await this.database.database.ref('users/' + this.UID + '/children').once('value').then(function(snap){
        if(snap.exists()){
          found = true
          snap.forEach(function(snapp){
            var name = snapp.child('name').child('val').val()
            var hobbies = snapp.child('hobbies').child('val').val()
            var favSub = snapp.child('favSub').child('val').val()
            var toughestSub = snapp.child('toughestSub').child('val').val()
            var funFact = snapp.child('funFact').child('val').val()
            var parentNote = snapp.child('parentNote').child('val').val()
            childrenHold.push(new student(name,hobbies,favSub,toughestSub,funFact,parentNote,snapp.key))
          })
        }else{
          //prompt to click the add child button
        }
        
      }).then(()=>{
        var nameHolder = ''
        this.database.database.ref('providers/userInfo/' + this.app[6] + '/immutable/name/val').once('value').then(function(snaper){
          nameHolder = snaper.val()
        }).then(()=>{
          this.displayName = nameHolder
          //get profile picture 
          this.getProPic(this.app[6]).then(()=>{
            this.date = this.app[0]
            this.date = this.date.charAt(0).toUpperCase() + this.date.slice(1)
            this.date = this.date.replace('-2021','')
            this.date = this.date.replace('-2022','')
            this.date = this.date.split(' ')[0] + ' ' + this.date.split(' ')[1] + ' ' + this.date.split(' ')[2].split('-')[0] + '-' + this.date.split(' ')[2].split('-')[1]
          })
          
        })
      }).finally(()=>{
        if(found){
          this.children = childrenHold  
        }
      })

      var appHold = this.app
      var snapHold
      await this.database.database.ref('users/' + `${this.app[2]}` + '/orders').once('value').then(function(snap){
        snapHold = snap
      })  
      var holdZoomID:any
      var holdZoomPass:any  
      var holdExplain
      var holdParentNote
      var chosenHold = false
      var childNameHold = ''
      await snapHold.forEach(function(snapp){
        if(snapp.child('orderUID').val() === `${appHold[7]}`){
          if(snapp.child('child').child('val').val() !== ""){
            chosenHold = true
            childNameHold = snapp.child('child').child('val').val()
          }
          holdZoomID = snapp.child('zoomID').child('val').val()
          holdZoomPass = snapp.child('zoomPass').child('val').val()
          holdExplain = snapp.child('intro').child('val').val()
          holdParentNote = snapp.child('parentNote').child('val').val()
        }
      })
      this.childsName = childNameHold
      this.childChosen = chosenHold
      this.zoomID = holdZoomID
      this.zoomPass = holdZoomPass
      this.currentExplain.emit(holdExplain)
      this.currentParentNote.emit(holdParentNote)
      this.currentID.emit(holdZoomID)
      this.currentPass.emit(holdZoomPass)
      
    }else{
      var appHold = this.app
      var snapHold
      await this.database.database.ref('providers/userInfo/' + `${this.app[6]}` + '/orders').once('value').then(function(snap){
        snapHold = snap
      }).then(()=>{
        var nameHolder = ''
        this.database.database.ref('users/' + this.app[2] + '/userInfo/name/val').once('value').then(function(snaper){
          nameHolder = snaper.val()
        }).then(()=>{
          this.displayName = nameHolder
          //get profile picture 
          this.getProPic(this.app[2]).then(()=>{
            this.date = this.app[8]
            this.date = this.date.charAt(0).toUpperCase() + this.date.slice(1)
            this.date = this.date.replace('-2021','')
            this.date = this.date.replace('-2022','')
            this.date = this.date.split(' ')[0] + ' ' + this.date.split(' ')[1] + ' ' + this.date.split(' ')[2].split('-')[0] + '-' + this.date.split(' ')[2].split('-')[1]
          })
        })
      })
      var holdZoomID:any
      var holdZoomPass:any  
      var holdExplain   
      var holdParentNote
      var chosenHold = false
      var childNameHold = ''
      await snapHold.forEach(function(snapp){
        if(snapp.child('orderUID').val() === `${appHold[7]}`){
          if(snapp.child('child').child('val').val() !== ""){
            chosenHold = true
            childNameHold = snapp.child('child').child('val').val()
          }
          holdZoomID = snapp.child('zoomID').child('val').val()
          holdZoomPass = snapp.child('zoomPass').child('val').val()
          holdExplain = snapp.child('intro').child('val').val()
          holdParentNote = snapp.child('parentNote').child('val').val()
        }
      })
      this.childsName = childNameHold
      this.currentExplain.emit(holdExplain)
      this.currentParentNote.emit(holdParentNote)
      this.currentID.emit(holdZoomID)
      this.currentPass.emit(holdZoomPass)
      this.childChosen = chosenHold
    }
    // var polHold = ''
    // await this.policy().then(val=>{
    //   polHold = String(100 - Number(val))
    // })
  //  if(polHold === '102'){

  //  }else{
  //   this.currentRefundRate = polHold
  //  }
    
  }

  addStudentProf(){   
    this.newTeacher.emit("")
  }

  explainUpdate(){
    this.explainInfo()
  }  

  async getProPic(UID){
    var QUID = this.app[10]
    // Create a reference from a Google Cloud Storage URI
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/teacherPics/' + UID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById('proPic' + QUID).setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      var key = ''
      var randomNum = Math.random()
      console.log(randomNum)
      if(Number(randomNum) === 1){
        randomNum =.98
      }
      var randomNumber = Math.floor(randomNum * 7) 
      if(randomNumber === 0){
        randomNumber = 1
      }
       
      if(randomNumber === 1){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png'
      }else if(randomNumber === 2){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-48@2x.png'
      }else if(randomNumber === 3){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-49@2x.png'
      }else if(randomNumber === 4){
        key = '../../../assets/Giraf.png'
      }else if(randomNumber === 5){
        key = '../../../assets/Cat.png'
      }else if(randomNumber === 6){
        key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-50@2x.png'
      }
      document.getElementById('proPic'+ QUID).setAttribute('src',`${key}`);
      // document.getElementById(`${teacherHold.UID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }

  async studentEmail(){
    var snapHold
    await this.database.database.ref('users/' + `${this.app[2]}` + '/orders').once('value').then(function(snap){
      snapHold = snap
    })

    var appHold = this.app
    var childHolder = this.currentChild
    await snapHold.forEach(function(snapp){
      if(snapp.child('orderUID').val() === `${appHold[7]}`){
        snapp.child('child').ref.set({val:`${childHolder.name}`})
      }
    })
    this.childChosen = true
    this.childChosen2.emit(true)
    const updateTeacher = this.fns.httpsCallable('updateParentRec')
    updateTeacher({
      UID:appHold[6],
      OrderID:appHold[7],
      File: 'child',
      Value: childHolder.name      
    }).toPromise()
    this.childsName = this.currentChild.name


    var subject = this.currentChild.name + '\'s information for your meeting on ' + `${this.app[0].split(' ')[0]}` + `${this.app[0].split(' ')[1]}`
    var msg = 'Here is '+ `${this.currentChild.name}` + '\'s information: \n Favorite subject:'+ `${this.currentChild.favSub}`+
     '\n. Their toughest subject: '+ `${this.currentChild.toughestSub}`+
     '\n. '+`${this.currentChild.name}` +'\'s fun fact: '+ `${this.currentChild.funFact}`+
     '\n.  Hobbies include: '+`${this.currentChild.hobbies}`+
     '\n.  And finally a word from our sponsors, a parent note: '+ `${this.currentChild.parentNote}`+
     '. We hope you have a great session :).'
    const newEmail = this.fns.httpsCallable('sendMail')
    await newEmail({
      address: this.app[4],
      subject: subject,
      msg:msg
    }).toPromise().then(()=> console.log("success")).catch((e)=> console.log('400',e))
  }

  setRegDate(val){
    this.reqDate = val
    this.slotPlaceHolder2 = this.dateFormat(val)
  }

  setStartTime(val){
    this.reqStartTime = val
    this.slotPlaceHolder4 = this.formatTime(val)
  }

  getDates(){ 
    var minDate = new Date(); 
    var count = 1 
    while(count < 100){
        var dd3 = String(minDate.getDate()).padStart(2, '0');
        var mm3 = String(minDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy3 = minDate.getFullYear();
        var todays3 =  yyyy3 + '-' + mm3 + '-' + dd3;
        this.validDays1.push(todays3)
        minDate = new Date(minDate.getTime() + ((1000 * 60 * 60 * 24)))
        count += 1
    }

  }

  getTimesDef(startTime,endTime){
    var currTime = startTime
    while(endTime >= currTime){
      if(currTime%100 ===5){
        currTime = currTime - 5
      }
      if(currTime%100 < 60){
        this.listNum1.push(currTime)
        this.listNum2.push(currTime)
      }
      currTime +=15
    }
    if(currTime%100 ===5){
      currTime = currTime - 5
      this.listNum1.push(currTime)
      this.listNum2.push(currTime)
    }
    
  }

  setEndTime(val){
    this.reqEndTime = val
    this.slotPlaceHolder5 = this.formatTime(val)
  }

  setGradeLevel(val:string){
    this.reqGradeLevel = val
    this.slotPlaceHolder6 = val
  }   


  async explainInfo(){
    var fnsHold = this.fns
    var appHold = this.app
    var explainHold = this.explainProblem
    await this.database.database.ref('users/' + `${this.app[2]}` + '/orders').once('value').then(function(snap){
      snap.forEach(function(snapp){
        if(snapp.child('orderUID').val() === `${appHold[7]}`){
          snapp.child('intro').ref.set({
            val: explainHold
          }).then(()=>{
            const updateTeacher = fnsHold.httpsCallable('updateParentRec')
            updateTeacher({
              UID:appHold[6],
              OrderID:appHold[7],
              File: 'intro',
              Value: explainHold        
            }).toPromise()
          })
        }
      })
    })
  }

  noteUpdate(){
    this.noteInfo()
  }

  requestRescheudle(){
    
  }

  async noteInfo(){
    var fnsHold = this.fns
    var appHold = this.app
    var parentHold = this.parentNote
    await this.database.database.ref('users/' + `${this.app[2]}` + '/orders').once('value').then(function(snap){
      snap.forEach(function(snapp){
        if(snapp.child('orderUID').val() === `${appHold[7]}`){
          snapp.child('parentNote').ref.set({
            val: parentHold
          }).then(()=>{
            const updateTeacher = fnsHold.httpsCallable('updateParentRec')
            updateTeacher({
              UID:appHold[6],
              OrderID:appHold[7],
              File: 'parentNote',
              Value: parentHold         
            }).toPromise()
          })
        }
      })
    })
  }

  async zoomUpdate(){
      await this.zoomInfo(this.zoomID,this.zoomPass)
      await this.zoomEmail()
    
  }


  displayChild(child){
    //
    this.currentChild = child
    this.readyToSendInfo = true
  
  }
 
  async zoomInfo(ID, PASS){
    var appHold = this.app
    var dbRef = this.database
    this.currentID.emit(ID) 
    this.currentPass.emit(PASS) 
    const updateTeacher = this.fns.httpsCallable('updateParentRec')
    await this.database.database.ref('users/' + `${this.app[2]}` + '/orders').once('value').then(function(snap){
      var dbRef2 =  dbRef 
      snap.forEach(function(snapp){
        if(snapp.child('orderUID').val() === `${appHold[7]}`){    
          dbRef.database.ref('users/' + `${appHold[2]}` + '/orders/'+ `${snapp.key}` + '/' + 'zoomID').set({
            val: ID
          }).then(()=>{
            dbRef2.database.ref('users/' + `${appHold[2]}` + '/orders/' + `${snapp.key}` + '/' + 'zoomPass').set({
              val: PASS
            }).then(()=>{
              updateTeacher({
                UID:appHold[6],
                OrderID:appHold[7],
                File: 'zoomID',
                Value: ID          
              }).toPromise().then(()=>{
                updateTeacher({
                  UID:appHold[6],
                  OrderID:appHold[7],
                  File: 'zoomPass',
                  Value: PASS         
                }).toPromise().catch((e)=> console.log('errin',e))
              }).catch((e)=> console.log('errout',e))
            })
          })
        }
      })
      })
     
    // await this.database.database.ref('providers/userInfo/' + `${this.teacherUID}` + '/' + `${this.app[7]}` + '/zoomID/val').set({
    //   val: ID
    // })
    // await this.database.database.ref('providers/userInfo/' + `${this.teacherUID}` + '/' + `${this.app[7]}` + '/zoomPass/val').set({
    //   val: PASS
    // })
  }
  
  async zoomEmail(){
    var subject = 'Zoom meeting updated for appointment with with ' + `${this.app[5]}` + ' on ' + `${this.app[0].split(' ')[0]}` + `${this.app[0].split(' ')[1]}`
    var msg = 'Your zoom meeting is all set up here are the login credentials -- Zoom Meeting ID: ' + this.zoomID  + ' Zoom Meeting Password: '  + this.zoomPass  +'. The meeting information is always accesable on your Verified Teachers profile page. We hope you have a great session :).'
    const newEmail = this.fns.httpsCallable('sendMail')
    await newEmail({
      address: this.app[1],
      subject: subject,
      msg:msg
    }).toPromise().then(()=>{
      newEmail({
        address: this.app[4],
        subject: subject,
        msg:msg
      }).toPromise().then(()=> console.log("success")).catch((e)=> console.log('400',e))
    }).catch((e)=> console.log('300',e))
  }

  dateFormat(day){
    var hold = day.split("-")
    var hold2 = day
    var day = hold[2]
    var month = hold[1]
    
    var nn = new Date(`${hold2}`)



    return this.getDay(nn.getDay())+ " " + this.getMonth(month) + " " +  day 
  }
  getDay(month){
    month = Number(month)
    if(month === 0){
      return "Mon"
    }else if(month === 1){
      return "Tue"
    }else if(month === 2){
      return "Wed"
    }else if(month === 3){
      return "Thu"
    }else if(month === 4){
      return "Fri"
    }else if(month === 5){
      return "Sat"
    }else if(month === 6){
      return "Sun"
    }
  }
  
  appointmentDate(date){
    if(date.includes('ASAP')){
      return 'ASAP'
    }
    var chuncks = date.split(' ')
    var date = chuncks[2].split('-')
    return chuncks[0] + ' ' + chuncks[1] + ' ' + date[0] + '-' + date[1] 
  }

  getMonth(month){
    month = Number(month)
    if(month === 1){
      return "Jan"
    }else if(month === 2){
      return "Feb"
    }else if(month === 3){
      return "Mar"
    }else if(month === 4){
      return "Apr"
    }else if(month === 5){
      return "May"
    }else if(month === 6){
      return "June"
    }else if(month === 7){
      return "July"
    }else if(month === 8){
      return "Aug"
    }else if(month === 9){  
      return "Sep"
    }else if(month === 10){
      return "Oct"
    }else if(month === 11){
      return "Nov"
    }else{
      return "Dec"  
    }
  }
  
  fixDate(month){
    if(month === 'Jan'){
      return 0
    }else if(month === 'Feb'){
      return 1
    }else if(month === 'Mar'){
      return 2
    }else if(month === 'Apr'){
      return 3
    }else if(month === 'May'){
      return 4
    }else if(month === 'Jun'){
      return 5
    }else if(month === 'Jul'){
      return 6
    }else if(month === 'Aug'){
      return 7
    }else if(month === 'Sep'){
      return 8
    }else if(month === 'Oct'){
      return 9
    }else if(month === 'Nov'){
      return 10
    }else if(month === 'Dec'){
      return 11
    }
  }

  // after 3 days we are into general policies AND longest grace is 2 Days
  daysSpread(date1: Date, date2:Date){
    var daysTil = 0
    while((date1.getMonth() !== date2.getMonth() || date1.getDate() !== date2.getDate() || date1.getFullYear() !== date2.getFullYear())){
      date1.setDate(date1.getDate() + 1);
      daysTil += 1
    }
    if((date1.getHours() *100) + date1.getMinutes() > (date2.getHours() *100) + date2.getMinutes()){
      daysTil -= 1
    }
    return daysTil
  }

  hoursSpread(date1: Date, date2:Date){
    var hoursTil = 0
    // if(this.teacher){
    //   var hold = date1
    //   date1 = date2
    //   date2 = hold
    // }
    while((date1.getMonth() !== date2.getMonth() || date1.getDate() !== date2.getDate() || date1.getFullYear() !== date2.getFullYear() || date1.getHours() !== date2.getHours()) ){
      date1.setTime(date1.getTime() + (60*60*1000));
      hoursTil += 1
    }
    if(date1.getMinutes() > date2.getMinutes()){
      hoursTil -= 1
    }
    return hoursTil
  }

  gracePeriod(date1: Date, date2:Date,gp:number){
    if(this.daysSpread(new Date(date1),new Date(date2)) <= 2){
      var hold = this.hoursSpread(new Date(date1),new Date(date2))
      if(hold < gp){
        return true
      }else{
        return false
      }
    }else{
      return false  
    }
  }

  async policy(){
    var appHold = this.app
    var time = ((this.app[10].split(" ")[4].split(':')[0])*100) + Number(this.app[10].split(" ")[4].split(':')[1])
    var timeZoneSet = this.app[12] * 100
    var date1 = new Date(Number(this.app[10].split(' ')[3]),Number( this.fixDate(this.app[10].split(' ')[1])), Number(this.app[10].split(' ')[2]))
    var adjTime
    if(time < timeZoneSet){
      date1.setDate(date1.getDate() - 1)
      var holdTime = timeZoneSet - time
      var dummyStart = 2400
      adjTime = dummyStart - holdTime
    }else{
      adjTime = time - timeZoneSet
    }
    date1.setHours(adjTime / 100,adjTime % 100)
    
    var date2 = new Date()
    var startDate = this.app[0].split(' ')[1]
    var date3 = new Date(Number(startDate.split('-')[2]),(Number(startDate.split('-')[0])-1),Number(startDate.split('-')[1]))
    date3.setHours(Number(this.app[0].split(' ')[2].split('-')[2]) / 100)
    date3.setMinutes(Number(this.app[0].split(' ')[2].split('-')[2]) % 100)
    
    var snapHold
          await this.database.database.ref('providers/userInfo/' + `${appHold[6]}` + '/policy').once('value').then(function(snap){
            snapHold = snap
          })
    var gp = await Number(snapHold.child('grace').child('val').val())
    if(date2 < date3){
      if(this.gracePeriod(new Date(date1), new Date(date2),gp)){
        //full refund
        return 0
      }else{
        if(this.daysSpread(new Date(date2), new Date(date3)) <= 2){
          var hours = this.hoursSpread(new Date(date2), new Date(date3))
          if(hours > 36){
            return await snapHold.child('generalFee').child('val').val()
          }else if(hours <= 36 && hours >= 24){
            return await snapHold.child('36_24').child('val').val()
          }else if(hours <= 23 && hours >= 12){
            return await snapHold.child('24_12').child('val').val()
          }else if(hours <= 11 && hours >= 3){
            return await snapHold.child('12_2').child('val').val()
          }else if(hours <= 2){
            return await snapHold.child('2_0').child('val').val()
          }
        }else{
          //standard policy
          return await snapHold.child('generalFee').child('val').val()
        }
      }
    }else{
      date2.setTime(date2.getTime() + (1*60*60*1000))
      if(date2 < date3){
        // put in a no show button
        return await snapHold.child('noShow').child('val').val()
      }else{
        // delete
        return 100
      }
      
    }
    

  }

  async delete(){
    var dbHold = this.database
    var teacherUID = this.app[6]
    var parentUID = this.app[2]
    var teacherEmail = this.app[1]
    var parentEmail = this.app[4]
    var uidHold = this.app[7]
    var totalHold = this.app[13]
    const deleteOrder = this.fns.httpsCallable('deleteOrder')
    if(this.teacher){
      await this.database.database.ref('providers/userInfo/' + `${teacherUID}`+ '/orders').once('value').then(function(snap:any){
        snap.forEach(function(snapp:any){
          if(snapp.child('orderUID').val() === uidHold ){
            dbHold.database.ref('providers/userInfo/'  + `${teacherUID}`+ '/orders/'+ `${snapp.key}`).remove()
          }
        })
      }).then(()=>{
        deleteOrder({
          Email:parentEmail,
          UID:parentUID,
          RequestUID:uidHold,
          Type: 'p'
        }).toPromise().then(()=>{
          gtag('event', 'refund', {
            transaction_id: `${uidHold}`,
            vale: `${totalHold}`
          });
        })
      })
    }else{
      await this.database.database.ref('/users/' + `${parentUID}`+ '/orders').once('value').then(function(snap:any){
        snap.forEach(function(snapp:any){
          if(snapp.child('orderUID').val() === uidHold ){
            dbHold.database.ref('/users/' + `${parentUID}`+ '/orders/'+ `${snapp.key}`).remove()
          }
        })
      }).then(()=>{
        deleteOrder({
          Email:teacherEmail,
          UID:teacherUID,
          RequestUID:uidHold,
          Type: 't'
        }).toPromise().then(()=>{
          console.log('succ')
        })
      })
    }

    var subject = 'An appointment was cancelled'
    var msg = 'Meeting with ' + `${this.app[5]}` + ' on ' + `${this.app[0].split(' ')[0]}` + `${this.app[0].split(' ')[1]}` + " has been canceled. the refund rate is" + `${this.currentRefundRate}`
    const newEmail = this.fns.httpsCallable('sendMail')
    await newEmail({
      address: 'fred.mendoza527@gmail.com',
      subject: subject,
      msg:msg
    }).toPromise().catch((e)=> console.log('300',e))

    this.deleteMe.emit(uidHold)
  }

  async reschedule(){
    if(this.teacher){
      console.log('teacher')
    }else{
      // console.log('parent')
      //check if this is fresh or not
      // console.log(this.app)
      if(Number(this.app[9]) === 0){
        //lock in refund rate
        var time = ((this.app[10].split(" ")[4].split(':')[0])*100) + Number(this.app[10].split(" ")[4].split(':')[1])
        var timeZoneSet = this.app[12] * 100
        if(time < timeZoneSet){
          var startDate = this.app[0].split(' ')[1]
          var adjDate = this.fixDate(this.app[10].split(' ')[1]) + " " + this.app[10].split(' ')[2] + " " + this.app[10].split(' ')[3]
          var incDate = new Date(Number(adjDate.split(' ')[2]),Number(adjDate.split(' ')[0]),Number(adjDate.split(' ')[1]))
          incDate.setDate(incDate.getDate() - 1)
          var endDate = new Date(Number(startDate.split('-')[2]),(Number(startDate.split('-')[0])-1),Number(startDate.split('-')[1]))
          var dayTil = 0 
          
          while(incDate.getMonth() !== endDate.getMonth() || incDate.getDate() !== endDate.getDate() || incDate.getFullYear() !== endDate.getFullYear()){
            incDate.setDate(incDate.getDate() + 1);
            dayTil += 1
          }
          if(dayTil > 3){
            var holdTime = timeZoneSet - time
            var dummyStart = 2400
            var adjTime = dummyStart - holdTime
            var startTime = this.app[0].split(' ')[2].split('-')[2]
            var incHour = new Date(Number(adjDate.split(' ')[2]),Number(adjDate.split(' ')[0]),Number(adjDate.split(' ')[1]))
            incHour.setDate(incHour.getDate() - 1)
            incHour.setHours(adjTime/100,adjTime%100)
            var endHour = new Date(Number(startDate.split('-')[2]),(Number(startDate.split('-')[0])-1),Number(startDate.split('-')[1]))
            endHour.setHours(startTime/100,startTime%100)
            var hoursTil = 0
            while(incHour.getMonth() !== endHour.getMonth() || incHour.getDate() !== endHour.getDate() || incHour.getFullYear() !== endHour.getFullYear() || incHour.getHours() !== endHour.getHours()){
              incHour.setTime(incHour.getTime() + (60*60*1000));
              hoursTil += 1
            }
            var minutes 
            if(incHour.getMinutes() > startTime%100){
              hoursTil -= 1
            }


          }else{
            //standard cancel policy unless we are in grace period
          }
          
        }else{

        }
      }else{

      }
    }
  }

  //rescheduler 
  async reqTime(){
    this.logging2 = true
    var database = this.database
    if(this.timeRequests.length > 0){
      if(auth().currentUser){
        var times = ''
        var count = 1
        await this.timeRequests.forEach(val=>{
          if (count === 1){
            times += val[4] 
          }else{
            times += +"||" + val[4] 
          }
          count += 1
        })
        const reqTime = this.fns.httpsCallable('reqTime')
        // reqTime({
        //   ParentUID:auth().currentUser.uid,
        //   TeacherUID:this.UID,
        //   TeacherEmail:'',
        //   ParentEmail:auth().currentUser.email,
        //   requestedTimes:times,
        //   services:this.chosenServices,
        //   grade:'',
        //   UID:this.UID
        // }).toPromise().then(()=>{
        //   database.database.ref('/users/' + `${auth().currentUser.uid}`+ '/requests').push({
        //     services:this.chosenServices,
        //     grade: '',
        //     times:times,
        //     ParentUID:auth().currentUser.uid,
        //     TeacherUID:this.UID,
        //     TeacherEmail:'',
        //     ParentEmail:auth().currentUser.email,
        //     agreed:{val:''}
        //   }).then(()=>{
        //     this.logging2 = false
        //   }).finally((()=>{
        //     this.logging2 = false
        //   }))
        // })
      }else{
        //make them login
        this.logging2 = false
      }
      

    }else{
      //error msg
      this.logging2 = false
    }
  }
  addReqTime(){ 
    var unique = true;
    if(this.reqDate !== '' && this.reqStartTime!== ''){
      var start = Number(this.app[0].split(' ')[2].split('-')[2])
      var end = Number(this.app[0].split(' ')[2].split('-')[3])
      var newVal = Number(this.reqStartTime)
      while(start < end ){
        start += 15 
        newVal += 15 
       if(start % 100 === 60 ){
         start = (Math.floor(start / 100)+1)*100
       }
       if(newVal % 100 === 60 ){
        newVal = (Math.floor(newVal / 100)+1)*100
      }
      }
      this.timeRequests.forEach(val =>{
        if(val[4] === this.reqDate + "-" +this.reqStartTime + "-" + newVal){
          unique = false
        }
      })
      if(unique){
        this.timeRequests.push([this.reqDate,this.reqStartTime,newVal,this.reqGradeLevel,this.reqDate + "-" +this.reqStartTime + "-" + newVal])
      }
    }else{
      //error
      var err = 'Looks like you forgot: '
      if(this.reqDate !== '' ){   
        err += 'a date'
      }
      if(this.reqStartTime!== ''){
        err += ', a startime'
      }
      if(this.reqEndTime !== ''){
        err += ', an end time'

      }
      if(this.reqGradeLevel !== ''){
        err += ', a grade level'
      }
      this.err = [err]
    }
  }

  clearReq(val2){
    this.timeRequests = this.timeRequests.filter(val => val[4] !== val2)
  }
  

  formatTime(num:number):string{
    if(num < 1200){
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ "am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + "am"
        }      
      } 
    }else{
      var holdVar = Math.floor(num/100)-12
      if(holdVar === 0){
        holdVar = 12
      }
      if(num%100 === 0){  
        return String(holdVar)+ "pm"
      }else{ 
        return String(holdVar)+":"+String(num%100) + "pm"
      }       

    }
  }
}
