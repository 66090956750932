<meta name="viewport" content="width=device-width, initial-scale=1">
<div class="container-center-horizontal noSelect">
  <div class="homepage screen">

    <!-- <div id = 'cartEditor' style="display: none;"> -->

      <!-- <app-cart-edit-popup style = 'position: fixed;
      left: 0;
      top: 0;
      width:100%;z-index: 103;' [config] = 'selectedTimeFrame | async' (close) = 'closeMe2()' (selectedTimes)="removeTime($event)" >
      </app-cart-edit-popup>
    </div>
    <span id = 'cartDropDownTotal' style="display: none;">
        <p style="display: inline-block;color: rgba(30, 86, 99, 1); font-family: var(--font-family-inter); font-weight: 500; font-size: 15px;padding-top: 14px;padding-left: 13px;">Total: ${{totalEmitter|async}}</p>
        <p *ngIf = 'cartItems.length > 0'(click)='pay()'style="    display: inline-block;
        color: white;
        font-family: var(--font-family-inter);
        font-weight: 500;
        font-size: 15px;
        position: absolute;
        top: 6px;
        background-color: rgba(30, 86, 99, 1);
        border-radius: 10px;
        padding: 8px;
        right: 13px;">Check Out</p>
    </span> -->
    <!-- <span id = 'cartDropDown' style="display: none;">
      <img *ngIf = 'cartItems.length === 0' src = '../../../assets/emptyCart.png' style="    width: 435px;
      margin-left: 12px;
      margin-top: 30px;">
      <p *ngIf = 'cartItems.length === 0' style="    text-align: center;
      margin-top: 22px;
      color: #C6C6C6;
      font-family: var(--font-family-inter);
      font-weight: 500;
      font-size: 17px;">Your Car Is Empty</p>
      <span *ngFor = 'let item of cartItems' style="width:445px;height:178px;display:block;background-color: #F3F3F3;border: 1px solid #C6C6C6;border-radius: 10px;padding: 10px;
      margin: 10px;">

      <div style="width:  402px;overflow: scroll;background-color: #C6C6C6;margin-left:10px;height: 60px;padding-left: 8px;border-radius: 13px;padding-top: 7px;" >
        <div style = "display: inline-flex;">
          <span *ngFor = "let range of item.dates.split('~')" (click)="updateTimeFrame(range)" style="border-radius: 6px;margin-right: 4px;background-color: #F3F3F3;border: 1px solid lightgrey;display: inline-block;width: 190px;">
            <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;font-size:15px;height: 22px;text-align: left;padding-left: 5px;padding-top: 4px;    overflow: scroll; white-space: nowrap;">{{formatDate(range.split('|-|')[0])}}</p>
            <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;font-size:15px;height: 22px;text-align: left;padding-left: 5px; white-space: nowrap;">{{formatHourMin(range.split('|-|')[0])}} - {{formatHourMin(range.split('|-|')[1])}}</p>
          </span>
        </div>

      </div>
      <span style="display:inline-block;vertical-align: top;">
        <span style="display:inline-block;margin-left: 5px;margin-top: 12px;">
          <span style="display: inline-block;width: 75px;">
            <img id = 'teacherPic3{{item.dates}}' src="'../../../../../assets/Giraf.png" style="width: 65px;height: 65px;padding:4px;border-radius: 40px;margin-left: 6px;mix-blend-mode: normal;object-fit: cover;">
            <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;font-size:14px;text-align: center;">
              {{item.TN}}
            </p>
          </span>
          <span style="display: inline-block;width: 75px;margin-left: 9px;">
            <img id = 'sProPic{{item.dates}}' src="'../../../../../assets/Giraf.png" style="width: 65px;height: 65px;padding:4px;border-radius: 40px;margin-left: 6px;mix-blend-mode: normal;object-fit: cover;">
            <p style="color: rgba(30, 86, 99, 1);font-family: var(--font-family-inter);font-weight: 500;font-size:14px;text-align: center;">
              {{item.SN}}
            </p>
          </span>
        </span>  
      </span>
      <span style="display:inline-block;vertical-align: top;">
        <p style="        color: rgba(30, 86, 99, 1);
        font-family: var(--font-family-inter);
        font-weight: 500;
        font-size: 13px;
        margin-top: 17px;
        margin-left: 17px;
        display: block;
        width: 232px;
        overflow: scroll;
        height: 40px;">
          {{item.M2T}}
        </p>
        <p style="    color: rgba(30, 86, 99, 1);
        font-family: var(--font-family-inter);
        font-weight: 500;
        font-size: 14px;
        margin-top: 23px;
        margin-left: 17px;">Total ${{item.total}}</p>
        <p (click) = 'openEditor(item)' style="    color: rgba(30, 86, 99, 1);
        font-family: var(--font-family-inter);
        font-weight: 500;
        font-size: 14px;
        margin-top: -14px;
        width: 31px;
        margin-left: 205px;
        text-align: right;;">Edit</p>
      </span>
      </span>

    </span>-->
    <div class="group-97">
      <img
        style="z-index: 100;cursor: pointer;"
        routerLink = ''
        class="image-1"
        src="https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/image-1@2x.png"
      />
      <div class="group-91" style="padding-left:48px">   
        <div class="blog worksans-medium-abbey-14px" routerLink = 'VerifiedBlog/Welcome'style="z-index: 100;cursor: pointer;">BLOG</div>
        <div class="teacher-sign-up worksans-medium-abbey-14px" routerLink = '/TeacherWelcome'style="z-index: 100;cursor: pointer;">TEACHER SIGN UP</div>
        <div class="promotions worksans-medium-abbey-14px" routerLink = 'EventPage'style="z-index: 100;cursor: pointer;">EVENT PAGE</div>
        <div class="faq worksans-medium-abbey-14px" routerLink = 'OrgSignUp'style="z-index: 100;cursor: pointer;">ENROLL</div>
      </div>

  </div> 
  <div id = 'dropship2' class="overlap-group221-1">
    <!-- put an ngfor here -->
    <div>
        <div *ngIf="auth.user$ | async; then authenticated else guest">
            <!-- template will replace this div -->
            </div>
        
            <!-- User NOT logged in -->
            
            <ng-template #guest>
            <!-- <div *ngIf="logging" >
                <div class="spinner-grow text-success" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-info" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div> -->
                <form>
                  
                    <div class="form-group">
                      <label for="exampleInputEmail1">Email Address</label>
                      <input ngModel name="email" #email ="ngModel" (change) = "storeEmail(email)" id ="email" type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <label for="exampleInputPassword1" style="color:#292F45;font-size: 25;">Password</label>
                      <input ngModel name="password" #password ="ngModel" (change) = "storePass(password)" id ="password" type="password" class="form-control">
                    </div>
                    <div class="overlap-group3-2 noSelect"  style="z-index: 1;cursor: pointer;"(click) = "emailSignIn()"><div class="sign-in">Sign In</div></div>
                    <!-- <div class="overlap-group3-2"  style="z-index: 1;cursor: pointer;"><div class="sign-in"(click) = "resetPass()" style="z-index: 1;cursor: pointer;">Reset Password</div></div> -->
                    
                  </form>
                  <div *ngFor = "let s of errorLogin" [s] = "s">
                    <div class = "txtBlock" style="padding-top:10px;text-align: center;">{{s}}</div>
                  </div>
                  <div (click) = "resetPass()" class="teacher-sign-up-1 worksans-semi-bold-chathams-blue-14px noSelect" style="width: 122px;z-index:1; cursor: pointer; "><p>Forgot Password</p></div>

            </ng-template>
        
            
        
            <!-- User logged in -->
            <ng-template #authenticated>
            <div *ngIf="auth.user$ | async as user">
                <p style="font-size: 20px;color:#292F45;">Welcome!<p>
                  <div class="overlap-group3-2"  style="z-index: 1;cursor: pointer;"(click) = "signOut()"><div class="sign-in">Logout</div></div>
            </div>
            </ng-template>
            <head>
                <script src="https://www.gstatic.com/firebasejs/ui/4.6.1/firebase-ui-auth.js"></script>
                <link type="text/css" rel="stylesheet" href="https://www.gstatic.com/firebasejs/ui/4.6.1/firebase-ui-auth.css" />
            </head>
    </div>
    </div>
  </div>
</div>
