import { Component, Input, OnInit } from '@angular/core';
import { receipt } from '../../models/receipt';

@Component({
  selector: 'app-booking-item',
  templateUrl: './booking-item.component.html',
  styleUrls: ['./booking-item.component.scss']
})
export class BookingItemComponent implements OnInit {

  constructor() { }

  @Input() app:receipt
  ngOnInit(): void {
    console.log("hello")
  }

}
