<div class="image"  style = "font-family: 'Avenir Light';">
    <h1 style = "text-align: center;margin: 0 auto;max-width: 1200px;font-size: 23px; padding: 5px;margin-bottom: 0px;  background-color: #292F45;color: white;border-top-left-radius: 25px; border-top-right-radius: 25px;">
        Post An Article
    </h1> 
    <div class="jumbotron jumbotron-fluid;" style="border-bottom-left-radius: 25px;border-bottom-right-radius: 25px ;max-width: 1200px;text-align: center;margin: 0 auto;;background-color:lightgray;border: 5px solid rgb(230,230,230)">

        <!-- <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Category</p></label>
          <input ngModel name="Category" #Category ="ngModel" (change) = "blogSwitch(Category.value,4)" id ="Category" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div> -->
        <div class="form-group" style="margin-top: -40px;">  
          <label><p style="text-align: center; font-size: 20px;">Title</p></label>
          <input ngModel name="TitleB" #TitleB ="ngModel" (change) = "blogSwitch(TitleB.value,0)" id ="TitleB" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Sub Title</p></label>
          <input ngModel name="SubTitleB" #SubTitleB ="ngModel" (change) = "blogSwitch(SubTitleB.value,1)" id ="SubTitleB" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Read Time</p></label>
          <input ngModel name="ReadTimeB" #ReadTimeB ="ngModel" (change) = "blogSwitch(ReadTimeB.value,2)" id ="ReadTimeB" type="text" class="form-control" style = "margin:0 auto;width: 85%;;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div>
<!--         
        <div class="form-group">  
          <label><p style="text-align: center; font-size: 20px;">Link For Youtube</p></label>
          <input ngModel name="Youtube" #Youtube ="ngModel" (change) = "blogSwitch(Youtube.value,6)" id ="Youtube" type="text" class="form-control" style = "margin:0 auto;width: 85%;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)">
        </div> -->
        <div>
          <label><p style="text-align: center; font-size: 20px;">Article Image</p></label>
        </div>
        <div class="custom-file" style = "max-width: 950px;margin-bottom: 20px;">
            <input type="file" class="custom-file-input" (change) = "blogSwitch($event,5)" accept = "*.png.jpg.pdf"id="inputGroupFile02">
            <label style=" font-size: 16px;margin:0 auto;text-align: center;background-color: #D8D8D8;" class="custom-file-label" for="inputGroupFile02"></label>
        </div>
        <div class="form-group">
          <label><p style="text-align: center; font-size: 20px;">Article Content</p></label>
          <p style="text-align: center; font-size: 16px;">*note: Everytime you type '||' it signals to start text on a new line.</p>
          <textarea id = 'article' ngModel name="article" #article ="ngModel" (change) = "blogSwitch(article.value,3)"minLength = "2" class="form-control" rows="3" style = "margin: 0 auto;width:980px;min-height: 250px; height:360px; min-height:100px;font-size:16px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230)" placeholder="Article" value = "" ></textarea> 
        </div>
        <div style="margin: 0 auto;max-width:990px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230);height: 230px;margin-bottom: 15px;">
          <div style="display:inline-block;vertical-align: top;">
            <img src={{url}} style="width: 250px; height:200px;margin-top: 10px;">
          </div>
          <div style = 'width:30px;display: inline-block;margin-left: 40px;'>
          </div>
          <div style="display:inline-block;width:65%;text-align: left;margin-top: 15px;">
            <p style = 'font-size: 14px;line-height: 1.1'>{{blogCat}}</p>
            <p style = 'font-size: 24px;line-height: 1.1'>{{blogTitle}}</p>
            <p style = 'font-size: 22px;line-height: 1.1'>{{blogSubTitle}}</p>
            <p style = 'font-size: 14px;line-height: 1.1'>{{date}} · {{blogReadTime}} min read </p>
          </div>
        </div>
        <div style="margin: 0 auto;max-width:990px;background-color: #D8D8D8;border: 5px solid rgb(230,230,230);height: 1000px;margin-bottom: 15px;overflow-y:auto;">
          <div style="margin:0 auto;width:67%;text-align: center;margin-top: 15px;">
            <p style = 'font-size: 14px;text-align: left;line-height: 1.1'>{{blogCat}}</p>
            <p style = 'font-size: 34px;text-align: left;line-height: 1.1'>{{blogTitle}}</p>
            <p style = 'font-size: 22px;text-align: left;line-height: 1.1'>{{blogSubTitle}}</p>
          </div>
          <div style="display:inline-block;vertical-align: top;">
            <img src={{url}} style="margin-top: 10px;width:67%;">
          </div>

          
          <div style="margin:0 auto;width:67%;text-align: left;">
            <img id = 'pro'src='{{url2}} ' style="border: 5px solid #D8D8D8;width:50px;height:50px; vertical-align: middle;border-radius: 55px;">
            <span>
                {{name}} - {{date}} · {{blogReadTime}} min read 
            </span>  
          </div>  
          <div style="margin: 0 auto;" *ngFor = "let p of paragraphs">
            <p style="font-size: 22px;padding: 5px; margin-bottom: 20px;width:67%;text-align: left;margin: 0 auto;line-height: 1.1">
              {{p}}
            </p>
          </div>
        </div>
        <button (click) = 'post2()' type="button" style="font-size: 16px;" class="btn btn-secondary btn-sm">POST</button>
        <div *ngIf = "yee" style="padding: 10px;">Posted!</div>
        <div *ngIf = "nah" style="padding: 10px;">All Fields Are Required.</div>
      </div>
</div>
