import { Component, Input, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { articleThumb } from 'src/app/models/articleThumb';

@Component({  
  selector: 'app-article-link',  
  templateUrl: './article-link.component.html',
  styleUrls: ['./article-link.component.scss']
})
export class ArticleLinkComponent implements OnInit {
  @Input() article:articleThumb
  date 
  
  constructor(private afm: AngularFireStorage) { 
    
  }  

  ngOnInit(){
    var articleHold = this.article
    var hold = this.article.AUID
    this.date = new Date(Number(hold)).toString()
    this.date = this.date.split(' ')[0] + ' ' + this.date.split(' ')[1] + ' ' + this.date.split(' ')[2]
    // Create a reference from a Google Cloud Storage URI
    console.log(articleHold.AUID)
    var storage = this.afm.storage.refFromURL('gs://v-t-dev.appspot.com/ArticleImages/' + articleHold.AUID)  
    //var storage = this.afm.storage.ref('teacherPics/' + this.teacher.UID + '.jpg');
    storage.getDownloadURL().then(function(url) {
      document.getElementById(`${articleHold.AUID}`).setAttribute('src',url);
    }).catch(function(error) {
      // Handle any errors
      document.getElementById(`${articleHold.AUID}`).setAttribute('src','../../../assets/noPic.png');
      document.getElementById(`${articleHold.AUID}`).setAttribute('style',"width:160px; height: 160px; margin-top: 15px;border-radius: 25px;border: 3px solid;border-color:grey;margin-bottom: 15px;background-color:#3a6da6");
    });
  }

}   
