import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { title_rate_time } from '../../models/title_rate_time';
import { MessengerService } from '../../services/messenger.service';
 
@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss']
})
export class AddToCartComponent implements OnInit { 
  items:title_rate_time[] = []
  @Input() order:title_rate_time
  @Output() insertTimes = new EventEmitter<any>();
  constructor(private msg: MessengerService) { }
 
  ngOnInit(): void {
  }

  putBackInList(){
    var ret:number[] = []
    var hold1 = this.order.getRawStart()
    ret.push(hold1)
    var curr = hold1
    var hold2 = this.order.getRawEnd()
    while(curr < hold2){
      curr = curr + 15
        if(curr%100 ===5){
          curr = curr - 5
        }
        if(Math.floor(curr%100) < 60){
          ret.push(curr)
        }
    }
    this.insertTimes.emit(this.order)
  }

}
