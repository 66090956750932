import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss']
})
export class LobbyComponent implements OnInit {
  public showWebcam = true;
  private trigger: Subject<void> = new Subject<void>();
  // public webcamImage: WebcamImage = null;
  public multipleWebcamsAvailable = false;    
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };  

  @Output() enterClass = new EventEmitter<any[]>();

  videoFeedOn = true
  audioFeedOn = true
  
  currentPecil = '../../../assets/vector-32@2x.svg'
  selectedPencil = '1'
  
  taskBarColor = '#F3F3F3'
  lastTaskBarChoice = 'circle1'

  selectedAvatar = '1'

  userAvatarImages = []    

  public errors: WebcamInitError[] = [];   
  
  constructor() { }
  
  async ngOnInit(){
    // document.getElementById('local').setAttribute('style','border-radius:5px;width:540px;overflow:hidden;')

    setTimeout(() => {
      document.getElementById('B1').setAttribute('style','display:none')
      document.getElementById('B2').setAttribute('style','z-index:1;cursor:pointer')
    }, 9500);
    // document.getElementById('flipFeed').setAttribute('style','-webkit-transform: scaleX(-1);transform: scaleX(-1);padding-left: 8px;')

    // let stream = null;

    // try {
    //   await navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(v=>{
    //     v.getAudioTracks().forEach(audioTrack=>{
    //       audioTrack.enabled
    //       console.log(audioTrack,audioTrack.enabled)
    //     })
    //     v.getVideoTracks().forEach(videoTrack=>{
    //       console.log(videoTrack)
    //     })
    //   })
      
    //   /* use the stream */
    // } catch(err) {
    //   /* handle the error */
    // }    
  }

  public get triggerObservable(): Observable<void> {   
    return this.trigger.asObservable();  
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }  

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  toggleLiveVideo(){
    this.videoFeedOn = !this.videoFeedOn
    if(this.videoFeedOn){
      this.showWebcam = true
    }else{
      this.showWebcam = false
    }
  }

  toggleLiveAudio(){
    this.audioFeedOn = !this.audioFeedOn
  }

  updateAvatar(key){
    if(key === 1){
      document.getElementById('avatarDisp').setAttribute('class','group-458 turtle')
      this.selectedAvatar = '1'
    }else if(key === 2){
      document.getElementById('avatarDisp').setAttribute('class','group-458 hippo')
      this.selectedAvatar = '2'
    }else if(key === 3){
      document.getElementById('avatarDisp').setAttribute('class','group-458 zebra')
      this.selectedAvatar = '3'
    }else if(key === 4){
      document.getElementById('avatarDisp').setAttribute('class','group-458 giraf')
      this.selectedAvatar = '4'
    }else if(key === 5){
      document.getElementById('avatarDisp').setAttribute('class','group-458 cat')
      this.selectedAvatar = '5'
    }else if(key === 6){
      document.getElementById('avatarDisp').setAttribute('class','group-458 owl')
      this.selectedAvatar = '6'
    }
  }

  firstRow(){   
    document.getElementById('avatarSlot1').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('avatarSlot2').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('avatarSlot3').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('avatarSlot4').setAttribute('style','display:none')
    document.getElementById('avatarSlot5').setAttribute('style','display:none')
    document.getElementById('avatarSlot6').setAttribute('style','display:none')
    
  }

  secondRow(){
    document.getElementById('avatarSlot1').setAttribute('style','display:none')
    document.getElementById('avatarSlot2').setAttribute('style','display:none')
    document.getElementById('avatarSlot3').setAttribute('style','display:none')
    document.getElementById('avatarSlot4').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('avatarSlot5').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('avatarSlot6').setAttribute('style','z-index:1;cursor:pointer;')
    
  }

  EnterClassRoom(){
    this.enterClass.emit([this.selectedAvatar,this.taskBarColor,this.selectedPencil])
  }


  logBarColor(num){
    if(num === 1){
      this.taskBarColor = '#F3F3F3'
    }else if(num === 2){
      this.taskBarColor = '#FFFFFF'  
    }else if(num === 3){
      this.taskBarColor = '#CDF2AF'
    }else if(num === 4){
      this.taskBarColor = '#1E5663'
    }else if(num === 5){
      this.taskBarColor = '#F6F392'
    }else if(num === 6){
      this.taskBarColor = '#D2648C'
    }else if(num === 7){
      this.taskBarColor = '#6B5177'
    }else if(num === 8){
      this.taskBarColor = '#4B4B4B'
    }
    document.getElementById(this.lastTaskBarChoice).setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('circle' + `${num}`).setAttribute('style','z-index:1;cursor:pointer;border: 4px solid var(--chathams-blue);')
    this.lastTaskBarChoice = 'circle' + `${num}`
  }

  togglePen(key,image){
    this.currentPecil = image
    this.selectedPencil = key
    console.log(image,'z-index: 1;cursor: url('+`${image}`+'), auto ;')
    document.getElementById('pens').setAttribute('style','z-index: 1;cursor: url("'+`${image}`+'"), auto ;')
    console.log(this.currentPecil)
  }
}
