import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-student-account',
  templateUrl: './create-student-account.component.html',
  styleUrls: ['./create-student-account.component.scss']
})
export class CreateStudentAccountComponent implements OnInit {
  studentName = ''
  favoriteSubject = ''
  toughestSubject = ''
  hobbies = ''
  gradeLevel = ''
  path
  parentNote = ''   

  uploaded = false

  profPic

  backgroundColor = ''
  alien = ''
  backgroundImage = ''
  profilePicture

  UID


  @Output() closeMeEmitter = new EventEmitter<any>();
  @Output() newStudentEmitter = new EventEmitter<any>();
  @Output() updateAlienColorEmitter = new EventEmitter<any>();
  @Output() updatePageBackgroundEmitter = new EventEmitter<any>();
  @Output() showButton = new EventEmitter<any>();


  constructor(private db: AngularFireDatabase,private afm: AngularFireStorage,private router:ActivatedRoute) { }
 
  async ngOnInit(){
    var valHold = ['','','','']
    var randNums = []
    this.UID = await this.router.snapshot.params.UID
    valHold.forEach(()=>{
      var randomNum = Math.random()
      console.log(randomNum)
      if(Number(randomNum) === 1){
        randomNum =.98
      }
      var randomNumber = Math.floor(randomNum * 7) 
      if(randomNumber === 0){
        randomNumber = 1
      }
      randNums.unshift(randomNumber)
    })
    
    var count = 0
    randNums.forEach(val=>{
      console.log(val)
      count += 1
      if(count === 1){
        this.randomBackgroundColor(val)
      }else if(count === 2){
        this.randomAlien(val)
      }else if(count === 3){
        this.updatePageBackground(String(val))
        this.backgroundImage = (String(val))
      }else if(count === 4){
        this.profPic = val
        this.randomProfilePic(val)
      }
    })
  }

  randomBackgroundColor(val){
    var key = ''
    if(val === 1){
      key = 'FFFFFF-F3F3F3-2A8499'
      this.updateBackgroundColor(key)
    }else if(val === 2){
      key = 'F2FEFF-C6DFE1-81CBD1'
      this.updateBackgroundColor(key)
    }else if(val === 3){
      key = 'F8F2FF-DAD4E1-C1A1E8'
      this.updateBackgroundColor(key)
    }else if(val === 4){
      key = 'FEFFD1-DADBA5-ECEF73'
      this.updateBackgroundColor(key)
    }else if(val === 5){
      key = 'EDFFD0-D2E2B8-B4F152'
      this.updateBackgroundColor(key)
    }else if(val === 6){
      key = 'FFF1F8-DCBACB-F398C6'
      this.updateBackgroundColor(key)
    }
    this.backgroundColor = key
  }

  randomAlien(val){
    var key = ''
    if(val === 1){
      key = 'pink'
      this.updateAlienColor(key)
    }else if(val === 2){
      key = 'green'
      this.updateAlienColor(key)
    }else if(val === 3){
      key = 'purple'
      this.updateAlienColor(key)
    }else if(val === 4){
      key = 'yellow'
      this.updateAlienColor(key)
    }else if(val === 5){
      key = 'blue'
      this.updateAlienColor(key)
    }else if(val === 6){
      key = 'orange'
      this.updateAlienColor(key)
    }
    this.alien = key
  }


  randomProfilePic(val){
    var key = ''
    if(val === 1){
      key = 'https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/615fbf0738caca957c9982a3/img/rectangle-47@2x.png'
    }else if(val === 2){
      key = '../../../assets/Frog.svg'
    }else if(val === 3){
      key = '../../../assets/Hippo.svg'
    }else if(val === 4){
      key = '../../../assets/Giraf.svg'
    }else if(val === 5){
      key = '../../../assets/Cat.svg'
    }else if(val === 6){
      key = '../../../assets/zebra.svg'
    }
    this.path = val
    document.getElementById('proPic2').setAttribute('src',`${key}`)
    this.profilePicture = key
  }



  closeMe(){
    this.closeMeEmitter.emit('')
  }

  updateName(name){
    this.studentName = name
    if(this.studentName.length > 0 && this.gradeLevel.length > 0 ){
      document.getElementById('editButton1').setAttribute('style','cursor: pointer;')
    }
    console.log(name)
  }

  updateGradeLevel(gradeLevel){
    this.gradeLevel = gradeLevel
    if(this.studentName.length > 0 && this.gradeLevel.length > 0 ){
      document.getElementById('editButton1').setAttribute('style','cursor: pointer;')
    }
    console.log(gradeLevel)
  }

  storeFile($event){
    this.uploaded = true
    this.path = $event.target.files[0]
    document.getElementById('proPic2').setAttribute('src',URL.createObjectURL(this.path));
  }

  updateBackgroundColor(hexValue){   
    console.log(hexValue)
    this.clearOtherBackgroundColors()
    this.backgroundColor = hexValue
    document.getElementById(hexValue).setAttribute('style','z-index:1;cursor:pointer;border: 2px solid var(--chathams-blue);')

  }

  clearOtherBackgroundColors(){
    document.getElementById('FFFFFF-F3F3F3-2A8499').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('F2FEFF-C6DFE1-81CBD1').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('F8F2FF-DAD4E1-C1A1E8').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('FEFFD1-DADBA5-ECEF73').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('EDFFD0-D2E2B8-B4F152').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('FFF1F8-DCBACB-F398C6').setAttribute('style','z-index:1;cursor:pointer;')

  }
  
  updateAlienColor(newColor){
    console.log(`${newColor}`+'AlienSelected2')
    this.alien = newColor
    this.updateAlienColorEmitter.emit(newColor)
    this.clearAlienSelect()
    document.getElementById(`${newColor}`+'AlienSelected2').setAttribute('class','alienSelected')
  }

  clearAlienSelect(){
    document.getElementById('pinkAlienSelected2').setAttribute('class','alienNotSelected')
    document.getElementById('yellowAlienSelected2').setAttribute('class','alienNotSelected')
    document.getElementById('greenAlienSelected2').setAttribute('class','alienNotSelected')
    document.getElementById('blueAlienSelected2').setAttribute('class','alienNotSelected')
    document.getElementById('purpleAlienSelected2').setAttribute('class','alienNotSelected')
    document.getElementById('orangeAlienSelected2').setAttribute('class','alienNotSelected')
  }

  uploadProfilePicture(){
    document.getElementById('inputGroupFile01').click()
  }

  updatePageBackground(imageIndex){
    console.log(imageIndex)
    this.clearBackgroundSelected()
    if(imageIndex === '1'){
      document.getElementById('backgroundBorder1').setAttribute('src','../../../assets/backgroundSelected.svg')
    }else if(imageIndex === '2'){
      document.getElementById('backgroundBorder2').setAttribute('class','overlap-group13-1')
    }else if(imageIndex === '3'){
      document.getElementById('backgroundBorder3').setAttribute('class','overlap-group10-1')
    }else if(imageIndex === '4'){
      document.getElementById('backgroundBorder4').setAttribute('class','overlap-group-5-1')
    }else if(imageIndex === '5'){
      document.getElementById('backgroundBorder5').setAttribute('class','overlap-group11-1')
    }else if(imageIndex === '6'){
      document.getElementById('backgroundBorder6').setAttribute('class','overlap-group-5-1')
    }
    this.backgroundImage = imageIndex
  }

  clearBackgroundSelected(){
    document.getElementById('backgroundBorder1').setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/6207273ea435ea8ee11cd95d/img/unsplash-omnchykdhp8-2@1x.png')
    document.getElementById('backgroundBorder2').setAttribute('class','overlap-group13')
    document.getElementById('backgroundBorder3').setAttribute('class','overlap-group10')
    document.getElementById('backgroundBorder4').setAttribute('class','overlap-group-5')
    document.getElementById('backgroundBorder5').setAttribute('class','overlap-group11')
    document.getElementById('backgroundBorder6').setAttribute('class','overlap-group-5')
  }
  
  async createStudent(){
    this.UID = await this.router.snapshot.params.UID
    var SUID = + new Date()
    //have to upload image first so we have store the DB REF
    var proPicRef = `${SUID+1}`
    if(this.uploaded){
      this.afm.upload("/proPicS/" + proPicRef, this.path).then(()=>{
        var newStudentProfile = {'profile':{'alien':this.alien,'proColor':this.backgroundColor,'backImg':this.backgroundImage,'proPic':proPicRef,'name':this.studentName},'info':{'toughSub':this.toughestSubject,'favSub':this.favoriteSubject,'grade':this.gradeLevel,'hobbies':this.hobbies,'note':this.parentNote}}
        this.db.database.ref('users/'+ `${this.UID}` +'/students/').update({[SUID]:newStudentProfile})
        .then(()=>{
          this.newStudentEmitter.emit({'SUID':SUID,'alien':this.alien,'proColor':this.backgroundColor,'backImg':this.backgroundImage,'proPic':proPicRef,'name':this.studentName,'toughSub':this.toughestSubject,'favSub':this.favoriteSubject,'grade':this.gradeLevel,'hobbies':this.hobbies,'note':this.parentNote})
        })
      })
    }else{
      var newStudentProfile = {'profile':{'alien':this.alien,'proColor':this.backgroundColor,'backImg':this.backgroundImage,'proPic':this.profPic,'name':this.studentName},'info':{'toughSub':this.toughestSubject,'favSub':this.favoriteSubject,'grade':this.gradeLevel,'hobbies':this.hobbies,'note':this.parentNote}}
      this.db.database.ref('users/'+ `${this.UID}` +'/students/').update({[SUID]:newStudentProfile})
      .then(()=>{
        this.newStudentEmitter.emit({'SUID':SUID,'alien':this.alien,'proColor':this.backgroundColor,'backImg':this.backgroundImage,'proPic':this.profPic,'name':this.studentName,'toughSub':this.toughestSubject,'favSub':this.favoriteSubject,'grade':this.gradeLevel,'hobbies':this.hobbies,'note':this.parentNote})
      })
    }
  }


}
