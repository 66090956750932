import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import {AuthService} from '../../services/auth.service'
import {AngularFireDatabase} from '@angular/fire/database'
import {AngularFireStorage} from '@angular/fire/storage'
import {ActivatedRoute, Router} from '@angular/router'
import {AngularFireFunctions} from '@angular/fire/functions'
 

 
@Component({
  selector: 'app-teacher-form',
  templateUrl: './teacher-form.component.html',
  styleUrls: ['./teacher-form.component.scss']
})  

export class TeacherFormComponent implements OnInit {  
  path:string = "";    
  uploaded = false
  notSent = true
  logging = false
  currentUID:string = "";
  signInDidNotWork:boolean;
  suggestions: string= "";
  services: string[] = [];
  grades: string[] = [];
  days: string[] = [];
  shortDesc: string= "";
  longDesc: string= "";
  fun: string= "";
  emailP: string= "";
  password:string= "";
  lastName:string= "";
  emailS:string= "";
  license:string= "";
  falseTry:boolean;
  accept = false
  

  constructor(private router:Router,private router2:ActivatedRoute,private auth: AuthService, private db: AngularFireDatabase, private afm: AngularFireStorage,private fns:AngularFireFunctions) {  }
  @Output() name = new EventEmitter<any>();
  ngOnInit(): void {
    if(this.router2.snapshot.params.NAME){
      console.log(this.router2.snapshot.params.NAME)
      this.fun = this.router2.snapshot.params.NAME
      setTimeout((()=>{
        this.name.emit(this.router2.snapshot.params.NAME)

      }),500)
    }
  }

  storeNgModel(msg:NgModel){
    console.log(msg.value);
  }

  log2(){
    
  }
  //name, school, uid, emailP, emailS
  async storeInfo(){

    if(this.formComplete()){
      this.notSent = false
      this.logging = true
      const newTeacherEmail = this.fns.httpsCallable('newTeacherEmail')
      await newTeacherEmail({
        name: this.fun,
        school:this.lastName,
        uid:'',
        emailP:this.emailS,
        emailS:Intl.DateTimeFormat().resolvedOptions().timeZone
      }).toPromise().then(()=> console.log('success')).catch((e)=> console.log('err',e)).finally(()=>this.logging = false)
      await this.storePublicInfo()
    }else{
      this.falseTry = true 
      this.notSent = true
      this.logging = false
    }
    
    
  }

  adjustSwitch(){
    this.accept = !this.accept
  }

  // make sure all vars that are gonna be stored have values
  formComplete():boolean{
    if(
      (this.path != null && this.path !== "") &&
      // this.services.length > 0 &&
      // this.grades.length > 0 &&
      // this.days.length > 0 &&
      // this.shortDesc !== ""&&
      this.longDesc !== ""&&
      this.fun !== ""&&
      this.emailP !== ""&&
      this.password !== ""&&
      this.lastName !== ""&&
      this.emailS !== ""&&
      // this.license!== ""&&
      this.accept === true
      ){  
        return true

    }else{
      return false;
    }
  }

  upload($event){
    this.path = $event.target.files[0]
    this.uploaded = true
  }


  async storePublicInfo(){
    console.log(1)
    this.auth.storeEmail(this.emailP)
    this.auth.storePassword(this.password)
    this.auth.emailSignUp().then(val =>{
      console.log(2)
      if(val !== null){
        console.log(3)
        // var holdServices = "|"
        // this.services.forEach(val =>{holdServices += val + "|"})
        // var holdGrades = "|" 
        // this.grades.forEach(val =>{holdGrades += val + "|"})
        // var holdDays = "|"
        // this.days.forEach(val =>{holdDays += val + "|"})
        // this.storeInfo()
        //******* */
        this.afm.upload("/applied/" + val, this.path)
        var providers = this.db.database.ref('waitingToBeChecked')
        console.log(val)
        console.log(providers)
        providers.push(
          {
            val:{ 
              // service: holdServices,
              // grades: holdGrades,
              // days: holdDays,
              // shortDesc:this.shortDesc,
              //********* */
              longDesc: this.longDesc,
              fn: this.fun,
              school: this.lastName,
              emailP:this.emailP,
              emailS: this.emailS,
              timeZone: new Date().getTimezoneOffset()/60,
              location: Intl.DateTimeFormat().resolvedOptions().timeZone,
              // license:this.license,
              uid:val
            }
            
          }
        ).then(()=>{
          this.router.navigateByUrl('/welcome')
        })
      }
    }).catch((e)=>{
      this.signInDidNotWork = true
    })
  }
 
  storeGrade(gradeLevel:string){
    if(this.gradeOn(gradeLevel)){
      this.grades.push(gradeLevel)
    }else{
      this.grades = this.grades.filter(val =>val !== gradeLevel)
    }
  }

  gradeOn(gradeLevel:string):boolean{
    var hold = true
    this.grades.forEach(grade=>{
      if(grade === gradeLevel){
        hold =  false
      }
    })
    return hold;
  }
  storeDay(day:string){
    if(this.dayOn(day)){
      this.days.push(day)
    }else{
      this.days = this.days.filter(val =>val !== day)
    }
  }

  dayOn(day:string):boolean{
    var hold = true
    this.days.forEach(serv=>{
      if(serv === day){
        hold =  false
      }
    })
    return hold;
  }

  storeService(serviceTitle:string){
    if(this.serviceOn(serviceTitle)){
      this.services.push(serviceTitle)
    }else{
      this.services = this.services.filter(val => val !== serviceTitle)
    }
  }

  serviceOn(service:string):boolean{
    var hold = true
    this.services.forEach(serv=>{
      if(serv === service){
        hold = false;
      }
    })
    return hold;
  }

}
