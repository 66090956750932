import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import {Router} from '@angular/router'
import {AngularFireDatabase} from '@angular/fire/database'
import {AngularFireStorage} from '@angular/fire/storage'
import {AngularFireAuth} from '@angular/fire/auth'
import {AuthService} from '../../services/auth.service';
import moment from 'moment-timezone';
import { S } from '@angular/cdk/keycodes';



@Component({
  selector: 'app-ac',
  templateUrl: './ac.component.html',
  styleUrls: ['./ac.component.scss']
})
export class AcComponent implements OnInit {
  @Output() admin = new EventEmitter<boolean>();

  uidFor = "";
  UID2 = ''
  UID3 = ''
  firstName = "";
  timeZone = "";
  formal = "";
  path = ''   
  UID232 = ''
//
  appointmentTUID = ''
  appointmentTName = ''
  appointmentTEmail = ''

  appointmentSName1 = ''
  appointmentSUID1 = ''
  appointmentPEmail1 = ''
  appointmentPUID1 = ''

  appointmentSName2 = ''
  appointmentSUID2 = ''
  appointmentPEmail2 = ''
  appointmentPUID2 = ''

  monday = ''
  tuesday = ''
  wednesday = ''
  thursday = ''
  friday = ''
  saturday = ''
  sunday = ''

//

  SUTeacherName = ''
  SUParentName = ''
  SUDATE = ''
  SUPUID = ''
  SUTUID = ''

  ambasAccountInfo = ''
  ambassEmail = ''
  ambassName = ''

  youtubeCode = "empty";
  blogCat = ''
  blogTitle = ''
  blogSubTitle = '' 
  blogImagePath = ''
  blogArticle = ''
  blogReadTime = ''
  url: string | ArrayBuffer = '';

  youtubeCodeCamp = "empty";
  campTitle = ''
  campSubTitle = '' 
  campImagePath = ''
  campArticle = ''
  campurl: string | ArrayBuffer = '';
  @Output() paragraphsCamp1 = new EventEmitter<any[]>();
  @Output() paragraphsCamp2 = new EventEmitter<any[]>();
  currentLink = []
  currentCode = []

  grades = ''
  currentTitles = ''
  UID = ''


  service = ""
  grade = ""
  day = ""
  UID4: string;

  AUID = ''
  Question = ''
  TimeStamp = ''
  qTopic = ''
  qGrade = ''
  qSubTopic = ''  
  paragraphs = []

  date 

  constructor(private router:Router, private db: AngularFireDatabase,private afm: AngularFireStorage,public auth: AuthService,private afAuth: AngularFireAuth) { }

  async ngOnInit(){
    // console.log(this.makeRandom(12))
    // console.log(moment().format())
    // console.log(moment().tz('America/New_York').format())
    // console.log(moment().format())
    // console.log(moment().tz('America/New_York').utc().format())
    // console.log(+ moment())
    
    var startMoment = moment('2022-02-24T15:30').utc()
    console.log(startMoment)
    var hol = this.admin
    var routeHold = this.router
    await this.auth.user$.subscribe(val=>{
      if(val !== null){
        this.db.database.ref('admins/' + val.uid).once('value').then(function(snap){
          if(snap.val() === null){
            routeHold.navigateByUrl('#')
          }else{
            hol.emit(true)
          }
        })
      }else{
        this.router.navigateByUrl('#')  
      }
         
    })
    this.date = new Date().toString()
    this.date = this.date.split(' ')[0] + ' ' + this.date.split(' ')[1] + ' ' + this.date.split(' ')[2]
  }
    
  bookSession(parentUID,teacherUID){
    // console.log(this.SUPUID,this.SUTUID)
    parentUID = this.SUPUID
    teacherUID = this.SUTUID
    var TS2 =  + new Date()
    var returnList2 = {date:'ASAP', teacherName:this.SUTeacherName,total:40, teacherUID:teacherUID,teacherEmail:'', parentUID:parentUID, parentEmail:'', services:'', zoomID:{val:''}, zoomPass:{val:''},orderUID:'',child:{val:''},teacherDate:'ASAP',ptz:4,ttz:4,parentNote:{val:''},intro:{val:''},reschedule:{active: {val:'0'}, type: {val:''}, times: {val:''}, timeStamp: {val:''},refundRate: {val:''},talkingStick:{val:''}},timeOfPur:TS2}
    this.db.database.ref('/providers/userInfo/' + `${teacherUID}`+ '/orders').update({[`${TS2}`]:returnList2}).then(()=>{
      this.db.database.ref('/users/' + `${parentUID}`+ '/orders').update({[`${TS2}`]:returnList2})
    })
  }
  
  async postCamp(){
    var title = this.campTitle
    var subTitle = this.campSubTitle
    var article = this.campArticle
    var dbRef = this.db
    if(title !== '' && subTitle !== '' && article !== ''){
      var TS = + new Date()
      this.currentLink = ['https://verifiedteachers.com/VerifiedCampaigns/' + `${TS}`]
      await this.db.database.ref('camps').update({[`${TS}`]:
        {
          title: title,
          subTitle: subTitle,
          article: article
        }}
      ).then(()=>{
        dbRef.database.ref('campsIDs').update({[`${TS}`]:`${TS}`})
      })
    }else{
      //you forgot something
    }
  }

  makeRandom(len) {     
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890*&^%$#@!";
    const lengthOfCode = len;
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    
    return text;
  }
  createAppointments3(){
    var appointmentTUID = this.appointmentTUID
    var appointmentTName = this.appointmentTName
    var appointmentTEmail = this.appointmentTEmail
  
    var appointmentSName1 = this.appointmentSName1
    var appointmentSUID1 = this.appointmentSUID1
    var appointmentPEmail1 = this.appointmentPEmail1
    var appointmentPUID1 = this.appointmentPUID1
  
    var appointmentSName2 = this.appointmentSName2
    var appointmentSUID2 = this.appointmentSUID2
    var appointmentPEmail2 = this.appointmentPEmail2
    var appointmentPUID2 = this.appointmentPUID2
  

    var room = appointmentTUID+'-'+appointmentPUID1+'-'+appointmentSUID1
    var members = {}
    // get teacher profile pic url 
    members[0] = {name:appointmentTName ,UID: appointmentTUID, email:appointmentTEmail,'type':'T'}
    members[1] = {name:appointmentSName1 ,UID: appointmentPUID1+'-'+appointmentSUID1, email:appointmentPEmail1,'type':'S','proPic':this.monday}

    if(appointmentPUID2 !== ''){
      room += '-'+appointmentPUID2+'-'+appointmentSUID2
      members[2]={name:appointmentSName2 ,UID: appointmentPUID2+'-'+appointmentSUID2, email:appointmentPEmail2,'type':'S','proPic':this.tuesday}
    }
    
    // console.log(this.wednesday)
    // '2022-02-08T09:30'
    console.log(this.wednesday,moment(this.wednesday))
    console.log(this.thursday)

    var startMoment = moment(this.wednesday).utc()
    var endMoment = moment(this.thursday).utc()
    var duration = endMoment.diff(startMoment,'minutes')

    console.log(+ startMoment)
    console.log(moment(+ startMoment).format())

    var calendarInfo = {'duration':duration,'members':members,'room':room}
    console.log(calendarInfo,'/providers/userInfo/' + `${appointmentTUID}`+ '/calendar/'+`${+ startMoment}`)
    this.db.database.ref('/providers/userInfo/' + `${appointmentTUID}`+ '/calendar/'+`${+ startMoment}`).push(calendarInfo).then(()=>{
      this.db.database.ref('/users/' + `${appointmentPUID1}`+ '/calendar/'+`${appointmentSUID1}`+'/'+`${+ startMoment}`).push(calendarInfo).then(()=>{
        if(appointmentPUID2 !== '' && appointmentPUID1 !== appointmentPUID2){
          this.db.database.ref('/users/' + `${appointmentPUID2}`+ '/calendar/'+`${appointmentSUID2}`+'/'+`${+ startMoment}`).push(calendarInfo)
        }
      })
    })

  }
  // DATE formats: wed 01-13-2021 2:30pm-3pm-1430-1500
  createAppointments2(){
    
    var appointmentTUID = this.appointmentTUID
    var appointmentTName = this.appointmentTName
    var appointmentTEmail = this.appointmentTEmail
  
    var appointmentSName1 = this.appointmentSName1
    var appointmentSUID1 = this.appointmentSUID1
    var appointmentPEmail1 = this.appointmentPEmail1
    var appointmentPUID1 = this.appointmentPUID1
  
    var appointmentSName2 = this.appointmentSName2
    var appointmentSUID2 = this.appointmentSUID2
    var appointmentPEmail2 = this.appointmentPEmail2
    var appointmentPUID2 = this.appointmentPUID2
  
    var monday = this.monday
    var tuesday = this.tuesday
    var wednesday = this.wednesday
    var thursday = this.thursday
    var friday = this.friday
    var saturday = this.saturday
    var sunday = this.sunday

    var room = appointmentTUID+'-'+appointmentPUID1+'-'+appointmentSUID1
    var members = {}
    members[0] = {name:appointmentTName ,UID: appointmentTUID, email:appointmentTEmail}
    members[1] = {name:appointmentSName1 ,UID: appointmentPUID1+'-'+appointmentSUID1, email:appointmentPEmail1}

    if(appointmentPUID2 !== ''){
      room += '-'+appointmentPUID2+'-'+appointmentSUID2
      members[2]={name:appointmentSName2 ,UID: appointmentPUID2+'-'+appointmentSUID2, email:appointmentPEmail2}
    }
    
    var teacherTZ = 'America/New_York'

    var hldMoment = moment().tz(teacherTZ)
    var appointments = []

    if(monday!==''){
      var count2 = 0
      while(count2 < 4){
        
        console.log()
        while(hldMoment.day() !== 1){
          hldMoment.add(24,'hours')
        }
        monday.split('|').forEach(timeFrame=>{
          var startmoment = hldMoment.clone().hour(Number(timeFrame.split('-')[0].split(':')[0])).minute(Number(timeFrame.split('-')[0].split(':')[1])).second(0).millisecond(0).utc()
          var endmoment = hldMoment.clone().hour(Number(timeFrame.split('-')[1].split(':')[0])).minute(Number(timeFrame.split('-')[1].split(':')[1])).second(0).millisecond(0).utc()
          // console.log(startmoment.format(),' - ',endmoment.format())
          this.db.database.ref('/providers/userInfo/' + `${appointmentTUID}`+ '/calendar').update({[startmoment.unix()]:{'end':endmoment.unix(),'room':room,'members':members}})
          .then(()=>{
            this.db.database.ref('/users/' + `${appointmentPUID1}`+ '/calendar').update({[startmoment.unix()]:{'end':endmoment.unix(),'room':room,'members':members}}).then(()=>{
              if(appointmentPUID2 !== '' && appointmentPUID1 !== appointmentPUID2){
                this.db.database.ref('/users/' + `${appointmentPUID2}`+ '/calendar').update({[startmoment.unix()]:{'end':endmoment.unix(),'room':room,'members':members}})
              }
            })
            
          })

          // console.log(startmoment.format(),' - ',endmoment.format())
          // console.log(startmoment.utc().format(),' - ',endmoment.utc().format())
          // console.log(+ startmoment)
          // console.log(startmoment.unix())
        })
        hldMoment.add(24,'hours')
        count2 += 1
      }
    }

    console.log(appointments)
    appointments.forEach(appointment=>{


      // .then(()=>{
      //   var date = parentDate
      //   var classroomAnchor = teacherUID + '-' + parentUID + '-' + studentUID 
      //   var studentAppt = {'date':date,'TN':teacherName,'SN':studentName,'cRef':classroomAnchor}
      //   this.db.database.ref('/users/' + `${parentUID}`+ '/students/' +`${studentUID}` + '/sessions/').update({[`${TS2}`]:studentAppt})
      // })
    })
  }

  createAppointments(){
    moment()
    // var appointmentTUID = this.appointmentTUID
    // var appointmentTName = this.appointmentTName
    // var appointmentTEmail = this.appointmentTEmail
  
    // var appointmentSName1 = this.appointmentSName1
    // var appointmentSUID1 = this.appointmentSUID1
    // var appointmentPEmail1 = this.appointmentPEmail1
    // var appointmentPUID1 = this.appointmentPUID1
  
    // var appointmentSName2 = this.appointmentSName2
    // var appointmentSUID2 = this.appointmentSUID2
    // var appointmentPEmail2 = this.appointmentPEmail2
    // var appointmentPUID2 = this.appointmentPUID2
  
    // var monday = this.monday
    // var tuesday = this.tuesday
    // var wednesday = this.wednesday
    // var thursday = this.thursday
    // var friday = this.friday
    // var saturday = this.saturday
    // var sunday = this.sunday
    var teacherTZ = 'America/New_York'

    var momentRef = moment
    var moment = momentRef.clone().tz(teacherTZ)
    console.log(moment.format())
    // if(monday!==''){
    //   var count = -1
    //   while(count < 4){
    //     count += 1

    //   }
    //   monday.split('|').forEach(timeFrame=>{

    //     var moment = momentRef.clone().tz(teacherTZ)
    //     console.log(moment.format)

        
    //     console.log(moment)
    //     moment = moment.tz().utc()
        
    //     var appointment = {
    //       teacherName:appointmentTName,
    //     }
    //   })
    // }



    // this.db.database.ref('/providers/userInfo/' + `${teacherUID}`+ '/orders').update({[`${TS2}`]:appointment}).then(()=>{
    //   this.db.database.ref('/users/' + `${parentUID}`+ '/orders').update({[`${TS2}`]:appointment})
    // }).then(()=>{
    //   var date = parentDate
    //   var classroomAnchor = teacherUID + '-' + parentUID + '-' + studentUID 
    //   var studentAppt = {'date':date,'TN':teacherName,'SN':studentName,'cRef':classroomAnchor}
    //   this.db.database.ref('/users/' + `${parentUID}`+ '/students/' +`${studentUID}` + '/sessions/').update({[`${TS2}`]:studentAppt})
    // })


  }
  
  
  

  async createAmbassAccount(){
    var pass = this.makeRandom(12);
    await this.auth.emailSignUpAmbass(this.ambassEmail,pass,this.ambassName).then(val=>{
      console.log('Email: ' + `${this.ambassEmail}` +  'Pass: ' + `${pass}` +  'AmbassID: ' + `${val}`) 
      setTimeout(()=>{
        this.db.database.ref('users/' + `${val}` + '/ambassCode/').once('value').then(snapper=>{
          console.log(snapper.key,snapper.val())
          this.ambasAccountInfo = 'Email: ' + `${this.ambassEmail}` +  ' || Pass: ' + `${pass}` +  ' || AmbassID: ' + `${snapper.val()}`
        })
      },600)
    })
  }

  createApp(TUID,PUID){

  }

  async post(){
    var title = this.blogTitle
    var subTitle = this.blogSubTitle
    var readTime = this.blogReadTime
    var article = this.blogArticle
    var cat = this.blogCat
    var youtube = this.youtubeCode
    var blogImagePath = this.blogImagePath
    if(this.blogTitle !== '' && this.blogReadTime !== '' && this.blogArticle !== '' && this.blogCat !== '' && this.blogImagePath !== ''){
      var TS = + new Date()
      await this.db.database.ref('blog/' + cat).update({[`${TS}`]:
        {
          title: title,
          subTitle: subTitle,
          readTime:readTime,
          article: article,
          nextIndex: 1,
          cat: cat,
          youtube: youtube
        }}
      ).then(()=>{
        this.db.database.ref('blog/new/nextIndex').once('value').then((snap)=>{
          var nextHold = snap.val()
          this.db.database.ref('blog/new/' +`${nextHold}` ).set({[`${TS}`]:
        {
          title: title,
          subTitle: subTitle,
          readTime:readTime,
          article: article,
          nextIndex: 1,
          cat: cat,
          youtube: youtube
        }}).then(()=>{
          var newIndex:number = Number(nextHold)
          if(newIndex === 5){
            newIndex = 1
          }else{
            newIndex += 1
          } 
          this.db.database.ref('blog/new').update({'nextIndex':newIndex}).then(()=>{
            this.upload2(blogImagePath,TS)
          })
        }).then(()=>{
          this.db.database.ref('blog/cats').child('val').once('value').then((snaper)=>{
            var topics = String(snaper.val())
            var newS = true
            console.log(snaper.val())
            topics.split('|').forEach(val=>{
              if(val === cat){
                newS = false
              }
            })
            if(newS){
              console.log(1)
              topics += '|' + cat
              this.db.database.ref('blog/cats').set({
                val:topics
              })

            }
          })
        })
        })
      })
    }else{
      //you forgot something
    }
  }  

  upload2($event,TS){
    this.path = $event.target.files[0]
    this.afm.upload("/ArticleImages/" + TS, this.path)
  }

  blogSwitch(val,type){
    if(type === 0){
      this.blogTitle = val
    }else if(type === 1){
      this.blogSubTitle = val
    }else if(type === 2){
      this.blogReadTime = val
    }else if(type === 3){
      this.blogArticle = val
      this.paragraphs = val.split('||')
    }else if(type === 4){
      this.blogCat = val
    }else if(type === 5){
      this.blogImagePath = val

      const files = val.target.files;
      if (files.length === 0)
          return;
  
      const mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
          return;
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(files[0]); 
      reader.onload = (_event) => { 
          this.url = reader.result; 
      }
    }else if(type === 6){
      this.youtubeCode = val
    }
  }

  campaignSwitch(val,type){
    if(type === 0){
      this.campTitle = val
    }else if(type === 1){
      this.campSubTitle = val
    }else if(type === 2){
      this.campImagePath = val

      const files = val.target.files;
      if (files.length === 0)
          return;
  
      const mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
          return;
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(files[0]); 
      reader.onload = (_event) => { 
          this.campurl = reader.result; 
      }
    }else if(type === 3){
      this.campArticle = val
      var halfWay = Math.ceil(val.split('||').length /2)
      console.log(halfWay)
      var count = 1
      var hold1 = []
      var hold2 = []
      val.split('||').forEach(val2=>{
        console.log(count, halfWay)
        console.log(val2)
        
        if(count <= halfWay){
          console.log(1)
          hold1.push(val2)  
        }else{
          console.log(2)
          hold2.push(val2)
        }
        count += 1
      })
      console.log(hold1,hold2)
      this.paragraphsCamp1.emit(hold1)
      this.paragraphsCamp2.emit(hold2)
    }else if(type === 4){
      this.youtubeCodeCamp = val
    }
  }

    //type 1:Grade 2:Subject
    async updateSearch(val,type){
      console.log(1)
      if(type === 1){
        console.log(2)
        var unique = true
        this.grades.split(",").forEach(grade=>{
          if(val === grade){
            unique = false
          }
        })
        if(unique){
          console.log(3)
          document.getElementById(val).style.background = '#EA6F66'
          this.addGrade2(val)
          //update personal profile with new grades list
          if(this.grades.split(",")[0] === ""){
            this.grades +=  val
          }else{
            this.grades += "," + val
          }
          await this.db.database.ref('providers/userInfo/' + this.UID + '/services/grades').set({
            val:this.grades
          })
        }else{
          //remove
          console.log(4)
          document.getElementById(val).style.background = 'grey'
          this.removeGrade2(val)
          
          
          //update personal profile with new grades list
          var locate = this.grades.split(",")
          if(locate.length === 1){
            this.grades = ""
          }else if(locate[0] === val){
            this.grades = this.grades.replace(val + ",","")
          }else if(locate[locate.length - 1] === val){
            this.grades = this.grades.replace("," + val ,"")
          }else{
            this.grades = this.grades.replace("," + val + ",",",")
          }
          await this.db.database.ref('providers/userInfo/' + this.UID + '/services/grades').set({
            val:this.grades
          })
           
        }
      }else{
        console.log(5)
        var unique = true
        this.currentTitles.split(",").forEach(subject=>{
          if(val === subject){
            unique = false
          }
        })
        if(unique){
          console.log(6)
          console.log(val)
          document.getElementById(val).style.background = '#EA6F66'
          this.addServices2(val)
          //update personal profile with new grades list
          if(this.currentTitles.split(",")[0] === ""){
            this.currentTitles +=  val
          }else{
            this.currentTitles += "," + val
          }
          await this.db.database.ref('providers/userInfo/' + this.UID + '/services/currentTitles').set({
            val:this.currentTitles
          })
        }else{
          //remove
          console.log(6)
          document.getElementById(val).style.background = 'grey'
          this.removeServices2(val)
          
          
          //update personal profile with new grades list
          var locate = this.currentTitles.split(",")
          if(locate.length === 1){
            this.currentTitles = ""
          }else if(locate[0] === val){
            this.currentTitles = this.currentTitles.replace(val + ",","")
          }else if(locate[locate.length - 1] === val){
            this.currentTitles = this.currentTitles.replace("," + val ,"")
          }else{
            this.currentTitles = this.currentTitles.replace("," + val + ",",",")
          }
          await this.db.database.ref('providers/userInfo/' + this.UID + '/services/currentTitles').set({
            val:this.currentTitles
          })
           
        }
      }
    }
  
    async removeServices2(subject:string){
      var dbRef = this.db
      if(!this.currentTitles.includes(',')){
        await this.grades.split(",").forEach(grade=>{
          dbRef.database.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}` +"/" + `${this.UID}`).remove()
        })
        this.updateSearch('General',2)
      }else{
        this.grades.split(",").forEach(grade=>{
          dbRef.database.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}` +"/" + `${this.UID}`).remove()
        })
      }
    }
  
    async addServices2(subject:string){
      var dbRef = this.db.database
      if(this.grades === ''){
        dbRef.ref('teacherSearch/General' + `${subject}`).update({[this.UID]:this.UID})
      }else{
        this.grades.split(",").forEach(grade=>{
          dbRef.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}`).update({[this.UID]:this.UID})
        })
      }
    }
  
    async removeGrade2(grade:string){
      var dbRef = this.db
      if(!this.grades.includes(',')){
        console.log(11)
        await this.currentTitles.split(",").forEach(subject=>{
          dbRef.database.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}` +"/" + `${this.UID}`).remove()
        })
        this.updateSearch('General Education',1)
      }else{
        console.log(22)
        this.currentTitles.split(",").forEach(subject=>{
          dbRef.database.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}` +"/" + `${this.UID}`).remove()
        })
      }
    }
  
    async addGrade2(grade:string){
      var dbRef = this.db.database
      if(this.currentTitles === ''){
        dbRef.ref('teacherSearch/' + `${grade}` + '/General').update({[this.UID]:this.UID})
      }else{
        this.currentTitles.split(",").forEach(subject=>{
          dbRef.ref('teacherSearch/' + `${grade}`+ '/' + `${subject}`).update({[this.UID]:this.UID})
        })
      }
    }

  async addToSearch(){
    if(this.service !== '' && this.grade !== ''){
      await this.db.database.ref('teacherSearch/' + this.grade + '/' + this.service).push(
        {
          uid: this.UID2
        }  
      )
    }
  }

  createInfCode(){
    this.db.database.ref('influencerCodes/').push(
      {
        val: '',
      }
    ).then(val=>{
      console.log(val.toString().split('/')[val.toString().split('/').length-1])
      this.currentCode= [val.toString().split('/')[val.toString().split('/').length-1]]
    })
  }

  async addQuestion(){
    console.log('questionSearch/' + this.qGrade + '/' + this.qSubTopic)
    var pathHold = this.qGrade + '/' + this.qSubTopic
    //Add Question 
    //
    var topic = this.qSubTopic
    var newTopics = ''
    await this.db.database.ref('questionsSearch/' + `${this.qGrade}`).once('value').then(function(snap){
      if(snap.exists()){
        var snapper:string = snap.child('val').val()
        if(!snapper.includes(topic)){
          if(snapper.includes(',')){
            newTopics = snapper + ',' + topic
          }
        }
      }
    }).then(()=>{
      if(newTopics !== ''){
        this.db.database.ref('questionsSearch/' + `${this.qGrade}`).update({'val':
          newTopics
        })
      }
    }).then(()=>{
      this.db.database.ref('questionSearch/' +  this.qGrade + '/' + this.qSubTopic).push(
        {
          question: this.Question,
          AUID: this.AUID,
          TUIDs: '',
          openClosed: 0,
          timeStamp: + new Date(),
          path:pathHold
        }
      )
    })
  }

  async addPolicy(){
    await this.db.database.ref('providers/userInfo/' + this.UID4 + "/policy").set({
        '12_2':{val: 70},
        '24_12':{val: 50},
        '2_0':{val: 90},
        '36_24':{val: 30},
        'generalFee':{val: 20},
        'grace':{val: 24},
        'noShow':{val: 100},
      })
  }

  upload($event){
    this.path = $event.target.files[0]
    this.afm.upload("/teacherPics/" + this.UID3, this.path)
  }

  storeUID(value){
    this.uidFor = value
  }

  async clearTitles(){
    await this.db.database.ref('providers/userInfo/' + this.UID232 + '/services/currentTitles').set({
      val:'General'
    })
  }

  async clearDays(){
    await this.db.database.ref('providers/userInfo/' + this.UID232 + '/services/activeDays').set({
      val:''
    })
  }

  async clearGrades(){
    await this.db.database.ref('providers/userInfo/' + this.UID232 + '/services/grades').set({
      val:'General Education'
    })
  }

  async storeUID2(value){
    this.UID = value
    var serviceHolder = ""
    var gradeHolder = ""
    document.getElementById("Language Arts").style.background = '#D8D8D8'
    document.getElementById("Mathematics").style.background = '#D8D8D8'
    document.getElementById("Social Studies").style.background = '#D8D8D8'
    document.getElementById("Science").style.background = '#D8D8D8'
    document.getElementById("Creative Arts").style.background = '#D8D8D8'
    document.getElementById("World Language").style.background = '#D8D8D8'
    document.getElementById("General").style.background = '#D8D8D8'

    document.getElementById("General Education").style.background = '#D8D8D8'
    document.getElementById("1_2").style.background = '#D8D8D8'
    document.getElementById("3_5").style.background = '#D8D8D8'
    document.getElementById("6_8").style.background = '#D8D8D8'
    document.getElementById("9_12").style.background = '#D8D8D8'

    await this.db.database.ref('providers/userInfo/' + this.UID + '/services/currentTitles/val').once('value').then(function(snap){
      console.log(snap.val())
      serviceHolder = snap.val()
    })
    this.currentTitles = serviceHolder

    await this.db.database.ref('providers/userInfo/' + this.UID + '/services/grades/val').once('value').then(function(snap){
      console.log(snap.val())
      gradeHolder = snap.val()
    })
    this.grades = gradeHolder

    this.grades.split(",").forEach(val=>{
      document.getElementById(val).style.background = '#EA6F66'
    })

    this.currentTitles.split(",").forEach(val=>{
      document.getElementById(val).style.background = '#EA6F66'
    })


  }

  storeFirstName(value){
    this.firstName = value
  }

  storeformalTitle(value){
    this.formal = value
  }

  storeTZO(value){
    this.timeZone = value
  }

  checkSend(){
    return this.timeZone !== "" && this.uidFor !== "" && this.firstName !== "" && this.formal !== ""
  }

  storeDay(day){
    this.day = day
  }

  checkGrade(grade){
    this.grade = grade
  }
  
  checkService(service){
    this.service = service
  }

  async addTeachers(){
    if(this.checkSend()){
      //immutable
      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/immutable/formalTitle").set({
        val: this.formal
      })
      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/immutable/name").set({
        val: this.firstName
      })
      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/immutable/stars").set({
        val: 5
      })

      //profile
      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/profile/longIntro").set({
        val: "empty"
      })

      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/profile/shortIntro").set({
        val: "empty"
      })

      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/profile/rate").set({
        val: 40
      })
      //schedule
      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/schedule/mon").set({
        val: "empty"
      })
      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/schedule/tue").set({
        val: "empty"
      })
      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/schedule/wed").set({
        val: "empty"
      })
      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/schedule/thu/").set({
        val: "empty"
      })
      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/schedule/fri").set({
        val: "empty"
      })
      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/schedule/sat").set({
        val: "empty"
      })
      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/schedule/sun").set({
        val: "empty"
      })

      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/schedule/futureBookings").set({
        val: ""
      })

      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/schedule/future").set({
        val: 7
      })
      
      // await this.db.database.ref('providers/userInfo/' + this.uidFor + "/schedule").set({
      //   timeZoneOffset: this.timeZone
      // })
      // services
      var count = 1
      while (count < 6){
        await this.db.database.ref('providers/userInfo/' + this.uidFor + "/services/" + count + "/title").set({
          val: "empty"
        })
        await this.db.database.ref('providers/userInfo/' + this.uidFor + "/services/" + count + "/desc").set({
          val: ""
        })
        count += 1
      }

      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/services/activeDays").set({
        val: ""
      })      

      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/services/currentTitles").set({
        val: ""
      })

      await this.db.database.ref('providers/userInfo/' + this.uidFor + "/services/grades").set({
        val: ""
      })

    console.log("all done")      
    }else{
      //check your inputs
    }
  }
  

}
