import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comment-header',
  templateUrl: './comment-header.component.html',
  styleUrls: ['./comment-header.component.scss']
})
export class CommentHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
