import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-display',
  templateUrl: './video-display.component.html',
  styleUrls: ['./video-display.component.scss']
})
export class VideoDisplayComponent implements OnInit {
  lock = false
  mute = false
   
  audio = true
  video = true

  UID = ''

  avatar = ''

  constructor() { }

  ngOnInit(): void {

  }  

  setAvatar(avatar){
    if(Number(avatar) === 1){
      document.getElementById('avatar').setAttribute('class','turtle centerAvatar rectangle-47')
      this.avatar = 'turtle'
    }else if(Number(avatar) === 2){
      this.avatar = 'hippo'
      document.getElementById('avatar').setAttribute('class','hippo centerAvatar rectangle-47')
    }else if(Number(avatar) === 3){
      this.avatar = 'zebra'
      document.getElementById('avatar').setAttribute('class','zebra centerAvatar rectangle-47')
    }else if(Number(avatar) === 4){
      this.avatar = 'giraf'
      document.getElementById('avatar').setAttribute('class','giraf centerAvatar rectangle-47')
    }else if(Number(avatar) === 5){
      this.avatar = 'cat'
      document.getElementById('avatar').setAttribute('class','cat centerAvatar rectangle-47')
    }else if(Number(avatar) === 6){
      this.avatar = 'owl'
      document.getElementById('avatar').setAttribute('class','owl centerAvatar rectangle-47')
    }
  }

  lockStudentsCanvas(){

  }  

  unlockStudentsCanvas(){

  }

  muteStudentsAudio(){

  }

  unmuteStudentsAudio(){

  }

  turnOnAudio(){

  }
  turnOffAudio(){

  }
  turnOnVideo(){

  }
  turnOffVideo(){
    
  }

}
