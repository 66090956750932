import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http' 
import { TeacherProfile } from '../models/TeacherProfile';
import { Observable } from 'rxjs'

@Injectable({ 
  providedIn: 'root'
}) 
export class TeachersService {
  teacherURL:string = 'https://my-json-server.typicode.com/Mendoza1997/mendoza1997-teachers/teachers'

  constructor(private http:HttpClient) { }

  // getTeachers():Observable<TeacherProfile[]>{ 
  //   var limit = "?_limit=3";
  //  return this.http.get<TeacherProfile[]>(` ${this.teacherURL}${limit}`);
  // }

  getAllTeachers():Observable<TeacherProfile[]>{
    return this.http.get<TeacherProfile[]>(this.teacherURL);
   }
  
}
