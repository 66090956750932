import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import{ TeacherProfile } from '../models/TeacherProfile';
import {TeachersService} from '../services/teachers.service';
import {queries} from '../models/queries'
import {AngularFireDatabase} from '@angular/fire/database'
import { DataSnapshot } from '@angular/fire/database/interfaces';
import { ResizedEvent } from 'angular-resize-event';
import { HostListener } from "@angular/core";
import { AngularFireFunctions } from '@angular/fire/functions';
import { ServiceDescription } from '../models/ServiceDescription';
import { auth } from 'firebase';
import {Router} from '@angular/router'
  
  
declare var gtag
   
@Component({         
  selector: 'app-booking',     
  templateUrl: './booking.component.html',  
  styleUrls: ['./booking.component.scss']
}) 
  

export class BookingComponent implements OnInit {
  currentURL = ''
  subjectS = ''
  gradeS = ''
  name2 = ''
  emailNL2 = ''
  teacherCounter = 0
  timeS = ''     
  width: number;
  height: number;   
  currentSlide = 1  
  fun    
  isTeacher = false    
  PUID
  recurr = false
  nonrecurr = true
  now = false
  teacherTimeZoneOffset:number; 
  name;
  previousTypeOfBook = 'NonRecurring'
  cred;
  teacherEmail = '';
  rate;
  logging2 = false
  createLogin2: string[];

  // edge: my grade {5 / five} student
  hotWords = {'1st':'1','2nd':'1','3rd':'3','4th':'3','5th':'3','6th':'6','7th':'6','8th':'6','9th':'9',
  '10th':'9','11th':'9','12th':'9',
  
  'first':'1','second':'1','third':'3','fourth':'3','fifth':'3','fith':'3','sixth':'6','six':'6','seventh':'6','eighth':'6','ninth':'9','nineth':'9',
  'tenth':'9','eleventh':'9','twelfth':'9','twelth':'9',  

  'primary':'1-3','secondary':'6','high':'9','senior':'9','junior':'9','sophmore':'9','freshman':'9','middle ':'6','elementary':'1-3',
  'schooler':'6-9',
  'math':'M','algebra':'M-9','arithmetic':'M-3','calculus':'M-9','calc':'M-9','combinatorics':'M-3','geometry':'M-9',
  'pre-calculus':'M-9','pre-calc':'M-9','statistics':'M-9','stats':'M-9','trigonometry':'M-9','trig':'M-9',

  'english':'E','language':'E','reading':'E-3-6','grammar':'E-6','journalism':'E-9','composition':'E-9',
  'comp':'E-9','spelling':'E-3','creative writting':'E-9','hand writting':'E-1','screen plays':'E-9', 'literature':'E-9',

  'history':'H-6-9','social':'H-6-9',

  'science':'S','biology':'S-9','bio':'S-9','chemistry':'S-9','chem':'S-9', 'life science':'S-9','physics':'S-9','physio':'S-9',

  'art':'A','drawing':'A','film':'A-9','ceramics':'A-9','photography':'A-9','sculpture':'A-9','painting':'A-9','singing':'A-9',
  'dance': 'A-9',

  'spanish':'W','french':'W','german':'W','mandarin':'W','japanese':'W',

  'sat': 'M-E-9','act':'M-E-S-9','resume':'E-9'

  }

  selections = []

  chosenGrades = []
  chosenSubjects = []

  currentSelector = ''

  fresh:boolean = true;
  teachers: TeacherProfile[] = [];
  @Output() currTeachers = new EventEmitter<any[]>();
  blank:boolean
  @Output() mobile = new EventEmitter<boolean>();
  currentlySelected: string[] = [];  
  activeQueries: Set<string[]> = new Set();
  currentQueries: queries[] = [] 
  relay:string[][] = []
  teacherUID:string[] = []
  zeros = 0 
  ones = 0   
  twos = 0
  nLMsg = []

  chosenServices: string = ""

  timeRequests = []

  sentNL = false
  listings: ServiceDescription[];  
  nameNL = ''
  emailNL = ''

  UIDs = ''

  timesList = [15,30,45,60,75,90,105,120]
  sessionList = [1,2,3,4,5,6,7,8,9,10]

  slotPlaceHolder1 = "Month"
  slotPlaceHolder2 = "Date"
  slotPlaceHolder22 = "End Date"
  slotPlaceHolder3 = "Year"
  slotPlaceHolder4 = "Start Time"   
  slotPlaceHolder5 = "End Time"  
  slotPlaceHolder6 = "# of Sessions"
  slotPlaceHolder7 = "Rate"

  validDays1 = []
  validDays2 = []

  s = false
  g = false
  d = false

  listNum1 = []
  listNum2 = []
  reqDate = ''
  reqDateEnd = ''
  reqStartTime = ''
  reqEndTime = ''
  reqSessionNum = ''
  reqSessionRate = ''

  subjects = ['Creative Arts','General','Language Arts','Mathematics','Science','Social Studies','World Language']
  grades = ['1_2','3_5','6_8','9_12']
  err: string[];
  
  constructor(private fns:AngularFireFunctions, private rerouter: Router,private database:AngularFireDatabase) {}
 
  //life cycle method     
  ngOnInit(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0
    this.PUID = Math.random().toString(36).substr(2);
    this.getDates()
    this.getTimesDef(500,2100)
    // document.getElementById('days').addEventListener('click',()=>{
    //   console.log('Days Clicked')
    // })
  }

  getDay(month){
    month = Number(month)
    if(month === 0){
      return "Mon"
    }else if(month === 1){
      return "Tue"
    }else if(month === 2){
      return "Wed"
    }else if(month === 3){
      return "Thu"
    }else if(month === 4){
      return "Fri"
    }else if(month === 5){
      return "Sat"
    }else if(month === 6){
      return "Sun"
    }
  }

  setRegDate(val){
    this.reqDate = val
    this.slotPlaceHolder2 = this.dateFormat(val)
  }
  setRegDate2(val){
    this.reqDateEnd = val
    this.slotPlaceHolder22 = val
  }


  setStartTime(val){
    //update list2 -- 
    this.listNum2 = this.getTimesSingle(500,2100)
    this.listNum2 = this.listNum2.filter(num => num > val)
    this.reqStartTime = val
    this.slotPlaceHolder4 = this.formatTime(val)
  }

  setEndTime(val){
    this.listNum1 = this.getTimesSingle(500,2100)
    this.listNum1 = this.listNum1.filter(num => num < val)
    this.reqEndTime = val
    this.slotPlaceHolder5 = this.formatTime(val)
  }

  setSessionNum(val:string){
    this.reqSessionNum = val
    this.slotPlaceHolder6 = val
  }

  nowAdd(){
    var value = Number(this.reqSessionNum)
    var time = '00' + this.reqSessionNum
    if(value > 60){
      time = '00'+ String(value)
    }else if(value === 60){
      time = '0100'
    }else if(value === 75){
      time = '0115'
    }else if(value === 90){
      time = '0130'    
    }else if(value === 105){
      time = '0145'
    }else if(value === 120){
      time = '0200'
    }
    this.timeRequests =[['ASAP-ASAP-ASAP','0000',time,'1',`${'ASAP-ASAP-ASAP-0000-' +time}`]]
  }

  goRight(){
    if(this.teacherCounter === 0){
      this.teacherCounter = 1
      this.rightProcedure1()
    }else if(this.teacherCounter === 1){
      this.teacherCounter = 2
      this.rightProcedure2()
    }else if(this.teacherCounter === 2){
      this.teacherCounter = 3
      this.rightProcedure3()
    }
  }

  rightProcedure1(){
    document.getElementById('teacherSlot1').setAttribute('style','display:none')
    document.getElementById('teacherSlot2').setAttribute('style','display:none')
    document.getElementById('teacherSlot3').setAttribute('style','display:none')
    document.getElementById('teacherSlot4').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot5').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot6').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot7').setAttribute('style','display:none')
    document.getElementById('teacherSlot8').setAttribute('style','display:none')
    document.getElementById('teacherSlot9').setAttribute('style','display:none')
    document.getElementById('teacherSlot10').setAttribute('style','display:none')
    document.getElementById('teacherSlot11').setAttribute('style','display:none')
    document.getElementById('teacherSlot12').setAttribute('style','display:none')
  }

  rightProcedure2(){
    document.getElementById('teacherSlot1').setAttribute('style','display:none')
    document.getElementById('teacherSlot2').setAttribute('style','display:none')
    document.getElementById('teacherSlot3').setAttribute('style','display:none')
    document.getElementById('teacherSlot4').setAttribute('style','display:none')
    document.getElementById('teacherSlot5').setAttribute('style','display:none')
    document.getElementById('teacherSlot6').setAttribute('style','display:none')
    document.getElementById('teacherSlot7').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot8').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot9').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot10').setAttribute('style','display:none')
    document.getElementById('teacherSlot11').setAttribute('style','display:none')
    document.getElementById('teacherSlot12').setAttribute('style','display:none')
  }
  
  rightProcedure3(){
    document.getElementById('teacherSlot1').setAttribute('style','display:none')
    document.getElementById('teacherSlot2').setAttribute('style','display:none')
    document.getElementById('teacherSlot3').setAttribute('style','display:none')
    document.getElementById('teacherSlot4').setAttribute('style','display:none')
    document.getElementById('teacherSlot5').setAttribute('style','display:none')
    document.getElementById('teacherSlot6').setAttribute('style','display:none')
    document.getElementById('teacherSlot7').setAttribute('style','display:none')
    document.getElementById('teacherSlot8').setAttribute('style','display:none')
    document.getElementById('teacherSlot9').setAttribute('style','display:none')
    document.getElementById('teacherSlot10').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot11').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot12').setAttribute('style','z-index:1;cursor:pointer;')
  }

  goLeft(){
    if(this.teacherCounter === 1){
      this.teacherCounter = 0
      this.leftProcedure1()
    }else if(this.teacherCounter === 2){
      this.teacherCounter = 1
      this.leftProcedure2()
    }else if(this.teacherCounter === 3){
      this.teacherCounter = 2
      this.leftProcedure3()
    }
    console.log('left2')
  }


  leftProcedure1(){
    document.getElementById('teacherSlot1').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot2').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot3').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot4').setAttribute('style','display:none')
    document.getElementById('teacherSlot5').setAttribute('style','display:none')
    document.getElementById('teacherSlot6').setAttribute('style','display:none')
    document.getElementById('teacherSlot7').setAttribute('style','display:none')
    document.getElementById('teacherSlot8').setAttribute('style','display:none')
    document.getElementById('teacherSlot9').setAttribute('style','display:none')
    document.getElementById('teacherSlot10').setAttribute('style','display:none')
    document.getElementById('teacherSlot11').setAttribute('style','display:none')
    document.getElementById('teacherSlot12').setAttribute('style','display:none')
  }

  leftProcedure2(){
    document.getElementById('teacherSlot1').setAttribute('style','display:none')
    document.getElementById('teacherSlot2').setAttribute('style','display:none')
    document.getElementById('teacherSlot3').setAttribute('style','display:none')
    document.getElementById('teacherSlot4').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot5').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot6').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot7').setAttribute('style','display:none')
    document.getElementById('teacherSlot8').setAttribute('style','display:none')
    document.getElementById('teacherSlot9').setAttribute('style','display:none')
    document.getElementById('teacherSlot10').setAttribute('style','display:none')
    document.getElementById('teacherSlot11').setAttribute('style','display:none')
    document.getElementById('teacherSlot12').setAttribute('style','display:none')
  }

  leftProcedure3(){
    document.getElementById('teacherSlot1').setAttribute('style','display:none')
    document.getElementById('teacherSlot2').setAttribute('style','display:none')
    document.getElementById('teacherSlot3').setAttribute('style','display:none')
    document.getElementById('teacherSlot4').setAttribute('style','display:none')
    document.getElementById('teacherSlot5').setAttribute('style','display:none')
    document.getElementById('teacherSlot6').setAttribute('style','display:none')
    document.getElementById('teacherSlot7').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot8').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot9').setAttribute('style','z-index:1;cursor:pointer;')
    document.getElementById('teacherSlot10').setAttribute('style','display:none')
    document.getElementById('teacherSlot11').setAttribute('style','display:none')
    document.getElementById('teacherSlot12').setAttribute('style','display:none')
  }


  nonRecurrAdd(){
    var unique = true;
    if(this.reqDate !== '' && this.reqStartTime!== '' && this.reqEndTime !== ''){
      this.timeRequests.forEach(val =>{
        if(val[4] === this.reqDate + "-" +this.reqStartTime + "-" +this.reqEndTime ){
          unique = false
        }
      })
      if(unique){
        this.timeRequests.push([this.reqDate,this.reqStartTime,this.reqEndTime,'',this.reqDate + "-" +this.reqStartTime + "-" +this.reqEndTime])
      }
    }else{
      //error
      var err = 'Looks like you forgot:  '
      if(this.reqDate !== '' ){
        err += 'a date'
      }
      if(this.reqStartTime!== ''){
        err += ', a startime'
      }
      if(this.reqEndTime !== ''){
        err += ', an end time'

      }
      if(this.reqSessionNum  !== ''){
        err += ', # of sessions'
      }
      this.err = [err]
    }
  }

  procTeacherSearch(searchString:string){
    var gradeKeys = ''
    var subKeys = ''
    searchString.toLowerCase().split(' ').forEach(word=>{
      console.log(this.hotWords[word])
      if(this.hotWords[word] !== undefined){
        var keys:string = this.hotWords[word]
        if(keys.includes('-')){
          keys.split('-').forEach(key=>{
            if(key === '1' ||key === '3' ||key === '6' ||key === '9' ){
              if(gradeKeys === ''){
                gradeKeys = key
              }else{
                var unique = true
                gradeKeys.split('-').forEach(char=>{
                  if(char === key){
                    unique = false
                  }
                })
                if(unique){
                  gradeKeys += '-' + key
                }
              }
            }else{
              if(subKeys === ''){
                subKeys = key
              }else{
                var unique = true
                subKeys.split('-').forEach(char=>{
                  if(char === key){
                    unique = false
                  }
                })
                if(unique){
                  subKeys += '-' + key
                }
              }
            }
          })
        }else{
          if(keys === '1' ||keys === '3' ||keys === '6' ||keys === '9' ){
            if(gradeKeys === ''){
              gradeKeys = keys
            }else{
              var unique = true
              gradeKeys.split('-').forEach(char=>{
                if(char === keys){
                  unique = false
                }
              })
              if(unique){
                gradeKeys += '-' + keys
              }
            }
          }else{
            if(subKeys === ''){
              subKeys = keys
            }else{
              var unique = true
              subKeys.split('-').forEach(char=>{
                if(char === keys){
                  unique = false
                }
              })
              if(unique){
                subKeys += '-' + keys
              }
            }
          }
        }
      }
      // if a word is a hot word, get the appropriate letter Key {M:Math}
    })
    this.gradeS = gradeKeys
    this.subjectS = subKeys
  }

  recurrAdd(){
    var unique = true;
    if(this.reqDate !== '' && this.reqDateEnd !== '' && this.reqStartTime!== '' && this.reqEndTime !== ''){
      var day = 0
      if(this.reqDate === 'Monday'){
        day =  0
      }else if(this.reqDate === 'Tuesday'){
        day = 1
      }else if(this.reqDate === 'Wednesday'){
        day = 2
      }else if(this.reqDate === 'Thursday'){
        day = 3
      }else if(this.reqDate === 'Friday'){
        day = 4
      }else if(this.reqDate === 'Saturday'){
        day = 5
      }else if(this.reqDate  === 'Sunday'){
        day = 6
      }

      var currDate = new Date()
      var count = 0
      var numSessions = Number(this.reqDateEnd)
      var dates = []
      if(this.reqSessionNum === ''){
        this.reqSessionNum = String(numSessions)
      }else{
        this.reqSessionNum = String(Number(this.reqSessionNum) + numSessions)
      }
      while(count !== numSessions){
        if(currDate.getDay() === day){
          dates.push(currDate.getFullYear() + '-' + `${currDate.getMonth() + 1}` + '-' + currDate.getDate())
          count += 1
        }
        currDate = new Date(currDate.getTime() + (1000 * 60 * 60 * 24));
      }
      dates.forEach(date=>{
        this.timeRequests.forEach(val =>{
          if(val[4] === date + "-" + this.reqStartTime + "-" +this.reqEndTime ){
            unique = false
          }
        })
        if(unique){
          this.timeRequests.push([`${date}`,this.reqStartTime,this.reqEndTime,'' ,date + "-" +this.reqStartTime + "-" +this.reqEndTime])
        }
      })

    }else{
      //error
      var err = 'Looks like you forgot:  '
      if(this.reqDate !== '' ){
        err += 'a date'
      }
      if(this.reqStartTime!== ''){
        err += ', a startime'
      }
      if(this.reqEndTime !== ''){
        err += ', an end time'

      }
      if(this.reqSessionNum  !== ''){
        err += ', # of sessions'
      }
      this.err = [err]
    }
  }


  addReqTime(){
    if(this.nonrecurr){
      this.nonRecurrAdd()
    }else if(this.now){
      this.nowAdd()
    }else if(this.recurr){
      this.recurrAdd()
    }
  }

  clearReq(val2){
    this.timeRequests = this.timeRequests.filter(val => val[4] !== val2)
  }

  getTimesSingle(startTime,endTime){
    var currTime = startTime
    var holdRet = []
    while(endTime >= currTime){
      if(currTime%100 ===5){
        currTime = currTime - 5
      }
      if(currTime%100 < 60){
        holdRet.push(currTime)
      }
      currTime +=15
    }
    if(currTime%100 ===5){
      currTime = currTime - 5
      holdRet.push(currTime)
    }
    return holdRet
  }

  formatTime(num:number):string{
    if(num < 1200){
      if(num%100 === 0){
        return String((Math.floor(num/100)))+ "am"
      }else{ 
        if(num%100 < 10){
          return String((Math.floor(num/100)))+":"+"0"+String(num%100) + "am"
        }else{
          return String((Math.floor(num/100)))+":"+String(num%100) + "am"
        }      
      } 
    }else{
      var holdVar = Math.floor(num/100)-12
      if(holdVar === 0){
        holdVar = 12
      }
      if(num%100 === 0){
        return String(holdVar)+ "pm"
      }else{ 
        return String(holdVar)+":"+String(num%100) + "pm"
      }       

    }
  }

  getMonth(month){
    month = Number(month)
    if(month === 1){
      return "Jan"
    }else if(month === 2){
      return "Feb"
    }else if(month === 3){
      return "Mar"
    }else if(month === 4){
      return "Apr"
    }else if(month === 5){
      return "May"
    }else if(month === 6){
      return "June"
    }else if(month === 7){
      return "July"
    }else if(month === 8){
      return "Aug"
    }else if(month === 9){  
      return "Sep"
    }else if(month === 10){
      return "Oct"
    }else if(month === 11){
      return "Nov"
    }else{
      return "Dec"  
    }
  }

  dateFormat(day){
    if(day ==='Monday'){
      return 'Monday'
    }else if(day ==='Tuesday'){
      return 'Tuesday'
    }else if(day ==='Wednesday'){
      return 'Wednesday'
    }else if(day ==='Thursday'){
      return 'Thursday'
    }else if(day ==='Friday'){
      return 'Friday'
    }else if(day ==='Saturday'){
      return 'Saturday'
    }else if(day ==='Sunday'){
      return 'Sunday'
    }else if(day ==='ASAP'){
      return 'ASAP'
    }
    else{
      var hold = day.split("-")
      var hold2 = day
      var day = hold[2]
      var month = hold[1]     
      var nn = new Date(`${hold2}`)
      var refit = ''
      hold2.split('-').forEach(timePiece =>{
        if(timePiece.length === 1){
          if(refit === ''){
            refit = '0' + timePiece
          }else{
            refit += '-0' + timePiece
          }
        }else{
          if(refit === ''){
            refit = timePiece
          }else{
            refit += '-' + timePiece
          }
        }
      })
      var nn2 = new Date(`${refit}`)
      console.log(refit,hold2,nn2,nn2.getDay(),this.getDay(nn2.getDay()))
      return this.getDay(nn2.getDay()) + " " + this.getMonth(month) + " " +  day 
    }
  }

  getTimesDef(startTime,endTime){
    var currTime = startTime
    while(endTime >= currTime){
      if(currTime%100 ===5){
        currTime = currTime - 5
      }
      if(currTime%100 < 60){
        this.listNum1.push(currTime)
        this.listNum2.push(currTime)
      }
      currTime +=15
    }
    if(currTime%100 ===5){
      currTime = currTime - 5
      this.listNum1.push(currTime)
      this.listNum2.push(currTime)
    }
    
  }

    
  async displayTeacher(uid:string){
    var newTeachers: TeacherProfile[] = []
    await this.database.database.ref('providers/userInfo/' + uid).once('value').then(function(snap){
      var cred = ""
      var stars = 5 
      var name = ""
      snap.child('immutable').forEach(function(child){
        if(child.key === "formalTitle"){
          cred = child.child('val').val()
        }else if (child.key === "stars"){
          stars = child.child('val').val() 
        }else if(child.key === "name"){
          name = child.child('val').val()
        }
      })

      var description = snap.child('profile').child('shortIntro').child('val').val()
      var rate = snap.child('pageInfo').child('rate').val()
      var teach = new TeacherProfile(uid,name,rate,stars,cred,description)
      newTeachers.push(teach)
    })
    if(newTeachers.length > 0 ){
      newTeachers.forEach(teacher=>{
        this.teachers.push(teacher)
      })
    }
    

    //this.doneLoading.emit(true)
  }

  async tester2(){
    console.log(this.UIDs)
  }

  shuffleArray(array) {
    var i = 0
    , j = 0
    , temp = null

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1))
    temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
    return array
  }

  async tester(){
    this.UIDs = ''
    var set = []  
    
    await this.chosenGrades.forEach(grade=>{
      this.chosenSubjects.forEach(sub=>{
        var holdSet = []
        this.database.database.ref('teacherSearch/' + `${grade}` + '/' + `${sub}`).once('value').then(function(snap){
          snap.forEach(function(snapp){
            if(!set.includes(snapp.val())){
              set.push(snapp.val())
              if(holdSet.length < 4){
                holdSet.push(snapp.val())
              } 
            }
          })
        }).then(()=>{    
          holdSet.forEach((valu:string)=>{
            if(this.UIDs === ''){
              this.UIDs = valu
            }else{
              this.UIDs += '|' + valu
            }
          })
        })
      })
    })
  }

  async reqTime(){
    this.logging2 = true
    var dbRef = this.database
    var serviceHold = this.chosenServices
    var UIDHold = this.UIDs
    var teacherRate = this.rate
    var teacherOffset = ((new Date().getTimezoneOffset()/60) - this.teacherTimeZoneOffset) * 100 
    var ttzHold = this.teacherTimeZoneOffset
    var teacherName = this.name
    var teacherEmail = this.teacherEmail
    var credHold = this.cred
    var reqRate = this.reqSessionRate
    var numSessions = this.reqSessionNum 
    if(this.reqSessionRate !== '' && (this.reqSessionNum !== '' || this.recurr)){
      if(this.now){
        await this.nowAdd()
      }
      if(this.chosenGrades.length > 0 && this.chosenSubjects.length > 0){
        if(this.timeRequests.length > 0){
          if(this.isTeacher === false){
            if(auth().currentUser){   
              var times = ''
              var teacherTime = ''
              var count = 1
              await this.timeRequests.forEach(val=>{
                if (count === 1){
                  times += val[4] 
                  var holdS = Number(val[4].split('-')[3]) + teacherOffset
                  var holdE = Number(val[4].split('-')[4]) + teacherOffset
                  teacherTime += val[4].split('-')[0] + '-' + val[4].split('-')[1] + '-' + val[4].split('-')[2] + '-' + String(holdS) + '-' + String(holdE)
                }else{
                  times += "|" + val[4] 
                  var holdS = Number(val[4].split('-')[3]) + teacherOffset
                  var holdE = Number(val[4].split('-')[4]) + teacherOffset
                  teacherTime += "|" + val[4].split('-')[0] + '-' + val[4].split('-')[1] + '-' + val[4].split('-')[2] + '-' + String(holdS) + '-' + String(holdE)
                }
                count += 1
              })
  
  
  
              const reqTimeAuto = this.fns.httpsCallable('reqTimeAuto')
              reqTimeAuto({
                ParentUID:auth().currentUser.uid,
                TeacherUIDS:UIDHold,
                TeacherEmail:teacherEmail,
                ParentEmail:auth().currentUser.email,
                requestedTimes:times,
                services:this.chosenServices,  
                numSessions:this.reqSessionNum,
                teacherOffset: teacherOffset,
                teacherRate: teacherRate,
                teacherName:teacherName,
                ttz:ttzHold,
                teacherTime:teacherTime,
                rate:this.reqSessionRate
              }).toPromise().then(()=>{
                    gtag('event', 'add_to_cart', {
                      currency: 'USD',
                      items: [{
                        name: `${UIDHold}`,
                        brand: `${credHold}`,
                        category: `${serviceHold}`,
                        price: `${reqRate}`,
                        quantity: Number(numSessions)
                      }]
                    });
                    this.rerouter.navigateByUrl('profile/' + `${auth().currentUser.uid}`)
                  }).finally((()=>{
                    this.logging2 = false
                  }))   
             
            }else{
              //make them login
              this.logging2 = false
              this.createLogin2 = ["Please create an account."]
            }
          }else{
            //please create a non teacher account
            this.logging2 = false
              this.createLogin2 = ["Sorry, you need a non teacher account"]
          }
        }else{
          this.logging2 = false
          this.createLogin2 = ["# of sessions and rate needed."]
        }
      }else{
        this.logging2 = false
        this.createLogin2 = ["Need to choose a grade level and subject"]
      }

    }else{
      //error msg   
      this.logging2 = false
    }
  }

  teacherPage(s){
    console.log(s)
  } 
  onResized(event: ResizedEvent) {
    this.width = event.newWidth;
    this.height = event.newHeight;
    this.width = event.newWidth;   
    this.height = event.newHeight;
    if(this.width < 700){
      this.mobile.emit(false)
    }
    if(this.width > 699){
      this.mobile.emit(true)
    }
  }

  getDates(){ 
    var minDate = new Date(); 
    var count = 1 
    while(count < 100){
        var dd3 = String(minDate.getDate()).padStart(2, '0');
        var mm3 = String(minDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy3 = minDate.getFullYear();
        var todays3 =  yyyy3 + '-' + mm3 + '-' + dd3;
        this.validDays1.push(todays3)
        this.validDays2.push(todays3)
        minDate = new Date(minDate.getTime() + ((1000 * 60 * 60 * 24)))
        count += 1
    }
     
  }

  gradeFormat(grade){
    if(grade === '1_2'){   
      return '1st to 2nd'   
    }else if(grade === '3_5'){
      return '3rd to 5th'  
    }else if(grade === '6_8'){
      return '6th to 8th'
    }else if(grade === '9_12'){
      return '9th to 12th'
    }
  }


  select(val){
    var selectedElem = document.getElementById(`${val}`)
    var dropship = document.getElementById('dropship')
    var arrow = document.getElementById(`${val}` + 'Arrow')
    var tag = document.getElementById(`${val}` + 'Tag')
    if(this.currentSelector !== ''){
      if(this.currentSelector === val){
        this.currentSelector =''
        if(val === 'days'){
          var content = document.getElementById(val + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display: none;')
          selectedElem.setAttribute('class','group-99')
          dropship.setAttribute('class','overlap-group1-1')
          tag.setAttribute('class','suitable-days inter-semi-bold-chathams-blue-14px')
          arrow.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }else if(val === 'subject'){
          var content = document.getElementById(val + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display: none;')
          selectedElem.setAttribute('class','group-194')
          dropship.setAttribute('class','overlap-group1-1')
          tag.setAttribute('class','subject inter-semi-bold-chathams-blue-14px')
          arrow.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }else if(val === 'grade'){
          var content = document.getElementById(val + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display: none;')
          selectedElem.setAttribute('class','group-98')
          dropship.setAttribute('class','overlap-group1-1')
          tag.setAttribute('class','grade inter-semi-bold-chathams-blue-14px')
          arrow.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }
      }else{
        var selectedElem2 = document.getElementById(`${this.currentSelector}`)
        var arrow2 = document.getElementById(`${this.currentSelector}` + 'Arrow')
        var tag2 = document.getElementById(`${this.currentSelector}` + 'Tag')
        if(this.currentSelector === 'days'){
          console.log(1)
          var content = document.getElementById(this.currentSelector + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display: none;')
          selectedElem2.setAttribute('class','group-99')
          tag2.setAttribute('class','suitable-days inter-semi-bold-chathams-blue-14px')
          arrow2.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }else if(this.currentSelector === 'subject'){
          console.log(2)
          var content = document.getElementById(this.currentSelector + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display: none;')
          selectedElem2.setAttribute('class','group-194')
          tag2.setAttribute('class','subject inter-semi-bold-chathams-blue-14px')
          arrow2.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }else if(this.currentSelector === 'grade'){
          console.log(3)
          var content = document.getElementById(this.currentSelector + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;display: none;')
          selectedElem2.setAttribute('class','group-98')
          tag2.setAttribute('class','grade inter-semi-bold-chathams-blue-14px')
          arrow2.setAttribute('src','https://anima-uploads.s3.amazonaws.com/projects/608b3bc2d56a83496c2c8522/releases/608b43657e8190e4de833870/img/vector@2x.svg')
        }

        this.currentSelector = val
        if(val === 'days'){
          var content = document.getElementById(val + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;')
          selectedElem.setAttribute('class','group-99-1')
          tag.setAttribute('class','suitable-days inter-normal-white-14px')
          arrow.setAttribute('src','../../assets/vector-54@2x.svg')
          dropship.setAttribute('class','overlap-group1-1-3')
        }else if(val === 'subject'){
          console.log(5)
          var content = document.getElementById(val + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;')
          this.selections = ['Language Arts','Mathematics','Science','Social Studies','Creative Arts','World Language']
          selectedElem.setAttribute('class','group-194-1')
          tag.setAttribute('class','subject inter-normal-white-14px')
          arrow.setAttribute('src','../../assets/vector-54@2x.svg')
          dropship.setAttribute('class','overlap-group1-1-1')
        }else if(val === 'grade'){
          console.log(6)
          var content = document.getElementById(val + 'Selector')  
          content.setAttribute('style','z-index: 1;cursor: pointer;')
          selectedElem.setAttribute('class','group-98-1')
          tag.setAttribute('class','grade inter-normal-white-14px')
          arrow.setAttribute('src','../../assets/vector-54@2x.svg')
          dropship.setAttribute('class','overlap-group1-1-2')
        }
      }
    }else{
      this.currentSelector = val
      var selectedElem = document.getElementById(`${val}`)
      if(val === 'days'){
        var content = document.getElementById(this.currentSelector + 'Selector')  
        content.setAttribute('style','z-index: 1;cursor: pointer;')
        selectedElem.setAttribute('class','group-99-1')
        tag.setAttribute('class','suitable-days inter-normal-white-14px')
        arrow.setAttribute('src','../../assets/vector-54@2x.svg')
        dropship.setAttribute('class','overlap-group1-1-3')
      }else if(val === 'subject'){
        var content = document.getElementById(this.currentSelector + 'Selector')  
        content.setAttribute('style','z-index: 1;cursor: pointer;')
        selectedElem.setAttribute('class','group-194-1')
        tag.setAttribute('class','subject inter-normal-white-14px')
        arrow.setAttribute('src','../../assets/vector-54@2x.svg')
        dropship.setAttribute('class','overlap-group1-1-1')
      }else if(val === 'grade'){
        var content = document.getElementById(this.currentSelector + 'Selector')  
        content.setAttribute('style','z-index: 1;cursor: pointer;')
        selectedElem.setAttribute('class','group-98-1')
        tag.setAttribute('class','grade inter-normal-white-14px')
        arrow.setAttribute('src','../../assets/vector-54@2x.svg')
        dropship.setAttribute('class','overlap-group1-1-2')
      }
    }
  }


  changeViews(type){
    if(type === 'NonRecurring'){
      this.reqSessionNum = ''
      this.slotPlaceHolder6 = '# Sessions'
      this.recurr = false
      this.now = false    
      this.nonrecurr = true
      this.slotPlaceHolder2 = 'Date' 
      this.sessionList = [1,2,3,4,5,6,7,8,9,10]
      this.slotPlaceHolder2 = 'Date'
      this.validDays1 = []
      this.getDates()
      this.slotPlaceHolder6 = '# of Sessions'
    }else if(type === 'Recurring'){
      this.slotPlaceHolder2 = 'Start Date' 
      this.recurr = true
      this.validDays1 = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
      this.validDays2 = [1,2,3,4,5,6,7,8,9,10]
      this.slotPlaceHolder2 = 'Day'
      this.slotPlaceHolder22 = '# Of Sessions'
      this.now = false
      this.nonrecurr = false 
      
    }else if(type === 'Now'){
      this.reqSessionNum = ''
      this.recurr = false
      this.now = true
      this.nonrecurr = true
      this.sessionList = [15,30,45,60,75,90,105,120]
      this.slotPlaceHolder6 = 'Time Needed'
    }
    var hold = this.previousTypeOfBook
    document.getElementById(`${hold}`).style.background = '#292F45'
    this.previousTypeOfBook = `${type}`
    document.getElementById(`${type}`).style.background = '#EA6F66'
  }

  incrementCounters(type:number){
    if(type === 0){ 
      this.zeros += 1
    }else if(type === 1){
      this.ones += 1
    }else{
      this.twos += 1
    }
  }

  storeAutoReq(val,type){
    var valEL = val + '2'
    if(type === 0){
      if(this.chosenGrades.includes(val)){
        this.chosenGrades = this.chosenGrades.filter(grade => grade !== val)
        document.getElementById(`${valEL}`).style.background = '#292F45'
        this.tester()
      }else{
        this.chosenGrades.push(val)
        this.chosenGrades = this.shuffleArray(this.chosenGrades)
        document.getElementById(`${valEL}`).style.background = '#EA6F66'
        this.tester()
      }
    }else{
      if(this.chosenSubjects.includes(val)){
        this.chosenSubjects = this.chosenSubjects.filter(grade => grade !== val)
        document.getElementById(`${valEL}`).style.background = '#292F45'
        this.tester()
      }else{
        this.chosenSubjects.push(val)
        this.chosenSubjects = this.shuffleArray(this.chosenSubjects)
        document.getElementById(`${valEL}`).style.background = '#EA6F66'
        this.tester()
      }
    }
  }

  async signUpNL(){
    this.sentNL = true
    var emailHold = this.nameNL + '||' + this.emailNL  
    if(this.emailNL !== ''){
      var addr = 'fred.mendoza527@gmail.com'
      var sub = 'New Teacher Updates Sign Up'
      const newEmail = this.fns.httpsCallable('sendMail')
      await newEmail({
        address: addr,
        subject: sub,
        msg:`${emailHold}`
      }).toPromise().then(()=> {
        this.nLMsg = ['Thanks for signing up!']
        
      }).catch((e)=> {
        this.nLMsg = ['Something went wrong, please try again.']
        this.sentNL = false
      })   
    }else{
      this.nLMsg = ['Only an Email is Necessary']
    }
  }

  decrementCounters(type:number){
    if(type === 0){
      this.zeros -= 1
    }else if(type === 1){
      this.ones -= 1
    }else{
      this.twos -= 1
    }
  } 
  removeEle(value, type):boolean{
    var ret = false
    this.currentQueries.forEach(function(q) {
      if(q.getStream()[type] === value){
        ret = true;   
      }
    })
    return ret  
  }
  changeColor(val){
    document.getElementById(val).style.background = '#EA6F66'
    document.getElementById(val).style.border = '5px solid rgb(230,230,230)'
  }      

  storeAttr(value:string,type:number){
    if(type === 0){
      if(this.subjectS === ''){
        document.getElementById(value).style.background = '#B1E7F3'
        this.subjectS += value
      }else{  
        if(this.subjectS.includes(value)){
          document.getElementById(value).style.background = 'none'
          if(this.subjectS.length === 1){
            this.subjectS = ''
          }else{
            this.subjectS = this.subjectS.replace(value,'')
          }
        }else{
          document.getElementById(value).style.background = '#B1E7F3'
          this.subjectS += value
        }
      }
    }else if(type === 1){
      if(this.gradeS === ''){
        document.getElementById(value).style.background = '#B1E7F3'
        this.gradeS += value
      }else{  
        if(this.gradeS.includes(value)){
          document.getElementById(value).style.background = 'none'
          if(this.gradeS.length === 1){
            this.gradeS = ''
          }else{
            this.gradeS = this.gradeS.replace(value,'')
          }
        }else{
          document.getElementById(value).style.background = '#B1E7F3'
          this.gradeS += value
        }
      }
    }else if(type === 2){
      if(this.timeS === ''){
        document.getElementById(value).style.background = '#B1E7F3'
        this.timeS += value
      }else{  
        if(this.timeS.includes(value)){
          document.getElementById(value).style.background = 'none'
          if(this.timeS.length === 1){
            this.timeS = ''
          }else{
            this.timeS = this.timeS.replace(value,'')
          }
        }else{
          document.getElementById(value).style.background = '#B1E7F3'
          this.timeS += value
        }
      }
    }
  }


  onSelect(value:string,type:number){
    this.fresh = false
    if(this.removeEle(value,type)){
      document.getElementById(value).style.background = '#292F45'
      this.removeFilter(value,type)
      if(this.currentQueries.length === 1){
        if(this.currentQueries[0].getStream()[0] === "" && this.currentQueries[0].getStream()[1] === "" && this.currentQueries[0].getStream()[2] === ""){
          this.currentQueries = []
          this.blank = true
        }else{
          this.blank = false
        }
      }  
      this.decrementCounters(type)
      this.teachers = []
      this.teacherUID = []
      this.runQurieies(this.currentQueries)
      this.currTeachers.emit(this.teachers)
    }else{
      //add new search filter
      document.getElementById(value).style.background = '#EA6F66'
      this.updateSearch(value,type)
      this.incrementCounters(type)
      this.teachers = []
      this.teacherUID = []
      this.runQurieies(this.currentQueries)
      this.currTeachers.emit(this.teachers)
    }
  }

  
  removeFilter(value:string, type:number){
    if(this.occurances(type) < 2){
      var removedQs =[]
      var newQs = []
      this.currentQueries.forEach(element =>{
        if(element.getStream()[type] === value){
          removedQs.push(element)
          if(!(element.simUpdateStream(value,type)[0] === "" && element.simUpdateStream(value,type)[1] === "" && element.simUpdateStream(value,type)[2] === "")){
            element.updateStream("",type)
            newQs.push(element.getStream())
          }
        }
      })
    }else{
      var list = []
      this.currentQueries.forEach(querie =>{
        if(querie.getStream()[type] === value){
          list.push(querie)
        }
      });
      list.forEach(element=>{
        this.currentQueries = this.currentQueries.filter(val => val !== element)
      });
      this.currTeachers.emit(this.teachers)
    }
  }

  occurances(type:number):number{
    if(type === 0){
      return this.zeros
    }else if (type === 1){
      return this.ones
    }else{
      return this.twos
    }
  }

  async runQurieies(qList:queries[]){
    var active:queries[] = []
    qList.forEach(element => {
      var count = 0
      var newQ = new queries("",1)
      element.getStream().forEach(ele => {
        if(ele === ""){
          var newValue = this.complete(count)
          newQ.updateStream(newValue,count)
        }else{
          newQ.updateStream(ele,count)
        } 
        count += 1
      })
      active.push(newQ)
    });
    await active.forEach(qu=>{
     this.runQurieie(qu,0)
    })
  }
  
  reqRate(rate){
    this.reqSessionRate = rate
  }

  //get the teacher UIDs from the db to rank based on stars
  //just like old time where you have to get all values from a database section
  // new when you need to get all values from paticular sections of a database section
  async runQurieie(q:queries,run:number):Promise<string[]>{
    var val = q
    var request = "teacherSearch/" + val.getStream()[0]
    var ref = this.database.database.ref(request)
    var teachers = []
    var RG = this.relatedGuess(val.getStream()[1],1)
    var BG = this.relatedGuess(val.getStream()[2],2)
    var sub = ""
    await ref.once("value").then(function(snapshot){
      if(snapshot.hasChild(val.getStream()[1])){
        if(snapshot.child(val.getStream()[1]).hasChild(val.getStream()[2])){
          var hol:DataSnapshot = snapshot.child(val.getStream()[1]).child(val.getStream()[2])
          hol.forEach(ele=>{
            teachers.push(ele.val())
          })
        }else{ 
          // BG / days if => else
          if(snapshot.child(val.getStream()[1]).hasChildren()){
            snapshot.child(val.getStream()[1]).forEach(function(val) {
            var hol:DataSnapshot = val
              hol.forEach(ele=>{
                teachers.push(ele.val())
              })
            })
          }
        }
      }else{
          if(snapshot.hasChild(RG)){
            if(snapshot.child(RG).hasChild(val.getStream()[2])){
              var hol:DataSnapshot = snapshot.child(RG).child(val.getStream()[2])
              hol.forEach(ele=>{
                if(ele.key !== "sub"){
                  teachers.push(ele.val())
                }
              })
            }else{
              // RG for the day
              if(snapshot.child(RG).hasChild(BG)){
                var hol:DataSnapshot = snapshot.child(RG).child(BG)
                hol.forEach(ele=>{
                  teachers.push(ele.val())
                })
              }else{
                //any keys / any days?
                if(snapshot.child(RG).hasChildren()){
                  snapshot.child(RG).forEach(function(val) {
                    var hol:DataSnapshot = val
                    hol.forEach(ele=>{
                      teachers.push(ele.val())
                    })
                  })
                }
              }
            }
          }
        }
        sub = snapshot.child("sub").val()
      });
      run += 1
      var hold
      if(run < 1){
        var nQ = new queries("",0)
        var cnt = 0
        q.simUpdateStream(sub,0).forEach(val =>{
          nQ.updateStream(val,cnt)
          cnt += 1
        })
        hold = await this.runQurieie(nQ,run)
        hold.forEach(val=>{
          teachers.push(val)
        })
      }
      teachers.forEach(val =>{
        val = val["uid"]
        if(this.teacherUID.find(ele => ele === val) === undefined){
          if(this.newUid(val)){
            this.teacherUID.push(val)
            this.displayTeacher(val)
            this.currTeachers.emit(this.teachers)
          }
        }
      })   
      return this.teacherUID  
      
  }
 
  newUid(val):boolean{
    var ret = true;
    this.teacherUID.forEach(teacher=>{
      if(val === teacher){
        ret = false 
      } 
    })
    return ret
  } 
  relatedGuess(val:string,type:number):string{
    if(type === 1){
      if(val === "1_2"){
        return "3_5"
      }else if(val === "3_5"){
        return "6_8"
      }else if(val === "6_8"){ 
        return "9_12"
      }else{
        return "6_8"
      } 
    }else if(type === 2){
      if(val === "mon"){
        return "tue"
      }else if(val === "tue"){
        return "wed"
      }else if(val === "wed"){
        return "thu"
      }else if(val === "thu"){
        return "fri"
      }else if(val === "fri"){
        return "sat"
      }else if(val === "sat"){
        return "sun"
      }else{
        return "sat" 
      }
    }
    return ""
  }

  complete(type:number):string{
    if(type === 0){  
      return "English" 
    }else if(type === 1){
      return "6_8" 
    }else{
      return "thursday" 
    }
  }

  async updateSearch(value:string,type:number): Promise<boolean>{
    var added = false;
    if(this.currentQueries.length === 0){
      let newQ = new queries(value,type)
      this.currentQueries.push(newQ)
      added = true
    }
    else{
      this.currentQueries.forEach(search=>{ 
        var stream = search.getStream()
        if(stream[type] === ""){
          search.updateStream(value,type)
          added = true
        }else{ 
          var newQ = new queries("",0)
          var count = 0
          search.simUpdateStream(value,type).forEach(element =>{
            newQ.updateStream(element,count)
            count += 1
          });
          var plus = true
          this.currentQueries.forEach(element =>{
            if(element.getStream()[0] === newQ.getStream()[0] && element.getStream()[1] === newQ.getStream()[1] && element.getStream()[2] === newQ.getStream()[2]){
              plus = false
            }
          });
          if(plus){
            this.currentQueries.push(newQ)
            added = true 
          };
        }  
    });
  }
  return added;
}

  newUnique(q,value,type):boolean{
    var resp = true
    var test = q
    test[type] = value 
    this.currentQueries.forEach(val =>{
      if (val.getStream() === test){
        resp = false
      }
    })
    return resp

  }
}